import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../core/services/config.service';
import { MatrixPerson } from '../matrix.service';

@Component({
  selector: 'ug-matrix-person-detail',
  templateUrl: './matrix-person-detail.component.html',
  styleUrls: ['./matrix-person-detail.component.scss'],
})
export class MatrixPersonDetailComponent implements OnInit {
  configService = inject(ConfigService);
  modal = inject(NgbActiveModal);

  @Input() currentUser: MatrixPerson;
  @Input() orgUnitType: string;

  ngOnInit(): void {}
}
