import { Component, Input, OnInit } from '@angular/core';
import { ChartConfig } from '../../../echarts/chart-config';

@Component({
  selector: 'ug-matrix-summary-donut-chart',
  templateUrl: './matrix-summary-donut-chart.component.html',
  styleUrls: ['./matrix-summary-donut-chart.component.scss'],
})
export class MatrixSummaryDonutChartComponent implements OnInit {
  @Input() publishedCount: number;
  @Input() totalCount: number;
  @Input() chartTitle: string;

  chartDataItem: any;

  chartConfig: ChartConfig;

  chartColourMap = new Map([
    ['Draft', '#a2a2a2'],
    ['Assessed', '#17b9a7'],
    ['Awaiting Assessment', '#f7931d'],
    ['Not Started', '#90439a'],
    ['Closed (cancelled)', '#ef432e'],
    ['Complete', '#264148'],
    ['Clarification required', '#17b9a7'],
    ['Deleted', '#dd4b39'],
    ['Rejected', '#dd4b39'],
    ['Resubmitted', '#00c0ef'],
    ['Unassessed', '#f39c12'],
    ['Revoked', '#dd4b39'],
    ['Withdrawn', '#dd4b39'],
    ['Assessed (Unacknowledged)', '#00a65a'],
    ['Closed (deleted)', '#dd4b39'],
    ['In Progress', '#00c0ef'],
    ['Expired', '#dd4b39'],
  ]);

  layoutConfig = {
    height: 180,
    margin: { t: 80, b: 10, l: 0, r: 0 },
    showlegend: false,
    annotations: [
      {
        font: {
          size: 16,
        },
        showarrow: false,
      },
    ],
  };

  ngOnInit(): void {
    this.chartConfig = {
      title: this.chartTitle,
      type: 'doughnut',
      data: [
        { name: 'Complete', value: this.publishedCount, colour: '#198754' },
        {
          name: 'Incomplete',
          value: this.totalCount - this.publishedCount,
          colour: 'rgb(215, 11, 11)',
        },
      ],
      donut: true,
      centerLabelFormatter: (params) => {
        return `${
          Math.round((this.publishedCount / this.totalCount) * 100 * 10) / 10
        }%`;
      },
      containerStyle: 'height: 200px; margin-bottom: 0px; ',
      disableAnimation: true,
      tooltipFormatter: (data) => {
        return `${this.chartTitle}</br><b>${data.name}</b>: ${
          data.value
        } (${Math.round((data.value / this.totalCount) * 100)}%)`;
      },
      hideLegend: true,
    };
  }
}
