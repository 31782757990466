import { Component, Input } from '@angular/core';
import { ScoreTrafficLight } from '../services/job-role.service';

@Component({
  selector: 'ug-role-person-score',
  templateUrl: './role-person-score.component.html',
  styleUrls: ['./role-person-score.component.scss'],
})
export class RolePersonScoreComponent {
  @Input() jobRolePersonScore: number;

  getScoreTrafficLightClass(roleScore: number): string {
    let backgroundColour = ScoreTrafficLight.success;
    let textColour = '';

    if (roleScore < 0.4) {
      backgroundColour = ScoreTrafficLight.danger;
    } else if (roleScore < 0.75) {
      backgroundColour = ScoreTrafficLight.warning;
      textColour = 'text-dark';
    }

    return `fs-6 badge rounded-pill bg-${backgroundColour} ${textColour}`;
  }
}
