<div class="card esqep-indicate-validity" [formGroup]="parentFormGroup">
  <div class="card-body">
    <h5 class="card-title">
      Competency Attribute
      <small *ngIf="!ugDisabled" class="text-muted">Click to select</small>
    </h5>
    <div *ngIf="ugDisabled">
      <div class="alert alert-info mb-3">
        <span class="fas fa-xl a-info-circle"></span>Competency Attribute of an
        existing Assessment Request cannot be changed
      </div>
    </div>
    <div class="table-responsive table-hover">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let compAttr of filteredCompetencyAttrList;
              trackBy: trackByFn
            "
            [hidden]="!showCompAttr(compAttr)"
            [class.table-success]="compAttr.id === selectedCompAttr?.id"
            (click)="onCompAttrClick(compAttr)"
          >
            <td>{{ compAttr.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
