import { Directive, ElementRef, Input, OnChanges, Renderer2, inject } from '@angular/core';

@Directive({
  selector: '[ugHighlightMandatory]',
})
export class HighlightMandatoryDirective implements OnChanges {
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);

  @Input() ugHighlightMandatory: string;
  @Input() ugHiglightDisabled = false;

  ngOnChanges() {
    // if (this.ugHighlightMandatory === '' || this.ugHighlightMandatory === null) {
    if (
      (this.ugHighlightMandatory || '').length === 0 &&
      !this.ugHiglightDisabled
    ) {
      this.renderer.addClass(this.el.nativeElement, 'text-danger');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'text-danger');
    }
  }
}
