<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      Competency Attribute
      <small *ngIf="!disableSelect" class="text-muted">Click to select</small>
    </h5>
    <div *ngIf="disableSelect">
      <div class="alert alert-info mb-3">
        <span class="fas fa-xl fa-info-circle"></span>
        Competency Attribute of an existing Assessment Request cannot be changed
      </div>
    </div>
    <ug-job-family-select-control
      *ngIf="!disableSelect"
      (selectedJobFamilyChange)="onJobFamilyChange($event)"
    ></ug-job-family-select-control>
    <div class="table-responsive table-hover">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Competency Application</th>
            <th scope="col">Competency Subcategory</th>
            <th scope="col">Competency</th>
            <th scope="col" class="w-50">Competency Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            [hidden]="!showCompAttr(skill)"
            [class.table-success]="skill.id === selectedSkill?.id"
            *ngFor="let skill of skillList; trackBy: trackByFn"
            (click)="onSkillClick(skill)"
          >
            <th scope="row">{{ skill.funcAreaDesc }}</th>
            <td>{{ skill.funcAreaName }}</td>
            <td>{{ skill.name }}</td>
            <td>{{ skill.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- <p>{{selectedSkill | json}}</p> -->
