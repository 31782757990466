import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ug-resource-summary',
  templateUrl: './resource-summary.component.html',
  styleUrls: ['./resource-summary.component.scss'],
})
export class ResourceSummaryComponent {
  @Input() parentFormGroup: FormGroup;
}
