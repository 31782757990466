import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { CompetencyComponent } from './v2/competency/competency.component';

const routes: Routes = [
  {
    path: 'capability-framework',
    component: CompetencyComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ADMIN_CAPABILITY-FRAMEWORK',
      title: 'Capability Framework',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CompetenciesRoutingModule {}
