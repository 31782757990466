<div class="card shadow">
  <div class="card-body">
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="filterList"
      [tableHeaderButtons]="headerButtons"
      [tableFilters]="filters"
      [tableRowButtons]="rowButtons"
      [isLoading]="tableLoading"
    ></ug-table>
  </div>
</div>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
