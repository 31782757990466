import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AssessorRoutingModule } from './assessor-routing.module';
import { AssessorService } from './services/assessor.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AssessorRoutingModule,
  ],
  declarations: [],
  exports: [],
  providers: [AssessorService],
})
export class AssessorModule {}
