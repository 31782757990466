<div>
  <div class="row">
    <div class="col-12">
      <div class="card mb-3" [formGroup]="parentFormGroup">
        <div class="card-body" formGroupName="competency">
          <h5 class="card-title">Competency Type</h5>
          <div class="form-group" formArrayName="competencyDimensionRadio">
            <div
              *ngFor="let dim of compDims; let i = index; trackBy: trackByFn"
              class="form-check form-check-inline"
            >
              <input
                type="radio"
                [checked]="dim.value.id === selectedCompDimension?.id"
                class="form-check-input"
                id="{{ 'dimensionOption' + i }}"
                [value]="dim.value"
                [disabled]="editMode"
                (change)="onDimChange(dim.value)"
              />
              <label
                class="form-check-label"
                for="{{ 'dimensionOption' + i }}"
                >{{ dim.value.name | titlecase }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="selectedCompDimension?.name === 'APPLICATION'">
    <div class="row">
      <div class="col-12">
        <esqep-competency-attr-category-list
          [parentFormGroup]="parentFormGroup.get('competency')"
          [funcAreaId]="selectedCompDimension?.id"
          [disableSelect]="editMode"
          [jobFamily]="selectedJobFamily"
          [selectedSkill]="selectedSkill"
          (selectedSkillChange)="onCompAttrChange($event)"
        ></esqep-competency-attr-category-list>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedCompDimension?.name === 'KNOWLEDGE'">
    <div class="row">
      <div *ngIf="!editMode" class="col-12 col-md-auto pb-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Filter by:</h5>
            <ug-text-filter-control
              [(filterText)]="knowledgeFilter"
              [ugDisabled]="editMode"
            ></ug-text-filter-control>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        [ngClass]="{ 'col-md-6': !editMode, 'col-md-12': editMode }"
      >
        <esqep-competency-attr-filter-list
          [parentFormGroup]="parentFormGroup.get('competency')"
          [funcAreaId]="selectedCompDimension?.id"
          [filter]="knowledgeFilter"
          [selectedCompAttr]="selectedSkill"
          (selectedCompAttrChange)="onCompAttrChange($event)"
          [ugDisabled]="editMode"
        ></esqep-competency-attr-filter-list>
      </div>
    </div>
  </ng-container>
</div>
