import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { Subject } from 'rxjs';
import { UitoolsService } from '../../core/services/uitools.service';

@Component({
  selector: 'esqep-warn-unsaved',
  templateUrl: './warn-unsaved.component.html',
  styleUrls: [],
})
export class WarnUnsavedComponent implements OnChanges {
  private uiService = inject(UitoolsService);

  @ViewChild('unsavedWarningModal') unsavedWarningModal: ElementRef;

  @Input() showDialog = false;
  @Input() exitWithoutSaving$: Subject<boolean>;
  @Output() exit: EventEmitter<boolean> = new EventEmitter();
  @Output() goBack: EventEmitter<boolean> = new EventEmitter();

  ngOnChanges() {
    if (this.showDialog) {
      this.uiService.openModal(this.unsavedWarningModal);
    }
  }

  exitUnsaved() {
    this.exitWithoutSaving$.next(true);
    this.exit.emit(true);
  }

  goBackUnsaved() {
    this.exitWithoutSaving$.next(false);
    this.goBack.emit(true);
  }
}
