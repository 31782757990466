<div class="card shadow">
  <div class="card-body">
    <ng-container>
      <ug-default-amber
        [units]="settingUnits"
        [defaultAmberWarningForm]="defaultAmberWarningForm"
      ></ug-default-amber>

      <ug-training-amber
        (updateSkillsEvent)="updateSkillsEvent($event)"
        (deleteSkillFromUpdateList)="deleteSkillFromUpdateList($event)"
        [units]="settingUnits"
        [skillList]="trainingList"
        [skillType]="'training'"
        [hasAdminAccess]="hasAdminAccess"
      >
      </ug-training-amber>

      <ug-training-amber
        (updateSkillsEvent)="updateSkillsEvent($event)"
        (deleteSkillFromUpdateList)="deleteSkillFromUpdateList($event)"
        [units]="settingUnits"
        [skillList]="competencyList"
        [skillType]="'competency'"
        [hasAdminAccess]="hasAdminAccess"
      >
      </ug-training-amber>

      <ng-container *ngIf="hasAdminAccess">
        <div>
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary ms-1"
            (click)="auditCollapsed = !auditCollapsed"
            [attr.aria-expanded]="!auditCollapsed"
            aria-controls="collapseAudit"
          >
            {{ auditCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
          </button>
        </div>

        <div #collapse="ngbCollapse" [(ngbCollapse)]="auditCollapsed">
          <ug-matrix-settings-audit></ug-matrix-settings-audit>
        </div>
      </ng-container>
    </ng-container>

    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="saveSettings()"
        [disabled]="canSave === false"
      >
        Save
      </button>
    </div>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
