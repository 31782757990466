import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ClaimModule } from '../claim/claim.module';
import { ControlsModule } from '../controls/controls.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { OrgUnitModule } from '../org-unit/org-unit.module';
import { PersonModule } from '../person/person.module';
import { SkillModule } from '../skill/skill.module';
import { CoreRoutingModule } from './core-routing.module';

import { OAuthCallbackComponent } from './adresponse/adresponse.component';
import { AlertComponent } from './alert/alert.component';
import { ExceptionComponent } from './exception/exception.component';
import { HomeComponent } from './home/home.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { LoginComponent } from './login/login.component';
import { TestComponent } from './test/test.component';

import { AlertService } from '../core/services/alert.service';
import { AuthService } from '../core/services/auth.service';
import { ConfigService } from '../core/services/config.service';
import { UitoolsService } from './services/uitools.service';

import {
  NgbCollapseModule,
  NgbModalModule,
  NgbOffcanvasModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AdminGuard } from '../core/guards/admin.guard';
import { JobRoleModule } from '../job-role/job-role.module';
import { NavbarMenuComponent } from './navbars/navbar/menu/navbar-menu.component';
import { MiniMenuComponent } from './navbars/navbar/mini-menu/mini-menu.component';
import { NavbarComponent } from './navbars/navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClientAccountService } from './services/client-account.service';
import { InactivityTimeoutService } from './services/inactivity-timeout.service';

@NgModule({
  imports: [
    CommonModule,
    ClaimModule,
    ControlsModule,
    DashboardModule,
    FormsModule,
    JobRoleModule,
    OrgUnitModule,
    PersonModule,
    SkillModule,
    CoreRoutingModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbOffcanvasModule,
  ],
  declarations: [
    OAuthCallbackComponent,
    HomeComponent,
    AlertComponent,
    ExceptionComponent,
    TestComponent,
    LoginComponent,
    LoggedOutComponent,
    NavbarMenuComponent,
    PageNotFoundComponent,
    NavbarComponent,
    MiniMenuComponent,
  ],
  exports: [
    OAuthCallbackComponent,
    HomeComponent,
    AlertComponent,
    ExceptionComponent,
    TestComponent,
    LoginComponent,
    LoggedOutComponent,
    NavbarMenuComponent,
    PageNotFoundComponent,
    NavbarComponent,
    MiniMenuComponent,
  ],
  providers: [
    AdminGuard,
    AuthService,
    AlertService,
    ConfigService,
    UitoolsService,
    InactivityTimeoutService,
    ClientAccountService,
  ],
})
export class CoreModule {}
