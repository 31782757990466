import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Filter } from '../../../../../dynamic-groups/services/dynamic-groups.service';

@Component({
  selector: 'ug-role-group-filter',
  templateUrl: './role-group-filter.component.html',
  styleUrls: ['./role-group-filter.component.scss'],
})
export class RoleGroupFilterComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() allowEdit: boolean;
  @Input() selectedFilter;
  @Input() jobRoleFilter: Filter;
  @Input() discardChangesSubj: Subject<boolean>;
  @Output() discardChangesFilter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  displayFilter: any;

  ngOnInit(): void {
    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.displayFilter = this.jobRoleFilter;
        this.parentFormGroup.get('filterId').markAsPristine();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedFilter'] && changes['selectedFilter'].currentValue) {
      this.displayFilter = this.selectedFilter;
    }

    if (changes['jobRoleFilter'] && changes['jobRoleFilter'].currentValue) {
      this.displayFilter = this.jobRoleFilter;
    }
  }

  onRemoveFilterClick() {
    this.parentFormGroup.get('filterId').setValue(null);
    this.parentFormGroup.get('filterId').markAsDirty();
  }

  onRestoreFilterClick() {
    this.parentFormGroup.get('filterId').setValue(this.jobRoleFilter.id);
    this.parentFormGroup.get('filterId').markAsPristine();
  }

  onDiscardFilterClick() {
    this.parentFormGroup.get('filterId').setValue(null);
    this.parentFormGroup.get('filterId').markAsPristine();
    this.displayFilter = null;
  }

  onDiscardChangesFilterClick() {
    this.parentFormGroup.get('filterId').setValue(this.jobRoleFilter.id);
    this.parentFormGroup.get('filterId').markAsPristine();
    this.displayFilter = this.jobRoleFilter;
    this.selectedFilter = null;
    this.discardChangesFilter.emit(true);
  }
}
