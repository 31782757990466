<div class="row vh-100 align-items-center mt-n5">
  <div class="col-12 col-md-8 offset-md-2">
    <div class="card mt-n5">
      <div class="card-header bg-dark text-light">
        <span class="h3">Logged out</span>
      </div>
      <div class="card-body">
        <!-- <h2 class="card-title">Please log in</h2> -->
        <p class="card-text lead">
          {{ loggedOutText }}. To log back in please click the login button
          below and follow instructions to authenticate your details using
          Microsoft Active Directory.
        </p>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-outline-primary float-end"
          (click)="login()"
          role="button"
        >
          Log in
        </button>
      </div>
    </div>
  </div>
</div>
