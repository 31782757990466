import { Directive, ElementRef, inject } from '@angular/core';
import { ConfigService } from '../../core/services/config.service';

@Directive({
  selector: '[ugAnonymize]',
})
export class AnonymizeDirective {
  private el = inject(ElementRef);
  private configService = inject(ConfigService);

  constructor() {
    if (this.configService.blurNames) {
      this.el.nativeElement.classList.add('blur-text');
    }
  }
}
