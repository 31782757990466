<div>
  <div *ngIf="menuReady">
    <ul class="side-nav-list">
      <li *ngFor="let topMenuItem of menuData; let first = first">
        <a
          class="side-nav-item"
          [routerLink]="topMenuItem.itemRoute ?? []"
          data-bs-target="#side-nav"
          (click)="toggleSubMenu(topMenuItem.itemName)"
        >
          <div
            class="menu-layout"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="{{ topMenuItem.itemName }}"
          >
            <span
              class="menu-icons"
              [ngClass]="topMenuItem.itemIconClass"
            ></span>
          </div>
          <span
            *ngIf="!first"
            class="fas fa-sm chevron"
            [ngClass]="
              subMenu === topMenuItem.itemName && !visible
                ? 'fa-chevron-down'
                : 'fa-chevron-right'
            "
          ></span>
        </a>

        <ul
          *ngIf="!first"
          class="side-nav-sublist collapse"
          [ngbCollapse]="subMenu !== topMenuItem.itemName"
        >
          <ng-container *ngIf="topMenuItem.subMenu">
            <li
              *ngFor="let dropDownMenuItem of topMenuItem.subMenu"
              class="side-nav-sublist-item"
              routerLinkActive="active"
            >
              <a
                routerLinkActive="active"
                [routerLink]="dropDownMenuItem.itemRoute"
                (click)="toggleCollapse()"
                >{{ dropDownMenuItem.itemName }}
              </a>
              <div>
                <span
                  *ngIf="
                    dropDownMenuItem.claimCategory &&
                    claimNotificationMap.get(dropDownMenuItem.claimCategory) > 0
                  "
                  class="text-end badge bg-danger"
                  >{{
                    claimNotificationMap.get(dropDownMenuItem.claimCategory)
                  }}</span
                >
              </div>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
