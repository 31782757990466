import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Logger, LogLevel } from '../services/config.service';

@Component({
  selector: 'ug-adresponse',
  templateUrl: './adresponse.component.html',
  styleUrls: [],
})
export class OAuthCallbackComponent implements OnInit {
  private router = inject(Router);
  private authService = inject(AuthService);


  ngOnInit() {
    if (this.authService.isAuthenticated) {
      const storedUrl = sessionStorage.getItem('esqep.login.redirect');
      if (storedUrl) {
        Logger(`Retrieved url from storage: ${storedUrl}`, LogLevel.VERBOSE);
        sessionStorage.removeItem('esqep.login.redirect');
        this.router.navigateByUrl(storedUrl);
      } else {
        this.router.navigate(['home']);
      }
    }
  }
}
