import { Component, Input } from '@angular/core';

@Component({
  selector: 'ug-list-status',
  templateUrl: './list-status.component.html',
  styleUrls: [],
})
export class ListStatusComponent {
  private _listLength = 0;
  private _emptyListMessage: string;
  private _isLoading = true;
  @Input() dataType: string;

  @Input()
  set listLength(value) {
    this._listLength = value;
  }
  get listLength() {
    return this._listLength;
  }
  @Input() emptyListMessage: string;
  @Input()
  set isLoading(value) {
    this._isLoading = value;
    if (this.listLength < 1) {
      if (this.isLoading) {
        this.listStatusText = 'Loading. Please wait';
      } else {
        this.listStatusText = this.emptyListMessage ?? 'No data to display';
      }
    } else {
      this.listStatusText = '';
    }
  }
  get isLoading() {
    return this._isLoading;
  }
  listStatusText = '...';

  get showListStatus() {
    return this.isLoading || this.listLength < 1;
  }
}
