import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ConfigService } from '../../../../../core/services/config.service';
import {
  SkillSearchBody,
  SkillSearchDictionary,
  SkillService,
} from '../../../../../skill/services/skill.service';

@Component({
  selector: 'ug-typeahead-search',
  templateUrl: './typeahead-search.component.html',
  styleUrls: ['./typeahead-search.component.scss'],
})
export class TypeaheadSearchComponent implements OnInit {
  private skillService = inject(SkillService);
  private configService = inject(ConfigService);
  private selectSkill: any;

  trainingItems$: Observable<Map<string, any[]>> = of(new Map());
  trainingLoading = false;
  trainingInput$ = new Subject<string>();
  selectedTraining = [];
  notFoundText: string = '';
  searchControl = new FormControl();
  showMinCharMessage = true;

  @Output() userSelectedResult: EventEmitter<object[]>;
  @Output() closeSearchEvent: EventEmitter<boolean>;
  @Input() set selectedSkill(value: any) {
    this.selectSkill = value;
    if (!this.selectSkill) {
      this.selectedTraining = null;
    }
  }

  constructor() {
    this.userSelectedResult = new EventEmitter<object[]>();
    this.closeSearchEvent = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.trainingItems$ = this.trainingInput$.pipe(
      filter((t) => !!t),
      filter((t1) => {
        this.showMinCharMessage = true;
        return t1.length > 2;
      }),
      distinctUntilChanged(),
      tap(() => (this.trainingLoading = true)),
      switchMap((searchTerm) => {
        this.showMinCharMessage = false;
        const searchFilters: SkillSearchBody = {
          dimensionId: 4,
          name: searchTerm,
          maxCount: 50,
          types: this.configService.jobRoleSkillSearchTypes,
        };
        return this.skillService.getSkillsByJobRoleSearch(searchFilters).pipe(
          map((s: SkillSearchDictionary) => {
            return new Map(Object.entries(s));
          }),
          catchError(() => of([])), // empty list on error
          tap((res: any) => {
            this.trainingLoading = false;
            if (!res.size) {
              this.notFoundText =
                'No results found for ' +
                searchFilters.name +
                '. Please enter a different search criteria.';
            }
          }),
        );
      }),
    );
  }

  resetSearch(): void {
    this.closeSearchEvent.emit(true);
    this.showMinCharMessage = true;
    this.selectedTraining = [];
  }

  onTrainingChange() {
    this.showMinCharMessage = false;
    this.userSelectedResult.emit(this.selectedTraining);
  }
}
