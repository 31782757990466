import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JobRoleSettingsForm } from '../../../job-role/services/job-role.service';

@Component({
  selector: 'ug-due-date-control',
  templateUrl: './due-date-control.component.html',
  styleUrls: ['./due-date-control.component.scss'],
})
export class DueDateControlComponent {
  @Input()
  formGroup!: FormGroup<JobRoleSettingsForm>;
  @Input() controlText: string | undefined;
  @Input() forNewHires: boolean = false;
  @Input() controlTitle: string = 'dueDate';
  @Input() unitOptions: Map<string, string> | undefined;
  @Input() fieldOptions: Map<string, string> | undefined;

  tooltipText: string = '';

  get hireDateConfigurationSummary() {
    return (
      this.formGroup.get('value')?.value &&
      this.formGroup.get('unit')?.value &&
      this.formGroup.get('field')?.value
    );
  }
}
