import { Component, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ug-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent {
  fb = inject(FormBuilder);

  createProjectForm: FormGroup;
  activeTab: number = 1;
  activeSubTab: number = 1;
  @ViewChild('nav') nav;

  constructor() {
    this.createForm();
  }

  selectNextTab() {
    this.nav.select(this.activeTab++);
  }

  selectPreviousTab() {
    this.nav.select(this.activeTab--);
  }

  setTab() {
    this.nav.select(this.activeTab);
  }

  selectNextSubTab() {
    if (this.activeSubTab === 3) {
      this.nav.select(this.activeTab++);
    } else {
      this.activeSubTab++;
    }
  }

  selectPreviousSubTab() {
    if (this.activeSubTab === 1) {
      this.nav.select(this.activeTab--);
    } else {
      this.activeSubTab--;
    }
  }

  isTabFormValid(active: number): boolean {
    if (active === 1) {
      return this.createProjectForm.get('details').valid;
    } else if (active === 2) {
      return this.createProjectForm.get('rolesAndResources').valid;
    } else if (active === 3) {
      return this.createProjectForm.get('projectSettings').valid;
    }

    // Leaving as this for now as projects will come up later on the road map
    // returning false to stop tests from breaking
    return false;
  }

  createForm() {
    this.createProjectForm = this.fb.group({
      details: this.fb.group({
        projectName: ['', Validators.required],
        projectLocation: ['', Validators.required],
        projectCode: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        projectDescription: ['', Validators.required],
      }),
      rolesAndResources: this.fb.group({
        projectRoles: this.fb.group({
          roleModalFilters: this.fb.group({
            jobRole: [''],
          }),
          jobRoleSearch: [''],
          assignedJobRoles: this.fb.group({
            jobRoles: this.fb.array([]),
          }),
        }),
        projectCompetencies: this.fb.group({
          competenciesModalFilters: this.fb.group({
            dimension: [''],
            functionalArea: [''],
            discipline: [''],
            skill: [''],
          }),
          assignedCompetencies: this.fb.group({
            competencies: this.fb.array([]),
          }),
        }),
        projectMembers: this.fb.group({
          radioOption: ['rec'],
          allModalFilters: this.fb.group({
            person: [''],
            jobRole: [''],
            location: [''],
            maxProjects: [''],
          }),
          recModalFilters: this.fb.group({
            jobRole: [''],
            competency: [''],
          }),
          recommendations: this.fb.group({
            person: [''],
            jobRole: [''],
            location: [''],
            activeProjects: [''],
          }),
          assignedMembers: this.fb.group({
            projectMembers: this.fb.array([]),
          }),
        }),
      }),
      projectSettings: this.fb.group({
        visibility: this.fb.group({
          status: ['public'],
          person: [''],
          people: [''],
        }),
        ownership: this.fb.group({
          person: [''],
          people: [''],
        }),
        phasesForm: this.fb.group({
          phases: this.fb.array([]),
        }),
      }),
    });
  }
}
