<div class="card shadow">
  <div class="card-body">
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading">
      <div class="d-flex flex-md-row align-items-center flex-column">
        <p class="h5 m-0 me-3">Select a dimension:</p>
        <div *ngFor="let dimension of skillDimensions" class="me-3 mb-2">
          <button
            type="button"
            class="btn"
            [attr.title]="dimension.name"
            [ngClass]="getDimensionsColour(dimension.id)"
            (click)="selectedDimensionId = dimension.id"
          >
            {{ dimension.name }}
          </button>
        </div>
      </div>
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-end">
            <li
              *ngFor="let bc of subjectBreadcrumbs | keyvalue"
              class="breadcrumb-item"
            >
              <button
                type="button"
                class="btn btn-link p-0"
                (click)="onBreadCrumbClick(bc.value)"
              >
                {{ bc.value.title }}
              </button>
            </li>
            <li>
              <button
                *ngIf="subjectBreadcrumbs.size > 0"
                type="button"
                class="btn btn-sm esqepBtn ms-2"
                title="reset"
                (click)="resetHierarchy()"
              >
                Clear
              </button>
            </li>
          </ol>
        </nav>
      </div>
      <div
        class="hierarchy-container d-flex flex-column align-items-start align-content-start flex-lg-wrap"
      >
        <div
          *ngFor="
            let hierarchyLevel of hierarchyMap | keyvalue;
            let j = index;
            trackBy: trackByFn
          "
          class="me-5 col-12"
          [ngClass]="
            hierarchyLevel.value.collapsed
              ? 'collapsed-card-width'
              : 'uncollapsed-card-width'
          "
        >
          <div class="card">
            <div
              class="card-header px-0"
              [ngClass]="getCardHeaderColour(hierarchyLevel.value.collapsed)"
            >
              <div
                *ngIf="!hierarchyLevel.value.editMode"
                class="d-flex align-items-center"
              >
                <div class="col-9">
                  <div class="d-flex align-items-center">
                    <p class="h6 mb-0 fw-bold ms-2" [ngClass]="getTextColour()">
                      {{ hierarchyLevel.value.name }}
                    </p>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-end col-3"
                >
                  <button
                    class="btn btn-sm me-1"
                    [ngClass]="getAddButtonColour(selectedDimensionId)"
                    *ngIf="!hierarchyLevel.value.collapsed"
                    (click)="
                      openSubjectModal(
                        hierarchyLevel.value.name,
                        hierarchyLevel.value.id
                      )
                    "
                    title="Add new subject"
                  >
                    Add
                  </button>
                  <button
                    *ngIf="
                      hierarchyLevel.value.isEditable &&
                      !hierarchyLevel.value.collapsed
                    "
                    class="btn btn-sm me-1"
                    (click)="editSubjectLevel(hierarchyLevel.value.id)"
                    [ngClass]="getAddButtonColour(selectedDimensionId)"
                    title="Edit level"
                  >
                    Edit
                  </button>
                  <button
                    (click)="collapseLevel(hierarchyLevel.key)"
                    class="btn btn-sm bg-transparent align-self-end"
                    title="Expand or collapse level"
                  >
                    <span
                      class="fas"
                      [ngClass]="[
                        !hierarchyLevel.value.collapsed
                          ? 'fa-compress-alt'
                          : 'fa-expand-alt',
                        getTextColour()
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
              <div
                *ngIf="hierarchyLevel.value?.editMode"
                class="d-flex align-items-center mx-2"
              >
                <div [formGroup]="subjectLevelForm" class="col-8">
                  <div formArrayName="subjectLevelNames">
                    <div [formGroupName]="j">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        formControlName="name"
                        (keydown.enter)="$event.preventDefault()"
                        (keydown.shift.enter)="$event.preventDefault()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4 d-flex justify-content-end">
                  <button
                    role="button"
                    class="btn btn-success btn-sm"
                    (click)="confirmLevelChange(hierarchyLevel.key)"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div
              [hidden]="hierarchyLevel.value.collapsed"
              class="card-body p-0 subj-card-height"
            >
              <div
                [formGroup]="subjectSearchForm"
                class="d-flex flex-wrap py-1 ps-1 border-bottom align-items-center sticky-top bg-white"
              >
                <div class="col-8 m-1">
                  <div formArrayName="levels">
                    <div [formGroupName]="j">
                      <ug-dropdown-select
                        [ngbTypeahead]="hierarchyLevel.value.search"
                        [resultFormatter]="subjectFormatter"
                        [inputFormatter]="subjectFormatter"
                        formControlName="search"
                        [placeholder]="'Search ...'"
                        [inlineGroup]="false"
                      >
                      </ug-dropdown-select>
                    </div>
                  </div>
                </div>
                <div class="col-2 ms-2">
                  <button
                    type="button"
                    class="btn btn-sm esqepBtn"
                    title="clear"
                    (click)="clearSearchFilter(j)"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="p-1">
                <div
                  *ngFor="
                    let subject of hierarchyLevel.value.subjects;
                    let i = index;
                    trackBy: trackByFn
                  "
                  class="border-bottom p-0 my-1 subject"
                  [attr.id]="'subject-' + subject.id"
                  [hidden]="
                    levelsFormArray.at(j).value.search &&
                    levelsFormArray.at(j).value.search.id !== subject.id
                  "
                >
                  <div class="d-flex align-items-center flex-wrap mb-1">
                    <div class="col-10 col-md-8">
                      <button
                        type="button"
                        (click)="onSubjectClick(subject)"
                        class="btn text-start bg-none p-1 mb-1"
                        [ngClass]="
                          getSelectedSubjectButtonColour(
                            hierarchyLevel.value.selectedSubjectId,
                            subject.id
                          )
                        "
                        [attr.title]="subject.title"
                      >
                        {{ subject.title }}
                      </button>
                    </div>
                    <div class="col-2 col-md-1">
                      <i
                        *ngIf="isDimensionAssigned(subject.dimensionIds)"
                        class="fas fa-xl fa-check-circle text-success text-center"
                      ></i>
                    </div>
                    <div class="col-2 d-flex">
                      <button
                        title="Edit"
                        class="btn btn-sm me-1"
                        [ngClass]="getActionButtonColour(selectedDimensionId)"
                        (click)="
                          openSubjectModal(
                            hierarchyLevel.value.name,
                            subject.subjectLevelId,
                            subject
                          )
                        "
                      >
                        Edit
                      </button>
                      <button
                        title="Assign"
                        class="btn btn-sm"
                        [ngClass]="getActionButtonColour(selectedDimensionId)"
                        (click)="openSkillAssignmentModal(subject)"
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!hierarchyLevel.value.subjects.length">
                <p class="fst-italic m-1">
                  Nothing assigned at
                  <span class="fw-bold">{{ hierarchyLevel.value.name }}</span>
                  level.
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
