import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../../core/services/auth.service';
import { ConfigService } from '../../../core/services/config.service';
import { UitoolsService } from '../../../core/services/uitools.service';

import {
  catchError,
  concat,
  defaultIfEmpty,
  forkJoin,
  last,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from 'rxjs';
import {
  JobRoleDetail,
  JobRoleDetailType,
  JobRoleDotNet,
  JobRoleService,
} from '../../../job-role/services/job-role.service';
import {
  AuditData,
  AuditFilter,
  AuditService,
} from '../../../shared/audit-table/audit.service';
import { ProgressModalComponent } from '../../../shared/progress-modal/progress-modal.component';
import {
  JobRoleDetailTypeName,
  JobRoleRequirements,
  RoleManagementConfig,
} from './role-management.service';

@Component({
  selector: 'ug-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit, OnDestroy {
  exceptionData = {
    JOB_ROLE_DATA: {
      level: AlertLevels.ERROR,
      code: 'JRM-001',
      message: 'Error getting job role data',
    } as AlertData,
    JOB_ROLE_REQUIREMENTS: {
      level: AlertLevels.ERROR,
      code: 'JRM-002',
      message: `Error saving Job Role`,
    } as AlertData,
    JOB_ROLE_CREATE: {
      level: AlertLevels.ERROR,
      code: 'JRM-003',
      message: 'Error creating job role',
    } as AlertData,
    JOB_ROLE_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'JRM-004',
      message: 'Error updating job role',
    } as AlertData,
    JOB_ROLE_DETAIL_TYPES: {
      level: AlertLevels.ERROR,
      code: 'JRM-005',
      message: 'Error getting job role detail types',
    } as AlertData,
  };

  private fb = inject(FormBuilder);
  private uiService = inject(UitoolsService);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private jobRoleService = inject(JobRoleService);
  private configService = inject(ConfigService);
  private domSanitizer = inject(DomSanitizer);
  private ngbModal = inject(NgbModal);
  private auditService = inject(AuditService);
  private orgUnitsToSave: Array<number>;
  private peopleToSave: Array<number>;

  @ViewChild('nav') nav;

  activeTab = 1;
  jobRoleForm: FormGroup;
  idCodeList: Array<string> = [];
  jobRole: JobRoleDotNet;
  jobRoleDetails: Array<JobRoleDetail>;
  jobRoleId: number;
  creatingNewJobRole = false;
  allowEditPeople = false;
  allowEditReq = false;
  jobReqEventHasNoSessions = false;
  allowEditRole = false;
  isLoading = false;
  ngUnsubscribe: Subject<boolean> = new Subject();
  navItemList = {};
  navItemLength = 0;
  jobRoleCompetencies: Array<JobRoleRequirements>;
  jobRoleTrainings: Array<JobRoleRequirements>;
  initialFormValues: any;
  progressModal: any;
  discardChangesSubj = new Subject<boolean>();
  saveChangesSubj = new Subject<boolean>();
  jobRoleDetailTypes: Array<JobRoleDetailType>;
  updatePeople = false;
  updateOrgUnits = false;

  jobRoleSavedSubject: Subject<void> = new Subject<void>();
  createAnotherRole: boolean = false;
  detailsAudit: (pagedData: AuditFilter, id: number) => Observable<AuditData> =
    this.jobRoleService.getJobRoleAudit;
  assignmentAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData> = this.jobRoleService.getJobRolePeopleAudit;
  requirementAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData> = this.jobRoleService.getJobRoleRequirementsAudit;
  orgUnitAudit: (pagedData: AuditFilter, id: number) => Observable<AuditData> =
    this.jobRoleService.getJobRoleOrgUnitsAudit;

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.jobRoleForm.dirty) {
      return false;
    } else {
      return true;
    }
  }

  get competencies(): FormArray {
    return this.jobRoleForm.get('competencies') as FormArray;
  }

  get training(): FormArray {
    return this.jobRoleForm.get('training') as FormArray;
  }

  get detailsFormGroup(): FormGroup {
    return this.jobRoleForm.get('details') as FormGroup;
  }

  get competencyRawValues() {
    return this.competencies.getRawValue();
  }

  get dirtyCompetencies() {
    return this.competencies.controls.filter((c) => c.dirty);
  }

  get trainingRawValues() {
    return this.training.getRawValue();
  }

  get dirtyTraining() {
    return this.training.controls.filter((c) => c.dirty);
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.createForm();

    this.authService.loggedInUserSubj
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((liu: LoggedInUser) => {
        this.allowEditRole = [
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.JobRoleReqs,
          SecurityRoleKey.JobRolePeople,
          SecurityRoleKey.JobRoleReqsPeople,
        ].some((sr) => liu.roleIdentifier === sr);
        this.allowEditPeople = [
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.JobRolePeople,
          SecurityRoleKey.JobRoleReqsPeople,
        ].some((sr) => liu.roleIdentifier === sr);
        this.allowEditReq = [
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.JobRoleReqs,
          SecurityRoleKey.JobRoleReqsPeople,
        ].some((sr) => liu.roleIdentifier === sr);

        if (liu.roleIdentifier === SecurityRoleKey.OuResponsible) {
          this.jobRoleForm.disable({ emitEvent: false });
        }
      });

    this.navItemList = this.configService.roleManagementTabs
      .map((rmt, index) => ({ [rmt]: index + 1 }))
      .reduce((prev, navList) => ({ ...prev, ...navList }), {});

    this.navItemLength = Object.keys(this.navItemList).length;

    this.jobRole = {} as any;
    this.activatedRoute.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.jobRoleId = Number(params.id);
        if (this.jobRoleId === 0) {
          this.creatingNewJobRole = true;
          this.initialFormValues = this.jobRoleForm.value;
          this.jobRoleService
            .getJobRoleDetailTypes()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (dt) => {
                this.jobRoleDetailTypes = dt;
                this.isLoading = false;
              },
              error: (err) => {
                this.alertService.createAlert2(
                  this.exceptionData.JOB_ROLE_DETAIL_TYPES,
                  err,
                );
                this.isLoading = false;
              },
            });
        } else {
          this.creatingNewJobRole = false;

          const getJrObservables = [
            this.jobRoleService.getJobRoleDotNet(this.jobRoleId),
            this.jobRoleService.getJobRoleDetails(this.jobRoleId),
            this.jobRoleService.getJobRoleDetailTypes(),
          ];

          forkJoin(getJrObservables)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              map(([jobRole, jobRoleDetails, jrDetailTypes]) => ({
                jobRole,
                jobRoleDetails,
                jrDetailTypes,
              })),
            )
            .subscribe({
              next: (jro) => {
                this.jobRole = jro.jobRole as JobRoleDotNet;
                this.jobRoleDetails = jro.jobRoleDetails as JobRoleDetail[];
                this.jobRoleDetailTypes =
                  jro.jrDetailTypes as JobRoleDetailType[];
                this.setupJobRoleData(this.jobRole, true);
                this.isLoading = false;
              },
              error: (err) => {
                this.alertService.createAlert2(
                  this.exceptionData.JOB_ROLE_DATA,
                  err,
                );
                this.isLoading = false;
              },
            });

          this.getJobRoleRequirements(this.jobRoleId);
          this.checkForMissinEventSessions();
        }
      });
  }

  createForm() {
    this.createAnotherRole = false;
    this.jobRoleForm = this.fb.group({
      active: [true],
      competencies: this.fb.array([]),
      description: [
        null,
        Validators.maxLength(RoleManagementConfig.descriptionMaxNumbOfChars),
      ],
      filterId: [null],
      id: [null],
      locationId: [null],
      name: ['', Validators.required],
      orgUnits: this.fb.array([]),
      people: this.fb.array([]),
      training: this.fb.array([]),
      details: this.fb.group({
        about: [null],
        skillsAndExperience: [null],
        keyResponsibilities: [null],
        keyPerformanceIndicators: [null],
        regulatoryRequirements: [null],
      }),
      settings: this.fb.group({
        dueAfterValue: [null],
        dueAfterUnit: [null],
        newVersionDueValue: [null],
        newVersionDueUnit: [null],
        newHireDueValue: [null],
        newHireDueUnit: [null],
      }),
    });
  }

  setAdditionalDetailsValue(typeName, control) {
    const typeId = this.jobRoleDetailTypes.find(
      (dt) => dt.name === typeName,
    )?.id;
    let detail = null;
    if (typeId) {
      detail = this.jobRoleDetails?.find(
        (jrd) => jrd.jobRoleDetailTypeId === typeId,
      );
    }

    if (detail) {
      control.setValue(detail.detail);
    }
  }

  onSaveClick() {
    this.progressModal = this.ngbModal.open(ProgressModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    this.progressModal.componentInstance.modalTitle = this.creatingNewJobRole
      ? 'Creating Job Role'
      : 'Saving Job Role';
    this.creatingNewJobRole ? this.createNewRole() : this.updateJobRole();
    this.createAnotherRole = true;
  }

  createJobRole = () => {
    this.isLoading = true;
    this.router.navigate(['/job-role-management/0']);
    this.jobRoleForm.reset();
    this.competencies.clear();
    this.training.clear();
    this.createForm();

    this.jobRoleId = 0;
    this.getJobRoleRequirements(this.jobRoleId);
    this.creatingNewJobRole = true;
    this.initialFormValues = this.jobRoleForm.value;
    this.jobRoleService
      .getJobRoleDetailTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (dt) => {
          this.jobRoleDetailTypes = dt;
          this.isLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.JOB_ROLE_DETAIL_TYPES,
            err,
          );
          this.isLoading = false;
        },
      });
  };

  isControlValueEmptyHtml(controlValue: string) {
    return controlValue === '<p></p>';
  }

  backToJobRoles() {
    this.router.navigate([`job-roles-list`]);
  }

  updateJobRole() {
    let updateObservables: Array<Observable<any>> = [];
    const competenciesObservables: { [id: string]: Observable<any> } = {};
    const trainingObservables: { [id: string]: Observable<any> } = {};

    if (
      this.jobRoleForm.get('active').dirty &&
      this.jobRoleForm.get('active').value &&
      !this.jobRole.active
    ) {
      updateObservables.push(this.updateJobRoleBasic(true));
    }

    if (!this.creatingNewJobRole && this.jobRoleCoreDetailsDirty) {
      updateObservables.push(this.updateJobRoleBasic());
    }

    const details = [];

    if (this.detailsFormGroup.dirty) {
      Object.keys(this.detailsFormGroup.controls).forEach((controlName) => {
        const control = this.detailsFormGroup.get(controlName);
        if (control.value && !this.isControlValueEmptyHtml(control.value)) {
          details.push({
            jobRoleId: this.jobRoleId,
            jobRoleDetailTypeId: this.jobRoleDetailTypes.find(
              (dt) => dt.name === JobRoleDetailTypeName[controlName],
            ).id,
            detail: this.domSanitizer.sanitize(
              SecurityContext.HTML,
              control.value,
            ),
          });
        }
      });
    }

    if (details.length > 0) {
      updateObservables.push(
        this.jobRoleService.updateJobRoleDetails(this.jobRoleId, details).pipe(
          tap(() => this.progressModal.componentInstance.updatesDone++),
          catchError((err) => {
            this.progressModal.componentInstance.updatesFailed++;
            this.pushErrorToFailedSections('Details', err);
            return of({
              error: err.message,
            });
          }),
        ),
      );
    }

    if (this.updateOrgUnits) {
      updateObservables.push(
        this.jobRoleService
          .updateJobRolesOrgUnitsDotNet(this.jobRoleId, this.orgUnitsToSave)
          .pipe(
            tap(() => this.progressModal.componentInstance.updatesDone++),
            catchError((err) => {
              this.progressModal.componentInstance.updatesFailed++;
              this.pushErrorToFailedSections('Org Units', err);
              return of({
                error: err.message,
              });
            }),
          ),
      );
    }

    if (this.updatePeople) {
      updateObservables.push(
        this.jobRoleService
          .updateJobRolesPeopleDotNet(this.jobRoleId, this.peopleToSave)
          .pipe(
            tap(() => this.progressModal.componentInstance.updatesDone++),
            catchError((err) => {
              this.progressModal.componentInstance.updatesFailed++;
              this.pushErrorToFailedSections('People', err);
              return of({
                error: err.message,
              });
            }),
          ),
      );
    }

    if (this.dirtyCompetencies && this.dirtyCompetencies.length) {
      this.dirtyCompetencies.forEach((dcc) => {
        const c = this.competencyRawValues.find(
          (crv) => crv.skillId === dcc.value.skillId,
        );
        const requirementObject = {
          claimLevelId: c.claimLevelId,
          jobRoleId: this.jobRoleId,
          mandatory: c.mandatory,
          skillId: c.skillId,
          weighting: c.weighting,
        };

        let competencyItemApiCall;

        if (c.id) {
          competencyItemApiCall = c.pendingDeletion
            ? this.jobRoleService.deleteJobRoleRequirementById(c.id).pipe(
                tap(() => this.progressModal.componentInstance.updatesDone++),
                catchError((err) => {
                  this.progressModal.componentInstance.updatesFailed++;
                  return of({
                    error: err.message,
                  });
                }),
              )
            : this.jobRoleService
                .updateJobRoleRequirementById(requirementObject, c.id)
                .pipe(
                  tap(() => this.progressModal.componentInstance.updatesDone++),
                  catchError((err) => {
                    this.progressModal.componentInstance.updatesFailed++;
                    return of({
                      error: err.message,
                    });
                  }),
                );
        } else {
          competencyItemApiCall = this.jobRoleService
            .addJobRoleRequirement(requirementObject)
            .pipe(
              tap(() => this.progressModal.componentInstance.updatesDone++),
              catchError((err) => {
                this.progressModal.componentInstance.updatesFailed++;
                return of({
                  error: err.message,
                });
              }),
            );
        }

        competenciesObservables[c.skillId] = competencyItemApiCall;
      });
    }

    if (this.dirtyTraining && this.dirtyTraining.length) {
      this.dirtyTraining.forEach((dtc: FormGroup) => {
        const t = this.trainingRawValues.find(
          (trv) => trv.skillId === dtc.value.skillId,
        );

        //if the actual control has dirty values,  or if it is dirty because one of the curric children is dirty
        const isDirtyOrNewSkill =
          this.isAnyControlDirtyExcludingChildren(dtc) || t.id === null;
        if (isDirtyOrNewSkill) {
          trainingObservables[t.externalGuid] =
            this.createTrainingRequirementObservable(t);
        }

        if (dtc.value.children?.length > 0) {
          const childrenControls = dtc.get('children') as FormArray;
          dtc.value.children.forEach((child) => {
            const childControl = childrenControls.controls.find(
              (x) => x.value.skillId === child.skillId,
            );

            if (childControl.dirty) {
              trainingObservables[child.externalGuid] =
                this.createTrainingRequirementObservable(child);
            }
          });
        }
      });
    }

    this.progressModal.componentInstance.updatesTotal =
      (this.creatingNewJobRole ? 1 : 0) +
      updateObservables.length +
      Object.keys(competenciesObservables).length +
      Object.keys(trainingObservables).length;

    if (this.creatingNewJobRole) {
      this.progressModal.componentInstance.updatesDone++;
    }

    const competencies = forkJoin(competenciesObservables).pipe(
      defaultIfEmpty([]),
    );

    const training = forkJoin(trainingObservables).pipe(defaultIfEmpty([]));

    // update jr details and people and then update requirements.

    concat(...updateObservables.map((source$) => source$.pipe(last())))
      .pipe(toArray())
      .pipe(
        switchMap((updatesResults) => {
          return forkJoin([competencies, training]).pipe(
            map(([competenciesResults, trainingResults]) => {
              return { competenciesResults, trainingResults, updatesResults };
            }),
          );
        }),
      )
      .subscribe({
        next: ({ competenciesResults, trainingResults, updatesResults }) => {
          this.manageCompetenciesAfterUpsert(competenciesResults);
          this.manageTrainingAfterUpsert(trainingResults);
          this.jobRoleForm.markAsPristine();

          if (
            updatesResults &&
            (this.orgUnitsToSave ||
              this.peopleToSave ||
              this.jobRoleForm.get('filterId').dirty)
          ) {
            this.saveChangesSubj.next(true);
          }

          if (this.creatingNewJobRole) {
            this.updateUrl(this.jobRoleId);
          }

          if (this.progressModal.componentInstance.updatesFailed === 0) {
            this.uiService.showToast(
              `Successfully updated '${
                this.jobRoleForm.get('name').value
              }' role (id: ${this.jobRoleId})`,
              {
                classname: 'bg-success text-light',
                delay: 3000,
              },
            );
            this.updatePeople = false;
            this.updateOrgUnits = false;
            this.auditService.updateAuditHistory.next(true);
          }
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.JOB_ROLE_UPDATE,
            err,
          );
        },
      });
  }

  pushErrorToFailedSections(sectionName: string, err: Error) {
    this.progressModal.componentInstance.failedSections.push({
      sectionName: sectionName,
      description: err.message,
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  setTab() {
    this.nav.select(this.activeTab);
  }

  selectNextTab() {
    this.nav.select(this.activeTab++);
  }

  selectPreviousTab() {
    this.nav.select(this.activeTab--);
  }

  discardChanges() {
    this.jobRoleForm.get('active').reset(this.initialFormValues.active);
    this.jobRoleForm
      .get('description')
      .reset(this.initialFormValues.description);
    this.jobRoleForm.get('filterId').reset(this.initialFormValues.filterId);
    this.jobRoleForm.get('locationId').reset(this.initialFormValues.locationId);
    this.jobRoleForm.get('name').reset(this.initialFormValues.name);

    Object.keys(this.detailsFormGroup.controls).forEach((controlName) => {
      const control = this.detailsFormGroup.get(controlName);
      control.reset(this.initialFormValues.details[controlName]);
    });

    this.discardChangesSubj.next(true);
    this.updatePeople = false;
    this.updateOrgUnits = false;
    this.jobRoleForm.markAsPristine();
  }

  jobRoleOrgUnitsChanges(event) {
    this.orgUnitsToSave = event;
    this.updateOrgUnits = true;
    this.jobRoleForm.markAsDirty();
  }

  jobRolePeopleChanges(event) {
    this.peopleToSave = event;
    this.updatePeople = true;
    this.jobRoleForm.markAsDirty();
  }

  private getJobRoleRequirements(jobRoleId: number) {
    this.jobRoleService.getJobRoleRequirements(jobRoleId).subscribe({
      next: (requirements: Array<JobRoleRequirements>) => {
        this.jobRoleCompetencies = requirements.filter((r) => !r.externalGuid);
        this.jobRoleTrainings = requirements.filter((r) => r.externalGuid);
      },
      error: (err) => {
        this.alertService.createAlert2(
          this.exceptionData.JOB_ROLE_REQUIREMENTS,
          err,
        );
      },
    });
  }

  private manageCompetenciesAfterUpsert(competenciesResults) {
    const competenciesIds = Object.keys(competenciesResults);
    competenciesIds.forEach((f) => {
      const getIndex = this.jobRoleForm
        .get('competencies')
        .value.findIndex((x) => x.skillId.toString() === f);
      const competencyItem = this.jobRoleForm.get(['competencies', getIndex]);
      if (!competencyItem.value.id && !competenciesResults[f].error) {
        competencyItem.patchValue({
          id: competenciesResults[f].id,
        });
      }

      if (
        competencyItem.value.id &&
        !competenciesResults[f]?.error &&
        competencyItem.value.pendingDeletion
      ) {
        const formArray = this.jobRoleForm.get('competencies') as FormArray;
        formArray.removeAt(getIndex);
        this.jobRoleCompetencies = this.jobRoleCompetencies.filter(
          (x) => x.skillId.toString() !== f,
        );
      }

      if (competenciesResults[f] && competenciesResults[f].error) {
        competencyItem.setErrors(competenciesResults[f].error);
        this.pushRequirementsErrorToFailedSections(
          'Competencies',
          competenciesResults,
          f,
          competencyItem,
        );
      }
    });

    if (competenciesIds.length > 0) {
      this.jobRoleService.updateTrainingAuditHistory.next(true);
    }
  }

  private manageTrainingAfterUpsert(trainingResults) {
    const trainingResultsExternalIds = Object.keys(trainingResults);
    trainingResultsExternalIds.forEach((f) => {
      const getIndex = this.jobRoleForm
        .get('training')
        .value.findIndex(
          (x) => x.externalGuid.toLowerCase() === f.toLowerCase(),
        );

      //if the skill cannot be found, it means it is a child of a curriculum skill

      if (getIndex === -1) {
        const training = this.jobRoleForm.get('training') as FormArray;

        training.controls.forEach((c, i) => {
          const children = c.get('children') as FormArray;
          if (children.value.length > 0) {
            children.controls.forEach((child, j) => {
              if (child.value.externalGuid.toLowerCase() === f.toLowerCase()) {
                const trainingItem = children.get([j]);
                this.updateTrainingItem(trainingItem, trainingResults, f);
              }
            });
          }
        });
      } else {
        const trainingItem = this.jobRoleForm.get(['training', getIndex]);
        this.updateTrainingItem(trainingItem, trainingResults, f);
      }
    });

    if (trainingResultsExternalIds.length > 0) {
      this.jobRoleService.updateTrainingAuditHistory.next(true);
    }
  }

  updateTrainingItem(
    trainingItem: AbstractControl,
    trainingResults: any,
    externalGuid: string,
  ) {
    trainingItem.get('keepDueDates').patchValue(false);
    if (!trainingItem.value.id && !trainingResults[externalGuid].error) {
      trainingItem.patchValue({
        id: trainingResults[externalGuid].id,
        claimLevelId: trainingResults[externalGuid].claimLevelId,
      });
    }

    if (trainingResults[externalGuid].error) {
      trainingItem.setErrors(trainingResults[externalGuid].error);
      this.pushRequirementsErrorToFailedSections(
        'Training',
        trainingResults,
        externalGuid,
        trainingItem,
      );
    }
  }

  pushRequirementsErrorToFailedSections(
    sectionName: string,
    trainingResults: any,
    externalGuid: string,
    trainingItem: AbstractControl,
  ) {
    this.progressModal.componentInstance.failedSections.push({
      sectionName: sectionName,
      description: `${trainingItem.value.skillName}: ${trainingResults[externalGuid].error} `,
    });
  }

  private setupJobRoleData(jobRole: JobRoleDotNet, isInit: boolean = false) {
    this.jobRoleForm.get('id').setValue(jobRole.id);
    this.jobRoleForm.get('active').setValue(jobRole.active);
    this.jobRoleForm.get('description').setValue(jobRole.description);
    this.jobRoleForm.get('name').setValue(jobRole.name);
    this.jobRoleForm.get('locationId').setValue(jobRole.locationId);
    this.jobRoleForm.get('filterId').setValue(jobRole.filterId);
    this.jobRoleForm
      .get('settings.dueAfterValue')
      .setValue(jobRole.dueAfterValue);
    this.jobRoleForm
      .get('settings.dueAfterUnit')
      .setValue(jobRole.dueAfterUnit);
    this.jobRoleForm
      .get('settings.newVersionDueValue')
      .setValue(jobRole.newVersionDueValue);
    this.jobRoleForm
      .get('settings.newVersionDueUnit')
      .setValue(jobRole.newVersionDueUnit);
    this.jobRoleForm
      .get('settings.newHireDueValue')
      .setValue(jobRole.newHireDueValue);
    this.jobRoleForm
      .get('settings.newHireDueUnit')
      .setValue(jobRole.newHireDueUnit);

    if (this.jobRoleDetails) {
      Object.keys(this.detailsFormGroup.controls).forEach((controlName) => {
        const control = this.detailsFormGroup.get(controlName);
        this.setAdditionalDetailsValue(
          JobRoleDetailTypeName[controlName],
          control,
        );
      });
    }

    if (!this.allowEditReq) {
      this.competencies.controls.forEach((c) => {
        c.disable();
      });

      this.training.controls.forEach((c) => {
        c.disable();
      });
    }

    if (this.jobRoleForm.get('fgNewReq')) {
      this.jobRoleForm.removeControl('fgNewReq');
    }

    this.jobRoleForm.markAsPristine();
    this.updatePeople = false;
    this.updateOrgUnits = false;
    this.initialFormValues = this.jobRoleForm.value;
  }

  private updateJobRoleBasic(activeOnly: boolean = false): Observable<any> {
    const basicJobRoleObject = {
      name: activeOnly ? this.jobRole.name : this.jobRoleForm.get('name').value,
      description: activeOnly
        ? this.jobRole.description
        : this.jobRoleForm.get('description').value,
      filterId: activeOnly
        ? this.jobRole.filterId
        : this.jobRoleForm.get('filterId').value,
      locationId: activeOnly
        ? this.jobRole.locationId
        : this.jobRoleForm.get('locationId').value,
      active: this.jobRoleForm.get('active').value,
      dueAfterValue: this.jobRoleForm.get('settings.dueAfterValue').value,
      dueAfterUnit: this.jobRoleForm.get('settings.dueAfterUnit').value,
      newVersionDueValue: this.jobRoleForm.get('settings.newVersionDueValue')
        .value,
      newVersionDueUnit: this.jobRoleForm.get('settings.newVersionDueUnit')
        .value,
      newHireDueValue: this.jobRoleForm.get('settings.newHireDueValue').value,
      newHireDueUnit: this.jobRoleForm.get('settings.newHireDueUnit').value,
    };

    return this.jobRoleService
      .updateJobRoleDotNet(this.jobRoleId, basicJobRoleObject)
      .pipe(
        tap(() => this.progressModal.componentInstance.updatesDone++),
        catchError((err) => {
          this.progressModal.componentInstance.updatesFailed++;
          return of({
            error: err.message,
          });
        }),
      );
  }

  private createNewRole() {
    const newRoleObject = {
      name: this.jobRoleForm.get('name').value,
      description: this.jobRoleForm.get('description').value,
      filterId: this.jobRoleForm.get('filterId').value,
      locationId: this.jobRoleForm.get('locationId').value,
      active: this.jobRoleForm.get('active').value,
      dueAfterValue: this.jobRoleForm.get('settings.dueAfterValue').value,
      dueAfterUnit: this.jobRoleForm.get('settings.dueAfterUnit').value,
      newVersionDueValue: this.jobRoleForm.get('settings.newVersionDueValue')
        .value,
      newVersionDueUnit: this.jobRoleForm.get('settings.newVersionDueUnit')
        .value,
      newHireDueValue: this.jobRoleForm.get('settings.newHireDueValue').value,
      newHireDueUnit: this.jobRoleForm.get('settings.newHireDueUnit').value,
    };

    this.jobRoleService.createJobRoleDotNet(newRoleObject).subscribe({
      next: (s) => {
        this.jobRoleId = s.id;
        this.jobRoleForm.get('id').setValue(s.id);
        this.updateJobRole();
      },
      error: (err) => {
        this.progressModal.componentInstance.updatesFailed++;
        this.pushErrorToFailedSections('Create Role', err);
        this.alertService.createAlert2(this.exceptionData.JOB_ROLE_CREATE, err);
      },
    });
  }

  private updateUrl(id) {
    this.router.navigate([`../${id}`], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }

  // NEEDS REPLACING WITH DOT NET API
  private checkForMissinEventSessions() {
    this.jobRoleService
      .listJobRoleEventsWithoutSessions(this.jobRoleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((eventSkills) => {
        this.jobReqEventHasNoSessions = eventSkills.length > 0;
      });
  }

  get jobRoleCoreDetailsDirty(): boolean {
    return (
      this.jobRoleForm.get('description').dirty ||
      this.jobRoleForm.get('filterId').dirty ||
      this.jobRoleForm.get('locationId').dirty ||
      this.jobRoleForm.get('name').dirty ||
      (this.jobRoleForm.get('active').dirty &&
        this.jobRoleForm.get('active').value === false) ||
      this.jobRoleForm.get('settings').dirty
    );
  }

  isAnyControlDirtyExcludingChildren(form: FormGroup): boolean {
    const controls = form.controls;
    for (const key in controls) {
      if (controls.hasOwnProperty(key) && key !== 'children') {
        if (controls[key].dirty) {
          return true;
        }
      }
    }
    return false;
  }

  createTrainingRequirementObservable(requirement) {
    const requirementObject = {
      skillId: requirement.skillId,
      weighting: requirement.weighting,
      jobRoleId: this.jobRoleId,
      claimLevelId: requirement.claimLevelId,
      mandatory: requirement.mandatory,
      pendingDeletion: requirement.pendingDeletion,
      pendingActivation: requirement.pendingActivation,
      recurValue: requirement.recurUnit ? requirement.recurValue : null,
      recurUnit: requirement.recurValue ? requirement.recurUnit : null,
      dueAfterValue: requirement.dueAfterUnit
        ? requirement.dueAfterValue
        : null,
      dueAfterUnit: requirement.dueAfterValue ? requirement.dueAfterUnit : null,
      overridePriorDueDates: !requirement.keepDueDates,
      newVersionDueValue: requirement.newVersionDueUnit
        ? requirement.newVersionDueValue
        : null,
      newVersionDueUnit: requirement.newVersionDueValue
        ? requirement.newVersionDueUnit
        : null,
      newHireDueValue: requirement.newHireDueUnit
        ? requirement.newHireDueValue
        : null,
      newHireDueUnit: requirement.newHireDueValue
        ? requirement.newHireDueUnit
        : null,
    };

    return requirement.id
      ? this.jobRoleService
          .updateJobRoleRequirementById(requirementObject, requirement.id)
          .pipe(
            tap(() => this.progressModal.componentInstance.updatesDone++),
            catchError((err) => {
              this.progressModal.componentInstance.updatesFailed++;
              return of({ error: err.message });
            }),
          )
      : this.jobRoleService.addJobRoleRequirement(requirementObject).pipe(
          tap(() => this.progressModal.componentInstance.updatesDone++),
          catchError((err) => {
            this.progressModal.componentInstance.updatesFailed++;
            return of({ error: err.message });
          }),
        );
  }
}
