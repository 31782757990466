import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

const PROJECTS = [
  {
    project: 'Project Sparkles',
    category: 'A',
    description:
      "This project is dedicated to building out SQEPtech's overall product suite in the HR market",
    visibility: 'Sensitive',
    members: '10',
    status: 'On Target',
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2025-01-01T00:00:00.000Z',
  },
  {
    project: 'Build the World',
    category: 'B',
    description:
      'Dedicated to the development of two key products ; Expenses App and eSQEP',
    visibility: 'Sensitive',
    members: '10',
    status: 'At Risk',
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2025-01-01T00:00:00.000Z',
  },
  {
    project: 'ABC Bank CSOD Implementation',
    category: 'C',
    description: 'Modules: Recruitment, Onboarding, Performance, Core',
    visibility: 'Public',
    members: '8',
    status: 'Behind Schedule',
    startDate: '2024-09-23T00:00:00.000Z',
    endDate: '2026-06-07T00:00:00.000Z',
  },
  {
    project: 'Project Sparkles 2',
    category: 'A',
    description:
      "This project is dedicated to building out SQEPtech's overall product suite in the HR market",
    visibility: 'Sensitive',
    members: '10',
    status: 'Completed',
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2025-01-01T00:00:00.000Z',
  },
  {
    project: 'Build the World 2',
    category: 'B',
    description:
      'Dedicated to the development of two key products ; Expenses App and eSQEP',
    visibility: 'Sensitive',
    members: '10',
    status: 'Cancelled',
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2025-01-01T00:00:00.000Z',
  },
  {
    project: 'ABC Bank CSOD Implementation 2',
    category: 'C',
    description: 'Modules: Recruitment, Onboarding, Performance, Core',
    visibility: 'Public',
    members: '8',
    status: 'Paused',
    startDate: '2023-10-23T00:00:00.000Z',
    endDate: '2025-06-07T00:00:00.000Z',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private http = inject(HttpClient);

  projectOwners = new Subject<any>();
  projectVisible = new Subject<any>();
  phases = new Subject<any>();
  projectMembers = new Subject<any>();

  getProjects(): Observable<any[]> {
    return of(PROJECTS);
  }

  applicationSkillList = [
    {
      description:
        'Use engineering knowledge and understanding to apply technical and practical skills',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 33,
      funcAreaName: 'PEI - Engineering Technician',
      funcAreaTypeName: 'Discipline',
      id: 1001,
      level: 2,
      name: 'Knowledge',
      skillVersion: '1.0',
    },
    {
      description:
        'Contribute to the design, development, manufacture, construction, commissioning, operation or maintenance of products, equipment, processes, systems or services.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 33,
      funcAreaName: 'PEI - Engineering Technician',
      funcAreaTypeName: 'Discipline',
      id: 1002,
      level: 2,
      name: 'Methodology',
      skillVersion: '1.0',
    },
    {
      description: 'Accept and exercise personal responsibility.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 33,
      funcAreaName: 'PEI - Engineering Technician',
      funcAreaTypeName: 'Discipline',
      id: 1003,
      level: 2,
      name: 'Responsibility',
      skillVersion: '1.0',
    },
    {
      description: 'Use effective communication and interpersonal skills.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 33,
      funcAreaName: 'PEI - Engineering Technician',
      funcAreaTypeName: 'Discipline',
      id: 1004,
      level: 2,
      name: 'Interpersonal skills',
      skillVersion: '1.0',
    },
    {
      description:
        'Make a personal commitment to an appropriate code of professional conduct, recognising obligations to society, the profession and the environment.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 33,
      funcAreaName: 'PEI - Engineering Technician',
      funcAreaTypeName: 'Discipline',
      id: 1005,
      level: 2,
      name: 'Conduct',
      skillVersion: '1.0',
    },
    {
      description:
        'Use a combination of general and specialist engineering knowledge and understanding to apply existing and emerging technology.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 34,
      funcAreaName: 'PIE - Incorporated Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1006,
      level: 2,
      name: 'Knowledge',
      skillVersion: '1.0',
    },
    {
      description:
        'Apply appropriate theoretical and practical methods to design, develop, manufacture, construct, commission, operate, maintain, decommission and re-cycle engineering processes, systems, services and products.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 34,
      funcAreaName: 'PIE - Incorporated Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1007,
      level: 2,
      name: 'Methodology',
      skillVersion: '1.0',
    },
    {
      description: 'Provide technical and commercial management.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 34,
      funcAreaName: 'PIE - Incorporated Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1008,
      level: 2,
      name: 'Management',
      skillVersion: '1.0',
    },
    {
      description: 'Demonstrate effective interpersonal skills.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 34,
      funcAreaName: 'PIE - Incorporated Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1009,
      level: 2,
      name: 'Interpersonal skills',
      skillVersion: '1.0',
    },
    {
      description:
        'Demonstrate a personal commitment to professional standards, recognising obligations to society, the profession and the environment.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 34,
      funcAreaName: 'PIE - Incorporated Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1010,
      level: 2,
      name: 'Conduct',
      skillVersion: '1.0',
    },
    {
      description:
        'Use a combination of general and specialist engineering knowledge and understanding to optimise the application of existing and emerging technology.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 35,
      funcAreaName: 'PIE - Chartered Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1011,
      level: 2,
      name: 'Knowledge',
      skillVersion: '1.0',
    },
    {
      description:
        'Apply appropriate theoretical and practical methods to the analysis and solution of engineering problems. ',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 35,
      funcAreaName: 'PIE - Chartered Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1012,
      level: 2,
      name: 'Methodology',
      skillVersion: '1.0',
    },
    {
      description: 'Provide technical and commercial leadership.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 35,
      funcAreaName: 'PIE - Chartered Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1013,
      level: 2,
      name: 'Leadership',
      skillVersion: '1.0',
    },
    {
      description: 'Demonstrate effective interpersonal skills.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 35,
      funcAreaName: 'PIE - Chartered Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1014,
      level: 2,
      name: 'Interpersonal skills',
      skillVersion: '1.0',
    },
    {
      description:
        'Demonstrate a personal commitment to professional standards, recognising obligations to society, the profession and the environment.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Engineering Council',
      funcAreaId: 35,
      funcAreaName: 'PIE - Chartered Engineer',
      funcAreaTypeName: 'Discipline',
      id: 1015,
      level: 2,
      name: 'Conduct',
      skillVersion: '1.0',
    },
    {
      description: 'Description for Business Case Ownership',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'In-house Project management discipline',
      funcAreaId: 37,
      funcAreaName: 'Project Manager',
      funcAreaTypeName: 'Discipline',
      id: 1044,
      level: 2,
      name: 'Business Case Ownership',
      skillVersion: '1.0',
    },
    {
      description: 'Description for Change Control',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'In-house Project management discipline',
      funcAreaId: 37,
      funcAreaName: 'Project Manager',
      funcAreaTypeName: 'Discipline',
      id: 1045,
      level: 2,
      name: 'Change Control',
      skillVersion: '1.0',
    },
    {
      description: 'Description for Commercial Management',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'In-house Project management discipline',
      funcAreaId: 37,
      funcAreaName: 'Project Manager',
      funcAreaTypeName: 'Discipline',
      id: 1046,
      level: 2,
      name: 'Commercial Management',
      skillVersion: '1.0',
    },
    {
      description: 'Description for Corporate Strategy',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'In-house Project management discipline',
      funcAreaId: 37,
      funcAreaName: 'Project Manager',
      funcAreaTypeName: 'Discipline',
      id: 1047,
      level: 2,
      name: 'Corporate Strategy',
      skillVersion: '1.0',
    },
    {
      description: 'Description for Cost and Finance',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'In-house Project management discipline',
      funcAreaId: 37,
      funcAreaName: 'Project Manager',
      funcAreaTypeName: 'Discipline',
      id: 1048,
      level: 2,
      name: 'Cost and Finance',
      skillVersion: '1.0',
    },
  ];

  knowledgeSkillList = [
    {
      description:
        'The ability to promote the wider public good in all actions, and to act in a morally, legally and socially appropriate manner in dealings with stakeholders and members of project teams and the organisation.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1016,
      level: 0,
      name: '(APM) Ethics, compliance and professionalism',
      skillVersion: '1.0',
    },
    {
      description: 'The ability to select, develop and manage teams.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1017,
      level: 0,
      name: '(APM) Team management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to identify, address and resolve differences between individuals and/or interest groups.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1018,
      level: 0,
      name: '(APM) Conflict management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to empower and inspire others to deliver successful change initiatives.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1019,
      level: 0,
      name: '(APM) Leadership',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to secure the provision of resources needed for change initiative(s) from internal and/or external providers.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1020,
      level: 0,
      name: '(APM) Procurement',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to agree contracts for the provision of goods and/or services, to monitor compliance, and to manage variances.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1021,
      level: 0,
      name: '(APM) Contract management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to prepare and maintain definitions of the requirements of change initiatives.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1022,
      level: 0,
      name: '(APM) Requirements management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to determine the best means of satisfying requirements within the context of the change initiative’s objectives and constraints.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1023,
      level: 0,
      name: '(APM) Solutions development',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to prepare and maintain schedules for activities and events for change initiatives, taking account of dependencies and resource requirements.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1024,
      level: 0,
      name: '(APM) Schedule management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to develop, implement and update resource allocation plans (other than finance) for change initiatives, taking account of availabilities and scheduling.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1025,
      level: 0,
      name: '(APM) Resource management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to develop and agree budgets for change initiatives, and to control forecast and actual costs against the budgets.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1026,
      level: 0,
      name: '(APM) Budgeting and cost control',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to identify and monitor risks (threats and opportunities), to plan and implement responses to those risks, and respond to other issues that affect the change initiative.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1027,
      level: 0,
      name: '(APM) Risk and issue management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to develop, maintain and apply quality management processes for change initiative activities and outputs.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1028,
      level: 0,
      name: '(APM) Quality management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to consolidate and document the fundamental components of a change initiative: scope; schedule; resource requirements; budgets; risks, opportunities and issues; and quality requirements.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1029,
      level: 0,
      name: '(APM) Consolidated planning',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to manage the integration of the outputs of change initiatives into business-as-usual, addressing the readiness of users, compatibility of work systems and the realisation of benefits.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1030,
      level: 0,
      name: '(APM) Transition management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to plan and control the finances of programmes or portfolios and their related change initiatives, as a means of driving performance and as part of the organisation’s overall financial management.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1031,
      level: 0,
      name: '(APM) Financial management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to prepare and maintain an overall schedule for resource use in related change initiatives, which avoids bottlenecks and conflicting demands, and which sequences outcomes in order to enable the efficient realisation of benefits.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1032,
      level: 0,
      name: '(APM) Resource capacity planning',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to establish and maintain governance structures that define clear roles, responsibilities and accountabilities for governance and delivery of change initiatives, and that align with organisational practice.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1033,
      level: 0,
      name: '(APM) Governance arrangements',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to manage stakeholders, taking account of their levels of influence and particular interests.',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1034,
      level: 0,
      name: '(APM) Stakeholder and communications management',
      skillVersion: '1.0',
    },
    {
      description:
        'The ability to identify and/or develop frameworks and methodologies that will ensure management of change initiatives will be comprehensive and consistent across different initiatives. (In this context ‘framework’ means the parameters, constraints or rule',
      externalGUID: null,
      externalOwner: null,
      externalProvider: null,
      externalType: null,
      funcAreaDesc: 'Qualifications and learning',
      funcAreaId: 41,
      funcAreaName: 'Qualifications',
      funcAreaTypeName: 'Function',
      id: 1035,
      level: 0,
      name: '(APM) Frameworks and methodologies',
      skillVersion: '1.0',
    },
  ];
}
