<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <span>{{ pageTitle }}</span>
      <div class="float-md-end mb-1">
        <a class="btn btn-outline-danger btn-sm" (click)="cancel()">Cancel</a>
        <a class="btn btn-outline-success btn-sm" (click)="save()">Save</a>
      </div>
    </h5>
    <div class="row mb-3">
      <div class="col-6">
        <input
          class="form-control form-control-sm"
          type="text"
          placeholder="Name"
          [(ngModel)]="name"
        />
      </div>
    </div>
    <!-- 
        <div class="row mb-3">
            <div class="col-6">
                <input class="form-control form-control-sm" type="text" placeholder="Identifier" [(ngModel)]="identifier" />
            </div>
        </div>
 -->
    <div class="row mb-3">
      <div class="col-6">
        <textarea
          class="form-control form-control-sm"
          type="text"
          placeholder="Description"
          [(ngModel)]="description"
        ></textarea>
      </div>
    </div>
  </div>
</div>
