import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertLevels, AlertService } from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';
import {
  JobRoleService,
  PersonJobRoleLookup,
  PersonOrAllRoleOptions,
} from '../../job-role/services/job-role.service';
import { JobRoleAnalysis } from '../job-role-analysis/job-role-analysis.component';

@Component({
  selector: 'ug-job-role-person',
  templateUrl: './job-role-person.component.html',
  styleUrls: ['./job-role-person.component.scss'],
})
export class JobRolePersonComponent implements OnInit {
  authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private configService = inject(ConfigService);
  private jobRoleService = inject(JobRoleService);
  private alertService = inject(AlertService);
  private destroyRef = inject(DestroyRef);

  isPlotLoading = true;
  personJobRoles: PersonJobRoleLookup[];
  allJobRoles: Array<any>;
  selectedRole: any;
  hasPlottingData = false;
  roleProfile: JobRoleAnalysis[] = [];
  activeTab = 1;
  jobRoleSearchForm: FormGroup;
  personOrAllRoleOptions = PersonOrAllRoleOptions;
  personOrAllRolesSelection = this.personOrAllRoleOptions.PERSON_ROLES;
  isLoading = false;
  dropdownJobRoles = [];
  dropdownBinding: { label: string; value: string };

  get disableJobRoleSearch(): boolean {
    return this.configService.disableAllJobRolesSearch;
  }

  get includeProfileMatch() {
    return (
      this.configService.roleManagementTabs.includes('competencies') ||
      this.configService.roleManagementTabs.includes('training')
    );
  }

  ngOnInit(): void {
    this.dropdownBinding =
      this.jobRoleService.jobRoleProfileDropdownBinding.get('personRole');

    this.jobRoleSearchForm = this.formBuilder.group({
      jobRole: [],
    });

    this.isLoading = true;

    this.jobRoleService
      .getJobRolesByPersonId(this.me.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (personJobRoles: PersonJobRoleLookup[]) => {
          this.personJobRoles = personJobRoles;
          this.dropdownJobRoles = this.personJobRoles;
          if (this.personJobRoles.length > 0) {
            this.selectedRole = this.personJobRoles[0]?.key ?? null;
            this.loadProfile();
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRP-001',
              message: 'Unable to retrieve person roles',
            },
            err,
          );
          this.isLoading = false;
        },
      });

    this.jobRoleService
      .getJobRolesDotNet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (jrl) => {
          this.allJobRoles = jrl;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRP-002',
              message: 'Unable to retrieve all job roles',
            },
            err,
          );
        },
      });
  }

  get me(): LoggedInUser {
    return this.authService.me;
  }

  loadProfile(): void {
    this.isPlotLoading = true;

    this.jobRoleService
      .getJobRoleAnalysisByJobRoleIdAndPersonId(
        Number(this.selectedRole),
        this.me.id,
      )
      .subscribe({
        next: (jrp) => {
          const personJobRoles = jrp;
          personJobRoles.forEach((pjr) => {
            pjr.isAdditional = true;
            pjr.people.forEach((p) => {
              p.skills.forEach((s) => {
                const skillWeightedInfo = this.getWeightedScore(s);
                s.personSkillWeightedScore =
                  skillWeightedInfo.personWeightedScore;
                s.personSkillWeightedScorePercentage =
                  skillWeightedInfo.personWeightedScorePercentage;
                s.roleSkillWeightedScore = skillWeightedInfo.roleWeightedScore;
                s.roleSkillWeightedScorePercentage =
                  skillWeightedInfo.roleWeightedScorePercentage;
                s.maxLevelWeighted = skillWeightedInfo.maxLevelWeighted;
              });
            });
          });
          this.roleProfile = personJobRoles;
          this.isPlotLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRP-003',
              message: 'Unable to retrieve job role vs person data',
            },
            err,
          );
          this.isPlotLoading = false;
        },
      });
  }

  getWeightedScore(skill: any) {
    const maxLevelWeighted =
      skill.claimLevelScore > skill.requirementLevelScore
        ? skill.claimLevelScore * skill.requirementWeighting
        : skill.requirementLevelScore * skill.requirementWeighting;

    // person level
    const personWeightedScore =
      skill.claimLevelScore * skill.requirementWeighting;
    const personWeightedScorePercentage =
      (personWeightedScore / maxLevelWeighted) * 100;
    const roleWeightedScore =
      skill.requirementLevelScore * skill.requirementWeighting;
    const roleWeightedScorePercentage =
      (roleWeightedScore / maxLevelWeighted) * 100;

    return {
      personWeightedScore,
      personWeightedScorePercentage,
      roleWeightedScore,
      roleWeightedScorePercentage,
      maxLevelWeighted,
    };
  }

  onRadioChange(event) {
    if (
      this.personOrAllRolesSelection ===
      this.personOrAllRoleOptions.PERSON_ROLES
    ) {
      this.dropdownBinding =
        this.jobRoleService.jobRoleProfileDropdownBinding.get('personRole');
      this.dropdownJobRoles = this.personJobRoles;
      if (this.personJobRoles.length > 0) {
        this.selectedRole = this.personJobRoles[0].key;
        this.loadProfile();
      } else {
        this.selectedRole = null;
      }
    } else {
      this.dropdownJobRoles = this.allJobRoles;
      this.dropdownBinding =
        this.jobRoleService.jobRoleProfileDropdownBinding.get('allRoles');
      if (this.allJobRoles.length > 0) {
        this.selectedRole = this.allJobRoles[0].id;
        this.loadProfile();
      } else {
        this.selectedRole = null;
      }
    }
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }
}
