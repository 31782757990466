<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <div class="card-body">
        <div *ngIf="viewData.editMode">
          <h5 class="card-title">Assessment Request Detail</h5>
          <div class="table-responsive">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" class="w-10">Assessment Request Id</th>
                  <th scope="col" class="w-10">Type</th>
                  <th scope="col" class="w-15">Competency Subcategory</th>
                  <th scope="col" class="w-15">Competency</th>
                  <th scope="col" class="w-10">Status</th>
                  <th scope="col" class="w-15">Claimant</th>
                  <th scope="col" class="w-15">Assessor</th>
                  <th scope="col" class="w-10"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{ claimData?.claim?.id }}</th>
                  <td>{{ claimData.claim?.competencyDimensionName }}</td>
                  <td>{{ claimData?.skill?.funcAreaName }}</td>
                  <td>{{ claimData?.skill?.name }}</td>
                  <td>{{ claimData?.claim?.statusText }}</td>
                  <td>{{ claimData?.claim?.personName }}</td>
                  <td>{{ claimData?.assessor?.personFamilyGivenName }}</td>
                  <td>
                    <!-- <span data-bs-toggle="collapse" data-bs-target="#historyTable"> -->
                    <span>
                      <!-- <button type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="button" aria-pressed="false" (click)="toggleClaimHistory()"> -->
                      <button
                        type="button"
                        class="btn btn-sm esqepBtn"
                        aria-pressed="false"
                        data-bs-toggle="collapse"
                        data-bs-target="#historyTable"
                        (click)="toggleClaimHistory()"
                      >
                        <span class="fas fa-history"></span>
                        {{ viewData.showClaimHistory ? 'Hide' : 'Show' }}
                        History
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="collapse" id="historyTable">
            <h5 class="card-title">History</h5>
            <esqep-history-table [claim]="claim"></esqep-history-table>
          </div>
        </div>
        <form [formGroup]="submissionForm">
          <div
            ngbNav
            #nav="ngbNav"
            [(activeId)]="currentTab"
            class="nav-pills"
            (navChange)="onNavChange($event)"
            [orientation]="'horizontal'"
          >
            <ng-container [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Competency Attribute </span>
                <span
                  class="{{
                    detailTabStatusClass(
                      submissionForm.get('detail.competency'),
                      viewData.editMode
                    )
                  }}"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div id="comp-attr-pane" formGroupName="detail">
                  <esqep-competency-attr-form
                    [parentFormGroup]="this.submissionForm.get('detail')"
                    [editMode]="viewData.editMode"
                    [selectedCompDimension]="selectedCompDimension"
                    [selectedJobFamily]="selectedJobFamily"
                    [(selectedSkill)]="selectedSkill"
                  ></esqep-competency-attr-form>
                  <p *ngIf="authService.diagShow">{{ selectedSkill | json }}</p>
                </div>
              </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Level and Assessor</span>
                <span
                  class="{{
                    detailTabStatusClass(
                      submissionForm.get('detail.levelAndVerifier'),
                      viewData.editMode
                    )
                  }}"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div id="level-verifier-pane" formGroupName="detail">
                  <esqep-level-and-verifier
                    [parentFormGroup]="this.submissionForm.get('detail')"
                    [editMode]="viewData.editMode"
                    [claimantView]="viewData.claimantView"
                    [claim]="claim"
                    [competency]="selectedSkill"
                    [(level)]="selectedClaimLevel"
                    [(verifier)]="selectedAssessor"
                    [verifierChangeText]="verifierChangeText"
                  ></esqep-level-and-verifier>
                </div>
              </ng-template>
            </ng-container>

            <ng-container [ngbNavItem]="3">
              <a ngbNavLink>
                <span class="">Evidence </span>
                <span
                  class="{{
                    detailTabStatusClass(
                      submissionForm.get('detail.evidence'),
                      viewData.editMode
                    )
                  }}"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div id="evidence-pane">
                  <esqep-evidence
                    [parentFormGroup]="this.submissionForm.get('detail')"
                    [editMode]="viewData.editMode"
                    [evidenceStatementText]="evidenceText"
                    [evidenceList]="evidenceList"
                    [claimId]="claim?.id"
                    [claimantView]="viewData.claimantView"
                  ></esqep-evidence>
                  <p *ngIf="authService.diagShow">
                    Claimant View: {{ viewData.claimantView }}
                  </p>
                  <p *ngIf="authService.diagShow">
                    Statement changed:
                    {{
                      submissionForm.get('detail.evidence.statementText').dirty
                    }}
                  </p>
                  <p *ngIf="authService.diagShow">
                    Dirty: {{ submissionForm.get('detail.evidence').dirty }}
                  </p>
                </div>
              </ng-template>
            </ng-container>

            <ng-container [ngbNavItem]="4">
              <a ngbNavLink>
                <span class="">{{
                  viewData.claimantView ? 'Review & Save ' : 'Verification '
                }}</span>
                <span class="{{ actionTabStatusClass() }}"></span>
              </a>
              <ng-template ngbNavContent>
                <div id="summary-verification-pane">
                  <div *ngIf="viewData.claimantView; else verifierTab">
                    <esqep-summary-action-form
                      [parentFormGroup]="submissionForm.get('action')"
                      [detailFormGroup]="submissionForm.get('detail')"
                      [claim]="claim"
                      [selectedClaimLevel]="selectedClaimLevel"
                      [selectedCompAttr]="selectedSkill"
                      [selectedVerifier]="selectedAssessor"
                      [evidenceText]="evidenceText"
                      [(action)]="claimantAction"
                      (actionCancel)="onActionCancel()"
                      (actionSubmit)="onSubmitActionClick()"
                    ></esqep-summary-action-form>
                    <p *ngIf="authService.diagShow">
                      {{
                        submissionForm.get(
                          'action.detail.claimantAction.claimAction'
                        ).value | json
                      }}
                    </p>
                  </div>
                  <ng-template #verifierTab>
                    <esqep-verifier-action-form
                      [parentFormGroup]="submissionForm.get('action')"
                      [detailFormGroup]="submissionForm.get('detail')"
                      [claim]="claim"
                      [(action)]="verifierAction"
                      (actionCancel)="onActionCancel()"
                      (actionSubmit)="onSubmitActionClick()"
                      (tabLinkClick)="tabClick($event)"
                    ></esqep-verifier-action-form>
                    <p *ngIf="authService.diagShow">
                      {{
                        submissionForm.get(
                          'action.detail.verification.claimAction'
                        ).value | json
                      }}
                    </p>
                  </ng-template>
                </div>
              </ng-template>
            </ng-container>

            <div
              class="btn-group ms-3 d-none d-md-inline-flex"
              role="group"
              aria-label="navigation"
            >
              <button
                [ngClass]="{ disabled: currentTab === 1 }"
                (click)="tabClick(currentTab - 1)"
                class="btn btn-outline-dark"
              >
                <span class="fas fa-arrow-left"></span>
              </button>
              <button
                [ngClass]="{ disabled: currentTab === 4 }"
                (click)="tabClick(currentTab + 1)"
                class="btn btn-outline-dark"
              >
                <span class="fas fa-arrow-right"></span>
              </button>
            </div>

            <div class="ms-3">
              <span *ngIf="viewData.buttons.modify.visible()"
                ><button
                  type="button"
                  class="btn btn-outline-success"
                  [class.disabled]="viewData.buttons.modify.disabled()"
                  (click)="modifyClaim()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Save changes"
                >
                  Save
                </button></span
              >
              <span *ngIf="viewData.buttons.save.visible()"
                ><button
                  type="button"
                  class="btn btn-outline-success"
                  [class.disabled]="viewData.buttons.save.disabled()"
                  (click)="createClaim()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Create CAR"
                >
                  Save
                </button></span
              >
              <span *ngIf="viewData.buttons.discard.visible()"
                ><button
                  type="button"
                  class="btn btn-outline-danger"
                  [class.disabled]="viewData.buttons.discard.disabled()"
                  (click)="discardChanges()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Discard changes and revert to previous state"
                >
                  <span class="">Discard</span>
                </button></span
              >
              <span *ngIf="viewData.buttons.create.visible()"
                ><a
                  class="btn esqepBtn"
                  [class.disabled]="viewData.buttons.create.disabled()"
                  routerLink="/assessment-request/new"
                  routerLinkActive="active"
                  ><span
                    class=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Create a brand new CAR"
                    >Create New CAR</span
                  ></a
                ></span
              >
              <span *ngIf="viewData.buttons.cancel.visible()"
                ><a
                  class="btn btn-outline-danger"
                  routerLink="/home"
                  routerLinkActive="active"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Abandon CAR and return to home screen"
                  ><span class="">Cancel</span></a
                ></span
              >
            </div>
            <div [ngbNavOutlet]="nav" class="w-100"></div>
          </div>
        </form>
        <div *ngIf="authService.diagShow" class="small">
          <div class="row">
            <div class="col-md-1">Assessment request data</div>
            <div class="col-md-11">{{ this.claimData | json }}</div>
          </div>
          <div class="row">
            <div class="col-md-1">Save Assessment request data</div>
            <div class="col-md-11">{{ this.saveClaimData | json }}</div>
          </div>
          <div class="row">
            <div class="col-md-1">Evidence</div>
          </div>
          <div class="row">
            <div class="col-md-1">View Data</div>
            <div class="col-md-11">{{ this.viewData | json }}</div>
          </div>
          <div class="row">
            <div class="col-md-1">Current (active) tab</div>
            <div class="col-md-11">{{ this.currentTab }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
