<div
  class="card border-{{ highlight }}"
  [ngClass]="{ 'border-top border-3': highlight }"
>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <h5
          *ngIf="cardTitle"
          class="text-sm mb-0 text-capitalize font-weight-bold card-main-title"
        >
          {{ cardTitle }}
        </h5>
        <p class="font-weight-bolder mb-0 card-main-content">
          {{ cardContent }}
        </p>
      </div>
      <div *ngIf="icon" class="col-4 icon text-end">
        <div
          class="icon-shape bg-gradient-primary text-center border-radius-md"
        >
          <i class="{{ icon }} text-lg text-{{ highlight }}"></i>
        </div>
      </div>
    </div>
  </div>
</div>
