import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, takeUntil } from 'rxjs';
import { TypeaheadService } from '../../../controls/dropdown-select/typeahead.service';
import { TextboxFilter } from '../../../controls/dynamic-form/control-types/filter-textbox';
import { TypeaheadFilter } from '../../../controls/dynamic-form/control-types/filter-typeahead';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  ExternalActivePerson,
  PersonService,
} from '../../../person/services/person.service';

@Component({
  selector: 'ug-add-attendees',
  templateUrl: './add-attendees.component.html',
  styleUrls: ['./add-attendees.component.scss'],
})
export class AddAttendeesComponent implements OnInit, OnDestroy {
  modal = inject(NgbActiveModal);
  fb = inject(FormBuilder);
  private personService = inject(PersonService);
  private alertService = inject(AlertService);
  private typeaheadService = inject(TypeaheadService);

  @Output() peopleAddedToSession: EventEmitter<any> = new EventEmitter();
  @Input() peopleIdsInSession: number[] = [];
  tableLoading = true;
  orgUnitSearch: OperatorFunction<string, string[]>;
  locationsSearch: OperatorFunction<string, string[]>;
  roleSearch: OperatorFunction<string, string[]>;

  private ngUnsubscribe: Subject<boolean> = new Subject();

  activeIcon = (row) => {
    return row.attending === true
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  headers: Array<TableHeader> = [
    { id: 'displayName', title: 'Name', class: 'w-10' },
    { id: 'location', title: 'Location', class: 'w-25' },
    { id: 'orgUnitNames', title: 'Organisation units', class: 'w-25' },
    { id: 'jobRoleNames', title: 'Roles', class: 'w-30' },
    {
      id: 'attending',
      title: 'Attending',
      iconFunction: this.activeIcon,
      class: 'text-center',
    },
  ];

  filters: Array<FilterBase<any>>;
  selectedButtons: Array<TableSelectedButton>;
  tableRows: Array<any>;

  sort(a: any, b: any, text: string) {
    if (a && b) {
      return a.startsWith(text) - b.startsWith(text) || b - a;
    }
    return null;
  }

  compare(items: string, input: string) {
    if (items) {
      return items.toLowerCase().includes(input);
    }
    return false;
  }

  formatter = (result) => result;

  ngOnInit(): void {
    this.tableLoading = true;

    this.personService
      .getExternalActivePeople()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (people: ExternalActivePerson[]) => {
          const allLocations = people.map(
            (c) => (c.location = !c.location ? '' : c.location),
          );
          const locations = [...new Set(allLocations)];
          this.locationsSearch = this.typeaheadService.typeahead(
            locations,
            this.compare,
            this.sort,
          );

          const attendeeOrgUnit = people.map((c) => c.orgUnitNames.split(','));
          const allOrgUnits = attendeeOrgUnit.reduce((carry, current) => [
            ...carry,
            ...current,
          ]);
          const uniqueOrgUnits = [...new Set(allOrgUnits)];
          this.orgUnitSearch = this.typeaheadService.typeahead(
            uniqueOrgUnits,
            this.compare,
            this.sort,
          );

          const attendeeRoles = people.map((c) => c.jobRoleNames.split(','));
          const allRoles = attendeeRoles.reduce((carry, current) => [
            ...carry,
            ...current,
          ]);
          const uniqueRoles = [...new Set(allRoles)];
          this.roleSearch = this.typeaheadService.typeahead(
            uniqueRoles,
            this.compare,
            this.sort,
          );

          this.filters = [
            new TextboxFilter({
              key: 'searchTerm',
              label: 'Refine by term',
              order: 1,
              placeholder: 'Filter by term',
              filterClass: 'col-12 col-lg mt-2',
            }),
            new TypeaheadFilter({
              key: 'location',
              label: 'Refine by location',
              typeAheadSearch: this.locationsSearch,
              resultFormatter: this.formatter,
              inputFormatter: this.formatter,
              inLineGroup: false,
              order: 2,
              filterClass: 'col-12 col-lg mt-2',
            }),
            new TypeaheadFilter({
              key: 'orgUnitNames',
              label: 'Refine by organisation unit',
              typeAheadSearch: this.orgUnitSearch,
              resultFormatter: this.formatter,
              inputFormatter: this.formatter,
              inLineGroup: false,
              order: 3,
              filterClass: 'col-12 col-lg mt-2',
            }),
            new TypeaheadFilter({
              key: 'jobRoleNames',
              label: 'Refine by role',
              typeAheadSearch: this.roleSearch,
              resultFormatter: this.formatter,
              inputFormatter: this.formatter,
              inLineGroup: false,
              order: 4,
              filterClass: 'col-12 col-lg mt-2',
            }),
          ];

          this.selectedButtons = [
            {
              title: 'Cancel',
              class: 'btn-outline-secondary',
              function: this.closeModal,
              cancelButton: true,
            },
            {
              title: 'Add Selected',
              class: 'btn-success',
              function: this.addSelected,
            },
          ];

          this.tableRows = people.map((person) => {
            return {
              ...person,
              attending: this.peopleIdsInSession.includes(person.id),
            };
          });
          this.tableLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRA-001',
              message: 'Error retrieving people list',
            } as AlertData,
            err,
          );
          this.tableLoading = false;
        },
      });
  }

  closeModal() {
    this.modal.close();
  }

  addSelected = (selected) => {
    this.peopleAddedToSession.emit(selected);
    this.modal.close();
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
