<div class="card h-100 shadow overflow-visible">
  <div class="card-body">
    <h5 class="card-title">{{ cardTitle }}</h5>
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading">
      <div *ngIf="hasJobRole">
        <div class="row mb-3">
          <div class="col">
            <ng-select
              [(ngModel)]="selectedRole"
              id="jobRoleFilterControl"
              appearance="outline"
              [searchable]="false"
              [clearable]="false"
              (ngModelChange)="loadProfile()"
            >
              <ng-option *ngFor="let role of jobRoles" [value]="role.key">{{
                role.name
              }}</ng-option>
            </ng-select>
          </div>
        </div>

        <ug-list-status
          [isLoading]="isPlotLoading"
          [listLength]="[roleProfile].length"
        ></ug-list-status>
        <ng-container *ngIf="!isPlotLoading">
          <ng-container *ngIf="hasPlottingData; else noPlotData">
            <div>
              <ug-role-person-score
                *ngIf="roleProfile.length"
                [jobRolePersonScore]="
                  roleProfile[0].people[0].jobRoleLevelScore || 0
                "
              ></ug-role-person-score>
            </div>

            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-link swap-profile-view"
                  data-bs-toggle="modal"
                  data-bs-target="#myJobRoleModal"
                >
                  <i class="fas text-primary fa-random"></i>
                </button>
                <ug-role-across-people
                  *ngIf="roleProfile"
                  [jobRolePeople]="roleProfile[0].people"
                  [legendPosition]="'bottom'"
                  [showDataSlider]="false"
                ></ug-role-across-people>
              </div>
            </div>
          </ng-container>
          <ng-template #noPlotData>
            <ug-role-person-score
              *ngIf="roleProfile.length"
              [jobRolePersonScore]="
                roleProfile[0].people[0].jobRoleLevelScore || 0
              "
            ></ug-role-person-score>
            <p>
              You have no competencies or training to plot for the selected
              role.
            </p>
            <ug-role-competency-summary
              *ngIf="roleProfile.length; else noProfileMatch"
              [jobRolePerson]="roleProfile[0].people[0]"
              [showGraph]="false"
              [showProgressBar]="false"
            >
            </ug-role-competency-summary>
            <ng-template #noProfileMatch>
              <div class="alert alert-info" role="alert">
                This role has no requirements assigned to it.
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12" *ngIf="!hasJobRole">
          <div class="alert alert-info">
            <span class="fas fa-exclamation-circle"></span> No role assigned
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isLoading && hasJobRole"
  class="modal fade"
  id="myJobRoleModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">
            My Job Role Profile - {{ roleProfile[0]?.jobRoleName }}
          </h5>
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-2">
          <ug-role-person-score
            *ngIf="roleProfile.length"
            [jobRolePersonScore]="
              roleProfile[0].people[0].jobRoleLevelScore || 0
            "
          ></ug-role-person-score>
        </div>
        <ug-role-competency-summary
          *ngIf="roleProfile.length; else noProfileMatch"
          [jobRolePerson]="roleProfile[0].people[0]"
          [showGraph]="false"
          [showProgressBar]="false"
        >
        </ug-role-competency-summary>
        <ng-template #noProfileMatch>
          <div class="alert alert-info" role="alert">
            This role has no requirements assigned to it.
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
