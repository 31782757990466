import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { JobRoleAnalysisComponent } from '../job-role/job-role-analysis/job-role-analysis.component';
import { JobRoleCompareComponent } from '../job-role/job-role-compare/job-role-compare.component';
import { JobRoleItemComponent } from './job-role-item/job-role-item.component';
import { JobRoleListComponent } from './job-role-list/job-role-list.component';
import { RoleManagementComponent } from './job-role-list/role-management/role-management.component';
import { JobRolePersonComponent } from './job-role-person/job-role-person.component';
import { JobRoleSettingsComponent } from './job-role-settings/job-role-settings.component';

const routes: Routes = [
  {
    path: 'job-role/:jobRoleId/person/:personId',
    component: JobRoleCompareComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  },
  {
    path: 'job-role-analysis',
    component: JobRoleAnalysisComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ANALYSIS_JOB-ROLES',
      title: 'Job Role / People Match Analysis',
    },
  },
  {
    path: 'job-roles-list',
    component: JobRoleListComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ADMIN_JOB-ROLES-LIST',
      title: 'Role Management',
      helpUrl:
        'https://scribehow.com/shared/Job_Role_Management__3TsLYsKnSN2VHKuOvnZLfA',
    },
  },
  {
    path: 'job-role-item/:id',
    component: JobRoleItemComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: { routeIdentifier: 'ADMIN_JOB-ROLES-LIST' },
  },
  {
    path: 'job-role-management/:id',
    component: RoleManagementComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      routeIdentifier: 'MY-JOB_ROLE_PROFILE',
      title: 'Role Management',
      helpUrl:
        'https://scribehow.com/shared/Job_Role_Management__3TsLYsKnSN2VHKuOvnZLfA',
    },
  },
  {
    path: 'my-job-role-profile',
    component: JobRolePersonComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'MY-JOB_ROLE_PROFILE',
      title: 'Job Role Profile',
      helpUrl:
        'https://scribehow.com/shared/Job_Role_Profile__PJaJ5kZ_RYiS5TU7euoeUA',
    },
  },
  {
    path: 'job-role-settings',
    component: JobRoleSettingsComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      routeIdentifier: 'ADMIN_JOB-ROLES-LIST',
      title: 'Job Role Settings',
      helpUrl:
        'https://scribehow.com/shared/Job_Role_Management__3TsLYsKnSN2VHKuOvnZLfA',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobRoleRoutingModule {}
