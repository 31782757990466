import { Component, Input } from '@angular/core';
import { JobRolePerson, ScoreTrafficLight } from '../services/job-role.service';

@Component({
  selector: 'ug-role-person-tiles',
  templateUrl: './role-person-tiles.component.html',
  styleUrls: ['./role-person-tiles.component.scss'],
})
export class RolePersonTilesComponent {
  mandatoryCountMessageMap = {
    '=-1': 'No requirements for this job role',
    '=0': 'All requirements met',
    '=1': '1 requirement unmet',
    other: '# mandatory requirements unmet',
  };

  @Input() jobRolePerson: JobRolePerson;

  getScoreTrafficLight(roleScore: number): string {
    let scoreLight = ScoreTrafficLight.success;
    if (roleScore < 0.4) {
      scoreLight = ScoreTrafficLight.danger;
    } else if (roleScore < 0.75) {
      scoreLight = ScoreTrafficLight.warning;
    }
    return scoreLight;
  }
}
