import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ExceptionTypes } from '../core/exception/exception.component';
import { AuthService } from '../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    if (route.queryParams.token && !route.queryParams.error) {
      localStorage.setItem('esqep.auth.saml', route.queryParams.token);
      this.authService.loginSucceeded();
      this.authService.authenticationStatusSubj.next(true);
    }

    if (route.queryParams.error) {
      this.router.navigate([
        'exception',
        ExceptionTypes[ExceptionTypes.NOT_USER],
      ]);
      return of(false);
    }

    if (this.authService.isAuthenticated) {
      return of(true);
    }

    this.router.navigate(['/login']);
    return of(false);
  }
}
