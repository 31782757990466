{
  "name": "ursus-gui",
  "version": "2.5.0",
  "license": "SEE LICENSE IN license.md",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "demo": "ng serve -c demo",
    "okta": "ng serve -c getinge-dev-sqeptech-okta",
    "build": "ng build",
    "test": "vitest",
    "test:coverage": "vitest --coverage",
    "test:watch": "vitest --watch",
    "lint": "ng lint",
    "app-version": "node -e \"console.log('APP_VERSION = \\'' + process.env.npm_package_version +'\\';')\" > src/version.js",
    "postversion": "node -e \"console.log('APP_VERSION = \\'' + process.env.npm_package_version +'\\';')\" > src/version.js",
    "i18n:extract": "transloco-keys-manager extract",
    "i18n:find": "transloco-keys-manager find"
  },
  "type": "module",
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.0",
    "@angular/cdk": "^18.2.0",
    "@angular/cli": "18.2.0",
    "@angular/common": "18.2.0",
    "@angular/compiler": "18.2.0",
    "@angular/compiler-cli": "18.2.0",
    "@angular/core": "18.2.0",
    "@angular/forms": "18.2.0",
    "@angular/material": "^18.2.0",
    "@angular/platform-browser": "18.2.0",
    "@angular/platform-browser-dynamic": "18.2.0",
    "@angular/router": "18.2.0",
    "@azure/msal-angular": "^3.0.23",
    "@azure/msal-browser": "^3.21.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/list": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@jsverse/transloco": "^7.4.3",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ng-select/ng-option-highlight": "^13.7.0",
    "@ng-select/ng-select": "^13.7.0",
    "@okta/okta-angular": "^6.4.0",
    "@okta/okta-auth-js": "^7.7.0",
    "@popperjs/core": "^2.11.8",
    "bootstrap": "^5.3.3",
    "core-js": "^3.38.1",
    "echarts": "^5.5.1",
    "moment": "^2.30.1",
    "ngx-echarts": "^18.0.0",
    "ngx-editor": "^17.5.4",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@analogjs/platform": "^1.7.2",
    "@analogjs/vite-plugin-angular": "^1.7.2",
    "@analogjs/vitest-angular": "^1.7.2",
    "@angular-eslint/builder": "18.3.0",
    "@angular-eslint/eslint-plugin": "18.3.0",
    "@angular-eslint/eslint-plugin-template": "18.3.0",
    "@angular-eslint/schematics": "18.3.0",
    "@angular-eslint/template-parser": "18.3.0",
    "@angular/build": "^18.2.0",
    "@jsverse/transloco-keys-manager": "^5.1.0",
    "@nx/vite": "~19.1.0",
    "@types/bootstrap": "^5.2.10",
    "@types/node": "^20.16.1",
    "@typescript-eslint/eslint-plugin": "^8.2.0",
    "@typescript-eslint/eslint-plugin-tslint": "^7.0.2",
    "@typescript-eslint/parser": "^8.2.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.1",
    "jsdom": "^24.1.1",
    "prettier": "^3.3.2",
    "ts-node": "^10.9.2",
    "typescript": "^5.5.4",
    "vite-tsconfig-paths": "^4.2.0",
    "vitest": "^1.6.0"
  },
  "volta": {
    "node": "20.16.0"
  }
}
