<form class="row align-items-end" [formGroup]="matrixFiltersForm">
  <div class="col-4">
    <label for="orgUnitFilter" class="form-label"
      >Select a {{ orgUnitType | lowercase }} to display on the matrix</label
    >
    <ng-select
      id="orgUnitFilter"
      [items]="orgUnitList"
      [virtualScroll]="true"
      [loading]="loadingOus"
      bindLabel="name"
      bindValue="id"
      placeholder="Select from the below or begin typing to filter results"
      [editableSearchTerm]="true"
      (scroll)="onScroll($event)"
      (scrollToEnd)="onScrollToEnd()"
      formControlName="orgUnit"
    >
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          No {{ orgUnitType | lowercase }} found for "{{ searchTerm }}"
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-7">
    <label for="additionalPeople" class="form-label"
      >Add additional people to the matrix</label
    >
    <ng-select
      id="additionalPeople"
      [items]="people$ | async"
      bindLabel="name"
      bindValue="key"
      [multiple]="true"
      [hideSelected]="true"
      [trackByFn]="trackByFn"
      [minTermLength]="2"
      [loading]="peopleLoading"
      placeholder="Please enter 2 or more characters to begin search..."
      typeToSearchText="Please enter 2 or more characters to begin search..."
      [typeahead]="peopleInput$"
      [clearable]="true"
      formControlName="additionalPeople"
    >
      <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          Fetching people for "{{ searchTerm }}"
        </div>
      </ng-template>
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
          No people found for "{{ searchTerm }}"
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-1">
    <button
      class="btn esqepBtn me-3 align-bottom"
      type="button"
      (click)="updateMatrixData()"
      [disabled]="!matrixFiltersForm.get('additionalPeople').touched"
    >
      Update
    </button>
  </div>
</form>
