import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, switchMap, takeUntil } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  EventSession,
  EventsService,
  NewEventSession,
} from '../../../events/services/events.service';
import { PersonBasic } from '../../../person/services/person.service';
import { CustomDateValidators } from '../../../shared/validations/CustomDateValidators';
import { CustomMinMaxValueValidators } from '../../../shared/validations/CustomMinMaxValueValidators';
import {
  SkillLookup,
  SkillService,
} from '../../../skill/services/skill.service';

@Component({
  selector: 'ug-book-session',
  templateUrl: './book-session.component.html',
  styleUrls: ['./book-session.component.scss'],
})
export class BookSessionComponent implements OnInit {
  fb = inject(FormBuilder);
  eventsService = inject(EventsService);
  activeModal = inject(NgbActiveModal);
  private skillService = inject(SkillService);
  private alertService = inject(AlertService);

  exceptionData = {
    NEW_SESSION_TRAINING: {
      level: AlertLevels.ERROR,
      code: 'ECNS-001',
      message: 'Error getting training subjects',
    } as AlertData,
    NEW_SESSION_TRAINERS: {
      level: AlertLevels.ERROR,
      code: 'ECNS-002',
      message: 'Error getting trainers for subject',
    } as AlertData,
    NEW_SESSION_SAVE: {
      level: AlertLevels.ERROR,
      code: 'ECNS-003',
      message: 'Error saving session for event',
    } as AlertData,
  };

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.bookSessionForm.dirty) {
      return false;
    } else {
      return true;
    }
  }

  bookSessionForm!: FormGroup;
  trainingSubjects: SkillLookup[] = [];
  trainers: PersonBasic[] = [];

  @Input() locations: Array<any> = [];
  @Input() selectedSkillId: number;
  @Input() usersToBookOntoSession: Array<number> = [];
  @Output() bookSession: EventEmitter<any> = new EventEmitter();
  @Output() bookSessionAndRoster: EventEmitter<any> = new EventEmitter();

  minSessionEndDate = null;
  spin = false;
  saveStarted = false;
  saveFailed = false;
  maximumWaitingTime = 180000; // 3 minutes

  private ngUnsubscribe: Subject<boolean> = new Subject();

  loadingTraining: boolean = false;
  loadingTrainers: boolean = false;

  get form() {
    return this.bookSessionForm.controls;
  }

  ngOnInit(): void {
    this.locations = this.eventsService.sessionLocations;

    this.bookSessionForm = this.fb.group(
      {
        training: [
          { value: null, disabled: this.selectedSkillId ? true : false },
          Validators.required,
        ],
        trainers: [null],
        location: [null, Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', [Validators.required]],
        expiryDate: [{ value: '', disabled: true }, Validators.required],
        minCapacity: [1, Validators.required],
        maxCapacity: [1, Validators.required],
        isSessionCompleted: [false],
      },
      {
        validator: [
          CustomDateValidators.afterDateValidator(
            'startDate',
            'endDate',
            'endDate',
          ),
          CustomDateValidators.afterDateValidator(
            'endDate',
            'expiryDate',
            'expiryDate',
          ),
          CustomMinMaxValueValidators.maxGreaterThanMinValidator(
            'minCapacity',
            'maxCapacity',
            'maxCapacity',
          ),
        ],
      },
    );

    this.bookSessionForm.get('training')?.valueChanges.subscribe((skill) => {
      if (skill) {
        this.loadingTrainers = true;
        this.eventsService
          .getTrainersBySkillId(skill.key)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (s: PersonBasic[]) => {
              this.trainers = s;
              this.loadingTrainers = false;
            },
            error: (err) => {
              this.loadingTrainers = false;
              this.alertService.createAlert2(
                this.exceptionData.NEW_SESSION_TRAINERS,
                err,
              );
            },
          });
      } else {
        this.trainers = [];
        this.bookSessionForm.get('trainer')?.setValue(null);
      }
    });

    this.loadingTraining = true;
    this.skillService
      .getSkillsByExternalType('Event')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (events: SkillLookup[]) => {
          this.trainingSubjects = events;
          if (this.selectedSkillId) {
            const val = this.trainingSubjects.find(
              (s) => s.key == this.selectedSkillId.toString(),
            );
            this.bookSessionForm.get('training')?.setValue(val);
          }
          this.loadingTraining = false;
        },
        error: (err) => {
          this.loadingTraining = false;
          this.alertService.createAlert2(
            this.exceptionData.NEW_SESSION_TRAINING,
            err,
          );
        },
      });

    this.bookSessionForm.get('startDate')?.valueChanges.subscribe((val) => {
      this.minSessionEndDate = val;
    });
  }

  createNewSession() {
    this.saveStarted = true;
    this.spin = true;

    const newEventSession: NewEventSession = {
      endDate: this.bookSessionForm.get('endDate').value,
      instructors: this.bookSessionForm.get('trainers').value ?? [],
      isSessionCompleted: this.bookSessionForm.get('isSessionCompleted').value, //default to false, needs further discussion on what should happen when completed at the point of creation.
      location: this.bookSessionForm.get('location').value.name,
      locationId: Number(this.bookSessionForm.get('location').value.key),
      minCapacity: this.bookSessionForm.get('minCapacity').value,
      maxCapacity: this.bookSessionForm.get('maxCapacity').value,
      skillId: Number(this.bookSessionForm.get('training').value.key),
      startDate: this.bookSessionForm.get('startDate').value,
      title: this.bookSessionForm.get('training').value.name,
    };

    if (this.usersToBookOntoSession && this.usersToBookOntoSession.length > 0) {
      //book session and update roster with attendees

      this.eventsService
        .createTrainingSession(newEventSession)
        .pipe(
          switchMap((savedEvent: EventSession) => {
            return this.eventsService.addAttendees(
              savedEvent.id,
              this.usersToBookOntoSession,
            );
          }),
        )
        .subscribe({
          next: () => {
            this.spin = false;
            this.activeModal.close();
            this.bookSessionAndRoster.next(true);
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.NEW_SESSION_SAVE,
              err,
            );
            this.saveFailed = true;
            this.spin = false;
          },
        });
    } else {
      this.eventsService.createTrainingSession(newEventSession).subscribe({
        next: (savedEvent: EventSession) => {
          this.spin = false;
          this.activeModal.close();
          this.bookSession.emit(savedEvent);
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.NEW_SESSION_SAVE,
            err,
          );
          this.saveFailed = true;
          this.spin = false;
        },
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
