import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'esqep-logged-out', // tslint:disable-line: component-selector
  templateUrl: './logged-out.component.html',
  styleUrls: [],
})
export class LoggedOutComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  loggedOutText = 'You have been logged out';

  ngOnInit() {
    const loggedOutReason = this.route.snapshot.paramMap
      .get('reason')
      .toUpperCase();
    if (loggedOutReason === 'TIMEOUT') {
      this.loggedOutText += ' due to inactivity';
    }
  }

  login() {
    this.router.navigate(['/login']);
  }
}
