import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CompetenciesModule } from '../competencies/competencies.module';
import { ControlsModule } from '../controls/controls.module';
import { SharedModule } from '../shared/shared.module';
import { BulkAssessmentCompletedComponent } from './bulk-assessment-completed/bulk-assessment-completed.component';
import { BulkAssessmentComponent } from './bulk-assessment/bulk-assessment.component';
import { AssessmentOptionsModalComponent } from './events-calendar-settings/assessment-options-modal/assessment-options-modal.component';
import { AssessmentOptionsComponent } from './events-calendar-settings/assessment-options/assessment-options.component';
import { EventsCalendarSettingsComponent } from './events-calendar-settings/events-calendar-settings.component';
import { EventsRoutingModule } from './events-routing.module';
import { EventsService } from './services/events.service';
import { AddAttendeesComponent } from './training-calendar/add-attendees/add-attendees.component';
import { BookExistingSessionComponent } from './training-calendar/book-existing-session/book-existing-session.component';
import { BookSessionComponent } from './training-calendar/book-session/book-session.component';
import { DateTimeSessionDisplayComponent } from './training-calendar/manage-session/date-time-session-display/date-time-session-display.component';
import { ManageSessionComponent } from './training-calendar/manage-session/manage-session.component';
import { TrainingCalendarComponent } from './training-calendar/training-calendar.component';
import { ViewSessionComponent } from './training-calendar/view-session/view-session.component';

@NgModule({
  declarations: [
    TrainingCalendarComponent,
    BulkAssessmentComponent,
    BookSessionComponent,
    ManageSessionComponent,
    AddAttendeesComponent,
    EventsCalendarSettingsComponent,
    BulkAssessmentCompletedComponent,
    AssessmentOptionsModalComponent,
    ViewSessionComponent,
    AssessmentOptionsComponent,
    BookExistingSessionComponent,
    DateTimeSessionDisplayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EventsRoutingModule,
    CompetenciesModule,
    FullCalendarModule,
    ControlsModule,
    NgbNavModule,
    NgbModule,
  ],
  exports: [
    TrainingCalendarComponent,
    BulkAssessmentComponent,
    EventsCalendarSettingsComponent,
  ],
  providers: [EventsService, DatePipe],
})
export class EventsModule {}
