import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, takeUntil } from 'rxjs';
import { TypeaheadService } from '../../../../../controls/dropdown-select/typeahead.service';
import { TypeaheadFilter } from '../../../../../controls/dynamic-form/control-types/filter-typeahead';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { OrgUnit } from '../../../../../org-unit/models/org-unit';
import {
  OrgUnitLookup,
  OrgUnitService,
} from '../../../../../org-unit/services/org-unit.service';
import { PersonService } from '../../../../../person/services/person.service';

@Component({
  selector: 'ug-org-units-modal',
  templateUrl: './org-units-modal.component.html',
  styleUrls: ['./org-units-modal.component.scss'],
})
export class OrgUnitsModalComponent implements OnInit, OnDestroy {
  modal = inject(NgbActiveModal);
  private fb = inject(FormBuilder);
  private orgUnitService = inject(OrgUnitService);
  private typeaheadService = inject(TypeaheadService);
  private personService = inject(PersonService);
  private alertService = inject(AlertService);

  exceptionData = {
    ORG_UNITS_BY_TYPE: {
      level: AlertLevels.ERROR,
      code: 'OUM-001',
      message: 'Error retrieving org units',
    } as AlertData,
    PERSON_LIST_IN_OU: {
      level: AlertLevels.ERROR,
      code: 'OUM-002',
      message: 'Error retrieving person list in org unit',
    } as AlertData,
  };

  tableHeaders = [];
  tableRows = [];
  tableFilters = [];
  tableSelectedButtons = [];
  orgUnitCount;

  @Input() jobRoleOrgUnitsInUse: Array<any>;
  @Output() orgUnitsSelected: EventEmitter<Array<any>> = new EventEmitter<
    Array<any>
  >();

  orgUnitFiltersForm: FormGroup;
  ouTableLoading = false;
  ngUnsubscribe: Subject<boolean> = new Subject();
  filteredOrgUnitList: Array<OrgUnit>;
  orgUnitSearch: OperatorFunction<string, OrgUnit[]>;
  orgUnitTypes: Array<OrgUnitLookup> = [];

  static compare(items, input: string) {
    return items.toLowerCase().includes(input);
  }

  static sort(a, b, text: string) {
    return a.startsWith(text) - b.startsWith(text) || b - a;
  }
  formatter = (result) => result;

  ngOnInit(): void {
    this.orgUnitFiltersForm = this.fb.group({
      filterType: [''],
    });

    this.orgUnitService
      .getLookups('OrgUnitType')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: Array<OrgUnitLookup>) => {
        this.orgUnitTypes = r;
      });

    this.tableHeaders = [
      { id: 'name', title: 'Org Unit' },
      {
        id: 'exists',
        title: 'Exists In Role',
        iconFunction: this.existsIcon,
        class: 'text-center',
      },
    ];

    this.tableSelectedButtons = [
      {
        title: 'Cancel',
        class: 'btn-outline-secondary',
        function: this.closeModal,
        cancelButton: true,
      },
      {
        title: 'Add Selected',
        class: 'btn-outline-success',
        function: this.addSelectedOrgUnits,
      },
    ];

    this.orgUnitFiltersForm
      .get('filterType')
      .valueChanges.subscribe((filter) => {
        if (filter) {
          this.ouTableLoading = true;
          this.orgUnitService
            .getOrgUnitsByTypeId(filter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (oul) => {
                this.filteredOrgUnitList = this.checkIfOuExists(oul);
                const allOus = this.filteredOrgUnitList.map((c) => c.name);
                const ouNames = [...new Set(allOus)];
                this.orgUnitSearch = this.typeaheadService.typeahead(
                  ouNames,
                  OrgUnitsModalComponent.compare,
                  OrgUnitsModalComponent.sort,
                );
                this.tableRows = this.filteredOrgUnitList;
                this.tableFilters = [
                  new TypeaheadFilter({
                    key: 'name',
                    label: 'Filter by name',
                    typeAheadSearch: this.orgUnitSearch,
                    resultFormatter: this.formatter,
                    inputFormatter: this.formatter,
                    inLineGroup: false,
                    order: 1,
                  }),
                ];
                this.ouTableLoading = false;
              },
              error: (err) =>
                this.alertService.createAlert2(
                  this.exceptionData.ORG_UNITS_BY_TYPE,
                  err,
                ),
            });
        }
      });
  }

  checkIfOuExists(ous) {
    const ouSet = new Set(this.jobRoleOrgUnitsInUse.map((ou) => ou.id));
    ous.forEach((ou) => {
      if (ouSet.has(ou.id)) {
        ou['exists'] = true;
      }
    });
    return ous;
  }

  existsIcon = (row) => {
    return row.exists
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  addSelectedOrgUnits = (selected) => {
    const selectedOrgType = this.orgUnitTypes.find(
      (f) => f.key === this.orgUnitFiltersForm.get('filterType').value,
    );
    selected.map((s) => {
      s.externalType = selectedOrgType.name;
      return s;
    });
    this.orgUnitsSelected.next(selected);
    this.closeModal();
  };

  closeModal() {
    this.modal.close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
