<div class="card">
  <div class="card-body">
    <div class="card-title">
      <span class="h5">
        {{ cardTitle }}
      </span>
      <div class="form-inline float-md-end">
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="unreadCheckBox"
              [(ngModel)]="unreadOnly"
            />
            <label class="form-check-label" for="unreadCheckBox"
              >Show unread only</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th scope="col" class="">Assessment Request Id</th>
            <th scope="col" class="">Date / Time</th>
            <th scope="col" class="">Description</th>
            <th scope="col" class="">Originator</th>
            <th scope="col" class="">Assessment Request Status</th>
            <th scope="col" class=""></th>
          </tr>
        </thead>
        <tbody>
          <tr
            [hidden]="unreadOnly && notification.isUnread !== true"
            *ngFor="let notification of notificationList; let i = index"
            [ngClass]="{ 'fw-bold': notification.isUnread }"
          >
            <th scope="row">
              <a
                [routerLink]="[
                  '/assessment-request',
                  notification.claimId,
                  notification.originatorActorType === 'C'
                    ? 'assessor'
                    : 'claimant',
                ]"
                >{{ notification.claimId }}</a
              >
            </th>
            <td nowrap>
              {{ notification.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}
            </td>
            <td>{{ notification.description }}</td>
            <td nowrap>{{ notification.originator }}</td>
            <td>{{ notification.statusText }}</td>
            <td>
              <button
                *ngIf="notification.isUnread"
                type="button"
                class="btn btn-outline-success btn-sm"
                (click)="onNotificationReadClick(i)"
                title="Mark as read"
              >
                <span class="far fa-envelope"></span>
              </button>
              <div *ngIf="!notification.isUnread" class="px-2 py-1 text-muted">
                <span class="far fa-envelope-open"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ug-list-status
      [listLength]="notificationDisplayCount"
      [emptyListMessage]="listDisplayMessage"
      [isLoading]="isLoading"
    ></ug-list-status>
    <!-- 
    <div class="row bg-none">
      <div class="col-1"><h6>Id</h6></div>
      <div class="col"><h6>Date / Time</h6></div>
      <div class="col"><h6>Description</h6></div>
      <div class="col-2"><h6>Originator</h6></div>
      <div class="col-2"><h6>Claim Status</h6></div>
      <div class="col-1"></div>
    </div>
    <div *ngFor="let notification of notificationList; let i = index" class="row" [hidden]="(unreadOnly && notification.isUnread !==true)">
      <div class="col-1 text-nowrap"><a [routerLink]="['/claim', notification.claimId, (notification.originatorActorType === 'C' ? 'assessor' : 'claimant')]">{{notification.claimId}}</a></div>
      <div class="col text-nowrap">{{notification.timestamp | date:'yyyy-MM-dd HH:mm:SS'}}</div>
      <div class="col text-truncate">{{notification.description}}</div>
      <div class="col-2">{{notification.originator}}</div>
      <div class="col-2">{{notification.statusText}}</div>
      <div class="col-1">
        <button *ngIf="notification.isUnread" type="button" class="btn btn-outline-success btn-sm" (click)="onNotificationReadClick(i)">
          <span class="far fa-envelope"></span>
        </button>
        <div *ngIf="!notification.isUnread" class="px-2 py-1 text-muted">
          <span class="far fa-envelope-open"></span>
        </div>
      </div>
    </div>
    <ug-list-status [listLength]="notificationDisplayCount" [emptyListMessage]="listDisplayMessage" [isLoading]="isLoading"></ug-list-status>
     -->
  </div>
  <!-- 
  <div class="card-footer">
    <div class="form-inline">
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="unreadCheckBox" [(ngModel)]="unreadOnly">
          <label class="form-check-label" for="unreadCheckBox">Show Unread Notifications only</label>
        </div>
      </div>
    </div>
  </div>
 -->
</div>
