import { Injectable, inject } from '@angular/core';
import moment from 'moment';
import {
  Observable,
  Subject,
  Subscription,
  first,
  fromEvent,
  merge,
  takeUntil,
  timer,
} from 'rxjs';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityTimeoutService {
  private configService = inject(ConfigService);

  idleEvents: Observable<any> = new Observable();
  timer: Subscription = new Subscription();
  idleSubscription: Subscription = new Subscription();
  destroy: Subject<boolean> = new Subject<boolean>();
  inactiveSecondsCount: Subject<number> = new Subject<number>();
  startIdleWatch: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.idleEvents = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'keyup'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    );
  }

  watchForUserIdle() {
    this.idleSubscription = this.idleEvents
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        this.restartTimer();
      });
  }

  restartTimer() {
    this.timer.unsubscribe();
    localStorage.removeItem('logoutTime');
    this.startTimer();
  }

  unsubscribeIdleSub() {
    this.destroy.next(true);
    this.destroy.complete();
    this.idleSubscription.unsubscribe();
  }

  startWatch() {
    this.startIdleWatch.pipe(first()).subscribe((val) => {
      if (val) {
        this.restartTimer();
        this.watchForUserIdle();
      }
    });
  }

  // startTimer() {
  //   const logoutTime = moment(new Date().getTime()).add(this.configService.inactivity_timeout_logout_seconds.toString(), 's').toDate()

  //   this.timer = timer(1000, 1000)
  //   .subscribe(() => {
  //     let now = moment(new Date());
  //     let end = moment(logoutTime)
  //     let timeRemaining = (end.diff(now, 'seconds'));
  //     this.inactiveSecondsCount.next(this.configService.inactivity_timeout_logout_seconds - timeRemaining)
  //   });
  // }

  startTimer() {
    let logoutTime = moment(new Date().getTime())
      .add(this.configService.inactivity_timeout_logout_seconds.toString(), 's')
      .toISOString();
    localStorage.setItem('logoutTime', logoutTime);

    this.timer = timer(1000, 1000).subscribe(() => {
      let timeRemaining = moment(localStorage.getItem('logoutTime')).diff(
        moment(new Date().toISOString()),
        'seconds'
      );
      this.inactiveSecondsCount.next(
        this.configService.inactivity_timeout_logout_seconds - timeRemaining
      );
    });
  }

  ngOnDestroy() {
    this.idleSubscription.unsubscribe();
  }
}
