import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { JobRolePayload, JobRoleService } from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-item',
  templateUrl: './job-role-item.component.html',
  styleUrls: ['./job-role-item.component.css'],
})
export class JobRoleItemComponent implements OnInit {
  private authService = inject(AuthService);
  private jobRoleService = inject(JobRoleService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  pageTitle = '';

  name = '';
  description = '';
  identifier = null;
  jobRoleId: number;

  ngOnInit(): void {
    this.authService.loggedInUserSubj.subscribe((liu: LoggedInUser) => {
      if (
        ![
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.JobRoleReqs,
          SecurityRoleKey.JobRoleReqsPeople,
        ].some((sr) => liu.roleIdentifier === sr)
      ) {
        this.router.navigate(['/exception/not_auth']);
      }
    });
    this.activatedRoute.params.subscribe((params) => {
      this.jobRoleId = Number(params.id);
      this.pageTitle = this.jobRoleId ? 'Edit Role' : 'Create New Role';
      this.jobRoleService.getJobRole(this.jobRoleId).subscribe(
        (jobRole) => {
          this.name = jobRole.name;
          this.identifier = jobRole.identifierCode;
          this.description = jobRole.description;
        },
        (err) => {
          console.log('Job Role not found');
        },
      );
    });
  }

  save() {
    const jobRoleData: JobRolePayload = {
      name: this.name,
      description: this.description,
      identifierCode: this.identifier,
      jobFamilyId: undefined,
      jobGradeId: undefined,
      organisationUnitId: undefined,
      requirements: {
        create: [],
        update: [],
        delete: [],
      },
      people: {
        create: [],
        update: [],
        delete: [],
      },
      orgUnits: {
        create: [],
        update: [],
        delete: [],
      },
    };

    if (this.jobRoleId) {
      jobRoleData.id = this.jobRoleId;
      this.jobRoleService
        .updateJobRole(this.jobRoleId, { jobRole: jobRoleData })
        .subscribe((e) =>
          this.router.navigate([`job-roles-list`], {
            queryParams: { roleName: this.name },
          }),
        );
    } else {
      this.jobRoleService
        .createJobRole({ jobRole: jobRoleData })
        .subscribe((e) =>
          this.router.navigate([`job-roles-list`], {
            queryParams: { roleName: this.name },
          }),
        );
    }
  }

  cancel() {
    this.router.navigate(['job-roles-list']);
  }
}
