import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { JobRoleService } from '../../../../../job-role/services/job-role.service';

@Component({
  selector: 'ug-ai-control-popover',
  templateUrl: './ai-control-popover.component.html',
  styleUrl: './ai-control-popover.component.scss',
})
export class AiControlPopoverComponent {
  @Input() key: string;
  @Input() roleName: string;

  @Input() set popoverPrompt(value) {
    this.prompt = value;
  }
  @Input() maxNumOfChars: number | null;
  @Output() confirmEvent = new EventEmitter<{
    key: string;
    aiResponse: string;
  }>();

  private jobRoleService = inject(JobRoleService);
  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private isLoadingAiGenerate = false;

  aiResponse = '';
  invalidPrompt: boolean;
  prompt: string;
  wordCount: number = 15;
  showConfirmButton = false;
  aiResponseHistory: string[] = [];
  currentPage = 0;

  invalidResponse =
    'Unable to provide a description based on the details provided, please try again.';

  ngOnInit() {
    this.generateAiKeyDetails();
  }

  set loadingAiGenerate(value: boolean) {
    this.isLoadingAiGenerate = value;
    if (value) {
      this.aiResponse = 'Generating AI response...';
    }
  }

  get loadingAiGenerate(): boolean {
    return this.isLoadingAiGenerate;
  }

  generateAiKeyDetails() {
    this.loadingAiGenerate = true;
    const payload = {
      details: this.prompt,
      wordCount: this.wordCount,
      jobTitle: this.roleName,
    };

    this.jobRoleService
      .getAIGeneratedKeyDetails(payload, this.key)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.aiResponse = res;
          if (this.aiResponse === this.invalidResponse) {
            this.invalidPrompt = true;
          } else {
            this.aiResponseHistory.push(res);
            this.invalidPrompt = false;
          }

          this.currentPage = this.aiResponseHistory.length;
          this.showConfirmButton = true;
          this.loadingAiGenerate = false;
        },
        error: (err) => {
          this.showConfirmButton = false;
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'AI-001',
              message: 'Error generating AI response',
            },
            err,
          );
          this.aiResponse = 'Unable to generate AI response';
          this.loadingAiGenerate = false;
        },
      });
  }

  confirm() {
    this.confirmEvent.emit({ key: this.key, aiResponse: this.aiResponse });
    this.aiResponse = '';
  }

  previousPage() {
    if (this.invalidPrompt) {
      this.currentPage = this.aiResponseHistory.length;
      this.invalidPrompt = false;
    } else {
      this.currentPage -= 1;
    }

    this.aiResponse = this.aiResponseHistory[this.currentPage - 1];
  }

  nextPage() {
    this.currentPage += 1;
    this.aiResponse = this.aiResponseHistory[this.currentPage - 1];
  }
}
