import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CompetenciesService,
  SkillVsSubject,
} from '../../../../../competencies/services/competencies.service';
import { TextboxFilter } from '../../../../../controls/dynamic-form/control-types/filter-textbox';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../../../controls/table/table.service';
import { SkillFilterPayload } from '../../../../../skill/services/skill.service';

@Component({
  selector: 'ug-skill-assignment-modal',
  templateUrl: './skill-assignment-modal.component.html',
  styleUrl: './skill-assignment-modal.component.scss',
})
export class SkillAssignmentModalComponent {
  modal = inject(NgbActiveModal);
  private competencyService = inject(CompetenciesService);
  private destroyRef = inject(DestroyRef);

  @Input() selectedDimension;
  @Input() subjectId: number;
  @Input() subjectTitle: string;
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();

  tableLoading = true;
  tableHeaders: Array<TableHeader>;
  tableSelectedButtons: Array<TableSelectedButton>;
  filters = [
    new TextboxFilter({
      key: 'searchTerm',
      order: 1,
      placeholder: 'Search ...',
    }),
  ];

  skillList: SkillVsSubject[];
  dimensionName: string;
  skillType: string;
  @Output() updateSelectedEvent: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  ngOnInit(): void {
    const filterData: SkillFilterPayload = {
      dimensionId: this.selectedDimension?.id,
      subjectId: null,
    };
    this.dimensionName = this.selectedDimension?.name;
    this.skillType =
      this.selectedDimension?.id === 4 ? 'Training' : 'Competency';
    this.tableHeaders = [
      { id: 'name', title: this.skillType, class: 'w-25' },
      { id: 'description', title: 'Description', class: 'w-50' },
      { id: 'assignedSubjects', title: 'Assigned To' },
    ];
    this.tableSelectedButtons = [
      {
        title: 'Update',
        class: 'btn-outline-success',
        function: this.updateSelected,
      },
    ];

    this.competencyService
      .getSkillsVsSubjectId(this.subjectId, filterData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (sk) => {
          this.skillList = sk;
          this.skillList.forEach((skill) => {
            const subjNames = skill.subjects.map((sj) => sj.name);
            skill['assignedSubjects'] = subjNames.join(', ');
          });

          this.tableLoading = false;
        },
      });
  }

  updateSelected = (selected: SkillVsSubject[]) => {
    const skillIds = selected.map((sk) => sk.id);
    this.updateSelectedEvent.emit(skillIds);
  };
}
