import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'ug-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextEditorComponent implements OnInit, OnDestroy {
  editor: Editor;

  @Input() controlName: string;
  @Input() controlLabel: string;
  @Input() controlPlaceholder: string;
  @Input() parentFormGroup: FormGroup;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ];

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
