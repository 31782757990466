<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">
          {{ cardTitle | titlecase }}
          <!-- <span data-bs-toggle="collapse" data-bs-target="#filterBlock"> -->
          <button
            class="float-end btn btn-sm btn-outline-secondary"
            data-bs-toggle="collapse"
            data-bs-target="#filterBlock"
            aria-pressed="false"
            (click)="toggleFilters()"
          >
            <span class="fas fa-filter"></span>
            {{ filtersEnabled ? 'Disable' : 'Enable' }} filters
          </button>
          <!-- </span> -->
        </h5>
        <div
          *ngIf="filtersEnabled"
          class="form bg-light border p-3 px-md-0 py-md-1"
          id="filterBlock"
          [formGroup]="filterControls"
        >
          <div class="row p-1">
            <div class="col-12 col-md-2">
              <label
                class="form-label-sm pb-0 mb-0"
                for="compDimensionFilterControl"
                >Type
              </label>

              <select
                class="form-select form-select-sm"
                id="compDimensionFilterControl"
                formControlName="compDimension"
              >
                <option [ngValue]="null">All types</option>
                <option
                  *ngFor="let compDimensionFilter of filterListCompDimension"
                  [ngValue]="compDimensionFilter"
                >
                  {{ compDimensionFilter }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-2">
              <label class="form-label-sm pb-0 mb-0" for="statusFilterControl"
                >Status</label
              >
              <select
                class="form-select form-select-sm"
                id="statusFilterControl"
                formControlName="status"
              >
                <option [ngValue]="null">All status</option>
                <option
                  *ngFor="let statusFilter of filterListStatus"
                  [ngValue]="statusFilter"
                >
                  {{ statusFilter }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-2">
              <label class="form-label-sm pb-0 mb-0" for="levelFilterControl"
                >Level</label
              >
              <select
                class="form-select form-select-sm"
                id="levelFilterControl"
                formControlName="level"
              >
                <option [ngValue]="null">All levels</option>
                <option
                  *ngFor="let levelFilter of filterListLevel"
                  [ngValue]="levelFilter"
                >
                  {{ levelFilter }}
                </option>
              </select>
            </div>
            <div *ngIf="claimantView" class="col-12 col-md-2">
              <label
                class="form-label-sm pb-0 mb-0"
                for="assessorFilterControl"
              >
                Assessor</label
              >
              <select
                class="form-select form-select-sm"
                id="assessorFilterControl"
                formControlName="counterparty"
              >
                <option [ngValue]="null">All Assessors</option>
                <option
                  *ngFor="let assessorFilter of filterListAssessor"
                  [ngValue]="assessorFilter"
                >
                  {{ assessorFilter }}
                </option>
              </select>
            </div>
            <div *ngIf="verifierView" class="col-12 col-md-2">
              <label class="form-label-sm pb-0 mb-0" for="claimantFilterControl"
                >Claimant</label
              >
              <select
                class="form-select form-select-sm"
                id="claimantFilterControl"
                formControlName="counterparty"
              >
                <option [ngValue]="null">All claimants</option>
                <option
                  *ngFor="let claimantFilter of filterListClaimant"
                  [ngValue]="claimantFilter"
                >
                  {{ claimantFilter }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-2">
              <label
                class="form-label-sm pb-0 mb-0"
                for="createDateFilterControl"
                >Created since</label
              >
              <select
                class="form-select form-select-sm"
                id="creareDateFilterControl"
                formControlName="createDateRange"
                title="Created since"
              >
                <option [ngValue]="null">Any time</option>
                <option
                  *ngFor="let createDataFilter of filterListIntervals"
                  [ngValue]="createDataFilter.offsetValue"
                >
                  {{ createDataFilter.intervalName }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-2">
              <label
                class="form-label-sm pb-0 mb-0"
                for="actionedDateFilterControl"
                >Actioned since</label
              >
              <select
                class="form-select form-select-sm"
                id="actionedDateFilterControl"
                formControlName="actionedDateRange"
              >
                <option [ngValue]="null">Any time</option>
                <option
                  *ngFor="let actionedDateFilter of filterListIntervals"
                  [ngValue]="actionedDateFilter.offsetValue"
                >
                  {{ actionedDateFilter.intervalName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- </div>
      <div class="card-body"> -->
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped">
            <thead>
              <tr>
                <th scope="col" class="w-5"></th>
                <th scope="col" class="w-5">Id</th>
                <th scope="col" class="w-10">Type</th>
                <th scope="col" class="w-25">Competency</th>
                <th scope="col" class="w-15">Level</th>
                <th scope="col" class="w-10">Status</th>
                <th *ngIf="!verifierView" scope="col" class="w-10">Assessor</th>
                <th *ngIf="verifierView" scope="col" class="w-10">Claimant</th>
                <th scope="col" class="w-10">Created</th>
                <th scope="col" class="w-10">Last Actioned</th>
              </tr>
            </thead>
            <tbody>
              <tr
                tabindex="0"
                (keyup.enter)="onClaimDetailClick(claim)"
                *ngFor="
                  let claim of claimList;
                  let i = index;
                  trackBy: trackByFn
                "
                [ngClass]="{ 'table-success': claim.hasNotification }"
                (click)="onClaimDetailClick(claim)"
              >
                <ng-container *ngIf="showClaim(claim)">
                  <td class="h5" (click)="$event.stopPropagation()">
                    <a
                      *ngIf="claim.hasNotification"
                      class="badge bg-success btn float-end"
                      (click)="onNotificationButtonClick(i)"
                      >New</a
                    >
                  </td>
                  <th scope="row">{{ claim.id }}</th>
                  <td>{{ claim.competencyDimensionName }}</td>
                  <!-- <td>{{claim.disciplineName + ' - ' + claim.skillName}}</td> -->
                  <td>
                    <span class="text-muted text-uppercase">{{
                      claim.disciplineName
                    }}</span>
                    - {{ claim.skillName }}
                  </td>
                  <td>{{ claim.claimLevelName }}</td>
                  <td>{{ claim.statusText }}</td>
                  <td *ngIf="!verifierView">{{ claim.assessorName }}</td>
                  <td *ngIf="verifierView">{{ claim.claimantName }}</td>
                  <td nowrap>
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="{{ claim.createDatetime | date: 'medium' }}"
                      >{{ claim.createDatetime | date }}</span
                    >
                  </td>
                  <!-- <td>{{claim.lastActionName + ': ' + (claim.lastActionDatetime | date)}}</td> -->
                  <td nowrap>
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="{{ claim.lastActionDatetime | date: 'medium' }}"
                      >{{ claim.lastActionDatetime | date }}</span
                    >
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
        <ug-list-status
          [listLength]="claimList?.length"
          [emptyListMessage]="'No Matching Claims found'"
          [isLoading]="isLoading"
        ></ug-list-status>
      </div>
    </div>
  </div>
</div>
