<div class="login-section">
  <div class="d-flex flex-column flex-lg-row flex-xl-row flex-xxl-row">
    <div class="me-lg-5 mb-5 mb-lg-0 mb-xl-0 mb-xxl-0">
      <h1>{{ loginPageMessage }}</h1>
      <div class="my-2">
        <img
          src="../assets/esqep_icon.png"
          alt="eSQEP Logo"
          id="esqepLogo"
          class="logo"
        />
      </div>
    </div>

    <div>
      <h2 class="signin">Sign in</h2>
      <div class="login-buttons">
        <button
          class="btn esqepBtn d-block position-relative"
          *ngIf="authSetup[authenticationConfigNames.CSOD].enabled"
          (click)="loginCsodSaml()"
        >
          <span class="integration-csod"></span>
          <span class="btn-text">{{
            authSetup[authenticationConfigNames.CSOD].buttonTitle
          }}</span>
        </button>
        <button
          class="btn esqepBtn d-block position-relative"
          *ngIf="authSetup[authenticationConfigNames.ENTRA].enabled"
          (click)="loginEntra()"
        >
          <span class="integration-microsoft"></span>
          <span class="btn-text">{{
            authSetup[authenticationConfigNames.ENTRA].buttonTitle
          }}</span>
        </button>
        <button
          class="btn esqepBtn d-block position-relative"
          *ngIf="authSetup[authenticationConfigNames.OKTA].enabled"
          (click)="loginOkta()"
        >
          <span class="integration-okta"></span>
          <span class="btn-text">{{
            authSetup[authenticationConfigNames.OKTA].buttonTitle
          }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
