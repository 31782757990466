<ng-template #unsavedWarningModal let-modal>
  <div class="modal-content">
    <div class="modal-header bg-warning">
      <h5 class="modal-title" id="unsavedWarningModalTitle">
        You have unsaved changes
      </h5>
    </div>
    <div class="modal-body">
      <p>
        Are you sure you want to leave this screen without saving your changes?
      </p>
    </div>
    <div class="modal-footer">
      <span
        data-toggle="tooltip"
        data-placement="bottom"
        title="Return to unsaved changes"
      >
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="goBackUnsaved(); modal.close()"
        >
          Go back
        </button>
      </span>
      <span
        data-toggle="tooltip"
        data-placement="bottom"
        title="Exit without saving changes"
      >
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="exitUnsaved(); modal.close()"
        >
          Exit
        </button>
      </span>
    </div>
  </div>
</ng-template>
