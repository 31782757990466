<div [formGroup]="parentFormGroup.get('projectCompetencies')">
  <button (click)="openAddCompetenciesModal()" class="btn esqepBtn">
    Add Competencies
  </button>
  <div class="fst-italic mt-2">
    Click the "Add Competencies" button to add competencies to the project. In
    the table below, set the required level and if required, the role to assign
    it to.
  </div>
  <div
    [formGroup]="
      parentFormGroup.get('projectCompetencies.assignedCompetencies')
    "
    class="fixed-table-height mt-3"
  >
    <div class="alert alert-info" [hidden]="competencies.controls?.length > 0">
      <i class="fas fa-xl fa-info-circle"></i>Add a competency to the project
    </div>
    <table class="w-100 table" [hidden]="competencies.controls?.length === 0">
      <thead>
        <tr>
          <th scope="col" class="w-30">Competency</th>
          <th scope="col" class="w-25">Level Required</th>
          <th scope="col" class="w-25">Role Assignment</th>
          <th scope="col" class="w-20" class="ms-3">Actions</th>
        </tr>
      </thead>
      <tbody formArrayName="competencies">
        <tr
          *ngFor="let comp of competencies.controls; let i = index"
          [formGroupName]="i"
        >
          <td>{{ comp.value.discipline }} - {{ comp.value.skillName }}</td>
          <td>
            <select
              class="form-select form-select-sm selectWidth"
              formControlName="requiredLevel"
            >
              <option
                *ngFor="let level of comp.value.levels"
                [ngValue]="level.levelCode"
              >
                {{ level.name }}
              </option>
            </select>
          </td>
          <td>
            <select
              class="form-select form-select-sm selectWidth"
              formControlName="assignment"
            >
              <option selected>Any</option>
              <option>All Roles</option>
              <option *ngFor="let role of projectJobRoles" [ngValue]="role.id">
                {{ role.name }}
              </option>
            </select>
          </td>
          <td>
            <div class="w-100">
              <button class="action-btn" (click)="removeCompetency(i)">
                <i class="fas fa-trash-alt text-danger"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #competenciesModal let-modal>
  <div class="modal-content">
    <div class="modal-header bg-dark text-light">
      <h5 class="modal-title">Add Competencies</h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        (click)="modal.close()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div *ngFor="let dim of compDims" class="form-check form-check-inline">
        <input
          type="radio"
          [checked]="dim.id === dimensionId"
          class="form-check-input"
          [value]="dim"
          (change)="onDimChange(dim)"
        />
        <label class="form-check-label" for="{{ 'dimensionOption' + i }}">{{
          dim.name | titlecase
        }}</label>
      </div>
      <div
        [formGroup]="
          parentFormGroup.get('projectCompetencies.competenciesModalFilters')
        "
        class="d-flex w-100 justify-content-between align-items-end mb-3"
      >
        <div class="dropdownWidth">
          <ug-dropdown-select
            [ngbTypeahead]="funcAreaSearch"
            [resultFormatter]="funcAreaFormatter"
            [inputFormatter]="funcAreaFormatter"
            [inlineGroup]="false"
            formControlName="functionalArea"
          >
            Search By Competency Application
          </ug-dropdown-select>
        </div>
        <div class="dropdownWidth">
          <ug-dropdown-select
            [ngbTypeahead]="disciplineSearch"
            [resultFormatter]="disciplineFormatter"
            [inputFormatter]="disciplineFormatter"
            [inlineGroup]="false"
            formControlName="discipline"
          >
            Search By Competency Subcategory
          </ug-dropdown-select>
        </div>
        <div class="dropdownWidth">
          <ug-dropdown-select
            [ngbTypeahead]="skillSearch"
            [resultFormatter]="skillFormatter"
            [inputFormatter]="skillFormatter"
            [inlineGroup]="false"
            formControlName="skill"
          >
            Search By Competency
          </ug-dropdown-select>
        </div>
        <div class="clear-filter-btn ms-3">
          <button class="btn esqepBtn" (click)="clearFilters()">
            Clear Filters
          </button>
        </div>
      </div>
      <div class="fixed-table-height">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="w-10">
                <input
                  type="checkbox"
                  [(ngModel)]="selectAllFilteredComps"
                  (change)="checkUncheckAllFilteredComps()"
                />
                <label class="ms-1">Select all</label>
              </th>
              <th scope="col">Competency</th>
              <th scope="col">Competency Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let skill of filteredCompList; let index = index">
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="filteredCompList[index].isSelected"
                  (change)="isAllFilteredCompsSelected()"
                />
              </td>
              <td>{{ skill.name }}</td>
              <td>{{ skill.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="addSelectedComps()"
          class="btn btn-outline-success"
          [attr.disabled]="addingCompetencies ? true : null"
        >
          <span
            *ngIf="addingCompetencies"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Add selected competencies
        </button>
        <button
          type="submit"
          class="btn btn-outline-dark"
          (click)="modal.close('')"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
