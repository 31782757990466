import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableSelectedButton,
} from '../../controls/table/table.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  DynamicGroupsService,
  Filter,
} from '../../dynamic-groups/services/dynamic-groups.service';

@Component({
  selector: 'ug-group-filters-modal',
  templateUrl: './group-filters-modal.component.html',
  styleUrls: ['./group-filters-modal.component.scss'],
})
export class GroupFiltersModalComponent implements OnInit, OnDestroy {
  modal = inject(NgbActiveModal);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private uiService = inject(UitoolsService);

  existsIcon = (row: Filter) => {
    return row.exists
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  addSelected = (selected: Filter[]) => {
    this.addSelectedLoading = true;
    const selectedFilter = selected[0];
    if (selectedFilter.id !== this.filterId) {
      this.selectedFilterChange.emit(selectedFilter);
      this.closeModal();
      this.addSelectedLoading = false;
    } else {
      this.uiService.showToast(
        selectedFilter.name + ' has already been assigned',
        { classname: 'bg-danger text-light', delay: 5000 },
      );
    }
  };

  @Input() filterId: number | undefined;
  @Input() modalTitle: string | undefined;
  @Output() selectedFilterChange = new EventEmitter<Filter>();

  tableLoading = false;
  filterList: Filter[] = [];
  addSelectedLoading = false;
  ngUnsubscribe: Subject<boolean> = new Subject();

  tableHeaders: TableHeader[] = [
    { id: 'name', title: 'Name', class: 'w-30' },
    { id: 'description', title: 'Description', class: 'w-40' },
    {
      id: 'exists',
      title: 'Assigned',
      iconFunction: this.existsIcon,
      class: 'text-center',
    },
  ];
  tableRows: Filter[] = [];
  tableFilters: FilterBase<any>[] = [
    new TextboxFilter({
      key: 'searchTerm',
      label: 'Filter by term',
      order: 1,
      placeholder: 'Filter by term',
    }),
  ];
  tableSelectedButtons: TableSelectedButton[] = [
    {
      title: 'Cancel',
      class: 'btn-outline-secondary',
      function: this.closeModal,
      cancelButton: true,
    },
    {
      title: 'Add Selected',
      class: 'btn-outline-success',
      function: this.addSelected,
    },
  ];
  filterCount: number = 0;

  ngOnInit(): void {
    this.tableLoading = true;

    this.dynamicGroupsService
      .getFiltersByEntity('Person')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((efl) => {
        this.filterList = efl;
        this.checkIfFilterExists();
        this.tableRows = this.filterList;
        this.tableLoading = false;
        this.filterCount = this.tableRows.length;
      });
  }

  closeModal() {
    this.modal.close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  checkIfFilterExists() {
    if (this.filterId) {
      this.filterList.forEach((f) => {
        if (f.id === this.filterId) {
          f['exists'] = true;
        }
      });
    }
  }
}
