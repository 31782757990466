import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Assessor } from '../../assessor/models/assessor';
import { ClaimAction } from '../../claim/models/claim-action';
import { ClaimLevel } from '../../claim/models/claim-level';
import { DotNetClaim } from '../../claim/services/claim.service';
import { Skill } from '../../skill/models/skill';

@Component({
  selector: 'esqep-summary-action-form', // tslint:disable-line:component-selector
  templateUrl: './summary-action-form.component.html',
  styleUrls: ['./summary-action-form.component.scss'],
})
export class SummaryActionFormComponent implements OnInit {
  private fb = inject(FormBuilder);

  @Input() parentFormGroup: FormGroup; // = this.fb.group({});
  @Input() detailFormGroup: FormGroup;
  @Input() claim: DotNetClaim;
  @Input() evidenceText: string;
  @Input() selectedClaimLevel: ClaimLevel;
  @Input() selectedCompAttr: Skill;
  @Input() selectedVerifier: Assessor;
  @Input() action: ClaimAction;

  @Output() actionChange: EventEmitter<ClaimAction> = new EventEmitter();
  @Output() actionCancel: EventEmitter<boolean> = new EventEmitter();
  @Output() actionSubmit: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {}

  onActionChange(action: ClaimAction) {
    this.actionChange.emit(action);
  }

  onSubmitActionClick() {
    this.actionSubmit.emit(true);
  }

  onCancelActionClick() {
    this.actionCancel.emit(true);
  }

  getStatusSymbolClass(fg: AbstractControl): Array<string> {
    const classList = [];
    classList.push('far fa-2x');
    if (fg.invalid) {
      classList.push('fa-times-circle');
      classList.push('text-danger');
    } else if (fg.dirty) {
      classList.push('fa-check-circle');
      classList.push('text-warning');
    } else {
      classList.push('fa-check-circle');
      classList.push('text-success');
    }

    return classList;
  }

  getErrorList(topFormGroup: FormGroup): Array<string> {
    const errorArray: Array<string> = [];
    this.getErrorMap(topFormGroup, new Map()).forEach((v, k) =>
      errorArray.push(k),
    );
    return errorArray;
  }

  getErrorMap(topFormControl: FormGroup, errorMap: Map<string, boolean>): any {
    if (topFormControl.errors) {
      for (const errorKey of Object.keys(topFormControl.errors)) {
        errorMap.set(errorKey, true);
      }
    }
    if (topFormControl.controls) {
      for (const control in topFormControl.controls) {
        if (control) {
          errorMap = this.getErrorMap(
            <FormGroup>topFormControl.get(control),
            errorMap,
          );
        }
      }
    }
    return errorMap;
  }
}
