<div class="d-flex justify-content-end my-2 me-1">
  <div class="form-check form-switch form-control-lg">
    <input
      class="form-check-input hr45"
      type="checkbox"
      role="switch"
      id="unassignedUsersSwitch"
      [checked]="showUnassignedUsers"
      (change)="toggleUnassignedUsers($event)"
    />
    <label class="form-check-label" for="unassignedUsersSwitch"
      >{{ !showUnassignedUsers ? 'Show' : 'Hide' }} unassigned users</label
    >
  </div>
</div>
