<div class="row" *ngIf="showGraph">
  <div class="col">
    <ng-container *ngIf="hasPlottingData; else noPlotData">
      <ug-role-across-people
        [jobRolePeople]="[jobRolePerson]"
      ></ug-role-across-people>
    </ng-container>
    <ng-template #noPlotData>
      <p>
        {{ jobRolePerson.personName }} has no skills to plot for the selected
        role.
      </p>
    </ng-template>
  </div>
</div>
<ng-container></ng-container>

<div>
  <div>
    <table class="table table-responsive table-striped">
      <thead>
        <tr>
          <th scope="col" class="w-35">Required Competencies / Training</th>
          <th scope="col" sortable="personLevel" (sort)="onSort($event)">
            Person Level
          </th>
          <th scope="col" sortable="roleLevel" (sort)="onSort($event)">
            Job Role Level
          </th>
          <th scope="col" sortable="mandatory" (sort)="onSort($event)">
            Mandatory
          </th>
          <th scope="col" sortable="expiryDate" (sort)="onSort($event)">
            Expires On
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let jrvp of jobRolePerson.skills">
          <!-- <td class="vertical-align-middle text-center">
          <span [ngClass]="meetsReqClass(jrvp.roleLevel.score, jrvp.personLevel.score, jrvp.mandatory)"></span>
        </td> -->
          <td class="vertical-align-middle">
            {{ jrvp?.skillName }}
          </td>
          <td class="vertical-align-middle">
            <div
              class="progress border"
              [class.bg-light]="jrvp?.requirementLevelScore > 0"
            >
              <div
                class="progress-bar"
                [ngClass]="['bg-success']"
                role="progressbar"
                aria-label="Person level"
                [style.width.%]="jrvp?.personSkillWeightedScorePercentage"
                [attr.aria-valuenow]="jrvp?.personSkillWeightedScore"
                aria-valuemin="0"
                [attr.aria-valuemax]="jrvp?.maxLevelWeighted"
              >
                {{ jrvp.claimLevelName || 'n/a' }}
              </div>
            </div>
          </td>
          <!-- <td class="vertical-align-middle">
          {{jrvp.personLevel.name || 'n/a'}}
        </td> -->
          <td class="vertical-align-middle">{{ jrvp.requirementLevelName }}</td>

          <td
            class="vertical-align-middle"
            [ngClass]="jrvp.requirementMandatory ? 'text-danger' : ''"
          >
            {{ jrvp.requirementMandatory ? 'Yes' : 'No' }}
          </td>
          <td class="vertical-align-middle">
            {{ (jrvp.claimExpiryDate | date: 'dd/MM/yyyy') ?? 'Not set' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
