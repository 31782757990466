import { AfterViewInit, Component, Directive, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction } from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import { ProjectsService } from '../services/projects.service';

export interface Project {
  project: string;
  category: string;
  description: string;
  visibility: string;
  members: string;
  status: string;
  startDate: string;
  endDate: string;
}

export type SortColumn = keyof Project | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};
const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "desc"',
    '[class.desc]': 'direction === "asc"',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'ug-view-projects',
  templateUrl: './view-projects.component.html',
  styleUrls: ['./view-projects.component.scss'],
})
export class ViewProjectsComponent implements OnInit, AfterViewInit {
  private formBuilder = inject(FormBuilder);
  private calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  private typeaheadService = inject(TypeaheadService);
  private projectsService = inject(ProjectsService);

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public table: {
    headers: Array<string>;
    rows: Array<any>;
  };

  statusColourMap = new Map<string, string>();
  pageSize: number;
  page = 1;
  pageSizeOptions: Array<number> = [5, 10, 25, 50, 100];
  initialPageSize = 5;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  filteredProjects = [];
  projectFilters: FormGroup;
  projectTitles = [];
  projectCategories = [];
  projectStatus = [];
  projectVisibility = [];
  projectList: Array<Project>;
  projectSearch: OperatorFunction<string, string[]>;
  categorySearch: OperatorFunction<string, string[]>;
  statusSearch: OperatorFunction<string, string[]>;
  visibilitySearch: OperatorFunction<string, string[]>;

  static compareProjects(items, input: string) {
    return items.toLowerCase().includes(input);
  }

  static sortProjects(a: any, b, text: string) {
    return a.startsWith(text) - b.startsWith(text) || b - a;
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    if (direction === '' || column === '') {
      this.filteredProjects = this.filteredProjects;
    } else {
      this.filteredProjects = [...this.filteredProjects].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
  projectFormatter = (result) => result;

  constructor() {
    this.projectFilters = this.formBuilder.group({
      project: [''],
      category: [''],
      status: [''],
      startDate: [''],
      endDate: [''],
      visibility: [''],
    });
  }

  ngOnInit(): void {
    this.pageSize = this.initialPageSize;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);

    this.projectsService.getProjects().subscribe((x) => {
      this.projectList = x;
      this.filteredProjects = this.projectList;

      this.table = {
        headers: ['project', 'category', 'visibility'],
        rows: this.projectList,
      };

      this.projectTitles = this.projectList
        .map((x) => x.project)
        .filter((value, index, arr) => arr.indexOf(value) === index);

      this.projectCategories = this.projectList
        .map((x) => x.category)
        .filter((value, index, arr) => arr.indexOf(value) === index);

      this.projectStatus = this.projectList
        .map((x) => x.status)
        .filter((value, index, arr) => arr.indexOf(value) === index);

      this.projectVisibility = this.projectList
        .map((x) => x.visibility)
        .filter((value, index, arr) => arr.indexOf(value) === index);

      this.projectSearch = this.typeaheadService.typeahead(
        this.projectTitles,
        ViewProjectsComponent.compareProjects,
        ViewProjectsComponent.sortProjects,
      );

      this.categorySearch = this.typeaheadService.typeahead(
        this.projectCategories,
        ViewProjectsComponent.compareProjects,
        ViewProjectsComponent.sortProjects,
      );

      this.statusSearch = this.typeaheadService.typeahead(
        this.projectStatus,
        ViewProjectsComponent.compareProjects,
        ViewProjectsComponent.sortProjects,
      );

      this.visibilitySearch = this.typeaheadService.typeahead(
        this.projectVisibility,
        ViewProjectsComponent.compareProjects,
        ViewProjectsComponent.sortProjects,
      );

      this.filteredProjects = this.projectList.map((projects) => ({
        ...projects,
      }));
      this.filterProjects();

      this.statusColourMap.set('1', 'bg-success');
      this.statusColourMap.set('2', 'bg-warning');
      this.statusColourMap.set('3', 'bg-danger');
      this.statusColourMap.set('4', 'bg-secondary');
    });
  }

  filterProjects() {
    this.projectFilters.valueChanges.subscribe((filters) => {
      this.filteredProjects = this.projectList.map((projects) => ({
        ...projects,
      }));
      for (const filter in filters) {
        if (filters[filter]) {
          this.filteredProjects = this.filteredProjects.filter(
            (project: string) => {
              if (filter === 'startDate') {
                return (
                  new Date(project[filter]) >=
                  new Date(
                    filters['startDate'].year,
                    filters['startDate'].month - 1,
                    filters['startDate'].day,
                  )
                );
              } else if (filter === 'endDate') {
                return (
                  new Date(project[filter]) <=
                  new Date(
                    filters['endDate'].year,
                    filters['endDate'].month - 1,
                    filters['endDate'].day,
                  )
                );
              } else {
                return project[filter]
                  .toLowerCase()
                  .includes(filters[filter].toLowerCase());
              }
            },
          );
        }
      }
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getVisibilityIcon(visibility: string) {
    return visibility === 'Sensitive'
      ? 'mx-1 fas fa-lock text-warning'
      : 'mx-1 fas fa-unlock text-success';
  }

  getStatusColour(status: string) {
    if (status === 'On Target' || status === 'Completed') {
      return this.statusColourMap.get('1');
    } else if (status === 'At Risk') {
      return this.statusColourMap.get('2');
    } else if (status === 'Behind Schedule') {
      return this.statusColourMap.get('3');
    } else {
      return this.statusColourMap.get('4');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setDefaultSort();
    });
  }

  setDefaultSort() {
    const header = this.headers.find((x) => x.sortable == 'project');
    header.direction = 'asc';
    this.onSort({ column: 'project', direction: 'asc' });
  }

  clearFilters() {
    this.projectFilters.reset();
  }
}
