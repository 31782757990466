import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AssessorModule } from '../assessor/assessor.module';
import { ControlsModule } from '../controls/controls.module';
import { SkillModule } from '../skill/skill.module';
import { ClaimRoutingModule } from './claim-routing.module';

import { ClaimService } from './services/claim.service';

import { ClaimDocumentComponent } from './claim-document/claim-document.component';
import { ClaimFileListComponent } from './claim-file-list/claim-file-list.component';
import { ClaimHistoryComponent } from './claim-history/claim-history.component';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { PublishedClaimsComponent } from './published-claims/published-claims.component';

import { HighlightMandatoryDirective } from '../core/directives/highlight-mandatory.directive';
import { ClaimActivitySummaryComponent } from './claim-activity-summary/claim-activity-summary.component';
import { ClaimActorPipe } from './pipes/claim-actor.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AssessorModule,
    ControlsModule,
    SkillModule,
    ClaimRoutingModule,
  ],
  declarations: [
    ClaimActorPipe,
    ClaimDocumentComponent,
    ClaimFileListComponent,
    ClaimHistoryComponent,
    ClaimListComponent,
    HighlightMandatoryDirective,
    PublishedClaimsComponent,
    ClaimActivitySummaryComponent,
  ],
  exports: [
    ClaimActorPipe,
    ClaimDocumentComponent,
    ClaimFileListComponent,
    ClaimHistoryComponent,
    ClaimListComponent,
    HighlightMandatoryDirective,
    PublishedClaimsComponent,
    ClaimActivitySummaryComponent,
  ],
  providers: [ClaimService],
})
export class ClaimModule {}
