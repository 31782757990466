import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimActor',
})
export class ClaimActorPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    let actorName = '';
    switch (value) {
      case 'A':
        actorName = 'Assessor';
        break;
      case 'C':
        actorName = 'Claimant';
        break;
      case 'S':
        actorName = 'Auto-Assessor';
        break;
      default:
        actorName = 'Unknown';
    }
    return actorName;
  }
}
