<div>
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add users to an existing session</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <ug-table
      [isLoading]="tableLoading"
      [tableHeaders]="headers"
      [tableFilters]="filters"
      [tableSelectedButtons]="tableSelectedButtons"
      [tableRows]="tableRows"
      [singleSelection]="true"
      [selectLoading]="selectLoading"
    ></ug-table>
  </div>
</div>
