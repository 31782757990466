<div class="table-responsive">
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th scope="col" class="w-15">Date / Time</th>
        <th scope="col" class="w-10">Status</th>
        <th scope="col" class="w-15">Updated by</th>
        <th scope="col" class="w-10">Acting as</th>
        <th scope="col" class="w-50">Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let claimHistory of claimHistoryList; let i = index">
        <th scope="row" nowrap>
          {{ claimHistory.updateDatetime | date: 'medium' }}
        </th>
        <td>{{ claimHistory.statusText }}</td>
        <td>{{ claimHistory.updatePersonFamilyGivenName }}</td>
        <td>{{ claimHistory.initiatorType | claimActor | titlecase }}</td>
        <td>{{ claimHistory.comment }}</td>
      </tr>
    </tbody>
  </table>
</div>
