import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  of,
} from 'rxjs';

import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs';
import { FilterBase } from '../dynamic-form/filter-base';
import { FilterControlService } from '../dynamic-form/filter-control.service';
import { SortDirection, SortEvent } from './sort-event';
import { SortableHeaderDirective } from './sortable-header.directive';
import {
  TableHeader,
  TableHeaderButton,
  TableRowButton,
  TableSelectedButton,
} from './table.service';

interface State {
  page: number;
  pageSize: number;
  sortColumn: any;
  sortDirection: SortDirection;
  filters: Array<any>;
}

@Component({
  selector: 'ug-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  private filterControlService = inject(FilterControlService);
  private ref = inject(ChangeDetectorRef);
  private activeModel = inject(NgbActiveModal);

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  @Input() tableHeaders: Array<TableHeader>;
  @Input() tableRows: Array<any>;
  @Input() tablePageIndex: number;
  @Input() tablePageSize: number;
  @Input() tableHeaderButtons: Array<TableHeaderButton>;
  @Input() tableRowButtons: Array<TableRowButton>;
  @Input() tableFooterButtons;
  @Input() tableFilters: Array<FilterBase<any>> = [];
  @Input() tableSelectedButtons: Array<TableSelectedButton>;
  @Input() tableNoDataMessage: string;
  @Input() tableSelectedLoading: boolean;
  @Input() tableCollapsedRowTitle;
  @Input() tableCollapsedRowData;
  @Input() tableCollapsedRowSubHeadings;
  @Input() showPagination = true;
  @Input() isLoading: boolean;
  @Input() showSelectBox = false;
  @Input() singleSelection = false;
  @Input() selectLoading = false;
  @Input() totalRecords: number;
  @Input() disableAddSelected = true;
  @Input() showSelectAll = true;
  @Input() existsKey: string;
  @Input() tableRowDropdowns: string;
  @Input() dropdownBindName: string;
  @Input() dropdownBindValue: string;
  @Input() includeExistingAsSelected = false;
  @Input() disablePreSelected = true;
  @Input() pagedApi: boolean = false;

  @Output() readonly filtersCleared = new EventEmitter<boolean>();
  @Output() readonly pageSizeChange = new EventEmitter<number>();
  @Output() readonly pageIndexChange = new EventEmitter<number>();
  @Output() readonly sortChange = new EventEmitter<{
    column: string;
    sortDirection: string;
  }>();

  filteredRows: Array<any>;
  filteredRowsNonPaginated: Array<any>;
  form: FormGroup;
  selectedRowsCache: Array<any> = [];
  paginationTotalRecords: number;
  rowIndex: number;
  pageSizeOptions: Array<number> = [5, 10, 25, 50, 100];
  selectAllRows = false;
  private _search$ = new Subject<void>();
  private _state: State = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortDirection: '',
    filters: [],
  };

  private _total$ = new BehaviorSubject<number>(0);

  get total$() {
    return this._total$.asObservable();
  }

  get page() {
    return this._state.page;
  }

  set page(page: number) {
    this._set({ page });
  }

  get pageSize() {
    return this._state.pageSize;
  }

  set pageSize(pageSize: number) {
    Object.assign(this._state, { page: 1 });
    this._set({ pageSize });
  }

  get filters() {
    return this._state.filters;
  }

  set filters(filters) {
    this._set({ filters });
  }

  set sortColumn(sortColumn: any) {
    this._set({ sortColumn });
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  get selectedRows() {
    return this.selectedRowsCache.length;
  }

  ngOnInit() {
    this._total$.next(this.totalRecords);
    this._search$.pipe(switchMap(() => this._search())).subscribe((result) => {
      this.filteredRows = [...result.data];
      this._total$.next(result.total);
    });

    this.form = this.filterControlService.toFormGroup(
      this.tableFilters as FilterBase<string>[],
    );

    this.form?.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((filters) => {
        this._set({ filters });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['tableFilters'] &&
      changes['tableFilters'].currentValue &&
      changes['tableFilters'].previousValue !==
        changes['tableFilters'].currentValue
    ) {
      this.form = this.filterControlService.toFormGroup(
        this.tableFilters as FilterBase<string>[],
      );
      this.form?.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((filters) => {
          this._set({ filters });
        });
    }

    if (
      changes['tableRows'] &&
      changes['tableRows'].previousValue !== changes['tableRows'].currentValue
    ) {
      this.tableRows.forEach((row) => {
        row['isSelected'] = row[this.existsKey] ?? false;
        if (this.includeExistingAsSelected) {
          row['isSelected'] ? this.selectedRowsCache.push(row) : null;
        }
      });
      this._search$.next();
    }

    if (
      changes['tablePageIndex'] &&
      changes['tablePageIndex'].previousValue !==
        changes['tablePageIndex'].currentValue
    ) {
      this._state.page = this.tablePageIndex;
    }

    if (
      changes['tablePageSize'] &&
      changes['tablePageSize'].previousValue !==
        changes['tablePageSize'].currentValue
    ) {
      this._state.pageSize = this.tablePageSize;
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortChange.emit({
      column: column,
      sortDirection: direction,
    });
    if (!this.pagedApi) {
      this.sortColumn = column;
      this.sortDirection = direction;
    }
  }

  compare = (v1: string | number | null, v2: string | number | null) => {
    if (v1 === null && v2 === null) {
      return 0;
    } else if (v1 === null) {
      return 1;
    } else if (v2 === null) {
      return -1;
    } else {
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    }
  };

  sort(data: any[], column: any, direction: string): any[] {
    if (direction === '' || column === '') {
      return data;
    } else {
      return [...data].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  checkUncheckAllTableRows() {
    this.selectedRowsCache = this.selectAllRows
      ? this.filteredRowsNonPaginated.filter((row) => !this.disableSelect(row))
      : [];
    if (this.selectAllRows) {
      this.tableRows.forEach((dr) => {
        const selectedRowIndexCache = this.selectedRowsCache.findIndex((row) =>
          this.objectsAreEqual(row, dr),
        );
        if (selectedRowIndexCache !== -1 && !this.disableSelect(dr)) {
          dr['isSelected'] = true;
        }
      });
      this.filteredRows.forEach((dr) => {
        const selectedRowIndexCache = this.selectedRowsCache.findIndex((row) =>
          this.objectsAreEqual(row, dr),
        );
        if (selectedRowIndexCache !== -1 && !this.disableSelect(dr)) {
          dr['isSelected'] = true;
        }
      });
    } else {
      this.tableRows.forEach((dr) => {
        dr['isSelected'] = false;
      });
      this.filteredRows.forEach((dr) => {
        dr['isSelected'] = false;
      });
    }
  }

  objectsAreEqual(object1, object2) {
    const objectKeys1 = Object.keys(object1);
    const objectKeys2 = Object.keys(object2);
    if (objectKeys1.length !== objectKeys2.length) {
      return false;
    }
    for (const key of objectKeys1) {
      if (key !== 'isSelected') {
        const value1 = object1[key];
        const value2 = object2[key];
        if (value1 !== value2) {
          return false;
        }
      }
    }
    return true;
  }

  onRowSelect(inputRow) {
    const selectedRowIndexCache = this.selectedRowsCache.findIndex((row) =>
      this.objectsAreEqual(row, inputRow),
    );
    const tableRowEl = this.tableRows.find((dr) => {
      return this.objectsAreEqual(inputRow, dr);
    });
    const filteredRowsEl = this.filteredRows.find((dr) => {
      return this.objectsAreEqual(inputRow, dr);
    });

    if (selectedRowIndexCache !== -1) {
      this.selectAllRows = false;
      if (tableRowEl) {
        tableRowEl['isSelected'] = false;
      }

      if (filteredRowsEl) {
        filteredRowsEl['isSelected'] = false;
      }
      this.selectedRowsCache.splice(selectedRowIndexCache, 1);
    } else {
      this.selectedRowsCache.push(inputRow);

      if (tableRowEl) {
        tableRowEl['isSelected'] = true;
      }

      if (filteredRowsEl) {
        filteredRowsEl['isSelected'] = true;
      }

      this.selectAllRows =
        this.selectedRowsCache.length === this.filteredRowsNonPaginated.length;
    }
  }

  actionButton(callback, row) {
    callback(row);
    this.ref.detectChanges();
  }

  iconFunction(callback, row) {
    return callback(row);
  }

  iconTooltip(callback, row) {
    return callback(row);
  }

  stringFunction(callback, row) {
    return callback(row);
  }

  getSelectedItems(callback) {
    const selected = this.selectedRowsCache;
    callback(selected);
  }

  cancel() {
    this.activeModel.close();
  }

  headerButton(callback) {
    callback();
  }

  hideCondition(callback, row) {
    return callback(row);
  }

  hideConditionHeader(callback) {
    return callback();
  }
  trackByFn(index: any, item: any) {
    return index;
  }

  toggleRow(i: number) {
    this.rowIndex = this.rowIndex === i ? null : i;
  }

  onPageChange($event: number) {
    this.page = $event;
    this.pageIndexChange.emit($event);
  }

  onPageSizeChange($event: number) {
    this.pageSize = $event;
    this.pageSizeChange.emit($event);
  }

  clearFilters($event: boolean) {
    this.page = 1;
    this.selectAllRows =
      this.tableRows.filter((f) => f.isSelected === true).length ===
      this.tableRows.length;
    this.filtersCleared.emit(true);
  }

  disableSelect(row) {
    const anySelectedRows = this.tableRows.filter((f) => f.isSelected).length;

    if (this.singleSelection && anySelectedRows > 0) {
      return !row.isSelected;
    }
    return this.existsKey ? row[this.existsKey] : row.exists;
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<any> {
    const { sortColumn, sortDirection, pageSize, page, filters } = this._state;

    const headers = this.tableHeaders.map((header) => header.id);

    let data = this.tableRows.map((row) => ({ ...row }));

    let total = this.totalRecords;

    if (!this.pagedApi) {
      let filtersApplied = false;

      data = this.sort(
        this.tableRows.map((row) => ({ ...row })),
        sortColumn,
        sortDirection,
      );

      data = data?.filter((obj) => {
        return Object.keys(filters).every((key) => {
          if (filters && filters[key] && filters[key].callback) {
            filtersApplied = true;
            return true;
          }

          if (
            key === 'searchTerm' &&
            filters[key] !== null &&
            !filters[key].callback
          ) {
            return headers.some((id) => {
              if (obj[id] !== null && obj[id] !== undefined) {
                filtersApplied = true;
                return obj[id]
                  .toString()
                  .toLowerCase()
                  .includes(filters[key].toLowerCase());
              }
            });
          } else {
            filtersApplied = !(filters[key] === '' || filters[key] === null);
            return (
              filters[key] === '' ||
              filters[key] === null ||
              obj[key] === filters[key] ||
              (typeof filters[key] === 'string' &&
                obj[key].toLowerCase().includes(filters[key].toLowerCase()))
            );
          }
        });
      });

      this.filteredRowsNonPaginated = data;
      total = this.totalRecords ? this.totalRecords : data.length;
    }

    if (this.showPagination === true && data.length > pageSize) {
      data = data
        ?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
        .map((row) => ({ ...row }));
    } else {
      data = data;
    }

    this.selectAllRows =
      (this.selectedRowsCache.length > 0 &&
        this.selectedRowsCache.length ===
          this.filteredRowsNonPaginated.length) === true;

    return of({ data, total });
  }

  onRowDropdownChange(callback, row, rowIndex) {
    this.tableRows[rowIndex] = row;
    return callback(row);
  }

  onRowCheckboxChange(callback, row, rowIndex) {
    this.tableRows[rowIndex] = row;
    return callback(row);
  }

  disabledDropdownFunction(callback, row) {
    return callback(row);
  }

  selectedWarningMessage(callback) {
    return callback(this.selectedRowsCache);
  }

  onRowInputNumberChange(callback, row, rowIndex) {
    this.tableRows[rowIndex] = row;
    return callback(row);
  }
}
