import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  OrgUnitService,
  OrganisationUnit,
} from '../../../org-unit/services/org-unit.service';
import {
  PersonLookup,
  PersonService,
} from '../../../person/services/person.service';

@Component({
  selector: 'ug-matrix-filters',
  templateUrl: './matrix-filters.component.html',
  styleUrls: ['./matrix-filters.component.scss'],
})
export class MatrixFiltersComponent implements OnInit {
  exceptionData = {
    MTX_ORG_UNITS: {
      level: AlertLevels.ERROR,
      code: 'MXF-001',
      message: 'Error retrieving org units',
    } as AlertData,
    MTX_ORG_UNITS_VISIBLE: {
      level: AlertLevels.ERROR,
      code: 'MXF-002',
      message: 'Error retrieving visible org units',
    } as AlertData,
  };

  @Input() fullFunc: boolean;
  @Input() orgUnitType: string;
  @Output() updateMatrix = new EventEmitter<{
    orgUnitId: number;
    additionalPeopleIds: Array<number>;
  }>();

  private destroyRef = inject(DestroyRef);
  private formBuilder = inject(FormBuilder);
  private alertService = inject(AlertService);
  private orgUnitService = inject(OrgUnitService);
  private personService = inject(PersonService);

  private ouBuffer = [];
  private numberOfItemsFromEndBeforeFetchingMore = 10;
  private bufferSize = 50;
  protected matrixFiltersForm: FormGroup;
  protected orgUnitList: Array<OrganisationUnit>;
  protected allowedOuList: any;
  protected loadingOus = false;
  protected people$: Observable<any[] | PersonLookup[]>;
  protected peopleLoading = false;
  protected peopleInput$ = new Subject<string>();

  ngOnInit(): void {
    this.loadPeople();
    this.loadingOus = true;
    this.matrixFiltersForm = this.formBuilder.group({
      orgUnit: [],
      additionalPeople: [],
    });

    forkJoin([
      this.orgUnitService.getOrgUnitsForViewing(),
      this.orgUnitService.getOrgUnitLookups({
        isViewUserData: true,
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef), defaultIfEmpty([]))
      .subscribe({
        next: ([ouList, allowedOuList]) => {
          this.allowedOuList = allowedOuList;
          this.orgUnitList = ouList.filter((ou) =>
            this.allowedOuList.some(
              (allowedOu) => Number(allowedOu.key) === ou.id,
            ),
          );
          this.loadingOus = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.MTX_ORG_UNITS_VISIBLE,
            err,
          );
        },
      });

    this.matrixFiltersForm.get('orgUnit').valueChanges.subscribe((ou) => {
      this.updateMatrixData();
    });
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.loadingOus || this.orgUnitList.length <= this.ouBuffer.length) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.ouBuffer.length
    ) {
      this.fetchMore();
    }
  }

  fetchMore() {
    const len = this.ouBuffer.length;
    const more = this.orgUnitList.slice(len, this.bufferSize + len);
    this.ouBuffer = this.ouBuffer.concat(more);
  }

  trackByFn(item: any) {
    return item.key;
  }

  updateMatrixData() {
    const extraPeople =
      this.matrixFiltersForm.get('additionalPeople')?.value || [];
    const additionalPeopleIds = extraPeople.map((p) => Number(p)) || [];

    const matrixUpdateData = {
      orgUnitId: this.matrixFiltersForm.get('orgUnit').value,
      additionalPeopleIds: additionalPeopleIds,
    };

    this.updateMatrix.emit(matrixUpdateData);
    this.matrixFiltersForm.get('additionalPeople').markAsUntouched();
  }

  private loadPeople() {
    this.people$ = this.peopleInput$.pipe(
      filter((res: string) => {
        return res !== null && res.length >= 2;
      }),
      distinctUntilChanged(),
      tap(() => (this.peopleLoading = true)),
      switchMap((term) =>
        this.personService
          .getPersonLookup({
            isViewUserData: true,
            maxCount: 50,
            search: term,
          })
          .pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.peopleLoading = false)),
          ),
      ),
    );
  }
}
