import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfig, ChartData } from '../../echarts/chart-config';
import { JobRoleAnalysisPerson } from '../job-role-analysis/job-role-analysis.component';
import { JobRolePerson } from '../services/job-role.service';

@Component({
  selector: 'ug-role-across-people',
  templateUrl: './role-across-people.component.html',
  styleUrls: ['./role-across-people.component.scss'],
})
export class RoleAcrossPeopleComponent implements OnChanges {
  public gridData;
  @Input() jobRolePeople: JobRolePerson | JobRolePerson[];
  chartConfig: ChartConfig;
  @Input() legendPosition: string = 'right';
  @Input() showDataSlider: boolean = true;

  public toggleGraphType() {
    if (this.chartConfig.type === 'bar') {
      this.chartConfig = {
        ...this.chartConfig,
        type: 'radar',
      };

      delete this.chartConfig.customDataTooltip;
    } else {
      this.chartConfig = {
        ...this.chartConfig,
        type: 'bar',
        customDataTooltip: this.customDataTooltip,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareGraphData(changes['jobRolePeople'].currentValue);
  }

  private prepareGraphData(data: JobRoleAnalysisPerson[]) {
    const chartData = [];

    data.forEach((jrp, i) => {
      jrp.skills.forEach((s) => {
        if (i === 0) {
          chartData.push({
            name: 'Role Baseline',
            type: 'line',
            value: s.roleSkillWeightedScore,
            colour: '#000',
            id: null,
            customData: [s.requirementLevelName, s.skillName],
          });
        }

        chartData.push({
          name: jrp.personName,
          value:
            s.personSkillWeightedScore === null ||
            Number.isNaN(s.personSkillWeightedScore)
              ? 0
              : s.personSkillWeightedScore,
          id: jrp.personId + s.skillId,
          seriesName: this.truncateSkillNameWithEllipsis(s.skillName),
          customData: [s.claimLevelName, s.skillName],
        });
      });
    });

    this.chartConfig = {
      ...this.chartConfig,
      type: 'bar',
      data: chartData,
      legendPosition: this.legendPosition,
      yAxis: {
        dualAxis: true,
        hide: true,
      },
      showDataSlider: this.showDataSlider,
      customDataTooltip: this.customDataTooltip,
      xAxisKey: 'seriesName',
    };
  }

  customDataTooltip(data: ChartData) {
    return `<b>${data.name}</b> <br /> ${data.customData[1]} <br /> Level: ${data.customData[0]}  `;
  }

  truncateSkillNameWithEllipsis(skillName: string): string {
    const words = skillName.split(' ');
    return words.length > 2 ? `${words.slice(0, 3).join(' ')}...` : skillName;
  }
}
