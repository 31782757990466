<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">
      {{ subject ? 'Edit ' : 'Add ' }}{{ subjectLevelName }}
    </h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.close()"
    ></button>
  </div>
  <div class="modal-body pb-1">
    <div [formGroup]="subjectForm" class="border-bottom">
      <div class="row mb-2">
        <div class="col-10">
          <label for="exampleFormControlInput1" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            [placeholder]="'Provide a name for the ' + subjectLevelName"
            formControlName="title"
            [ngClass]="{ 'is-invalid': !formControlValid('title') }"
          />
          <div class="invalid-feedback">Name is required</div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="subjectLevelId === 1" class="col-12">
          <label for="dimensionIds">Dimensions</label>
          <ng-select
            [items]="skillDimensions"
            bindLabel="name"
            bindValue="id"
            labelForId="dimensionIds"
            [multiple]="true"
            placeholder="Select dimensions"
            clearAllText="Clear"
            formControlName="dimensionIds"
            [ngClass]="{ 'is-invalid': !formControlValid('dimensionIds') }"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span
                class="ng-value-label rounded customLabel"
                [ngClass]="setItemColour(item.id)"
              >
                {{ item.name }}</span
              >
              <span
                class="ng-value-icon border-0 m-0 ms-1 p-0 right text-danger bg-white"
                (click)="clear(item)"
                aria-hidden="true"
                >X</span
              >
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span> {{ item.name }}</span>
            </ng-template>
          </ng-select>
          <div *ngIf="dimensions.invalid" class="invalid-feedback">
            At least one Dimension is required
          </div>
          <p class="fst-italic">
            Assigned dimensions will be applied to children at all levels
          </p>
        </div>
        <div *ngIf="subjectLevelId > 1" class="mt-2">
          <div>
            Assigned Dimensions :
            <span
              *ngFor="let dim of dimensions.value"
              class="rounded-pill p-1 mx-1"
              [ngClass]="setItemColour(dim)"
            >
              <ng-container *ngIf="getDimensionById(dim) as dimension">
                {{ dimension.name }}
              </ng-container></span
            >
          </div>
          <p class="fst-italic pt-1">
            Children inherit their parent's dimensions assigned at the category
            level.
          </p>
        </div>
      </div>
      <div class="mt-1">
        <ng-container *ngIf="parentSubject">
          <p>Parent: {{ parentSubject.title }}</p>
          <p
            *ngIf="!dimensions.value || dimensions.value?.length < 1"
            class="text-danger"
          >
            Warning - no dimension assigned at category level, unable to create
            {{ subjectLevelName }}.
          </p>
        </ng-container>

        <p *ngIf="subject">
          Assigned Competencies/Training : {{ subjectSkillCount }}
        </p>
        <p *ngIf="subject">
          Imported :
          <span
            class="fas text-center fa-xl"
            [ngClass]="
              subject.source
                ? 'fa-check-circle text-success'
                : 'fa-times-circle text-danger'
            "
          ></span>
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <div *ngIf="subject && disableDelete" class="text-danger">
      Unable to delete imported subjects or those with assigned
      competencies/training
    </div>
    <button
      *ngIf="!confirmingDelete"
      type="button"
      class="btn btn-danger"
      (click)="deleteSubject()"
      [disabled]="disableDelete"
    >
      Delete
    </button>
    <button
      *ngIf="confirmingDelete"
      type="button"
      class="btn btn-warning"
      (click)="cancelDelete()"
    >
      Cancel
    </button>
    <button
      *ngIf="confirmingDelete"
      type="button"
      class="btn btn-danger"
      (click)="confirmDelete()"
    >
      Confirm Delete
    </button>
    <button
      *ngIf="!confirmingDelete"
      type="button"
      class="btn btn-success"
      (click)="saveSubject()"
      [disabled]="subjectForm.invalid || !subjectForm.dirty"
    >
      Save
    </button>
  </div>
</div>
