<div class="card">
  <div class="card-body">
    <ng-container *ngIf="selectedDimensionId !== 0; else noSelectedDimension">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title p-0">
          Assigned {{ skillType === 'Competency' ? 'Competencies' : skillType }}
        </h5>

        <button
          type="button"
          class="btn esqepBtn"
          (click)="openSkillAssignmentModal()"
          [hidden]="skillType === 'Training'"
        >
          Edit
        </button>
      </div>
      <ug-table
        [tableHeaders]="tableHeaders"
        [tableRows]="selectedDimensionSkills"
        [isLoading]="tableLoading"
        [tableNoDataMessage]="tableNoDataMessage"
      ></ug-table>
    </ng-container>
    <ng-template #noSelectedDimension>
      <div class="text-center">
        <div>
          Select a dimension from the top of the page to view assigned skills
          for this subject.
        </div>
      </div>
    </ng-template>
  </div>
</div>
