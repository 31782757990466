<ngb-progressbar
  [type]="type"
  [value]="currentPercentage"
  [animated]="true"
  [striped]="true"
>
  {{
    isFailed ? 'Action failed' : currentPercentageLabel + ' ' + progressTitle
  }}
</ngb-progressbar>
