<div [formGroup]="parentFormGroup.get('projectSettings')">
  <div class="row">
    <div class="col-6">
      <h5 class="fw-semibold">Project visbility</h5>
      <div [formGroup]="parentFormGroup.get('projectSettings.visibility')">
        <div class="form-check">
          <label class="form-check-label" for="public">
            Keep the project publicly visible (default)
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="status"
            id="public"
            formControlName="status"
            value="public"
            checked
          />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="private">
            Make the project private to select individuals only
            <span>(Project members always have visibility)</span>
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="status"
            id="private"
            formControlName="status"
            value="private"
          />
        </div>
        <div *ngIf="visibilityStatus === 'private'">
          <div class="d-flex align-items-end mt-2">
            <ng-select [multiple]="true" formControlName="people">
              <ng-option
                *ngFor="let person of peopleList"
                [value]="person.displayName"
                >{{ person.displayName }}</ng-option
              >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h5 class="fw-semibold">Project Owners</h5>
      <div>
        By default the project owner is always the creater of the project
      </div>
      <div>
        Owners have full permission to view, edit, update and delete the project
      </div>
      <div class="text-muted">
        <em>(We recommend no more than 3 project owners)</em>
      </div>
      <div [formGroup]="parentFormGroup.get('projectSettings.ownership')">
        <div class="d-flex align-items-end mt-2">
          <ng-select
            [multiple]="true"
            maxSelectedItems="3"
            formControlName="people"
          >
            <ng-option
              *ngFor="let person of peopleList"
              [value]="person.displayName"
              >{{ person.displayName }}</ng-option
            >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <h5 class="fw-semibold">Define project phases and lifecycles</h5>
    <button type="button" (click)="addPhase()" class="btn esqepBtn">Add</button>
    <div class="my-2 fst-italic">
      Add phases and amend the project's expected lifecycle - this can be
      tracked and updated by the Project Owner.
    </div>
    <form [formGroup]="parentFormGroup.get('projectSettings.phasesForm')">
      <div formArrayName="phases" class="d-flex flex-wrap">
        <div *ngFor="let phase of phases.controls; let i = index">
          <div [formGroupName]="i" class="d-flex flex-column my-2">
            <div class="d-flex m-0 p-0">
              <button
                (click)="removePhase(i)"
                class="bg-white border-0 m-0 p-0"
              >
                <i class="fas fa-times text-danger"></i>
              </button>
              <span class="mx-1">Remove</span>
            </div>
            <div class="d-flex align-items-center">
              <textarea
                class="rectangle"
                formControlName="title"
                class="rectangle"
                [style.background-color]="getPhaseColour(i)"
                [placeholder]="'Phase ' + (i + 1)"
              ></textarea>
              <div
                class="triangle-right"
                [style.border-left]="'40px solid ' + getPhaseColour(i)"
              ></div>
            </div>
            <div id="colourPicker">
              <button type="button" class="action-btn mt-2">
                <i
                  class="fas fa-circle fa-xl"
                  [style.color]="getPhaseColour(i)"
                ></i>
              </button>
              <input type="color" value="#ff0000" formControlName="colour" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
