import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  Observable,
  Subscription,
  catchError,
  mergeMap,
  of,
  throwError,
} from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { LogLevel, Logger } from '../../core/services/config.service';
import { PersonService } from '../../person/services/person.service';
import { ClaimService } from '../services/claim.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimDetailGuard implements OnDestroy {
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private claimService = inject(ClaimService);
  private personService = inject(PersonService);
  private router = inject(Router);

  subscriptions: Subscription = new Subscription();

  exceptionData = {
    PERSON_BY_GUID: {
      level: AlertLevels.ERROR,
      code: 'CDG-001',
      message: 'Error retrieving user details',
    } as AlertData,
    IS_CLAIMANT: {
      level: AlertLevels.ERROR,
      code: 'CDG-002',
      message: 'Error validating user as claimant',
    } as AlertData,
    IS_VERIFIER: {
      level: AlertLevels.ERROR,
      code: 'CDG-003',
      message: 'Error validating user as verifier',
      translationKey: 'errorValidatingVerifier',
    } as AlertData,
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const navigationExtras: NavigationExtras = {
      queryParams: { redirectUrl: route.url },
    };

    // Logger(`claim-detail-guard: route.params.claimId=${route.params.claimId} user.oid=${this.authService.userGUID}`, LogLevel.VERBOSE);

    if (route.url[0].path !== 'assessment-request') {
      this.router.navigate(['/home']);
      return of(false);
    }
    if (!route.params['claimId']) {
      return of(true);
    }
    if (!this.authService.isAuthenticated) {
      return of(false);
    }

    const personFromUUID = this.personService
      .getPersonByUUID(this.authService.userGUID)
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
    const userIsVerifier = personFromUUID.pipe(
      mergeMap((person) => {
        return this.claimService.isClaimAssessor(
          route.params.claimId,
          person.id,
        );
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
    const userIsClaimant = personFromUUID.pipe(
      mergeMap((person) => {
        return this.claimService.isClaimClaimant(
          route.params.claimId,
          person.id,
        );
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );

    if (route.url[2].path === 'assessor') {
      this.subscriptions.add(
        userIsVerifier.subscribe({
          next: (isAllowed) => {
            if (!isAllowed) {
              Logger(
                `Not authorised to view Claim Id:${route.params.claimId} as verifier`,
                LogLevel.INFO,
              );
              this.router.navigate(['/exceptio#not_auth']);
            }
          },
          error: (err) => {
            this.alertService.createAlert2(this.exceptionData.IS_VERIFIER, err);
          },
        }),
      );
      return userIsVerifier;
    } else if (route.url[2].path === 'claimant') {
      this.subscriptions.add(
        userIsClaimant.subscribe({
          next: (isAllowed) => {
            if (!isAllowed) {
              Logger(
                `Not authorised to view Claim Id:${route.params.claimId} as claimant`,
                LogLevel.INFO,
              );
              this.router.navigate(['/exception/not_auth']);
            }
          },
          error: (err) => {
            this.alertService.createAlert2(this.exceptionData.IS_CLAIMANT, err);
          },
        }),
      );
      return userIsClaimant;
    } else {
      this.router.navigate(['/home']);
      return of(false);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
