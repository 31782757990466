import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { TestComponent } from '../core/test/test.component';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { ExceptionComponent } from './exception/exception.component';
import { HomeComponent } from './home/home.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  //  {path: 'adresponse', component: OAuthCallbackComponent, canActivate: [OAuthCallbackHandler]},
  {
    path: 'exception/:type',
    canActivate: mapToCanActivate([PreAuthGuard]),
    component: ExceptionComponent,
  },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  }, // ,
  { path: 'login', component: LoginComponent },
  { path: 'logged-out/:reason', component: LoggedOutComponent },
  { path: 'test', component: TestComponent },
  {
    path: 'divisions-import',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'skills-matrix-divisions',
    redirectTo: 'page-not-found', // 'divisions-import',
    pathMatch: 'full',
  },
  {
    path: 'watch-hierarchy',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'group-subjects',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'job-families',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'job-grades',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'page-not-found',
    canActivate: mapToCanActivate([PreAuthGuard]),
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class CoreRoutingModule {}
