import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  ClaimLevelDictionary,
  ClaimService,
} from 'app/claim/services/claim.service';
import { BehaviorSubject, OperatorFunction, Subject } from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { JobRole } from '../../job-role/services/job-role.service';
import { Skill } from '../../skill/models/skill';
import { SkillService } from '../../skill/services/skill.service';
import { ProjectsService } from '../services/projects.service';

@Component({
  selector: 'ug-project-competencies',
  templateUrl: './project-competencies.component.html',
  styleUrls: ['./project-competencies.component.scss'],
})
export class ProjectCompetenciesComponent implements OnInit {
  private uiService = inject(UitoolsService);
  private fb = inject(FormBuilder);
  private typeaheadService = inject(TypeaheadService);
  private projectsService = inject(ProjectsService);
  private skillService = inject(SkillService);
  private claimService = inject(ClaimService);

  @Input() parentFormGroup: FormGroup;
  @Input() compDims: any;
  @ViewChild('competenciesModal') competenciesModal: ElementRef;

  private applicationSkillListBS = new BehaviorSubject<Array<Skill>>([]);
  private knowledgeSkillListBS = new BehaviorSubject<Array<Skill>>([]);
  private ngUnsubscribe: Subject<boolean> = new Subject();
  levelSets: ClaimLevelDictionary;

  @Input()
  set applicationSkillList(value) {
    this.applicationSkillListBS.next(value);
  }

  get applicationSkillList() {
    return this.applicationSkillListBS.getValue();
  }

  @Input()
  set knowledgeSkillList(value) {
    this.knowledgeSkillListBS.next(value);
  }

  get knowledgeSkillList() {
    return this.knowledgeSkillListBS.getValue();
  }

  appSkills: Array<Skill>;
  knldgeSkills: Array<Skill>;
  dimensionId: number = 38;
  filteredCompList: Array<Skill>;
  projectJobRoles: Array<JobRole>;
  selectAllFilteredComps = false;
  disciplineList = [];
  funcAreaList = [];
  skillList = [];
  disciplineSearch: OperatorFunction<string, JobRole[]>;
  disciplineFormatter = (result) => result;
  skillSearch: OperatorFunction<string, JobRole[]>;
  skillFormatter = (result) => result;
  funcAreaSearch: OperatorFunction<string, JobRole[]>;
  funcAreaFormatter = (result) => result;
  competenciesAdded = 0;
  addingCompetencies = false;

  ngOnInit(): void {
    const jobRoles = this.parentFormGroup.get(
      'projectRoles.assignedJobRoles.jobRoles',
    ) as FormArray;
    this.projectJobRoles = jobRoles.value;
    this.applicationSkillList = this.projectsService.applicationSkillList;
    this.filteredCompList = this.applicationSkillList;
    this.knowledgeSkillList = this.projectsService.knowledgeSkillList;
    this.setModalFilters();
    this.filterCompetencies();
    this.claimService
      .getAllClaimLevels()
      .subscribe((s) => (this.levelSets = s));
  }

  setModalFilters() {
    const allFuncAreas = this.filteredCompList.map((c) => c.funcAreaDesc);
    this.funcAreaList = [...new Set(allFuncAreas)];

    const allDisciplines = this.filteredCompList.map((c) => c.funcAreaName);
    this.disciplineList = [...new Set(allDisciplines)];

    const allSkills = this.filteredCompList.map((c) => c.name);
    this.skillList = [...new Set(allSkills)];

    this.skillSearch = this.typeaheadService.typeahead(
      this.skillList,
      ProjectCompetenciesComponent.compare,
      ProjectCompetenciesComponent.sort,
    );

    this.disciplineSearch = this.typeaheadService.typeahead(
      this.disciplineList,
      ProjectCompetenciesComponent.compare,
      ProjectCompetenciesComponent.sort,
    );

    this.funcAreaSearch = this.typeaheadService.typeahead(
      this.funcAreaList,
      ProjectCompetenciesComponent.compare,
      ProjectCompetenciesComponent.sort,
    );

    this.clearFilters();
  }

  clearFilters() {
    this.selectAllFilteredComps = false;
    this.parentFormGroup
      .get('projectCompetencies.competenciesModalFilters')
      .reset();
  }

  openAddCompetenciesModal() {
    this.filteredCompList.forEach((c) => {
      c['isSelected'] = false;
    });
    this.selectAllFilteredComps = false;
    this.setModalFilters();

    this.uiService.openModalExtraLarge(this.competenciesModal);
  }

  get competencies(): FormArray {
    return this.parentFormGroup.get(
      'projectCompetencies.assignedCompetencies.competencies',
    ) as FormArray;
  }

  onDimChange(dim) {
    this.dimensionId = dim.id;
    this.selectAllFilteredComps = false;

    if (dim.id === 38) {
      this.filteredCompList = this.applicationSkillList;
    } else if (dim.id === 40) {
      this.filteredCompList = this.knowledgeSkillList;
    }

    this.filteredCompList.forEach((c) => {
      c['isSelected'] = false;
    });

    this.setModalFilters();
  }

  addCompetency(skill) {
    this.competencies.push(this.newComp(skill));
  }

  newComp(skill: Skill): FormGroup {
    return this.fb.group({
      skillId: skill.id,
      discipline: skill.funcAreaName,
      skillName: skill.name,
      competency: skill.funcAreaName + '-' + skill.name,
      levels: [skill['levels']],
      requiredLevel: skill['levels'][0].key,
      assignment: 'Any',
    });
  }

  removeCompetency(index: number) {
    this.competencies.removeAt(index);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  checkUncheckAllFilteredComps() {
    this.filteredCompList.forEach((c) => {
      c['isSelected'] = this.selectAllFilteredComps;
    });
  }

  isAllFilteredCompsSelected() {
    this.selectAllFilteredComps = this.filteredCompList.every((c: any) => {
      return c['isSelected'] === true;
    });
  }

  addSelectedComps() {
    this.addingCompetencies = true;
    const selectedCompetencies = this.filteredCompList.filter(
      (c) => c['isSelected'],
    );
    const competenciesAdded = selectedCompetencies.length;

    selectedCompetencies.forEach((c) => {
      c['levels'] = this.levelSets['0'];
      this.competencies.push(this.newComp(c));
    });

    this.uiService.showToast(
      `Successfully added ${competenciesAdded} competencies`,
      { classname: 'bg-success text-light', delay: 3000 },
    );
    this.filteredCompList.map((c) => {
      c['isSelected'] = false;
    });

    this.addingCompetencies = false;
  }

  static sort(a, b, text: string) {
    return a.startsWith(text) - b.startsWith(text) || b - a;
  }

  static compare(items, input: string) {
    return items.toLowerCase().includes(input);
  }

  filterCompetencies() {
    this.parentFormGroup
      .get('projectCompetencies.competenciesModalFilters')
      .valueChanges.subscribe((filters) => {
        if (this.dimensionId === 38) {
          this.filteredCompList = this.applicationSkillList;
        } else if (this.dimensionId === 40) {
          this.filteredCompList = this.knowledgeSkillList;
        }

        for (const filter in filters) {
          if (filters[filter] !== '' && filters[filter] !== null) {
            this.filteredCompList = this.filteredCompList.filter((c) => {
              if (filter == 'discipline') {
                return c['funcAreaName'] === filters[filter];
              } else if (filter === 'skill') {
                return c['name'] === filters[filter];
              } else if (filter === 'functionalArea') {
                return c['funcAreaDesc'] === filters[filter];
              }
            });
          }
        }
      });
  }
}
