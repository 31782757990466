import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subject, takeUntil } from 'rxjs';

import { FuncArea } from '../models/func-area';
import { Skill } from '../models/skill';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { SkillService } from '../services/skill.service';

@Component({
  selector: 'ug-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: [],
})
export class SkillListComponent implements OnChanges, OnDestroy, OnInit {
  private alertService = inject(AlertService);
  private skillService = inject(SkillService);

  @Input() parentFormGroup: FormGroup;
  @Input() jobFamily: FuncArea;
  @Input() selectedSkill: Skill;
  @Input() disableSelect = false;
  @Input() funcAreaId: number;
  @Output() selectedSkillChange = new EventEmitter<Skill>();

  skillList: Array<Skill>;

  exceptionData = {
    SKILLS: {
      level: AlertLevels.ERROR,
      code: 'SKL-001',
      message: 'Error retrieving skills',
    } as AlertData,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject();

  ngOnChanges() {
    if (this.selectedSkill) {
      this.parentFormGroup.get('competencyDetail').setValue({
        id: this.selectedSkill.id,
        funcAreaId: this.selectedSkill.funcAreaId,
        funcAreaName: this.selectedSkill.funcAreaName,
        funcAreaDesc: this.selectedSkill.funcAreaDesc,
        funcAreaTypeName: this.selectedSkill.funcAreaTypeName,
        name: this.selectedSkill.name,
        description: this.selectedSkill.description,
      });
    }
  }

  ngOnInit() {
    this.filterSkillList(this.jobFamily);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSkillClick(skill: Skill) {
    if (this.disableSelect) {
      return;
    }
    if (this.selectedSkill && skill.id === this.selectedSkill.id) {
      this.selectedSkillChange.emit(new Skill());
    } else {
      this.selectedSkillChange.emit(skill);
    }
  }

  showCompAttr(compAttr: Skill) {
    return (
      !this.disableSelect ||
      (this.disableSelect && compAttr.id === this.selectedSkill.id)
    );
  }

  onJobFamilyChange(jobFamily: FuncArea) {
    this.filterSkillList(jobFamily);
  }

  filterSkillList(jobFamily: FuncArea) {
    let disciplineId = 0;
    let parentJobFamilyId = 0;

    if (!jobFamily.parentFuncAreaId) {
      parentJobFamilyId = this.funcAreaId;
      // } else if (jobFamily.funcAreaTypeName === 'Discipline' ) {
    } else if (
      ['Discipline', 'Curriculum Section'].includes(jobFamily.funcAreaTypeName)
    ) {
      disciplineId = jobFamily.id;
      parentJobFamilyId = jobFamily.parentFuncAreaId;
    } else {
      parentJobFamilyId = jobFamily.id || 0;
    }

    this.skillService
      .getSkills(parentJobFamilyId, disciplineId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (sl: Array<Skill>) => (this.skillList = sl),
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.SKILLS, err);
        },
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
