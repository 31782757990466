<div class="modal-header bg-dark text-light">
  <h5 class="modal-title">Book a new session</h5>
  <button
    type="button"
    class="btn-close btn-close-white"
    (click)="activeModal.close()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <ug-progress-bar-fake
    *ngIf="saveStarted"
    [isDone]="!spin"
    [progressTitle]="'Saving Session'"
    [maximumWaitingTime]="maximumWaitingTime"
    [isFailed]="saveFailed"
  ></ug-progress-bar-fake>
  <form class="form" [formGroup]="bookSessionForm">
    <div class="row">
      <div class="col-10 mb-3">
        <label id="training-name">Select training</label>
        <ng-select
          labelForId="training-name"
          formControlName="training"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          placeholder="Select training"
          [loading]="loadingTraining"
        >
          <ng-option
            *ngFor="let training of trainingSubjects"
            [value]="training"
            >{{ training.name }}</ng-option
          >
        </ng-select>
      </div>
      <div class="col-10 mb-3">
        <label id="location">Select location</label>
        <ng-select
          labelForId="location"
          formControlName="location"
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="true"
          placeholder="Select location"
        >
          <ng-option *ngFor="let location of locations" [value]="location">{{
            location.name
          }}</ng-option>
        </ng-select>
      </div>
      <div class="col-10 mb-3">
        <label id="trainer-name">Select trainer(s)</label>
        <ng-select
          labelForId="trainer-name"
          formControlName="trainers"
          [multiple]="true"
          [closeOnSelect]="true"
          [searchable]="true"
          placeholder="Select trainer"
          [loading]="loadingTrainers"
        >
          <ng-option *ngFor="let trainer of trainers" [value]="trainer">{{
            trainer.displayName
          }}</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-4 mb-3">
        <label class="form-label" for="newSesionStartDate">Session start</label>
        <input
          class="form-control"
          type="datetime-local"
          formControlName="startDate"
          id="newSesionStartDate"
          title="New Session Start Date"
        />
      </div>
      <div class="col-4 mb-3">
        <label class="form-label" for="newSesionEndDate">Session end</label>
        <input
          class="form-control"
          type="datetime-local"
          formControlName="endDate"
          id="newSesionEndDate"
          [min]="minSessionEndDate"
          title="New Session End Date"
          [ngClass]="{
            'is-invalid':
              (form.endDate.errors && form.endDate.touched) ||
              bookSessionForm.hasError('endDate')
          }"
        />
        <div
          *ngIf="bookSessionForm.hasError('endDate')"
          class="invalid-feedback"
        >
          Session start date must be before the end date.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-5 mb-3">
            <label class="form-label" for="minCapacity">Minimum capacity</label>
            <input
              class="form-control form-number"
              type="number"
              min="0"
              formControlName="minCapacity"
              id="minCapacity"
              title="New Session Start Date"
            />
          </div>
          <div class="col-6 mb-3">
            <label class="form-label" for="maxCapacity">Maximum capacity</label>
            <input
              class="form-control form-number"
              type="number"
              min="0"
              formControlName="maxCapacity"
              id="maxCapacity"
              title="Maximum session capacity"
              [ngClass]="{
                'is-invalid':
                  (form.maxCapacity.errors && form.maxCapacity.touched) ||
                  bookSessionForm.hasError('maxCapacity')
              }"
            />
            <div
              *ngIf="bookSessionForm.hasError('maxCapacity')"
              class="invalid-feedback"
            >
              Session maximum capacity must be a value equal to or greater than
              the minimum capacity.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ug-progress-bar-fake
    *ngIf="saveStarted"
    [isDone]="!spin"
    [progressTitle]="'Saving session progress'"
    [maximumWaitingTime]="maximumWaitingTime"
    [isFailed]="saveFailed"
  ></ug-progress-bar-fake>
</div>
<div class="modal-footer">
  <div class="btn-group" role="group">
    <button
      type="button"
      class="btn btn-outline-danger mx-1"
      (click)="activeModal.close()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success mx-1"
      (click)="createNewSession()"
      [disabled]="!bookSessionForm.valid || spin"
    >
      <i class="fa fa-spinner fa-spin" *ngIf="spin"> </i> Create Session
    </button>
  </div>
</div>
