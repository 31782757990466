<div class="col-md-8 col-12 mx-auto h-100">
  <div class="text-end">
    <button
      type="button"
      aria-label="toggle graph view"
      class="btn btn-link graph-toggle p-1"
      (click)="toggleGraphType()"
    >
      <i
        [ngClass]="
          chartConfig.type === 'bar' ? 'fas fa-chart-pie' : 'fas fa-chart-bar'
        "
      ></i>
    </button>
  </div>
  <div>
    <ug-chart [chartConfig]="chartConfig"></ug-chart>
  </div>
</div>
