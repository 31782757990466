<div
  [style]="chartConfig.containerStyle"
  echarts
  [options]="chartOption"
  (chartInit)="onChartInit($event)"
  (chartClick)="onChartClick($event)"
  (chartLegendSelectChanged)="onLegendClick($event)"
></div>
<button
  *ngIf="customSaveAsImage"
  title="Save as image"
  class="btn px-0 py-0 custom-save-button"
  (click)="saveAsImage(); $event.stopPropagation()"
>
  <svg
    class="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 58 58"
  >
    <path
      d="M4.7,22.9L29.3,45.5L54.7,23.4M4.6,43.6L4.6,58L53.8,58L53.8,43.6M29.2,45.1L29.2,0"
    />
  </svg>
</button>
