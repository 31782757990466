import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Observable,
  catchError,
  defaultIfEmpty,
  finalize,
  forkJoin,
  of,
} from 'rxjs';
import { TableHeader } from '../../controls/table/table.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  AttributeField,
  DynamicGroupsService,
  FilterPayload,
} from '../services/dynamic-groups.service';

@Component({
  selector: 'ug-group-filter-upload',
  templateUrl: './group-filter-upload.component.html',
  styleUrls: ['./group-filter-upload.component.scss'],
  providers: [DatePipe],
})
export class GroupFilterUploadComponent implements OnInit {
  modal = inject(NgbActiveModal);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private uiService = inject(UitoolsService);
  private datePipe = inject(DatePipe);

  @ViewChild('progressModal') progressModal: ElementRef;

  attributeFields: AttributeField[] = [];
  groupUploadTotal = 0;
  groupUploadDone = 0;
  groupUploadFailed = 0;
  groupProcessedDone = 0;
  groupProcessedFailed = 0;

  tableHeaders: Array<TableHeader>;
  groupFilterTableRows: Array<any>;
  processLog = [];
  uploadLog = [];
  isLoading = true;
  filtersToUpload = [];
  processingIndex = 0;
  updatesInProgress = true;
  hideUploadButton: boolean = false;

  ngOnInit(): void {
    this.tableHeaders = [
      {
        id: 'status',
        title: 'Status',
        iconFunction: this.existsIcon,
        class: 'text-center',
      },
      { id: 'groupName', title: 'Group Name' },
      { id: 'detail', title: 'Detail' },
    ];
  }

  existsIcon = (row) => {
    return row.status
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : 'text-center fas fa-xl fa-times-circle text-danger';
  };

  closeModal() {
    this.modal.close();
  }

  fileUploadChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.parseCSV(file);
    }
  }

  parseCSV(file: File): void {
    this.processLog = [];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csv = e.target.result;
      let groupData = csv.split(/\r\n/).slice(1);
      groupData = groupData.filter((row) => row !== '');

      let currentGroupName = groupData[0].split(',')[0];
      let currentDescription = groupData[0].split(',')[1];
      let currentId = groupData[0].split(',')[7];

      let conditions = [];
      let group = [];
      const allGroups = [];
      let currentGroup = {};

      for (let i = 0; i < groupData.length; i++) {
        const condition = groupData[i].split(',');
        const groupName = condition[0];
        const groupDescription = condition[1];
        let conditionName = condition[2];
        const lookupEntity = condition[3] === '' ? null : condition[3];
        const operator = condition[4];
        const value = condition[5];
        const join = condition[6];
        const groupId = condition[7];
        const groupJoin = condition[8];

        if (lookupEntity?.toLowerCase() === 'orgunit') {
          conditionName += ' (OU)';
        } else if (lookupEntity?.toLowerCase() === 'customfieldvalue') {
          conditionName += ' (ECF)';
        }

        if (groupName === currentGroupName) {
          const conditionObj = {
            name: conditionName,
            lookupEntity: lookupEntity,
            operator: operator,
            value: value,
            join: join,
            groupId: groupId,
            groupJoin: groupJoin,
          };

          if (conditionObj.groupId === currentId) {
            conditions.push(conditionObj);
          } else {
            group.push(conditions);
            currentId = conditionObj.groupId;
            conditions = [];
            conditions.push(conditionObj);
          }

          if (i === groupData.length - 1) {
            group.push(conditions);
            currentGroup = {
              name: currentGroupName,
              description: currentDescription,
              conditions: group,
            };
            allGroups.push(currentGroup);
          }
        } else {
          group.push(conditions);
          currentGroup = {
            name: currentGroupName,
            description: currentDescription,
            conditions: group,
          };
          allGroups.push(currentGroup);
          currentGroup = {};
          conditions = [];
          group = [];
          currentGroupName = groupName;
          currentDescription = groupDescription;
          currentId = groupId;

          const conditionObj = {
            name: conditionName,
            lookupEntity: lookupEntity,
            operator: operator,
            value: value,
            join: join,
            groupId: groupId,
            groupJoin: groupJoin,
          };

          conditions.push(conditionObj);
        }
      }

      this.addGroupFilters(allGroups);
    };

    reader.readAsText(file);
  }
  addGroupFilters(csvData: Array<any>) {
    this.isLoading = true;
    this.groupUploadTotal = 0;
    this.groupUploadDone = 0;
    this.groupUploadFailed = 0;

    this.closeModal();
    this.uiService.openModalLarge(this.progressModal);
    this.groupUploadTotal = csvData.length;

    this.dynamicGroupsService
      .getAttributeFields('Person')
      .subscribe((attributeFields) => {
        this.attributeFields = attributeFields;
        csvData.forEach((groupConditions) => {
          this.processGroupConditions(groupConditions);
        });
      });
  }

  processGroupConditions(groupConditions: any) {
    const observables: Observable<any>[] = [];
    const groupName = groupConditions.name;

    groupConditions.conditions.forEach((group) => {
      group.forEach((condition) => {
        const attribute = this.attributeFields?.find(
          (a) =>
            a.name.toLowerCase() === condition.name.toLowerCase() &&
            (a.lookupEntity?.toLowerCase() ===
              condition.lookupEntity?.toLowerCase() ||
              !a.lookupEntity),
        );

        const operator = attribute?.operators.find(
          (o) => o.name.toLowerCase() === condition.operator.toLowerCase(),
        );

        if (attribute && operator) {
          if (attribute.dataType === 'Boolean') {
            observables.push(
              of([
                { key: 'True', name: 'True' },
                { key: 'False', name: 'False' },
              ]),
            );
          } else if (attribute.dataType === 'DateTime') {
            observables.push(of([]));
          } else if (
            attribute.lookupEntity === 'OrgUnit' ||
            attribute.lookupEntity === 'Person'
          ) {
            const observable = this.getFilteredEntityValueObservable(
              condition,
              groupName,
              attribute.lookupEntity,
            );
            observables.push(observable);
          } else {
            const observable = this.dynamicGroupsService
              .getLookupValues(
                attribute.lookupEntity,
                attribute.lookupParentKey,
              )
              .pipe(
                catchError((err) => {
                  this.processLog.push({
                    status: false,
                    groupName: groupConditions.name,
                    detail: `Failed to retrive attribute lookup values`,
                  });
                  return of(undefined);
                }),
              );
            observables.push(observable);
          }
        } else {
          if (!attribute) {
            let detailMessage = `Invalid attribute : ${condition.name} `;
            condition.lookupEntity
              ? (detailMessage += `of type ${condition.lookupEntity}`)
              : '';
            this.processLog.push({
              status: false,
              groupName: groupConditions.name,
              detail: detailMessage,
            });
          } else {
            this.processLog.push({
              status: false,
              groupName: groupConditions.name,
              detail: `Invalid operator ${condition.operator}`,
            });
          }
        }
      });
    });

    forkJoin(observables)
      .pipe(defaultIfEmpty([]))
      .subscribe((lookupResults) => {
        const filterHasError = this.processLog.findIndex(
          (el) => el.groupName === groupConditions.name,
        );

        if (filterHasError === -1) {
          const filterData = this.buildFilterData(
            groupConditions,
            lookupResults,
          );

          if (filterData) {
            this.groupProcessedDone++;
            this.filtersToUpload.push(filterData);
            this.processLog.push({
              status: true,
              groupName: groupConditions.name,
              detail: 'Successfully processed',
            });
          }
        } else {
          this.groupProcessedFailed++;
        }

        if (
          this.groupProcessedDone + this.groupProcessedFailed ===
          this.groupUploadTotal
        ) {
          this.groupFilterTableRows = [...this.processLog];
          this.isLoading = false;
          this.updatesInProgress = false;
        }
      });
  }

  buildFilterData(groupConditions: any, lookupResults: any[]): FilterPayload {
    this.processingIndex = 0;

    const filterArray = [];
    const uiFilterArray = [];
    let valueIndex = 0;

    groupConditions.conditions.forEach((group, groupIndex) => {
      const groupArray = [];
      const uiGroupArray = [];

      group.forEach((condition, conditionIndex) => {
        const attribute = this.attributeFields?.find((a) => {
          if (a.lookupEntity && condition.lookupEntity) {
            return (
              a.lookupEntity.toLowerCase() ===
                condition.lookupEntity.toLowerCase() &&
              a.name.toLowerCase() === condition.name.toLowerCase()
            );
          } else {
            return (
              a.lookupEntity === condition.lookupEntity &&
              a.name === condition.name
            );
          }
        });
        const operator = attribute?.operators.find(
          (o) => o.name.toLowerCase() === condition.operator.toLowerCase(),
        );
        let value = null;

        if (operator.operator === 'in' || operator.operator === '!in') {
          const valueArr = [];
          const values = condition.value.split('|');
          values.forEach((v, i) => {
            if (v) {
              if (attribute.lookupEntity === 'OrgUnit') {
                const ou = lookupResults[valueIndex].find(
                  (z) => z.externalId.toLowerCase() === v.toLowerCase(),
                );
                if (!ou) {
                  this.processLog.push({
                    status: false,
                    groupName: groupConditions.name,
                    detail: `Invalid organisation unit Id ${v}`,
                  });
                } else {
                  const x = { key: ou.id, name: ou.name };
                  valueArr.push(x);
                }
              } else if (attribute.lookupEntity === 'Person') {
                const person = lookupResults[valueIndex].find(
                  (z) => z.externalUserId.toLowerCase() === v.toLowerCase(),
                );
                if (!person) {
                  this.processLog.push({
                    status: false,
                    groupName: groupConditions.name,
                    detail: `Invalid person Id ${v}`,
                  });
                } else {
                  const x = {
                    key: person.id,
                    name: person.displayName + ' (' + values[i] + ')',
                  };
                  valueArr.push(x);
                }
              } else {
                const x = lookupResults[valueIndex].find(
                  (z) => z.name.toLowerCase() === v.toLowerCase(),
                );
                if (!x) {
                  this.processLog.push({
                    status: false,
                    groupName: groupConditions.name,
                    detail: `Invalid person Id ${v}`,
                  });
                } else {
                  valueArr.push(x);
                }
              }
            }
          });

          value = valueArr;
        } else {
          if (condition.value !== '') {
            if (attribute.lookupEntity === 'OrgUnit') {
              const ou = lookupResults[valueIndex][0];
              if (!ou) {
                this.processLog.push({
                  status: false,
                  groupName: groupConditions.name,
                  detail: `Invalid organisation unit Id ${condition.value}`,
                });
              } else {
                value = { key: ou.id.toString(), name: ou.name };
              }
            } else if (attribute.lookupEntity === 'Person') {
              const p = lookupResults[valueIndex][0];
              if (!p) {
                this.processLog.push({
                  status: false,
                  groupName: groupConditions.name,
                  detail: `Invalid person Id ${condition.value}`,
                });
              } else {
                value = {
                  key: p.id.toString(),
                  name: p.displayName + ' (' + condition.value + ' )',
                };
              }
            } else if (attribute.dataType === 'DateTime') {
              const date = new Date(condition.value);
              const formattedDate = this.isValidDate(date)
                ? this.datePipe.transform(date, 'yyyy-MM-dd')
                : null;
              if (!formattedDate) {
                this.processLog.push({
                  status: false,
                  groupName: groupConditions.name,
                  detail: `Invalid date ${condition.value}`,
                });
              } else {
                value = { key: formattedDate, name: formattedDate };
              }
            } else {
              const x = lookupResults[valueIndex].find(
                (z) => z.name.toLowerCase() === condition.value.toLowerCase(),
              );
              if (!x) {
                this.processLog.push({
                  status: false,
                  groupName: groupConditions.name,
                  detail: `Invalid value ${condition.value}`,
                });
              } else {
                value = x;
              }
            }
          } else {
            if (!operator.noValueRequired) {
              this.processLog.push({
                status: false,
                groupName: groupConditions.name,
                detail: `Value cannot be empty for attribute ${condition.name} with operator ${operator.name}`,
              });
            }
          }
        }

        valueIndex++;

        const valueKey = Array.isArray(value)
          ? value.map((v) => v.key)
          : value?.key;
        const uiValue = Array.isArray(value)
          ? value.map((v) => v.name)
          : value?.name;

        const conditionArray = [
          attribute.entityPath,
          operator.operator,
          valueKey,
        ];
        const uiConditionArray = [
          attribute.name,
          operator.name,
          uiValue,
          attribute.lookupParentKey,
        ];

        if (group.length > 1) {
          groupArray.push(conditionArray);
          uiGroupArray.push(uiConditionArray);
          if (conditionIndex !== group.length - 1) {
            if (condition.join === 'and' || condition.join === 'or') {
              groupArray.push(condition.join);
              uiGroupArray.push(condition.join);
            } else {
              this.processLog.push({
                status: false,
                groupName: groupConditions.name,
                detail: `Invalid condition join for ${condition.name} ${
                  condition.operator
                } ${condition.value} - ${
                  condition.join === '' ? 'No join supplied' : condition.join
                }`,
              });
            }
          }
        } else {
          groupArray.push(conditionArray);
          uiGroupArray.push(uiConditionArray);
        }
      });

      if (groupConditions.conditions.length > 1) {
        filterArray.push(groupArray);
        uiFilterArray.push(uiGroupArray);
        if (groupIndex !== groupConditions.conditions.length - 1) {
          if (group[0].groupJoin === 'and' || group[0].groupJoin === 'or') {
            filterArray.push(group[0].groupJoin);
            uiFilterArray.push(group[0].groupJoin);
          } else {
            this.processLog.push({
              status: false,
              groupName: groupConditions.name,
              detail: `Invalid group join - ${
                group[0].groupJoin === ''
                  ? 'No group join supplied'
                  : group[0].groupJoin
              }`,
            });
          }
        }
      } else {
        filterArray.push(groupArray);
        uiFilterArray.push(uiGroupArray);
      }
      this.processingIndex = groupIndex;
    });

    const filterHasError = this.processLog.findIndex(
      (el) => el.groupName === groupConditions.name,
    );

    if (filterHasError === -1) {
      const groupString = JSON.stringify(filterArray);
      const uiGroupString = JSON.stringify(uiFilterArray);

      const filterActiveString = `[\"ExternalActive\",\"=\",\"True\"]`;
      const filterActiveUiString = `[\"Person Active Status\",\"Equals\",\"Active\"]`;

      // appends active filter as group
      const filterString = `[[${groupString}],\"and\",${filterActiveString}]`;
      const uiFilterString = `[[${uiGroupString}],\"and\",${filterActiveUiString}]`;

      return {
        entity: 'Person',
        filterJson: filterString,
        uiFilterInfo: uiFilterString,
        name: groupConditions.name,
        description: groupConditions.description,
        errorMessage: null,
      };
    } else {
      this.groupProcessedFailed++;
      return null;
    }
  }

  getFilteredEntityValueObservable(
    condition,
    groupName,
    lookupEntity,
  ): Observable<any[] | undefined> {
    let filter = '';
    let order = '';

    if (lookupEntity === 'OrgUnit') {
      order = 'Name asc';
      if (
        condition.operator === 'Is in' ||
        condition.operator === 'Is not in'
      ) {
        const v = JSON.stringify(condition.value.split('|'));
        filter = `[\"ExternalExId\",\"in\",${v}]`;
      } else {
        filter = `[\"ExternalExId\",\"=\",\"${condition.value}\"]`;
      }
    } else if (lookupEntity === 'Person') {
      order = 'DisplayName asc';
      if (
        condition.operator === 'Is in' ||
        condition.operator === 'Is not in'
      ) {
        const v = JSON.stringify(condition.value.split('|'));
        filter = `[\"ExternalUserId\",\"in\",${v}]`;
      } else {
        filter = `[\"ExternalUserId\",\"=\",\"${condition.value}\"]`;
      }
    }

    const filterData = {
      filter: filter,
      page: 0,
      pageSize: 0,
      order: order,
    };

    const observable = this.dynamicGroupsService
      .getFilteredEntityValues(lookupEntity, filterData)
      .pipe(
        catchError((err) => {
          this.processLog.push({
            status: false,
            groupName: groupName,
            detail: `Failed to retrive ${lookupEntity} lookup values`,
          });
          return of(undefined);
        }),
      );

    return observable;
  }

  confirmUpload() {
    this.hideUploadButton = true;
    this.uploadLog = [];
    this.isLoading = true;
    this.groupFilterTableRows = [];
    this.filtersToUpload.forEach((groupFilter, i) => {
      this.dynamicGroupsService
        .addFilter(groupFilter)
        .pipe(
          finalize(() => {
            if (i === this.filtersToUpload.length - 1) {
              this.isLoading = false;
            }
            this.groupFilterTableRows = [...this.uploadLog];
          }),
        )
        .subscribe(
          (gf) => {
            this.groupUploadDone++;
            this.uploadLog.push({
              status: true,
              groupName: groupFilter.name,
              detail: `Successfully uploaded`,
            });
          },
          (err) => {
            this.groupUploadFailed++;

            if (err.message === 'Duplicated Name') {
              this.uploadLog.push({
                status: false,
                groupName: groupFilter.name,
                detail: `Unable to create filter - group with name ${groupFilter.name} already exists`,
              });
            } else {
              this.uploadLog.push({
                status: false,
                groupName: groupFilter.name,
                detail: `Unable to create filter - (${err.message})`,
              });
            }
          },
        );
    });
  }

  isValidDate(d: Date): boolean {
    return !isNaN(d.getTime());
  }
}
