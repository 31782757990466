<div class="row">
  <div class="col-12 col-md-8">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Assessment Request Summary</h5>
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  [ngClass]="[
                    'fa-2x far fa-check-circle me-2',
                    detailFormGroup.dirty || !claim?.id
                      ? 'text-warning'
                      : 'text-success',
                  ]"
                >
                </span>
                <span class="h4 mb-0">Status</span>
              </div>
              <div
                class="col-12 col-md-6 d-flex flex-column"
                [ugHighlightMandatory]="
                  claim?.statusText || !detailFormGroup.dirty
                "
              >
                <span>
                  {{ claim?.statusText ? claim.statusText : 'Draft' }}
                  <span class="badge bg-warning">{{
                    detailFormGroup.dirty || !claim?.id ? 'UNSAVED' : null
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  class="me-2"
                  [ngClass]="
                    getStatusSymbolClass(
                      detailFormGroup.get('competency.competencyDetail')
                    )
                  "
                ></span>
                <span class="h4 mb-0">Competency</span>
              </div>
              <div
                class="col-12 col-md-6 d-flex flex-column"
                [ugHighlightMandatory]="selectedCompAttr?.id"
              >
                <span>{{
                  selectedCompAttr?.name || 'Please select a competency'
                }}</span>
                <esqep-form-error-list
                  [sourceFormGroup]="
                    detailFormGroup.get('competency.competencyDetail')
                  "
                ></esqep-form-error-list>
              </div>
            </div>
          </div>
          <div class="list-group-item flex-column">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  class="me-2"
                  [ngClass]="
                    getStatusSymbolClass(
                      detailFormGroup.get('levelAndVerifier.level')
                    )
                  "
                ></span>
                <span class="h4 mb-0">Competency Level</span>
              </div>
              <div
                class="col-12 col-md-6 d-flex flex-column"
                [ugHighlightMandatory]="selectedClaimLevel?.id"
              >
                <p class="m-0">
                  {{
                    selectedClaimLevel?.name ||
                      'Please select an assessment request level'
                  }}
                </p>
                <esqep-form-error-list
                  [sourceFormGroup]="
                    detailFormGroup.get('levelAndVerifier.level')
                  "
                ></esqep-form-error-list>
              </div>
            </div>
          </div>
          <div class="list-group-item flex-column">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  class="me-2"
                  [ngClass]="
                    getStatusSymbolClass(
                      detailFormGroup.get('levelAndVerifier.verifier')
                    )
                  "
                ></span>
                <span class="h4 mb-0">Assessor</span>
              </div>
              <div
                class="col-12 col-md-6 d-flex flex-column"
                [ugHighlightMandatory]="
                  selectedClaimLevel?.selfAssessed ||
                  selectedVerifier?.assessorId
                "
              >
                <p class="m-0">
                  {{
                    selectedClaimLevel?.selfAssessed
                      ? 'Self-assessed'
                      : selectedVerifier?.personFamilyGivenName ||
                        'Please select an Assessor'
                  }}
                </p>
                <esqep-form-error-list
                  [sourceFormGroup]="
                    detailFormGroup.get('levelAndVerifier.verifier')
                  "
                ></esqep-form-error-list>
              </div>
            </div>
          </div>
          <div class="list-group-item flex-column">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  class="me-2"
                  [ngClass]="
                    getStatusSymbolClass(detailFormGroup.get('evidence'))
                  "
                ></span>
                <span class="h4 mb-0">Evidence</span>
              </div>
              <div
                class="col-12 col-md-6 d-flex flex-column"
                [ugHighlightMandatory]="evidenceText"
              >
                <p class="m-0">
                  {{
                    detailFormGroup.get('evidence').invalid
                      ? 'Please enter evidence statement'
                      : evidenceText
                  }}
                </p>
                <esqep-form-error-list
                  [sourceFormGroup]="detailFormGroup.get('evidence')"
                ></esqep-form-error-list>
              </div>
            </div>
          </div>
          <div *ngIf="claim?.expiryDate" class="list-group-item flex-column">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <span
                  class="me-2 far fa-2x fa-check-circle text-success"
                ></span>
                <span class="h4 mb-0">Expiry Date</span>
              </div>
              <div class="col-12 col-md-6 d-flex align-items-center">
                <p class="m-0 align-text-bottom">
                  {{ claim.expiryDate | date: 'dd/MM/yyyy' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <esqep-action-select-form
      [parentFormGroup]="parentFormGroup.get('detail.claimantAction')"
      [targetFormGroup]="detailFormGroup"
      [buttonsFormGroup]="parentFormGroup.get('buttons')"
      [componentIndex]="'CA1'"
      [initiatorType]="'C'"
      [claimId]="claim?.id"
      [claimStatusId]="claim?.claimStatusId"
      (actionChange)="onActionChange($event)"
      (submit)="onSubmitActionClick()"
      (cancel)="onCancelActionClick()"
    ></esqep-action-select-form>
  </div>
</div>
