import { Component, OnInit, inject } from '@angular/core';

import { AlertData, AlertLevels } from '../services/alert.service';
import { AuthService, LoggedInUser } from '../services/auth.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'ug-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  authService = inject(AuthService);
  private configService = inject(ConfigService);

  person: LoggedInUser;
  activeTabIndex = 0;
  logging: any;
  showStatusSummData = false;
  homeCards = {} as any;

  exceptionData = {
    PERSON_UUID: {
      level: AlertLevels.ERROR,
      code: 'HMC-001',
      message: 'Error retrieving user person details',
    } as AlertData,
  };

  constructor() {
    const configService = this.configService;

    this.logging = configService.logging;
    // this.logging.newMsg('User Info from JWT:' + JSON.stringify(this.authService.userInfo), 'VERBOSE');
    this.authService.authenticationStatusSubj.subscribe((status) => {
      this.logging.newMsg(
        'Home component ngOnInit isSignedIn=' + this.isSignedIn,
        'VERBOSE',
      );
    });

    this.homeCards = this.configService.homeCards
      .map((rmt, index) => ({ [rmt]: index + 1 }))
      .reduce((prev, homeCards) => ({ ...prev, ...homeCards }), {});
  }

  get isSignedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  get me(): LoggedInUser {
    return this.authService.me;
  }

  ngOnInit() {}

  getCardOrder(itemName) {
    if (this.homeCards[itemName] === 1) {
      return 'order-first';
    } else if (
      this.homeCards[itemName] === Object.keys(this.homeCards).length
    ) {
      return 'order-last';
    } else {
      return `order-${this.homeCards[itemName] - 2}`;
    }
  }
}
