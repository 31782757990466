<div
  *ngIf="matrixPeople.length > 1"
  class="d-flex justify-content-end align-items-center"
>
  <div class="form-check form-switch form-control-lg">
    <input
      class="form-check-input hr45"
      type="checkbox"
      role="switch"
      id="unassignedUsersSwitch"
      [checked]="showUnassigned"
      (change)="toggleUnassignedUsers($event)"
    />
    <label class="form-check-label" for="unassignedUsersSwitch"
      >{{ !showUnassigned ? 'Hiding' : 'Showing' }} unassigned users</label
    >
  </div>
</div>
<div class="table-responsive skill-matrix-table">
  <table class="table">
    <thead class="sticky-table-header">
      <tr>
        <th
          scope="col"
          class="ps-0 border border-end-0 bg-white"
          [attr.colspan]="
            (fullFunc || isManager) && matrixType === 'training' ? 3 : 2
          "
        >
          <div class="d-flex justify-content-between align-items-center px-2">
            <div class="d-flex">
              <div class="search-filter-container me-5">
                <ug-text-filter-control
                  (filterTextChange)="onFilterTextChange($event)"
                  [filterText]="currFilterText"
                  [placeholderText]="'Begin typing to filter by ' + matrixType"
                >
                </ug-text-filter-control>
              </div>

              <div
                *ngIf="matrixPeople.length > 1"
                class="search-filter-container"
              >
                <ug-text-filter-control
                  (filterTextChange)="onPersonFilterTextChange($event)"
                  [filterText]="personFilterText"
                  [placeholderText]="'Begin typing to filter by person'"
                >
                </ug-text-filter-control>
              </div>
            </div>
          </div>
        </th>
        <th
          scope="col"
          *ngIf="excludeLoggedInUser ?? filteredMatrixPeople.length > 1"
          class="text-center table-light esqep-user-indirect-report fw-normal border border-start-0"
          [attr.colspan]="
            excludeLoggedInUser
              ? filteredMatrixPeople.length
              : filteredMatrixPeople.length - 1
          "
        ></th>
      </tr>
      <tr class="border-top">
        <th
          scope="col"
          class="table-light border-start border-end border-top skill-column"
        >
          {{ matrixTableTitle }}
        </th>
        <th
          scope="col"
          *ngIf="(fullFunc || isManager) && matrixType === 'training'"
          class="table-light text-center border-end border-top action-column"
        >
          Action
        </th>
        <th
          *ngFor="let person of filteredMatrixPeople"
          scope="col"
          class="text-center table-light border-end border-top person-column"
        >
          <div class="d-flex flex-column">
            <button
              *ngIf="fullFunc || isManager"
              type="button"
              class="btn btn-link"
              (click)="showUserDetail(person)"
              ugAnonymize
            >
              <span
                [ngClass]="
                  person && person.id === authService.me.id ? 'fw-bold' : ''
                "
                >{{ person.displayName }}</span
              >
            </button>
            <div
              *ngIf="!(fullFunc || isManager)"
              ugAnonymize
              [ngClass]="
                person && person.id === authService.me.id ? 'fw-bold' : ''
              "
            >
              {{ person.displayName }}
            </div>
            <div>
              <ng-container
                *ngIf="
                  personCompletion.get(person.id).totalRequiredCount;
                  else personNotRequired
                "
              >
                <span
                  class="badge rounded-pill"
                  [ngClass]="personCompletion.get(person.id).progressClass"
                >
                  {{
                    personCompletion.get(person.id).totalPercentComplete
                      | percent
                  }}
                </span>
              </ng-container>
              <ng-template #personNotRequired>
                <span [ngClass]="stateSymbolMap.get('Not required')"></span>
              </ng-template>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let sk of filteredUngroupedSkills;
          let usi = index;
          trackBy: trackById
        "
      >
        <th
          scope="col"
          class="text-start align-middle bg-white border-start border-end"
        >
          <ng-container *ngIf="sk.url; else nonUrlTitle">
            <a
              [href]="sk.url"
              class="stretched-link skill-link"
              target="_blank"
              rel="noreferrer noopener"
              (click)="onLinkClick(sk)"
              title="Click here to launch training"
              >{{ sk.name
              }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span></a
            >
          </ng-container>
          <ng-template #nonUrlTitle>
            {{ sk.name }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span>
          </ng-template>
        </th>
        <td
          class="text-center align-middle action-column border-end"
          *ngIf="(fullFunc || isManager) && matrixType === 'training'"
        >
          @if (!hideActionButton || (hideActionButton && fullFunc)) {
            <button
              *ngIf="sk.type !== 'Event'"
              type="button"
              class="btn esqepBtn btn-sm"
              (click)="showBulkSubmit(sk, gsk)"
            >
              Complete
            </button>
          }
          <button
            *ngIf="sk.type === 'Event'"
            type="button"
            class="btn esqepBtn btn-sm"
            (click)="showEventCalendar(sk)"
          >
            Events Calendar
          </button>
        </td>
        <td
          *ngFor="
            let person of filteredMatrixPeople;
            let pi = index;
            trackBy: trackById
          "
          class="text-white text-center align-middle border-end"
        >
          <ug-state-icon
            [personSkillMapValue]="personSkillMap.get(person.id + '_' + sk.id)"
            [skill]="sk"
            [person]="person"
            (modifySkillEvent)="modifySkill($event)"
          ></ug-state-icon>
        </td>
      </tr>
    </tbody>
    <tbody
      *ngFor="
        let gsk of filteredGroupedSkills || [];
        let ci = index;
        trackBy: trackById
      "
    >
      <tr class="curric-row">
        <th scope="col" class="curric-row border-start border-end">
          <div class="row align-items-center">
            <div class="col-8">{{ gsk.name }}</div>
            <div class="col-2">
              <div class="d-flex flex-column align-items-center w-100">
                <span
                  class="badge"
                  [ngClass]="groupSummary.get(gsk.id).progressClass"
                >
                  {{ groupSummary.get(gsk.id).average | percent }}
                </span>
                <div class="progress w-75">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    [ngClass]="groupSummary.get(gsk.id).progressClass"
                    [ngStyle]="groupSummary.get(gsk.id).progressStyle"
                    [attr.aria-valuenow]="groupSummary.get(gsk.id).average"
                    aria-valuemin="0"
                    aria-valuemax="1"
                    title="Mean completion"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-2 text-end">
              <button
                class="btn btn-link toggle-row"
                [attr.aria-expanded]="collapsedRowMap.get(gsk.id)"
                (click)="toggleRow(gsk.id)"
              >
                <span
                  class="fas"
                  [ngClass]="
                    !collapsedRowMap.get(gsk.id)
                      ? 'fa-chevron-down'
                      : 'fa-chevron-right'
                  "
                ></span>
              </button>
            </div>
          </div>
        </th>
        <th scope="col" *ngIf="fullFunc || isManager" class="border-end"></th>
        <th
          *ngFor="
            let person of filteredMatrixPeople;
            let pi = index;
            trackBy: trackById
          "
          class="text-center align-middle border-end"
        >
          <div class="w-50 m-auto">
            <ng-container
              *ngIf="
                personGroupCompletion.get(person.id + '_' + gsk.id)
                  .requiredCount;
                else curricNotRequired
              "
            >
              <div
                class="position-relative badge"
                [ngClass]="
                  personGroupCompletion.get(person.id + '_' + gsk.id)
                    .progressClass
                "
              >
                {{
                  personGroupCompletion.get(person.id + '_' + gsk.id)
                    .percentComplete | percent
                }}
                <ng-container
                  *ngIf="
                    personGroupCompletion.get(person.id + '_' + gsk.id).isAlert
                  "
                >
                  <span
                    class="position-absolute top-0 start-100 translate-middle"
                  >
                    <i class="fas fa-exclamation-triangle text-secondary"></i>
                  </span>
                </ng-container>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngClass]="
                    personGroupCompletion.get(person.id + '_' + gsk.id)
                      .progressClass
                  "
                  [ngStyle]="
                    personGroupCompletion.get(person.id + '_' + gsk.id)
                      .progressStyle
                  "
                  [attr.aria-valuenow]="
                    personGroupCompletion.get(person.id + '_' + gsk.id)
                      .percentComplete
                  "
                  aria-valuemin="0"
                  aria-valuemax="1"
                  title="User completion"
                ></div>
              </div>
            </ng-container>
            <ng-template #curricNotRequired>
              <div [ngClass]="stateSymbolMap.get('Not required')"></div>
            </ng-template>
          </div>
        </th>
      </tr>
      <ng-container *ngIf="!collapsedRowMap.get(gsk.id)">
        <ng-container *ngIf="gsk.sections">
          <ng-container *ngFor="let section of gsk.sections; let si = index">
            <tr class="section-row">
              <th scope="col" class="border-start border-end">
                {{ section?.name }}
              </th>
              <th scope="col" class="border-end"></th>
              <th
                scope="col"
                [attr.colspan]="filteredMatrixPeople.length"
                class="border-end"
              ></th>
            </tr>
            <tr
              *ngFor="
                let sk of section.skills;
                let usi = index;
                trackBy: trackById
              "
              class=""
              [attr.id]="'collapseItem_' + ci + '_' + usi"
              [ngClass]="'collapseCurriculum' + '-' + ci"
            >
              <th
                scope="col"
                class="text-end align-middle text-muted border-start border-end bg-white"
              >
                <ng-container *ngIf="sk.url; else nonUrlTitle">
                  <a
                    [href]="sk.url"
                    class="stretched-link skill-link"
                    target="_blank"
                    rel="noreferrer noopener"
                    (click)="onLinkClick(sk, gsk.id)"
                    title="Click here to launch training"
                    >{{ sk.name
                    }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span></a
                  >
                </ng-container>
                <ng-template #nonUrlTitle>
                  {{ sk.name
                  }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span>
                </ng-template>
              </th>
              <td
                class="text-center align-middle action-column border-end"
                *ngIf="fullFunc || isManager"
              >
                @if (!hideActionButton || (hideActionButton && fullFunc)) {
                  <button
                    *ngIf="sk.type !== 'Event'"
                    type="button"
                    class="btn esqepBtn btn-sm"
                    (click)="showBulkSubmit(sk, gsk)"
                  >
                    Complete
                  </button>
                }
                <button
                  *ngIf="sk.type === 'Event'"
                  type="button"
                  class="btn esqepBtn btn-sm"
                  (click)="showEventCalendar(sk)"
                >
                  Events Calendar
                </button>
              </td>
              <td
                *ngFor="
                  let person of filteredMatrixPeople;
                  let pi = index;
                  trackBy: trackById
                "
                class="text-center align-middle border-end"
              >
                <ug-state-icon
                  [personSkillMapValue]="
                    personSkillMap.get(person.id + '_' + sk.id + '_' + gsk.id)
                  "
                  [skill]="sk"
                  [person]="person"
                  [groupedSkills]="gsk"
                  (modifySkillEvent)="modifySkill($event)"
                ></ug-state-icon>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="gsk.skills">
          <tr
            *ngFor="let sk of gsk.skills; let usi = index; trackBy: trackById"
            class=""
            [attr.id]="'collapseItem_' + ci + '_' + usi"
            [ngClass]="'collapseCurriculum' + '-' + ci"
          >
            <th
              scope="col"
              class="text-end align-middle text-muted border-start border-end bg-white"
            >
              <ng-container *ngIf="sk.url; else nonUrlTitle">
                <a
                  [href]="sk.url"
                  class="stretched-link skill-link"
                  target="_blank"
                  rel="noreferrer noopener"
                  (click)="onLinkClick(sk, gsk.id)"
                  title="Click here to launch training"
                  >{{ sk.name
                  }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span></a
                >
              </ng-container>
              <ng-template #nonUrlTitle>
                {{ sk.name
                }}<span *ngIf="sk.type">{{ ' [' + sk.type + ']' }}</span>
              </ng-template>
            </th>
            <td
              class="text-center align-middle action-column border-end"
              *ngIf="fullFunc || isManager"
            >
              @if (!hideActionButton || (hideActionButton && fullFunc)) {
                <button
                  *ngIf="sk.type !== 'Event'"
                  type="button"
                  class="btn esqepBtn btn-sm"
                  (click)="showBulkSubmit(sk, gsk)"
                >
                  Complete
                </button>
              }
              <button
                *ngIf="sk.type === 'Event'"
                type="button"
                class="btn esqepBtn btn-sm"
                (click)="showEventCalendar(sk)"
              >
                Events Calendar
              </button>
            </td>
            <td
              *ngFor="
                let person of filteredMatrixPeople;
                let pi = index;
                trackBy: trackById
              "
              class="text-center align-middle border-end"
            >
              <ug-state-icon
                [personSkillMapValue]="
                  personSkillMap.get(person.id + '_' + sk.id + '_' + gsk.id)
                "
                [skill]="sk"
                [person]="person"
                [groupedSkills]="gsk"
                (modifySkillEvent)="modifySkill($event)"
              ></ug-state-icon>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
