<div class="card shadow training-calendar">
  <ng-container>
    <div class="card-body">
      <form [formGroup]="trainingCalFilters" class="row">
        <div class="col">
          <ng-select
            labelForId="training-name"
            formControlName="trainingId"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            [items]="trainingSubjects"
            bindLabel="name"
            bindValue="key"
            placeholder="Select event"
          >
          </ng-select>
        </div>
        <div class="col">
          <ng-select
            labelForId="location"
            formControlName="locationId"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            placeholder="Select location"
            [items]="locations"
            bindLabel="name"
            bindValue="key"
          >
          </ng-select>
        </div>
        <div class="col">
          <ng-select
            labelForId="trainer"
            formControlName="trainerId"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            [items]="trainers"
            bindLabel="displayName"
            bindValue="id"
            placeholder="Select trainer"
          >
          </ng-select>
        </div>
      </form>
    </div>
  </ng-container>
  <div class="calendar-section">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
