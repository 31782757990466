<div>
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add Additional Attendees</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <ug-table
      [isLoading]="tableLoading"
      [tableHeaders]="headers"
      [tableFilters]="filters"
      [tableSelectedButtons]="selectedButtons"
      [tableRows]="tableRows"
      [showSelectBox]="true"
      [existsKey]="'attending'"
    ></ug-table>
  </div>
</div>
