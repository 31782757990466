import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  EventSession,
  EventsService,
} from '../../../events/services/events.service';

@Component({
  selector: 'ug-book-existing-session',
  templateUrl: './book-existing-session.component.html',
  styleUrl: './book-existing-session.component.scss',
})
export class BookExistingSessionComponent {
  modal = inject(NgbActiveModal);
  private eventsService = inject(EventsService);
  private alertService = inject(AlertService);

  @Input() usersToBookOntoSession: Array<number> = [];
  @Input() selectedSkillId: number;
  @Output() bookToSessionEvent: EventEmitter<any> = new EventEmitter();
  tableLoading = true;

  headers: Array<TableHeader> = [
    { id: 'externalClassroomId', title: 'Classroom ID', class: 'w-10' },
    {
      id: 'startDate',
      title: 'Start Date',
      dateFormat: 'dd/MM/yyyy HH:mm',
      class: 'w-10',
    },
    {
      id: 'endDate',
      title: 'End Date',
      dateFormat: 'dd/MM/yyyy HH:mm',
      class: 'w-10',
    },
    { id: 'location', title: 'Location', class: 'w-30' },
    { id: 'trainers', title: 'Trainers', class: 'w-20' },
    { id: 'attendeesVsCapacity', title: 'Capacity', class: 'w-15' },
  ];

  tableRows: Array<any>;
  filters: Array<FilterBase<any>>;
  selectLoading: boolean = false;

  bookToSession = (session) => {
    this.selectLoading = true;
    const sessionId = session[0].id;

    if (this.usersToBookOntoSession.length) {
      this.eventsService
        .addAttendees(sessionId, this.usersToBookOntoSession)
        .subscribe({
          next: (response) => {
            this.selectLoading = false;
            this.bookToSessionEvent.next(true);
            this.modal.close();
          },
          error: (error) => {
            this.alertService.createAlert2(
              {
                level: AlertLevels.ERROR,
                code: 'BES-001',
                message: 'Error booking users to session',
              } as AlertData,
              error,
            );
          },
        });
    }
  };

  warningMessageFunction = (sessions) => {
    const selectedSession = sessions[0];
    if (selectedSession.maxCapacity) {
      const availableCapacity =
        selectedSession.maxCapacity - selectedSession.attendeeCount;
      const usersToBookCount = this.usersToBookOntoSession.length;
      const waitingListCount =
        availableCapacity > 0
          ? usersToBookCount - availableCapacity
          : usersToBookCount;
      return waitingListCount > 0
        ? `Adding selected users will take this session over capacity :  ${waitingListCount}  will be added to the waiting list`
        : null;
    }
    return null;
  };

  tableSelectedButtons: Array<TableSelectedButton> = [
    {
      title: 'Book',
      class: 'btn-success',
      function: this.bookToSession,
      warningMessageFunction: this.warningMessageFunction,
    },
  ];

  ngOnInit() {
    this.eventsService
      .getFutureSessionsBySkillId(this.selectedSkillId)
      .subscribe({
        next: (sessions: EventSession[]) => {
          const tableSessions = sessions.map((session) => {
            return {
              ...session,
              trainers: session.instructors
                .map((trainer) => trainer.name)
                .join(', '),
              attendeesVsCapacity: this.capacityFunction(session),
            };
          });

          this.tableRows = [...tableSessions];
          this.tableLoading = false;
        },
        error: (error) => {
          this.tableLoading = false;
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'BES-001',
              message: 'Error getting session data for skill',
            } as AlertData,
            error,
          );
        },
      });
  }

  capacityFunction(session) {
    return session.maxCapacity === null
      ? `${session.attendeeCount} / Capacity Unkown`
      : `${session.attendeeCount} / ${session.maxCapacity}`;
  }
}
