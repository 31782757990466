import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../../../environments/environment';
import { APP_VERSION } from '../../constants/constants.component';

export enum LogLevel {
  ERROR,
  WARNING,
  INFO,
  VERBOSE,
}

export function Logger(msg, lvl): void {
  const configService: ConfigService = new ConfigService();
  // configService.logging.newMsg( `${this.constructor.name}: ${msg}`, lvl);
  configService.logging.newMsg(msg, lvl);
}
// export function Logger(): void {}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public authSetup;

  private _componentName = '';
  public logging = {
    level: environment.loggingLevel,
    levelMap: {
      ERROR: {
        level: 0,
        log: (msg) => console.error(msg),
      },
      WARNING: {
        level: 1,
        log: (msg) => console.warn(msg),
      },
      INFO: {
        level: 2,
        log: (msg) => console.info(msg), //tslint:disable-line
      },
      VERBOSE: {
        level: 3,
        log: (msg) => console.log(msg),
      },
    },
    re: /\w*-([A-Z]*):\s/,
    newMsg: (msg: string, msgLevel: string | number) => {
      let numericMsgLevel = LogLevel.VERBOSE;
      if (typeof msgLevel === 'string') {
        numericMsgLevel = LogLevel['msgLevel'] || LogLevel.VERBOSE;
      } else if (typeof msgLevel === 'number') {
        numericMsgLevel = msgLevel;
      }
      const logComponentName = `[${
        this._componentName || 'Unknown Component'
      }] `;
      this.logging.log(
        // `${new Date().toUTCString()}:${this.appVersion}-${LogLevel[numericMsgLevel]} [${this._componentName || 'Unknown Component'}] ${msg}`,
        `${new Date().toUTCString()}:${this.appVersion}-${
          LogLevel[numericMsgLevel]
        } ${
          LogLevel[numericMsgLevel] === 'VERBOSE' ? logComponentName : ''
        }${msg}`,
        numericMsgLevel,
      );
    },
    log: (msg: string, msgLevel?: number) => {
      if (window.console) {
        let msgLevelDesc = 'INFO';
        if (msgLevel) {
          msgLevelDesc = LogLevel[msgLevel];
        } else {
          msgLevelDesc = this.logging.re.exec(msg)[1] || 'VERBOSE';
        }
        if (this.logging.levelMap[msgLevelDesc].level <= this.logging.level) {
          this.logging.levelMap[msgLevelDesc].log(msg);
        }
      }
    },
  };

  public title = 'eSQEP';

  // public inactivity_timeout_warning_seconds = 10;
  // public inactivity_timeout_countdown_seconds = 20;
  // public inactivity_timeout_logout_seconds = 40;
  public inactivity_timeout_warning_seconds = 1500;
  public inactivity_timeout_countdown_seconds = 300;
  public inactivity_timeout_logout_seconds = 1800;

  public defaultRoleManagementTabs = [
    'details',
    'competencies',
    'training',
    'assignment',
  ];
  public defaultHomeCards = [
    'myActivitySummary',
    'myClaimActions',
    'myJobRoleProfile',
    'myAssignment',
    'myTrainingCompletion',
  ];
  public defaultTrainingSearchTypes = [
    'Cohort',
    'Curriculum',
    'Event',
    'Library',
    'Material',
    'Online Class',
    'Online Course',
    'Quick Course',
    'Test',
    'Video',
    'Course',
  ];

  constructor() {
    this.authSetup = environment.auth.reduce(
      (a, v) => ({ ...a, [v.authConfigName]: v }),
      {},
    );
  }

  public get appVersion(): string {
    return APP_VERSION + '-' + environment.envName;
  }

  public get shareServerUri(): string {
    return environment.shareUrl;
  }

  public get apiServerUri(): string {
    return environment.apiUrl;
  }

  public get devMode(): boolean {
    return isDevMode();
  }

  public set componentName(value: string) {
    this._componentName = value;
  }

  public get excludeExternalInactivePeople() {
    return environment['excludeExternalInactivePeople'] || false;
  }

  public get blurNames() {
    return environment['blurNames'] || false;
  }

  public get skillMatrixHeadings(): Array<string> {
    return environment.skillMatrixHeadings;
  }

  public get clientLogoAsset(): string {
    return environment.clientLogoAsset;
  }

  public get navbarBgClass(): string {
    return environment.navbarBgClass || 'bg-dark';
  }

  public get skillMatrixFaId(): number {
    return environment.skillMatrixFaId || 48;
  }

  public get isJobRoleLearningAssignmentVersion(): boolean {
    return environment['jobRoleLearningAssignmentVersion'];
  }

  public get roleManagementTabs(): Array<string> {
    return environment['roleManagementTabs'] || this.defaultRoleManagementTabs;
  }

  public get homeCards(): Array<string> {
    return environment['homeCards'] || this.defaultHomeCards;
  }

  public get lmsName(): string {
    const lmsName =
      environment['lms'] && environment['lms']['name']
        ? environment['lms']['name']
        : 'Cornerstone';
    return lmsName;
  }

  public get disableAllJobRolesSearch(): boolean {
    const disableAllJobRoleSearch = environment['disableAllJobRolesSearch']
      ? environment['disableAllJobRolesSearch']
      : false;
    return disableAllJobRoleSearch;
  }

  public get jobRoleSkillSearchTypes(): Array<string> {
    const trainingSearchTypes = environment['trainingSearchTypes']
      ? environment['trainingSearchTypes']
      : this.defaultTrainingSearchTypes;
    return trainingSearchTypes;
  }

  public get showRoleManagementAiPrompt(): any {
    return environment['showRoleManagementAiPrompt']
      ? environment['showRoleManagementAiPrompt']
      : false;
  }

  public get hideActionButtonForManagers(): any {
    return environment['hideActionButtonForManagers']
      ? environment['hideActionButtonForManagers']
      : false;
  }
}

// TODO: https://angular.io/guide/dependency-injection#dependency-injection-tokens
