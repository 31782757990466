<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">{{ modalTitle | titlecase }}</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <ug-table
      [singleSelection]="true"
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [tableFilters]="tableFilters"
      [tableSelectedButtons]="tableSelectedButtons"
      [showPagination]="true"
      [isLoading]="tableLoading"
      [totalRecords]="filterCount"
    >
    </ug-table>
  </div>
</div>
