import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';

import { SecurityRoleGuard } from '../core/guards/security-role.guard';

import { PersonSecurityRoleComponent } from './person-security-role/person-security-role.component';
import { SharedInfoListComponent } from './shared-info-list/shared-info-list.component';

import { PreAuthGuard } from '../guards/pre-auth.guard';
import { PersonDetailComponent } from './person-detail/person-detail.component';

const routes: Routes = [
  {
    path: 'mydata/shared-links/list',
    component: SharedInfoListComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: { routeIdentifier: 'MY-DATA_SHARED-LINKS', title: 'My Shared Links' },
  }, // TODO: Add extra guard?
  {
    path: 'person-details',
    component: PersonDetailComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: { routeIdentifier: 'MY-USER_PROFILE', title: 'My Details' },
  },
  {
    path: 'user-security-roles',
    component: PersonSecurityRoleComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ADMIN_USER-SECURITY-ROLES',
      title: 'User Security Roles',
      helpUrl:
        'https://scribehow.com/shared/User_Permissions__4QvjjPHrRWmKLljHkq7m1g',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PersonRoutingModule {}
