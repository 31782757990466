<div
  *ngIf="parentFormGroup"
  class="card esqep-indicate-validity"
  [formGroup]="parentFormGroup"
>
  <div class="card-body">
    <h5 class="card-title">Competency Level</h5>
    <div *ngIf="controlStatus.disabled" class="alert alert-info">
      <span class="fas fa-xl fa-info-circle"></span> {{ controlStatus.reason }}
    </div>
    <div class="form-group row mt-3">
      <label for="claimLevelSelect" class="col-5 col-form-label">
        <strong>{{
          editMode ? 'Amend competency level to' : 'Select competency level'
        }}</strong>
      </label>
      <div class="col-7">
        <select
          class="form-select"
          formControlName="id"
          id="claimLevelSelect"
          [compareWith]="equals"
          (change)="onClaimLevelChange()"
        >
          <option disabled [ngValue]="null">select competency Level</option>
          <option
            *ngFor="let level of claimLevels; let i = index"
            [ngValue]="level.id"
          >
            {{ level.name }}
          </option>
        </select>
      </div>
      <div class="form-text col-9 offset-3 small text-end">
        {{ level?.description }}
      </div>
    </div>
  </div>
</div>
