import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxEchartsDirective } from 'ngx-echarts';
import { ChartComponent } from './chart/chart.component';

@NgModule({
  declarations: [ChartComponent],
  imports: [CommonModule, NgxEchartsDirective],
  exports: [ChartComponent],
})
export class EchartsModule {}
