import { Injectable, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UnsavedChangesDialogComponent } from '../shared/unsaved-changes/unsaved-changes.dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard {
  private modal = inject(NgbModal);

  canDeactivate(
    component: CanComponentDeactivate,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate()
      ? true
      : this.modal
          .open(UnsavedChangesDialogComponent, {
            centered: true,
            size: 'md',
          })
          .result.then(
            () => {
              return true;
            },
            () => {
              return false;
            },
          );
  }
}
