import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SpinnerInterceptor } from './controls/interceptors/spinner-interceptor';
import { TimeoutInterceptor } from './core/interceptors/timeout.interceptor';

import { NgOptimizedImage } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  NgbCollapseModule,
  NgbOffcanvasModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AssessorModule } from './assessor/assessor.module';
import { AuthModule } from './auth.module';
import { ClaimModule } from './claim/claim.module';
import { CompetenciesModule } from './competencies/competencies.module';
import { ControlsModule } from './controls/controls.module';
import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DynamicGroupsModule } from './dynamic-groups/dynamic-groups.module';
import { EventsModule } from './events/events.module';
import { JobRoleModule } from './job-role/job-role.module';
import { OrgUnitModule } from './org-unit/org-unit.module';
import { PersonModule } from './person/person.module';
import { ToFirstCharUpperCasePipe } from './pipes/to-first-char-upper-case.pipe';
import { ProjectsModule } from './projects/projects.module';
import { SkillModule } from './skill/skill.module';
import { SubmissionModule } from './submission/submission.module';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent, ToFirstCharUpperCasePipe],
  bootstrap: [AppComponent],
  exports: [],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    AuthModule,
    FormsModule,
    BrowserAnimationsModule,
    AssessorModule,
    ClaimModule,
    ControlsModule,
    CoreModule,
    DashboardModule,
    OrgUnitModule,
    PersonModule,
    SkillModule,
    JobRoleModule,
    SubmissionModule,
    NgbCollapseModule,
    NgbOffcanvasModule,
    ProjectsModule,
    CompetenciesModule,
    EventsModule,
    TranslocoRootModule,
    DynamicGroupsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AppRoutingModule,
  ],
  providers: [
    AppConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
