<div class="card overflow-visible">
  <div class="card-body">
    <div class="card overflow-visible">
      <div class="card-body">
        <ug-skill-filters
          (generateClickEvent)="generateAnalysis($event)"
          [showData]="showData"
        ></ug-skill-filters>
      </div>
    </div>

    <div *ngIf="showData" class="mx-3">
      <div *ngIf="!tableLoading && peopleVsClaimData.records.length">
        <ug-analytics-chart
        [selectedSkillName]="selectedSkillName"
          [peopleVsClaimChartData]="peopleVsClaimData.counts"
          (updateStatus)="updateStatus($event)"
        ></ug-analytics-chart>
      </div>
      <div class="my-3">
        <ug-table
          [showPagination]="true"
          [showSelectBox]="showSelectBox"
          [tableHeaders]="tableHeaders"
          [tableRows]="tableRows"
          [tableSelectedButtons]="tableSelectedButtons"
          [tableHeaderButtons]="tableHeaderButtons"
          [isLoading]="tableLoading"
          [tableNoDataMessage]="noDataMessage"
        >
        </ug-table>
      </div>
    </div>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
