import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface AuditFilter {
  filter: string;
  order: string;
  page: number;
  pageSize: number;
}

export interface AuditData {
  data: any[] | null;
  page: number;
  pageSize: number;
  totalPageCount: number;
  totalRowCount: number;
}

export interface AuditFilterValue {
  dateFrom: string;
  dateTo: string;
  action: string;
  modifiedBy: string;
  modifiedProperty: string;
  fromValue: string;
  toValue: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  updateAuditHistory: Subject<boolean> = new Subject();

  getAuditPaged!: (filter: AuditFilter, id?: number) => Observable<AuditData>;
}
