import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClaimService } from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';

import { ClaimAction } from '../../claim/models/claim-action';

@Component({
  selector: 'esqep-action-select-form', // tslint:disable-line:component-selector
  templateUrl: './action-select-form.component.html',
  styleUrls: ['./action-select-form.component.scss'],
})
export class ActionSelectFormComponent implements OnChanges, OnDestroy, OnInit {
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);

  @Input()
  parentFormGroup: FormGroup;
  @Input()
  targetFormGroup: FormGroup;
  @Input()
  buttonsFormGroup: FormGroup;
  @Input()
  initiatorType: string;
  @Input()
  claimStatusId: number;
  @Input()
  claimId: number;
  @Input()
  componentIndex: number | string;

  @Output()
  actionChange: EventEmitter<ClaimAction> = new EventEmitter();
  @Output()
  submit: EventEmitter<any> = new EventEmitter(true);
  @Output()
  cancel: EventEmitter<any> = new EventEmitter(true);

  claimActionsSubs = new Subscription();
  claimActions: Array<ClaimAction>;
  savedClaimStatusId: number;

  exceptionData = {
    CLAIM_ACTIONS: {
      level: AlertLevels.ERROR,
      code: 'CAC-001',
      message: 'Error retrieving claim actions',
      translationKey: 'errRetrievingClaimActions',
    } as AlertData,
  };

  ngOnChanges(changes: SimpleChanges) {
    if (!this.claimId || !this.claimStatusId) {
      return;
    }

    if (this.claimStatusId === this.savedClaimStatusId) {
      return;
    }
    this.savedClaimStatusId = this.claimStatusId;
    this.claimActionsSubs = this.claimService
      .getClaimActions(this.claimId, this.initiatorType)
      .subscribe(
        (ca: Array<ClaimAction>) => {
          this.claimActions = ca;
          this.savedClaimStatusId = this.claimStatusId;
          if (this.claimActions.length > 0) {
            this.parentFormGroup.enable();
          } else {
            this.parentFormGroup.disable();
          }
        },
        (err) => {
          this.alertService.createAlert2(this.exceptionData.CLAIM_ACTIONS, err);
        },
      );
  }

  ngOnInit() {
    this.parentFormGroup.disable();
    this.claimActionsSubs.add(
      this.targetFormGroup.valueChanges.subscribe((changes) => {
        if (
          this.targetFormGroup.pristine &&
          this.targetFormGroup.valid &&
          this.parentFormGroup.disabled
        ) {
          // setTimeout( () => {
          this.parentFormGroup.get('claimAction').enable();
          // this.submissionForm.get('action').updateValueAndValidity();  // Re-evaluate so buttons get correct state?
          // }, 0);
        } else if (
          (this.targetFormGroup.dirty || this.targetFormGroup.invalid) &&
          this.parentFormGroup.enabled
        ) {
          // setTimeout( () => {
          this.parentFormGroup.disable();
          this.buttonsFormGroup.disable();
          // }, 0)
        }
      }),
    );
  }

  ngOnDestroy() {
    this.claimActionsSubs.unsubscribe();
  }

  onActionChange() {
    const ca = this.claimActions.find((a) => {
      return a.id === this.parentFormGroup.get('claimAction.id').value;
    });
    this.actionChange.emit(ca || new ClaimAction());
  }

  onOkClick() {
    this.submit.emit(null);
  }

  onCancelClick() {
    this.cancel.emit(null);
  }

  matchClaimAction(ca1: ClaimAction, ca2: ClaimAction) {
    return ca1.id === ca2.id;
  }
}
