import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ClaimAction } from '../../claim/models/claim-action';
import { DotNetClaim } from '../../claim/services/claim.service';

@Component({
  selector: 'esqep-verifier-action-form', // tslint:disable-line:component-selector
  templateUrl: './verifier-action-form.component.html',
  styleUrls: ['./verifier-action-form.component.scss'],
})
export class VerifierActionFormComponent {
  @Input() parentFormGroup: FormGroup;
  @Input() detailFormGroup: FormGroup;
  @Input() claim: DotNetClaim;
  @Input() action: ClaimAction;

  @Output() actionChange: EventEmitter<ClaimAction> = new EventEmitter();
  @Output() actionCancel: EventEmitter<boolean> = new EventEmitter();
  @Output() actionSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output() tabLinkClick: EventEmitter<number> = new EventEmitter();

  onActionChange(action: ClaimAction) {
    this.actionChange.emit(action);
  }

  onSubmitActionClick() {
    this.actionSubmit.emit(true);
  }

  onCancelActionClick() {
    this.actionCancel.emit(true);
  }

  onTabLinkClick(tabIndex: number) {
    this.tabLinkClick.emit(tabIndex);
  }
}
