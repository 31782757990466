import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ug-claim-expiry-date-form',
  templateUrl: './claim-expiry-date-form.component.html',
  styleUrls: ['./claim-expiry-date-form.component.scss'],
})
export class ClaimExpiryDateFormComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  expiresIn = 0;

  get expiryDate(): AbstractControl {
    return this.parentFormGroup.get('expiryDate');
  }

  ngOnInit(): void {
    this.parentFormGroup.get('claimAction').valueChanges.subscribe((ca) => {
      if (ca.id) {
        //: Remove hard coding
        const isVerify = ca.name === 'Verify';
        const isExpiryDateDisabled = this.expiryDate.disabled;

        if (isVerify && isExpiryDateDisabled) {
          this.expiryDate.enable();
          this.expiryDate.addValidators(Validators.required);
        } else if (!isVerify && !isExpiryDateDisabled) {
          this.expiryDate.removeValidators(Validators.required);
          this.expiryDate.setValue(null);
          this.expiryDate.disable();
          this.expiresIn = 0;
        }
      } else {
        this.expiryDate.disable();
        this.expiryDate.removeValidators(Validators.required);
      }

      this.expiryDate.updateValueAndValidity();
    });

    this.expiryDate.valueChanges.subscribe((ed) => {
      if (ed) {
        this.expiresIn = 0;
      }
    });
  }

  updateExpiryDate(event) {
    const startDate = new Date();
    const startMonth = startDate.getMonth();
    const newDate = new Date(startDate.setMonth(startMonth + Number(event)));
    const patchValueDate = formatDate(newDate, 'yyyy-MM-dd', 'en-UK');
    this.expiryDate.setValue(patchValueDate);
    this.expiresIn = event;
  }

  isExpiryDateRequired(): boolean {
    return this.expiryDate?.hasError('required');
  }

  isExpiryDateInPast(): boolean {
    return (
      !this.isExpiryDateRequired() && this.expiryDate?.hasError('dateInPast')
    );
  }
}
