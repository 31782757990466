import { Component, OnInit, inject } from '@angular/core';

import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'ug-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  private spinnerService = inject(SpinnerService);


  get showSpinner(): boolean {
    return this.spinnerService.showSpinner;
  }
  get spinnerCount(): number {
    return this.spinnerService.count;
  }

  ngOnInit() {}
}
