import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ug-unsaved-changes-dialog',
  template: `<div class="modal-content">
      <div class="modal-header bg-dark text-light">
        <h4 class="modal-title">Leave Page</h4>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"
        ></button>
      </div>
    </div>
    <div class="modal-body">
      <p>
        Are you sure you want to leave the current page? The changes that you
        made will not be saved.
      </p>
    </div>
    <hr />
    <div class="modal-footer border-0">
      <button
        type="button"
        class="btn btn-outline-secondary ms-3"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-danger btn-md" (click)="leave()">
        Leave
      </button>
    </div>`,
})
export class UnsavedChangesDialogComponent {
  activeModal = inject(NgbActiveModal);

  leave() {
    this.activeModal.close('Leave');
  }

  cancel() {
    this.activeModal.dismiss('Cancel');
  }
}
