import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ControlsModule } from '../controls/controls.module';
import { JobFamilyFilterComponent } from './job-family-filter/job-family-filter.component';
import { KnowledgeSelectComponent } from './knowledge-select/knowledge-select.component';
import { SkillService } from './services/skill.service';
import { SkillListComponent } from './skill-list/skill-list.component';
import { SkillRoutingModule } from './skill-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlsModule,
    SkillRoutingModule,
  ],
  declarations: [
    SkillListComponent,
    JobFamilyFilterComponent,
    KnowledgeSelectComponent,
  ],
  exports: [
    SkillListComponent,
    JobFamilyFilterComponent,
    KnowledgeSelectComponent,
  ],
  providers: [SkillService],
})
export class SkillModule {}
