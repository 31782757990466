<ng-template #progressModal let-modal>
  <div class="modal-content">
    <div class="modal-header bg-dark text-light">
      <h5 class="modal-title">Group Upload Progress</h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        (click)="modal.close()"
        aria-label="Close"
        [attr.disabled]="updatesInProgress ? true : null"
      ></button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <ug-progress-bar
          [progressTitle]="'Group Filters Processed'"
          [progressTotal]="groupUploadTotal"
          [progressSuccess]="groupProcessedDone"
          [progressFailed]="groupProcessedFailed"
        ></ug-progress-bar>
      </div>
      <div class="container-fluid">
        <ug-progress-bar
          [progressTitle]="'Group Filters Uploaded'"
          [progressTotal]="groupUploadTotal"
          [progressSuccess]="groupUploadDone"
          [progressFailed]="groupUploadFailed"
        ></ug-progress-bar>
      </div>

      <div class="mt-1">
        <ug-table
          [tableHeaders]="tableHeaders"
          [tableRows]="groupFilterTableRows"
          [isLoading]="isLoading"
        ></ug-table>
      </div>
    </div>
    <div class="modal-footer">
      <div *ngIf="groupProcessedFailed > 0">
        <span class="text-danger"
          >Unable to upload groups whilst there are errors in the upload
          file</span
        >
      </div>
      <button
        *ngIf="!hideUploadButton"
        type="button"
        (click)="confirmUpload()"
        class="btn esqepBtn"
        [disabled]="groupProcessedFailed"
      >
        Confirm Upload
      </button>
    </div>
  </div>
</ng-template>
<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Upload Group Filters</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="closeModal()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <form class="my-2">
      <input
        type="file"
        class="form-control form-control-sm"
        (change)="fileUploadChange($event)"
        placeholder="Choose file"
      />
    </form>
  </div>
</div>
