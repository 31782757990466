import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Component({
  selector: '[ug-requirement-row]',
  templateUrl: './requirement-row.component.html',
  styleUrl: './requirement-row.component.scss',
})
export class RequirementRowComponent {
  @Input() roleRequirement: AbstractControl;
  @Input() showRequirementSelectBox = false;
  @Input() rowIndex: number;
  @Input() allowEdit: boolean;
  @Output() removeRequirementEvent = new EventEmitter<number>();
  @Output() discardAddedRequirementEvent = new EventEmitter<number>();
  @Output() discardChangedRequirementEvent = new EventEmitter<{
    id: number;
    index: number;
  }>();
  @Output() onDiscardChangeRequirementEvent = new EventEmitter<{
    parentSkillId: number;
    skillId: number;
  }>();
  @Output() toggleRowEvent = new EventEmitter<number>();
  @Output() selectedRequirementEvent = new EventEmitter<any>();
  @Input() isCurricChild: boolean = false;
  @Input() parentSkillId: number = null;
  @Output() restoreRequirementEvent = new EventEmitter<number>();
  @Input() parentId: number;

  collapsedRowMap = new Map<number, boolean>();
  protected skillAdditionalDetails = '';

  toggleRow(skillId: number) {
    this.collapsedRowMap.set(
      skillId,
      !this.collapsedRowMap.get(skillId) || false,
    );
    this.toggleRowEvent.emit(skillId);
  }

  formControlValid(controlName: string): boolean {
    const c: AbstractControl = this.roleRequirement.get(controlName);
    return c?.disabled || !(c?.touched && c?.invalid);
  }

  enableKeepDueDates(): boolean {
    return (
      !this.roleRequirement.get('pendingDeletion').value &&
      (this.roleRequirement.get('dueAfterValue').dirty ||
        this.roleRequirement.get('dueAfterUnit').dirty ||
        this.roleRequirement.get('newVersionDueValue').dirty ||
        this.roleRequirement.get('newVersionDueUnit').dirty ||
        this.roleRequirement.get('newHireDueValue').dirty ||
        this.roleRequirement.get('newHireDueUnit').dirty)
    );
  }

  onRemoveRequirementClick() {
    this.removeRequirementEvent.emit(this.rowIndex);
  }

  onRestoreRequirementClick() {
    this.restoreRequirementEvent.emit(this.rowIndex);
  }

  onDiscardAddedRequirementClick() {
    this.discardAddedRequirementEvent.emit(this.rowIndex);
  }

  onDiscardChangeRequirementClick() {
    this.onDiscardChangeRequirementEvent.emit({
      parentSkillId: this.parentSkillId,
      skillId: this.roleRequirement.value.skillId,
    });
  }

  onSelectChange() {
    this.selectedRequirementEvent.emit({
      parentId: this.parentSkillId,
      skillId: this.roleRequirement.value.skillId,
    });
  }

  ngOnInit() {
    this.watchFormValueChanges();
    this.skillAdditionalDetails = this.getAdditionalSkillDetails();
  }

  getAdditionalSkillDetails() {
    let additionalDetails = [];

    if (this.roleRequirement.get('trainingType').value) {
      additionalDetails.push(this.roleRequirement.get('trainingType').value);
    }

    if (this.roleRequirement.get('trainingVersion').value) {
      additionalDetails.push(this.roleRequirement.get('trainingVersion').value);
    }

    if (this.roleRequirement.get('provider').value) {
      additionalDetails.push(this.roleRequirement.get('provider').value);
    }

    if (this.roleRequirement.get('owner').value) {
      additionalDetails.push(this.roleRequirement.get('owner').value);
    }

    return additionalDetails.join(' / ');
  }

  watchFormValueChanges() {
    const controls = [
      'dueAfterValue',
      'dueAfterUnit',
      'newVersionDueValue',
      'newVersionDueUnit',
      'newHireDueValue',
      'newHireDueUnit',
      'recurValue',
      'recurUnit',
    ];
    const children = this.roleRequirement.get('children') as FormArray;

    controls.forEach((control) => {
      this.roleRequirement.get(control).valueChanges.subscribe((x) => {
        if (
          this.roleRequirement.get('keepDueDates').disabled &&
          this.enableKeepDueDates
        ) {
          this.roleRequirement.get('keepDueDates').enable();
        } else if (!this.enableKeepDueDates) {
          this.roleRequirement.get('keepDueDates').disable();
        }

        if (!this.isCurricChild && children.value.length > 0) {
          children.controls.forEach((child, index) => {
            child.get(control).setValue(x);
            if (child.get('id').value) {
              child.get(control).markAsDirty();
              child.get(control).markAsUntouched({ onlySelf: true });
            }
          });
        }
      });
    });
  }
}
