<div *ngIf="noAssessmentAvailable; else showAssessmentHistory" class="row mt-3">
  <div class="col-12">
    <p>There is no assessment history for this session.</p>
  </div>
</div>

<ng-template #showAssessmentHistory>
  <ng-container *ngIf="!isLoading">
    <p class="fst-italic">
      Assessment completed by {{ bulkAssessment.assessorName }}
    </p>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <ng-container
              *ngIf="bulkAssessmentSkills.length > 0; else emptyHeading"
            >
              <th scope="col">Competencies</th>
            </ng-container>
            <ng-template #emptyHeading>
              <th scope="col"></th>
            </ng-template>
            <th
              scope="col"
              style="text-align: left; min-width: 150px"
              *ngFor="let person of bulkAssessment?.people"
            >
              {{ person.personName }}
              <span
                class="text-center"
                [ngbTooltip]="person.attended ? 'Attended' : 'Not Attended'"
                ><i
                  class="fas fa-sm"
                  [ngClass]="
                    person.attended
                      ? 'fa-check-circle text-success'
                      : 'fa-times-circle text-danger'
                  "
                ></i
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-align-left vertical-align-middle"
            *ngFor="let competency of bulkAssessmentSkills"
          >
            <td style="min-width: 300px">
              {{ competency.skillName }}
            </td>
            <td
              class="text-align-left vertical-align-middle"
              *ngFor="let person of bulkAssessment?.people"
            >
              {{ competency.claimLevelName }}
            </td>
          </tr>
          <tr style="border-top: 3px solid rgb(87, 85, 85)">
            <td class="text-align-left vertical-align-middle">
              <strong>Comments</strong>
            </td>
            <td
              class="text-align-left vertical-align-middle"
              *ngFor="let people of bulkAssessment?.people"
            >
              {{ people.comments || '-' }}
            </td>
          </tr>

          <tr>
            <td class="text-align-left vertical-align-middle">
              <strong>Final Score</strong>
            </td>
            <td
              class="text-align-left vertical-align-middle"
              *ngFor="let people of bulkAssessment?.people"
            >
              {{ people.score || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-align-left vertical-align-middle">
              <strong>Assessment Passed</strong>
            </td>
            <td
              class="text-align-left vertical-align-middle"
              *ngFor="let people of bulkAssessment?.people"
            >
              <span
                class="fas fa-sm text-center"
                [ngClass]="
                  people.isPass
                    ? 'fa-check-circle text-success'
                    : 'fa-times-circle text-danger'
                "
              >
              </span>
            </td>
          </tr>
          <tr>
            <td class="text-align-left vertical-align-middle">
              <strong>Assessment Outcome</strong>
            </td>
            <td
              class="text-align-left vertical-align-middle"
              *ngFor="let people of bulkAssessment?.people"
            >
              {{ people.outcome || '-' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <h4>Assessor Commentary</h4>
        <ng-container
          *ngIf="
            bulkAssessment?.comments && bulkAssessment?.comments?.length;
            else noAssessorComments
          "
        >
          <blockquote class="blockquote">
            <p>{{ bulkAssessment.comments }}</p>
          </blockquote>
        </ng-container>
        <ng-template #noAssessorComments>
          <p class="fst-italics">No comments have been left by the assessor</p>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
