import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { TypeaheadService } from '../../../../controls/dropdown-select/typeahead.service';
import {
  AlertData,
  AlertLevels,
} from '../../../../core/services/alert.service';
import { ConfigService } from '../../../../core/services/config.service';
import { OrgUnit } from '../../../../org-unit/models/org-unit';
import { OrgUnitService } from '../../../../org-unit/services/org-unit.service';
import {
  AuditData,
  AuditFilter,
} from '../../../../shared/audit-table/audit.service';
import { RoleManagementConfig } from '../role-management.service';

@Component({
  selector: 'ug-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss'],
})
export class RoleDetailsComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() detailsAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;

  @ViewChild('detailsPopover') detailsPopover: NgbPopover;

  private typeaheadService = inject(TypeaheadService);
  private orgUnitService = inject(OrgUnitService);
  private configService = inject(ConfigService);
  private destroyRef = inject(DestroyRef);
  private ouLocations: OrgUnit[];

  protected maxNumOfChars = RoleManagementConfig.descriptionMaxNumbOfChars;
  protected locationSearch: OperatorFunction<string, OrgUnit[]>;
  protected selectedLocation: OrgUnit;
  protected isCollapsed = true;

  exceptionData = {
    JOB_ROLE_CREATE: {
      level: AlertLevels.ERROR,
      code: 'JRD-001',
      message: 'Error creating job role',
    } as AlertData,
    JOB_ROLE_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'JRD-002',
      message: 'Error updating job role',
    } as AlertData,
    DISCIPLINE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-004',
      message: 'Error retrieving disciplines',
      translationKey: 'errRetrievingDisciplines',
    } as AlertData,
    ORG_UNIT_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-005',
      message: 'Error retrieving organisation units',
    } as AlertData,
    PEOPLE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-006',
      message: 'Error retrieving people',
    } as AlertData,
    JOB_FAMILY_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-007',
      message: 'Error retrieving job families',
    } as AlertData,
    SKILLS_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-009',
      message: 'Error retrieving skills',
    } as AlertData,
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'JRD-010',
      message: 'Error retrieving assessment request levels',
    } as AlertData,
  };

  aiPrompt: any;
  showConfirmButton: boolean;
  showRoleManagementAiPrompt: boolean;
  selectedPopover: NgbPopover;
  popoverKey: string;
  popoverPrompt: string;

  compareName(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  sortName(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  nameFormatter = (result) => result['name'];

  get jobRoleId() {
    return this.parentFormGroup.get('id').value;
  }

  ngOnInit(): void {
    this.showRoleManagementAiPrompt =
      this.configService.showRoleManagementAiPrompt;

    this.orgUnitService
      .getExtOrgUnitsByType('Location')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((oul) => {
        this.ouLocations = oul;
        this.locationSearch = this.typeaheadService.typeahead(
          this.ouLocations,
          this.compareName,
          this.sortName,
        );

        if (this.parentFormGroup.get('locationId').value) {
          this.selectedLocation = this.ouLocations.find(
            (f) => f.id === this.parentFormGroup.get('locationId').value,
          );
        }
      });
  }

  confirmAiResponse($event) {
    this.parentFormGroup.get($event.key).setValue($event.aiResponse);
    this.parentFormGroup.get($event.key).markAsDirty();
    this.parentFormGroup.get($event.key).markAsTouched();
    this.selectedPopover.close();
  }

  get roleDescriptionLength() {
    return this.parentFormGroup.get('description').value?.length || 0;
  }

  onChange($event) {
    this.parentFormGroup.get('locationId').setValue($event.id);
    this.parentFormGroup.get('locationId').markAsDirty();
  }

  openClosePopover(key: string, popover: NgbPopover) {
    if (this.selectedPopover && this.selectedPopover.isOpen()) {
      this.selectedPopover.close();
    } else {
      this.popoverKey = key;
      this.selectedPopover = popover;
      this.popoverPrompt = this.parentFormGroup.get(key).value;

      this.selectedPopover.open();
    }
  }
}
