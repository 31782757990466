import { Component, OnInit } from '@angular/core';
import { MatrixType } from '../matrix/matrix.service';

@Component({
  selector: 'ug-training-matrix-summary',
  templateUrl: './training-matrix-summary.component.html',
  styleUrls: ['./training-matrix-summary.component.scss'],
})
export class TrainingMatrixSummaryComponent implements OnInit {
  matrixType = MatrixType.Training;

  ngOnInit(): void {}
}
