import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DateFilter } from '../../../controls/dynamic-form/control-types/filter-date';
import { DropdownSelectFilter } from '../../../controls/dynamic-form/control-types/filter-select';
import { TextboxFilter } from '../../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import { FilterControlService } from '../../../controls/dynamic-form/filter-control.service';
import { AuditFilterValue } from '../audit.service';

@Component({
  selector: 'ug-audit-filters',
  templateUrl: './audit-filters.component.html',
  styleUrl: './audit-filters.component.scss',
})
export class AuditFiltersComponent {
  @Input() contextKey: string;
  @Input() contextTitle: string;
  @Output() filtersChangedEvent = new EventEmitter();

  private filterControlService = inject(FilterControlService);
  protected filtersForm: FormGroup;
  protected auditFilters = [
    new DateFilter({
      key: 'dateFrom',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Date from',
    }),
    new DateFilter({
      key: 'dateTo',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Date to',
    }),
    new DropdownSelectFilter({
      key: 'action',
      placeholder: 'Action...',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Action',
      options: [
        { key: 'Add', value: 'Add' },
        { key: 'Update', value: 'Update' },
        { key: 'Delete', value: 'Delete' },
      ],
    }),
    new TextboxFilter({
      key: 'modifiedBy',
      newRow: true,
      placeholder: 'Modified by...',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Modified by',
    }),
    new TextboxFilter({
      key: 'modifiedProperty',
      placeholder: 'Modified property...',
      filterClass: 'col-sm-12 col-md-2',
      label: 'Modified property',
    }),
    new TextboxFilter({
      key: 'fromValue',
      placeholder: 'From value...',
      filterClass: 'col-sm-12 col-md-2',
      label: 'From value',
      tooltip: 'To search for empty values, use "null"',
    }),
    new TextboxFilter({
      key: 'toValue',
      filterClass: 'col-sm-12 col-md-2',
      label: 'To value',
      placeholder: 'To value...',
      tooltip: 'To search for empty values, use "null"',
    }),
  ];

  ngOnInit() {
    if (this.contextKey) {
      const contextFilter = new TextboxFilter({
        key: this.contextKey,
        filterClass: 'col-sm-12 col-md-2',
        label: this.contextTitle,
        placeholder: `${this.contextTitle}...`,
      });
      this.auditFilters.splice(4, 0, contextFilter);
    }

    this.filtersForm = this.filterControlService.toFormGroup(
      this.auditFilters as FilterBase<string>[],
    );

    this.filtersForm.valueChanges.pipe(debounceTime(300)).subscribe({
      next: (value: AuditFilterValue) => {
        this.filtersChangedEvent.emit(value);
      },
    });
  }
}
