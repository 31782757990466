<ng-container [formGroup]="roleRequirement">
  <td *ngIf="showRequirementSelectBox" [ngClass]="">
    <input type="checkbox" (change)="onSelectChange()" />
  </td>
  <td class="border-end" [ngClass]="isCurricChild ? 'text-end' : null">
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-fill">
        <div *ngIf="!roleRequirement.value.active">
          <span class="badge inactive-badge">Inactive</span>
        </div>
        <div
          [ngClass]="
            roleRequirement.value.trainingType === 'Curriculum'
              ? 'fw-bold'
              : null
          "
        >
          {{ roleRequirement.value.skillName }}
        </div>
        <div>
          [
          <span class="fst-italic">{{ skillAdditionalDetails }} </span>
          ]
        </div>
      </div>
      <div
        *ngIf="
          !isCurricChild && roleRequirement.value.trainingType === 'Curriculum'
        "
      >
        <button
          class="btn btn-link toggle-row p-0 me-2"
          [attr.aria-expanded]="
            collapsedRowMap.get(roleRequirement.value.skillId)
          "
          (click)="toggleRow(roleRequirement.value.skillId)"
        >
          <span
            class="fas"
            [ngClass]="
              collapsedRowMap.get(roleRequirement.value.skillId)
                ? 'fa-chevron-down'
                : 'fa-chevron-right'
            "
          ></span>
        </button>
      </div>
    </div>
  </td>

  <td>
    <div class="input-group input-group-sm">
      <input
        type="number"
        min="1"
        class="form-control"
        id="recur-after-value-control"
        formControlName="recurValue"
        title="Recurs every"
        [ngClass]="{
          'is-invalid': !formControlValid('recurValue')
        }"
      />
      <select
        class="form-select chevron-position pe-3 ps-1"
        formControlName="recurUnit"
        title="Recurs every unit"
      >
        <option [value]="'day'">days</option>
        <option [value]="'week'">weeks</option>
        <option [value]="'month'">months</option>
        <option [value]="'year'">years</option>
      </select>
      <div class="invalid-feedback">
        Recur value must be blank or greater than 0
      </div>
    </div>
  </td>
  <td>
    <div class="input-group input-group-sm">
      <input
        type="number"
        min="1"
        class="form-control pe-0 ps-2 recur-due-alignment"
        id="due-after-value-control"
        formControlName="dueAfterValue"
        title="Due after"
        [ngClass]="{
          'is-invalid': !formControlValid('dueAfterValue')
        }"
      />
      <select
        class="form-select chevron-position pe-3 ps-1"
        formControlName="dueAfterUnit"
        title="Due after unit"
      >
        <option [value]="'day'">days</option>
        <option [value]="'week'">weeks</option>
        <option [value]="'month'">months</option>
        <option [value]="'year'">years</option>
      </select>
      <div class="invalid-feedback">
        Due after value must be blank or greater than 0
      </div>
    </div>
  </td>
  <td>
    <div class="input-group input-group-sm">
      <input
        type="number"
        min="1"
        class="form-control pe-0 ps-2 recur-due-alignment"
        id="reversioning-due-after-value-control"
        formControlName="newVersionDueValue"
        title="Reversioning value"
        [ngClass]="{
          'is-invalid': !formControlValid('newVersionDueValue')
        }"
      />
      <select
        class="form-select chevron-position pe-3 ps-1"
        formControlName="newVersionDueUnit"
        title="Reversioning unit"
      >
        <option [value]="'day'">days</option>
        <option [value]="'week'">weeks</option>
        <option [value]="'month'">months</option>
        <option [value]="'year'">years</option>
      </select>
      <div class="invalid-feedback">
        Reversioning due value must be blank or greater than 0
      </div>
    </div>
  </td>
  <td>
    <div class="input-group input-group-sm">
      <input
        type="number"
        min="1"
        class="form-control pe-0 ps-2 recur-due-alignment"
        id="new-hire-value-control"
        formControlName="newHireDueValue"
        title="New Hire"
        [ngClass]="{
          'is-invalid': !formControlValid('newHireDueValue')
        }"
      />
      <select
        class="form-select chevron-position pe-3 ps-1"
        formControlName="newHireDueUnit"
        title="New hire unit"
      >
        <option [value]="'day'">days</option>
        <option [value]="'week'">weeks</option>
        <option [value]="'month'">months</option>
        <option [value]="'year'">years</option>
      </select>
      <div class="invalid-feedback">
        New hire due value must be blank or greater than 0
      </div>
    </div>
  </td>
  <td class="text-center">
    <input
      class="form-check-input"
      type="checkbox"
      [attr.id]="'keep-due-date-flag-control-' + rowIndex"
      formControlName="keepDueDates"
      title="Retain existing due dates?"
    />
  </td>
  <td class="text-center">
    <input
      *ngIf="!isCurricChild"
      class="form-check-input"
      type="checkbox"
      id="training-mandatory-flag-control"
      formControlName="mandatory"
      title="Is requirement mandatory?"
    />
  </td>
  <td class="">
    <div
      *ngIf="
        roleRequirement?.value.pendingDeletion &&
        !roleRequirement.dirty &&
        !isCurricChild
      "
      class="alert alert-danger p-1 text-center m-0"
    >
      Pending Removal
    </div>

    <ng-container *ngIf="allowEdit && !roleRequirement?.value.pendingDeletion">
      <button
        *ngIf="
          (roleRequirement.get('dueAfterUnit').dirty ||
            roleRequirement.get('dueAfterValue').dirty ||
            roleRequirement.get('newVersionDueUnit').dirty ||
            roleRequirement.get('newVersionDueValue').dirty ||
            roleRequirement.get('newHireDueUnit').dirty ||
            roleRequirement.get('newHireDueValue').dirty ||
            roleRequirement.get('recurUnit').dirty ||
            roleRequirement.get('recurValue').dirty ||
            roleRequirement.get('mandatory').dirty) &&
          (roleRequirement.value.id || (isCurricChild && parentId > 0))
        "
        type="button"
        class="btn btn-outline-danger btn-sm float-end"
        (click)="onDiscardChangeRequirementClick()"
      >
        Undo
      </button>
      <button
        *ngIf="
          !isCurricChild &&
          roleRequirement?.value.id &&
          !(
            roleRequirement.get('dueAfterUnit').dirty ||
            roleRequirement.get('dueAfterValue').dirty ||
            roleRequirement.get('newVersionDueUnit').dirty ||
            roleRequirement.get('newVersionDueValue').dirty ||
            roleRequirement.get('newHireDueUnit').dirty ||
            roleRequirement.get('newHireDueValue').dirty ||
            roleRequirement.get('recurUnit').dirty ||
            roleRequirement.get('recurValue').dirty ||
            roleRequirement.get('mandatory').dirty
          )
        "
        type="button"
        class="btn btn-outline-danger btn-sm float-end"
        (click)="onRemoveRequirementClick()"
      >
        Remove
      </button>
      <button
        *ngIf="
          !isCurricChild && roleRequirement?.dirty && !roleRequirement?.value.id
        "
        type="button"
        class="btn btn-outline-danger btn-sm float-end"
        (click)="onDiscardAddedRequirementClick()"
      >
        Discard
      </button>
    </ng-container>

    <button
      *ngIf="
        !isCurricChild &&
        allowEdit &&
        roleRequirement?.value.pendingDeletion &&
        roleRequirement.dirty
      "
      type="button"
      class="btn btn-outline-secondary btn-sm float-end"
      (click)="onRestoreRequirementClick()"
    >
      Restore
    </button>
  </td>
</ng-container>
