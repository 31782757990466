import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SkillFilterPayload } from '../../skill/services/skill.service';

const apiServerUri = environment.serverUrl;

export class SubjectDictionary {
  [key: string]: SkillSubject[];
}

export class SkillSubject {
  id: number;
  dimensionSkillCounts: DimensionSkillCount[];
  parentId: number;
  subjectLevelId: number;
  name: string;
  isActive: boolean;
  source: string;
  externalId: number;
  externalParentId: number;
  dimensionIds: Array<number>;
  cultureId: number;
  culture: string;
  canDelete: boolean;
  skillCount: number;
  functionalAreaCount: number;
  subjectIds: Array<number>;
}

export class DimensionSkillCount {
  dimensionId: number;
  skillCount: number;
}

export class SkillDimension {
  id: number;
  name: string;
}

export class SubjectLevels {
  id: number;
  name: string;
}

export class SubjectPayload {
  parentId: number;
  subjectLevelId: number;
  name: string;
  isActive: boolean;
  source: string;
  externalId: number;
  externalParentId: number;
}

export class DimensionPayload {
  name: string;
}

export class SkillVsSubject {
  id: number;
  name: string;
  description: string;
  belongsToSubject: boolean;
  subjects: [
    {
      id: number;
      name: string;
    },
  ];
}

export enum SkillDimensionId {
  Application = 1,
  Behaviour = 2,
  Knowledge = 3,
  Training = 4,
}

export interface EventCompetency {
  skillId: number;
  competencyId: number;
  competency: Competency;
  claimLevelSet: ClaimLevelSet;
}

export interface ClaimLevelSet {
  id: number;
  name: string;
  description: string;
  claimLevels: any[];
  functionalAreas: any[];
}

export interface Competency {
  id: number;
  competencyCategoryId: number;
  parentCompetencyTreeId: number;
  name: string;
  description: string;
  claimLevelSetId: number;
  claimStatusActionSetId: number;
  attribute: string;
  notes: string;
  allowMultipleClaims: boolean;
}

export interface SkillBySubjectId {
  skillId: number;
  skillName: string;
  skillDescription: string;
  dimensionId: number;
  dimensionName: string;
}

@Injectable({
  providedIn: 'root',
})
export class CompetenciesService {
  private http = inject(HttpClient);


  dimensionBgMap = new Map<number, string>([
    [1, 'text-white bg-sqeptech'],
    [2, 'text-white bg-sqeptech-purple'],
    [3, 'text-white bg-sqeptech-red'],
    [4, 'text-sqeptech-dark bg-sqeptech-orange'],
  ]);

  dimensionButtonMap = new Map<number, string>([
    [1, 'dimension-btn-application'],
    [2, 'dimension-btn-behaviour'],
    [3, 'dimension-btn-knowledge'],
    [4, 'dimension-btn-training'],
  ]);

  addButtonMap = new Map<number, string>([
    [1, 'add-btn-application'],
    [2, 'add-btn-behaviour'],
    [3, 'add-btn-knowledge'],
    [4, 'add-btn-training'],
  ]);

  actionButtonMap = new Map<number, string>([
    [1, 'action-btn-application'],
    [2, 'action-btn-behaviour'],
    [3, 'action-btn-knowledge'],
    [4, 'action-btn-training'],
  ]);

  selectedSubjectButtonMap = new Map<number, string>([
    [1, 'selectedSubject-btn-application'],
    [2, 'selectedSubject-btn-behaviour'],
    [3, 'selectedSubject-btn-knowledge'],
    [4, 'selectedSubject-btn-training'],
  ]);

  badgeColourMap = new Map<number, string>([
    [1, 'badge-application'],
    [2, 'badge-behaviour'],
    [3, 'badge-knowledge'],
    [4, 'badge-training'],
  ]);

  getSubjectsDictionary(): Observable<SubjectDictionary> {
    return this.http.get(`${apiServerUri}/subjects/dictionary`).pipe(
      map((resp: SubjectDictionary) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  addSubject(subjectData: SubjectPayload): Observable<SkillSubject> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(`${apiServerUri}/subjects`, JSON.stringify(subjectData), {
        headers: headers,
      })
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updateSubjectById(
    subjectId: number,
    subjectData: SubjectPayload,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(
        `${apiServerUri}/subjects/${subjectId}`,
        JSON.stringify(subjectData),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  deleteSubjectById(subjectId: number): Observable<any> {
    return this.http.delete(`${apiServerUri}/subjects/${subjectId}`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getSkillDimensions(): Observable<Array<SkillDimension>> {
    return this.http.get(`${apiServerUri}/skillDimensions`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getSubjectLevels(): Observable<Array<SubjectLevels>> {
    return this.http.get(`${apiServerUri}/subjectLevels`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  updateSubjectLevelById(
    subjectLevelId: number,
    subjectObject,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${apiServerUri}/subjectLevels/${subjectLevelId}`,
        JSON.stringify(subjectObject),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updateSubjectAndChildDimensionsById(
    subjectId: number,
    dimensionIds: Array<number>,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(
        `${apiServerUri}/subjectDimensions/bySubjectId/${subjectId}/includeChildren`,
        JSON.stringify(dimensionIds),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updateSubjectDimensionsById(
    subjectId: number,
    dimensionIds: Array<number>,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(
        `${apiServerUri}/subjectDimensions/bySubjectId/${subjectId}`,
        JSON.stringify(dimensionIds),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsVsSubjectId(
    subjectId: number,
    filterData: SkillFilterPayload,
  ): Observable<SkillVsSubject[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<SkillVsSubject[]>(
      `${apiServerUri}/skills/vsSubjectId/${subjectId}`,
      JSON.stringify(filterData),
      { headers: headers },
    );
  }

  updateSubjectSkillsById(
    subjectId: number,
    skillIds: Array<number>,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(
        `${apiServerUri}/skillSubjects/bySubjectId/${subjectId}`,
        JSON.stringify(skillIds),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsBySubjectId(subjectId: number): Observable<SkillBySubjectId[]> {
    return this.http
      .get(`${apiServerUri}/skills/bySubjectId/${subjectId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }
}
