<div [formGroup]="parentFormGroup">
  <button
    (click)="openAddRolesModal()"
    class="btn esqepBtn"
    title="Add Project Roles"
  >
    Add Roles
  </button>
  <div class="fst-italic mt-2">
    Click the "Add Roles" button to add roles to the project. In the table
    below, set the required quantity and the minimum role match.
  </div>
  <div class="fst-italic" [hidden]="jobRoles.controls?.length === 0">
    To see the role competency requirements, click the
    <span class="ms-2"><i class="fas fa-search-plus fw-bold"></i></span>
  </div>
  <div
    [formGroup]="parentFormGroup.get('projectRoles.assignedJobRoles')"
    class="d-flex mt-3"
  >
    <div class="w-100 jr-table">
      <div class="alert alert-info" [hidden]="jobRoles.controls?.length > 0">
        <span class="fas fa-xl fa-info-circle"></span>
        Add a role to the project
      </div>
      <table
        class="table table-responsive"
        [hidden]="jobRoles.controls?.length === 0"
      >
        <thead>
          <tr>
            <th scope="col" class="w-30">Job Role</th>
            <th scope="col" class="w-20">Required</th>
            <th scope="col" class="w-30">Minimum Role Match</th>
            <th scope="col" class="w-20">Actions</th>
          </tr>
        </thead>
        <tbody formArrayName="jobRoles">
          <tr
            *ngFor="let jobRole of jobRoles.controls; let i = index"
            [formGroupName]="i"
          >
            <td>
              <div>
                <div class="form-group mb-0">
                  {{ jobRole.value.name }}
                </div>
              </div>
            </td>
            <td>
              <select
                class="form-select form-select-sm qtySelect"
                formControlName="quantity"
              >
                <option *ngFor="let qty of quantityDropDown" [value]="qty">
                  {{ qty }}
                </option>
              </select>
            </td>
            <td>
              <div class="d-flex">
                <input
                  type="range"
                  min="0"
                  max="100"
                  formControlName="minRoleMatch"
                  step="5"
                  [value]="jobRole.value.minRoleMatch"
                  aria-label="minRoleMatch"
                  aria-describedby="minRoleMatch"
                />
                <div class="input-group input-group-sm ms-2 roleMatch">
                  <span class="input-group-text" id="minRolePercent">%</span>
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="100"
                    class="form-control form-control-sm rmNumber"
                    aria-label="MinRoleMatch"
                    aria-describedby="minRoleMatch"
                    formControlName="minRoleMatch"
                    [value]="jobRole.value.minRoleMatch"
                  />
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center my-2">
                <button
                  (click)="removeRole(i)"
                  class="border-0 p-0 m-0 bg-white me-2"
                  title="Remove role"
                >
                  <i class="fas fa-trash-alt text-danger"></i>
                </button>
                <button
                  *ngIf="!requirementsShowing"
                  title="View competency requirements"
                  class="border-0 p-0 m-0 bg-white mx-3"
                  data-bs-toggle="collapse"
                  data-bs-target="#compRequirements"
                  aria-expanded="true"
                  aria-controls="compRequirements"
                  type="button"
                  (click)="openRequirements(i)"
                >
                  <i class="fas fa-search-plus"></i>
                </button>
                <button
                  *ngIf="requirementsShowing"
                  title="View competency requirements"
                  class="border-0 p-0 m-0 bg-white mx-2"
                  type="button"
                  (click)="setActiveJobRole(i)"
                >
                  <i class="fas fa-search-plus"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="collapse collapse-horizontal" id="compRequirements">
        <div class="card card-body requirements">
          <div class="d-flex w-100 justify-content-between">
            <h5>{{ activeJobRoleTitle }}</h5>
            <button
              data-bs-toggle="collapse"
              data-bs-target="#compRequirements"
              aria-expanded="true"
              aria-controls="compRequirements"
              type="button"
              class="action-btn"
              (click)="requirementsShowing = false"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
          <table class="table mt-3">
            <thead>
              <tr>
                <th scope="col" class="w-60">Competency Subcategory/ Skill</th>
                <th scope="col" class="w-20 text-center">Mandatory</th>
                <th scope="col" class="w-20">Level Required</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let req of activeJobRoleRequirements">
                <td>{{ req.disciplineName }} / {{ req.skillName }}</td>
                <td>
                  <div class="text-center">
                    <i
                      class="fas fa-xl text-center"
                      [ngClass]="
                        req.mandatory === true
                          ? 'fa-check text-success'
                          : 'fa-times text-danger'
                      "
                    ></i>
                  </div>
                </td>
                <td>{{ req.claimLevelName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #jobRolesModal let-modal>
  <div class="modal-content">
    <div class="modal-header bg-dark text-light">
      <h5 class="modal-title">Add Project Roles</h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        (click)="modal.close()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <ug-list-status
        [isLoading]="jrLoading"
        [listLength]="listLength"
      ></ug-list-status>
      <div *ngIf="!jrLoading">
        <div [formGroup]="parentFormGroup.get('projectRoles.roleModalFilters')">
          <div class="d-flex align-items-end">
            <div class="dropdownWidth">
              <ug-dropdown-select
                [ngbTypeahead]="jobRoleSearch"
                [resultFormatter]="jobRoleFormatter"
                [inputFormatter]="jobRoleFormatter"
                [inlineGroup]="false"
                formControlName="jobRole"
              >
                Search for a Job Role
              </ug-dropdown-select>
            </div>
            <div class="clear-filter-btn ms-3">
              <button class="btn esqepBtn" (click)="clearFilters()">
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <div class="fixed-table-height mt-3">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" style="width: 10%">
                  <div class="d-flex">
                    <input
                      type="checkbox"
                      [(ngModel)]="selectAllFilteredJobs"
                      (change)="checkUncheckAllFilteredJobs()"
                    />
                    <label class="ms-1">Select all</label>
                  </div>
                </th>
                <th scope="col" style="width: 30%">Job Role</th>
                <th scope="col" style="width: 60%">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let role of filteredJobRoleList; let i = index">
                <td>
                  <input
                    type="checkbox"
                    [(ngModel)]="filteredJobRoleList[i].isSelected"
                    (change)="isAllFilteredJobsSelected()"
                  />
                </td>
                <td>{{ role.name }}</td>
                <td>{{ role.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="addSelectedJobs()"
          class="btn btn-outline-success"
        >
          Add selected roles
        </button>
        <button
          type="submit"
          class="btn btn-outline-dark"
          (click)="modal.close('')"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
