import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ug-project-review',
  templateUrl: './project-review.component.html',
  styleUrls: ['./project-review.component.scss'],
})
export class ProjectReviewComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
