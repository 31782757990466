import { Component, Input } from '@angular/core';

@Component({
  selector: 'ug-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {
  @Input() progressTitle = '';
  @Input() progressTotal = 0;
  @Input() progressSuccess = 0;
  @Input() progressFailed = 0;

  progressStyle(count: number, total: number): any {
    if (total === 0) {
      return { width: `0%` };
    }
    return { width: `${(count * 100) / total}%` };
  }
}
