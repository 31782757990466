<button
  type="button"
  class="btn btn-link"
  placement="top"
  triggers="hover"
  [ngbTooltip]="personSkillMapValue.state"
  (click)="personSkillMapValue.required && modifySkill()"
  [class.default-cursor]="!personSkillMapValue.required"
  closeDelay="500"
  openDelay="500"
>
  <span class="fa-xl" [ngClass]="personSkillMapValue.stateSymbolClass"></span>
</button>
