import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TrainingSubjectCategories } from '../../dashboard/training-subject-categories/training-subject-categories';
import { ConfigSetting } from '../config-setting';
import { OrgUnit } from '../models/org-unit';

export interface ExtOrgUnitPerson {
  personId: number;
  personName: string;
  position: string;
  station: string;
  supervisor: string;
  userId: string;
  username: string;
  watch: string;
}

export interface OrgUnitTypeForId {
  description: string;
  externalId: number;
  id: number;
  isRequired: boolean;
  name: string;
  source: string;
}

export interface OrgUnitLookup {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

export interface OrganisationUnit {
  id: number;
  parentId: number;
  orgUnitTypeId: number;
  externalId: number;
  externalParentId: number;
  name: string;
  externalExId: string;
  description: string;
  isActive: boolean;
}

export interface OrgUnitSearchParameters {
  isViewUserData?: boolean; //this takes 1st priority with the following 2 booleans
  allowTrainingCalendarAdmin?: boolean;
  allowAssessor?: boolean;
  orgUnitTypeId?: number; //this takes 2nd priority when not null
  source?: string;
  externalTypeId?: string; //when a number, the csod type e.g. 2 = Dimension, or the name e.g. Watch
  search?: string; //can always be set to filter the results by name
  maxCount?: number;
}

const apiServerUri = environment.apiUrl;
const dotNetApiServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class OrgUnitService {
  private http = inject(HttpClient);


  getOrgUnits(): Observable<Array<OrgUnit>> {
    return this.http.get(`${apiServerUri}/org-units/`).pipe(
      map((resp) => {
        return resp['orgUnit'];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnit(orgUnitId: number): Observable<OrgUnit> {
    return this.http.get(`${apiServerUri}/org-unit/${orgUnitId}`).pipe(
      map((resp) => {
        return resp['orgUnit'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getExtOrgUnitPeople(
    extSource: string,
    extId: string,
    extOuType: string,
  ): Observable<Array<ExtOrgUnitPerson>> {
    let params: HttpParams = new HttpParams();
    if (extId) {
      params = params.append('ext-ou-id', `${extId}`);
    }
    if (extSource) {
      params = params.append('ext-source', extSource);
    }

    return this.http
      .get(`${apiServerUri}/org-units/ext-type/${extOuType}/people`, {
        params: params,
      })
      .pipe(
        map((resp) => {
          return resp['externalOrgUnitPeople'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getExtOrgUnitsByType(extOuType: string): Observable<Array<OrgUnit>> {
    let params: HttpParams = new HttpParams();
    if (extOuType) {
      params = params.append('ext-ou-type', `${extOuType}`);
    }

    return this.http
      .get(`${apiServerUri}/org-units/external`, { params: params })
      .pipe(
        map((resp) => {
          return resp['orgUnits'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getConfigSettingsByKey(configKey: string): Observable<Array<ConfigSetting>> {
    let params: HttpParams = new HttpParams();
    if (configKey) {
      params = params.append('type', configKey);
    }

    return this.http
      .get(`${apiServerUri}/org-units/config-settings`, { params: params })
      .pipe(
        map((resp) => {
          return resp['configSettings'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  addConfigSetting(configSetting: ConfigSetting): Observable<ConfigSetting> {
    return this.http
      .post(`${apiServerUri}/org-units/config-settings`, {
        configSettings: configSetting,
      })
      .pipe(
        map((resp) => resp['configSetting']),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  deleteConfigSetting(configSettingId: number): Observable<any> {
    return this.http
      .delete(`${apiServerUri}/org-units/config-settings/${configSettingId}`)
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  sortTabs(
    tabArray: String[],
    tabData: Array<TrainingSubjectCategories>,
  ): Array<TrainingSubjectCategories> {
    return tabData.sort((a, b) => {
      const aIdx = tabArray.indexOf(a.title);
      const bIdx = tabArray.indexOf(b.title);
      console.log(`${a.title} index = ${aIdx}`);
      console.log(`${b.title} index = ${bIdx}`);
      if (aIdx === -1) {
        return 1;
      }
      if (bIdx === -1) {
        return -1;
      }
      return aIdx > bIdx ? 1 : -1;
    });
  }

  getLookups(entity: string, parentKey?: string): Observable<OrgUnitLookup[]> {
    let url = `${dotNetApiServerUri}/lookups/${entity}`;
    if (parentKey) {
      url = `${url}/${parentKey}`;
    }
    return this.http.get<OrgUnitLookup[]>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnitsByTypeId(orgUnitTypeId: string): Observable<OrganisationUnit[]> {
    const url = `${dotNetApiServerUri}/orgUnits/${orgUnitTypeId}`;
    return this.http.get<OrganisationUnit[]>(url).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnitTypeById(typeId: number | string): Observable<OrgUnitTypeForId> {
    const url = `${dotNetApiServerUri}/orgUnitTypes/${typeId}`;
    return this.http.get<OrgUnitTypeForId>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnitsForViewing(): Observable<OrganisationUnit[]> {
    const url = `${dotNetApiServerUri}/orgUnits/forViewing`;
    return this.http.get<OrganisationUnit[]>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnitTypesForViewing() {
    const url = `${dotNetApiServerUri}/orgUnitTypes/forViewing`;
    return this.http.get<any>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getOrgUnitLookups(
    orgUnitLookup: OrgUnitSearchParameters,
  ): Observable<OrgUnitLookup[]> {
    const url = `${dotNetApiServerUri}/orgUnits/lookups`;
    return this.http.post<OrgUnitLookup[]>(url, orgUnitLookup).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }
}
