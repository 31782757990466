import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FilterBase } from './filter-base';

@Injectable({
  providedIn: 'root',
})
export class FilterControlService {
  toFormGroup(filters: FilterBase<string>[]) {
    const group: any = {};

    filters?.forEach((filter) => {
      group[filter.key] = filter.required
        ? new FormControl(
            { value: filter.value || null, disabled: filter.disabled },
            Validators.required,
          )
        : new FormControl({
            value: filter.value || null,
            disabled: filter.disabled,
          });
    });
    return new FormGroup(group);
  }
}
