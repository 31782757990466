import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  JobRoleOrPersonOptions,
  JobRoleService,
  PersonJobRoleLookup,
} from '../../job-role/services/job-role.service';
import { OrgUnitService } from '../../org-unit/services/org-unit.service';
import { PersonService, PersonSubordinate } from '../services/person.service';

import { OrgUnit } from '../../org-unit/models/org-unit';
import { Person } from '../models/person';

@Component({
  selector: 'ug-assignment-detail',
  templateUrl: './assignment-detail.component.html',
  styleUrls: [],
})
export class AssignmentDetailComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private orgUnitService = inject(OrgUnitService);
  private personService = inject(PersonService);
  private jobRoleService = inject(JobRoleService);

  subscriptions = new Subscription();

  @Input() cardTitle = 'My Assignment';
  // @Input() personId: number;
  @Input() person: Person;

  orgUnit: OrgUnit;
  supervisor: Person;
  personJobRoles: PersonJobRoleLookup[];
  personDirectReports: PersonSubordinate[];
  jobRoleOrPersonOptions = JobRoleOrPersonOptions;

  orgUnitLoading = true;
  jobRolesLoading = true;
  personDirectReportsLoading = true;

  drPersonId: number = null;
  drJobRoleId: number = null;
  drDisplayName = 'Not selected';

  exceptionData = {
    ORG_UNIT: {
      level: AlertLevels.ERROR,
      code: 'ASD-001',
      message: 'Error retrieving org unit detail',
    } as AlertData,
    SUPERVISOR: {
      level: AlertLevels.ERROR,
      code: 'ASD-002',
      message: 'Error retrieving supervisor detail',
    } as AlertData,
    PERSON_JOB_ROLES: {
      level: AlertLevels.ERROR,
      code: 'ASD-003',
      message: 'Error retrieving job roles',
    } as AlertData,
    PERSON_DIRECT_REPORTS: {
      level: AlertLevels.ERROR,
      code: 'ASD-004',
      message: 'Error retrieving direct reports',
    } as AlertData,
  };
  roleProfile: any = [];
  private getJobRoleVsPerson: Subscription;
  isPlotLoading = false;
  selectedJobRole: string;

  private static enhanceJRPObject(enhancedObject, unassigned) {
    enhancedObject.unassigned = unassigned;
    enhancedObject.summary.forEach((s) => {
      s.maxLevelWeighted =
        s.personLevel.score > s.roleLevel.score
          ? s.personLevel.score * s.weighting
          : s.roleLevel.score * s.weighting;

      // person level
      s.personLevel.weightedScore = s.personLevel.score * s.weighting;
      s.personLevel.weightedScorePercentage =
        (s.personLevel.weightedScore / s.maxLevelWeighted) * 100;
      // person level
      s.roleLevel.weightedScore = s.roleLevel.score * s.weighting;
      s.roleLevel.weightedScorePercentage =
        (s.roleLevel.weightedScore / s.maxLevelWeighted) * 100;
    });
    return enhancedObject;
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  get isLoading() {
    return this.orgUnitLoading || this.jobRolesLoading;
  }

  ngOnChanges() {
    if (!this.person) {
      return;
    }
    if (this.person.organisationUnitId) {
      this.subscriptions.add(
        this.orgUnitService
          .getOrgUnit(this.person.organisationUnitId)
          .subscribe(
            (orgUnit: OrgUnit) => {
              this.orgUnit = orgUnit;
              this.orgUnitLoading = false;
            },
            (err) => {
              this.alertService.createAlert2(this.exceptionData.ORG_UNIT, err);
              this.orgUnitLoading = false;
            },
          ),
      );
    }

    if (this.person.id) {
      this.subscriptions.add(
        this.jobRoleService.getJobRolesByPersonId(this.person.id).subscribe(
          (pjr) => {
            this.personJobRoles = pjr;
            this.jobRolesLoading = false;
          },
          (err) => {
            this.alertService.createAlert2(
              this.exceptionData.PERSON_JOB_ROLES,
              err,
            );
            this.jobRolesLoading = false;
          },
        ),
      );
      this.subscriptions.add(
        this.personService.getPersonSubordinates().subscribe(
          (pdr) => {
            this.personDirectReports = pdr;
            this.personDirectReportsLoading = false;
          },
          (err) => {
            this.alertService.createAlert2(
              this.exceptionData.PERSON_DIRECT_REPORTS,
              err,
            );
            this.personDirectReportsLoading = false;
          },
        ),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.getJobRoleVsPerson) {
      this.getJobRoleVsPerson.unsubscribe();
    }
  }

  onDirectReportClick(
    personId: number,
    jobRoleId: number,
    displayName: string,
    jobRoleName: string,
  ) {
    this.drPersonId = personId;
    this.drJobRoleId = jobRoleId;
    this.drDisplayName = displayName;
    this.isPlotLoading = true;
    this.selectedJobRole = jobRoleName;
    this.getJobRoleVsPerson = this.jobRoleService
      .getJobRoleAnalysisByJobRoleIdAndPersonId(jobRoleId, personId)
      .subscribe({
        next: (jrp) => {
          const personJobRoles = jrp;
          personJobRoles.forEach((pjr) => {
            pjr.isAdditional = true;
            pjr.people.forEach((p) => {
              p.skills.forEach((s) => {
                const skillWeightedInfo = this.getWeightedScore(s);
                s.personSkillWeightedScore =
                  skillWeightedInfo.personWeightedScore;
                s.personSkillWeightedScorePercentage =
                  skillWeightedInfo.personWeightedScorePercentage;
                s.roleSkillWeightedScore = skillWeightedInfo.roleWeightedScore;
                s.roleSkillWeightedScorePercentage =
                  skillWeightedInfo.roleWeightedScorePercentage;
                s.maxLevelWeighted = skillWeightedInfo.maxLevelWeighted;
              });
            });
          });
          this.roleProfile = personJobRoles;
          this.isPlotLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRP-003',
              message: 'Unable to retrieve job role vs person data',
            },
            err,
          );
          this.isPlotLoading = false;
        },
      });

    // this.getJobRoleVsPerson = this.jobRoleService
    //   .getJobRoleVsPerson(jobRoleId, personId)
    //   .subscribe((result) => {
    //     const enhancedObject = AssignmentDetailComponent.enhanceJRPObject(
    //       result,
    //       true
    //     );
    //     this.roleProfile = enhancedObject;
    //     this.isPlotLoading = false;
    //   });
  }

  closeModal(): void {
    if (this.getJobRoleVsPerson) {
      this.getJobRoleVsPerson.unsubscribe();
    }
  }

  private getWeightedScore(skill: any) {
    const maxLevelWeighted =
      skill.claimLevelScore > skill.requirementLevelScore
        ? skill.claimLevelScore * skill.requirementWeighting
        : skill.requirementLevelScore * skill.requirementWeighting;

    // person level
    const personWeightedScore =
      skill.claimLevelScore * skill.requirementWeighting;
    const personWeightedScorePercentage =
      (personWeightedScore / maxLevelWeighted) * 100;
    const roleWeightedScore =
      skill.requirementLevelScore * skill.requirementWeighting;
    const roleWeightedScorePercentage =
      (roleWeightedScore / maxLevelWeighted) * 100;

    return {
      personWeightedScore,
      personWeightedScorePercentage,
      roleWeightedScore,
      roleWeightedScorePercentage,
      maxLevelWeighted,
    };
  }
}
