import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventSession } from '../../../events/services/events.service';

@Component({
  selector: 'ug-view-session',
  templateUrl: './view-session.component.html',
  styleUrls: ['./view-session.component.scss'],
})
export class ViewSessionComponent implements OnInit {
  modal = inject(NgbActiveModal);

  @Input() currentSession!: EventSession;

  viewSessionObject = {};

  ngOnInit(): void {
    this.viewSessionObject = {
      title: this.currentSession.title,
      startDate: this.currentSession.startDate,
      endDate: this.currentSession.endDate,
      location: this.currentSession.location,
      trainers: this.currentSession.instructors
        .map((i) => i.displayName)
        .join(', '),
      attendeeCount: this.currentSession.isSessionCompleted
        ? this.currentSession.attendedCount
        : this.currentSession.attendeeCount,
      capacity: this.currentSession.maxCapacity,
    };
  }

  viewSessionObjectEntries() {
    return Object.entries(this.viewSessionObject);
  }
}
