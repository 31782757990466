<div
  *ngIf="parentFormGroup"
  class="card esqep-indicate-validity mb-3"
  [formGroup]="parentFormGroup"
>
  <div class="card-body">
    <h5 class="card-title">
      {{ esqepTitle }}
      <span [hidden]="statementTextControl.disabled" class="small float-end"
        >Characters used: ({{
          (statementTextControl.value || '').length
        }}/4000)</span
      >
    </h5>
    <div class="form-group">
      <textarea
        class="form-control"
        [ngClass]="{ 'is-invalid': statementTextControl.invalid }"
        formControlName="statementText"
        rows="10"
        [attr.id]="'evidenceTextBox' + (componentIndex || '')"
        [placeholder]="placeholderText"
        title="Statement"
      ></textarea>
      <!-- <textarea class="form-control" formControlName="statementText" rows="10" [attr.id]="'evidenceTextBox' + (componentIndex || '')" [placeholder]="placeholderText"></textarea> -->
      <div class="invalid-feedback">{{ esqepTitle }} is required</div>
    </div>
    <!-- <p class="card-text">Child Statement: {{statementTextControl.value}}</p> -->
  </div>
</div>
