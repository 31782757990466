<ng-container *transloco="let t">
  <div *ngIf="isLoading" class="text-center mt-1">
    <span class="text-white fas fa-spinner fa-spin fa-2x"></span>
  </div>
  <div class="translation-dropdown">
    <div *ngIf="!isLoading" class="person-details-section">
      <img
        *ngIf="personPhotoUrl; else photoPlaceHolder"
        [src]="personPhotoUrl || ''"
        class="rounded-circle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        alt="user photo"
      />
      <ng-template #photoPlaceHolder>
        <div
          class="text-center placeholder-icon"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="far fa-user fa-xl text-white"></span>
        </div>
      </ng-template>
      <div class="dropdown-menu">
        <div class="person-details">
          <div class="fw-bold">
            {{ person.givenName }} {{ person.familyName }}
          </div>
          <div class="text-muted person-email">{{ person.adUPN }}</div>
        </div>
        <hr class="dropdown-divider mt-0" />
        <ul class="dropdown-menu-items">
          <li>
            <a [routerLink]="['/person-details']">{{
              t('personDetails.my_details')
            }}</a>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-link logout-btn"
              (click)="logout()"
            >
              {{ t('personDetails.logout') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <!-- commented out until we need to select a language -->
      <!-- <select class="form-select" #lang (change)="changeLang(lang.value)">
        <option *ngFor="let lang of availableLangs" [value]="lang.id">
          {{ lang.label }}
        </option>
      </select> -->
    </div>
  </div>
</ng-container>
