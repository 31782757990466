<div class="modal-header bg-dark text-light">
  <h5 class="modal-title">Bulk completion</h5>
  <button
    type="button"
    class="btn-close btn-close-white"
    (click)="modal.close()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <p>
        Mark <strong>{{ trainingTitle }}</strong> complete for:
      </p>
    </div>
  </div>
  <form class="form" *ngIf="bulkSubmitForm" [formGroup]="bulkSubmitForm">
    <div class="row fw-bold">
      <div class="col-6">Name</div>
      <div class="col-4 text-center">Current State</div>
      <div class="col-2 text-center">Include?</div>
    </div>
    <div class="row">
      <div class="col-2 offset-10 text-center">
        <input
          aria-label="select all users"
          type="checkbox"
          id="selectAll"
          title="Select all users"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="selectAll"
          (ngModelChange)="selectAllChange($event)"
        />
      </div>
    </div>
    <div formArrayName="people">
      <div
        *ngFor="let c of peopleFormArray.controls; let i = index"
        class="row"
        [formGroupName]="i"
      >
        <div class="col-6" ugAnonymize>{{ c.get('personName').value }}</div>
        <div class="col-4 text-white text-center">
          <span [ngClass]="peopleArray[i].stateSymbolClass"></span>
        </div>
        <div class="col-2 form-check text-center">
          <input
            type="checkbox"
            [formControlName]="'includePerson'"
            [attr.disabled]="c.disabled ? true : null"
            [attr.id]="'includePerson' + i"
            title="Include person"
            (change)="selectAll = false"
          />
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary me-2"
    (click)="modal.close()"
  >
    Close
  </button>
  <button
    type="button"
    *ngIf="!awaitingCompleteConfirmation"
    [disabled]="disableBulkSave"
    class="btn btn-outline-success me-2"
    [ngClass]="{ disabled: disableBulkSave }"
    (click)="awaitingCompleteConfirmation = true"
  >
    Submit
  </button>
  <div class="btn-group" role="group" *ngIf="awaitingCompleteConfirmation">
    <button
      type="button"
      class="btn btn-success me-2"
      (click)="saveBulkSubmit()"
    >
      Click to confirm submission
    </button>
    <button
      type="button"
      class="btn btn-outline-danger me-2"
      (click)="awaitingCompleteConfirmation = false"
    >
      Cancel
    </button>
  </div>
</div>
