<div class="card-body">
  <div class="d-flex">
    <button
      *ngIf="skillDetails.skillSubject.id && parentSubject?.subjectLevelId < 8"
      type="button"
      class="btn esqepBtn ms-auto"
      (click)="addChildNode()"
    >
      Create Child Subject
    </button>
  </div>

  <div>
    <label for="title">Title: </label>
    <input
      #title
      type="text"
      id="title"
      name="title"
      [disabled]="disabled"
      [(ngModel)]="skillDetails.name"
      class="form-control"
    />

    <div *ngIf="parentSubject" class="my-1">
      Parent: {{ parentSubject?.name }}
    </div>

    <div class="my-3">
      <div>Assigned Dimensions:</div>

      <div class="dimension-checkboxes">
        <input
          id="application"
          [value]="1"
          name="application"
          type="checkbox"
          class="btn-check"
          [(ngModel)]="skillDetails.application"
          [checked]="skillDetails.application"
          [disabled]="skillDetails.skillSubject.subjectLevelId > 1"
        />
        <label for="application" class="btn">{{
          skillDimensions[0].name
        }}</label>
        <input
          id="behaviour"
          [value]="2"
          name="behaviour"
          type="checkbox"
          class="btn-check"
          [(ngModel)]="skillDetails.behaviour"
          [checked]="skillDetails.behaviour"
          [disabled]="skillDetails.skillSubject.subjectLevelId > 1"
        />
        <label for="behaviour" class="ms-2 btn">{{
          skillDimensions[1].name
        }}</label>

        <input
          id="knowledge"
          [value]="3"
          name="knowledge"
          type="checkbox"
          class="btn-check"
          [(ngModel)]="skillDetails.knowledge"
          [checked]="skillDetails.knowledge"
          [disabled]="skillDetails.skillSubject.subjectLevelId > 1"
        />
        <label for="knowledge" class="ms-2 btn">{{
          skillDimensions[2].name
        }}</label>
        <input
          id="training"
          [value]="4"
          name="training"
          type="checkbox"
          class="btn-check"
          [disabled]="true"
          [(ngModel)]="skillDetails.training"
          [checked]="skillDetails.training"
        />
        <label for="training" class="ms-2 btn">{{
          skillDimensions[3].name
        }}</label>
      </div>
    </div>

    <div
      class="info text-danger"
      *ngIf="skillDetails.skillSubject.subjectLevelId === 1"
    >
      Assigned dimensions will be applied to children at all levels , except for
      training which must be managed externally.
      <div
        class="info text-danger"
        *ngIf="skillDetails.skillSubject.subjectLevelId > 1"
      >
        Children inherit their parent's dimensions assigned at the category
        level.
      </div>
    </div>

    <div class="levels" *ngIf="subjectLevelName$ | async as name">
      Level:
      <ng-container *ngIf="!showName; else showedit">{{ name }}</ng-container>
      <ng-template #showedit>
        <div>
          <input
            type="text"
            id="name"
            name="name"
            [(ngModel)]="skillDetails.levelName"
            class="form-control form-control-sm"
          />
        </div>
      </ng-template>
      <div
        class="edit-levels"
        *ngIf="
          skillDetails.skillSubject.subjectLevelId >= 3 &&
          skillDetails.skillSubject.subjectLevelId <= 8
        "
      >
        <button
          type="button"
          (click)="edit()"
          class="btn btn-outline-dark btn-sm esqepBtn"
        >
          {{ showName ? 'Done' : 'Edit' }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="skillDetails.skillSubject.id">
      <div class="mb-2">Assigned Competencies/Training: {{ skillCount }}</div>
      <div class="mb-2">
        Imported:
        <span
          [ngClass]="
            skillDetails.skillSubject.source
              ? 'fas fa fa-check-circle text-success text-center'
              : 'fas fa fa-times-circle text-danger text-center'
          "
        ></span>
      </div>
      <div class="mb-2">Imported subjects: {{ importedCount }}</div>
      <div class="mb-2">Internal subjects: {{ internalCount }}</div>
    </ng-container>
    <div *ngIf="!canDelete" class="info text-danger my-2">
      You are unable to delete subjects that have been imported, have child
      subjects or have competencies/training assigned to them.
    </div>
    <div class="action-buttons my-2">
      <div class="left-button">
        <button
          type="button"
          class="btn btn-danger btn-md"
          title="Delete"
          *ngIf="skillDetails.skillSubject.id && canDelete && !confirmingDelete"
          (click)="delete()"
        >
          Delete
        </button>

        <button
          type="button"
          *ngIf="confirmingDelete"
          class="btn btn-outline-secondary btn-md"
          title="Cancel"
          (click)="canceldelete()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-danger btn-md"
          title="Confirm Delete"
          *ngIf="confirmingDelete"
          (click)="confirmDelete()"
        >
          Confirm Delete
        </button>
      </div>
      <div class="right-button">
        <button
          type="button"
          (click)="submitForm()"
          class="btn btn-outline-dark btn-success btn-md esqepBtn"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
