import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { IncompleteExpressClassComponent } from './incomplete-express-class/incomplete-express-class.component';
import { MatrixDetailCellComponent } from './matrix-detail-cell/matrix-detail-cell.component';
import { PerfGridCardComponent } from './perf-grid-card/perf-grid-card.component';
import { PerfGridComponent } from './perf-grid/perf-grid.component';
import { PerfMgmtComponent } from './perf-mgmt/perf-mgmt.component';
import { PerfSummaryComponent } from './perf-summary/perf-summary.component';
import { ProgressRowCardComponent } from './progress-row-card/progress-row-card.component';
import { DashboardService } from './services/dashboard.service';

import { CompetenciesModule } from '../competencies/competencies.module';
import { EchartsModule } from '../echarts/echarts.module';
import { SharedModule } from '../shared/shared.module';
import { AnalyticsChartComponent } from './competency-training-analytics/analytics-chart/analytics-chart.component';
import { CompetencyTrainingAnalyticsComponent } from './competency-training-analytics/competency-training-analytics.component';
import { SkillFiltersComponent } from './competency-training-analytics/skill-filters/skill-filters.component';
import { DefaultAmberComponent } from './matrix-settings/default-amber/default-amber.component';
import { MatrixSettingsAuditComponent } from './matrix-settings/matrix-settings-audit/matrix-settings-audit.component';
import { MatrixSettingsComponent } from './matrix-settings/matrix-settings.component';
import { TrainingAmberComponent } from './matrix-settings/training-amber/training-amber.component';
import { MatrixSummaryDonutChartComponent } from './matrix-summary/matrix-summary-donut-chart/matrix-summary-donut-chart.component';
import { MatrixSummaryComponent } from './matrix-summary/matrix-summary.component';
import { ClaimExpiryDatePipe } from './matrix/claim-expiry-date.pipe';
import { KeyModalComponent } from './matrix/key-modal/key-modal.component';
import { MatrixFiltersComponent } from './matrix/matrix-filters/matrix-filters.component';
import { MatrixPersonDetailComponent } from './matrix/matrix-person-detail/matrix-person-detail.component';
import { MatrixTableComponent } from './matrix/matrix-table/matrix-table.component';
import { StateIconComponent } from './matrix/matrix-table/state-icon/state-icon.component';
import { MatrixComponent } from './matrix/matrix.component';
import { TrainingBulkCompletionActionComponent } from './matrix/training-bulk-completion-action/training-bulk-completion-action.component';
import { UnassignedUsersControlComponent } from './matrix/unassigned-users-control/unassigned-users-control.component';
import { UserTaskStatusComponent } from './matrix/user-task-status/user-task-status.component';
import { TrainingMatrixSummaryComponent } from './training-matrix-summary/training-matrix-summary.component';

@NgModule({
  imports: [
    CommonModule,
    ControlsModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbAccordionModule,
    NgbNavModule,
    NgSelectModule,
    NgbPaginationModule,
    SharedModule,
    CompetenciesModule,
    FullCalendarModule,
    NgbToastModule,
    EchartsModule,
    NgbTooltipModule,
  ],
  declarations: [
    DashboardComponent,
    PerfMgmtComponent,
    PerfGridComponent,
    PerfSummaryComponent,
    PerfGridCardComponent,
    MatrixDetailCellComponent,
    IncompleteExpressClassComponent,
    ProgressRowCardComponent,
    MatrixComponent,
    MatrixPersonDetailComponent,
    UserTaskStatusComponent,
    TrainingBulkCompletionActionComponent,
    KeyModalComponent,
    ClaimExpiryDatePipe,
    MatrixFiltersComponent,
    MatrixTableComponent,
    MatrixSummaryComponent,
    MatrixSummaryDonutChartComponent,
    CompetencyTrainingAnalyticsComponent,
    SkillFiltersComponent,
    AnalyticsChartComponent,
    UnassignedUsersControlComponent,
    MatrixSettingsComponent,
    DefaultAmberComponent,
    TrainingAmberComponent,
    MatrixSettingsAuditComponent,
    StateIconComponent,
    TrainingMatrixSummaryComponent,
  ],
  exports: [
    DashboardComponent,
    PerfMgmtComponent,
    PerfGridComponent,
    PerfSummaryComponent,
    ProgressRowCardComponent,
  ],
  providers: [DashboardService],
})
export class DashboardModule {}
