<div class="card h-100 shadow">
  <div class="card-body">
    <h5 class="card-title">My Activity Summary</h5>
    <div class="row">
      <div
        *ngIf="
          fetchingData || activitySummary.length === 0;
          else summaryStatusDisplay
        "
        class="col-12 col-md-12"
      >
        <ug-list-status
          [listLength]="activitySummary.length"
          [emptyListMessage]="'No Assessment Request Data Available'"
          [isLoading]="fetchingData"
        ></ug-list-status>
      </div>
      <ng-template #summaryStatusDisplay>
        <div
          *ngFor="let pieChartData of activitySummary; let i = index"
          class="col-sm-12"
          [ngClass]="{
            'col-md-6': activitySummary.length === 2,
            col: activitySummary.length === 1,
          }"
        >
          <div class="card">
            <div class="card-body">
              <ug-chart [chartConfig]="pieChartData"></ug-chart>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
