<div class="card mt-3 shadow">
  <div class="card-body">
    <!-- <div class="card-title">
            <span class="h5">{{title}}</span>
        </div> -->
    <div *ngIf="!isLoading">
      <div class="row h5 mb-3">
        <div class="col-3">Training Title</div>
        <div class="col-1">Proxy Id</div>
        <div class="col-2">Completion Date</div>
        <div class="col-4">People</div>
        <div class="col-2">Actions</div>
      </div>
      <div *ngFor="let xc of summaryList; let i = index">
        <div class="row mb-2 border-top">
          <div class="col-3">{{ xc.skillName }}</div>
          <div class="col-1">{{ xc.proxyId }}</div>
          <div class="col-2">{{ xc.completionDate | date: 'medium' }}</div>
          <div class="col-4">{{ formatPeopleList(xc.people) }}</div>
          <div class="col-2">
            <div *ngIf="!xc.processed" class="btn-group btn-group-sm">
              <button
                class="btn btn-outline-primary mt-2"
                *ngIf="!awaitResubmitConfirmation && !awaitDiscardConfirmation"
                (click)="onResubmitClick(i)"
              >
                Resubmit
              </button>
              <button
                class="btn btn-danger mt-2"
                *ngIf="awaitResubmitConfirmation && ecResubmitIdx === i"
                (click)="resubmitExpressClass(xc.expressClassId)"
              >
                Confirm
              </button>
              <button
                class="btn btn-outline-success mt-2"
                *ngIf="awaitResubmitConfirmation && ecResubmitIdx === i"
                (click)="onCancelResubmitClick()"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-danger mt-2"
                *ngIf="!awaitDiscardConfirmation && !awaitResubmitConfirmation"
                (click)="onDiscardClick(i)"
              >
                Discard
              </button>
              <button
                class="btn btn-danger mt-2"
                *ngIf="awaitDiscardConfirmation && ecDiscardIdx === i"
                (click)="updateExpressClass(xc.expressClassId)"
              >
                Confirm
              </button>
              <button
                class="btn btn-outline-success mt-2"
                *ngIf="awaitDiscardConfirmation && ecDiscardIdx === i"
                (click)="onCancelDiscardClick()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ug-list-status
      [listLength]="summaryList?.length"
      [emptyListMessage]="'No Incomplete Express Classes Found'"
      [isLoading]="isLoading"
    ></ug-list-status>
  </div>
</div>
