<div [formGroup]="parentFormGroup">
  <div *ngIf="!allowEdit" class="fst-italic fw-bold">
    <p class="fst-italic fw-bold">
      You do not have permission to edit Role Assignment
    </p>
  </div>
  <div *ngIf="allowEdit" class="mb-2">
    <button
      (click)="openAddRoleMembersModal()"
      class="btn esqepBtn me-3"
      title="Add role members"
      [disabled]="jobRoleFilter || parentFormGroup.get('filterId').value"
    >
      Add People
    </button>
    <button
      (click)="openAddOuModal()"
      class="btn esqepBtn me-3"
      title="Add ou to role"
      [disabled]="jobRoleFilter || parentFormGroup.get('filterId').value"
    >
      Add Organisation Unit
    </button>
    <button
      (click)="openFileUploadModal()"
      class="btn esqepBtn me-3"
      title="Add role members by upload"
      [disabled]="
        jobRoleFilter ||
        parentFormGroup.get('filterId').value ||
        disableUploadButton
      "
    >
      Upload File
    </button>
    <span class="me-3">OR</span>
    <button
      (click)="openAddGroupModal()"
      class="btn esqepBtn me-3"
      title="Add role members by group filter"
      [disabled]="
        (jobRolePeople?.length > 0 ||
          selectedPeople.length ||
          jobRoleOrgUnits?.length > 0 ||
          selectedOrgUnits?.length) &&
        !parentFormGroup.get('filterId').value
      "
    >
      {{ !parentFormGroup.get('filterId').value ? 'Add Group' : 'Edit Group' }}
    </button>
    <div class="fst-italic mt-2">
      Click one of the above buttons to add people or organisation units to the
      role.
    </div>
  </div>
  <div
    class="alert alert-info mt-2"
    [hidden]="
      jobRolePeople?.length > 0 ||
      selectedPeople.length > 0 ||
      jobRoleOrgUnits?.length > 0 ||
      selectedOrgUnits?.length > 0 ||
      parentFormGroup.get('filterId').value
    "
  >
    <i class="fas fa-xl fa-info-circle me-2"></i> No people, organisation units,
    or group filter have been added to this role
  </div>
  <div
    class="row"
    [hidden]="
      (!this.jobRolePeople || this.jobRolePeople?.length === 0) &&
      selectedPeople?.length === 0 &&
      (!this.jobRoleOrgUnits || jobRoleOrgUnits?.length === 0) &&
      selectedOrgUnits?.length === 0 &&
      !parentFormGroup.get('filterId').value
    "
  >
    <div class="col-sm-12 col-md-6">
      <ug-role-people
        [discardChangesSubj]="discardChangesSubj"
        [allowEdit]="allowEdit"
        [jobRoleId]="jobRoleId"
        (discardPerson)="discardPerson($event)"
        [jobRolePeople]="jobRolePeople"
        [selectedPeople]="selectedPeople"
        [filterPeople]="filterPeople"
        [filterIdControl]="parentFormGroup.get('filterId')"
        (removeRestorePerson)="removeRestorePerson($event)"
        [jobRoleFilter]="jobRoleFilter"
        [tablePageSize]="tableFilterData.pageSize"
        [tablePageIndex]="tableFilterData.page"
        [peopleCount]="totalJobRolePeople"
        [tableLoading]="tableLoading"
        (pageSizeChange)="updateData(1, $event)"
        (pageIndexChange)="updateData($event)"
        (sortChange)="updateData(1, null, $event)"
      >
      </ug-role-people>
    </div>
    <div class="col-sm-12 col-md-6">
      <div *ngIf="!jobRoleFilter && !parentFormGroup.get('filterId').value">
        <ug-role-org-units
          [discardChangesSubj]="discardChangesSubj"
          [saveChangesSubj]="saveChangesSubj"
          [allowEdit]="allowEdit"
          [jobRoleId]="jobRoleId"
          [jobRoleOrgUnits]="jobRoleOrgUnits"
          [selectedOrgUnits]="selectedOrgUnits"
          (discardOu)="discardOu($event)"
          (removeRestoreOu)="removeRestoreOu($event)"
        ></ug-role-org-units>
      </div>
      <div *ngIf="jobRoleFilter || parentFormGroup.get('filterId').value">
        <ug-role-group-filter
          [discardChangesSubj]="discardChangesSubj"
          [allowEdit]="allowEdit"
          [parentFormGroup]="parentFormGroup"
          [selectedFilter]="selectedFilter"
          [jobRoleFilter]="jobRoleFilter"
          (discardChangesFilter)="discardChangesFilter($event)"
        ></ug-role-group-filter>
      </div>
    </div>
  </div>
  <ng-container *ngIf="parentFormGroup.get('id').value">
    <div class="mt-2">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary ms-1"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseAudit"
      >
        {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
      </button>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ug-audit-table
        [auditEndpoint]="assignmentAudit"
        [id]="parentFormGroup.get('id').value"
        contextKey="personName"
        contextTitle="Person"
        auditTitle="Person"
      ></ug-audit-table>

      <ug-audit-table
        [auditEndpoint]="orgUnitAudit"
        [id]="parentFormGroup.get('id').value"
        contextKey="orgUnitName"
        contextTitle="Organisation Unit"
        auditTitle="Organisation Unit"
      ></ug-audit-table>
    </div>
  </ng-container>
</div>
