<div class="list-group list-group-flush">
  <div
    *ngFor="let error of getErrorList(sourceFormGroup)"
    class="list-group-item bg-danger text-light p-1"
  >
    <p class="mx-2 my-1">
      {{ error.description }}
    </p>
  </div>
</div>
