<form class="form" [formGroup]="filterGroup">
  <div
    class="input-group"
    [ngClass]="{ 'border border-danger rounded': showMinCharMessage }"
  >
    <span class="input-group-text" *ngIf="showSearchIcon">
      <span class="fas fa-search text-center pt-1"></span>
    </span>
    <input
      formControlName="filterControl"
      id="textFilterBox"
      type="text"
      class="form-control"
      [attr.placeholder]="placeholderText"
      title="Reset filter"
    />
    <button
      type="button"
      class="btn btn-outline-dark esqepBtn"
      (click)="onResetClick()"
    >
      Reset
    </button>
  </div>
  <p *ngIf="showMinCharMessage" class="text-danger fst-italic">
    Minimum search criteria must be 3 characters.
  </p>
</form>
