<esqep-warn-unsaved
  [showDialog]="showUnsavedExitWarning"
  [exitWithoutSaving$]="warningModalResponse"
  (exit)="resetUnsavedExitWarning()"
  (goBack)="resetUnsavedExitWarning()"
></esqep-warn-unsaved>
<div class="card shadow overflow-visible mb-3">
  <div class="card-body">
    <h5 class="card-title text-end">
      <!-- User Security Roles -->
      <span *ngIf="psrForm.dirty" class="badge bg-warning mx-2"
        >Unsaved changes</span
      >
    </h5>
    <div class="bg-light border p-3" id="filterBlock">
      <div class="row">
        <div class="col-12 col-md-6">
          <ug-text-filter-control
            [ugDisabled]="!filtersEnabled"
            [filterText]="filterText"
            [placeholderText]="'Begin typing a users name to filter results'"
            (filterTextChange)="onFilterTextChange($event)"
            [showSearchIcon]="true"
          ></ug-text-filter-control>
        </div>
        <div class="col-12 col-md-6">
          <div class="form" [formGroup]="filtersForm">
            <div class="row">
              <label
                class="col-12 col-md-4 col-form-label text-md-end"
                for="securityRoleFilterControl"
                >Security Role</label
              >
              <div class="col-12 col-md-7">
                <select
                  class="form-select me-2"
                  id="securityRoleFilterControl"
                  formControlName="securityRoleId"
                >
                  <option [ngValue]="null">All security roles</option>
                  <option
                    *ngFor="let role of userSecurityRoles"
                    [ngValue]="role.id"
                  >
                    {{ role.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="filteredPeople.length"
      [emptyListMessage]="'No matching results found'"
    ></ug-list-status>

    <div
      *ngIf="!isLoading && filteredPeople.length > 0"
      class="table-responsive"
    >
      <table
        class="table table-sm table-striped overflow-visible mt-3"
        [formGroup]="psrForm"
      >
        <thead>
          <tr>
            <th scope="col" class="w-20">Name</th>
            <th scope="col" class="w-20">UPN</th>
            <th scope="col" class="w-20">Security Role</th>
            <th scope="col" class="w-25">Restriction By {{ ouType }}</th>
            <th scope="col" class="w-15"></th>
          </tr>
        </thead>
        <tbody formArrayName="personSecurityRoles">
          <tr
            *ngFor="
              let person of personControlsArray;
              let i = index;
              trackBy: trackByFn
            "
            [hidden]="hidePersonSecurityRole(i)"
            [formGroupName]="i"
          >
            <td class="align-middle">{{ person.personName }}</td>
            <td class="align-middle">{{ person.personUPN }}</td>
            <td class="align-middle">
              <select
                class="form-select esqep-indicate-validity"
                id="securityRoleSelect{{ i }}"
                formControlName="personSecurityRoleId"
                (change)="onSecurityRoleChange(i)"
                title="Security role"
              >
                <option value="null" selected disabled>
                  Select a Security Role...
                </option>
                <option
                  *ngFor="let usr of userSecurityRoles; let j = index"
                  [ngValue]="usr.id"
                  [disabled]="usr.securityLevel >= personSecurityLevel"
                >
                  {{ usr.name }}
                </option>
              </select>
            </td>
            <td class="align-middle">
              <ng-container *ngIf="person.canRestrictByOu">
                <ng-select
                  [items]="ouList"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [hideSelected]="true"
                  bindLabel="name"
                  bindValue="id"
                  [placeholder]="'Select ' + ouType + '...'"
                  formControlName="restrictedOus"
                  appendTo=".card"
                  class="tableDropdown"
                >
                </ng-select>
              </ng-container>
            </td>
            <td class="align-middle">
              <span
                *ngIf="showStatusIcon(i)"
                [ngClass]="statusIconClass(i)"
                data-toggle="tooltip"
                data-placement="bottom"
                [title]="statusIconTooltip(i)"
              ></span>
              <div class="btn-group btn-group-sm float-end">
                <button
                  *ngIf="getPersonFormGroup(i).dirty"
                  class="btn btn-outline-secondary me-2 btn-sm"
                  (click)="onDiscardPersonSecurityRole(i)"
                >
                  Discard
                </button>
                <button
                  *ngIf="getPersonFormGroup(i).dirty"
                  class="btn btn-outline-success me-2 btn-sm"
                  (click)="onUpdatePersonSecurityRole(i)"
                  [disabled]="!getPersonFormGroup(i).valid"
                >
                  Save
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex flex-wrap">
        <ngb-pagination
          [collectionSize]="total$ | async"
          [(page)]="page"
          [pageSize]="pageSize"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
          [maxSize]="5"
          (pageChange)="onPageChange($event)"
        >
        </ngb-pagination>
        <div class="d-flex mt-2 ms-1">
          <label for="pageSize" class="me-2">Rows per page:</label>
          <select
            class="form-select-sm pageNoSize"
            name="pageSize"
            [(ngModel)]="pageSize"
            id="pageSize"
            (ngModelChange)="onPageSizeChange($event)"
          >
            <option *ngFor="let ps of pageSizeOptions" [ngValue]="ps">
              {{ ps }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<ug-person-security-access
  [userSecurityRoles]="userSecurityRoles"
></ug-person-security-access>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
