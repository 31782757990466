<div>
  <label [attr.for]="controlLabel" class="form-label">{{ controlLabel }}</label>
  <div class="ngxEditorContainer">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
    <ngx-editor
      [attr.id]="controlLabel"
      [editor]="editor"
      [formControlName]="controlName"
      [placeholder]="controlPlaceholder"
      [outputFormat]="'html'"
    >
    </ngx-editor>
  </div>
</div>
