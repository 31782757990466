import {
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subject, finalize, pairwise } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../core/services/alert.service';
import {
  JobRole,
  JobRoleRequirement,
  JobRoleService,
  JobRoleSetting,
  JobRoleSettings,
} from '../../../../job-role/services/job-role.service';
import {
  AuditData,
  AuditFilter,
} from '../../../../shared/audit-table/audit.service';
import { SkillSearch } from '../../../../skill/services/skill.service';
import { JobRoleRequirements } from '../role-management.service';

@Component({
  selector: 'ug-role-training',
  templateUrl: './role-training.component.html',
  styleUrls: ['./role-training.component.scss'],
})
export class RoleTrainingComponent implements OnInit, OnChanges {
  @ViewChild('roleTrainingModal') roleTrainingModal: ElementRef;
  @Input() parentFormGroup: FormGroup;
  @Input() allowEdit: boolean;
  @Input() jobRoleId: number;
  @Input() jobRole: JobRole;
  @Input() jobRoleTrainings: Array<JobRoleRequirements>;
  @Input() discardChangesSubj: Subject<boolean>;
  @Input() requirementAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;

  private destroyRef = inject(DestroyRef);
  private fb = inject(FormBuilder);
  private jobRoleService = inject(JobRoleService);
  private alertService: AlertService = inject(AlertService);
  private groupedSkillsByType = new Map();
  private setSelectedResultSubj: Subject<SkillSearch[]> = new Subject<
    SkillSearch[]
  >();
  private globalJobRoleSettings: Map<string, JobRoleSetting> = new Map<
    string,
    JobRoleSetting
  >();
  private initialValues: any;
  private matchingSkills: Array<SkillSearch> = [];
  private selectedRows = [];
  private childIds = [];
  protected isCollapsed = true;
  protected addingReqInProgress = false;
  protected selectedSkill!: SkillSearch | null;
  protected dupeSkill = false;
  protected selectedEventHasNoSessions = false;
  protected fgNewTraining: FormGroup;
  protected viewSettings = false;
  protected showRequirementSelectBox: any;
  protected fetchingCurriculumLOs: boolean;
  protected loadingSettings: boolean;
  protected collapsedRowMap = new Map<number, boolean>();
  protected childLOsAreDuplicated: boolean;

  get training(): FormArray {
    return this.parentFormGroup.get('training') as FormArray;
  }

  get trainingTypes(): Array<string> {
    const trainingTypes = [
      ...new Set(this.matchingSkills.map((sk) => sk.type)),
    ].sort();
    if (trainingTypes.length === 1) {
      this.fgNewTraining.get('trainingType').setValue(trainingTypes[0]);
    }
    return trainingTypes;
  }

  get trainingVersions(): Array<string> {
    const trainingVersions = [
      ...new Set(
        this.matchingSkills
          .filter(
            (ms) => ms.type === this.fgNewTraining.get('trainingType').value,
          )
          .map((sk) => sk.version),
      ),
    ].sort((a, b) => {
      const firstItem = parseFloat(a);
      const secondItem = parseFloat(b);
      let retVal: number;
      if (Number.isNaN(firstItem) && Number.isNaN(secondItem)) {
        retVal = 0;
      } else if (Number.isNaN(firstItem)) {
        retVal = 1;
      } else if (Number.isNaN(secondItem)) {
        retVal = -1;
      } else {
        retVal = firstItem - secondItem;
      }
      return retVal;
    });

    if (trainingVersions.length === 1) {
      this.fgNewTraining.get('trainingVersion').setValue(trainingVersions[0]);
    }
    return trainingVersions;
  }

  get trainingProviders(): Array<string> {
    const providers = [
      ...new Set(
        this.matchingSkills
          .filter((s) => {
            return (
              s.type === this.fgNewTraining.get('trainingType').value &&
              s.version === this.fgNewTraining.get('trainingVersion').value
            );
          })
          .map((ms) => ms.provider),
      ),
    ].sort();
    if (providers.length === 1) {
      this.fgNewTraining.get('provider').setValue(providers[0]);
    }
    return providers;
  }

  recurAndDueAfterValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value !== null && value <= 0) {
      return { invalidNumber: true };
    }
    return null;
  }

  ngOnInit(): void {
    this.fgNewTraining = this.fb.group({
      id: [null],
      claimLevelId: [0],
      dueAfterUnit: [null],
      dueAfterValue: [null, this.recurAndDueAfterValidator],
      newHireDueUnit: [null],
      newHireDueValue: [null, this.recurAndDueAfterValidator],
      newVersionDueUnit: [null],
      newVersionDueValue: [null, this.recurAndDueAfterValidator],
      keepDueDates: [false],
      trainingVersion: [''],
      mandatory: [false],
      active: [true],
      recurUnit: [null],
      recurValue: [null, this.recurAndDueAfterValidator],
      skillId: [null],
      skillName: [null],
      weighting: [1],
      provider: [null],
      owner: [null],
      trainingType: [null, Validators.required],
      externalGuid: [null],
      pendingDeletion: [false],
      children: [null],
    });

    if (this.allowEdit) {
      this.loadingSettings = true;
      this.jobRoleService
        .getJobRoleSettingsDueDates()
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          finalize(() => (this.loadingSettings = false)),
        )
        .subscribe({
          next: (jrs: JobRoleSettings) => {
            this.globalJobRoleSettings = new Map(Object.entries(jrs.dueDates));
          },
          error: (error) => {
            this.alertService.createAlert2(
              {
                level: AlertLevels.ERROR,
                code: 'JRT-002',
                message: 'Error getting job role settings due dates',
              } as AlertData,
              error,
            );
          },
        });
    }

    this.fgNewTraining.get('trainingVersion').disable();
    this.fgNewTraining.get('provider').disable();
    this.fgNewTraining.get('owner').disable();
    this.fgNewTraining.get('trainingType').disable();

    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.training.reset(this.initialValues);

        for (let i = this.training.length - 1; i >= 0; i--) {
          const control = this.training.at(i);
          if (!control.value.id) {
            this.training.removeAt(i);
          } else {
            if (!control.value.pendingDeletion && this.allowEdit) {
              control.get('recurUnit').enable();
              control.get('recurValue').enable();
              control.get('dueAfterUnit').enable();
              control.get('dueAfterValue').enable();
              control.get('newHireDueUnit').enable();
              control.get('newHireDueValue').enable();
              control.get('newVersionDueUnit').enable();
              control.get('newVersionDueValue').enable();
              control.get('mandatory').enable();
            }
          }
        }
      }
    });

    this.setSelectedResultSubj.subscribe((pl) => {
      if (pl) {
        this.setSelectedSkills(pl);
      }
    });

    this.fgNewTraining
      .get('trainingType')
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev && next && next !== prev) {
          const training = this.groupedSkillsByType.get(next);
          const sortedItemsInFirstGrouping = training.sort((a, b) =>
            a.version.localeCompare(b.version, undefined, { numeric: true }),
          );

          this.selectedSkill =
            sortedItemsInFirstGrouping[sortedItemsInFirstGrouping.length - 1];

          if (sortedItemsInFirstGrouping.length > 1) {
            this.fgNewTraining.get('trainingVersion').enable();
          } else if (this.fgNewTraining.get('trainingVersion')?.enabled) {
            this.fgNewTraining.get('trainingVersion')?.disable();
          }

          this.updateNewTrainingForm();
          this.dupeSkill = this.checkIfDuplicateSkill();
        }
      });

    this.fgNewTraining
      .get('trainingVersion')
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev && next && next !== prev) {
          this.selectedSkill = this.matchingSkills.find(
            (mci) =>
              mci.version === next &&
              mci.type === this.fgNewTraining.get('trainingType').value,
          );
          this.dupeSkill = this.checkIfDuplicateSkill();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['jobRoleTrainings'] &&
      changes['jobRoleTrainings'].currentValue !==
        changes['jobRoleTrainings'].previousValue &&
      this.jobRoleTrainings &&
      this.jobRoleTrainings.length > 0
    ) {
      this.training.clear();

      this.sortJobRoleTraining();

      this.jobRoleTrainings.forEach((r) => {
        const trainingFormGroup = this.createTrainingFormGroup(r);

        if (r.children.length > 0) {
          r.children.forEach((child) => {
            this.childIds.push(child.skillId);
            const childTrainingFormGroup = this.createTrainingFormGroup(
              r,
              child,
            );
            const children = trainingFormGroup.get('children') as FormArray;
            children.push(childTrainingFormGroup);
          });
        }

        trainingFormGroup.get('keepDueDates').reset(false);

        if (r.pendingDeletion || !this.allowEdit) {
          trainingFormGroup.disable();
        }

        this.training.push(trainingFormGroup);
        this.collapsedRowMap.set(r.skillId, false);
      });

      this.initialValues = this.training.getRawValue();
      this.childLOsAreDuplicated = this.childLOsDuplicated();
    }
  }

  sortJobRoleTraining() {
    this.jobRoleTrainings.sort((a, b) => {
      const isACurriculum = a.externalType === 'Curriculum';
      const isBCurriculum = b.externalType === 'Curriculum';

      if (isACurriculum && !isBCurriculum) {
        return 1;
      } else if (!isACurriculum && isBCurriculum) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  childLOsDuplicated() {
    return new Set(this.childIds).size !== this.childIds.length;
  }

  createTrainingFormGroup(
    r: JobRoleRequirements,
    child?: JobRoleRequirements,
  ): FormGroup {
    return this.fb.group({
      id: [child ? child.id : r.id],
      claimLevelId: [
        child ? child.claimLevelId : r.claimLevelId,
        Validators.required,
      ],
      dueAfterUnit: [child && child?.id ? child.dueAfterUnit : r.dueAfterUnit],
      dueAfterValue: [
        child && child?.id ? child.dueAfterValue : r.dueAfterValue,
      ],
      newHireDueUnit: [
        child && child?.id ? child.newHireDueUnit : r.newHireDueUnit,
      ],
      newHireDueValue: [
        child && child?.id ? child.newHireDueValue : r.newHireDueValue,
      ],
      newVersionDueUnit: [
        child && child?.id ? child.newVersionDueUnit : r.newVersionDueUnit,
      ],
      newVersionDueValue: [
        child && child?.id ? child.newVersionDueValue : r.newVersionDueValue,
      ],
      keepDueDates: [{ value: false, disabled: true }],
      trainingType: [child ? child.externalType : r.externalType],
      externalGuid: [child ? child.externalGuid : r.externalGuid],
      trainingVersion: [child ? child.skillVersion : r.skillVersion],
      mandatory: child ? child.mandatory : r.mandatory,
      active: child ? child.isActive : r.isActive,
      pendingDeletion: [child ? child.pendingDeletion : r.pendingDeletion],
      recurUnit: [child && child?.id ? child.recurUnit : r.recurUnit],
      recurValue: [child && child?.id ? child.recurValue : r.recurValue],
      skillId: [child ? child.skillId : r.skillId, [Validators.required]],
      skillName: [child ? child.skillName : r.skillName],
      weighting: [
        child ? child.weighting : r.weighting,
        [Validators.required, Validators.min(0), Validators.max(1)],
      ],
      provider: [child ? child.externalProvider : r.externalProvider],
      owner: [child ? child.externalOwner : r.externalOwner],
      // The children array only exists on the parent form
      ...(child ? {} : { children: this.fb.array([]) }),
    });
  }

  addTrainingItem() {
    const newTrainingForm = this.fb.group({
      id: null,
      claimLevelId: 0, // default to 0, backend to pick up claim level reference value for client
      dueAfterUnit:
        this.settingsFormGroup.get('dueAfterUnit').value ??
        this.globalJobRoleSettings.get('jobRole')?.unit ??
        null,
      dueAfterValue:
        this.settingsFormGroup.get('dueAfterValue').value ??
        this.globalJobRoleSettings.get('jobRole')?.value ??
        null,
      newHireDueUnit:
        this.settingsFormGroup.get('newHireDueUnit').value ??
        this.globalJobRoleSettings.get('newHire')?.unit ??
        null,
      newHireDueValue:
        this.settingsFormGroup.get('newHireDueValue').value ??
        this.globalJobRoleSettings.get('newHire')?.value ??
        null,
      newVersionDueUnit:
        this.settingsFormGroup.get('newVersionDueUnit').value ??
        this.globalJobRoleSettings.get('newVersion')?.unit ??
        null,
      newVersionDueValue:
        this.settingsFormGroup.get('newVersionDueValue').value ??
        this.globalJobRoleSettings.get('newVersion')?.value ??
        null,
      keepDueDates: false,
      trainingVersion: this.fgNewTraining.get('trainingVersion').value,
      mandatory: this.fgNewTraining.get('mandatory').value,
      active: this.fgNewTraining.get('active').value,
      recurUnit: null,
      recurValue: null,
      skillId: this.fgNewTraining.get('skillId').value,
      skillName: this.fgNewTraining.get('skillName').value,
      weighting: 1,
      provider: this.fgNewTraining.get('provider').value,
      owner: this.fgNewTraining.get('owner').value,
      trainingType: this.fgNewTraining.get('trainingType').value,
      externalGuid: this.fgNewTraining.get('externalGuid').value,
      pendingDeletion: [false],
      children: this.fb.array([]),
    });

    const children = this.fgNewTraining.get('children').value;

    if (children.length > 0) {
      children.forEach((child) => {
        const childTrainingFormGroup = this.fb.group({
          id: [null],
          claimLevelId: [0, Validators.required],
          dueAfterUnit: [newTrainingForm.get('dueAfterUnit').value],
          dueAfterValue: [newTrainingForm.get('dueAfterValue').value],
          newHireDueUnit: [newTrainingForm.get('newHireDueUnit').value],
          newHireDueValue: [newTrainingForm.get('newHireDueValue').value],
          newVersionDueUnit: [newTrainingForm.get('newVersionDueUnit').value],
          newVersionDueValue: [newTrainingForm.get('newVersionDueValue').value],
          keepDueDates: [false],
          trainingType: [child.type],
          externalGuid: [child.externalGuid],
          trainingVersion: [child.version],
          mandatory: [false],
          active: [true],
          pendingDeletion: [false],
          recurUnit: [null],
          recurValue: [null],
          skillId: [child.id, [Validators.required]],
          skillName: [child.name],
          weighting: [
            1,
            [Validators.required, Validators.min(0), Validators.max(1)],
          ],
          provider: [child.provider],
          owner: [child.owner],
        });
        const children = newTrainingForm.get('children') as FormArray;
        children.push(childTrainingFormGroup);
      });
    }

    this.training.push(newTrainingForm);

    this.training.controls[this.training.length - 1].markAsDirty();

    this.selectedSkill = null;
    this.matchingSkills = [];
    this.addingReqInProgress = false;
    this.resetNewTrainingForm();
  }

  resetNewTrainingForm() {
    this.fgNewTraining.reset({
      id: null,
      claimLevelId: 0,
      dueAfterUnit: null,
      dueAfterValue: null,
      newHireDueUnit: null,
      newHireDueValue: null,
      newVersionDueUnit: null,
      newVersionDueValue: null,
      keepDueDates: false,
      trainingVersion: '',
      mandatory: false,
      active: true,
      recurUnit: null,
      recurValue: null,
      skillId: null,
      skillName: null,
      weighting: 1,
      provider: null,
      owner: null,
      trainingType: null,
      externalGuid: null,
      pendingDeletion: false,
      children: null,
    });
  }

  cancelTrainingItem() {
    this.addingReqInProgress = false;
    this.dupeSkill = false;
  }

  trackByReqs(index: number, requirement: JobRoleRequirement): number {
    return requirement.id;
  }

  onRemoveRequirementClick(reqIndex: number) {
    const reqControl = this.training.at(reqIndex);

    reqControl.get('pendingDeletion').setValue(true);
    reqControl.get('pendingDeletion').markAsDirty();

    this.updateControlState(reqControl, 'disable');

    const children = reqControl.get('children') as FormArray;

    if (children.value.length > 0) {
      children.controls.forEach((child) => {
        if (child.get('id').value) {
          child.get('pendingDeletion').setValue(true);

          child.markAsDirty({ onlySelf: true });
        }
        this.updateControlState(child, 'disable');
      });
    }
  }

  updateControlState(control: AbstractControl, action: string) {
    const controlsTopUpdate = [
      'recurUnit',
      'recurValue',
      'dueAfterUnit',
      'dueAfterValue',
      'newHireDueUnit',
      'newHireDueValue',
      'newVersionDueUnit',
      'newVersionDueValue',
      'mandatory',
      'keepDueDates',
    ];
    if (action === 'disable') {
      controlsTopUpdate.forEach((controlName) => {
        control.get(controlName).disable();
      });
    }

    if (action === 'enable') {
      controlsTopUpdate.forEach((controlName) => {
        control.get(controlName).enable();
      });
    }

    if (action === 'markAsPristine') {
      controlsTopUpdate.forEach((controlName) => {
        control.get(controlName).markAsPristine();
      });
    }
  }

  patchFormValues(
    control: AbstractControl,
    skill: JobRoleRequirements,
    child?: JobRoleRequirements,
  ) {
    control.patchValue(
      {
        id: child ? child.id : skill.id,
        claimLevelId: child ? child.claimLevelId : skill.claimLevelId,
        dueAfterUnit:
          child && child.id ? child.dueAfterUnit : skill.dueAfterUnit,
        dueAfterValue:
          child && child.id ? child.dueAfterValue : skill.dueAfterValue,
        newVersionDueValue:
          child && child.id
            ? child.newVersionDueValue
            : skill.newVersionDueValue,
        newVersionDueUnit:
          child && child.id ? child.newVersionDueUnit : skill.newVersionDueUnit,
        newHireDueValue:
          child && child.id ? child.newHireDueValue : skill.newHireDueValue,
        newHireDueUnit:
          child && child.id ? child.newHireDueUnit : skill.newHireDueUnit,
        keepDueDates: false,
        trainingType: child ? child.externalType : skill.externalType,
        externalGuid: child ? child.externalGuid : skill.externalGuid,
        trainingVersion: child ? child.skillVersion : skill.skillVersion,
        mandatory: child ? child.mandatory : skill.mandatory,
        active: child ? child.isActive : skill.isActive,
        pendingDeletion: child ? child.pendingDeletion : skill.pendingDeletion,
        recurUnit: child && child.id ? child.recurUnit : skill.recurUnit,
        recurValue: child && child.id ? child.recurValue : skill.recurValue,
        skillId: child ? child.skillId : skill.skillId,
        skillName: child ? child.skillName : skill.skillName,
        weighting: child ? child.weighting : skill.weighting,
        provider: child ? child.externalProvider : skill.externalProvider,
        owner: child ? child.externalOwner : skill.externalOwner,
      },
      { emitEvent: false },
    );
  }

  onDiscardChangeRequirement($event: {
    parentSkillId: number;
    skillId: number;
  }) {
    const { skillId, parentSkillId } = $event;

    if (parentSkillId) {
      const parent = this.jobRoleTrainings.find(
        (jrr) => jrr.skillId === parentSkillId,
      );
      const child = parent.children.find((child) => child.skillId === skillId);

      const parentControl = this.training.controls.find(
        (c) => c.value.skillId === parentSkillId,
      );
      const children = parentControl.get('children') as FormArray;
      const control = children.controls.find(
        (c) => c.value.skillId === skillId,
      );
      this.patchFormValues(control, parent, child);
      this.updateControlState(control, 'markAsPristine');
    } else {
      const skillValue = this.jobRoleTrainings.find(
        (jrr) => jrr.skillId === skillId,
      );
      const control = this.training.controls.find(
        (c) => c.value.skillId === skillId,
      );

      this.patchFormValues(control, skillValue);

      const children = control.get('children') as FormArray;

      if (children.value.length > 0) {
        children.controls.forEach((child) => {
          if (!child.dirty || (child.dirty && !child.touched)) {
            this.onDiscardChangeRequirement({
              skillId: child.get('skillId').value,
              parentSkillId: control.get('skillId').value,
            });
          }
        });
      }

      this.updateControlState(control, 'markAsPristine');
    }
  }

  onRestoreRequirementClick(rowIndex: number) {
    const control = this.training.at(rowIndex);
    this.onDiscardChangeRequirement({
      skillId: control.get('skillId').value,
      parentSkillId: null,
    });

    const children = control.get('children') as FormArray;
    if (children.value.length > 0) {
      children.controls.forEach((child) => {
        this.onDiscardChangeRequirement({
          skillId: control.get('skillId').value,
          parentSkillId: null,
        });
        child.enable();
      });
    }
    control.enable();
  }

  clearSearchBox() {
    this.resetNewTrainingForm();
    this.matchingSkills = [];
    this.selectedSkill = null;
    this.dupeSkill = false;
  }

  onAddRequirementClick() {
    this.addingReqInProgress = true;
  }

  roleSkillUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.training.value
        .map((jr) => jr.skillId)
        .filter((x) => x === control.value).length > 1
        ? { roleSkillUniqueness: { value: control.value } }
        : null;
    };
  }

  onDiscardAddedRequirementClick(reqIndex: number) {
    this.training.removeAt(reqIndex);
  }

  public setSelectedResult(event): void {
    this.setSelectedResultSubj.next(event);
  }

  formControlValid(controlName: string, index): boolean {
    const c: AbstractControl = this.training.at(index).get(controlName);
    return c.disabled || !(c.touched && c.invalid);
  }

  private setSelectedSkills(ms: SkillSearch[]) {
    this.matchingSkills = ms;
    if (this.matchingSkills.length > 0) {
      this.groupedSkillsByType = this.groupBy(
        this.matchingSkills,
        (skill) => skill.type,
      );

      if (this.groupedSkillsByType.size > 1) {
        this.fgNewTraining.get('trainingType').enable();
      }

      const sortedItemsInFirstGrouping = this.groupedSkillsByType
        .values()
        .next()
        .value.sort((a, b) =>
          a.version.localeCompare(b.version, undefined, { numeric: true }),
        );

      if (sortedItemsInFirstGrouping.length > 1) {
        this.fgNewTraining.get('trainingVersion').enable();
      }
      this.selectedSkill =
        sortedItemsInFirstGrouping[sortedItemsInFirstGrouping.length - 1];

      this.updateNewTrainingForm();
    }

    this.dupeSkill = this.checkIfDuplicateSkill();
  }

  checkIfDuplicateSkill(): boolean {
    const selectedSkillId = this.selectedSkill.id;
    const trainingSkills = this.training.value;

    const isSkillInTraining = trainingSkills.some(
      (skill) => skill.skillId === selectedSkillId,
    );
    if (isSkillInTraining) {
      return true;
    }

    const isSkillInChildren = trainingSkills
      .filter(
        (skill) =>
          skill.trainingType === 'Curriculum' && skill.children.length > 0,
      )
      .some((curriculum) =>
        curriculum.children.some((child) => child.skillId === selectedSkillId),
      );

    return isSkillInChildren;
  }

  updateNewTrainingForm() {
    if (this.selectedSkill.type === 'Curriculum') {
      this.fetchingCurriculumLOs = true;
      this.jobRoleService
        .getCurriculumChildrenBySkillId(this.selectedSkill.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (children) => {
            this.patchNewTrainingForm(children);
            this.fetchingCurriculumLOs = false;
          },
          error: (error) => {
            this.alertService.createAlert2(
              {
                level: AlertLevels.ERROR,
                code: 'JRT-001',
                message: `Error getting child LOs for curriculum skill ${this.selectedSkill.name}`,
              } as AlertData,
              error,
            );
            this.fetchingCurriculumLOs = false;
          },
        });
    } else {
      this.fetchingCurriculumLOs = false;
      this.patchNewTrainingForm();
    }
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  patchNewTrainingForm(children = []) {
    this.fgNewTraining.patchValue({
      skillId: this.selectedSkill.id,
      trainingType: this.selectedSkill.type,
      skillName: this.selectedSkill.name,
      trainingVersion: this.selectedSkill.version,
      provider: this.selectedSkill.provider,
      owner: this.selectedSkill.owner,
      pendingDeletion: false,
      externalGuid: this.selectedSkill.externalGuid.toUpperCase(),
      children: children,
    });
  }

  toggleRow(skillId: number) {
    this.collapsedRowMap.set(
      skillId,
      !this.collapsedRowMap.get(skillId) || false,
    );
  }

  showSelectBox($event) {
    this.showRequirementSelectBox = $event;
    this.selectedRows = [];
  }

  confirmSettings(assignmentRule: string) {
    const updateControlAndChildren = (
      control: AbstractControl,
      markAsDirty: boolean,
    ) => {
      this.updateDueDateValues(control, assignmentRule, markAsDirty);
      const children = control.get('children') as FormArray;
      children.controls.forEach((childControl) => {
        const childMarkAsDirty = !!childControl.get('id').value;
        this.updateDueDateValues(
          childControl,
          assignmentRule,
          childMarkAsDirty,
        );
      });
    };

    if (this.showRequirementSelectBox) {
      this.selectedRows.forEach((selectedRow) => {
        const control = this.findControlBySkillId(
          selectedRow.skillId,
          selectedRow.parentId,
        );
        if (control) {
          this.updateDueDateValues(control, assignmentRule, true);
        }
      });
    } else {
      this.training.controls
        .filter((control) => !control.disabled)
        .forEach((control) => updateControlAndChildren(control, true));
    }

    this.selectedRows = [];
    this.showRequirementSelectBox = false;
  }

  private findControlBySkillId(skillId: number, parentId?: number) {
    if (parentId) {
      const parentControl = this.findControlBySkillId(parentId);
      const children = parentControl?.get('children') as FormArray;
      return children?.controls.find((c) => c.value.skillId === skillId);
    } else {
      return this.training.controls.find((c) => c.value.skillId === skillId);
    }
  }

  selectedRequirement($event) {
    const selectedRowIndex = this.selectedRows.findIndex((x) => x === $event);
    if (selectedRowIndex === -1) {
      this.selectedRows.push($event);
    } else {
      this.selectedRows.splice(selectedRowIndex, 1);
    }
  }

  get settingsFormGroup() {
    return this.parentFormGroup.get('settings') as FormGroup;
  }

  updateDueDateValues(
    control: AbstractControl,
    assignmentRule: string,
    markAsDirty = false,
  ) {
    const controlNames = [
      'dueAfterUnit',
      'dueAfterValue',
      'newVersionDueUnit',
      'newVersionDueValue',
      'newHireDueUnit',
      'newHireDueValue',
    ];

    const updateControl = (controlName: string) => {
      const controlToSet = control.get(controlName);
      const newValue = this.settingsFormGroup.get(controlName).value;

      if (controlToSet.value !== newValue) {
        controlToSet.setValue(newValue, { emitEvent: false });
        if (markAsDirty) {
          controlToSet.markAsDirty();
        }
      }
    };

    controlNames.forEach(updateControl);

    const keepDueDatesControl = control.get('keepDueDates');
    if (assignmentRule === 'newOnly') {
      keepDueDatesControl.setValue(true, { emitEvent: false });
    } else if (assignmentRule === 'replace') {
      keepDueDatesControl.setValue(false, { emitEvent: false });
    }
  }
}
