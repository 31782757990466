import { Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  filter,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  ClaimLevelDictionary,
  ClaimService,
} from '../../claim/services/claim.service';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableRowButton,
  TableSelectedButton,
} from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { SkillModalComponent } from '../../shared/skill-modal/skill-modal.component';
import { SkillLookup, SkillService } from '../../skill/services/skill.service';
import {
  EventSkillPayload,
  EventsService,
  SkillForEvent,
  SkillVsEvent,
} from '../services/events.service';

@Component({
  selector: 'ug-events-calendar-settings',
  templateUrl: './events-calendar-settings.component.html',
  styleUrls: ['./events-calendar-settings.component.scss'],
})
export class EventsCalendarSettingsComponent implements OnInit, OnDestroy {
  private alertService = inject(AlertService);
  private eventsService = inject(EventsService);
  private uiService = inject(UitoolsService);
  private ngbModal = inject(NgbModal);
  private skillsService = inject(SkillService);
  private claimService = inject(ClaimService);
  private authService = inject(AuthService);

  exceptionData = {
    SKILLS_BY_TYPE: {
      level: AlertLevels.ERROR,
      code: 'ECS-001',
      message: 'Error when fetching training events',
    },
    COMPETENCIES_SAVE_TO_EVENT: {
      level: AlertLevels.ERROR,
      code: 'ECS-002',
      message: 'Error saving competencies to the event',
    },
    COMPETENCIES_FOR_EVENT: {
      level: AlertLevels.ERROR,
      code: 'ECS-003',
      message: 'Error fetching competencies for event',
    },
    COMPETENCIES_REMOVE_FROM_EVENT: {
      level: AlertLevels.ERROR,
      code: 'ECS-004',
      message: 'Error deleting competencies for event',
    },
    SKILLS_LIST: {
      level: AlertLevels.ERROR,
      code: 'ECS-005',
      message: 'Error retrieving skills',
    } as AlertData,
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'ECS-006',
      message: 'Error retrieving claim levels',
    } as AlertData,
  };

  competenciesToDelete = [];
  competenciesToUpdate = [];
  ngUnsubscribe: Subject<boolean> = new Subject();
  trainingSubjects: SkillLookup[];
  selectedEvent: string;
  activeTab = 1;
  tableLoading: boolean = false;
  tableRows = [];
  tableRowDropdowns = [];
  skillsForEvent = [];
  claimLevelsMap = new Map<number, any>();
  readOnlyView: boolean = false;
  tableSelectedButtons: TableSelectedButton[] = [];

  onDeleteCompetencyClick = (competency) => {
    this.competenciesToDelete.push(competency.id);
  };

  hideDeleteButton = (competency) => {
    return (
      this.competenciesToDelete.includes(competency.id) || this.readOnlyView
    );
  };

  onRestoreCompetencyClick = (competency) => {
    const index = this.competenciesToDelete.findIndex(
      (cid) => cid === competency.id,
    );
    this.competenciesToDelete.splice(index, 1);
  };

  hideRestoreButton = (competency) => {
    return !this.competenciesToDelete.includes(competency.id);
  };

  disableClaimLevelDropdown = (competency) => {
    return this.competenciesToDelete.includes(competency.id);
  };

  disableSaveButton = () => {
    return !this.competenciesToDelete.length &&
      !this.updatesExcludingDeleted.length
      ? true
      : false;
  };

  claimLevelChange = (competency) => {
    const changedSkill = this.skillsForEvent.find(
      (sk) => sk.id === competency.id,
    );
    if (changedSkill.claimLevelId === competency.claimLevelId) {
      const updateIndex = this.competenciesToUpdate.findIndex(
        (sk) => sk.id === competency.id,
      );
      this.competenciesToUpdate.splice(updateIndex, 1);
    } else {
      this.competenciesToUpdate.push(competency);
    }
  };

  tableHeaders: Array<TableHeader> = [
    { id: 'skillName', title: 'Competency', class: 'w-25' },
    { id: 'skillDescription', title: 'Description', class: 'w-50' },
    {
      id: 'claimLevelId',
      title: 'Claim Level',
      class: 'w-15',
      dropdown: true,
      rowDropdowns: [],
      dropdownBindName: 'name',
      dropdownBindValue: 'key',
      disabledCondition: this.hideDeleteButton,
      dropdownFunction: this.claimLevelChange,
    },
  ];

  tableRowButtons: TableRowButton[] = [
    {
      title: 'Delete',
      class: 'btn-outline-danger',
      rowAction: this.onDeleteCompetencyClick,
      hideCondition: this.hideDeleteButton,
    },
    {
      title: 'Restore',
      class: 'btn-outline-secondary',
      rowAction: this.onRestoreCompetencyClick,
      hideCondition: this.hideRestoreButton,
    },
  ];

  noDataMessage =
    'Please select a training event to view the assigned competencies';
  tableFilter: Array<FilterBase<any>> = [
    new TextboxFilter({
      key: 'searchTerm',
      label: 'Search assigned competencies',
      order: 1,
      placeholder: 'Begin typing to search...',
    }),
  ];

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (
      this.updatesExcludingDeleted.length ||
      this.competenciesToDelete.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.tableSelectedButtons = [
      {
        title: 'Save Changes',
        class: 'btn btn-outline-success',
        function: this.saveChanges,
        hideCondition: this.readOnlyView,
        disabledCondition: this.disableSaveButton,
      },
    ];
    this.skillsService
      .getSkillsByExternalType('Event')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (r) => {
          this.trainingSubjects = r;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.SKILLS_BY_TYPE,
            err,
          );
        },
      });

    this.claimService
      .getAllClaimLevels()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (cl: ClaimLevelDictionary) => {
          for (const key in cl) {
            if (cl.hasOwnProperty(key)) {
              const values = cl[key].map((c) => ({
                ...c,
                key: Number(c.key),
              }));
              this.claimLevelsMap.set(Number(key), values);
            }
          }
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.CLAIM_LEVELS, err);
        },
      });

    this.authService.loggedInUserSubj.subscribe((usd: LoggedInUser) => {
      this.readOnlyView = [
        SecurityRoleKey.JobRolePeople,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRoleReqsPeople,
        SecurityRoleKey.OuResponsible,
      ].some((sr) => usd.roleIdentifier === sr);
    });
  }

  openAddCompetenciesModal() {
    const modalRef = this.ngbModal.open(SkillModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'xxl',
    });
    modalRef.componentInstance.eventSkillId = Number(this.selectedEvent);
    modalRef.componentInstance.dimensionIdsToInclude = [1, 2, 3];
    modalRef.componentInstance.existsKey = 'existsInEvent';
    modalRef.componentInstance.tableHeaders = [
      {
        id: 'name',
        title: 'Competency',
        class: 'w-25',
      },
      {
        id: 'description',
        title: 'Description',
        class: 'w-50',
      },
      {
        id: 'existsInEvent',
        title: 'Exists In Event',
        iconFunction: this.activeIcon,
        class: 'text-center w-10',
      },
    ];
    const eventName = this.trainingSubjects.find(
      (ts) => ts.key === this.selectedEvent,
    ).name;
    modalRef.componentInstance.modalTitle = `Add Competencies to Event : ${eventName} `;

    modalRef.componentInstance.skillsFilters.subscribe((sf) => {
      modalRef.componentInstance.tableLoading = true;
      this.eventsService
        .getSkillsVsEventId(Number(this.selectedEvent), sf)
        .subscribe({
          next: (x: SkillVsEvent[]) => {
            modalRef.componentInstance.rowsChangedSubject.next(x);
            modalRef.componentInstance.skillCount = x.length;
            modalRef.componentInstance.tableLoading = false;
          },
          error: (err) => {
            this.alertService.createAlert2(this.exceptionData.SKILLS_LIST, err);
            modalRef.componentInstance.tableLoading = false;
          },
        });
    });

    modalRef.componentInstance.skillsSelected.subscribe((sks) => {
      const eventSkillPayload: EventSkillPayload[] = [];
      sks.forEach((sk) => {
        eventSkillPayload.push({
          skillId: sk.id,
          claimLevelId: this.claimLevelsMap.get(sk.claimLevelSetId)[0].key,
        });
      });

      this.eventsService
        .addSkillsToEvent(Number(this.selectedEvent), eventSkillPayload)
        .subscribe({
          next: (s: SkillForEvent[]) => {
            this.updateTable(s);
            this.uiService.showToast(
              `${sks.length} competencies successfully added`,
              { classname: 'bg-success text-light', delay: 5000 },
            );
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.COMPETENCIES_SAVE_TO_EVENT,
              err,
            );
          },
        });
    });
  }

  onChange($event) {
    if ($event) {
      this.tableLoading = true;
      this.eventsService
        .getSkillsForEvent(Number(this.selectedEvent))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (s: SkillForEvent[]) => {
            this.updateTable(s);
            this.tableLoading = false;
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.COMPETENCIES_FOR_EVENT,
              err,
            );
          },
        });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  saveChanges = () => {
    if (this.competenciesToDelete.length) {
      this.eventsService
        .removeSkillFromEvent(this.competenciesToDelete)
        .pipe(
          tap((result) => {
            if (!this.updatesExcludingDeleted.length) {
              this.updateTable(result);
              this.uiService.showToast(
                `Event competencies successfully updated`,
                { classname: 'bg-success text-light', delay: 5000 },
              );
            }
            this.competenciesToDelete = [];
          }),
          filter(() => this.updatesExcludingDeleted.length > 0),
          switchMap((val) => {
            const eventSkillPayload: EventSkillPayload[] = [];
            this.updatesExcludingDeleted.forEach((sk) => {
              eventSkillPayload.push({
                skillId: sk.skillId,
                claimLevelId: sk.claimLevelId,
              });
            });

            return this.eventsService.updateSkillsForEvent(
              Number(this.selectedEvent),
              eventSkillPayload,
            );
          }),
          catchError((error) => {
            this.alertService.createAlert2(
              this.exceptionData.COMPETENCIES_REMOVE_FROM_EVENT,
              error,
            );
            this.competenciesToDelete = [];
            return of(undefined);
          }),
        )
        .subscribe({
          next: (x) => {
            this.updateTable(x);
            this.uiService.showToast(
              `Event competencies successfully updated`,
              { classname: 'bg-success text-light', delay: 5000 },
            );
            this.competenciesToUpdate = [];
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.COMPETENCIES_SAVE_TO_EVENT,
              err,
            );
            this.competenciesToUpdate = [];
          },
        });
    } else {
      const eventSkillPayload: EventSkillPayload[] = [];
      this.updatesExcludingDeleted.forEach((sk) => {
        eventSkillPayload.push({
          skillId: sk.skillId,
          claimLevelId: sk.claimLevelId,
        });
      });

      this.eventsService
        .updateSkillsForEvent(Number(this.selectedEvent), eventSkillPayload)
        .subscribe({
          next: (x) => {
            this.updateTable(x);
            this.uiService.showToast(
              `Event competencies successfully updated`,
              { classname: 'bg-success text-light', delay: 5000 },
            );
            this.competenciesToUpdate = [];
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.COMPETENCIES_SAVE_TO_EVENT,
              err,
            );
            this.competenciesToUpdate = [];
          },
        });
    }
  };

  get updatesExcludingDeleted() {
    return this.competenciesToUpdate?.filter(
      (c) => !this.competenciesToDelete.includes(c.id),
    );
  }

  updateTable(data) {
    this.tableRows = data;
    if (!data.length) {
      this.noDataMessage = 'No competencies have been assigned to this event';
    }
    this.skillsForEvent = [...data];
    const tableHeader = this.tableHeaders.find(
      (th) => th.id === 'claimLevelId',
    );
    tableHeader.rowDropdowns = [];

    this.tableRows.forEach((tr) => {
      tableHeader.rowDropdowns.push(
        this.claimLevelsMap.get(tr.claimLevelSetId),
      );
    });
  }

  activeIcon = (row) => {
    return row.existsInEvent === true
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };
}
