import { Observable, of } from 'rxjs';

export class FilterBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { key: string; value: any }[] | string[];
  placeholder: string;
  typeAheadSearch: any;
  callback: any;
  inputFormatter: any;
  resultFormatter: any;
  inLineGroup: boolean;
  defaultValue: any;
  multiple: boolean;
  maxSelectedItems: number | string;
  maxDisplayItems: number;
  filterClass: string;
  showFirstLabel: boolean;
  filterLoading: boolean;
  typeaheadSubject: any;
  items: Observable<any>;
  bindLabel: string;
  isMap: boolean;
  disabled: boolean;
  invalidMessage: string;
  notFoundText: string;
  bindValue: string;
  newRow: boolean;
  tooltip: string;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: any }[];
      placeholder?: string;
      typeAheadSearch?: any;
      callback?: any;
      inputFormatter?: any;
      resultFormatter?: any;
      inLineGroup?: boolean;
      defaultValue?: any;
      multiple?: boolean;
      maxSelectedItems?: number | string;
      maxDisplayItems?: number;
      filterClass?: string;
      showFirstLabel?: boolean;
      filterLoading?: boolean;
      typeaheadSubject?: any;
      items?: Observable<any>;
      bindLabel?: string;
      isMap?: boolean;
      disabled?: boolean;
      invalidMessage?: string;
      notFoundText?: string;
      bindValue?: string;
      newRow?: boolean;
      tooltip?: string;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.placeholder = options.placeholder || '';
    this.typeAheadSearch = options.typeAheadSearch || '';
    this.callback = options.callback || '';
    this.inputFormatter = options.inputFormatter || '';
    this.resultFormatter = options.resultFormatter || '';
    this.inLineGroup = options.inLineGroup || false;
    this.defaultValue = options.defaultValue || '';
    this.multiple = options.multiple || false;
    this.maxSelectedItems = options.maxSelectedItems || 'none';
    this.maxDisplayItems = options.maxDisplayItems || null;
    this.filterClass = options.filterClass || '';
    this.showFirstLabel = options.showFirstLabel || false;
    this.filterLoading = options.filterLoading || false;
    this.typeaheadSubject = options.typeaheadSubject || null;
    this.items = options.items || null;
    this.bindLabel = options.bindLabel || '';
    this.isMap = options.isMap || false;
    this.disabled = options.disabled || false;
    this.invalidMessage = options.invalidMessage || '';
    this.notFoundText = options.notFoundText || 'No items found';
    this.bindValue = options.bindValue || null;
    this.newRow = options.newRow || false;
    this.tooltip = options.tooltip || null;
  }

  inputChange(value) {
    if (this.callback) {
      return this.callback(of(value));
    } else if (this.typeaheadSubject) {
      return this.typeaheadSubject ? this.typeaheadSubject.next(value) : null;
    } else {
      return null;
    }
  }
}
