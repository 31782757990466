import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomMinMaxValueValidators {
  static maxGreaterThanMinValidator(
    minInput: string,
    maxInput: string,
    fieldName: string,
  ): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const minValue = formGroup.get(minInput).value;
      const maxValue = formGroup.get(maxInput).value;

      const isRangeValid = maxValue - minValue >= 0;

      return isRangeValid ? null : { [fieldName]: true };
    };
  }
}
