import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ClaimService } from '../../../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
} from '../../../../core/services/alert.service';
import { JobRole } from '../../../../job-role/services/job-role.service';
import {
  AuditData,
  AuditFilter,
} from '../../../../shared/audit-table/audit.service';
import { JobRoleRequirements } from '../role-management.service';
import { CompetenciesModalComponent } from './competencies-modal/competencies-modal.component';

@Component({
  selector: 'ug-role-competencies',
  templateUrl: './role-competencies.component.html',
  styleUrls: ['./role-competencies.component.scss'],
})
export class RoleCompetenciesComponent implements OnInit, OnDestroy, OnChanges {
  fb = inject(FormBuilder);
  private claimService = inject(ClaimService);
  private ngbModal = inject(NgbModal);

  exceptionData = {
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'JRC-001',
      message: 'Error retrieving claim levels',
    } as AlertData,
  };

  @ViewChild('roleCompetenciesModal') roleCompetenciesModal: ElementRef;
  @Input() parentFormGroup: FormGroup;
  @Input() allowEdit: boolean;
  @Input() jobRole: JobRole;
  @Input() jobRoleId: number;
  @Input() jobRoleCompetencies: Array<JobRoleRequirements>;
  @Input() discardChangesSubj: Subject<boolean>;
  @Input() requirementAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;

  initialValues: any;
  levelSets: any;
  preDeleteReqControlStatus = {};
  ngUnsubscribe: Subject<boolean> = new Subject();
  public isCollapsed = true;

  protected readonly parseInt = parseInt;

  get competencies(): FormArray {
    return this.parentFormGroup.get('competencies') as FormArray;
  }

  ngOnInit(): void {
    this.claimService
      .getAllClaimLevels()
      .subscribe((s) => (this.levelSets = s));

    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.competencies.reset(this.initialValues);

        for (let i = this.competencies.length - 1; i >= 0; i--) {
          const control = this.competencies.at(i);
          if (!control.value.id) {
            this.competencies.removeAt(i);
          } else {
            if (!control.value.pendingDeletion && this.allowEdit) {
              control.get('claimLevelId').enable();
              control.get('weighting').enable();
              control.get('mandatory').enable();
            }
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['jobRoleCompetencies'] &&
      changes['jobRoleCompetencies'].currentValue !==
        changes['jobRoleCompetencies'].previousValue &&
      this.jobRoleCompetencies &&
      this.jobRoleCompetencies.length > 0
    ) {
      this.competencies.clear();
      this.jobRoleCompetencies.forEach((r) => {
        const compFormGroup = this.fb.group({
          claimLevelId: [r.claimLevelId, Validators.required],
          claimLevelSetId: [r.claimLevelSetId],
          functionalAreaName: [r.functionalAreaName],
          id: [r.id],
          jobRoleId: this.jobRoleId,
          pendingDeletion: [r.pendingDeletion],
          mandatory: [r.mandatory, Validators.required],
          skillId: [r.skillId, Validators.required],
          skillName: [r.skillName],
          weighting: [
            r.weighting,
            [Validators.required, Validators.min(0), Validators.max(1)],
          ],
        });
        if (r.pendingDeletion || !this.allowEdit) {
          compFormGroup.get('claimLevelId').disable();
          compFormGroup.get('weighting').disable();
          compFormGroup.get('mandatory').disable();
        }

        this.competencies.push(compFormGroup);
      });

      this.initialValues = this.competencies.getRawValue();
    }
  }

  openAddRoleCompetenciesModal() {
    const modalRef = this.ngbModal.open(CompetenciesModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl',
    });

    modalRef.componentInstance.parentFormGroup = this.parentFormGroup;
    modalRef.componentInstance.jobRoleId = this.jobRoleId;
  }

  onRemoveRequirementClick(reqIndex: number) {
    const reqControls = this.competencies.at(reqIndex);

    reqControls.get('pendingDeletion').setValue(true);
    reqControls.get('claimLevelId').disable();
    reqControls.get('weighting').disable();
    reqControls.get('mandatory').disable();
    reqControls.markAsDirty();
  }

  onDiscardAddedRequirementClick(reqIndex: number) {
    this.competencies.removeAt(reqIndex);
  }

  onDiscardChangeRequirementClick(reqIndex: number, id: number) {
    const jr = this.jobRoleCompetencies.find((jrr) => jrr.id === id);
    this.competencies.at(reqIndex).setValue({
      claimLevelId: jr.claimLevelId,
      claimLevelSetId: jr.claimLevelSetId,
      functionalAreaName: jr.functionalAreaName,
      id: jr.id,
      jobRoleId: this.jobRoleId,
      pendingDeletion: jr.pendingDeletion,
      mandatory: jr.mandatory,
      skillId: jr.skillId,
      skillName: jr.skillName,
      weighting: jr.weighting,
    });
    this.competencies.at(reqIndex).markAsPristine();
  }

  onRestoreRequirementClick(reqIndex: number, id: number) {
    const jr = this.jobRoleCompetencies.find((jrr) => jrr.id === id);

    this.competencies.at(reqIndex).setValue({
      claimLevelId: jr.claimLevelId,
      claimLevelSetId: jr.claimLevelSetId,
      functionalAreaName: jr.functionalAreaName,
      id: jr.id,
      jobRoleId: this.jobRoleId,
      pendingDeletion: jr.pendingDeletion,
      mandatory: jr.mandatory,
      skillId: jr.skillId,
      skillName: jr.skillName,
      weighting: jr.weighting,
    });

    this.competencies.at(reqIndex).get('claimLevelId').enable();
    this.competencies.at(reqIndex).get('weighting').enable();
    this.competencies.at(reqIndex).get('mandatory').enable();

    this.competencies.at(reqIndex).markAsPristine();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
