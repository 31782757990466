import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrgUnitDetailComponent } from './org-unit-detail/org-unit-detail.component';
import { OrgUnitRoutingModule } from './org-unit-routing.module';
import { OrgUnitService } from './services/org-unit.service';
import { SkillsMatrixDivisionComponent } from './skills-matrix-division/skills-matrix-division.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OrgUnitRoutingModule,
    ReactiveFormsModule,
  ],
  declarations: [OrgUnitDetailComponent, SkillsMatrixDivisionComponent],
  exports: [OrgUnitDetailComponent],
  providers: [OrgUnitService],
})
export class OrgUnitModule {}
