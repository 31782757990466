<div class="card shadow overflow-visible">
  <div class="card-body">
    <form [formGroup]="createProjectForm">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeTab"
        [destroyOnHide]="false"
        class="nav-justified"
      >
        <li [ngbNavItem]="1">
          <a ngbNavLink class="p-0 m-0" (click)="setTab()">
            <h6>Key Details</h6>
            <h6>
              <small>Provide some basic information about the project</small>
            </h6>
          </a>
          <ng-template ngbNavContent>
            <ug-key-details
              [parentFormGroup]="createProjectForm"
            ></ug-key-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" (click)="setTab()">
          <a ngbNavLink class="p-0 m-0">
            <h6>Roles and Resources</h6>
            <h6>
              <small
                >Add required roles and allocate resources to the project</small
              >
            </h6>
          </a>
          <ng-template ngbNavContent>
            <ug-roles-and-resources
              [parentFormGroup]="createProjectForm"
              [peopleList]="peopleList"
              [peopleSearch]="peopleSearch"
              [activeSubTab]="activeSubTab"
              [activeParentTab]="activeTab"
            ></ug-roles-and-resources>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="p-0 m-0" (click)="setTab()">
            <h6>Settings and Security</h6>
            <h6>
              <small
                >Settings and visibility restrictions for the project</small
              >
            </h6>
          </a>
          <ng-template ngbNavContent>
            <ug-project-settings
              [parentFormGroup]="createProjectForm"
              [peopleList]="peopleList"
              [peopleSearch]="peopleSearch"
            ></ug-project-settings>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" (click)="setTab()">
          <a ngbNavLink class="p-0 m-0">
            <h6>Review</h6>
            <h6>
              <small>Review and confirm the creation of the project</small>
            </h6>
          </a>
          <ng-template ngbNavContent>
            <ug-project-review
              [parentFormGroup]="createProjectForm"
            ></ug-project-review>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </form>
  </div>
  <div class="card-footer bg-white border-0">
    <div *ngIf="activeTab !== 2" class="d-flex justify-content-end">
      <button
        type="button"
        *ngIf="activeTab !== 1"
        title="Previous"
        [disabled]="activeTab === 1"
        class="btn esqepBtn mx-2"
        (click)="selectPreviousTab()"
      >
        Previous
      </button>
      <button
        type="button"
        *ngIf="activeTab !== 4"
        title="Next"
        class="btn esqepBtn"
        (click)="selectNextTab()"
        [disabled]="!isTabFormValid(activeTab)"
      >
        Next
      </button>
      <button
        type="button"
        *ngIf="activeTab === 4"
        title="Create project"
        class="btn btn-outline-success"
      >
        Create Project
      </button>
    </div>
    <div *ngIf="activeTab === 2" class="d-flex justify-content-end">
      <button
        type="button"
        title="Previous"
        class="btn esqepBtn mx-2"
        (click)="selectPreviousSubTab()"
      >
        Previous
      </button>
      <button
        type="button"
        title="Next"
        class="btn esqepBtn"
        (click)="selectNextSubTab()"
        [disabled]="!isTabFormValid(activeTab)"
      >
        Next
      </button>
    </div>
  </div>
</div>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
