import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PeopleByOuPayload } from '../../core/models/peopleByOuPayload';

const dotNetServerUri = environment.serverUrl;

export enum ClaimStatusKey {
  Complete = 'Complete',
  NotComplete = 'Not Complete / Expired',
  AwaitingSync = 'Awaiting Sync',
  Updating = 'Updating',
  NotActivated = 'Not Activated',
}

export enum MatrixType {
  Training = 'training',
  Competency = 'competency',
  JobRole = 'jobRole',
}

export class MatrixOrgUnit {
  id: number;
  parentId: number;
  orgUnitTypeId: number;
  externalId: number;
  externalParentId: number;
  name: string;
  externalExId: string;
  description: null;
  isActive: boolean;
}

export class MatrixPersonClaim {
  id: number;
  personId: number;
  isPublished: boolean;
  status: string;
  dueDate: string;
  expiryDate: string;
  completionDate: string;
  state: string;
  isAlert: boolean;
  isRequired: boolean;
}

export class MatrixSkill {
  id: number;
  name: string;
  url: string;
  objectId: string;
  type: string;
  userCanAccess: boolean;
  claims: MatrixPersonClaim[];
  sectionNumber?: string;
  sectionTitle?: string;
}

export class MatrixGroupedSkills {
  id: number;
  name: string;
  skills: MatrixSkill[];
  sections: MatrixSection[];
}

export interface MatrixSection {
  name: string;
  skills: MatrixSkill[];
}

export class MatrixTab {
  id: number;
  name: string;
  groupedSkills: MatrixGroupedSkills[];
  ungroupedSkills: MatrixSkill[];
}

export class MatrixPerson {
  id: number;
  displayName: string;
  supervisorDisplayName: string;
  externalSource: string;
  externalGuid: string;
  externalUserId: string;
  externalUserName: string;
  externalId: number;
  positionId: number;
  positionName: string;
  orgUnitId: number;
  orgUnitName: string;
  isManager: boolean;
  isAdditional: boolean;
}

export class Matrix {
  personIdOrder: number[];
  people: Record<string, MatrixPerson>;
  tabIdOrder: number[];
  tabs: Record<string, MatrixTab>;
}

export interface MatrixPayload {
  orgUnitId: number;
  subjectId: number;
  extraPersonIds: Array<number>;
  excludeLoggedInUser: boolean;
}

export interface MatrixSummary {
  skillStatusCounts: StatusCountMap;
  subjects: SummarySubject[];
}

export interface SummarySubject {
  children: SummarySubject[];
  key: string;
  name: string;
  subjectLevelId: number;
  subjectLevelName: string;
  selected?: boolean;
  complete?: number;
}

export interface StatusCountMap {
  [parentKey: string]: OrgUnitStatusCount;
}

export interface OrgUnitStatusCount {
  key: string;
  subjectId: number;
  orgUnitId: number;
  name: string;
  publishedCount: number;
  totalCount: number;
  childKeys: string[];
  selected?: boolean;
}

export interface MatrixSummaryFilters {
  subjectId: number;
  orgUnitId: number;
}

export interface ClaimsForTrainingMatrixRequest {
  skillId: number;
  skillObjectId: string;
  personIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class MatrixService {
  private http = inject(HttpClient);

  public static readonly EVENT = 'Event';

  statusColourMap = new Map<string, string>([
    ['1', 'danger'],
    ['2', 'success'],
    ['3', 'secondary'],
    ['4', 'info'],
    ['5', 'danger'],
    ['6', 'secondary'],
  ]);

  matrixTableTitle = new Map<string, string>([
    ['training', 'Training'],
    ['competency', 'Competencies'],
    ['jobRole', 'Training / Competencies'],
  ]);

  static MatrixTableTitle: any;

  getOrgUnitsForMatrix(): Observable<Array<MatrixOrgUnit>> {
    return this.http.get(`${dotNetServerUri}/orgUnits/forMatrix`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getMatrixByType(matrixData: MatrixPayload, type: string): Observable<Matrix> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${dotNetServerUri}/matrices/${type}`, JSON.stringify(matrixData), {
        headers: headers,
      })
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getMatrixTraining(matrixData: MatrixPayload): Observable<Matrix> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/matrices/training`,
        JSON.stringify(matrixData),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getMatrixCompetency(matrixData: MatrixPayload): Observable<Matrix> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/matrices/competency`,
        JSON.stringify(matrixData),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPeopleByOrgUnit(peopleByOuPayload: PeopleByOuPayload): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(
        `${dotNetServerUri}/people/lookups/byOrgUnit`,
        JSON.stringify(peopleByOuPayload),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getMatrixSummaryByType(type: string): Observable<MatrixSummary> {
    return this.http.get<MatrixSummary>(
      `${dotNetServerUri}/matrices/summary/${type}`,
    );
  }

  completeClaimsForTrainingMatrix(claimData: ClaimsForTrainingMatrixRequest) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(
      `${dotNetServerUri}/claims/forTrainingMatrix`,
      JSON.stringify(claimData),
      { headers: headers },
    );
  }
}
