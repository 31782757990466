import { Component, HostListener, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, of, switchMap, takeUntil, tap } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  DynamicGroupsService,
  Filter,
} from '../../dynamic-groups/services/dynamic-groups.service';
import { AuditService } from '../../shared/audit-table/audit.service';
import { GroupFiltersModalComponent } from '../../shared/group-filters-modal/group-filters-modal.component';
import {
  JobRoleService,
  JobRoleSetting,
  JobRoleSettingForm,
  JobRoleSettings,
  JobRoleSettingsForm,
  JobRoleSettingsPost,
} from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-settings',
  templateUrl: './job-role-settings.component.html',
  styleUrls: ['./job-role-settings.component.scss'],
})
export class JobRoleSettingsComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private ngbModal = inject(NgbModal);
  private router = inject(Router);
  private jobRoleService = inject(JobRoleService);
  private uiService = inject(UitoolsService);
  private alertService = inject(AlertService);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private auditService = inject(AuditService);

  exceptionData = {
    GET_JOB_ROLE_SETTINGS: {
      level: AlertLevels.ERROR,
      code: 'JRS-001',
      message: 'Error getting job role settings',
    } as AlertData,
    POST_JOB_ROLE_SETTINGS: {
      level: AlertLevels.ERROR,
      code: 'JRS-002',
      message: 'Error saving job role settings',
    } as AlertData,
  };

  unitOptions!: Map<string, string>;
  fieldOptions!: Map<string, string>;
  currentSettings!: Map<string, JobRoleSetting>;
  selectedGroup: Filter | undefined;
  jobRoleSettingsForm: FormGroup<JobRoleSettingsForm> = new FormGroup({});
  isLoading = true;
  ngUnsubscribe: Subject<boolean> = new Subject();
  public isCollapsed = true;

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.jobRoleSettingsForm.dirty) {
      return false;
    } else {
      return true;
    }
  }

  onDeleteGroupClick = () => {
    this.selectedGroup = undefined;
  };

  ngOnInit(): void {
    this.jobRoleService
      .getJobRoleSettingsDueDates()
      .pipe(
        tap((jrs) => this.initializeForm(jrs)),
        takeUntil(this.ngUnsubscribe),
        switchMap(() => {
          return this.newHireFilterId?.value
            ? this.dynamicGroupsService.getFilterById(
                this.newHireFilterId?.value,
              )
            : of(undefined);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (gf: Filter | undefined) => {
          this.selectedGroup = gf;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.GET_JOB_ROLE_SETTINGS,
            err,
          );
        },
      });
  }

  private initializeForm(jrs: JobRoleSettings): void {
    this.unitOptions = new Map(Object.entries(jrs.units));
    this.fieldOptions = new Map(Object.entries(jrs.fields));
    this.currentSettings = new Map(Object.entries(jrs.dueDates));

    for (let [key, value] of this.currentSettings) {
      const validateFieldControl = key === 'newHireFilter';
      const formGroup = this.createFormGroup(value, validateFieldControl);
      this.jobRoleSettingsForm.addControl(key, formGroup);
      this.setupValueChanges(key, formGroup, validateFieldControl);
    }
  }

  private createFormGroup(
    value: JobRoleSetting,
    validateFieldControl: boolean,
  ): FormGroup<JobRoleSettingForm> {
    const group = this.formBuilder.group({
      isActive: [value.isActive],
      value: [value.value ?? null, Validators.min(1)],
      unit: [value.unit ?? null],
      field: [value.field ?? null],
      filterId: [value.filterId ?? null],
    });

    this.addValidators(group, value.isActive, validateFieldControl);
    return group;
  }

  private setupValueChanges(
    key: string,
    formGroup: FormGroup,
    validateFieldControl: boolean,
  ): void {
    formGroup.get('isActive')?.valueChanges.subscribe((isActive) => {
      this.addValidators(formGroup, isActive, validateFieldControl);
      if (key === 'newHire' && isActive) {
        this.jobRoleSettingsForm
          .get('newHireFilter.isActive')
          ?.setValue(isActive);
      }

      if (key === 'newHireFilter' && !isActive) {
        this.jobRoleSettingsForm.get('newHire.isActive')?.setValue(isActive);
      }
    });
  }

  private addValidators(
    formGroup: FormGroup,
    isActive: boolean,
    validateFieldControl: boolean,
  ): void {
    const valueControl = formGroup.get('value');
    const unitControl = formGroup.get('unit');
    const fieldControl = formGroup.get('field');

    if (isActive) {
      valueControl?.setValidators([Validators.required, Validators.min(1)]);
      unitControl?.setValidators(Validators.required);
      if (validateFieldControl) {
        fieldControl?.setValidators(Validators.required);
      }
    } else {
      valueControl?.clearValidators();
      unitControl?.clearValidators();
      fieldControl?.clearValidators();
    }

    valueControl?.updateValueAndValidity();
    unitControl?.updateValueAndValidity();
    fieldControl?.updateValueAndValidity();
  }

  openGroupFiltersModal() {
    const modalRef = this.ngbModal.open(GroupFiltersModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.modalTitle = 'assign new hires filter';
    modalRef.componentInstance.filterId = this.newHireFilterId?.value;
    modalRef.componentInstance.selectedFilterChange.subscribe((f: Filter) => {
      this.selectedGroup = f;
      this.newHireFilterId?.setValue(this.selectedGroup?.id);
      this.newHireFilterId?.markAsTouched();
    });
  }

  updateJobRoleSettings() {
    const value = this.jobRoleSettingsForm.value as JobRoleSettingsPost;
    this.jobRoleService.updateJobRoleSettingsDueDates(value).subscribe({
      next: (jrs: JobRoleSettings) => {
        this.currentSettings = new Map(Object.entries(jrs.dueDates));
        this.uiService.showToast(`Successfully updated job role settings`, {
          classname: 'bg-success text-light',
          delay: 3000,
        });
        this.jobRoleSettingsForm.markAsPristine();
        this.jobRoleSettingsForm.markAsUntouched();
        this.auditService.updateAuditHistory.next(true);
      },
      error: (err) => {
        this.alertService.createAlert2(
          this.exceptionData.GET_JOB_ROLE_SETTINGS,
          err,
        );
      },
    });
  }

  backToJobRoles() {
    this.router.navigate([`job-roles-list`]);
  }

  get newHireFilterId() {
    return this.jobRoleSettingsForm?.get('newHireFilter.filterId');
  }

  deleteGroupFilter() {
    this.selectedGroup = undefined;
    this.newHireFilterId?.setValue(null);
    this.newHireFilterId?.markAsTouched();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
