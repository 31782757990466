<div class="card shadow">
  <div *ngIf="vm$ | async as vm" class="card-body">
    <div class="tree-filter-wrapper">
      <div>
        <div class="d-flex align-items-center">
          <h6 class="me-4">Select a dimension</h6>
          <ug-dimension-buttons
            [skillDimension]="vm.skillDimensions"
            (dimensionSelectedEvent)="dimensionSelected($event)"
          ></ug-dimension-buttons>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-check form-switch form-control-lg">
          <input
            class="form-check-input hr45"
            type="checkbox"
            role="switch"
            id="inactive"
            [checked]="isActive"
            (change)="active()"
          />
          <label class="form-check-label" for="inactive"
            >{{ isActive ? 'Hiding inactive' : 'Showing inactive' }}
          </label>
        </div>

        <div class="form-check form-switch form-control-lg">
          <input
            class="form-check-input hr45"
            type="checkbox"
            role="switch"
            id="expand-collapse"
            [checked]="expanded"
            (change)="expandCollapseTreeview()"
          />
          <label class="form-check-label" for="expand-collapse"
            >{{ !expanded ? 'Collapse all' : 'Expand all' }}
          </label>
        </div>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="tree-content scroll">
        <div class="row p-2">
          <div class="col-9">
            <ug-text-filter-control
              (filterTextChange)="onFilterTextChange($event)"
              [filterText]="subjectFilterText"
              [placeholderText]="'Begin typing to filter'"
            >
            </ug-text-filter-control>
          </div>
          <div class="col">
            <div class="float-end">
              <button type="button" class="btn esqepBtn" (click)="addTopNode()">
                Create Subject
              </button>
            </div>
          </div>
        </div>

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodePadding
            (click)="activeNode = node; expandDetails(node)"
            [class.background-highlight]="activeNode === node"
          >
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <div class="d-flex align-items-center">
              <span class="highlight">{{ node.name }}</span>
              <span
                *ngFor="let dim of node.dimensionIds"
                class="badge rounded-pill ms-2 text-dark"
                [class.application-badge]="dim === 1"
                [class.behaviour-badge]="dim === 2"
                [class.knowledge-badge]="dim === 3"
                [class.training-badge]="dim === 4"
                placement="top"
                [ngbTooltip]="
                  dim === 1
                    ? 'Application'
                    : dim === 2
                      ? 'Behaviour'
                      : dim === 3
                        ? 'Knowledge'
                        : 'Training'
                "
                >{{
                  dim === 1 ? 'A' : dim === 2 ? 'B' : dim === 3 ? 'K' : 'T'
                }}</span
              >
            </div>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node
            (click)="activeNode = node"
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
            matTreeNodeToggle
            [class.background-highlight]="activeNode === node"
          >
            <button
              mat-icon-button
              [attr.aria-label]="'Toggle ' + node.name"
              (click)="expandDetails(node)"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
                }}
              </mat-icon>
            </button>
            <div class="d-flex align-items-center">
              <span class="highlight" (click)="expandDetails(node)">
                {{ node.name }}</span
              >
              <span
                *ngFor="let dim of node.dimensionIds"
                class="badge rounded-pill ms-2 text-dark"
                [class.application-badge]="dim === 1"
                [class.behaviour-badge]="dim === 2"
                [class.knowledge-badge]="dim === 3"
                [class.training-badge]="dim === 4"
                placement="top"
                [ngbTooltip]="
                  dim === 1
                    ? 'Application'
                    : dim === 2
                      ? 'Behaviour'
                      : dim === 3
                        ? 'Knowledge'
                        : 'Training'
                "
                >{{
                  dim === 1 ? 'A' : dim === 2 ? 'B' : dim === 3 ? 'K' : 'T'
                }}</span
              >
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
      <div class="add-node-wrapper">
        <div class="skill-subject-content">
          <ng-container *ngIf="subject && !add; else emptyNode">
            <div>
              <ug-skillsubject
                (deleteConfirmed)="delete($event)"
                [subjectLevels]="vm.subjectLevels"
                (editedDetails)="editedDetails($event)"
                [parentSubject]="parentSubject"
                [skillSubject]="subject"
                [skillDimensions]="vm.skillDimensions"
                [subjectLevels]="vm.subjectLevels"
                [selectedSubjectDescendants]="selectedSubjectDescendants"
                (addChildSubject)="addChildSubject($event)"
              ></ug-skillsubject>
            </div>
          </ng-container>
          <ng-container *ngIf="add">
            <div>
              <ug-skillsubject
                [parentSubject]="parentSubject"
                (editedDetails)="editedDetails($event)"
                [skillSubject]="subject"
                [skillDimensions]="vm.skillDimensions"
                [subjectLevels]="vm.subjectLevels"
                [newNode]="add"
                (addChildSubject)="addChildSubject($event)"
                [selectedSubjectDescendants]="selectedSubjectDescendants"
              ></ug-skillsubject>
            </div>
          </ng-container>
          <ng-template #emptyNode>
            <div *ngIf="!add" class="empty-skill-subject">
              <div>Select an item from the tree to display its values.</div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="subject && !add && vm.skillDimensions">
          <ug-subject-assigned-skills
            [subject]="subject"
            [subjectId]="subject.id"
            [skillDimensions]="vm.skillDimensions"
            [dimensionId]="selectedDimensionId"
            (skillsUpdatedEvent)="skillsUpdated($event)"
          ></ug-subject-assigned-skills>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
