import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterBase } from '../filter-base';

@Component({
  selector: 'ug-filter',
  templateUrl: './dynamic-form-filter.component.html',
  styleUrls: ['./dynamic-form-filter.component.scss'],
})
export class DynamicFormFilterComponent implements OnInit {
  @Input() filter!: FilterBase<string>;
  @Input() form!: FormGroup;
  @Input() rowIndex: number;

  protected showUnassigned: boolean = false;

  ngOnInit() {
    if (this.filter.typeaheadSubject || this.filter.callback) {
      this.form.get(this.filter.key).valueChanges.subscribe((v) => {
        this.filter?.inputChange(v);
      });
    }
  }
}
