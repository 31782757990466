<div [formGroup]="parentFormGroup.get('details')">
  <div class="row">
    <div class="col-6">
      <label for="projectName" class="form-label">Project Name</label>
      <input
        type="text"
        class="form-control"
        id="projectName"
        name="projectName"
        formControlName="projectName"
      />
    </div>
    <div class="col-3">
      <label for="projectCode" class="form-label">Project Code/Category</label>
      <input
        type="text"
        class="form-control"
        id="projectCode"
        name="projectCode"
        formControlName="projectCode"
      />
    </div>
    <div class="col-3">
      <label for="projectLocation" class="form-label">Project Location</label>
      <input
        type="text"
        class="form-control"
        id="projectLocation"
        name="projectLocation"
        formControlName="projectLocation"
      />
    </div>
  </div>
  <div class="d-flex mt-3">
    <div class="me-5">
      <label for="startDate" class="form-label">Start Date</label>
      <div class="input-group">
        <input
          id="startDate"
          type="text"
          ngbDatepicker
          #d="ngbDatepicker"
          formControlName="startDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="startDate"
        />
        <button
          class="btn btn-outline-secondary fas fa-calendar"
          (click)="d.toggle()"
          type="button"
        ></button>
      </div>
    </div>
    <div>
      <label for="endDate" class="form-label">End Date</label>
      <div class="input-group">
        <input
          id="endDate"
          type="text"
          ngbDatepicker
          #d2="ngbDatepicker"
          formControlName="endDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="endDate"
        />
        <button
          class="btn btn-outline-secondary fas fa-calendar"
          (click)="d2.toggle()"
          type="button"
        ></button>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <label for="projectDescription" class="form-label"
      >Project Description</label
    >
    <textarea
      id="projectDescription"
      name="projectDescription"
      class="form-control w-100"
      rows="3"
      placeholder="Add a description for the project..."
      formControlName="projectDescription"
    ></textarea>
  </div>
</div>
