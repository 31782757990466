<div class="d-flex justify-content-between w-75">
  <div>
    <h4>Required Roles</h4>
    <div
      *ngFor="
        let jr of parentFormGroup.get('projectRoles.assignedJobRoles.jobRoles')
          .value
      "
    >
      {{ jr.quantity }} x {{ jr.jobRoleName }}
    </div>
  </div>
  <div>
    <h4>Required Competencies</h4>
    <div
      *ngFor="
        let comp of parentFormGroup.get(
          'projectCompetencies.assignedCompetencies.competencies'
        ).value
      "
    >
      {{ comp.discipline }} - {{ comp.skillName }}
    </div>
  </div>
  <div>
    <h4>Project Members</h4>
    <div
      *ngFor="
        let person of parentFormGroup.get(
          'projectMembers.assignedMembers.projectMembers'
        ).value
      "
    >
      {{ person.name }} - {{ person.role }}
    </div>
  </div>
</div>
