import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, finalize, map, switchMap, takeUntil } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { ConfigSetting } from '../config-setting';
import { OrgUnit } from '../models/org-unit';
import { OrgUnitService } from '../services/org-unit.service';

@Component({
  selector: 'ug-skills-matrix-division',
  templateUrl: './skills-matrix-division.component.html',
  styleUrls: [],
})
export class SkillsMatrixDivisionComponent implements OnInit, OnDestroy {
  private orgUnitService = inject(OrgUnitService);
  private alertService = inject(AlertService);

  private ngUnsubscribe: Subject<boolean> = new Subject();
  loadingData = false;
  csodDivsList: OrgUnit[] = [];
  configSettingsList: ConfigSetting[] = [];
  csodDisplayDivsList: OrgUnit[] = [];
  updateInProgress = false;

  exceptionData = {
    IMPORT_DIV_LIST: {
      level: AlertLevels.ERROR,
      code: 'DIV-001',
      message: 'Error retrieving import divisions list',
    } as AlertData,
    IMPORT_DIV_CREATE: {
      level: AlertLevels.ERROR,
      code: 'DIV-002',
      message: 'Error adding import division',
    } as AlertData,
    IMPORT_DIV_DELETE: {
      level: AlertLevels.ERROR,
      code: 'DIV-003',
      message: 'Error deleting import division',
    } as AlertData,
  };

  ngOnInit(): void {
    this.orgUnitService
      .getExtOrgUnitsByType('Division')
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap((oud) =>
          this.orgUnitService.getConfigSettingsByKey('CSOD-DIVS').pipe(
            map((cs) => [oud, cs]),
            finalize(() => (this.loadingData = false)),
          ),
        ),
      )
      .subscribe({
        next: (res) => {
          this.csodDivsList = res[0] as Array<OrgUnit>;
          this.csodDivsList.sort((a, b) => {
            return a.externalId < b.externalId ? -1 : 1;
          });
          this.configSettingsList = res[1] as Array<ConfigSetting>;
          this.csodDisplayDivsList = this.buildDisplayDivsList();
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.IMPORT_DIV_LIST,
            err,
          );
        },
      });
  }

  private buildDisplayDivsList(): OrgUnit[] {
    return this.csodDivsList.filter((cd) => {
      return (
        this.configSettingsList.findIndex(
          (cs) => cs.configValue === cd.externalId,
        ) === -1
      );
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onAddClick(divIndex: number) {
    this.updateInProgress = true;
    const newConfigSetting = {
      configKey: 'CSOD-DIVS',
      configValue: this.csodDisplayDivsList[divIndex].externalId,
      id: -1,
      description: this.csodDisplayDivsList[divIndex].name,
      lastModified: new Date(),
      rv: 'dummy',
    } as ConfigSetting;

    this.orgUnitService
      .addConfigSetting(newConfigSetting)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.updateInProgress = false)),
      )
      .subscribe({
        next: (cs) => {
          this.configSettingsList.push(cs);
          this.csodDisplayDivsList = this.buildDisplayDivsList();
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.IMPORT_DIV_CREATE,
            err,
          );
        },
      });
  }

  onRemoveClick(importDivIndex) {
    this.updateInProgress = true;

    this.orgUnitService
      .deleteConfigSetting(this.configSettingsList[importDivIndex].id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.updateInProgress = false)),
      )
      .subscribe({
        next: () => {
          this.configSettingsList.splice(importDivIndex, 1);
          this.csodDisplayDivsList = this.buildDisplayDivsList();
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.IMPORT_DIV_DELETE,
            err,
          );
        },
      });
  }
}
