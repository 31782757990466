import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { SpinnerService } from '../../controls/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptor implements HttpInterceptor {
  private spinnerService = inject(SpinnerService);
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req instanceof HttpRequest) {
      this.spinnerService.add(req.url);
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.spinnerService.remove(req.url);
      }),
    );
  }
}
