<div class="card h-100 shadow">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between w-100 mb-2">
          <h5 class="fw-bold">My Actions</h5>
          <button class="btn btn-link mb-1 p-0" (click)="onRefreshClick()">
            <span class="fas fa-sync"></span>
          </button>
        </div>
      </div>
    </div>
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="claimActivitySummary?.length"
    ></ug-list-status>
    <div *ngIf="!isLoading" class="list-group">
      <h5>Competency Assessment Requests</h5>
      <a
        href="#"
        class="list-group-item list-group-item-action"
        id="createSubmission"
        (click)="onNewSubmissionClick()"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Create a new submission"
      >
        <h6>Create new</h6>
      </a>
      <a
        href="#"
        class="list-group-item list-group-item-action"
        [ngClass]="{ disabled: category.claimList.length < 1 }"
        *ngFor="let category of claimantActivitySummary; let i = index"
        (click)="onActivitySummaryItemClick(category['index'])"
        data-toggle="tooltip"
        data-placement="bottom"
        [title]="category.categoryDescription"
      >
        <h6 class="d-flex justify-content-between align-items-center">
          <span
            >{{ category.categoryName }}
            <small
              >({{
                category.claimList.length
                  | i18nPlural: categoryItemCountMessageMap
              }})</small
            ></span
          >
          <span
            *ngIf="category.notificationClaimIdList.length > 0"
            class="badge bg-danger"
            >{{ category.notificationClaimIdList.length }}</span
          >
        </h6>
      </a>
      <div *ngIf="isMinSrAssessor && verifierActivitySummary.length > 0">
        <h5 class="pt-3">Competency Assessment Approvals</h5>
        <a
          href="#"
          class="list-group-item list-group-item-action"
          [ngClass]="{ disabled: category.claimList.length < 1 }"
          *ngFor="let category of verifierActivitySummary; let i = index"
          (click)="onActivitySummaryItemClick(category['index'])"
          data-toggle="tooltip"
          data-placement="bottom"
          [title]="category.categoryDescription"
        >
          <h6 class="d-flex justify-content-between align-items-center">
            <span
              >{{ category.categoryName }}
              <small
                >({{
                  category.claimList.length
                    | i18nPlural: categoryItemCountMessageMap
                }})</small
              ></span
            >
            <span
              *ngIf="category.notificationClaimIdList.length > 0"
              class="badge bg-danger"
              >{{ category.notificationClaimIdList.length }}</span
            >
          </h6>
        </a>
      </div>
    </div>
  </div>
</div>
