import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  CompetenciesService,
  SubjectDictionary,
  SubjectLevels,
} from '../../competencies/services/competencies.service';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { TableHeader } from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { EventsService } from '../../events/services/events.service';
import { ToFirstCharUpperCasePipe } from '../../pipes/to-first-char-upper-case.pipe';
import {
  SkillDimension,
  SkillService,
} from '../../skill/services/skill.service';

@Component({
  selector: 'ug-skill-modal',
  templateUrl: './skill-modal.component.html',
  styleUrls: ['./skill-modal.component.scss'],
})
export class SkillModalComponent implements OnInit, OnDestroy {
  modal = inject(NgbActiveModal);
  private alertService = inject(AlertService);
  private eventService = inject(EventsService);
  private fb = inject(FormBuilder);
  private competenciesService = inject(CompetenciesService);
  private skillService = inject(SkillService);

  exceptionData = {
    SKILLS_LIST: {
      level: AlertLevels.ERROR,
      code: 'EVC-001',
      message: 'Error retrieving skills',
    } as AlertData,
    SKILL_DIMS: {
      level: AlertLevels.ERROR,
      code: 'EVC-002',
      message: 'Error retrieving skill dimensions',
    } as AlertData,
    SUBJECT_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'EVC-003',
      message: 'Error retrieving subject levels',
    } as AlertData,
    SUBJECT_DICTIONARY: {
      level: AlertLevels.ERROR,
      code: 'EVC-004',
      message: 'Error retrieving subject list',
    } as AlertData,
  };

  tableHeaders: Array<TableHeader> = [
    {
      id: 'name',
      title: 'Skill',
      class: 'w-30',
    },
    { id: 'description', title: 'Description', class: 'w-50' },
  ];
  tableRows = [];
  tableFilters = [];
  tableSelectedButtons = [];
  @Input() skillCount: number;
  @Input() tablePageSize: number;
  @Input() tablePageIndex: number;

  dimensionsForm: FormGroup;
  skillDimensions: Array<SkillDimension>;
  subjectsFilterForm: FormGroup;
  collapseFilters = false;

  ngUnsubscribe: Subject<boolean> = new Subject();
  subjectMap = new Map<number, any>();
  subjectLevels: Array<SubjectLevels>;

  @Input() rowsChangedSubject: Subject<[]> = new Subject<[]>();
  @Input() modalTitle: string;
  @Output() skillsSelected: EventEmitter<Array<any>> = new EventEmitter<
    Array<any>
  >();
  @Output() skillsFilters: EventEmitter<{
    dimensionId: number;
    subjectId: number;
  }> = new EventEmitter<{ dimensionId: number; subjectId: number }>();
  @Input() tableLoading: boolean;
  @Input() pagedApi: boolean = false;
  @Output() paginatedDataEvent = new EventEmitter<any>();
  @Input() dimensionIdsToInclude: number[] = [1, 2, 3, 4];
  @Input() additionalHeaders: Array<TableHeader> = [];
  @Input() existsKey: string;

  paginatedTableFilterData: {
    filter: string;
    order: string;
    page: number;
    pageSize: number;
  } = {
    filter: '',
    order: '',
    page: 1,
    pageSize: 10,
  };

  termSubject = new Subject();
  skillSearchEvent = new EventEmitter<any>();

  get subjectsArray(): FormArray {
    return this.subjectsFilterForm.get('subjectLevels') as FormArray;
  }

  get dimensionId() {
    return this.dimensionsForm.get('dimensionId').value;
  }

  ngOnInit(): void {
    this.rowsChangedSubject.subscribe((x) => {
      this.tableRows = [...x];
    });

    this.tableFilters = !this.pagedApi
      ? [
          new TextboxFilter({
            key: 'searchTerm',
            label: 'Search competencies',
            order: 1,
            placeholder: 'Start typing to filter the results',
          }),
        ]
      : [
          new TextboxFilter({
            key: 'searchTerm',
            label: 'Filter by name',
            callback: this.skillSearch,
            order: 1,
            placeholder: 'Filter by name',
          }),
        ];

    this.tableSelectedButtons = [
      {
        title: 'Cancel',
        class: 'btn-outline-secondary',
        cancelButton: true,
      },
      {
        title: 'Add Selected',
        class: 'btn-outline-success',
        function: this.addSelected,
      },
    ];

    this.dimensionsForm = this.fb.group({
      dimensionId: [''],
    });

    this.subjectsFilterForm = this.fb.group({
      subjectLevels: this.fb.array([]),
    });

    this.dimensionsForm.valueChanges.subscribe((df) => {
      this.tableLoading = true;
      this.subjectsArray.controls.forEach((control) => {
        const idValue = control.get('id').value;
        const subjectId = control.get('subjectId');
        subjectId.setValue(null, { emitEvent: false });

        if (idValue > 1 && subjectId.enabled) {
          subjectId.disable({ emitEvent: false });
        }
      });
      this.skillsFilters.next({
        dimensionId: this.dimensionId,
        subjectId: null,
      });
    });

    this.skillService
      .getSkillDimensions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (cd: SkillDimension[]) => {
          this.skillDimensions = cd.filter((sd) =>
            this.dimensionIdsToInclude.includes(sd.id),
          );
          this.dimensionsForm
            .get('dimensionId')
            .setValue(this.skillDimensions[0].id);
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.SKILL_DIMS, err);
        },
      });

    this.competenciesService.getSubjectLevels().subscribe({
      next: (subjectLevels: SubjectLevels[]) => {
        this.subjectLevels = subjectLevels;

        this.subjectLevels.forEach((sl) => {
          const formGroup = this.fb.group({
            id: sl.id,
            name: sl.name,
            subjectMapKey: sl.id === 1 ? 0 : null,
            subjectId: null,
          });

          sl.id > 1
            ? formGroup.get('subjectId').disable({ emitEvent: false })
            : false;

          this.subjectsArray.push(formGroup);

          this.watchFormGroup(formGroup);
        });
      },
      error: (err) => {
        this.alertService.createAlert2(this.exceptionData.SUBJECT_LEVELS, err);
      },
    });

    this.competenciesService.getSubjectsDictionary().subscribe({
      next: (sd: SubjectDictionary) => {
        for (const key in sd) {
          if (sd.hasOwnProperty(key)) {
            const values = sd[key].filter((s) => s.isActive);
            this.subjectMap.set(Number(key), values);
          }
        }
      },
      error: (err) => {
        this.alertService.createAlert2(
          this.exceptionData.SUBJECT_DICTIONARY,
          err,
        );
      },
    });
  }

  watchFormGroup(formGroup: FormGroup) {
    formGroup.valueChanges.subscribe((vc) => {
      this.subjectsArray.controls.forEach((control) => {
        const idValue = control.get('id').value;
        const subjectId = control.get('subjectId');

        if (idValue > vc.id && subjectId.enabled) {
          subjectId.setValue(null, { emitEvent: false });
          subjectId.disable({ emitEvent: false });
        }
      });

      const curIndex = this.subjectsArray.value.findIndex(
        (s) => s.id === vc.id,
      );

      if (vc.subjectId) {
        this.skillsFilters.next({
          dimensionId: this.dimensionId,
          subjectId: vc.subjectId,
        });
        this.enableNextSubjectControl(curIndex, vc.subjectId);
      } else {
        this.getSkillsForPreviousSubject(curIndex);
      }
    });
  }

  enableNextSubjectControl(curIndex: number, subjectId: number) {
    const nextControl = this.subjectsArray.at(curIndex + 1);
    if (!nextControl) {
      return;
    }

    nextControl.get('subjectId').enable({ emitEvent: false });
    nextControl.get('subjectMapKey').setValue(subjectId);
  }

  getSkillsForPreviousSubject(curIndex: number) {
    if (curIndex === 0) {
      this.skillsFilters.next({
        dimensionId: this.dimensionId,
        subjectId: null,
      });
      return;
    }

    const previousSubjectControl = this.subjectsArray.at(curIndex - 1);
    const prevSubjectId = previousSubjectControl.get('subjectId').value;
    this.skillsFilters.next({
      dimensionId: this.dimensionId,
      subjectId: prevSubjectId,
    });
  }

  activeIcon = (row) => {
    return row[this.existsKey] === true
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  addSelected = (selected) => {
    this.skillsSelected.next(selected);
    this.modal.close();
  };

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  updateData(
    pageNumber?: number,
    pageSize?: number,
    sort?: { column: string; sortDirection: string },
  ) {
    const dataToPaginate = this.paginatedTableFilterData;
    const toFirstCharUpperCase = new ToFirstCharUpperCasePipe();

    if (pageNumber) {
      dataToPaginate.page = pageNumber;
    }
    if (pageSize) {
      dataToPaginate.pageSize = pageSize;
    }
    if (sort) {
      const col = toFirstCharUpperCase.transform(sort.column);
      dataToPaginate.order = `${col} ${sort.sortDirection}`;
    }

    this.paginatedDataEvent.emit(dataToPaginate);
  }

  skillSearch = (searchTerm) => {
    searchTerm
      .pipe(distinctUntilChanged(), debounceTime(5000))
      .subscribe((term) => {
        this.skillSearchEvent.emit(term);
      });
  };
}
