import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ExceptionTypes } from '../exception/exception.component';

@Injectable({
  providedIn: 'root',
})
export class AzureAuthAllowedOrgInterceptor implements HttpInterceptor {
  private router = inject(Router);


  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        // If there's a 403 error means the user isn't allowed to access the app.
        error: (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.router.navigate([
              'exception',
              ExceptionTypes[ExceptionTypes.NOT_CUSTOMER],
            ]);
          }
        },
      })
    );
  }
}
