<div class="card" [formGroup]="formGroup">
  <div class="card-header p-b border-0 bg-transparent">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        [attr.id]="settingKey + 'Active'"
        formControlName="isActive"
      />
      <label
        class="form-check-label fw-bold"
        [attr.for]="settingKey + 'Active'"
        >{{ settingSwitchText }}</label
      >
    </div>
  </div>

  <div class="card-body py-0" [ngbCollapse]="!formGroup.get('isActive').value">
    <h2 class="h5 fw-bold">{{ settingTitle }}</h2>

    <ng-container *ngIf="settingKey !== 'newHireFilter'; else newHireFilter">
      <ug-due-date-control
        [formGroup]="formGroup"
        [controlText]="controlText"
        [controlTitle]="controlTitle"
        [unitOptions]="unitOptions"
        [fieldOptions]="fieldOptions"
      ></ug-due-date-control>
    </ng-container>

    <ng-template #newHireFilter>
      <div class="row mb-3">
        <div class="col-7">
          <p class="p-0 m-0">
            Assign an existing Group to assist with capturing new hires. If a
            Group is not assigned, a new hire will be identified accross the
            organisation from the below criteria only.
          </p>
          <div class="my-2">
            <button
              type="button"
              class="btn esqepBtn me-3"
              (click)="onAssignButtonClick()"
            >
              Assign Group
            </button>
          </div>
        </div>
        <div class="col-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h3 class="h5 mb-0">Assigned Group</h3>
              <button
                *ngIf="selectedGroup"
                class="btn btn-outline-danger"
                (click)="onDeleteButtonClick()"
              >
                Delete
              </button>
            </div>
            <div class="card-body">
              <ng-container *ngIf="selectedGroup; else noGroupAssigned">
                <div>Name: {{ selectedGroup?.name }}</div>
                <div>Description: {{ selectedGroup?.description }}</div>
              </ng-container>
              <ng-template #noGroupAssigned>
                No Group Has Been Assigned
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ug-due-date-control
        [formGroup]="formGroup"
        [controlText]="controlText"
        [controlTitle]="controlTitle"
        [unitOptions]="unitOptions"
        [fieldOptions]="fieldOptions"
      ></ug-due-date-control>
    </ng-template>
  </div>
</div>
