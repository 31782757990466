<div class="card shadow">
  <div class="card-body">
    <div *ngIf="currentSession" class="w-100">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4>{{ currentSession.title }}</h4>
        <div class="d-flex me-5">
          <ug-date-time-session-display
            [startDate]="currentSession.startDate"
            [endDate]="currentSession.endDate"
            [customClass]="'h5'"
          ></ug-date-time-session-display>
        </div>
      </div>
      <div class="d-flex justify-content-between me-5 mb-2">
        <div>
          <span class="fas fa-chalkboard-teacher me-1"></span> Trainers :
          <ng-container *ngIf="instructors?.length > 0; else noAssessors">
            <span>{{ instructors }}</span>
          </ng-container>
          <ng-template #noAssessors>
            <span>n/a</span>
          </ng-template>
        </div>
        <div>Location: {{ currentSession.location }}</div>
      </div>
    </div>

    <ng-container *ngIf="!viewCompletedAssessment">
      <ng-container *ngIf="selectedAttendees; else noAttendeesSelected">
        <form [formGroup]="bulkAssessmentForm">
          <div class="table-responsive">
            <table class="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <ng-container
                    *ngIf="
                      competenciesForEvent && competenciesForEvent.length;
                      else emptyHeading
                    "
                  >
                    <th scope="col">Competencies</th>
                  </ng-container>
                  <ng-template #emptyHeading>
                    <th scope="col"></th>
                  </ng-template>
                  <th>
                    <div class="form-check" style="min-width: 125px">
                      <input
                        [ngModelOptions]="{ standalone: true }"
                        class="form-check-input"
                        type="checkbox"
                        [(ngModel)]="selectAllChecked"
                        (change)="selectAll()"
                        id="selectAll"
                      />
                      <label for="selectAll" class="form-check-label"
                        >Select all</label
                      >
                    </div>
                  </th>
                  <th
                    scope="col"
                    *ngFor="let person of people.controls; let i = index"
                  >
                    <div
                      class="form-check center-selects"
                      style="min-width: 170px"
                    >
                      <input
                        class="form-check-input checkboxestocheck"
                        type="checkbox"
                        [formControl]="person.get('selected')"
                        (change)="selectAllChecked = false"
                      />
                      {{ person.value.displayName }}
                      <span
                        class="text-center"
                        [ngbTooltip]="
                          person.value.attended ? 'Attended' : 'Not Attended'
                        "
                        ><i
                          class="fas fa-sm"
                          [ngClass]="
                            person.value.attended
                              ? 'fa-check-circle text-success'
                              : 'fa-times-circle text-danger'
                          "
                        ></i
                      ></span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="people">
                <ng-container
                  *ngFor="let skill of competenciesForEvent; index as b"
                >
                  <tr>
                    <td style="min-width: 300px">
                      {{ skill.skillName }}
                      <span
                        *ngIf="skill.skillDescription.length > 0"
                        class="fas fa-solid fa-circle-info"
                        [ngbTooltip]="skill.skillDescription"
                      ></span>
                    </td>
                    <!-- start of select all -->
                    <td>
                      <select
                        class="form-select"
                        title="Level"
                        [disabled]="!peopleSelected()"
                        (change)="gradeAll($event, b)"
                      >
                        <option selected value="null">Select level...</option>
                        <option
                          *ngFor="
                            let level of claimLevelsMap.get(
                              skill.claimLevelSetId
                            )
                          "
                          [value]="level.key"
                        >
                          {{ level.name }}
                        </option>
                      </select>
                    </td>
                    <!-- end of select all -->
                    <ng-container
                      *ngFor="let person of people.controls; let a = index"
                    >
                      <td>
                        <select
                          class="form-select"
                          [formControl]="
                            person.get('skills').at(b).get('claimLevelId')
                          "
                          title="Level"
                        >
                          <option selected [ngValue]="null">
                            Select level...
                          </option>
                          <option
                            *ngFor="
                              let level of claimLevelsMap.get(
                                skill.claimLevelSetId
                              )
                            "
                            [ngValue]="level.key"
                          >
                            {{ level.name }}
                          </option>
                        </select>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
                <tr style="border-top: 3px solid rgb(87, 85, 85)">
                  <td></td>
                  <td style="text-align: right"><strong>Comments</strong></td>
                  <td *ngFor="let person of people.controls; index as c">
                    <textarea
                      class="form-control"
                      rows="1"
                      [formControl]="person.get('comments')"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right"><strong>Score</strong></td>
                  <td *ngFor="let person of people.controls">
                    <input
                      [ngClass]="{
                        'is-invalid': person.get('score').invalid,
                      }"
                      type="number"
                      [formControl]="person.get('score')"
                      name="score"
                      min="0"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right">
                    <strong>Assessment Outcome</strong>
                  </td>
                  <td *ngFor="let person of people.controls">
                    <select
                      [ngClass]="{
                        'is-invalid': person.get('trainingOutcomeId').invalid,
                      }"
                      class="form-select"
                      [formControl]="person.get('trainingOutcomeId')"
                      title="Asessment Outcome"
                    >
                      <option selected [ngValue]="null">
                        Select Assessment Outcome...
                      </option>
                      <option
                        *ngFor="let ao of finalAssessmentOptions"
                        [ngValue]="ao.id"
                      >
                        {{ ao.name }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- parking for now - issues with paginating the data -->
          <!-- <div
            *ngIf="competenciesForEvent?.length > initialPageSize"
            class="pagination-container d-flex justify-content-end align-items-center mt-2"
          >
            <div class="d-flex align-items-center me-3">
              <label for="rows" class="mx-2">Rows per page:</label>
              <select
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="pageSize"
                (ngModelChange)="onPageSizeChange($event)"
                class="rows-dropdown pe-1"
                id="rows"
              >
                <option *ngFor="let ps of pageSizeOptions" [ngValue]="ps">
                  {{ ps }}
                </option>
              </select>
            </div>
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="competenciesForEvent.length"
              [maxSize]="5"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
              (pageChange)="onPageChange($event)"
            ></ngb-pagination>
          </div> -->
          <div class="row mt-3">
            <div class="col-5 mb-3">
              <div class="d-flex">
                <p class="me-2">Expires in:</p>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="1m"
                    id="1m"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="expiresIn"
                    [value]="1"
                    (ngModelChange)="updateExpiryDate($event)"
                  />
                  <label class="form-check-label" for="1m">1 month</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="6m"
                    id="6m"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="expiresIn"
                    [value]="6"
                    (ngModelChange)="updateExpiryDate($event)"
                  />
                  <label class="form-check-label" for="6m">6 months</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="12m"
                    id="12m"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="expiresIn"
                    [value]="12"
                    (ngModelChange)="updateExpiryDate($event)"
                  />
                  <label class="form-check-label" for="12m">1 year</label>
                </div>
              </div>
              <div class="w-50">
                <label for="expiryDate">Expiry date</label>
                <input
                  [ngClass]="{
                    'is-invalid':
                      bulkAssessmentForm
                        .get('expiryDate')
                        .hasError('required') &&
                      bulkAssessmentForm.get('expiryDate').touched,
                  }"
                  class="form-control"
                  type="date"
                  formControlName="expiryDate"
                  id="expiryDate"
                  title="Expiry Date"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h4>Assessor Commentary</h4>
              <textarea
                class="form-control w-100 mb-2"
                rows="7"
                formControlName="comments"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-between">
          <button class="btn esqepBtn" (click)="backToEventsCalendar()">
            Return to calendar
          </button>
          <button
            [disabled]="bulkAssessmentForm.invalid"
            class="btn esqepBtn"
            (click)="postBulkAssessment()"
          >
            Save Assessment
          </button>
        </div>
      </ng-container>
      <ng-template #noAttendeesSelected>
        No attendees have been selected for this session. Return to the events
        calendar to select attendees for the bulk assessment
        <button (click)="backToEventsCalendar()" class="btn esqepBtn mx-2">
          Return
        </button>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="viewCompletedAssessment">
      <ug-bulk-assessment-completed
        [trainingSessionId]="currentSession.id"
      ></ug-bulk-assessment-completed>
    </ng-container>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
