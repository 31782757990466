<div class="card shadow overflow-visible">
  <div class="card-body">
    <div class="d-flex mb-1">
      <h6 class="fw-bold">{{ matrixSummaryTitle }}</h6>
      <div class="ms-auto">
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="openKeyModal()"
        >
          <span class="fas fa-xl fa-key" title="Show key"></span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="isForSummary">
      <div class="fst-italic mb-1">
        The matrix below only includes people directly assigned to the selected
        organisation unit.
      </div>
    </ng-container>
    <div *ngIf="fullFunc && !isForSummary" class="row mb-4">
      @if (orgUnitTypeForViewing | async; as out) {
        <ug-matrix-filters
          (updateMatrix)="updateMatrix($event)"
          [orgUnitType]="orgUnitType"
          [fullFunc]="fullFunc"
        ></ug-matrix-filters>
      }
    </div>

    <ug-list-status
      [dataType]="matrixType"
      [isLoading]="loadingData"
      [listLength]="matrixTabData.length"
      [emptyListMessage]="
        !canAccessOuData && isForSummary
          ? 'You do not have access to view the matrix for this Organisation Unit'
          : 'No ' + matrixType + ' assigned'
      "
      [isLoading]="loadingData"
    ></ug-list-status>

    <ng-container *ngIf="!loadingData && matrixTabData.length">
      <ng-container *ngIf="!isForSummary; else summaryTable">
        <ul class="nav nav-pills d-flex justify-content-evenly py-2">
          <li
            *ngFor="let tab of matrixTabData; let tabIndex = index"
            [disabled]="
              tab.groupedSkills.length + tab.ungroupedSkills.length === 0
            "
            class="nav-item max-tab-width"
          >
            <a
              class="text-center nav-link"
              [ngClass]="activeTab === tabIndex + 1 ? 'active' : ''"
              (click)="activeTab = tabIndex + 1"
              >{{ tab.name }} ({{
                tab.groupedSkills.length + tab.ungroupedSkills.length
              }})</a
            >
          </li>
        </ul>

        <ng-container *ngFor="let tab of matrixTabData; let tabIndex = index">
          <div class="w-100" *ngIf="activeTab === tabIndex + 1">
            <ug-matrix-table
              [stateSymbolMap]="stateSymbolMap"
              [matrixTab]="tab"
              [matrixPeople]="matrixPeople"
              [matrixType]="matrixType"
              [fullFunc]="fullFunc"
              [isManager]="isManager"
              [showUnassignedUsers]="showUnassignedUsers"
              [orgUnitType]="orgUnitType"
              (showUnassignedUsersEvent)="
                updateUnassignedUsersVisibility($event)
              "
            >
            </ug-matrix-table>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #summaryTable>
        <!-- <ug-matrix-table-filters
          (showUnassignedUsersEvent)="updateUnassignedUsersVisibility($event)"
        ></ug-matrix-table-filters> -->
        <ug-matrix-table
          [stateSymbolMap]="stateSymbolMap"
          [excludeLoggedInUser]="true"
          [matrixTab]="matrixForSummary"
          [matrixPeople]="matrixPeople"
          [matrixType]="matrixType"
          [fullFunc]="fullFunc"
          [isManager]="isManager"
          [showUnassignedUsers]="showUnassignedUsers"
          (showUnassignedUsersEvent)="updateUnassignedUsersVisibility($event)"
        >
        </ug-matrix-table>
      </ng-template>
    </ng-container>
  </div>
</div>
