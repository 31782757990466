import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { AuditFiltersComponent } from './audit-table/audit-filters/audit-filters.component';
import { AuditTableComponent } from './audit-table/audit-table.component';
import { AnonymizeDirective } from './directives/anonymize.directive';
import { GroupFiltersModalComponent } from './group-filters-modal/group-filters-modal.component';
import { ProgressModalComponent } from './progress-modal/progress-modal.component';
import { SkillModalComponent } from './skill-modal/skill-modal.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes/unsaved-changes.dialog.component';
import { CustomDateValidators } from './validations/CustomDateValidators';
import { CustomMinMaxValueValidators } from './validations/CustomMinMaxValueValidators';

@NgModule({
  declarations: [
    AnonymizeDirective,
    SkillModalComponent,
    ProgressModalComponent,
    GroupFiltersModalComponent,
    AuditTableComponent,
    UnsavedChangesDialogComponent,
    AuditFiltersComponent,
  ],
  imports: [
    CommonModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbCollapseModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AnonymizeDirective,
    GroupFiltersModalComponent,
    AuditTableComponent,
  ],
  providers: [CustomDateValidators, CustomMinMaxValueValidators],
})
export class SharedModule {}
