<div class="graph-section">
  <div class="text-end">
    <div class="btn-group" role="group" aria-label="toggle graph view">
      <button
        type="button"
        class="btn btn-link graph-toggle"
        (click)="toggleGraphType()"
      >
        <i
          [ngClass]="
            chartConfig.type === 'radar'
              ? 'fas fa-chart-bar'
              : 'fas fa-chart-area'
          "
        ></i>
      </button>
    </div>
  </div>
  <ug-chart [chartConfig]="chartConfig"></ug-chart>
</div>
