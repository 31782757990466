import { Component, Input, SimpleChanges } from '@angular/core';
import {
  BarSeriesOption,
  DataZoomComponentOption,
  ECharts,
  EChartsOption,
  LegendComponentOption,
  LineSeriesOption,
  YAXisComponentOption,
} from 'echarts';
import { ChartConfig, ChartData } from '../chart-config';

@Component({
  selector: 'ug-chart',
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss',
})
export class ChartComponent {
  @Input() chartConfig: ChartConfig;
  @Input() customSaveAsImage: boolean = false;
  @Input() chartClickCallback: (arg0?: any) => any;
  echartsInstance: ECharts;
  chartOption: EChartsOption;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartConfig) {
      this.initializeChartOption();
      const chartData = this.setupChartData();
      this.configureChartType(chartData, this.chartConfig.type);
      this.setChartOptions();

      this.chartOption = { ...this.chartOption };
    }
  }

  configureChartType(chartData: ChartData[], type: string) {
    switch (type) {
      case 'pie':
      case 'doughnut':
        this.configurePieDoughnutChart(chartData, type);
        break;
      case 'bar':
        this.configureBarChart(chartData);
        break;
      case 'radar':
        this.configureRadarChart(chartData);
        break;
    }
  }

  configureRadarChart(chartData: ChartData[]): void {
    const seriesNamesSet = new Set<string>();
    const radarDataMap = new Map();

    chartData.forEach((item) => {
      if (item.seriesName) {
        seriesNamesSet.add(item.seriesName);
      }

      if (!radarDataMap.has(item.name)) {
        radarDataMap.set(item.name, {
          name: item.name,
          value: [],
        });
      }

      radarDataMap.get(item.name).value.push(item.value);
    });

    const radarData = Array.from(radarDataMap.values());
    const indicators = Array.from(seriesNamesSet).map((name) => ({ name }));

    this.chartOption.radar = { indicator: indicators };

    this.chartOption.series = [
      {
        type: 'radar',
        data: radarData,
      },
    ];
  }

  configureBarChart(chartData: ChartData[]): void {
    const seriesMap = new Map();
    this.configureXAxis(chartData);
    this.configureYAxis();

    //if xaxis data is same as chart data, then it is a simple bar chart

    if (this.chartOption.xAxis['data'].length === chartData.length) {
      seriesMap.set('bar', {
        type: 'bar',
        data: chartData.map((item) => ({
          ...item,
          itemStyle: { color: item.colour },
        })),
        emphasis: {
          focus: 'series',
        },
      });
    } else {
      //if xaxis data is different from chart data, then it is has multiple series or multiple data points  per series
      chartData.forEach((item) => {
        if (!seriesMap.has(item.name)) {
          seriesMap.set(item.name, {
            name: item.name,
            type: item.type === 'line' ? 'line' : 'bar',
            data: [],
            ...(item.type === 'line'
              ? this.getLineSeriesOptions()
              : this.getBarSeriesOptions(item.colour)),
          });
        }
        seriesMap.get(item.name).data.push(item);
      });
    }

    this.chartOption.series = Array.from(seriesMap.values());
  }

  configurePieDoughnutChart(chartData: ChartData[], type: string) {
    const radius = type === 'pie' ? '50%' : ['30%', '50%'];
    this.chartOption.series[0] = {
      type: 'pie',
      radius: radius,
      data: chartData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      color: chartData.map((item) => item.colour),
    };

    if (this.chartConfig.centerLabelFormatter) {
      this.chartOption.series[0].label = {
        show: true,
        position: 'center',
        formatter: this.chartConfig.centerLabelFormatter,
      };
    }
  }

  setChartOptions() {
    this.chartOption.legend = this.getLegendOptions();
    this.chartOption.animation = !this.chartConfig.disableAnimation;
    this.chartOption.dataZoom = this.getDataZoomOptions();
    this.chartOption.tooltip['formatter'] = this.getTooltipFormatter();
  }

  getLegendOptions(): LegendComponentOption {
    if (this.chartConfig.hideLegend) {
      return { show: false };
    }

    const position = this.chartConfig.legendPosition;
    switch (position) {
      case 'bottom':
        return { orient: 'horizontal', top: 'bottom' };
      case 'right':
        Object.assign(this.chartOption.grid, { left: '5%', right: '30%' });
        return { type: 'scroll', orient: 'vertical', right: 0 };
      default:
        return {};
    }
  }

  getDataZoomOptions(): DataZoomComponentOption[] {
    return this.chartConfig.showDataSlider
      ? [
          {
            type: 'slider',
            height: 15,
            showDataShadow: false,
            showDetail: false,
            moveHandleSize: 5,
          },
          { type: 'inside' },
        ]
      : undefined; // Return undefined if the data slider is not enabled
  }

  getTooltipFormatter(): ($event: any) => string {
    if (this.chartConfig.customDataTooltip) {
      return (data) => {
        const seriesData =
          this.chartOption.series[data.seriesIndex].data[data.dataIndex];
        return this.chartConfig.customDataTooltip(seriesData);
      };
    }

    return this.chartConfig.tooltipFormatter;
  }

  setupChartData(): ChartData[] {
    return this.chartConfig.sort
      ? this.chartConfig.data.sort((a, b) => b.value - a.value)
      : this.chartConfig.data;
  }

  initializeChartOption(): void {
    this.chartOption = {
      grid: {
        containLabel: true,
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 0,
        top: 'center',
      },
      toolbox: {
        show: true,
        bottom: 0,
        feature: {
          saveAsImage: {
            show: this.customSaveAsImage ? false : true,
            title: '',
            name: this.chartConfig.title,
          },
        },
      },
      title: {
        text: this.chartConfig.title,
        show: true,
        left: 'center',
        textStyle: {
          fontSize: 16,
          width: 300,
          overflow: 'break',
          fontFamily: 'Roboto-Regular',
          fontWeight: 700,
        },
      },
      tooltip: {
        trigger: 'item',
      },

      series: [
        {
          data: [],
        },
      ],
    };
  }

  getLineSeriesOptions(): LineSeriesOption {
    return {
      symbol: 'line',
      symbolSize: 20,
      emphasis: { focus: 'series' },
      itemStyle: {
        borderWidth: 3,
        borderColor: 'red',
        color: 'red',
      },
      lineStyle: { width: 0 },
    };
  }

  getBarSeriesOptions(colour: string): BarSeriesOption {
    return {
      barGap: 0,
      emphasis: { focus: 'series' },
      itemStyle: { color: colour },
    };
  }

  configureXAxis(chartData) {
    const xAxisData: string = chartData
      .filter((item) => item[this.chartConfig.xAxisKey])
      .map((item) => item[this.chartConfig.xAxisKey]);

    this.chartOption.xAxis = {
      type: 'category',
      data: [...new Set(xAxisData)],
      axisLabel: { interval: 0, rotate: 60 },
    };
  }

  configureYAxis() {
    const axisOptions: YAXisComponentOption = {
      type: 'value',
      name: this.chartConfig.yAxis?.name,
      minInterval: 1,
      axisLine: { show: !this.chartConfig.yAxis?.hide },
      axisTick: { show: !this.chartConfig.yAxis?.hide },
      axisLabel: { show: !this.chartConfig.yAxis?.hide },
    };

    if (this.chartConfig.yAxis?.dualAxis) {
      this.chartOption.yAxis = [axisOptions, axisOptions];
    } else {
      this.chartOption.yAxis = [axisOptions];
    }
  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  onChartClick($event) {
    return this.chartConfig.chartClickCallback
      ? this.chartConfig.chartClickCallback($event)
      : null;
  }

  onLegendClick($event) {
    if (this.chartConfig.disableHideItemOnLegendClick) {
      this.echartsInstance.dispatchAction({
        type: 'legendSelect',
        name: $event.name,
      });
    }

    return this.chartConfig.legendClickCallback
      ? this.chartConfig.legendClickCallback($event)
      : null;
  }

  saveAsImage() {
    const a = document.createElement('a');
    a.download = `${this.chartConfig.title}.png`;
    a.href = this.echartsInstance.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
