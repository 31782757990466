<div class="card shadow">
  <div class="card-body">
    <ug-table
      [showPagination]="true"
      [tableHeaders]="tableHeaders"
      [tableHeaderButtons]="tableHeaderButtons"
      [tableRows]="tableRows"
      [tableRowButtons]="tableRowButtons"
      [tableFilters]="tableFilters"
      [isLoading]="tableLoading"
    ></ug-table>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
