import {
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { SortEvent } from '../../controls/table/sort-event';
import {
  SortableHeaderDirective,
  compare,
} from '../../controls/table/sortable-header.directive';
import { JobRoleAnalysisPerson } from '../job-role-analysis/job-role-analysis.component';
import { ScoreTrafficLight } from '../services/job-role.service';

@Component({
  selector: 'ug-role-competency-summary',
  templateUrl: './role-competency-summary.component.html',
  styleUrls: ['./role-competency-summary.component.scss'],
})
export class RoleCompetencySummaryComponent implements OnChanges {
  @Input() jobRolePerson: JobRoleAnalysisPerson;
  @Input() showGraph = false;
  hasPlottingData = false;

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  ngOnChanges(changes: SimpleChanges): void {
    this.hasPlottingData = !this.jobRolePerson.skills.every(
      (element) => element.claimLevelScore === null || element === null,
    );
    if (this.jobRolePerson) {
      this.onSort({ column: 'mandatory', direction: 'desc' });
      this.onSort({ column: 'personLevel', direction: 'asc' });
    }
  }

  meetsRequirements(
    roleScore: number,
    personScore: number,
    isMandatory: boolean,
  ): string {
    let classString = ScoreTrafficLight.success;

    if (personScore < roleScore) {
      if (isMandatory) {
        classString = ScoreTrafficLight.danger;
      } else {
        classString = ScoreTrafficLight.warning;
      }
    }

    return classString;
  }

  meetsReqClass(
    roleScore: number,
    personScore: number,
    isMandatory: boolean,
  ): string {
    let classString = '';

    if (personScore >= roleScore) {
      classString = 'far fa-xl fa-check-circle text-success fa-2x';
    }
    if (personScore < roleScore) {
      if (isMandatory) {
        classString = 'far fa-xl fa-times-circle text-danger fa-2x';
      } else {
        classString = 'far fa-xl fa-times-circle text-warning fa-2x';
      }
    }

    return classString;
  }

  onSort({ column, direction }: SortEvent, itemToSort?) {
    // resetting other headers
    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting
    if (direction === '' || column === '') {
      this.jobRolePerson.skills = this.jobRolePerson.skills;
    } else {
      // @ts-ignore
      this.jobRolePerson.skills = [...this.jobRolePerson.skills].sort(
        (a, b) => {
          if (column === 'personLevel') {
            const res = compare(
              this.meetsReqOrder(
                a.requirementLevelScore,
                a.claimLevelScore,
                a.requirementMandatory,
              ),
              this.meetsReqOrder(
                a.requirementLevelScore,
                a.claimLevelScore,
                a.requirementMandatory,
              ),
            );
            return direction === 'asc' ? res : -res;
          } else {
            const res =
              column === 'personLevel' || column === 'roleLevel'
                ? compare(a[column], b[column])
                : compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
          }
        },
      );
    }
  }

  meetsReqOrder(
    roleScore: number,
    personScore: number,
    isMandatory: boolean,
  ): number {
    if (personScore >= roleScore) {
      return 3; // Met requirement
    }
    if (isMandatory) {
      return 1; // Unmet mandatory requirement
    }

    return 2; // Unmet non-mandatory requirement
  }
}
