import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  TableHeader,
  TableRowButton,
} from '../../../controls/table/table.service';
import { ConfigService } from '../../../core/services/config.service';
import { SkillModalComponent } from '../../../shared/skill-modal/skill-modal.component';
import { MatrixSettingsService } from '../matrix-settings.service';
import { ICustomSkillWarning } from '../skill-warning';

@Component({
  selector: 'ug-training-amber',
  templateUrl: './training-amber.component.html',
  styleUrl: './training-amber.component.scss',
})
export class TrainingAmberComponent {
  @Input() skillType: string;
  @Input() set units(value: any) {
    const dropdownHeader = this.tableHeaders.find(
      (header) => header.id === 'expiringAmberUnit',
    );
    dropdownHeader.rowDropdowns = value;
  }
  @Input() hasAdminAccess: boolean = false;
  @Input() set skillList(value: any) {
    this.tableRows = [...value];
    this.originalSkillList = [...value];
    this.skillsToDelete = [];
    this.isLoading = false;
  }
  @Output() deleteSkillFromUpdateList = new EventEmitter<number>();
  @Output() selectedSkillsEvent = new EventEmitter<any>();
  @Output() updateSkillsEvent = new EventEmitter<
    ICustomSkillWarning | ICustomSkillWarning[]
  >();

  private ngbModal = inject(NgbModal);
  private matrixSettingsService = inject(MatrixSettingsService);
  private configService = inject(ConfigService);
  private originalSkillList = [];

  searchFilterData: {
    filter?: string;
    page: number;
    pageSize: number;
    order?: string;
    subjectId?: number;
    dimensionId: number;
    name?: string;
    types?: string[];
  } = {
    page: 1,
    pageSize: 10,
    dimensionId: 1,
  };

  skillSearchName: string;
  termSubject: Subject<string> = new Subject<string>();
  modalRef: any;
  tableRows = [];
  isLoading = true;
  skillsToDelete: any[] = [];

  updateSkill = (skill: ICustomSkillWarning, property) => {
    const changedSkill = this.originalSkillList.find(
      (sk) => sk.id === skill.id,
    );

    const isValueUnchanged =
      changedSkill && changedSkill[property] === skill[property];

    if (isValueUnchanged && changedSkill) {
      this.deleteSkillFromUpdateList.emit(skill.id);
    } else {
      this.updateSkillsEvent.emit(skill);
    }
  };

  skillUnitChanged = (skill) => {
    this.updateSkill(skill, 'expiringAmberUnit');
  };

  skillValueChanged = (skill) => {
    this.updateSkill(skill, 'expiringAmberValue');
  };

  hideDeleteButton = (skill) => {
    return this.skillsToDelete.includes(skill.id) || !this.hasAdminAccess;
  };

  tableHeaders: Array<TableHeader> = [
    { id: 'name', title: 'Training', class: 'w-30' },
    { id: 'description', title: 'Description', class: 'w-40' },
    {
      id: 'expiringAmberValue',
      title: 'Value',
      class: 'w-10',
      numberInput: true,
      disabledCondition: this.hideDeleteButton,
      numberInputFunction: this.skillValueChanged,
    },
    {
      id: 'expiringAmberUnit',
      title: 'Unit',
      class: 'w-15',
      dropdown: true,
      rowDropdowns: [],
      dropdownKeyValue: true,
      dropdownBindName: 'value',
      dropdownBindValue: 'key',
      disabledCondition: this.hideDeleteButton,
      dropdownFunction: this.skillUnitChanged,
      inputPlaceholder: 'Select Unit',
    },
  ];

  onDeleteSkillClick = (skill) => {
    this.skillsToDelete = [...this.skillsToDelete, skill.id];

    let skillToUpdate = {
      ...skill,
      expiringAmberValue: null,
      expiringAmberUnit: null,
    };

    this.updateSkillsEvent.emit(skillToUpdate);
  };

  onRestoreSkillClick = (skill) => {
    const index = this.skillsToDelete.findIndex((cid) => cid === skill.id);
    this.skillsToDelete.splice(index, 1);

    const originalSkill = this.originalSkillList.find(
      (sk) => sk.id === skill.id,
    );

    const skillListSkill = this.tableRows.find((sk) => sk.id === skill.id);
    skillListSkill.expiringAmberValue = originalSkill.expiringAmberValue;
    skillListSkill.expiringAmberUnit = originalSkill.expiringAmberUnit;

    this.deleteSkillFromUpdateList.emit(skill.id);
  };

  hideRestoreButton = (skill) => {
    return !this.skillsToDelete.includes(skill.id);
  };

  tableRowButtons: TableRowButton[] = [
    {
      title: 'Delete',
      class: 'btn-outline-danger',
      rowAction: this.onDeleteSkillClick,
      hideCondition: this.hideDeleteButton,
    },
    {
      title: 'Restore',
      class: 'btn-outline-secondary',
      rowAction: this.onRestoreSkillClick,
      hideCondition: this.hideRestoreButton,
    },
  ];

  ngOnInit() {}

  openSkillModal() {
    this.modalRef = this.ngbModal.open(SkillModalComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'xxl',
    });
    this.modalRef.componentInstance.pagedApi = true;
    this.modalRef.componentInstance.showDimensionFilters = false;
    this.modalRef.componentInstance.dimensionIdsToInclude =
      this.skillType === 'training' ? [4] : [1, 2, 3];
    this.searchFilterData.types =
      this.skillType === 'training'
        ? this.configService.jobRoleSkillSearchTypes
        : undefined;
    this.modalRef.componentInstance.modalTitle =
      this.skillType === 'training' ? 'Training Search' : 'Competency Search';
    this.modalRef.componentInstance.tableLoading = true;
    this.modalRef.componentInstance.existsKey = 'expiringAmberValue';
    this.modalRef.componentInstance.tableHeaders = [
      { id: 'name', title: 'Name', class: 'w-40' },
      {
        id: 'type',
        title: 'Type',
        class: 'w-10',
      },
      {
        id: 'provider',
        title: 'Provider',
        class: 'w-15',
      },
      {
        id: 'version',
        title: 'Version',
        class: 'w-5',
      },
      {
        id: 'expiringAmberValue',
        title: 'Expiring Amber Value',
        class: 'w-10',
      },
      {
        id: 'expiringAmberUnit',
        title: 'Expiring Amber Unit',
        class: 'w-10',
      },
    ];

    this.modalRef.componentInstance.paginatedDataEvent.subscribe((data) => {
      this.searchFilterData.page = data.page;
      this.searchFilterData.pageSize = data.pageSize;
      this.searchFilterData.order = data.order;
      this.getSkillsData();
    });

    this.modalRef.componentInstance.skillSearchEvent
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term) => {
        this.termSubject.next(term);
        this.searchFilterData.name = term;
        this.searchFilterData.page = 1;
        this.searchFilterData.pageSize = 10;
        this.searchFilterData.order = '';
        this.getSkillsData();
      });

    this.modalRef.componentInstance.skillsFilters.subscribe((sf) => {
      this.searchFilterData.subjectId = sf.subjectId;
      this.searchFilterData.dimensionId = sf.dimensionId;
      this.modalRef.componentInstance.tableLoading = true;
      this.getSkillsData();
    });

    this.modalRef.componentInstance.skillsSelected.subscribe(
      (selectedSkills) => {
        const newSkills = selectedSkills.map((s) => {
          return {
            id: s.id, // Skill id
            name: s.name,
            description: s.description,
            expiringAmberValue: s.expiringAmberValue,
            expiringAmberUnit: s.expiringAmberUnit,
          };
        });

        this.tableRows = [...this.tableRows, ...newSkills];

        this.updateSkillsEvent.emit(newSkills);
        this.modalRef.close();
      },
    );
  }

  getSkillsData() {
    this.matrixSettingsService
      .getSkills(this.searchFilterData)
      .pipe(takeUntil(this.termSubject))
      .subscribe({
        next: (x) => {
          this.modalRef.componentInstance.rowsChangedSubject.next(x.data);
          this.modalRef.componentInstance.skillCount = x.totalRowCount;
          this.modalRef.componentInstance.tablePageSize = x.pageSize;
          this.modalRef.componentInstance.tablePageIndex = x.page;
          this.modalRef.componentInstance.tableLoading = false;
        },
        error: (err) => {
          // this.alertService.createAlert2(this.exceptionData.SKILLS_LIST, err);
          this.modalRef.componentInstance.tableLoading = false;
        },
      });
  }
}
