<div class="card shadow">
  <div class="card-body">
    <h5 class="card-title">{{ cardTitle }}</h5>
    <ug-list-status
      [isLoading]="loadingData"
      [listLength]="progressDataArray.length"
      [emptyListMessage]="'No assigned competencies found'"
    ></ug-list-status>
    <div class="col-2" style="column-count: 2">
      <div
        *ngFor="let progressBarData of progressDataArray; let i = index"
        class="row"
      >
        <div class="col">
          <span
            >{{ progressBarData.name }} ({{
              getChartScore(progressBarData.data[1]['value'])
            }})</span
          >
        </div>
        <div class="col align-self-center">
          <div
            class="progress bg-danger"
            role="progressbar"
            aria-label="Warning example"
            aria-valuenow="{{
              getChartScore(progressBarData.data[1]['value'])
            }}"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar bg-success"
              [style.width]="getChartScore(progressBarData.data[1]['value'])"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
