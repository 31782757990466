import { Component, OnDestroy, TemplateRef, inject } from '@angular/core';
import { UitoolsService } from '../../core/services/uitools.service';

@Component({
  selector: 'ug-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of uiService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="uiService.removeToast(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class ToastsComponent implements OnDestroy {
  uiService = inject(UitoolsService);


  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  ngOnDestroy() {
    this.uiService.clearToasts();
  }
}
