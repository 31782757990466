<div *ngIf="isSignedIn && authService.me">
  <div class="row g-4 g-xl-5">
    <div
      *ngIf="homeCards.myActivitySummary"
      class="col-12"
      [ngClass]="getCardOrder('myActivitySummary')"
    >
      <ug-dashboard
        [personId]="authService.me.id"
        [showChartData]="showStatusSummData"
      >
      </ug-dashboard>
    </div>
    <div
      *ngIf="homeCards.myClaimActions"
      class="col-12 col-xl-6"
      [ngClass]="getCardOrder('myClaimActions')"
    >
      <esqep-claim-activity-summary></esqep-claim-activity-summary>
    </div>
    <div
      *ngIf="homeCards.myJobRoleProfile"
      class="col-12 col-xl-6"
      [ngClass]="getCardOrder('myJobRoleProfile')"
    >
      <ug-job-role-profile [personId]="me.id"></ug-job-role-profile>
    </div>
    <div
      *ngIf="homeCards.myAssignment"
      class="col-12 col-xl-6"
      [ngClass]="getCardOrder('myAssignment')"
    >
      <ug-assignment-detail [person]="me"></ug-assignment-detail>
    </div>
    <div
      *ngIf="homeCards.myTrainingCompletion"
      class="col-12 col-xl-6"
      [ngClass]="getCardOrder('myTrainingCompletion')"
    >
      <ug-curricula-summary></ug-curricula-summary>
    </div>
  </div>
</div>
