import { Component, Input, inject } from '@angular/core';
import { SecurityRole } from '../../core/models/security-role';
import { MenuItem } from '../../core/navbars/menu-item';
import { AuthService, SecurityRoleKey } from '../../core/services/auth.service';
import { ClientAccountService } from '../../core/services/client-account.service';

@Component({
  selector: 'ug-person-security-access',
  templateUrl: './person-security-access.component.html',
  styleUrl: './person-security-access.component.scss',
})
export class PersonSecurityAccessComponent {
  @Input() userSecurityRoles: Array<SecurityRole> = [];

  private authService = inject(AuthService);
  private clientAccountService = inject(ClientAccountService);
  securityrolesubj = this.authService.securityRoleLookupSubj;
  menuItems: MenuItem[] = this.clientAccountService.getMenu();
  SecurityRoleKey = SecurityRoleKey;
  collapsedRowMap = new Map<number, boolean>();
  menuItemMap = new Map<string, { isAccessible: boolean; readOnly: boolean }>();
  tableCollapsed: boolean = false;

  ngOnInit() {
    this.menuItems.forEach((item: MenuItem) => {
      this.setMenuItemAccessibility(item, this.userSecurityRoles);

      if (item.subMenu) {
        item.subMenu.forEach((subItem) => {
          this.setMenuItemAccessibility(subItem, this.userSecurityRoles);
        });
      }
    });
  }

  isAccessibleBySecurityLevel(minSecurityLevel: string, securityLevel: string) {
    const minLevel = this.userSecurityRoles.find(
      (role) => role.roleIdentifier === minSecurityLevel,
    ).securityLevel;
    const srLevel = this.userSecurityRoles.find(
      (role) => role.roleIdentifier === securityLevel,
    ).securityLevel;

    return minLevel <= srLevel;
  }

  setMenuItemAccessibility(menuItem: MenuItem, roles: SecurityRole[]) {
    roles.forEach((role: SecurityRole) => {
      const { roleIdentifier } = role;
      const isAccessible = this.isAccessibleBySecurityLevel(
        menuItem.minSecurityRole,
        roleIdentifier,
      );
      this.menuItemMap.set(`${menuItem.itemName} ${roleIdentifier}`, {
        isAccessible,
        readOnly: menuItem.readOnly?.includes(roleIdentifier) ?? false,
      });
    });
  }

  toggleRow(i: number) {
    this.collapsedRowMap.set(i, !this.collapsedRowMap.get(i));
  }
}
