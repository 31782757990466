<div [formGroup]="parentFormGroup" class="mx-3">
  <div class="row align-items-center my-2">
    <div class="col-5">
      <div>
        <label for="name" class="form-label">Role Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          placeholder="Job Role Name"
          autocomplete="off"
          required
        />
        <div
          *ngIf="
            parentFormGroup.get('name').hasError('required') &&
            parentFormGroup.get('name').touched
          "
          class="error"
        >
          Role Name is required
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="form-check mt-4 pt-1">
        <input
          id="role-active"
          class="form-check-input"
          type="checkbox"
          formControlName="active"
          title="Is role active?"
        />
        <label for="role-active" class="form-check-label text-bottom"
          >Role active</label
        >
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col-10">
      <div class="d-flex justify-content-between">
        <label for="description" class="form-label">Description</label>
        <div *ngIf="showRoleManagementAiPrompt">
          <button
            type="button"
            class="btn ai-btn"
            popoverClass="role-details-ai-popover"
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            [autoClose]="false"
            placement="left"
            [disabled]="!parentFormGroup.get('name').value"
            #detailsPopover="ngbPopover"
            (click)="openClosePopover('description', detailsPopover)"
            triggers="manual"
          >
            <span
              class="fa-stack small"
              placement="top"
              ngbTooltip="Generate description with AI"
            >
              <i class="fa-solid fa-circle fa-stack-2x"></i>
              <i
                class="fa-solid fa-lightbulb fa-stack-1x"
                [ngClass]="
                  parentFormGroup.get('name').value
                    ? 'lightbulb-on'
                    : 'lightbulb-off'
                "
              ></i>
            </span>
          </button>
        </div>
      </div>
      <textarea
        class="form-control"
        type="textarea"
        rows="3"
        id="description"
        formControlName="description"
        placeholder="Enter a description for the job role"
        maxlength="{{ maxNumOfChars }}"
      ></textarea>
      <div class="max-characters">
        <div id="char_count">
          {{ roleDescriptionLength }}/{{ maxNumOfChars }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-12">
    <ug-dropdown-select
      [ngbTypeahead]="locationSearch"
      [resultFormatter]="nameFormatter"
      [inputFormatter]="nameFormatter"
      [inlineGroup]="false"
      [ngModel]="selectedLocation"
      (ngModelChange)="onChange($event)"
      [ngModelOptions]="{ standalone: true }"
      >Location</ug-dropdown-select
    >
  </div>

  <ug-additional-details
    [detailsFormGroup]="parentFormGroup.get('details')"
  ></ug-additional-details>

  <ng-container *ngIf="parentFormGroup.get('id').value">
    <div class="mt-2">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary ms-1"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseAudit"
      >
        {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
      </button>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ug-audit-table
        [auditEndpoint]="detailsAudit"
        [id]="parentFormGroup.get('id').value"
        auditTitle="Role"
      ></ug-audit-table>
    </div>
  </ng-container>
</div>

<ng-template #popTitle>
  <div class="d-flex justify-content-between align-items-center">
    <div>AI Generate</div>

    <button class="btn text-light" (click)="selectedPopover.close()">
      <i class="fa fa-times"></i>
    </button>
  </div>
</ng-template>

<ng-template #popContent>
  <ug-ai-control-popover
    [key]="popoverKey"
    [roleName]="parentFormGroup.get('name').value"
    [popoverPrompt]="popoverPrompt"
    [showConfirmButton]="showConfirmButton"
    [loadingAiGenerate]="loadingAiGenerate"
    [maxNumOfChars]="popoverKey === 'description' ? maxNumOfChars : null"
    (generateAiKeyDetailsEvent)="generateAiKeyDetails($event)"
    (confirmEvent)="confirmAiResponse($event)"
  ></ug-ai-control-popover
></ng-template>
