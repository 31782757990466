export class FuncArea {
  id: number;
  name: string;
  description: string;
  funcAreaTypeName: string;
  parentFuncAreaId: number;
  childCount: number;
  selected: any;
  claimLevelSetId: number;
  claimStatusActionSetId: number;
  externalProvider: string;
}
