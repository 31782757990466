import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuditData,
  AuditFilter,
} from '../../../shared/audit-table/audit.service';
import { MatrixSettingsService } from '../matrix-settings.service';

@Component({
  selector: 'ug-matrix-settings-audit',
  templateUrl: './matrix-settings-audit.component.html',
  styleUrl: './matrix-settings-audit.component.scss',
})
export class MatrixSettingsAuditComponent {
  private matrixSettingsService = inject(MatrixSettingsService);

  matrixSettingsAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData> =
    this.matrixSettingsService.getMatrixSettingsAudit;
}
