import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'esqep-statement-form', // tslint:disable-line:component-selector
  templateUrl: './statement-form.component.html',
  styleUrls: ['./statement-form.component.scss'],
})
export class StatementFormComponent {
  @Input()
  parentFormGroup: FormGroup;
  @Input()
  esqepTitle = 'Statement of evidence';
  @Input()
  esqepDisabled = false;
  @Input()
  statementText = '';
  @Input()
  componentIndex: number | string = null;
  @Input()
  placeholderText = 'Supporting Evidence';

  get statementTextControl(): FormControl {
    return this.parentFormGroup.get('statementText') as FormControl;
  }
}
