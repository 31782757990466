import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';

import { MetaClaimGuard } from '../claim/guards/meta-claim.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { SubmissionDetailComponent } from './submission-detail/submission-detail.component';

const routes: Routes = [
  {
    path: 'assessment-request/new',
    component: SubmissionDetailComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      title: 'Create Competency Assessment Request',
      routeIdentifier: 'ASSESSMENT_REQUEST_NEW',
    },
  },
  {
    path: 'claim/new',
    redirectTo: 'assessment-request/new',
    pathMatch: 'full',
  },
  {
    path: 'assessment-request/:claimId/assessor',
    component: SubmissionDetailComponent,
    canActivate: mapToCanActivate([MetaClaimGuard]),
    data: { title: 'Verify Competency Assessment Request' },
  },
  {
    path: 'claim/:claimId/assessor',
    redirectTo: 'assessment-request/:claimId/assessor',
    pathMatch: 'full',
  },
  {
    path: 'assessment-request/:claimId/claimant',
    component: SubmissionDetailComponent,
    canActivate: mapToCanActivate([MetaClaimGuard]),
    data: { title: 'Modify Competency Assessment Request' },
  },
  {
    path: 'claim/:claimId/claimant',
    redirectTo: 'assessment-request/:claimId/claimant',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmissionRoutingModule {}
