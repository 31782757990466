<div>
  <label for="aiPrompt" class="form-label"
    >Provide a brief description for the role (optional)</label
  >
  <textarea
    class="form-control"
    type="textarea"
    id="aiPrompt"
    [(ngModel)]="prompt"
    [ngModelOptions]="{ standalone: true }"
  ></textarea>
  <div class="d-flex flex-wrap align-items-center my-3">
    <label for="aiPromptLength" class="form-label my-0 py-0 me-2"
      >Response Length:</label
    >
    <select
      id="aiPromptLength"
      class="form-select form-select-sm w-15"
      aria-label="Default select example"
      [(ngModel)]="wordCount"
      [disabled]="loadingAiGenerate"
    >
      <option value="15">Short</option>
      <option value="35">Medium</option>
      <option value="60">Long</option>
    </select>
  </div>

  <div>
    <label for="aiResponse" class="form-label">AI Generated Description</label>
    <textarea
      class="form-control my-2"
      type="textarea"
      id="aiResponse"
      [(ngModel)]="aiResponse"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="loadingAiGenerate || invalidPrompt"
    ></textarea>

    <div class="d-flex justify-content-between">
      <p
        *ngIf="
          (aiResponse.length || aiResponseHistory.length[currentPage]) >
          maxNumOfChars
        "
        class="text-danger me-2"
      >
        Warning: Response exceeds character limit. Edit the content above or try
        again.
      </p>

      <div class="ms-auto">
        {{ loadingAiGenerate || invalidPrompt ? 0 : aiResponse.length }}/{{
          maxNumOfChars
        }}
      </div>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-between align-items-center mt-2">
    <div class="ai-pagination d-flex align-items-center">
      <button
        class="btn response-pagination-btn py-0"
        title="previous"
        [disabled]="
          currentPage <= 1 ||
          loadingAiGenerate ||
          aiResponseHistory.length === 0
        "
        (click)="previousPage()"
      >
        <i class="fa-solid fa-less-than"></i>
      </button>
      <div class="mb-1">{{ currentPage }} / {{ aiResponseHistory.length }}</div>
      <button
        [disabled]="
          currentPage === aiResponseHistory.length || loadingAiGenerate
        "
        class="btn response-pagination-btn py-0"
        title="next"
        (click)="nextPage()"
      >
        <i class="fa-solid fa-greater-than"></i>
      </button>
    </div>

    <div class="ms-1">
      <button
        class="btn esqepBtn btn-sm align-bottom"
        title="Generate AI response"
        [disabled]="loadingAiGenerate"
        (click)="generateAiKeyDetails()"
      >
        Generate
      </button>
      <button
        *ngIf="showConfirmButton"
        class="btn btn-outline-success btn-sm ms-2"
        title="Confirm AI response"
        [disabled]="
          loadingAiGenerate ||
          invalidPrompt ||
          (aiResponse.length || aiResponseHistory.length[currentPage]) >
            maxNumOfChars
        "
        (click)="confirm()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
