import { DOCUMENT, Location } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { MsalInterceptorConfiguration } from '@azure/msal-angular/msal.interceptor.config';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { AuthenticationConfigNames } from '../core/authenticationConfigNames';
import { ConfigService } from '../core/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor
  extends MsalInterceptor
  implements HttpInterceptor
{
  authenticationConfigNames: typeof AuthenticationConfigNames =
    AuthenticationConfigNames;

  constructor(
    @Optional() @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    @Optional()
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    msalInterceptorConfig: MsalInterceptorConfiguration,
    @Inject(DOCUMENT) document: Document,
    @Inject(Location) location: Location,
    @Optional() private msalService: MsalService,
    @Optional() msalBroadcastService: MsalBroadcastService,
    private configService: ConfigService,
  ) {
    super(
      msalInterceptorConfig,
      msalService,
      location,
      msalBroadcastService,
      document,
    );
  }

  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let req = request;

    if (
      this.configService.authSetup[this.authenticationConfigNames.OKTA] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.OKTA
    ) {
      return next.handle(this.addAuthHeaderToAllowedOrigins(request));
    }
    if (
      this.configService.authSetup[this.authenticationConfigNames.CSOD] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.CSOD
    ) {
      const authToken = localStorage.getItem('esqep.auth.saml');

      if (authToken) {
        req = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        });
      }
      return next.handle(req);
    }

    if (
      this.configService.authSetup[this.authenticationConfigNames.ENTRA] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.ENTRA
    ) {
      req = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
      return super.intercept(req, next);
    }
    return next.handle(req);
  }

  private addAuthHeaderToAllowedOrigins(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    let req = request;
    if (
      !!this.configService.authSetup[
        this.authenticationConfigNames.OKTA
      ].allowedOrigins.find((origin) => request.url.includes(origin))
    ) {
      const authToken = this._oktaAuth.getAccessToken();
      req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
    }

    return req;
  }
}
