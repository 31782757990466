import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { BasicOrgUnit } from '../../../../../org-unit/models/org-unit';
import { Person } from '../../../../../person/models/person';
import { PersonService } from '../../../../../person/services/person.service';
export enum OrgUnitActionType {
  RESTORE = 'Restore',
  REMOVE = 'Remove',
  DISCARD = 'Discard',
}

@Component({
  selector: 'ug-role-org-units',
  templateUrl: './role-org-units.component.html',
  styleUrls: ['./role-org-units.component.scss'],
})
export class RoleOrgUnitsComponent implements OnInit, OnChanges, OnDestroy {
  private personService = inject(PersonService);
  private alertService = inject(AlertService);

  @Input() discardChangesSubj: Subject<boolean>;
  @Input() saveChangesSubj: Subject<boolean>;
  @Input() allowEdit: boolean;
  @Input() jobRoleId: number;
  @Input() jobRoleOrgUnits: Array<any>;
  @Input() selectedOrgUnits: Array<any>;
  @Output() discardOu: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeRestoreOu: EventEmitter<{ id: number; remove: boolean }> =
    new EventEmitter<{ id: number; remove: boolean }>();

  currentOrgUnitsInUse: Array<any> = [];
  ngUnsubscribe: Subject<boolean> = new Subject();
  ouPeopleIsLoading: boolean;
  ouPeopleCache = new Map<number, Array<Person>>();
  ouPeopleList: Array<Person> = [];

  ouIndex: number;

  private orgUnitsFromApi: Array<BasicOrgUnit>;
  private selectedOu: Array<BasicOrgUnit>;

  orgUnitCountMessageMap = {
    '=0': 'None',
    '=1': '1 org unit',
    other: '# org units',
  };

  protected readonly OrgUnitActionType = OrgUnitActionType;

  ngOnInit(): void {
    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.currentOrgUnitsInUse = this.orgUnitsFromApi.map((e) => {
          e['actionType'] = null;
          return e;
        });
      }
    });
    this.saveChangesSubj.subscribe((ch) => {
      if (ch) {
        this.currentOrgUnitsInUse = this.orgUnitsFromApi.map((e) => {
          e['actionType'] = OrgUnitActionType.REMOVE;
          return e;
        });
        this.selectedOu = [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jobRoleOrgUnits']) {
      this.orgUnitsFromApi =
        changes['jobRoleOrgUnits'] && changes['jobRoleOrgUnits'].currentValue
          ? changes['jobRoleOrgUnits'].currentValue.map((e) => {
              e.actionType = null;
              return e;
            })
          : [];
    }

    if (changes['selectedOrgUnits']) {
      this.selectedOu =
        changes['selectedOrgUnits'] && changes['selectedOrgUnits'].currentValue
          ? changes['selectedOrgUnits'].currentValue.map((e) => {
              e.actionType = OrgUnitActionType.DISCARD;
              return e;
            })
          : [];
    }
    this.currentOrgUnitsInUse = [...this.orgUnitsFromApi, ...this.selectedOu];
  }
  toggleOu(i: number, ou) {
    this.ouIndex = this.ouIndex === i ? null : i;
    this.getPeopleInOu(ou);
  }

  getPeopleInOu(ouValue) {
    this.ouPeopleList = [];
    if (this.ouIndex !== null) {
      if (this.ouPeopleCache.has(ouValue.id)) {
        this.ouPeopleList = this.ouPeopleCache.get(ouValue.id);
      } else {
        this.ouPeopleIsLoading = true;
        const payload = {
          orgUnitId: ouValue.id,
          operator: '=',
        };

        this.personService
          .search(payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (p) => {
              this.ouPeopleCache.set(ouValue.id, p);
              this.ouPeopleList = p;
              this.ouPeopleIsLoading = false;
            },
            error: (err) => {
              this.ouPeopleIsLoading = true;
              this.alertService.createAlert2(
                {
                  level: AlertLevels.ERROR,
                  code: 'ROU-001',
                  message: 'Error retrieving people in org unit',
                } as AlertData,
                err,
              );
            },
          });
      }
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onRemoveOuClick(id: number) {
    const orgToRemove = this.currentOrgUnitsInUse.find((f) => f.id === id);
    orgToRemove.actionType = OrgUnitActionType.RESTORE;
    this.removeRestoreOu.emit({ id: id, remove: true });
  }

  onDiscardOuClick(id: number) {
    this.discardOu.emit(id);
  }

  onRestoreOuClick(ouIndex: number) {
    const foundOrgUnit = this.currentOrgUnitsInUse.find(
      (f) => f.id === ouIndex,
    );
    foundOrgUnit.actionType = null;
    this.removeRestoreOu.emit({ id: foundOrgUnit.id, remove: false });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
