import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, combineLatest, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ClientAccountService } from '../services/client-account.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityRoleGuard {
  private authService = inject(AuthService);
  private router = inject(Router);
  private clientAccountService = inject(ClientAccountService);


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.clientAccountService.flattenRequiredSecurityRoles) {
      this.clientAccountService.getMenu();
    }

    if (next.data['routeIdentifier']) {
      const minRole =
        this.clientAccountService.flattenRequiredSecurityRoles[
          next.data['routeIdentifier']
        ];

      return combineLatest([
        this.authService.securityRoleLookupSubj,
        this.authService.loggedInUserSubj,
      ]).pipe(
        map(([securityRoleLookup, loggedInUser]) => {
          const requiredSecurityRole = securityRoleLookup[minRole];
          const allowAccess =
            requiredSecurityRole &&
            loggedInUser.securityLevel >= requiredSecurityRole.securityLevel;
          if (!allowAccess) {
            this.router.navigate(['/exception/not_auth']);
          }
          return allowAccess;
        })
      );
    } else {
      console.log('CONFIG ERROR: minimum role not defined for this route');
      return of(false);
    }
  }
}
