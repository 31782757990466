import { Component, OnInit, inject } from '@angular/core';

import { SharedInfo } from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';
import { PersonService } from '../services/person.service';

@Component({
  selector: 'ug-shared-info-list',
  templateUrl: './shared-info-list.component.html',
  styleUrls: [],
})
export class SharedInfoListComponent implements OnInit {
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private configService = inject(ConfigService);
  private personService = inject(PersonService);

  sharedInfoList: Array<SharedInfo>;
  sharedServerUri: string;
  personId: number;
  buttonStatus = {};

  exceptionData = {
    SHARED_INFO: {
      level: AlertLevels.ERROR,
      code: 'SIL-001',
      message: 'Error retrieving shared info list',
    } as AlertData,
    SHARED_INFO_DELETE: {
      level: AlertLevels.ERROR,
      code: 'SIL-002',
      message: 'Error deleting shared item',
    } as AlertData,
  };

  ngOnInit() {
    this.sharedServerUri = this.configService.shareServerUri;
    this.authService.loggedInUserSubj.subscribe((liu: LoggedInUser) => {
      if (liu?.id) {
        this.personId = liu.id;
        this.personService.getSharedInfoByPersonId(liu.id).subscribe(
          (si: Array<SharedInfo>) => {
            this.sharedInfoList = si;
          },
          (err) =>
            this.alertService.createAlert2(this.exceptionData.SHARED_INFO, err)
        );
      }
    });
  }

  onDeleteClick(sharedInfoId: number) {
    this.buttonStatus[sharedInfoId] = { disabled: true };
    this.personService.deleteSharedInfo(sharedInfoId, this.personId).subscribe(
      (rc) => {
        this.sharedInfoList = this.sharedInfoList.filter(
          (element: SharedInfo) =>
            element['publishedClaim'].sharedInfoId !== sharedInfoId
        );
      },
      (err) => {
        this.alertService.createAlert2(
          this.exceptionData.SHARED_INFO_DELETE,
          err
        );
        this.buttonStatus[sharedInfoId] = { disabled: false };
      },
      () => delete this.buttonStatus[sharedInfoId]
    );
  }
}
