import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // , ReactiveFormsModule
import { TranslocoModule } from '@jsverse/transloco';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { EchartsModule } from '../echarts/echarts.module';
import { SharedModule } from '../shared/shared.module';
import { JobRoleAnalysisComponent } from './job-role-analysis/job-role-analysis.component';
import { JobRoleCompareComponent } from './job-role-compare/job-role-compare.component';
import { JobRoleDetailsSummaryComponent } from './job-role-details-summary/job-role-details-summary.component';
import { JobRoleItemComponent } from './job-role-item/job-role-item.component';
import { JobRoleListComponent } from './job-role-list/job-role-list.component';
import { Pagination } from './job-role-list/paging/pagination.component';
import { OrgUnitsModalComponent } from './job-role-list/role-management/role-assignment/org-units-modal/org-units-modal.component';
import { PeopleModalComponent } from './job-role-list/role-management/role-assignment/people-modal/people-modal.component';
import { PeopleUploadComponent } from './job-role-list/role-management/role-assignment/people-upload/people-upload.component';
import { RoleAssignmentComponent } from './job-role-list/role-management/role-assignment/role-assignment.component';
import { RoleGroupFilterComponent } from './job-role-list/role-management/role-assignment/role-group-filter/role-group-filter.component';
import { RoleOrgUnitsComponent } from './job-role-list/role-management/role-assignment/role-org-units/role-org-units.component';
import { RolePeopleComponent } from './job-role-list/role-management/role-assignment/role-people/role-people.component';
import { CompetenciesModalComponent } from './job-role-list/role-management/role-competencies/competencies-modal/competencies-modal.component';
import { RoleCompetenciesComponent } from './job-role-list/role-management/role-competencies/role-competencies.component';
import { AdditionalDetailsComponent } from './job-role-list/role-management/role-details/additional-details/additional-details.component';
import { AiControlPopoverComponent } from './job-role-list/role-management/role-details/ai-control-popover/ai-control-popover.component';
import { RoleDetailsComponent } from './job-role-list/role-management/role-details/role-details.component';
import { RoleManagementComponent } from './job-role-list/role-management/role-management.component';
import { RequirementRowComponent } from './job-role-list/role-management/role-training/requirement-row/requirement-row.component';
import { RoleDueDateSettingsComponent } from './job-role-list/role-management/role-training/role-due-date-settings/role-due-date-settings.component';
import { RoleTrainingComponent } from './job-role-list/role-management/role-training/role-training.component';
import { TypeaheadSearchComponent } from './job-role-list/role-management/role-training/typeahead-search/typeahead-search.component';
import { JobRolePeopleAuditComponent } from './job-role-people-audit/job-role-people-audit.component';
import { JobRolePersonComponent } from './job-role-person/job-role-person.component';
import { JobRoleProfileComponent } from './job-role-profile/job-role-profile.component';
import { LearningAssignmentJobRoleRequirementsTableComponent } from './job-role-requirements-table/cornerstone-learning-assignment-version/learning-assignment-job-role-requirements-table.component';
import { DefaultJobRoleRequirementsTableComponent } from './job-role-requirements-table/default-version/default-job-role-requirements-table.component';
import { JobRoleRoutingModule } from './job-role-routing.module';
import { DueDateControlComponent } from './job-role-settings/due-date-control/due-date-control.component';
import { JobRoleSettingsAuditComponent } from './job-role-settings/job-role-settings-audit/job-role-settings-audit.component';
import { JobRoleSettingsComponent } from './job-role-settings/job-role-settings.component';
import { SettingsCardComponent } from './job-role-settings/settings-card/settings-card.component';
import { JobRoleTrainingAuditComponent } from './job-role-training-audit/job-role-training-audit.component';
import { PersonJobRoleCompareComponent } from './person-job-role-compare/person-job-role-compare.component';
import { RoleAcrossPeopleComponent } from './role-across-people/role-across-people.component';
import { RoleCompetencySummaryComponent } from './role-competency-summary/role-competency-summary.component';
import { RolePersonScoreComponent } from './role-person-score/role-person-score.component';
import { RolePersonTilesComponent } from './role-person-tiles/role-person-tiles.component';
import { JobRoleService } from './services/job-role.service';

@NgModule({
  imports: [
    CommonModule,
    ControlsModule,
    DashboardModule,
    FormsModule,
    JobRoleRoutingModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgSelectModule,
    NgbNavModule,
    NgbModule,
    NgbToastModule,
    NgOptionHighlightModule,
    NgbAccordionModule,
    SharedModule,
    EchartsModule,
    TranslocoModule,
    NgbPopoverModule,
    NgbTooltipModule,
  ],
  declarations: [
    JobRoleCompareComponent,
    JobRoleAnalysisComponent,
    JobRoleProfileComponent,
    DefaultJobRoleRequirementsTableComponent,
    LearningAssignmentJobRoleRequirementsTableComponent,
    JobRoleListComponent,
    JobRoleItemComponent,
    Pagination,
    JobRolePeopleAuditComponent,
    JobRoleTrainingAuditComponent,
    RoleCompetencySummaryComponent,
    RoleAcrossPeopleComponent,
    RolePersonTilesComponent,
    RoleManagementComponent,
    RoleDetailsComponent,
    RolePeopleComponent,
    RoleCompetenciesComponent,
    RoleTrainingComponent,
    TypeaheadSearchComponent,
    RoleAssignmentComponent,
    RoleOrgUnitsComponent,
    OrgUnitsModalComponent,
    PeopleModalComponent,
    PeopleUploadComponent,
    CompetenciesModalComponent,
    RoleGroupFilterComponent,
    RolePersonScoreComponent,
    JobRoleDetailsSummaryComponent,
    JobRolePersonComponent,
    AdditionalDetailsComponent,
    JobRoleSettingsComponent,
    DueDateControlComponent,
    SettingsCardComponent,
    JobRoleSettingsAuditComponent,
    PersonJobRoleCompareComponent,
    RoleDueDateSettingsComponent,
    RequirementRowComponent,
    AiControlPopoverComponent,
  ],
  providers: [JobRoleService],
  exports: [
    JobRoleCompareComponent,
    JobRoleAnalysisComponent,
    JobRoleProfileComponent,
    DefaultJobRoleRequirementsTableComponent,
    LearningAssignmentJobRoleRequirementsTableComponent,
    JobRoleListComponent,
    JobRoleItemComponent,
    RolePersonTilesComponent,
    Pagination,
    RoleCompetencySummaryComponent,
    RoleDetailsComponent,
    RolePersonScoreComponent,
    JobRolePersonComponent,
    JobRoleDetailsSummaryComponent,
  ],
})
export class JobRoleModule {}
