import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { ClaimService } from '../services/claim.service';

@Component({
  selector: 'ug-claim-document',
  templateUrl: './claim-document.component.html',
  styleUrls: ['./claim-document.component.scss'],
})
export class ClaimDocumentComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);
  private sanitizer = inject(DomSanitizer);

  /* JB 20170626 - Begin
   ** Attempt to force onchanges() when reclick view in IE/Edge */
  private _claimId: number;
  private _docId: number;

  @Input() set claimId(value) {
    this._claimId = value;
  }
  @Input() set docId(value) {
    this._docId = value;
  }
  get claimId() {
    return this._claimId;
  }
  get docId() {
    return this._docId;
  }
  /* JB 20170626 - End */

  showDoc = false;
  showImg = false;
  isMS = false;
  dataSrc: SafeResourceUrl;
  fileUrl: SafeResourceUrl;
  fileType: string;

  exceptionData = {
    CLAIM_DOC: {
      level: AlertLevels.ERROR,
      code: 'CDC-001',
      message: 'Error retrieving claim document',
      translationKey: 'errRetrievingAssessmentRequestDoc',
    } as AlertData,
  };

  claimDocSubs = new Subscription();

  ngOnChanges() {
    console.log('Doc Id = ' + this.docId);
    if (!this.docId && !this.claimId) {
      return;
    }
    this.dataSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.claimService.getClaimDocUrl(this.claimId, this.docId)
    );

    this.claimDocSubs = this.claimService
      .getClaimDoc(this.claimId, this.docId)
      .subscribe(
        (cd) => {
          let tempUrl = '';
          const blob = new Blob([cd], { type: cd.type });
          this.fileType = blob.type;
          if (
            blob.type === 'application/pdf' ||
            blob.type === 'application/msword'
          ) {
            this.showImg = false;
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //     window.navigator.msSaveOrOpenBlob(blob);
            //     this.isMS = true;
            // } else {
            //     tempUrl = window.URL.createObjectURL(blob);
            //     this.isMS = false;
            // }
            tempUrl = window.URL.createObjectURL(blob);
            this.isMS = false;
          } else if (blob.type === 'image/jpeg' || blob.type === 'image/png') {
            this.showImg = true;
            tempUrl = window.URL.createObjectURL(blob);
          }
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //     window.navigator.msSaveOrOpenBlob(blob);
          // } else {
          //     tempUrl = window.URL.createObjectURL(blob);
          //     // var objectUrl = URL.createObjectURL(blob);
          //     // window.open(objectUrl);
          // }
          // const tempUrl = window.URL.createObjectURL(blob);
          console.log('Temp URL = ' + tempUrl);
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(tempUrl);
          console.log('Sanitized URL = ' + this.fileUrl);
          // const jReader = new FileReader();
          // jReader.addEventListener('loadend', function() {
          //   const dataSrc = jReader.result.toString();
          //   console.log(dataSrc);
          //   // setDataSrc(dataSrc);
          //   localStorage.setItem('myDoc', JSON.stringify(dataSrc));
          // });
          // jReader.readAsDataURL(blob);
          // console.log('dummy');
        },
        (err) => {
          this.alertService.createAlert2(this.exceptionData.CLAIM_DOC, err);
        },
        () => {
          console.log('Got Document');
          this.showDoc = !this.isMS;
          this.docId = undefined;
        }
        // this.dataSrc = this.sanitizer.bypassSecurityTrustResourceUrl(JSON.parse(localStorage.get('myDoc')));
        // console.log(this.dataSrc); }
      );
    // https://stackoverflow.com/questions/22938194/xmlhttp-request-is-raising-an-access-denied-error
  }

  ngOnDestroy() {
    this.claimDocSubs.unsubscribe();
  }
}
