import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  apiServerUri: string;

  constructor() {
    // this.apiServerUri = 'https://hachyonics-a1.azurewebsites.net/esqep/api/v1.0';
    // this.apiServerUri = 'https://hachyonics-a2.azurewebsites.net/esqep/api/v1.0';
    this.apiServerUri = environment.apiUrl;
  }
}
