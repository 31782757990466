import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { GroupFiltersListComponent } from './group-filters-list/group-filters-list.component';
import { GroupManagementComponent } from './group-management/group-management.component';

const routes: Routes = [
  {
    path: 'group-management',
    component: GroupFiltersListComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ADMIN_JOB-ROLE-GROUPS',
      title: 'Group Management',
      helpUrl:
        'https://scribehow.com/shared/Group_Management__CWYiePukRb2LFK64Kdi5eQ',
    },
  },
  {
    path: 'group-management/:id',
    component: GroupManagementComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      routeIdentifier: 'ADMIN_JOB-ROLE-GROUPS',
      title: 'Group Management',
      helpUrl:
        'https://scribehow.com/shared/Group_Management__CWYiePukRb2LFK64Kdi5eQ',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DynamicGroupsRoutingModule {}
