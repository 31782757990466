import { ElementRef, Injectable, TemplateRef, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class UitoolsService {
  private modalService = inject(NgbModal);


  public openModal(modalElement: ElementRef) {
    this.modalService.open(modalElement, { centered: true });
  }

  public openModalLarge(modalElement: ElementRef) {
    this.modalService.open(modalElement, { centered: true, size: 'lg' });
  }

  public openModalExtraLarge(modalElement: ElementRef) {
    this.modalService.open(modalElement, { centered: true, size: 'xl' });
  }

  public openModalStatic(modalElement: ElementRef) {
    this.modalService.open(modalElement, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });
  }

  public closeModal() {
    this.modalService.dismissAll();
  }

  toasts: any[] = [];

  showToast(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  removeToast(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clearToasts() {
    this.toasts.splice(0, this.toasts.length);
  }
}
