<div class="card">
  <div class="card-body">
    <h5 class="card-title">Performance Management</h5>
    <div class="container-fluid">
      <div [hidden]="isLoading" class="row">
        <div class="col-12">
          <form [formGroup]="mgmtForm" class="form-inline">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                formControlName="meOrTeam"
                value="me"
                id="meRadio"
                (click)="radioClick()"
              />
              <label class="form-check-label" for="meRadio"
                >Me ({{ perfMgmtData.me?.userFirstName }}
                {{ perfMgmtData.me?.userLastName }})</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                formControlName="meOrTeam"
                value="team"
                id="teamRadio"
                (click)="radioClick()"
              />
              <label class="form-check-label" for="teamRadio"
                >Team member</label
              >
            </div>
            <!-- <select class="form-select" formControlName="" [disabled]="mgmtForm.get('meOrTeam').value==='me'"> -->
            <select
              class="form-select"
              formControlName="teamMember"
              [hidden]="mgmtForm.get('teamMember').disabled"
              title="Team member"
            >
              <option
                *ngFor="let tm of perfGridUserIds; let j = index"
                [value]="tm"
              >
                {{ userMap.get(tm) }}
              </option>
              <!-- <option *ngFor="let tm of perfMgmtData.team; let j=index" [value]="j">{{tm.userLastName}}, {{tm.userFirstName}} ({{tm.name}})</option> -->
              <!-- <option *ngFor="let tm of userMap.keys(); let j=index" [value]="tm">{{userMap.get(tm)}}</option> -->
              <!-- <option *ngFor="let tm of userRoles.keys(); let j=index" [value]="tm">{{userRoles.get(tm)['name']}}</option> -->
            </select>
            <!-- <p>{{selectedPersonId}}</p> -->
            <!-- <p>{{perfGridUserIds | json}}</p> -->
            <!-- <p *ngFor="let u of usersList">{{u | json}}</p> -->
            <!-- <select class="form-select" formControlName="teamMember" [hidden]="mgmtForm.get('teamMember').disabled">
                            <option *ngFor="let tm of userRoles.get(); let j=index" [value]="tm">{{userRoles.get(tm)['name']}}</option>
                        </select> -->
          </form>
          <!-- <p>{{perfGridTabData | json}}</p> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ug-perf-grid [perfGridContent]="perfMgmtData.me"></ug-perf-grid> -->
<!-- <ug-perf-grid *ngIf="!isLoading" [perfGridContent]="perfGridData[0]"></ug-perf-grid> -->
<ug-perf-grid *ngIf="!isLoading" [perfGridArray]="perfGridData"></ug-perf-grid>

<!-- <p>{{perfGridData | json}}</p> -->
