import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import {
  OrgUnitLookup,
  OrgUnitService,
} from '../../org-unit/services/org-unit.service';
import { PersonBasic } from '../../person/services/person.service';
import { SkillLookup, SkillService } from '../../skill/services/skill.service';
import { EventSession, EventsService } from '../services/events.service';
import { BookSessionComponent } from './book-session/book-session.component';
import { ManageSessionComponent } from './manage-session/manage-session.component';
import { ViewSessionComponent } from './view-session/view-session.component';

@Component({
  selector: 'ug-training-calendar',
  templateUrl: './training-calendar.component.html',
  styleUrls: ['./training-calendar.component.scss'],
})
export class TrainingCalendarComponent implements OnInit, AfterViewInit {
  fb = inject(FormBuilder);
  eventsService = inject(EventsService);
  private alertService = inject(AlertService);
  private orgUnitService = inject(OrgUnitService);
  private authService = inject(AuthService);
  private skillService = inject(SkillService);
  private ngbModal = inject(NgbModal);
  private activatedRoute = inject(ActivatedRoute);
  private datePipe = inject(DatePipe);
  private destroyRef = inject(DestroyRef);

  exceptionData = {
    OU_TYPES: {
      level: AlertLevels.ERROR,
      code: 'EC-001',
      message: 'Error getting org unit types',
    } as AlertData,
    OU_BYTYPE: {
      level: AlertLevels.ERROR,
      code: 'EC-002',
      message: 'Error getting org units by type',
    } as AlertData,
    TRAINING_SUBJECTS: {
      level: AlertLevels.ERROR,
      code: 'EC-003',
      message: 'Error getting training subjects',
    } as AlertData,
    TRAINERS: {
      level: AlertLevels.ERROR,
      code: 'EC-004',
      message: 'Error getting trainers',
    } as AlertData,
    SESSIONS: {
      level: AlertLevels.ERROR,
      code: 'EC-005',
      message: 'Error getting sessions in date range',
    } as AlertData,
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('fc-changeViewButton-button')
  listViewButton: FullCalendarComponent;

  calendarApi: Calendar;
  trainingSubjects: Array<SkillLookup> = [];
  locations: Array<OrgUnitLookup> = [];
  trainers: Array<PersonBasic> = [];
  userHasAdminAccess = false;
  loading = true;

  trainingCalFilters: FormGroup = this.fb.group({
    trainingId: [null],
    trainerId: [null],
    locationId: [null],
  });

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    headerToolbar: {
      right: 'today prev,next dayGridMonth listMonth',
      center: 'title',
      left: '',
    },
    customButtons: {
      addEventButton: {
        text: 'New Session',
        click: (() => {
          this.addEvent();
        }).bind(this),
      },
    },
    initialView: 'dayGridMonth',
    events: ((info, successCallback, failureCallback) =>
      this.getTrainingSessions(info, successCallback, failureCallback)).bind(
      this,
    ),
    weekends: true,
    firstDay: 1, // 0 is Sunday, 1 is Monday
    editable: true,
    selectable: true,
    selectMirror: true,
    eventClick: this.eventClick.bind(this),
    displayEventTime: false,
    dateIncrement: { month: 1 },
    eventContent: this.eventDomNodes.bind(this),
    views: {
      listMonth: {
        buttonText: 'List',
        listDayFormat: {
          weekday: 'long',
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        },
        listDaySideFormat: false,
      },
      dayGridMonth: { buttonText: 'Calendar' },
    },
  };

  ngOnInit(): void {
    this.loading = true;
    this.authService.loggedInUserSubj
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((liu: LoggedInUser) => {
        this.userHasAdminAccess = [
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.TrainingCalendarAdmin,
        ].some((sr) => liu.roleIdentifier === sr);

        this.calendarOptions.headerToolbar = {
          ...this.calendarOptions.headerToolbar,
          left: this.userHasAdminAccess ? 'addEventButton' : '',
        };

        this.loading = false;
      });

    this.activatedRoute.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        if (params.sessionId) {
          const modalRef = this.ngbModal.open(ManageSessionComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'xl',
          });
          modalRef.componentInstance.currentSessionId = params.sessionId;
          modalRef.componentInstance.userHasAdminAccess =
            this.userHasAdminAccess;
        } else if (params.subjectId) {
          this.trainingCalFilters.get('trainingId').setValue(params.subjectId);
        }
      });

    this.skillService
      .getSkillsWithExistingSessions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (sk: SkillLookup[]) => {
          this.trainingSubjects = sk;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.TRAINING_SUBJECTS,
            err,
          );
        },
      });

    this.orgUnitService
      .getOrgUnitLookups({ externalTypeId: '32', source: 'CSOD' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (oul) => {
          this.locations = oul;
          this.eventsService.sessionLocations = oul;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.OU_BYTYPE, err);
        },
      });

    this.trainingCalFilters.get('trainingId').valueChanges.subscribe((val) => {
      if (val) {
        this.eventsService
          .getTrainersBySkillId(val)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (s: PersonBasic[]) => {
              this.trainers = s;
            },
            error: (err) => {
              this.alertService.createAlert2(this.exceptionData.TRAINERS, err);
            },
          });
      } else {
        this.trainers = [];
        this.trainingCalFilters.get('trainerId').setValue(null);
      }
    });

    this.trainingCalFilters.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.calendarApi.batchRendering(() => {
          const events = this.calendarApi.getEvents();
          for (let i = 0; i < events.length; i++) {
            const event = events[i];

            event.setProp('display', 'none');
            if (
              (event.extendedProps.skillId === Number(val.trainingId) ||
                !val.trainingId) &&
              (event.extendedProps.instructors.findIndex(
                (si) => si.id === val.trainerId,
              ) !== -1 ||
                !val.trainerId) &&
              ((event.extendedProps.locationId &&
                event.extendedProps.locationId === Number(val.locationId)) ||
                !val.locationId)
            ) {
              event.setProp('display', 'block');
            }
          }
        });
      });
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();
  }

  eventClick(info: EventClickArg) {
    let modalRef: NgbModalRef;

    if (this.userHasAdminAccess) {
      modalRef = this.ngbModal.open(ManageSessionComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'xl',
      });
    } else {
      modalRef = this.ngbModal.open(ViewSessionComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });
    }

    modalRef.componentInstance.userHasAdminAccess = this.userHasAdminAccess;
    const event: EventSession = {
      attendeeCount: info.event._def.extendedProps.attendeeCount,
      attendedCount: info.event._def.extendedProps.attendedCount,
      endDate: info.event.end
        ? this.datePipe.transform(info.event.end, 'EE dd MMMM yy h:mm a')
        : '',
      externalId: info.event._def.extendedProps.externalId,
      externalClassroomId: info.event._def.extendedProps.externalClassroomId,
      id: Number(info.event._def.publicId),
      instructors: info.event._def.extendedProps.instructors,
      isSessionCompleted: info.event._def.extendedProps.isSessionCompleted,
      location: info.event._def.extendedProps.location,
      locationId: info.event._def.extendedProps.locationId,
      minCapacity: info.event._def.extendedProps.minCapacity,
      maxCapacity: info.event._def.extendedProps.maxCapacity,
      skillId: info.event._def.extendedProps.skillId,
      startDate: info.event.start
        ? this.datePipe.transform(info.event.start, 'EE dd MMMM yy h:mm a')
        : '',
      title: info.event._def.title,
    };

    modalRef.componentInstance.currentSession = event;
  }

  eventDomNodes(info: any) {
    const extendedProps = info.event._def
      ? info.event._def.extendedProps
      : info.event.extendedProps;
    const isListView = info.view.type === 'listMonth';
    const container = document.createElement('div');

    isListView
      ? container.classList.add('row')
      : container.classList.add(
          'd-flex',
          'w-100',
          'justify-content-between',
          'px-2',
          'flex-wrap',
        );

    const title = document.createElement('div');
    title.innerHTML = info.event.title;
    isListView
      ? title.classList.add('col-4')
      : title.classList.add('text-wrap');

    const attendees = document.createElement('div');
    isListView
      ? attendees.classList.add('col-4', 'd-flex', 'align-items-center')
      : attendees.classList.add('d-flex', 'align-items-center', 'flex-wrap');

    const icon = document.createElement('span');
    icon.classList.add('far', 'fa-user', 'px-2');

    const noOfAttendees = extendedProps.attendeeCount;

    attendees.appendChild(icon);
    const usercount = document.createElement('div');
    usercount.innerHTML = extendedProps.isSessionCompleted
      ? `${extendedProps.attendedCount} / ${noOfAttendees} Attended`
      : `${noOfAttendees}/${
          extendedProps.maxCapacity
            ? extendedProps.maxCapacity + ' booked'
            : '(Capacity unknown)'
        }`;

    !isListView ? usercount.classList.add('px-2', 'text-wrap') : null;

    attendees.appendChild(usercount);

    if (extendedProps.isSessionCompleted) {
      const capacity = document.createElement('div');
      capacity.innerHTML = 'Session Completed';
      capacity.classList.add('px-2', 'text-wrap');
      attendees.appendChild(capacity);
    }

    container.appendChild(title);
    container.appendChild(attendees);

    if (isListView) {
      const endDate = document.createElement('div');
      endDate.innerHTML =
        'End date : ' + new Date(info.event.end).toDateString();
      endDate.classList.add('col-4');
      container.appendChild(endDate);
    }

    const arrayOfDomNodes = [container];
    return { domNodes: arrayOfDomNodes };
  }

  addEvent() {
    const modalRef = this.ngbModal.open(BookSessionComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.bookSession.subscribe((session) => {
      this.calendarApi.refetchEvents();

      const currentModalRef = this.ngbModal.open(ManageSessionComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'xl',
      });
      currentModalRef.componentInstance.currentSession = session;
      currentModalRef.componentInstance.userHasAdminAccess =
        this.userHasAdminAccess;
    });
  }

  private getTrainingSessions(info, successCallback, failureCallback) {
    this.eventsService
      .getTrainingSessionInRange(info.startStr, info.endStr)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: EventSession[]) => {
          if (result.length) {
            const sessionObject = result.map((session) => {
              let eventDisplay = 'none';
              if (
                (session.skillId ===
                  Number(this.trainingCalFilters.value.trainingId) ||
                  !this.trainingCalFilters.value.trainingId) &&
                (session.instructors.findIndex(
                  (si) => si.id === this.trainingCalFilters.value.trainerId,
                ) !== -1 ||
                  !this.trainingCalFilters.value.trainerName) &&
                (session.locationId ===
                  Number(this.trainingCalFilters.value.locationId) ||
                  !this.trainingCalFilters.value.locationId)
              ) {
                eventDisplay = 'block';
              }
              return {
                id: session.id,
                title: session.title,
                start: session.startDate,
                end: session.endDate,
                display: eventDisplay,
                backgroundColor: this.selectColor(session.skillId),
                textColor: '#000000',
                extendedProps: {
                  instructors: session.instructors,
                  attendeeCount: session.attendeeCount,
                  attendedCount: session.attendedCount,
                  location: session.location,
                  locationId: session.locationId,
                  externalId: session.externalId,
                  externalClassroomId: session.externalClassroomId,
                  skillId: session.skillId,
                  maxCapacity: session.maxCapacity,
                  minCapacity: session.minCapacity,
                  isSessionCompleted: session.isSessionCompleted,
                },
              };
            });
            successCallback(sessionObject);
          }
        },
        error: (e) => {
          this.alertService.createAlert2(this.exceptionData.SESSIONS, e);
          failureCallback(e);
        },
      });
  }

  private selectColor(number) {
    const hue = number * 137.508; // use golden angle approximation
    return `hsl(${hue},50%,75%)`;
  }

  convertToNumber(n) {
    return Number(n);
  }
}
