export class Assessor {
  assessorId: number;
  personId: number;
  funcAreaId: number;
  funcAreaName: string;
  funcAreaDescription: string;
  inOrgBranch: boolean;
  personFamilyGivenName: string;
  orgUnitId: number;
  orgUnitName: string;
}
