import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Filter } from '../../../dynamic-groups/services/dynamic-groups.service';
import { JobRoleSettingsForm } from '../../../job-role/services/job-role.service';

@Component({
  selector: 'ug-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrl: './settings-card.component.scss',
})
export class SettingsCardComponent implements OnInit {
  @Input()
  formGroup!: FormGroup<JobRoleSettingsForm>;
  @Input()
  settingKey!: string;
  @Input()
  unitOptions!: Map<string, string>;
  @Input()
  fieldOptions!: Map<string, string>;
  @Input()
  selectedGroup!: Filter;
  @Input()
  settingSwitchText: string;
  @Input()
  settingTitle: string;
  @Input()
  controlTitle: string;
  @Input()
  controlText: string;
  @Output()
  onAssignButtonClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onDeleteButtonClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {}

  onAssignButtonClick() {
    this.onAssignButtonClickEvent.emit(true);
  }

  onDeleteButtonClick() {
    this.onDeleteButtonClickEvent.emit(true);
  }
}
