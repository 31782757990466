<div class="search">
  <div
    class="input-group"
    [ngClass]="{ 'border border-danger rounded': showMinCharMessage }"
  >
    <span class="input-group-text">
      <span class="fas fa-search text-center pt-1"></span>
    </span>
    <div class="form-control p-0">
      <ng-select
        [items]="trainingItems$ | async | keyvalue"
        [bindLabel]="'key'"
        [bindValue]="'value'"
        [minTermLength]="2"
        [loading]="trainingLoading"
        typeToSearchText="Please enter 3 or more characters"
        [typeahead]="trainingInput$"
        [(ngModel)]="selectedTraining"
        (ngModelChange)="onTrainingChange()"
        [notFoundText]="notFoundText"
        [clearSearchOnAdd]="true"
        (clear)="resetSearch()"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <span [ngOptionHighlight]="search">{{ item.key }}</span>
        </ng-template>
      </ng-select>
    </div>
    <button class="btn btn-secondary" (click)="resetSearch()" type="button">
      Reset
    </button>
  </div>
  <p *ngIf="showMinCharMessage" class="text-danger fst-italic">
    Minimum search criteria must be 3 characters.
  </p>
</div>
