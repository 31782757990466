<div class="card shadow">
  <div class="card-body">
    <ug-table
      [tableHeaders]="headers"
      [tableHeaderButtons]="headerButtons"
      [tableRowButtons]="rowButtons"
      [tableFilters]="filters"
      [tableRows]="competencies"
      [isLoading]="tableLoading"
      [showSelectBox]="true"
    ></ug-table>
  </div>
</div>
