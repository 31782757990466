<div ngbAccordion class="people">
  <div
    *ngFor="let jobRolePerson of jobRolePeople; let i = index"
    [collapsed]="true"
    ngbAccordionItem
  >
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>
        <div class="heading-section w-100">
          <div class="view-selection">
            <span
              class="badge rounded-pill mr-5"
              [ngClass]="
                jobRolePerson.isAdditional ? 'text-bg-info' : 'text-bg-success'
              "
            >
              {{ jobRolePerson.isAdditional ? 'Unassigned' : 'Assigned' }}
            </span>
            <span>
              {{
                jobRoleOrPerson === jobRoleOrPersonOptions.PERSON
                  ? jobRoleName
                  : jobRolePerson.personName
              }}
            </span>
          </div>
          <div class="mr-5">
            <span class="text-muted">Match score: </span>
            <span
              [ngClass]="
                'text-' + getScoreTrafficLight(jobRolePerson.jobRoleLevelScore)
              "
            >
              {{ jobRolePerson.jobRoleLevelScore | percent: '1.1-1' }}
            </span>
            <span
              [ngClass]="[
                'fas fa-xl',
                jobRolePerson.unmetMandatoryCount > 0
                  ? 'fa-exclamation-circle text-danger'
                  : 'fa-check-circle text-success'
              ]"
            >
            </span>
            <div class="assigned-status">
              <button
                *ngIf="jobRolePerson.isAdditional"
                class="btn btn-sm btn-outline-danger"
                (click)="removeComparison(jobRolePerson)"
                title="Remove comparison"
              >
                <span class="fas fa-times"></span>
              </button>
            </div>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <ug-role-competency-summary
            [jobRolePerson]="jobRolePerson"
            [showGraph]="jobRoleOrPerson === jobRoleOrPersonOptions.PERSON"
          ></ug-role-competency-summary>
        </ng-template>
      </div>
    </div>
  </div>
</div>
