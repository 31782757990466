<div class="fixed-top top-nav-offset">
  <div
    *ngFor="let alert of alerts; let i = index"
    [@fadeShrinkInOut]="'inOut'"
    class="alert alert-dismissable mb-1 mx-1 fade show"
    [ngClass]="alert.className"
    role="alert"
  >
    <div class="row">
      <div class="col-12 col-lg-1 text-center text-lg-start">
        <h5>
          <span [ngClass]="['badge', getAlertBadgeClass(i)]">{{
            alert.typeName
          }}</span>
        </h5>
      </div>
      <div class="col-12 col-lg-3 text-center text-lg-start">
        {{ alert.timestamp | date: 'medium' }}
      </div>
      <!-- <div class="col-12 col-lg-1 text-center text-lg-end">
        <h5>
          <span [ngClass]="['badge', getAlertBadgeClass(i)]">{{alert.typeName}}</span>
        </h5>
      </div> -->
      <!-- translationKey -->
      <div class="col-12 col-lg-6 text-center text-lg-start">
        [{{ alert.code }}]
        {{ alert.message | titlecase }}
      </div>
      <div class="col-12 col-lg-2 text-center text-lg-end">
        <button
          type="button"
          class="btn btn-sm btn-outline-dark"
          (click)="onAlertDismiss(alert.id)"
        >
          Dismiss{{ getAlertCountdown(i) }}
        </button>
      </div>
    </div>
    <!-- <button type="button" class="btn-close" (click)="onAlertDismiss(alert.id)" title="Dismiss alert"></button> -->
  </div>
  <!-- <p>{{alerts | json}}</p> -->
</div>
