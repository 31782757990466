import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IWarningUnits } from '../skill-warning';

@Component({
  selector: 'ug-default-amber',
  templateUrl: './default-amber.component.html',
  styleUrl: './default-amber.component.scss',
})
export class DefaultAmberComponent {
  @Input() defaultAmberWarningForm: FormGroup;
  @Input() units: IWarningUnits;

  ngOnInit() {}

  clearSettings() {
    this.defaultAmberWarningForm.get('value').setValue(null);
    this.defaultAmberWarningForm.get('unit').setValue(null);
    this.defaultAmberWarningForm.markAsDirty();
  }
}
