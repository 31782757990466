export class Person {
  adGUID?: string;
  adObjectGuid?: string;
  adUPN?: string;
  adUpn?: string;
  businessPhone: string;
  city: string;
  country: string;
  displayName: string;
  division?: string;
  externalAbsent?: boolean;
  externalActive: string;
  externalGUID?: string;
  externalGuid?: string;
  externalId: number;
  externalLastHireDate?: string;
  externalLastModifiedDatetime?: string;
  externalOriginalHireDate?: string;
  externalSource: string;
  externalTerminationDate?: string;
  externalUserId: string;
  externalUserName: string;
  familyGivenName: string;
  familyName: string;
  givenName: string;
  id: number;
  knownAsName: string;
  location?: string;
  mobilePhone: string;
  officeLocation: string;
  orgUnitId: number;
  orgUnitName?: string;
  organisationUnitId?: number;
  position?: string;
  postalCode: string;
  roleId: number;
  roleName?: string;
  rowVersion?: string;
  securityRoleId: number;
  state: string;
  streetAddress: string;
  supervisorId?: number;
  supervisorName?: string;
  supervisorPersonId?: number;
  watch?: string;
  watchOfficerOrAbove?: boolean;
}

export class BasicJobRolePerson {
  id: number;
  name: string;
  pendingDeletion: boolean;
}
