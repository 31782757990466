import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subject, finalize, takeUntil } from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { Skill } from '../../skill/models/skill';
import { SkillService } from '../../skill/services/skill.service';

@Component({
  selector: 'esqep-competency-attr-filter-list', // tslint:disable-line: component-selector
  templateUrl: './competency-attr-filter-list.component.html',
  styleUrls: ['./competency-attr-filter-list.component.scss'],
})
export class CompetencyAttrFilterListComponent
  implements OnChanges, OnDestroy, OnInit
{
  private alertService = inject(AlertService);
  private skillService = inject(SkillService);

  @Input() parentFormGroup: FormGroup;
  @Input() funcAreaId: number;
  @Input() filter = '';
  @Input() selectedCompAttr: Skill;
  @Input() ugDisabled = false;
  @Output() selectedCompAttrChange = new EventEmitter<Skill>();

  competencyAttrList: Array<Skill>;
  filteredCompetencyAttrList: Array<Skill>;
  requestInProgress = false;

  exceptionData = {
    COMP_ATTRS: {
      level: AlertLevels.ERROR,
      code: 'KNS-001',
      message: 'Error retrieving competency attributes',
    } as AlertData,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.funcAreaId) {
      return;
    }

    if (!this.competencyAttrList) {
      if (!this.requestInProgress) {
        this.requestInProgress = true;
        this.skillService
          .getCompetencyAttrs(this.funcAreaId)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.requestInProgress = false)),
          )
          .subscribe({
            next: (ca) => {
              this.competencyAttrList = ca;
              this.filteredCompetencyAttrList = this.competencyAttrList.filter(
                (el) =>
                  el.name
                    .toLowerCase()
                    .indexOf((this.filter || '').toLowerCase()) !== -1,
              );
            },
            error: (err) => {
              this.alertService.createAlert2(
                this.exceptionData.COMP_ATTRS,
                err,
              );
            },
          });
      }
    } else {
      this.filteredCompetencyAttrList = this.competencyAttrList.filter(
        (el) =>
          el.name.toLowerCase().indexOf((this.filter || '').toLowerCase()) !==
          -1,
      );
      if (
        this.selectedCompAttr &&
        !this.filteredCompetencyAttrList.find((el) => {
          return el.id === this.selectedCompAttr.id;
        })
      ) {
        this.selectedCompAttr = new Skill();
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onCompAttrClick(compAttr: Skill) {
    if (!this.ugDisabled) {
      if (this.selectedCompAttr && compAttr.id === this.selectedCompAttr.id) {
        this.selectedCompAttrChange.emit(new Skill());
      } else {
        this.selectedCompAttrChange.emit(compAttr);
      }
    }
  }

  showCompAttr(compAttr: Skill) {
    return !this.ugDisabled || compAttr.id === this.selectedCompAttr.id;
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
