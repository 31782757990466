import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomDateValidators {
  static afterDateValidator(
    fromDateField: string,
    toDateField: string,
    fieldName: string,
  ): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const fromDate = formGroup.get(fromDateField).value;
      const toDate = formGroup.get(toDateField).value;
      var startDateAsDate = new Date(fromDate);
      if (toDate) {
        var endDateAsDate = toDate.includes('T')
          ? new Date(toDate)
          : // this is when we only pass the value as date rather than date & time
            new Date(new Date(toDate).setHours(23, 59, 59, 999));

        const isRangeValid =
          endDateAsDate.getTime() - startDateAsDate.getTime() > 0;

        return isRangeValid ? null : { [fieldName]: true };
      }

      return null;
    };
  }
}
