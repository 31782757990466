<ug-list-status
  [isLoading]="isLoading"
  [listLength]="listLength"
></ug-list-status>
<div *ngIf="!isLoading">
  <div *ngIf="tableHeaderButtons" class="d-flex justify-content-end mb-1">
    <ng-container
      *ngFor="let headerBtn of tableHeaderButtons"
      [ngSwitch]="headerBtn.type"
    >
      <ng-container *ngSwitchCase="'button'">
        <button
          [hidden]="
            headerBtn.hideCondition
              ? hideConditionHeader(headerBtn.hideCondition)
              : false
          "
          type="button"
          (click)="headerButton(headerBtn.headerAction)"
          class="btn btn-sm ms-2"
          [ngClass]="headerBtn.class ? headerBtn.class : 'esqepBtn'"
        >
          {{ headerBtn.title }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'dropdown'">
        <div class="dropdown">
          <button
            [hidden]="
              headerBtn.hideCondition
                ? hideConditionHeader(headerBtn.hideCondition)
                : false
            "
            class="btn btn-sm btn-secondary dropdown-toggle ms-2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ headerBtn.title }}
          </button>
          <div class="dropdown-menu">
            <button
              *ngFor="let dropdownItem of headerBtn.dropdownItems"
              (click)="headerButton(dropdownItem.dropdownAction)"
              class="dropdown-item"
            >
              {{ dropdownItem.title }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="tableFilters" class="mb-2">
    <ug-dynamic-form
      [filters]="tableFilters"
      [form]="form"
      (filtersCleared)="clearFilters($event)"
    ></ug-dynamic-form>
  </div>
  <div *ngIf="!filteredRows?.length">
    <div class="alert alert-info">
      {{ tableNoDataMessage ? tableNoDataMessage : 'No data to display' }}
    </div>
  </div>
  <div *ngIf="filteredRows?.length">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" *ngIf="showSelectBox || singleSelection">
            <div *ngIf="!singleSelection && showSelectAll">
              <input
                type="checkbox"
                [(ngModel)]="selectAllRows"
                (change)="checkUncheckAllTableRows()"
              />
              <label class="ms-1"
                >Select all
                <small *ngIf="selectedRowsCache?.length > 0"
                  >({{ selectedRowsCache?.length }})</small
                ></label
              >
            </div>
            <div
              *ngIf="
                !singleSelection &&
                !showSelectAll &&
                selectedRowsCache?.length > 0
              "
            >
              ({{ selectedRowsCache?.length }})
            </div>
          </th>
          <ng-container *ngFor="let item of tableHeaders">
            <th
              scope="col"
              (sort)="onSort($event)"
              [sortable]="item.id"
              *ngIf="
                !item.notSortable &&
                (item.hideCondition ? !hideCondition(item.hideCondition) : true)
              "
              [ngClass]="item.class"
            >
              {{ item.title ?? item.id }}
              {{ item.notSortable }}
            </th>
            <th
              scope="col"
              *ngIf="
                item.notSortable &&
                (item.hideCondition ? !hideCondition(item.hideCondition) : true)
              "
              [ngClass]="item.class"
            >
              {{ item.title ?? item.id }}
            </th>
          </ng-container>
          <th scope="col" *ngIf="tableRowButtons && tableRowButtons?.length">
            Actions
          </th>
          <th scope="col" *ngIf="tableCollapsedRowData"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let row of filteredRows; index as i; trackBy: trackByFn"
        >
          <tr
            (click)="toggleRow(i)"
            [ngClass]="i % 2 === 0 ? 'striped-row' : null"
            class="align-items-center"
          >
            <td *ngIf="showSelectBox || singleSelection">
              <input
                *ngIf="showSelectBox"
                type="checkbox"
                [(ngModel)]="row.isSelected"
                (change)="onRowSelect(row)"
                [disabled]="disablePreSelected ? disableSelect(row) : false"
              />
              <ng-container *ngIf="singleSelection">
                <input
                  type="checkbox"
                  [(ngModel)]="row.isSelected"
                  (change)="onRowSelect(row)"
                  [disabled]="disableSelect(row)"
                />
              </ng-container>
            </td>
            <ng-container *ngFor="let col of tableHeaders">
              <td
                *ngIf="
                  col.hideCondition ? !hideCondition(col.hideCondition) : true
                "
              >
                <div
                  *ngIf="col?.iconFunction && col?.iconTooltip"
                  class="text-center"
                >
                  <span
                    class="icon"
                    [ngClass]="iconFunction(col.iconFunction, row)"
                    placement="top"
                    [ngbTooltip]="iconTooltip(col.iconTooltip, row)"
                  ></span>
                </div>
                <div
                  *ngIf="col?.iconFunction && !col?.iconTooltip"
                  class="text-center"
                >
                  <span
                    class="icon"
                    [ngClass]="iconFunction(col.iconFunction, row)"
                  ></span>
                </div>
                <div
                  *ngIf="col?.stringFunction"
                  [ngClass]="col.noWrap ? 'text-nowrap' : 'text-wrap'"
                >
                  <span> {{ stringFunction(col.stringFunction, row) }}</span>
                </div>
                <div *ngIf="col?.checkbox" class="text-center">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="row[col.id]"
                    (change)="onRowCheckboxChange(col.checkboxFunction, row, i)"
                  />
                </div>
                <div *ngIf="col?.dateFormat">
                  {{ row[col.id] | date: col.dateFormat }}
                </div>
                <div *ngIf="col?.dropdown">
                  <ng-select
                    class="tableDropdown"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    [searchable]="true"
                    [ngClass]="{ 'is-invalid': !row[col.id] }"
                    [items]="
                      !col.dropdownKeyValue
                        ? col.rowDropdowns[i]
                        : (col.rowDropdowns | keyvalue)
                    "
                    [bindLabel]="col.dropdownBindName"
                    [bindValue]="col.dropdownBindValue"
                    [(ngModel)]="row[col.id]"
                    [clearable]="false"
                    [placeholder]="col.inputPlaceholder"
                    [disabled]="
                      disabledDropdownFunction(col.disabledCondition, row)
                        ? true
                        : false
                    "
                    (change)="onRowDropdownChange(col.dropdownFunction, row, i)"
                  >
                  </ng-select>
                </div>
                <div *ngIf="col?.numberInput">
                  <input
                    class="form-control"
                    type="number"
                    min="1"
                    [ngClass]="!row[col.id] ? 'is-invalid' : ''"
                    [(ngModel)]="row[col.id]"
                    aria-label="Default amber warning value"
                    [disabled]="
                      disabledDropdownFunction(col.disabledCondition, row)
                        ? true
                        : false
                    "
                    (change)="
                      onRowInputNumberChange(col.numberInputFunction, row, i)
                    "
                  />
                </div>
                <div
                  *ngIf="
                    !col?.stringFunction &&
                    !col?.iconFunction &&
                    !col?.checkbox &&
                    !col?.dateFormat &&
                    !col.dropdown &&
                    !col.numberInput
                  "
                  [ngClass]="col.noWrap ? 'text-nowrap' : 'text-wrap'"
                >
                  <ngb-highlight
                    [result]="row[col.id]"
                    [term]="filters['searchTerm']"
                  ></ngb-highlight>
                </div>
              </td>
            </ng-container>
            <td *ngIf="tableCollapsedRowData">
              <div class="w-100 text-center mt-1">
                <i
                  class="me-2"
                  [ngClass]="
                    rowIndex === i
                      ? 'fa fa-chevron-down'
                      : 'fa fa-chevron-right'
                  "
                ></i>
              </div>
            </td>
            <td *ngIf="tableRowButtons">
              <ng-container *ngFor="let action of tableRowButtons">
                <button
                  type="button"
                  [hidden]="
                    action.hideCondition
                      ? hideCondition(action.hideCondition, row)
                      : false
                  "
                  class="btn btn-sm me-2"
                  (click)="actionButton(action.rowAction, row)"
                  [title]="action.title"
                  [ngClass]="action.class ? action.class : 'esqepBtn'"
                >
                  {{ action.title }}
                </button>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="tableCollapsedRowData">
            <td colspan="12" class="p-0 m-0">
              <div [ngbCollapse]="rowIndex != i" class="my-1 card m-auto">
                <div class="card-body">
                  <div class="h6 card-title mb-0">
                    {{ tableCollapsedRowTitle }}
                  </div>
                  <div class="h6 card-subtitle text-muted mb-1">
                    {{ tableCollapsedRowSubHeadings[i] }}
                  </div>
                  <div>
                    {{ tableCollapsedRowData[i] }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div
      class="border-top border-secondary"
      *ngIf="
        showPagination ||
        (tableSelectedButtons && tableSelectedButtons.length > 0)
      "
    >
      <div
        class="mt-3"
        [ngClass]="showPagination ? 'd-flex justify-content-between' : 'w-100'"
      >
        <div *ngIf="showPagination" class="d-flex flex-wrap">
          <ngb-pagination
            [collectionSize]="total$ | async"
            [(page)]="page"
            [pageSize]="pageSize"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            [maxSize]="5"
            (pageChange)="onPageChange($event)"
          >
          </ngb-pagination>
          <div class="d-flex mt-2 ms-1">
            <label for="pageSize" class="me-2">Rows per page:</label>
            <select
              class="form-select-sm pageNoSize"
              name="pageSize"
              [(ngModel)]="pageSize"
              id="pageSize"
              (ngModelChange)="onPageSizeChange($event)"
            >
              <option *ngFor="let ps of pageSizeOptions" [ngValue]="ps">
                {{ ps }}
              </option>
            </select>
          </div>
        </div>
        <div class="button-group">
          <div class="text-end">
            <ng-container *ngFor="let selectBtn of tableSelectedButtons">
              <button
                *ngIf="selectBtn.cancelButton"
                type="button"
                [hidden]="selectBtn.hideCondition"
                [disabled]="selectBtn.disabledCondition"
                class="btn btn-outline-secondary btn-md cancel-button"
                (click)="cancel(selectBtn?.function)"
              >
                {{ selectBtn.title }}
              </button>
            </ng-container>
            <ng-container *ngFor="let selectBtn of tableSelectedButtons">
              <button
                *ngIf="!selectBtn.cancelButton"
                type="button"
                [hidden]="
                  selectBtn.hideCondition
                    ? hideConditionHeader(selectBtn.hideCondition)
                    : false
                "
                (click)="getSelectedItems(selectBtn?.function)"
                class="btn"
                [ngClass]="selectBtn?.class"
                [disabled]="
                  (
                    selectBtn.disabledCondition
                      ? hideConditionHeader(selectBtn.disabledCondition)
                      : disableAddSelected && selectedRows === 0
                  )
                    ? true
                    : false
                "
              >
                <i class="fa fa-spinner fa-spin me-2" *ngIf="selectLoading"></i
                >{{ selectBtn.title }}
              </button>
            </ng-container>
          </div>
          <div>
            <ng-container *ngFor="let selectBtn of tableSelectedButtons">
              <p
                *ngIf="
                  selectBtn.disabledCondition && selectBtn.disabledMessage
                    ? hideConditionHeader(selectBtn.disabledCondition)
                    : false
                "
                class="text-danger"
              >
                {{ selectBtn.disabledMessage }}
              </p>
              <p
                *ngIf="
                  selectedRowsCache.length &&
                  selectBtn.warningMessageFunction &&
                  selectedWarningMessage(selectBtn.warningMessageFunction)
                "
                class="text-danger"
              >
                {{ selectedWarningMessage(selectBtn.warningMessageFunction) }}
              </p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
