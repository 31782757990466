import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { take } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  LangDefinition,
  TranslocoService,
  provideTranslocoScope,
} from '@jsverse/transloco';
import { AuthenticationConfigNames } from '../../core/authenticationConfigNames';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { LogLevel, Logger } from '../../core/services/config.service';
import { PersonService } from '../services/person.service';

@Component({
  selector: 'ug-person-detail-navbar',
  templateUrl: './person-detail-navbar.component.html',
  styleUrls: ['./person-detail-navbar.component.scss'],
  providers: [provideTranslocoScope('person-details')],
})
export class PersonDetailNavbarComponent implements OnInit {
  @Input() personUPN;

  private service = inject(TranslocoService);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private personService = inject(PersonService);
  private sanitizer = inject(DomSanitizer);
  private destroyRef = inject(DestroyRef);
  private personPhotoFailure = false;

  protected availableLangs =
    this.service.getAvailableLangs() as LangDefinition[];
  protected person: LoggedInUser;
  protected personPhotoUrl: SafeResourceUrl;
  protected isLoading = true;

  exceptionData = {
    PERSON_UUID: {
      level: AlertLevels.ERROR,
      code: 'PDT-001',
      message: 'Unable to retrieve user data',
    } as AlertData,
    PERSON_PHOTO: {
      level: AlertLevels.WARNING,
      code: 'PDT-002',
      message: 'Unable to retrieve user photo',
    } as AlertData,
  };

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  get activeLang() {
    return this.service.getActiveLang();
  }

  changeLang(lang: string) {
    this.service
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.service.setActiveLang(lang);
      });
  }

  ngOnInit() {
    if (!this.personUPN) {
      this.personUPN = this.authService.me.adUpn;
    }
    this.person = this.authService.me;
    this.isLoading = false;
    if (
      localStorage.getItem('esqep.authType') === AuthenticationConfigNames.ENTRA
    ) {
      this.personService
        .getUserPhoto(this.authService.userGUID)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (photo) => {
            let photoUrl = '';
            const photoBlob = new Blob([photo], { type: photo.type });
            if (
              photoBlob.type === 'image/jpeg' ||
              photoBlob.type === 'image/png'
            ) {
              photoUrl = window.URL.createObjectURL(photoBlob);
              this.personPhotoUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(photoUrl);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.personPhotoFailure = true;
            this.alertService.createAlert2(
              this.exceptionData['PERSON_PHOTO'],
              err,
            );
          },
          complete: () => Logger('Got Person photo', LogLevel.VERBOSE),
        });
    }
  }

  logout() {
    this.authService.logout();
  }
}
