<div class="card" [formGroup]="parentFormGroup">
  <div class="card-body">
    <h5 class="card-title">Attach Documentary Evidence</h5>
    <div class="">
      <div class="">
        <label class="form-label" for="evidenceFileSelect"
          >Select Document...</label
        >
        <input
          class="form-control"
          id="evidenceFileSelect"
          type="file"
          accept=".pdf,.jpg,.png"
          name="eviFile"
          (change)="onEviFileChange($event)"
          placeholder="Upload evidence file"
        />
      </div>
      <span class="form-text text-end small"
        >Supported file types: PDF, JPG, PNG</span
      >
    </div>
  </div>
  <div class="card-body pt-0">
    <h6 class="card-title">Attached Files</h6>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let eviFile of evidenceFileList">
        {{ eviFile.name }}
      </li>
    </ul>
  </div>
</div>
