import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
// import { Subscription } from 'rxjs/Subscription';
// import { ListMessageComponent } from '../core/list-message.component';
// import { ListStatusComponent } from '../controls/list-status.component';

import { Notification } from '../models/notification';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { PersonService } from '../services/person.service';

@Component({
  selector: 'ug-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: [],
})
export class NotificationListComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private personService = inject(PersonService);

  @Input() cardTitle = 'Notifications';
  @Input() personId: number;
  isLoading = true;
  unreadOnly = true;
  notificationList: Array<Notification>;

  // getNotificationsSubs = new Subscription();
  // writeNotificationSubs = new Subscription();
  private ngUnsubscribe: Subject<boolean> = new Subject();

  exceptionData = {
    GET_NOTIFICATIONS: {
      level: AlertLevels.ERROR,
      code: 'NOL-001',
      message: 'Error retrieving notifications',
    } as AlertData,
    UPDATE_NOTIFICATION: {
      level: AlertLevels.ERROR,
      code: 'NOL-002',
      message: 'Error updating notifications',
    } as AlertData,
  };

  ngOnChanges() {
    if (!this.personId) {
      return;
    }
    this.isLoading = true;
    // this.getNotificationsSubs.unsubscribe(); // TODO: Unsubs
    // this.getNotificationsSubs = this.personService.getNotificationsByPersonId(this.personId)
    this.personService
      .getNotificationsByPersonId(this.personId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (n) => {
          this.notificationList = n;
          this.isLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.GET_NOTIFICATIONS,
            err
          );
        },
      });
  }

  ngOnDestroy() {
    // this.getNotificationsSubs.unsubscribe();
    // this.writeNotificationSubs.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onNotificationReadClick(notificationIndex: number) {
    // this.writeNotificationSubs = this.personService.setNotificationAsRead(this.personId, this.notificationList[notificationIndex])
    this.personService
      .setNotificationAsRead(
        this.personId,
        this.notificationList[notificationIndex]
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (n) => (this.notificationList[notificationIndex] = n),
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.UPDATE_NOTIFICATION,
            err
          );
        },
      });
  }

  get unreadNotificationCount(): number {
    if (!this.notificationList) {
      return -1;
    }
    return this.notificationList.reduce((count, n) => {
      return count + (n.isUnread === true ? 1 : 0);
    }, 0);
  }

  get listDisplayMessage(): string {
    let msg = 'No Notifications';
    if (this.unreadOnly === true && this.unreadNotificationCount < 1) {
      msg = 'No Unread Notifications';
    }
    return msg;
  }

  get notificationDisplayCount(): number {
    let count = -1;
    if (this.unreadOnly) {
      count = this.unreadNotificationCount;
    } else {
      count = this.notificationList.length || -1;
    }
    return count;
  }
}
