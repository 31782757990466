<form [formGroup]="testFormGroup" class="form">
  <div class="card">
    <div class="card-header">Filter skills by:</div>
    <div class="card-body">
      <div formArrayName="testControls">
        <div
          *ngFor="
            let control of testFormGroup.controls['testControls']['controls'];
            let i = index
          "
        >
          <div class="form-group row">
            <label for="filter{{ i }}" class="col-md-3 col-form-label">{{
              jobFamilyTypeList[i]
            }}</label>
            <div class="col-md-7">
              <select
                class="form-select"
                [formControlName]="i"
                (change)="ngOnSelectChange(i)"
                id="filter{{ i }}"
              >
                <option [value]="0" selected disabled>Show All</option>
                <option *ngFor="let cjf of childJobFamily[i]" [value]="cjf.id">
                  {{ cjf.name }}
                </option>
              </select>
            </div>
            <!--<div class="col-md-2">
              <button class="btn btn-outline-secondary btn-outline-danger" (click)="onRemoveFilter(i)">X</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<table class="table table-responsive table-sm table-hover table-striped">
  <thead>
    <tr>
      <th>Competency Application</th>
      <th>Competency Subcategory</th>
      <th>Competency</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{ 'table-success': selectedJobFamilyIdx === j }"
      *ngFor="let skill of skillList | async; let j = index"
      (click)="onJobFamilyClick(j, skill)"
    >
      <td>{{ skill.funcAreaDesc }}</td>
      <td>{{ skill.funcAreaName }}</td>
      <td>
        <b>{{ skill.name }}</b>
      </td>
      <!--<td><span class="fas fa-info-circle"></span></td>-->
    </tr>
  </tbody>
</table>
<div class="card">
  <div class="card-body">
    <b>Competency Subcategory:</b>
    {{ selectedSkill?.description || 'Not selected' }}
  </div>
</div>
<!--
<p>{{parentJobFamily | json}}</p>
<p>{{childJobFamily | json}}</p>
<p>Form Group: {{ testFormGroup.value | json}}</p>
<p>Job Family Types: {{ jobFamilyTypeList | json}}</p>
<p>Job Family List: {{ jobFamilyList | json}}
-->
