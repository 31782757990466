import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'esqep-form-error-list', // tslint:disable-line: component-selector
  templateUrl: './form-error-list.component.html',
  styleUrls: ['./form-error-list.component.scss'],
})
export class FormErrorListComponent {
  @Input() sourceFormGroup: FormGroup;

  errorLookup: any = {
    required: { description: 'This field is mandatory' },
    competencyAttrRequired: { description: 'A competency must be selected' },
    competencyAttrBeforeLevel: {
      description:
        'Select a competency before selecting assesment request level',
    },
    competencyAttrBeforeVerifier: {
      description: 'Select a competency before selecting assessor',
    },
    levelRequired: {
      description: 'An assessment request level must be selected',
    },
    verifierRequired: {
      description: 'A assessor must be selected',
    },
    verifierRequiredForLevel: {
      description:
        'A assessor must be specified for this assessment request level',
    },
    verifierChangeReasonRequired: {
      description: 'A reason must be provided for the change in assessor',
    },
    evidenceStatementRequired: {
      description: 'An evidence statement must be provided',
    },
    verifierStatementRequired: {
      description: 'A statement about the verification action must be supplied',
    },
    verificationActionRequired: {
      description: 'A verification action must be selected',
    },
  };

  getErrorList(topFormGroup: FormGroup): Array<string> {
    const errorArray: Array<string> = [];
    this.getErrorMap(topFormGroup, new Map()).forEach((v, k) =>
      errorArray.push(this.errorLookup[k] || k),
    );
    return errorArray;
  }

  getErrorMap(topFormControl: FormGroup, errorMap: Map<string, boolean>): any {
    if (topFormControl.errors) {
      for (const errorKey of Object.keys(topFormControl.errors)) {
        errorMap.set(errorKey, true);
      }
    }
    if (topFormControl.controls) {
      for (const control in topFormControl.controls) {
        if (control) {
          errorMap = this.getErrorMap(
            <FormGroup>topFormControl.get(control),
            errorMap,
          );
        }
      }
    }
    return errorMap;
  }
}
