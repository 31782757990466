<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
      [attr.disabled]="updatesInProgress ? true : null"
    ></button>
  </div>
  <div class="modal-body">
    <ug-progress-bar
      [progressTitle]="'Update'"
      [progressTotal]="updatesTotal"
      [progressSuccess]="updatesDone"
      [progressFailed]="updatesFailed"
    ></ug-progress-bar>

    <div class="mt-1" *ngIf="failedSectionRows.length">
      <ug-table
        [tableHeaders]="headers"
        [tableRows]="failedSectionRows"
      ></ug-table>
    </div>
  </div>
</div>
