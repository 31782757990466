import { Component, EventEmitter, Input, OnChanges, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { SkillService } from '../../skill/services/skill.service';

import { ClaimLevel } from '../../claim/models/claim-level';

@Component({
  selector: 'esqep-level-select-form', // tslint:disable-line:component-selector
  templateUrl: './level-select-form.component.html',
  styleUrls: ['./level-select-form.component.scss'],
})
export class LevelSelectFormComponent implements OnInit, OnChanges {
  private alertService = inject(AlertService);
  private skillService = inject(SkillService);
  private fb = inject(FormBuilder);

  claimLevels = new Array<ClaimLevel>();
  cl: ClaimLevel;
  amend = false;
  savedSkillId: number;
  savedClaimLevelSetId: number;

  @Input() parentFormGroup: FormGroup;
  @Input() skillId: number;
  @Input() level: ClaimLevel;
  @Input() controlStatus = { disabled: false, reason: '' };
  @Input() editMode = false;

  @Output() levelChange = new EventEmitter<ClaimLevel>();

  exceptionData = {
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'CLC-001',
      message: 'Error retrieving claim levels',
      translationKey: 'errRetrievingAssessmentReqLevels',
    } as AlertData,
  };

  ngOnInit() {}

  ngOnChanges() {
    if (this.controlStatus.disabled && this.parentFormGroup.enabled) {
      setTimeout(() => {
        this.parentFormGroup.disable();
      }, 0);
    } else if (!this.controlStatus.disabled && this.parentFormGroup.disabled) {
      setTimeout(() => {
        this.parentFormGroup.enable();
      }, 0);
    }
    if (!this.skillId) {
      this.claimLevels = [];
      this.savedClaimLevelSetId = null;
      this.savedSkillId = null;
    }
    if (this.savedSkillId === this.skillId) {
      return;
    }

    this.savedSkillId = this.skillId;
    this.skillService.getLevels(this.skillId).subscribe(
      (claimLevels: Array<ClaimLevel>) => {
        this.claimLevels = claimLevels;
        if (this.claimLevels.length > 0) {
          if (
            this.savedClaimLevelSetId !== this.claimLevels[0].claimLevelSetId
          ) {
            this.savedClaimLevelSetId = this.claimLevels[0].claimLevelSetId;
            const matchedClaimLevel = this.claimLevels.find(
              (cl) => cl.id === this.level.id,
            );
            this.levelChange.emit(matchedClaimLevel || new ClaimLevel());
          }
        } else {
          this.level = new ClaimLevel();
        }
      },
      (err) => {
        this.alertService.createAlert2(this.exceptionData.CLAIM_LEVELS, err);
      },
    );
  }

  onClaimLevelChange() {
    const newClaimLevel = this.claimLevels.find((cl) => {
      return cl.id === this.parentFormGroup.get('id').value;
    });
    this.levelChange.emit(newClaimLevel || new ClaimLevel());
  }

  equals(cl1: ClaimLevel, cl2: ClaimLevel) {
    return cl1 === cl2;
  }
}
