import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ug-date-time-session-display',
  templateUrl: './date-time-session-display.component.html',
  styleUrl: './date-time-session-display.component.scss',
})
export class DateTimeSessionDisplayComponent implements OnInit {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() customClass: string;

  isStartEndDateInSameDay = false;
  ngOnInit(): void {
    this.isStartEndDateInSameDay =
      this.startDate && this.endDate
        ? new Date(this.startDate).getUTCDate() ===
          new Date(this.endDate).getUTCDate()
        : false;
  }
}
