import { Component, inject, Input } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { MenuItem, Subset } from '../../menu-item';

@Component({
  selector: 'ug-mini-menu',
  templateUrl: './mini-menu.component.html',
  styleUrls: ['./mini-menu.component.scss'],
})
export class MiniMenuComponent {
  private authService: AuthService = inject(AuthService);

  @Input() menuLoading = true;
  @Input() menuData: Array<MenuItem | Subset<MenuItem>> = [];
  @Input() claimNotificationMap = new Map<string, number>();

  subMenu: string;
  visible: boolean = false;

  toggleCollapse(): void {
    this.subMenu = null;
  }

  get menuReady(): boolean {
    return !this.menuLoading && this.authService.isAuthenticated;
  }

  toggleSubMenu(itemName: string) {
    this.subMenu = this.subMenu === itemName ? null : itemName;
  }
}
