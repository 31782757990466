import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { catchError, map, mergeMap, throwError } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { FuncArea } from '../../skill/models/func-area';
import { SkillService } from '../../skill/services/skill.service';

@Component({
  selector: 'ug-job-family-select-control',
  templateUrl: './job-family-select-control.component.html',
  styleUrls: [],
})
export class JobFamilySelectControlComponent implements OnInit {
  private fb = inject(FormBuilder);
  private alertService = inject(AlertService);
  private skillService = inject(SkillService);

  filterFormArray: FormArray;
  filterFormGroup: FormGroup;

  parentJobFamily: Array<FuncArea>;
  childJobFamily = {};
  jobFamilyList = {};
  jobFamilyTypeList = {};

  currentJobFamilyId: number;
  // selectedJobFamily: FuncArea;
  selectedJobFamilyIdx = -1;

  @Input() selectedJobFamily: FuncArea;
  @Output() selectedJobFamilyChange = new EventEmitter<FuncArea>();
  // @Output() jobFamilyChange: EventEmitter<FuncArea> = new EventEmitter();

  exceptionData = {
    FUNC_AREA: {
      level: AlertLevels.ERROR,
      code: 'JFC-001',
      message: 'Error retrieving functional area',
      translationKey: 'errRetrievingFuncArea',
    } as AlertData,
    CHILD_FUNC_AREAS: {
      level: AlertLevels.ERROR,
      code: 'JFC-002',
      message: 'Error retrieving child functional areas',
      translationKey: 'errRetrievingChildFuncArea',
    } as AlertData,
  };
  enableResetButton: boolean;

  constructor() {
    this.filterFormArray = new FormArray([]);
    this.filterFormGroup = this.fb.group({
      filterControls: this.filterFormArray,
    });
  }

  ngOnInit() {
    this.parentJobFamily = [];
    this.skillService
      .getFuncArea(0)
      .pipe(
        map((jobFamilies: Array<FuncArea>) => {
          this.parentJobFamily.push(jobFamilies[0]);
          // this.childJobFamily[0] = [];
          // this.jobFamilyTypeList[0] = '';
          this.filterFormArray.push(new FormControl());
          // this.filterFormArray.controls[0].setValue(0);
          return jobFamilies[0];
        }),
        mergeMap((jobFamily) =>
          this.skillService.getChildFuncAreas(jobFamily.id),
        ),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      )
      .subscribe({
        next: (childJobFamilies) => {
          this.childJobFamily[0] = childJobFamilies;
          this.jobFamilyTypeList[0] = childJobFamilies[0].funcAreaTypeName;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.FUNC_AREA, err);
        },
      });
  }

  onSelectChange(filterIdx: number) {
    this.enableResetButton = false;
    while (this.filterFormArray.length > filterIdx + 1) {
      this.filterFormArray.removeAt(filterIdx + 1);
      this.selectedJobFamilyIdx = -1;
    }

    this.parentJobFamily.length = filterIdx + 1;
    const selectedControlIdx = this.filterFormArray.controls[filterIdx].value;
    this.selectedJobFamily = this.childJobFamily[filterIdx][selectedControlIdx];
    const selectedJobFamilyId = this.selectedJobFamily.id;
    // const selectedJobFamilyId = this.filterFormArray.controls[filterIdx].value;
    this.currentJobFamilyId = selectedJobFamilyId;
    console.log(selectedJobFamilyId);
    // this.jobFamilyChange.emit(this.selectedJobFamily);
    this.selectedJobFamilyChange.emit(this.selectedJobFamily);
    this.skillService.getFuncArea(selectedJobFamilyId).subscribe(
      (pjf: Array<FuncArea>) => {
        // if (pjf[0].funcAreaTypeName !== 'Discipline') {
        if (
          !['Discipline', 'Curriculum Section'].includes(
            pjf[0].funcAreaTypeName,
          )
        ) {
          this.parentJobFamily.push(pjf[0]);
          if (!this.jobFamilyTypeList[filterIdx + 1]) {
            this.jobFamilyTypeList[filterIdx + 1] = '';
          }
          this.jobFamilyList[pjf[0].id] = pjf[0];
        }
      },
      (err) => {
        this.alertService.createAlert2(this.exceptionData.FUNC_AREA, err);
      },
    );
    this.skillService.getChildFuncAreas(selectedJobFamilyId).subscribe(
      (cjf: Array<FuncArea>) => {
        if (cjf.length > 0) {
          this.childJobFamily[filterIdx + 1] = cjf;
          // this.filterJobFamily({parentId: cjf[0].parentFuncAreaId, discId: 0 });
          this.filterFormArray.push(new FormControl());
          this.filterFormArray.controls[filterIdx + 1].setValue(null);
          this.childJobFamily[filterIdx + 1] = cjf;
          this.jobFamilyTypeList[filterIdx + 1] = cjf[0].funcAreaTypeName;
          cjf.forEach((element) => {
            this.jobFamilyList[element.id] = element;
          });
        } else {
          // this.filterJobFamily({parentId: this.parentJobFamily[filterIdx].id, discId: selectedJobFamilyId });
        }
        this.enableResetButton = true;
      },
      (err) => {
        this.alertService.createAlert2(
          this.exceptionData.CHILD_FUNC_AREAS,
          err,
        );
      },
    );
    // this.selectedJobFamilyChange.emit(this.selectedJobFamily);
  }

  onResetClick() {
    this.filterFormArray.controls[0].setValue(null);
    while (this.filterFormArray.length > 1) {
      this.filterFormArray.removeAt(this.filterFormArray.length - 1);
      this.selectedJobFamilyIdx = -1;
    }

    this.selectedJobFamilyChange.emit(null);
  }
}
