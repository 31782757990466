import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface AttributeField {
  entityPath: string;
  name: string;
  dataType: string;
  isMathematical: boolean;
  isHierarchical: boolean;
  isNullable: boolean;
  lookupEntity: string;
  lookupParentKey: string;
  useSearchableLookup: boolean;
  operators: Array<OperatorField>;
}

export interface OperatorField {
  operator: string;
  name: string;
  noValueRequired: boolean;
}

export interface LookupValue {
  key: string;
  name: string;
  parentKey: string;
}

export interface EntityValues {
  data: [any];
  pageSize: number;
  page: number;
  totalPageCount: number;
  totalRowCount: number;
}

export interface EntityFilterData {
  filter: string;
  pageSize: number;
  page: number;
}

export interface FilterPayload {
  entity: string;
  filterJson: string;
  uiFilterInfo: string;
  name: string;
  description: string;
  errorMessage: string;
}

export interface Filter {
  exists?: any;
  id: number;
  jobRoleIds: Array<number>;
  entity: string;
  filterJson: string;
  uiFilterInfo: string;
  name: string;
  description: string;
  errorMessage: string;
  runMilliseconds: number;
}

export enum JoinOperator {
  And = 'and',
  Or = 'or',
}

export class GroupFilterAudit {
  id: number;
  filterId: string;
  entity: string;
  filterJson: string;
  name: string;
  description: string;
  uiFilterInfo: string;
  errorMessage: string;
  lastModifiedPersonId: string;
  lastModifiedDate: Date;
  auditStartDate: Date;
  auditEndDate: Date;
  auditStartOperation: string;
  auditEndOperation: string;
  auditStartPersonId: number;
  auditStartPersonDisplayName: string;
  auditStartPersonUsername: string;
  auditEndPersonId: number;
  auditEndPersonDisplayName: string;
  auditEndPersonUsername: string;
  auditStartTransactionId: number;
  auditEndTransactionId: number;
}

export enum InvalidFilter {
  Attribute = 'Invalid Attribute',
  Value = 'Invalid Value',
  Operator = 'Invalid Operator',
}

export interface SearchData {
  parentKey: string;
  search: string;
  maxCount: number;
}

const apiServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class DynamicGroupsService {
  private http = inject(HttpClient);


  createGroupsSummary(filterArray) {
    const groupsSummary = [];
    if (
      this.checkTypes(filterArray) ||
      filterArray.every((f) => this.checkTypes(f))
    ) {
      const group = this.createGroupSummary(filterArray);
      groupsSummary.push(group);
    } else {
      filterArray.forEach((f, i) => {
        if (Array.isArray(f)) {
          const group = this.createGroupSummary(f);
          groupsSummary.push(group);
        } else {
          groupsSummary.push(f);
        }
      });
    }

    return groupsSummary;
  }

  createGroupSummary(group) {
    let groupSummary = [];

    if (this.checkTypes(group)) {
      const condition = this.createConditionSummary(group);
      groupSummary.push(condition);
    } else {
      group.forEach((f, i) => {
        if (Array.isArray(f)) {
          const condition = this.createConditionSummary(f);
          groupSummary.push(condition);
        } else {
          groupSummary.push(f);
        }
      });
    }

    return groupSummary;
  }

  createConditionSummary(filterCondition) {
    let condition = Array.isArray(filterCondition)
      ? [filterCondition[0], filterCondition[1], filterCondition[2]].join(' ')
      : filterCondition;
    return condition;
  }

  checkTypes(filterItem): boolean {
    if (Array.isArray(filterItem)) {
      return typeof filterItem[0] === 'string';
    } else if (filterItem === 'and' || filterItem === 'or') {
      return true;
    } else {
      return false;
    }
  }

  getAttributeFields(entity: string): Observable<Array<AttributeField>> {
    return this.http.get(`${apiServerUri}/filterinfo/fields/${entity}`).pipe(
      map((resp) => {
        return resp as any;
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getLookupValues(
    lookupEntity: string,
    lookupParentKey?: string,
  ): Observable<Array<LookupValue>> {
    const params = lookupParentKey
      ? `${lookupEntity}/${lookupParentKey}`
      : `${lookupEntity}`;
    return this.http.get(`${apiServerUri}/lookups/${params}`).pipe(
      map((resp) => {
        return resp as any;
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }
  getFilteredEntityValuesPaged(
    entity: string,
    filterData: EntityFilterData,
  ): Observable<EntityValues> {
    return this.http
      .post(`${apiServerUri}/data/${entity}/paged`, filterData)
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }
  getFilteredEntityValues(
    entity: string,
    filterData: EntityFilterData,
  ): Observable<Array<any>> {
    return this.http.post(`${apiServerUri}/data/${entity}`, filterData).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getFiltersByEntity(entity: string): Observable<Array<Filter>> {
    return this.http.get(`${apiServerUri}/filters/byEntity/${entity}`).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getFilterById(id: number): Observable<Filter> {
    return this.http.get(`${apiServerUri}/filters/${id}`).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  deleteFilterById(id: number): Observable<Filter> {
    return this.http.delete(`${apiServerUri}/filters/${id}`).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  updateFilterById(id: number, filterData: FilterPayload): Observable<Filter> {
    return this.http.post(`${apiServerUri}/filters/${id}`, filterData).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  addFilter(filterData: FilterPayload): Observable<Filter> {
    return this.http.post(`${apiServerUri}/filters`, filterData).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        const errorMessage =
          err.error?.name || err.error?.Name
            ? 'Duplicated Name'
            : `${err.status} ${err.statusText}`;
        return throwError(() => new Error(errorMessage));
      }),
    );
  }

  listGroupFilterAudit(id: number): Observable<Array<GroupFilterAudit>> {
    return this.http.get(`${apiServerUri}/filterAudits/byFilterId/${id}`).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  filterSearchLookup(entity: string, filterData: SearchData): Observable<any> {
    return this.http.post(`${apiServerUri}/lookups/${entity}`, filterData).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }
}
