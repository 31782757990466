import { Component, OnInit, inject } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { DashboardService } from '../services/dashboard.service';
import { IncompleteClaim } from './incomplete-claim';
import {
  IncompleteExpressClass,
  IncompleteExpressClassPerson,
} from './incomplete-express-class';

@Component({
  selector: 'ug-incomplete-express-class',
  templateUrl: './incomplete-express-class.component.html',
  styleUrls: ['./incomplete-express-class.component.css'],
})
export class IncompleteExpressClassComponent implements OnInit {
  private alertService = inject(AlertService);
  private dashboardService = inject(DashboardService);

  exceptionData = {
    INCOMPLETE_EXPRESS_CLASS: {
      level: AlertLevels.ERROR,
      code: 'IEC-001',
      message: 'Error retrieving incomplete express class history',
    } as AlertData,
    FAILED_EXPRESS_CLASS_RESUBMIT: {
      level: AlertLevels.ERROR,
      code: 'IEC-002',
      message: 'Error resubmitting express class',
    } as AlertData,
    FAILED_EXPRESS_CLASS_DISCARD: {
      level: AlertLevels.ERROR,
      code: 'IEC-002',
      message: 'Error resubmitting express class',
    } as AlertData,
  };

  private ngUnsubscribe: Subject<boolean> = new Subject();

  incompleteClaimsList: Array<IncompleteClaim> = [];
  incompleteClaimSummaryMap: Map<string, IncompleteExpressClass> = new Map<
    string,
    IncompleteExpressClass
  >();

  title = 'Incomplete Express Class';

  isLoading = true;

  awaitResubmitConfirmation: boolean = false;
  ecResubmitIdx: number;
  awaitDiscardConfirmation: boolean = false;
  ecDiscardIdx: number;

  ngOnInit(): void {
    this.populateIncompleteSummMap();
  }

  private populateIncompleteSummMap() {
    this.incompleteClaimSummaryMap.clear();
    this.dashboardService.getIncompleteClaims().subscribe(
      (incompleteClaims) => {
        this.incompleteClaimsList = incompleteClaims;
        incompleteClaims.forEach((ic) => {
          let incompleteExpressClass = this.incompleteClaimSummaryMap.get(
            ic.expressClassId,
          );
          if (!incompleteExpressClass) {
            incompleteExpressClass = {
              expressClassId: ic.expressClassId,
              proxyId: ic.proxyId,
              skillName: ic.skillName,
              completionDate: new Date(ic.eSqepCompletedDate),
              modificationDate: new Date(ic.eSqepLastModifiedDate),
              processed: ic.processed,
              people: new Set<IncompleteExpressClassPerson>(),
            } as IncompleteExpressClass;
          }
          incompleteExpressClass.people.add({
            displayName: ic.displayName,
            externalUserId: ic.externalUserId,
            externalId: ic.externalId,
            personId: ic.personId,
          } as IncompleteExpressClassPerson);
          this.incompleteClaimSummaryMap.set(
            ic.expressClassId,
            incompleteExpressClass,
          );
          this.isLoading = false;
        });
      },
      (err) =>
        this.alertService.createAlert2(
          this.exceptionData.INCOMPLETE_EXPRESS_CLASS,
          err,
        ),
    );
  }

  get summaryList(): Array<IncompleteExpressClass> {
    return Array.from(this.incompleteClaimSummaryMap.values()).filter(
      (sl) => sl.completionDate.getTime() + 7200000 < new Date().getTime(),
    );
  }

  formatPeopleList(peopleList: Set<IncompleteExpressClassPerson>): string {
    const people = Array.from(peopleList.values());
    return people
      .map((p) => `${p.displayName} [${p.externalUserId}]`)
      .join(', ');
  }

  resubmitExpressClass(expressClassId: string) {
    this.dashboardService
      .resubmitExpressClass(expressClassId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.awaitResubmitConfirmation = false;
          this.ecResubmitIdx = null;
        }),
      )
      .subscribe({
        next: () => {
          this.updateExpressClass(expressClassId, 'Resubmitted');
        },
        error: (err) =>
          this.alertService.createAlert2(
            this.exceptionData.FAILED_EXPRESS_CLASS_RESUBMIT,
            err,
          ),
      });
  }

  updateExpressClass(expressClassId: string, statusText = 'Discarded') {
    this.dashboardService
      .updateExpressClassStatus(expressClassId, statusText)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.awaitDiscardConfirmation = false;
          this.ecDiscardIdx = null;
        }),
      )
      .subscribe({
        next: () => {
          this.populateIncompleteSummMap();
        },
        error: (err) =>
          this.alertService.createAlert2(
            this.exceptionData.FAILED_EXPRESS_CLASS_DISCARD,
            err,
          ),
      });
  }

  onResubmitClick(index: number) {
    this.awaitResubmitConfirmation = true;
    this.ecResubmitIdx = index;
  }

  onCancelResubmitClick() {
    this.awaitResubmitConfirmation = false;
    this.ecResubmitIdx = null;
  }

  onDiscardClick(index: number) {
    this.awaitDiscardConfirmation = true;
    this.ecDiscardIdx = index;
  }

  onCancelDiscardClick() {
    this.awaitDiscardConfirmation = false;
    this.ecDiscardIdx = null;
  }
}
