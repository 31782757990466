import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { Subscription, finalize } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { DashboardService } from '../../dashboard/services/dashboard.service';
import { PersonService } from '../services/person.service';
import { CurriculaSummary } from './curriculaSummary';

@Component({
  selector: 'ug-curricula-summary',
  templateUrl: './curricula-summary.component.html',
  styleUrls: ['./curricula-summary.component.scss'],
})
export class CurriculaSummaryComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  private alertService = inject(AlertService);
  private dashboardService = inject(DashboardService);
  private personService = inject(PersonService);

  curriculaData: any = [];
  curriculaSummSubs: Subscription = new Subscription();
  loadingData = true;

  exceptionData = {
    SKILL_MATRIX: {
      level: AlertLevels.ERROR,
      code: 'CSC-001',
      message: 'Error retrieving skill matrix data',
    } as AlertData,
  };

  ngOnInit() {
    this.personService
      .getPersonCategoryStatusCounts()
      .pipe(
        finalize(() => {
          this.loadingData = false;
        }),
      )
      .subscribe({
        next: (cs: Array<CurriculaSummary>) => {
          cs.forEach((c) => {
            const complete = c.publishedCount / c.totalCount;
            this.curriculaData.push({
              name: c.categoryName,
              data: [
                { label: 'Incomplete', value: 1 - complete },
                { label: 'Complete', value: complete },
              ],
              chartScore: this.getChartScore(complete),
            });
          });
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.SKILL_MATRIX, err);
        },
      });

    if (this.curriculaData.length > 0) {
      const overallComplete: number =
        this.curriculaData
          .map((c) => {
            return c.data.filter((d) => {
              return d['label'] === 'Complete';
            });
          })
          .map((x) => x[0].value)
          .reduce((a, b) => a + b, 0) / this.curriculaData.length;

      this.curriculaData.unshift({
        name: 'All',
        data: [
          { label: 'Incomplete', value: 1 - overallComplete },
          { label: 'Complete', value: overallComplete },
        ],
      });
    }
  }

  getChartScore(chartScore: number): string {
    return this.dashboardService.getDisplayPercentage(chartScore);
  }

  ngOnDestroy() {
    this.curriculaSummSubs.unsubscribe();
  }
}
