import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import {
  TableHeader,
  TableHeaderButton,
  TableSelectedButton,
} from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { BookExistingSessionComponent } from '../../events/training-calendar/book-existing-session/book-existing-session.component';
import { BookSessionComponent } from '../../events/training-calendar/book-session/book-session.component';
import { SkillDimension } from '../../skill/services/skill.service';
import {
  AnalyticsResults,
  DashboardService,
  GenerateAnalysisEvent,
} from '../services/dashboard.service';

@Component({
  selector: 'ug-competency-training-analytics',
  templateUrl: './competency-training-analytics.component.html',
  styleUrl: './competency-training-analytics.component.scss',
})
export class CompetencyTrainingAnalyticsComponent implements OnInit, OnDestroy {
  private dashboardService = inject(DashboardService);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private ngbModal = inject(NgbModal);
  private uiToolsService = inject(UitoolsService);

  statusToInclude: number[] = [];
  skillDimensions: SkillDimension[] = [];
  dimensionId: number = 1;
  showData: boolean = false;
  tableHeaders!: TableHeader[];
  tableSelectedButtons: TableSelectedButton[] = [];
  tableRows: any[] = [];
  peopleVsClaimData: AnalyticsResults;
  tableLoading = false;
  showSelectBox = false;
  showSessionButton = false;
  noDataMessage =
    'No results match the filter criteria, try adjusting the filters.';
  ngUnsubscribe = new Subject<boolean>();

  sharedHeaders: TableHeader[] = [
    { id: 'skillName', title: 'Skill Name', class: 'w-25' },
    { id: 'personName', title: 'Name' },
    { id: 'positionName', title: 'Position', class: 'w-10' },
    { id: 'locationName', title: 'Location', class: 'w-10' },
    { id: 'managerName', title: 'Manager' },
    { id: 'analyticStatus', title: 'Status' },
    { id: 'claimStatus', title: 'Claim Status' },
    {
      id: 'completionDate',
      title: 'Completion Date',
      dateFormat: 'dd/MM/yyyy',
    },
    { id: 'expiryDate', title: 'Expiry Date', dateFormat: 'dd/MM/yyyy' },
  ];

  trainingTableHeaders: TableHeader[];
  competencyTableHeaders: TableHeader[];
  selectedSkillId!: number;
  analysisFilters: GenerateAnalysisEvent;
  tableHeaderButtons: TableHeaderButton[];
  selectedSkillName: string;

  ngOnInit(): void {
    this.authService.loggedInUserSubj.subscribe((liu: LoggedInUser) => {
      this.showSessionButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.TrainingCalendarAdmin,
      ].some((sr) => liu.roleIdentifier === sr);
    });

    this.trainingTableHeaders = [
      ...this.sharedHeaders,
      { id: 'trainedDate', title: 'Trained Date', dateFormat: 'dd/MM/yyyy' },
    ];
    const claimStatusIndex = this.sharedHeaders.findIndex(
      (header) => header.id === 'claimStatus',
    );
    const shared = [...this.sharedHeaders];
    shared.splice(
      claimStatusIndex,
      0,
      ...[{ id: 'claimLevel', title: 'Level' }],
    );
    this.competencyTableHeaders = shared;

    this.tableHeaderButtons = [
      {
        title: 'Export to CSV',
        headerAction: this.exportToCsv,
        type: 'button',
      },
    ];
  }

  generateAnalysis(event) {
    if (event !== null) {
      this.analysisFilters = event;
      this.tableLoading = true;
      this.showData = true;
      const dimensionType = event.dimensionId === 4 ? 'training' : 'competency';
      this.dashboardService
        .getCompetencyTrainingAnalyticsData(dimensionType, event.filters)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (data) => {
            this.selectedSkillName = event.filters.skillName;
            this.peopleVsClaimData = data;
            this.tableRows = [...this.peopleVsClaimData.records];
            this.tableHeaders =
              event.skillType === 'Event'
                ? [...this.trainingTableHeaders]
                : [...this.competencyTableHeaders];
            this.tableSelectedButtons =
              event.skillType === 'Event' && this.showSessionButton
                ? [
                    {
                      title: 'Create New Session',
                      class: 'btn esqepBtn me-2',
                      function: this.bookNewSession,
                    },
                    {
                      title: 'Select Existing Session',
                      class: 'btn esqepBtn',
                      function: this.addToExistingSession,
                    },
                  ]
                : [];
            this.showSelectBox =
              event.skillType === 'Event' && this.showSessionButton
                ? true
                : false;
            this.statusToInclude = [
              ...new Set(
                this.peopleVsClaimData.records.map(
                  (row) => row.analyticStatusId,
                ),
              ),
            ];
            this.tableLoading = false;
            this.selectedSkillId = event.filters.skillId;
          },
          error: (error) => {
            this.alertService.createAlert2(
              {
                level: AlertLevels.ERROR,
                code: 'CTA-001',
                message: 'Error retrieving training analytics results',
              } as AlertData,
              error,
            );
            this.tableLoading = false;
          },
        });
    } else if (this.showData) {
      this.showData = false;
    }
  }

  updateStatus(statusId: number) {
    if (this.statusToInclude.includes(statusId)) {
      this.statusToInclude = this.statusToInclude.filter((s) => s !== statusId);
    } else {
      this.statusToInclude.push(statusId);
    }

    this.tableRows = [
      ...this.peopleVsClaimData.records.filter((row) =>
        this.statusToInclude.includes(row.analyticStatusId),
      ),
    ];
  }

  bookNewSession = (rows) => {
    const modalRef = this.ngbModal.open(BookSessionComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.selectedSkillId = this.selectedSkillId;
    modalRef.componentInstance.usersToBookOntoSession = rows.map(
      (row) => row.personId,
    );
    modalRef.componentInstance.bookSessionAndRoster.subscribe((response) => {
      this.uiToolsService.showToast(
        'Successfully created the session and booked users onto the roster',
        { classname: 'bg-success text-light', delay: 3000 },
      );
      this.generateAnalysis(this.analysisFilters);
    });
  };

  addToExistingSession = (rows) => {
    const modalRef = this.ngbModal.open(BookExistingSessionComponent, {
      centered: true,
      windowClass: 'xxl',
    });
    modalRef.componentInstance.selectedSkillId = this.selectedSkillId;
    modalRef.componentInstance.usersToBookOntoSession = rows.map(
      (row) => row.personId,
    );
    modalRef.componentInstance.bookToSessionEvent.subscribe((response) => {
      this.uiToolsService.showToast('Successfully updated session roster ', {
        classname: 'bg-success text-light',
        delay: 3000,
      });
      this.generateAnalysis(this.analysisFilters);
    });
  };

  exportToCsv = () => {
    const dimensionType =
      this.analysisFilters.dimensionId === 4 ? 'training' : 'competency';
    this.dashboardService
      .getAnalyticsCsv(dimensionType, this.analysisFilters.filters)
      .subscribe({
        next: (csvFile) => {
          const url = window.URL.createObjectURL(csvFile);
          const link = document.createElement('a');
          link.setAttribute('target', '_self');
          link.setAttribute('href', url);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      });
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
