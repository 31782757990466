<div class="card shadow min-vh-50" *transloco="let t">
  <div class="card-body">
    <h5 class="card-title">{{ t('personDetails.my_details') }}</h5>
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading" class="row">
      <div class="col-12 col-md-4 h-100 d-flex flex-column align-self-center">
        <div
          *ngIf="personPhotoUrl; else photoPlaceHolder"
          class="text-center mt-1"
        >
          <img
            [src]="personPhotoUrl || ''"
            alt="user photo"
            class="img-fluid user-photo rounded-circle"
          />
        </div>
        <ng-template #photoPlaceHolder>
          <div class="user-photo mt-1">
            <div class="text-center p-3">
              <div *ngIf="!personPhotoFailure; else photoLoadFailure">
                <span class="fas fa-spinner fa-spin fa-3x"></span>
                <p class="small">{{ t('personDetails.loading_photo') }}</p>
              </div>
              <ng-template #photoLoadFailure>
                <span class="far fa-user fa-5x"></span>
                <p class="small">
                  {{ t('personDetails.loading_unavailable') }}
                </p>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-12 col-md-8">
        <dl class="row">
          <dt class="col-12 col-md-4">{{ t('personDetails.given_name') }}</dt>
          <dd class="col-12 col-md-8">{{ person.givenName }}</dd>
          <dt class="col-12 col-md-4">{{ t('personDetails.known_as') }}</dt>
          <dd class="col-12 col-md-8">{{ person.knownAsName }}</dd>
          <dt class="col-12 col-md-4">{{ t('personDetails.family_name') }}</dt>
          <dd class="col-12 col-md-8">{{ person.familyName }}</dd>
          <dt class="col-12 col-md-4">{{ t('personDetails.login_id') }}</dt>
          <dd class="col-12 col-md-8">{{ person.adUpn }}</dd>
          <dt class="col-12 col-md-4">
            {{ t('personDetails.business_phone') }}
          </dt>
          <dd class="col-12 col-md-8">{{ person.businessPhone || 'n/a' }}</dd>
          <dt class="col-12 col-md-4">{{ t('personDetails.mobile_phone') }}</dt>
          <dd class="col-12 col-md-8">{{ person.mobilePhone || 'n/a' }}</dd>
          <dt class="col-12 col-md-4">
            {{ t('personDetails.office_location') }}
          </dt>
          <dd class="col-12 col-md-8">{{ person.officeLocation || 'n/a' }}</dd>
          <dt class="col-12 col-md-4">{{ t('personDetails.address') }}</dt>
          <dd class="col-12 col-md-8">
            {{ person.streetAddress }}<br />
            {{ person.city }}<br />
            {{ person.state }} {{ person.postalCode }}<br />
            {{ person.country }}
          </dd>
          <dt
            *ngIf="(personVerificationAreas.verificationAreas || []).length > 0"
            class="col-12 col-md-4"
          >
            {{ t('personDetails.assessor_for') }}
          </dt>
          <dd
            *ngFor="
              let pva of personVerificationAreas.verificationAreas;
              let i = index
            "
            class="col-12 col-md-8"
            [ngClass]="{ 'offset-md-4': i > 0 }"
          >
            {{ pva.funcAreaName }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</div>
