<div *ngIf="parentFormGroup" class="row" [formGroup]="levelAndVerifierFG">
  <div class="col-12 col-lg-6 mb-3">
    <esqep-level-select-form
      [parentFormGroup]="levelAndVerifierFG.get('level')"
      [editMode]="editMode"
      [controlStatus]="levelControlStatus"
      [skillId]="competency?.id"
      [level]="level"
      (levelChange)="onLevelChange($event)"
    ></esqep-level-select-form>
  </div>
  <div class="col-12 col-lg-6">
    <div *ngIf="verifierChanged">
      <esqep-statement-form
        [parentFormGroup]="levelAndVerifierFG.get('verifier.verifierChange')"
        [esqepTitle]="'Assessor change reason'"
        [componentIndex]="'VS1'"
        [statementText]="verifierChangeText"
      ></esqep-statement-form>
    </div>
    <esqep-verifier-select-form
      [parentFormGroup]="levelAndVerifierFG"
      [controlStatus]="verifierControlStatus"
      [funcAreaId]="competency?.funcAreaId"
      [verifier]="verifier"
      (verifierChange)="onVerifierChange($event)"
      [selfVerified]="selfVerifiedLevel"
    ></esqep-verifier-select-form>
  </div>
</div>
