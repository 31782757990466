import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  catchError,
  combineLatest,
  finalize,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  CompetenciesService,
  SkillSubject,
  SubjectDictionary,
  SubjectPayload,
} from '../../../competencies/services/competencies.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { UitoolsService } from '../../../core/services/uitools.service';

interface FlatNode {
  id: number;
  name: string;
  dimensionIds: number[];
  dimensionSkillCounts: [{ dimensionId: number; skillCount: number }];
  externalId: number;
  source: string;
  isActive: boolean;
  externalParentId: number;
  subjectLevelId: number;
  parentId: number;
  children: FlatNode[];
  level: number;
  expandable: boolean;
  canDelete: boolean;
  cultureId: number;
  skillCount: number;
  functionalAreaCount: number;
}

@Component({
  selector: 'ug-competency',
  templateUrl: './competency.component.html',
  styleUrl: './competency.component.scss',
})
export class CompetencyComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private competencyService = inject(CompetenciesService);
  private alertService = inject(AlertService);
  private uiService = inject(UitoolsService);

  exceptionData = {
    SUBJECT_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'SH-006',
      message: 'Error updating subject',
    } as AlertData,
    SUBJECT_UPDATE_DIMENSIONS_CHILDREN: {
      level: AlertLevels.ERROR,
      code: 'SH-007',
      message: 'Error updating dimensions for subject and children',
    } as AlertData,
  };
  protected expanded: boolean;
  protected subject: FlatNode;
  protected subjectDictionary: SubjectDictionary;
  protected filteredSubjectDictionary: SubjectDictionary;
  protected isActive: boolean = true;
  protected add: boolean;
  protected newNode: boolean;
  protected isLoading: boolean = false;
  protected selectedDimensionId: number;
  protected subjectFilterText: string;
  protected levels: boolean;
  protected name: string;
  protected levelName: string;
  protected subjectLevelId: number;
  protected parentSubject: FlatNode;

  updateCategoryLevel$ = new Subject<any>();

  subjectLevelsTrigger = new BehaviorSubject<void>(null);

  subjectLevels$ = this.subjectLevelsTrigger.pipe(
    switchMap(() => this.competencyService.getSubjectLevels()),
  );

  vm$ = combineLatest([
    this.competencyService.getSkillDimensions(),
    this.subjectLevels$,
  ]).pipe(
    map(([skillDimensions, subjectLevels]) => ({
      skillDimensions,
      subjectLevels,
    })),
  );

  transformer = (node: SkillSubject, level: number) => {
    return {
      expandable: this.subjectDictionary[node.id]?.length > 0,
      name: node.culture ? `${node.name} (${node.culture})` : node.name,
      level: level,
      id: node.id,
      dimensionIds: node.dimensionIds.sort((a, b) => a - b),
      dimensionSkillCounts: node.dimensionSkillCounts,
      externalId: node.externalId,
      source: node.source,
      isActive: node.isActive,
      externalParentId: node.externalParentId,
      subjectLevelId: node.subjectLevelId,
      parentId: node.parentId,
      cultureId: node.cultureId,
      canDelete: node.canDelete,
      skillCount: node.skillCount,
      functionalAreaCount: node.functionalAreaCount,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => this.filterActive(node),
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;
  hideSkillAssignment: boolean = false;
  selectedSubjectDescendants: FlatNode[];
  newSubject: SkillSubject;

  filterActive(node: SkillSubject): SkillSubject[] {
    return this.filteredSubjectDictionary[node.id].filter((x) => {
      return this.isActive ? x.isActive === this.isActive : x;
    });
  }

  delete(event: { deleted: boolean; skillSubject: SkillSubject }) {
    this.competencyService
      .deleteSubjectById(event.skillSubject.id)
      .subscribe(() => {
        this.subject = null;
        this.getData();
        this.uiService.showToast(
          'Successfully deleted subject ' + event.skillSubject.name,
          { classname: 'bg-success text-light', delay: 3000 },
        );
      });
  }

  editedDetails(event: {
    name: string;
    levelName: string;
    levelNameChanged: boolean;
    application: boolean;
    behaviour: boolean;
    knowledge: boolean;
    training: boolean;
    skillSubject: SkillSubject;
  }) {
    const dimensionIds: number[] = [];
    if (event.application) {
      dimensionIds.push(1);
    }
    if (event.behaviour) {
      dimensionIds.push(2);
    }
    if (event.knowledge) {
      dimensionIds.push(3);
    }
    if (event.training) {
      dimensionIds.push(4);
    }
    const subjectData: SubjectPayload = {
      parentId: event.skillSubject.parentId,
      subjectLevelId: event.skillSubject.subjectLevelId,
      name: event.name,
      isActive: event.skillSubject.isActive,
      source: event.skillSubject.source,
      externalId: event.skillSubject.externalId,
      externalParentId: event.skillSubject.externalParentId,
    };

    if (event.skillSubject.id === null) {
      this.competencyService
        .addSubject(subjectData)
        .pipe(
          tap((subject) => (this.newSubject = subject)),
          switchMap((subject) => {
            return this.competencyService.updateSubjectDimensionsById(
              subject.id,
              dimensionIds,
            );
          }),
        )
        .subscribe({
          next: (subjectDimension) => {
            this.getData(this.newSubject);
            this.uiService.showToast(
              'Successfully created subject ' + subjectData.name,
              { classname: 'bg-success text-light', delay: 3000 },
            );
            this.newSubject = null;
          },
        });
    } else {
      const subjectObservable =
        event.name !== event.skillSubject.name
          ? this.competencyService
              .updateSubjectById(event.skillSubject.id, subjectData)
              .pipe(
                catchError((err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.SUBJECT_UPDATE,
                    err,
                  );
                  return EMPTY;
                }),
              )
          : of(undefined);

      const dimensionObservable =
        !dimensionIds.every((x) =>
          event.skillSubject.dimensionIds.includes(x),
        ) ||
        !event.skillSubject.dimensionIds.every((x) => dimensionIds.includes(x))
          ? this.competencyService
              .updateSubjectAndChildDimensionsById(
                event.skillSubject.id,
                dimensionIds,
              )
              .pipe(
                catchError((err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.SUBJECT_UPDATE_DIMENSIONS_CHILDREN,
                    err,
                  );
                  return EMPTY;
                }),
              )
          : of(undefined);

      const subjectLevelObservable = event.levelNameChanged
        ? this.competencyService
            .updateSubjectLevelById(event.skillSubject.subjectLevelId, {
              name: event.levelName,
            })
            .pipe(
              catchError((err) => {
                this.alertService.createAlert2(
                  this.exceptionData.SUBJECT_UPDATE_DIMENSIONS_CHILDREN,
                  err,
                );
                return EMPTY;
              }),
            )
        : of(undefined);

      combineLatest([
        subjectObservable,
        dimensionObservable,
        subjectLevelObservable,
      ]).subscribe(([subject, subjectDimension, levelName]) => {
        if (subject || subjectDimension) {
          this.getData();
        }

        if (levelName) {
          this.subjectLevelsTrigger.next();
        }

        this.uiService.showToast(
          `Successfully updated subject ${this.subject.name}`,
          { classname: 'bg-success text-light', delay: 3000 },
        );

        // if (subject) {
        //   this.subject = subject;
        // } else if (subjectDimension) {
        //   this.subject = {
        //     ...this.subject,
        //     dimensionIds: subjectDimension.map((x) => x.skillDimensionId),
        //   };
        // }
      });
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(newSubject?: SkillSubject) {
    this.subjectFilterText = '';
    this.competencyService
      .getSubjectsDictionary()
      .pipe(
        finalize(() => this.updateSelectedNode(newSubject)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data: SubjectDictionary) => ({
        next:
          ((this.subjectDictionary = data),
          (this.filteredSubjectDictionary = data),
          (this.dataSource.data = this.filteredSubjectDictionary[0].filter(
            (x: SkillSubject) => {
              return this.isActive ? x.isActive === this.isActive : x;
            },
          ))),
      }));
  }

  updateSelectedNode(newSubject?: SkillSubject) {
    if (this.subject || newSubject) {
      const nodes = this.treeControl.dataNodes;
      const selectedNode = nodes.find(
        (node) => node.id === (this.subject ? this.subject.id : newSubject.id),
      );
      if (selectedNode) {
        this.expandDetails(selectedNode);
      }
    }
  }

  active() {
    this.isActive = !this.isActive;
    if (this.expanded) {
      this.expanded = false;
    }

    this.dataSource.data = this.filteredSubjectDictionary[0].filter((x) => {
      return this.isActive ? x.isActive === this.isActive : x;
    });
  }

  addTopNode() {
    this.add = true;
    this.subject = null;
    this.parentSubject = null;
  }

  addChildNode() {}

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  expandDetails(value: FlatNode) {
    this.parentSubject =
      value.subjectLevelId > 1
        ? (this.treeControl.dataNodes.find(
            (node) => node.id === value.parentId,
          ) as any)
        : null;

    this.selectedSubjectDescendants = this.treeControl.getDescendants(
      this.treeControl.dataNodes.find((node) => node.id === value.id),
    );

    this.add = false;
    this.subject = value;
  }

  expandCollapseTreeview() {
    if (!this.expanded) {
      this.treeControl.expandAll();
      this.expanded = true;
    } else {
      this.treeControl.collapseAll();
      this.expanded = false;
    }
  }

  onFilterTextChange(filterText: string): void {
    this.subjectFilterText = filterText;
    this.filteredSubjectDictionary = { ...this.subjectDictionary };

    const matchingSubjectBranchIds = this.findMatchingBranches(
      this.filteredSubjectDictionary,
      this.subjectFilterText,
    ).map((item) => item.id);

    if (matchingSubjectBranchIds.length === 0) {
      this.filteredSubjectDictionary = {};
    } else {
      Object.keys(this.filteredSubjectDictionary).forEach((key) => {
        let filteredSubjects = this.filteredSubjectDictionary[key].filter((x) =>
          matchingSubjectBranchIds.includes(x.id),
        );

        if (filteredSubjects.length > 0) {
          this.filteredSubjectDictionary[key] = filteredSubjects;
          this.filteredSubjectDictionary[key].forEach((subject) => {
            this.treeControl.expand(subject as any);
          });
        }
      });
    }

    this.dataSource.data =
      this.filteredSubjectDictionary[0]?.filter((x) => {
        return this.isActive ? x.isActive === this.isActive : x;
      }) || [];

    if (this.subjectFilterText) {
      this.treeControl.expandAll();
      this.expanded = true;
    } else {
      this.treeControl.collapseAll();
      this.expanded = false;
    }
  }

  findMatchingBranches(data: any, searchTerm: string): SkillSubject[] {
    const matchedNodes: any[] = [];
    this.searchTree(data, searchTerm.toLowerCase(), '0', matchedNodes);
    return matchedNodes;
  }

  searchTree(
    data: any,
    searchTerm: string,
    nodeId: string,
    matchedNodes: any[],
  ): boolean {
    const nodes = data[nodeId] || [];
    let isMatchFound = false;

    for (const node of nodes) {
      const nodeMatch =
        node.name.toLowerCase().includes(searchTerm) &&
        (this.selectedDimensionId > 0
          ? node.dimensionIds.includes(this.selectedDimensionId)
          : node);
      const childrenMatch = this.searchTree(
        data,
        searchTerm,
        node.id.toString(),
        matchedNodes,
      );

      if (nodeMatch || childrenMatch) {
        matchedNodes.push(node);
        isMatchFound = true;
      }
    }

    return isMatchFound;
  }

  dimensionSelected(dimId: number) {
    this.selectedDimensionId = dimId;
    this.filteredSubjectDictionary = { ...this.subjectDictionary };
    if (this.subjectFilterText) {
      this.subjectFilterText = '';
    } else {
      if (this.selectedDimensionId > 0) {
        Object.keys(this.filteredSubjectDictionary).forEach((key) => {
          this.filteredSubjectDictionary[key] = this.filteredSubjectDictionary[
            key
          ].filter((x) => x.dimensionIds.includes(dimId));
        });
      }
      this.dataSource.data =
        this.filteredSubjectDictionary[0]?.filter((x) => {
          return this.isActive ? x.isActive === this.isActive : x;
        }) || [];
    }
  }

  addChildSubject(hide: boolean) {
    this.parentSubject = this.subject;
    this.add = true;
    this.subject = null;
  }

  skillsUpdated(skillCount: any) {
    this.getData();
    // this.subject = {
    //   ...this.subject,
    //   skillCount: skillCount,
    // };

    // (this.treeControl.toggle(this.subject), this.treeControl.dataNodes)
  }
}
