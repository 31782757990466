import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';
import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { CompetencyTrainingAnalyticsComponent } from './competency-training-analytics/competency-training-analytics.component';
import { IncompleteExpressClassComponent } from './incomplete-express-class/incomplete-express-class.component';
import { MatrixSettingsComponent } from './matrix-settings/matrix-settings.component';
import { MatrixComponent } from './matrix/matrix.component';
import { MatrixType } from './matrix/matrix.service';
import { PerfMgmtComponent } from './perf-mgmt/perf-mgmt.component';
import { PerfSummaryComponent } from './perf-summary/perf-summary.component';
import { TrainingMatrixSummaryComponent } from './training-matrix-summary/training-matrix-summary.component';

const routes: Routes = [
  {
    path: 'competency-skill-matrix-summ',
    redirectTo: 'competency-training-matrix-summ',
    pathMatch: 'full',
  },
  {
    path: 'competency-training-matrix-summ',
    redirectTo: 'training-matrix-summary',
    pathMatch: 'full',
  },
  {
    path: 'training-matrix-summary',
    component: TrainingMatrixSummaryComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      routeIdentifier: 'ANALYSIS_TRAINING-MATRIX-SUMMARY',
      title: 'Training Matrix Summary',
      matrixType: MatrixType.Training,
      helpUrl:
        'https://scribehow.com/shared/Training_Matrix_Summary__6UNdkjQpT3-IBf4lCdHoTw',
    },
  },
  {
    path: 'competency-matrix',
    component: MatrixComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      routeIdentifier: 'ANALYSIS_COMPETENCY-MATRIX',
      title: 'Competency Matrix',
      matrixType: MatrixType.Competency,
    },
  },
  {
    path: 'training-matrix',
    component: MatrixComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      routeIdentifier: 'ANALYSIS_TRAINING-MATRIX',
      title: 'Training Matrix',
      matrixType: MatrixType.Training,
      helpUrl:
        'https://scribehow.com/shared/Training_Matrix__p3b4FMlURHC2z7EZP19b8A',
    },
  },
  {
    path: 'performance-summ',
    component: PerfSummaryComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
  },
  {
    path: 'performance-management',
    component: PerfMgmtComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  },
  {
    path: 'incomplete-express-class',
    component: IncompleteExpressClassComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [CanDeactivateGuard],
    data: {
      routeIdentifier: 'ADMIN_INCOMPLETE-EXPRESS-CLASS',
      title: 'Incomplete Express Class',
    },
  },
  {
    path: 'competency-training-analytics',
    component: CompetencyTrainingAnalyticsComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'ANALYSIS_COMPETENCY-TRAINING-ANALYTICS',
      title: 'Competency & Training Analytics',
      helpUrl:
        'https://scribehow.com/shared/Competency_and_Training_Analytics__Da8lo1RcTVuhHcbUKiY8Ew',
    },
  },
  {
    path: 'matrix-settings',
    component: MatrixSettingsComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      routeIdentifier: 'ADMIN_MATRIX-SETTINGS',
      title: 'Matrix Settings',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
