import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbNavModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ControlsModule } from '../controls/controls.module';
import { DynamicGroupsRoutingModule } from './dynamic-groups-routing.module';
import { GroupFilterAuditComponent } from './group-filter-audit/group-filter-audit.component';
import { GroupFilterUploadComponent } from './group-filter-upload/group-filter-upload.component';
import { GroupFiltersListComponent } from './group-filters-list/group-filters-list.component';
import { GroupDetailsComponent } from './group-management/group-details/group-details.component';
import { GroupManagementComponent } from './group-management/group-management.component';
import { GroupReviewComponent } from './group-management/group-review/group-review.component';

@NgModule({
  declarations: [
    GroupFiltersListComponent,
    GroupManagementComponent,
    GroupDetailsComponent,
    GroupReviewComponent,
    GroupFilterAuditComponent,
    GroupFilterUploadComponent,
  ],
  imports: [
    CommonModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    NgbToastModule,
    DynamicGroupsRoutingModule,
    NgbCollapseModule,
    NgbTooltipModule,
  ],
  exports: [
    GroupFiltersListComponent,
    GroupManagementComponent,
    GroupDetailsComponent,
    GroupReviewComponent,
  ],
})
export class DynamicGroupsModule {}
