<div class="card h-100 shadow">
  <div class="card-body">
    <h5 class="card-title">{{ cardTitle }}</h5>
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading" class="row">
      <div class="col-12 col-md-4">
        <dl>
          <dt class="col">Organisation</dt>
          <dd class="col">{{ orgUnit?.parentOrgUnitName }}</dd>
          <dt class="col">Function</dt>
          <dd class="col">{{ orgUnit?.name }}</dd>
          <dt class="col">Line manager</dt>
          <dd class="col">
            {{ person.managerDisplayName }}<br />{{ person?.managerAdUpn }}
          </dd>
          <!-- <dt class="col">Dotted manager(s)</dt>
          <dd class="col">n/a</dd> -->
          <dt class="col">Job roles</dt>
          <dd *ngFor="let jobRole of personJobRoles; let i = index" class="col">
            {{ jobRole.name }}
          </dd>
        </dl>
      </div>
      <div class="col-12 col-md-8 border-start">
        <dl>
          <dt class="col pb-2">Direct reports:</dt>
          <dd class="col">
            <div class="row fw-bold">
              <div class="col-4">Name</div>
              <div class="col-8">Role(s)</div>
            </div>
            <div
              *ngFor="let directReport of personDirectReports; let i = index"
              class="row border-top"
            >
              <div class="col-4">{{ directReport.displayName }}</div>
              <div class="col-8">
                <div *ngIf="directReport.jobRoles.length == 0" class="row">
                  <div class="col-12">No Role specified</div>
                </div>
                <div
                  *ngFor="let jr of directReport.jobRoles; let j = index"
                  class="row"
                >
                  <div class="col-9">{{ jr.name || 'name' }}</div>
                  <div class="col-3">
                    <a
                      *ngIf="directReport.jobRoles.length > 0"
                      type="button"
                      class=""
                      data-bs-toggle="modal"
                      data-bs-target="#drJobRoleModal"
                      (click)="
                        onDirectReportClick(
                          directReport.id,
                          jr.id,
                          directReport.displayName,
                          jr.name
                        )
                      "
                    >
                      <span class="fas fa-chart-bar text-primary"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="drJobRoleModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ drDisplayName }} - {{ selectedJobRole }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="close"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        <ug-list-status
          [isLoading]="isPlotLoading"
          [listLength]="[roleProfile].length"
        ></ug-list-status>
        <ng-container *ngIf="!isPlotLoading">
          <ug-role-competency-summary
            *ngIf="roleProfile.length; else noProfileMatch"
            [jobRolePerson]="roleProfile[0].people[0]"
            [showGraph]="false"
            [showProgressBar]="false"
          >
          </ug-role-competency-summary>
          <ng-template #noProfileMatch>
            <div class="alert alert-info" role="alert">
              This role has no requirements assigned to it.
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
