// IMPORTANT: Need to allow 3rd party cookies from login.microsoft.com for token renewal

// TODO: https://docs.microsoft.com/en-gb/azure/active-directory/develop/tutorial-v2-angular-auth-code

import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { OKTA_AUTH } from '@okta/okta-angular';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationConfigNames } from '../authenticationConfigNames';
import { SecurityRole } from '../models/security-role';
import { ConfigService } from './config.service';

export enum SecurityRoleKey {
  User = 'SECURITY_ROLE_USER',
  Assessor = 'SECURITY_ROLE_ASSESSOR',
  Admin = 'SECURITY_ROLE_ADMIN',
  Superuser = 'SECURITY_ROLE_SUPERUSER',
  JobRolePeople = 'SECURITY_ROLE_JOB_PEOPLE',
  JobRoleReqs = 'SECURITY_ROLE_JOB_REQS',
  JobRoleReqsPeople = 'SECURITY_ROLE_JOB_REQS_PEOPLE',
  OuResponsible = 'SECURITY_ROLE_OU_RESPONSIBLE',
  TrainingCalendarAdmin = 'SECURITY_ROLE_TRAINING_CALENDAR_ADMIN', //TBD
}

export interface LoggedInUser {
  adObjectGuid: string;
  adUpn: string;
  businessPhone: string;
  city: string;
  country: string;
  displayName: string;
  externalGuid: string;
  externalId: string;
  externalUserId: string;
  familyName: string;
  givenName: string;
  id: number;
  isManager: boolean;
  knownAsName: string;
  mobilePhone: string;
  name: string;
  officeLocation: string;
  orgUnitId: number;
  organisationUnitId: number;
  postalCode: string;
  roleIdentifier: string;
  securityLevel: number;
  state: string;
  streetAddress: string;
}

export const SecurityRoleConfig = {};

const apiServerUri = environment.apiUrl;
const dotNetServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  diagShow = false;
  authenticationStatusSubj = new BehaviorSubject<boolean>(false);
  adminStatusSubj = new BehaviorSubject<boolean>(false);
  inUserGroupSubj = new BehaviorSubject<boolean>(false);
  loggedInUserSubj = new BehaviorSubject<LoggedInUser | null>(null);
  authTimeoutSubj = new Subject<any>();
  authServiceSubs = new Subscription();
  userPhotoUrlSubj = new BehaviorSubject<SafeResourceUrl>('');
  securityRolesSubj = new ReplaySubject<Array<SecurityRole>>(1);
  securityRoleLookupSubj = new ReplaySubject<any>(1);

  userInfo: LoggedInUser;

  private _userSecurityRoleLookup: { [role_identifier: string]: SecurityRole } =
    {};
  private _userSecurityRoles: Array<SecurityRole> = [];
  private ngUnsubscribe: Subject<boolean> = new Subject();
  private router = inject(Router);
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  public msalService = inject(MsalService, { optional: true });
  private oktaAuth = inject(OKTA_AUTH, { optional: true });

  ngOnDestroy() {
    this.authServiceSubs.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loginSucceeded() {
    this.isAuthenticated = true;
    this.inUserGroup = true;
    this.hasAdminRole =
      this.userSecurityRoles.findIndex(
        (f) => f.roleIdentifier === SecurityRoleKey.Admin,
      ) > -1;
  }

  logout() {
    if (
      localStorage.getItem('esqep.authType') === AuthenticationConfigNames.CSOD
    ) {
      this.isAuthenticated = false;
      this.loggedInUserSubj.next(null);
      this.userInfo = null;
      this.storeUserPersonDetail(null);
      localStorage.removeItem('esqep.auth.saml');
      localStorage.removeItem('esqep.authType');
      // redirect to cornerstone logout;
      window.location.href =
        this.configService.authSetup[AuthenticationConfigNames.CSOD].logoutUrl;
    } else if (
      localStorage.getItem('esqep.authType') === AuthenticationConfigNames.OKTA
    ) {
      localStorage.removeItem('esqep.authType');
      of(this.oktaAuth.signOut()).subscribe(() =>
        this.router.navigate(['/login']),
      );
    } else {
      localStorage.removeItem('esqep.authType');
      this.msalService.logoutRedirect();
    }
  }

  timeoutLogout() {
    this.logout();
  }

  storeUserPersonDetail(person: LoggedInUser) {
    if (person) {
      person.knownAsName = person.knownAsName || person.givenName;
      this.loggedInUserSubj.next(person);
    } else {
      this.loggedInUserSubj.next(null);
    }
  }

  getSecurityRoles(): Observable<Array<SecurityRole>> {
    return this.http.get<Array<SecurityRole>>(
      `${dotNetServerUri}/securityRoles`,
    );
  }

  populateSecurityRoles() {
    this.authServiceSubs.add(
      this.getSecurityRoles().subscribe({
        next: (sr) => {
          this._userSecurityRoles = sr;
          this.securityRolesSubj.next(sr);
          this._userSecurityRoleLookup = sr.reduce((m, r) => {
            m[r.roleIdentifier] = r;
            return m;
          }, {});
          this.securityRoleLookupSubj.next(this._userSecurityRoleLookup);
        },
        error: (err) => {},
      }),
    );
  }

  get userSecurityRoles(): Array<SecurityRole> {
    return this._userSecurityRoles;
  }

  get me(): LoggedInUser {
    return this.loggedInUserSubj.getValue();
  }

  get myPhotoUrl(): SafeResourceUrl {
    return this.userPhotoUrlSubj.getValue();
  }

  public get isAuthenticated() {
    return this.authenticationStatusSubj.getValue();
  }

  public set isAuthenticated(value: boolean) {
    this.authenticationStatusSubj.next(value);
  }

  public get inUserGroup(): boolean {
    return this.inUserGroupSubj.getValue();
  }

  public set inUserGroup(value: boolean) {
    if (this.inUserGroupSubj.getValue() !== value) {
      this.inUserGroupSubj.next(value);
    }
  }

  public get hasAdminRole(): boolean {
    return this.adminStatusSubj.getValue();
  }

  public set hasAdminRole(value: boolean) {
    if (this.adminStatusSubj.getValue() !== value) {
      this.adminStatusSubj.next(value);
    }
  }

  get userGUID(): string {
    return this.userInfo?.externalGuid || this.userInfo?.adObjectGuid;
  }

  public get userUPN(): string {
    return this.userInfo?.adUpn;
  }

  public getSsoRedirect() {
    const returnUrl = window.location.origin + '/home';
    const spConfigName =
      this.configService.authSetup[AuthenticationConfigNames.CSOD].spConfigName;
    return this.http
      .get(
        `${dotNetServerUri}/auth/${spConfigName}?returnUrl=${returnUrl}&redirectUrl=${returnUrl}`,
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  public getLoggedInUser(): Observable<LoggedInUser> {
    return this.http.get<LoggedInUser>(
      `${dotNetServerUri}/people/loggedInUser`,
    );
  }
}
