import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { combineLatest, forkJoin, map, switchMap } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ClaimService } from '../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../core/services/alert.service';
import { AuthService, SecurityRoleKey } from '../core/services/auth.service';
import { ChartConfig, ChartData } from '../echarts/chart-config';
import { PersonService } from '../person/services/person.service';
import {
  ClaimItemVsCategory,
  DashboardService,
  ItemSummary,
} from './services/dashboard.service';

@Component({
  encapsulation: ViewEncapsulation.None, // Allow styles on injected elements
  selector: 'ug-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [],
})
export class DashboardComponent implements OnInit {
  @Input() personId!: number;
  private authService = inject(AuthService);
  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private dashboardService = inject(DashboardService);
  private personService = inject(PersonService);
  private claimService = inject(ClaimService);
  private router = inject(Router);
  private isVerifier = false;

  protected activitySummary: ChartConfig[] = [];
  protected fetchingData = true;

  exceptionData = {
    CLAIMANT_SUMM: {
      level: AlertLevels.ERROR,
      code: 'DBD-001',
      message: 'Error retrieving claimant status summary',
    } as AlertData,
    VERIFIER_SUMM: {
      level: AlertLevels.ERROR,
      code: 'DBD-002',
      message: 'Error retrieving verifier status summary',
      translationKey: 'errRetrievingStatusSumm',
    } as AlertData,
  };

  chartColourMap = new Map([
    ['Draft', '#a2a2a2'],
    ['Assessed', '#17b9a7'],
    ['Awaiting Assessment', '#f7931d'],
    ['Not Started', '#90439a'],
    ['Closed (cancelled)', '#ef432e'],
    ['Complete', '#264148'],
    ['Clarification required', '#17b9a7'],
    ['Deleted', '#dd4b39'],
    ['Rejected', '#e74c3c'],
    ['Resubmitted', '#00c0ef'],
    ['Unassessed', '#f39c12'],
    ['Revoked', '#c0392b'],
    ['Withdrawn', '#d35400'],
    ['Assessed (Unacknowledged)', '#00a65a'],
    ['Closed (deleted)', '#cd6155'],
    ['In Progress', '#3498db'],
    ['Expired', '#ba4a00'],
  ]);

  ngOnInit() {
    combineLatest([
      this.authService.securityRoleLookupSubj,
      this.authService.loggedInUserSubj,
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(([securityRoleLookup, loggedInUser]) =>
          forkJoin([
            this.personService.getPersonVerificationAreas(loggedInUser.id),
            this.dashboardService.getClaimStatusSummaryVsRole(loggedInUser.id),
          ]).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(([personVerificationArea, claimStatusSummaryVsRole]) => ({
              personVerificationArea,
              claimStatusSummaryVsRole,
              securityRoleLookup,
              loggedInUser,
            })),
          ),
        ),
      )
      .subscribe({
        next: ({
          claimStatusSummaryVsRole,
          personVerificationArea,
          securityRoleLookup,
          loggedInUser,
        }) => {
          this.isVerifier =
            personVerificationArea.verificationAreas.length > 0 &&
            loggedInUser.securityLevel >=
              securityRoleLookup[SecurityRoleKey.Assessor].securityLevel;
          this.activitySummary = this.setupPieCharts(
            claimStatusSummaryVsRole[0].groupSummary,
          );

          this.fetchingData = false;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.VERIFIER_SUMM, err);
        },
      });
  }

  setupPieCharts(claimsVsCategory: ClaimItemVsCategory[]): ChartConfig[] {
    const filteredData = this.filteDataIfNotVerifier(claimsVsCategory);
    const formattedData = this.formatToChartData(filteredData);

    return this.isEmptyChartData(formattedData) ? [] : formattedData;
  }

  filteDataIfNotVerifier(
    claimsVsCategory: ClaimItemVsCategory[],
  ): ClaimItemVsCategory[] {
    return this.isVerifier
      ? claimsVsCategory
      : claimsVsCategory.filter(
          (dataPoint) =>
            dataPoint['categoryName'].toLowerCase() !== 'as verifier',
        );
  }

  formatToChartData(claimsVsCategory: ClaimItemVsCategory[]): ChartConfig[] {
    return claimsVsCategory.map((claimsVsCategory: ClaimItemVsCategory) => {
      const translatedTitle =
        claimsVsCategory.categoryName === 'As Claimant'
          ? 'My Competency Assessment Requests'
          : 'Pending My Assessment';

      const data = this.formatItemSummaryAsChartData(
        claimsVsCategory.itemSummary,
        claimsVsCategory.categoryName,
      );

      return {
        title: translatedTitle,
        type: 'pie',
        data: data,
        chartClickCallback: this.chartClicked,
        sort: true,
        legendPosition: 'bottom',
        // containerStyle: 'height: 300px;',
      };
    });
  }

  formatItemSummaryAsChartData(
    itemSummary: ItemSummary[],
    categoryName,
  ): ChartData[] {
    return itemSummary
      .filter((item) => item.count !== 0)
      .map((item) => ({
        name: item.itemName,
        value: item.count,
        id: item.itemId,
        colour: this.chartColourMap.get(item.itemName),
        seriesName: categoryName,
      }));
  }

  isEmptyChartData(chartData: ChartConfig[]): boolean {
    return (
      chartData.length === 0 ||
      chartData.every((dataPoint) => dataPoint.data.length === 0)
    );
  }

  chartClicked = ($event) => {
    const userType: 'assessor' | 'claimant' =
      $event.data.seriesName === 'As Verifier' ? 'assessor' : 'claimant';
    this.claimService.getClaimsByStatus($event.data.id, userType).subscribe({
      next: (result) => {
        this.claimService.activityListData = result;

        const navigationLabel = $event.data.name
          .toUpperCase()
          .replace(' ', '-');
        this.router.navigate([`submissions/${navigationLabel}`]);
      },
    });
  };

  camalize(str: string) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}
