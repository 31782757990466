import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { FuncArea } from '../../skill/models/func-area';
import { Skill } from '../../skill/models/skill';
import { SkillService } from '../../skill/services/skill.service';

@Component({
  selector: 'esqep-competency-attr-form',
  templateUrl: './competency-attr-form.component.html',
  styleUrls: ['./competency-attr-form.component.scss'],
})
export class CompetencyAttrFormComponent
  implements OnChanges, OnDestroy, OnInit
{
  private alertService = inject(AlertService);
  private fb = inject(FormBuilder);
  private skillService = inject(SkillService);

  @Input() parentFormGroup: FormGroup;
  @Input() editMode = false;
  @Input() selectedCompDimension: FuncArea;
  @Input() selectedJobFamily: FuncArea;
  @Input() selectedSkill: Skill;
  @Output() selectedSkillChange: EventEmitter<Skill> = new EventEmitter();

  knowledgeFilter = '';
  compDimensionList: Array<FuncArea> = [];
  competencyAttrSubs = new Subscription();

  compDims: Array<any> = [];
  exceptionData = {
    COMP_DIM: {
      level: AlertLevels.ERROR,
      code: 'CED-001',
      message: 'Error retrieving competency dimensions',
    } as AlertData,
  };

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.parentFormGroup.dirty) {
      return false;
    } else {
      return true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.knowledgeFilter = this.selectedSkill.name;
  }

  ngOnInit() {
    this.competencyAttrSubs.add(
      this.skillService.getCompDimensions().subscribe(
        (fa: Array<FuncArea>) => {
          this.compDimensionList = fa;

          if (this.compDimensionList.length > 0) {
            this.selectedCompDimension = this.compDimensionList[0];
            // this.compDimension = this.selectedCompDimension.name;
          }
          this.getcompDimensionControls(fa);
        },
        (err) => {
          this.alertService.createAlert2(this.exceptionData.COMP_DIM, err);
        },
      ),
    );
  }

  ngOnDestroy() {
    this.competencyAttrSubs.unsubscribe();
  }

  getcompDimensionControls(compDimArray: Array<FuncArea>) {
    const compDimControlArray = Array<FormGroup>();
    compDimArray.forEach((fa) => {
      compDimControlArray.push(this.fb.group(fa));
    });

    this.compDims = compDimControlArray;
  }

  onDimChange(dim: FuncArea) {
    // this.logging.newMsg('Competency dimension changed to:' + dim.name, 'VERBOSE');
    this.selectedCompDimension = dim;
  }

  onCompAttrChange(compAttr: Skill) {
    this.selectedSkillChange.emit(compAttr);
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
