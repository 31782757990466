<div [formGroup]="parentFormGroup.get('rolesAndResources')" class="border-1">
  <ul ngbNav #nav2="ngbNav" class="nav-tabs nav-justified">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Project Roles</a>
      <ng-template ngbNavContent>
        <ug-project-roles
          [parentFormGroup]="parentFormGroup.get('rolesAndResources')"
          [jobRoleList]="jobRoleList"
          [jobRoleSearch]="jobRoleSearch"
          [jrLoading]="jrLoading"
        ></ug-project-roles>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Project Competencies</a>
      <ng-template ngbNavContent>
        <ug-project-competencies
          [parentFormGroup]="parentFormGroup.get('rolesAndResources')"
          [applicationSkillList]="applicationSkillList"
          [knowledgeSkillList]="knowledgeSkillList"
          [compDims]="compDims"
        ></ug-project-competencies>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Project Members</a>
      <ng-template ngbNavContent>
        <ug-project-members
          [parentFormGroup]="parentFormGroup.get('rolesAndResources')"
          [peopleList]="peopleList"
          [plLoading]="plLoading"
          [peopleSearch]="peopleSearch"
          [jobRoleList]="jobRoleList"
          [jobRoleSearch]="jobRoleSearch"
        ></ug-project-members>
      </ng-template>
    </li>
  </ul>
</div>
<div [ngbNavOutlet]="nav2" class="mt-2"></div>
