<div class="card shadow overflow-visible">
  <div class="card-body">
    <form [formGroup]="projectFilters">
      <div class="row">
        <div class="col-8 d-flex p-1">
          <div class="w-100 me-3">
            <ug-dropdown-select
              [ngbTypeahead]="projectSearch"
              [resultFormatter]="projectFormatter"
              [inputFormatter]="projectFormatter"
              [inlineGroup]="false"
              formControlName="project"
            >
              Select Project
            </ug-dropdown-select>
          </div>
          <div class="w-100 me-3">
            <ug-dropdown-select
              [ngbTypeahead]="categorySearch"
              [resultFormatter]="projectFormatter"
              [inputFormatter]="projectFormatter"
              [inlineGroup]="false"
              formControlName="category"
            >
              Select Category / Code
            </ug-dropdown-select>
          </div>
          <div class="w-100 me-3">
            <ug-dropdown-select
              [ngbTypeahead]="statusSearch"
              [resultFormatter]="projectFormatter"
              [inputFormatter]="projectFormatter"
              [inlineGroup]="false"
              formControlName="status"
            >
              Select Status
            </ug-dropdown-select>
          </div>
          <div class="w-100 me-3">
            <ug-dropdown-select
              [ngbTypeahead]="visibilitySearch"
              [resultFormatter]="projectFormatter"
              [inputFormatter]="projectFormatter"
              [inlineGroup]="false"
              formControlName="visibility"
            >
              Select Visibility
            </ug-dropdown-select>
          </div>
        </div>
        <div class="col-4 d-flex align-items-end justify-content-between p-1">
          <div>
            <label for="startDate" class="form-label">Start Date</label>
            <div class="input-group">
              <input
                id="startDate"
                type="text"
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="startDate"
                class="form-control"
                placeholder="yyyy-mm-dd"
              />
              <button
                class="btn btn-outline-secondary fas fa-calendar"
                (click)="d.toggle()"
              ></button>
            </div>
          </div>
          <div>
            <label for="endDate" class="form-label">End Date</label>
            <div class="input-group">
              <input
                id="endDate"
                type="text"
                ngbDatepicker
                #d2="ngbDatepicker"
                formControlName="endDate"
                class="form-control"
                placeholder="yyyy-mm-dd"
              />
              <button
                class="btn btn-outline-secondary fas fa-calendar"
                (click)="d2.toggle()"
              ></button>
            </div>
          </div>
          <div>
            <button (click)="clearFilters()" class="btn esqepBtn">
              Clear Filters
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="mx-3">
    <table class="table">
      <thead>
        <tr>
          <th
            scope="col"
            sortable="project"
            (sort)="onSort($event)"
            class="w-35"
          >
            Project
          </th>
          <th
            scope="col"
            sortable="category"
            (sort)="onSort($event)"
            class="w-10"
          >
            Category/Code
          </th>
          <th
            scope="col"
            sortable="visibility"
            (sort)="onSort($event)"
            class="w-10 text-center"
          >
            Visibility
          </th>
          <th
            scope="col"
            sortable="members"
            (sort)="onSort($event)"
            class="w-10"
          >
            Project Members
          </th>
          <th
            scope="col"
            sortable="status"
            (sort)="onSort($event)"
            class="w-10"
          >
            Status
          </th>
          <th
            scope="col"
            sortable="startDate"
            (sort)="onSort($event)"
            class="w-10"
          >
            Start Date
          </th>
          <th
            scope="col"
            sortable="endDate"
            (sort)="onSort($event)"
            class="w-10"
          >
            End Date
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let project of filteredProjects
              | slice: (page - 1) * pageSize : (page - 1) * pageSize + pageSize;
            let index = index
          "
        >
          <td>
            <div class="fw-bold">{{ project.project }}</div>
            <div>{{ project.description }}</div>
          </td>
          <td>{{ project.category }}</td>
          <td class="text-center">
            <i [ngClass]="getVisibilityIcon(project.visibility)"></i>
          </td>
          <td>{{ project.members }}</td>
          <td>
            <div
              class="rounded-pill px-1 text-center text-white"
              [ngClass]="getStatusColour(project.status)"
            >
              {{ project.status }}
            </div>
          </td>
          <td>{{ project.startDate | date: 'dd/MM/yyyy' }}</td>
          <td>{{ project.endDate | date: 'dd/MM/yyyy' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-end align-items-center m-2">
    <label for="rows" class="me-2">Rows per page:</label>
    <div class="d-flex align-items-center me-3">
      <select [(ngModel)]="pageSize" class="form-select" id="rows">
        <option *ngFor="let ps of pageSizeOptions" [ngValue]="ps">
          {{ ps }}
        </option>
      </select>
    </div>
    <ngb-pagination
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="filteredProjects.length"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>
