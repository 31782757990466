<h4>Review Details</h4>
<h6>To make edits, select the relevant heading above</h6>
<div class="card px-2">
  <div class="card-body">
    <div class="row border rounded">
      <div class="col-5">
        <div class="p-1">
          Project Name:
          <span>{{ parentFormGroup.value.details.projectName }}</span>
        </div>
        <div class="p-1">
          Project Description:
          <span>{{ parentFormGroup.value.details.projectDescription }}</span>
        </div>
        <div class="p-1">
          Project Location: {{ parentFormGroup.value.details.projectLocation }}
        </div>
        <div class="p-1">
          Category/Code: {{ parentFormGroup.value.details.projectCode }}
        </div>
        <div class="p-1">
          Visibility:
          {{
            parentFormGroup.value.projectSettings.visibility.status | titlecase
          }}
        </div>
        <div class="d-flex p-1">
          <div>Project Owners:</div>
          <div
            *ngFor="
              let person of parentFormGroup.value.projectSettings.ownership
                .people
            "
          >
            <div class="badge rounded-pill bg-success mx-1 p-1">
              {{ person }}
            </div>
          </div>
        </div>
        <div class="d-flex p-1">
          <div>Project Visibility:</div>
          <div
            *ngFor="
              let person of parentFormGroup.value.projectSettings.visibility
                .people
            "
          >
            <div class="badge rounded-pill bg-success mx-1 p-1">
              {{ person }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div class="p-1">
          <span>Date:</span>
          {{ parentFormGroup.value.details.startDate.day }}/{{
            parentFormGroup.value.details.startDate.month
          }}/{{ parentFormGroup.value.details.startDate.year }}
          -
          {{ parentFormGroup.value.details.endDate.day }}/{{
            parentFormGroup.value.details.endDate.month
          }}/{{ parentFormGroup.value.details.endDate.year }}
        </div>
        <div class="p-1">
          <div>Phases:</div>
          <div class="d-flex flex-wrap">
            <div
              *ngFor="
                let phase of parentFormGroup.value.projectSettings.phasesForm
                  .phases
              "
            >
              <div class="d-flex align-items-center mb-1">
                <div
                  class="rectangle text-center"
                  [style.background-color]="phase.colour"
                >
                  {{ phase.title }}
                </div>
                <div
                  class="triangle-right"
                  [style.border-left]="'40px solid ' + phase.colour"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="w-75">
        <h5>Project Team Members</h5>
        <div>
          <table class="table w-100 border">
            <thead class="table-light">
              <tr>
                <th scope="col" class="w-25">Name</th>
                <th scope="col" class="w-40">Role</th>
                <th scope="col" class="w-35">Role Match</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let member of parentFormGroup.value.rolesAndResources
                    .projectMembers.assignedMembers.projectMembers
                "
              >
                <td>{{ member.name }}</td>
                <td>{{ member.roleName }}</td>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="w-75">
                      <div class="progress">
                        <div
                          class="progress-bar bg-success"
                          role="progressbar"
                          aria-label="Basic example"
                          [style.width]="member.score + '%'"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="w-25 mx-2">
                      <span>{{ member.score }}%</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
