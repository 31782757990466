<div class="p-3">
  <div class="border-bottom">
    <p>
      Default outcomes for all assessment types regardless of the event are
      outlined below.
    </p>
    <p>
      Additional options may be added; these outcomes can be selected to be
      recorded against an individual's assessment when finalised.
    </p>
    <button
      (click)="openAssessmentOptionModal(TrainingOutcomeAction.Create)"
      class="btn esqepBtn mb-2"
      [hidden]="readOnlyView"
    >
      Add Assessment Option
    </button>
  </div>

  <div class="my-3">
    <h5>Passed Assessment Options</h5>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="passedOptionsTableRows"
      [tableRowButtons]="tableRowButtons"
      [isLoading]="tableLoading"
      [showPagination]="false"
    >
    </ug-table>
  </div>
  <hr />
  <div class="my-3">
    <h5>Failed Assessment Options</h5>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="failedOptionsTableRows"
      [tableRowButtons]="tableRowButtons"
      [isLoading]="tableLoading"
      [showPagination]="false"
    >
    </ug-table>
  </div>
</div>
