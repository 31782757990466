import { CommonModule, JsonPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbAccordionModule,
  NgbActiveModal,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxEditorModule } from 'ngx-editor';
import { SpinnerService } from '../controls/services/spinner.service';
import { SpinnerComponent } from '../controls/spinner/spinner.component';
import { BaseComponent } from './base-component/base-component.component';
import { BaseInputComponent } from './base-input/base-input.component';
import { CardTileComponent } from './card-tile/card-tile.component';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { DynamicFormFilterComponent } from './dynamic-form/dynamic-form-filter/dynamic-form-filter.component';
import { DynamicFormQuestionComponent } from './dynamic-form/dynamic-form-question.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FilterControlService } from './dynamic-form/filter-control.service';
import { JobFamilySelectControlComponent } from './job-family-select-control/job-family-select-control.component';
import { ListStatusComponent } from './list-status/list-status.component';
import { ModalComponent } from './modal/modal.component';
import { ProgressBarFakeComponent } from './progress-bar-fake/progress-bar-fake.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SortableHeaderDirective } from './table/sortable-header.directive';
import { TableComponent } from './table/table.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TextFilterControlComponent } from './text-filter-control/text-filter-control.component';
import { ToastsComponent } from './toasts/toasts.component';
import { WarnUnsavedComponent } from './warn-unsaved/warn-unsaved.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbNavModule,
    NgbPopoverModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbToastModule,
    NgbTooltipModule,
    NgbModule,
    NgSelectModule,
    NgxEditorModule,
    NgOptionHighlightModule,
    NgbDatepickerModule,
    JsonPipe,
  ],
  declarations: [
    JobFamilySelectControlComponent,
    ListStatusComponent,
    SpinnerComponent,
    TextFilterControlComponent,
    WarnUnsavedComponent,
    TableComponent,
    SortableHeaderDirective,
    BaseComponent,
    BaseInputComponent,
    DropdownSelectComponent,
    ProgressBarComponent,
    CardTileComponent,
    DynamicFormFilterComponent,
    DynamicFormComponent,
    ToastsComponent,
    ModalComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ProgressBarFakeComponent,
    TextEditorComponent,
  ],
  exports: [
    JobFamilySelectControlComponent,
    ListStatusComponent,
    SpinnerComponent,
    TextFilterControlComponent,
    WarnUnsavedComponent,
    SortableHeaderDirective,
    DropdownSelectComponent,
    NgbTypeaheadModule,
    ProgressBarComponent,
    CardTileComponent,
    ModalComponent,
    TableComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    DynamicFormFilterComponent,
    ToastsComponent,
    ProgressBarFakeComponent,
    TextEditorComponent,
  ],
  providers: [SpinnerService, FilterControlService, NgbActiveModal],
})
export class ControlsModule {}
