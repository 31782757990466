import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { provideTranslocoScope } from '@jsverse/transloco';

@Component({
  selector: 'ug-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.scss'],
  providers: [provideTranslocoScope('additional-details')],
})
export class AdditionalDetailsComponent {
  @Input() detailsFormGroup: FormGroup;

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => {
    return 0;
  };
}
