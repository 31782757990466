import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ug-unassigned-users-control',
  templateUrl: './unassigned-users-control.component.html',
  styleUrl: './unassigned-users-control.component.scss',
})
export class UnassignedUsersControlComponent {
  @Output() showUnassignedUsersEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  showUnassignedUsers: boolean = false;

  toggleUnassignedUsers($event: any) {
    this.showUnassignedUsers = $event.target.checked;
    this.showUnassignedUsersEvent.emit(this.showUnassignedUsers);
  }
}
