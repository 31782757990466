import { Component, Input, inject } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { MenuItem, Subset } from '../../menu-item';

@Component({
  selector: 'esqep-navbar-menu', // tslint:disable-line: component-selector
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
})
export class NavbarMenuComponent {
  private authService: AuthService = inject(AuthService);

  @Input() menuLoading = true;
  @Input() menuData: Array<MenuItem | Subset<MenuItem>> = [];
  @Input() claimNotificationMap = new Map<string, number>();

  subMenu: string;

  get menuReady(): boolean {
    return !this.menuLoading && this.authService.isAuthenticated;
  }

  toggleSubMenu(itemName: string) {
    this.subMenu = this.subMenu == itemName ? null : itemName;
  }
}
