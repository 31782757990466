import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable, catchError, map, throwError } from 'rxjs';
import { SharedInfo } from '../../claim/services/claim.service';
import { Notification } from '../models/notification';
import { Person } from '../models/person';

import { environment } from '../../../environments/environment';
import { SecurityRole } from '../../core/models/security-role';
import { ConfigService } from '../../core/services/config.service';
import { ExtOrgUnitPerson } from '../../org-unit/services/org-unit.service';
import { CurriculaSummary } from '../curricula-summary/curriculaSummary';

export interface PersonJobRoles {
  personId: number;
  jobRoles: Array<{
    jobRoleDescription: string;
    jobRoleName: string;
    jobRoleId: number;
    orgUnitId: number;
    orgUnitName: string;
  }>;
}

export interface PersonVerificationAreas {
  personId: number;
  verificationAreas: Array<{
    funcAreaDescription: string;
    funcAreaId: number;
    funcAreaName: string;
    maxApprovalLevel: number;
  }>;
}

export interface PersonDirectReports {
  personId: number;
  directReports: Array<{
    personId: number;
    displayName: string;
    jobRoles: Array<{
      jobRoleId: number;
      jobRoleName: string;
    }>;
  }>;
}

export interface PersonBasic {
  id: number;
  name: string;
  displayName: string;
  externalId: number;
  externalUserId: number;
}

export interface ExternalActivePerson {
  id: number;
  name: string;
  displayName: string;
  externalId: number;
  externalUserId: number;
  location: string;
  orgUnitNames: string;
  jobRoleNames: string;
}

interface PersonJobRolesResponse {
  personJobRoles: PersonJobRoles;
}

export interface PersonSearchParameters {
  isViewUserData?: boolean;
  orgUnitTypeId?: number;
  orgUnitId?: number;
  allowTrainingCalendarAdmin?: boolean;
  securityRoleId?: number;
  source?: string;
  externalTypeId?: string;
  operator?: string;
  search?: string;
  maxCount?: number;
  page?: number;
  pageSize?: number;
}

export interface PersonLookup {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

export interface PersonSecrityRole {
  adUpn: string;
  securityRoleId: number;
  securityLevel: number;
  orgUnits: { id: number; name: string }[];
  id: number;
  name: string;
  displayName: string;
  externalId: any;
  externalUserId: any;
}

export interface SecurityRoleUpdate {
  securityRoleId: number;
  orgUnitIds: number[];
}

export interface PagedPersonSecurityRoleSearch {
  data: PersonSecrityRole[];
  page: number;
  pageSize: number;
  totalPageCount: number;
  totalRowCount: number;
}

export interface PersonSubordinate {
  displayName: string;
  id: number;
  jobRoles: { id: number; name: string }[];
}

const apiServerUri = environment.apiUrl;
const dotNetApiServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  private http = inject(HttpClient);
  private config = inject(ConfigService);


  getPersonList(): Observable<Array<Person>> {
    return this.http.get(`${apiServerUri}/people/`).pipe(
      map((resp) => {
        return resp['person'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonByUUID(uuid: string): Observable<Person> {
    return this.http.get(`${apiServerUri}/person/${uuid}`).pipe(
      map((resp) => resp['person'] || {}),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }
  getPeopleByUserIds(ids: Array<string>): Observable<Array<Person>> {
    return this.http.post(`${apiServerUri}/people/userids`, ids).pipe(
      map((resp) => {
        return resp['people'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonSummary(personId: number): Observable<Person> {
    return this.http.get(`${apiServerUri}/person/${personId}/summary`).pipe(
      map((resp) => {
        return resp['person'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonJobRoles(personId: number): Observable<PersonJobRoles> {
    return this.http.get(`${apiServerUri}/person/${personId}/job-roles`).pipe(
      map((resp) => {
        return resp['personJobRoles'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonDirectReports(personId: number): Observable<PersonDirectReports> {
    let params: HttpParams = new HttpParams();

    if (this.config.excludeExternalInactivePeople) {
      params = params.append('excludeExternalInactive', 'true');
    }
    return this.http
      .get(`${apiServerUri}/person/${personId}/direct-reports`, {
        params: params,
      })
      .pipe(
        map((resp) => {
          return resp['personDirectReports'] || {};
          // return (resp || {}) as PersonDirectReports;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPersonVerificationAreas(
    personId: number,
  ): Observable<PersonVerificationAreas> {
    return this.http
      .get<PersonVerificationAreas>(
        `${apiServerUri}/person/${personId}/verifier-for`,
      )
      .pipe(
        map((resp) => {
          return resp['personVerifierFor'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getNotificationsByPersonId(
    personId: number,
  ): Observable<Array<Notification>> {
    return this.http
      .get(`${apiServerUri}/person/${personId}/notifications`)
      .pipe(
        map((resp) => {
          return resp['notifications'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  setNotificationAsRead(
    personId: number,
    notification: Notification,
  ): Observable<Notification> {
    return this.http
      .put(
        `${apiServerUri}/person/${personId}/notifications/${notification.id}/read`,
        JSON.stringify(notification),
      )
      .pipe(
        map((resp) => {
          return resp['notification'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSharedInfoByPersonId(personId: number): Observable<Array<SharedInfo>> {
    return this.http
      .get<Array<SharedInfo>>(`${apiServerUri}/person/${personId}/shared-info`)
      .pipe(
        map((resp: Array<SharedInfo>) => {
          return resp['sharedInfoList'] || ([] as Array<SharedInfo>);
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  deleteSharedInfo(sharedInfoId: number, personId: number): Observable<any> {
    return this.http
      .delete(`${apiServerUri}/person/${personId}/shared-info/${sharedInfoId}`)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPersonSecurityRole(uuid: string): Observable<SecurityRole> {
    return this.http
      .get<{
        securityRole: SecurityRole;
      }>(`${apiServerUri}/person/${uuid}/security-role`)
      .pipe(
        map((resp) => {
          return resp.securityRole;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updatePersonSecurityRole(
    personId: number,
    securityRoleUpdate: SecurityRoleUpdate,
  ) {
    const url = `${dotNetApiServerUri}/people/${personId}/securityRole`;
    return this.http.post(url, securityRoleUpdate).pipe(
      map((resp) => resp),
      catchError((err) => throwError(() => new Error(err))),
    );
  }

  getPersonExternalOus(personId: number): Observable<ExtOrgUnitPerson> {
    return this.http
      .get(`${apiServerUri}/people/${personId}/ext-org-units`)
      .pipe(
        map((resp) => {
          return resp['personExternalOrgUnits'];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPeopleInExternalOu(
    extOuType: string,
    extOus: Array<string>,
  ): Observable<Array<Person>> {
    let params: HttpParams = new HttpParams();

    extOus.forEach((eou) => {
      params = params.append('extOuId', eou);
    });
    return this.http
      .get(`${apiServerUri}/people/ext-org-unit/${extOuType}`, {
        params: params,
      })
      .pipe(
        map((resp) => {
          return resp['people'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getUserPhoto(userId: string): Observable<Blob> {
    return (
      this.http
        .get(`${apiServerUri}/graph/users/${userId}/photo`, {
          responseType: 'blob',
        })
        // return this.http.get(
        //   'https://graph.microsoft.com/v1.0/me/photo/$value',
        //   {
        //     responseType: 'blob'
        //   }
        // )
        .pipe(
          map((resp) => {
            return new Blob([resp], { type: resp.type });
          }),
          catchError((err) => {
            return throwError(() => new Error(err));
          }),
        )
    );
  }

  // .net request

  search(payload: PersonSearchParameters): Observable<any> {
    const url = `${dotNetApiServerUri}/people/search`;
    return this.http.post(url, JSON.stringify(payload)).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  searchForSecurityRoles(
    payload: PersonSearchParameters,
  ): Observable<PersonSecrityRole[]> {
    const url = `${dotNetApiServerUri}/people/forSecurityRoles`;
    return this.http
      .post<PersonSecrityRole[]>(url, JSON.stringify(payload))
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  searchForSecurityRolesPaged(
    payload: PersonSearchParameters,
  ): Observable<PagedPersonSecurityRoleSearch> {
    const url = `${dotNetApiServerUri}/people/forSecurityRoles/paged`;
    return this.http.post<PagedPersonSecurityRoleSearch>(
      url,
      JSON.stringify(payload),
    );
  }

  getExternalActivePeople(): Observable<ExternalActivePerson[]> {
    const url = `${dotNetApiServerUri}/people/externalActive`;
    return this.http.get<ExternalActivePerson[]>(url).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getPersonCategoryStatusCounts(): Observable<Array<CurriculaSummary>> {
    const url = `${dotNetApiServerUri}/people/categoryStatusCounts`;
    return this.http.get<Array<CurriculaSummary>>(url);
  }

  getPersonLookup(payload: PersonSearchParameters): Observable<PersonLookup[]> {
    return this.http
      .post<
        PersonLookup[]
      >(`${dotNetApiServerUri}/people/lookups`, JSON.stringify(payload))
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getPersonSubordinates(): Observable<PersonSubordinate[]> {
    return this.http.get<PersonSubordinate[]>(
      `${dotNetApiServerUri}/people/subordinates`,
    );
  }
}
