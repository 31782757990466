<ug-list-status
  [isLoading]="isLoading"
  [listLength]="listLength"
></ug-list-status>
<ng-container *ngIf="!isLoading">
  <div class="px-4" *transloco="let t">
    <ng-container *ngFor="let item of jobRoleSummary | keyvalue: originalOrder">
      <div class="mb-4" *ngIf="item.value && isNotEmptyHtml(item.value)">
        <h5 class="fw-bold">
          {{ t('jobRoleDetails.' + item.key) | titlecase }}
        </h5>
        <div class="innerHtmlContainer" [innerHTML]="item.value"></div>
      </div>
    </ng-container>
  </div>
</ng-container>
