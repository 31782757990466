<div class="col-12 col-lg-12" [formGroup]="formGroup">
  <div class="card-title">
    <span class="h6"
      >Role Requirements ({{
        roleReqsArray.length | i18nPlural: reqCountMessageMap
      }})</span
    >
    <button
      type="button"
      class="btn btn-outline-primary btn-sm"
      (click)="onAddRequirementClick()"
      [disabled]="addingReqInProgress"
    >
      Add Requirement
    </button>
  </div>
  <div class="table-responsive">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Learning Object</th>
          <th scope="col">Type</th>
          <th scope="col">Version</th>
          <th scope="col">Provider</th>
          <th scope="col">Content Owner</th>
          <th scope="col">Active</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="addingReqInProgress" formGroupName="fgNewReq">
        <tr class="table-warning">
          <td>
            <select
              class="form-select form-select-sm"
              [ngClass]="{
                'is-invalid': !formControlValid('fgNewReq.disciplineId'),
              }"
              [name]="'jobRole' + jobRoleIndex + 'NewReqDiscipline'"
              formControlName="disciplineId"
              (change)="onDisciplineChange()"
              title="Competency Category"
            >
              <option disabled selected [ngValue]="null">
                Select Competency Subcategory
              </option>
              <option
                *ngFor="let discipline of disciplineList"
                [ngValue]="discipline.id"
              >
                {{ discipline.name }}
              </option>
            </select>
            <div class="invalid-feedback">
              Competency Subcategory is required
            </div>
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              [ngClass]="{
                'is-invalid': !formControlValid('fgNewReq.skillId'),
              }"
              [name]="'jobRole' + jobRoleIndex + 'NewSkillId'"
              formControlName="skillId"
              (change)="onSkillChange()"
              title="Skill"
            >
              <option disabled selected [ngValue]="null">
                Select Competency...
              </option>
              <option
                *ngFor="let skill of skillsCache[addedDisciplineId]"
                [ngValue]="skill.id"
              >
                {{ skill.name }}
              </option>
            </select>
            <div
              *ngIf="
                formGroup
                  .get('fgNewReq.skillId')
                  .hasError('roleSkillUniqueness')
              "
              class="invalid-feedback"
            >
              Duplicate Competency
            </div>
            <div
              *ngIf="formGroup.get('fgNewReq.skillId').hasError('required')"
              class="invalid-feedback"
            >
              Competency is required
            </div>
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              [ngClass]="{
                'is-invalid': !formControlValid('fgNewReq.claimLevelId'),
              }"
              [name]="'jobRole' + jobRoleIndex + 'NewReqLevel'"
              formControlName="claimLevelId"
              title="Level"
            >
              <option disabled selected [ngValue]="null">
                Select Level...
              </option>
              <option
                *ngFor="let level of levelSetsCache[addedDisciplineId]"
                [ngValue]="level.id"
              >
                {{ level.name }}
              </option>
            </select>
            <div class="invalid-feedback">Level is required</div>
          </td>
          <td class="text-center">
            <input
              class="form-control form-control-sm text-end"
              [ngClass]="{
                'is-invalid': !formControlValid('fgNewReq.weighting'),
              }"
              type="number"
              step="0.01"
              min="0"
              max="1"
              name="'jobRole' + jobRoleIndex + 'NewReqWeighting'"
              formControlName="weighting"
              title="Weighting"
            />
            <div class="invalid-feedback">
              Weighting must be between 0 and 1
            </div>
          </td>
          <td class="text-center">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                [name]="'jobRole' + jobRoleIndex + 'NewReqMandatory'"
                formControlName="mandatory"
                title="Is mandatory?"
              />
            </div>
          </td>
          <td>
            <div class="btn-group btn-group-sm">
              <button
                type="button"
                class="btn btn-outline-success btn-sm"
                (click)="onNewReqDoneClick()"
                [disabled]="!formGroup.get('fgNewReq').valid"
              >
                <span
                  class="fas fa-xl fa-check"
                  title="Save job requirement"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                (click)="onNewReqDeleteClick()"
                title="Delete job requirement"
              >
                <span class="fas fa-xl fa-times"></span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody formArrayName="requirements">
        <tr
          *ngFor="
            let roleRequirement of roleReqsArray;
            let j = index;
            trackBy: trackByReqs
          "
          [formGroupName]="j"
          [class.table-danger]="roleRequirement.action === 'D'"
        >
          <td>{{ roleRequirement?.disciplineName }}</td>
          <td>{{ roleRequirement?.skillName }}</td>
          <td>
            <select
              class="form-select form-select-sm"
              [name]="'jobRole' + jobRoleIndex + 'Req' + j + 'Level'"
              formControlName="claimLevelId"
              title="Level"
            >
              <option disabled selected [ngValue]="null">
                Select Level...
              </option>
              <option
                *ngFor="
                  let level of levelSetsCache[roleRequirement.disciplineId];
                  let k = index
                "
                [ngValue]="level.id"
              >
                {{ level.name }}
              </option>
            </select>
          </td>
          <td class="text-center">
            <input
              class="form-control form-control-sm text-end"
              [ngClass]="{
                'is-invalid': !formControlValid(
                  'requirements.' + j + '.weighting'
                ),
              }"
              type="number"
              step="0.01"
              min="0"
              max="1"
              name="'jobRole' + jobRoleIndex + 'Req' + j + 'Weighting'"
              formControlName="weighting"
              title="Weighting"
            />
            <div class="invalid-feedback">
              Weighting must be between 0 and 1
            </div>
          </td>
          <td class="text-center">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                [name]="'jobRole' + jobRoleIndex + 'Req' + j + 'Mandatory'"
                formControlName="mandatory"
                title="Is mandatory?"
              />
            </div>
          </td>
          <td>
            <button
              *ngIf="roleRequirement.action !== 'D'"
              type="button"
              class="btn btn-outline-danger btn-sm"
              (click)="onRemoveRequirementClick(j)"
            >
              Remove
            </button>
            <button
              *ngIf="roleRequirement.action === 'D'"
              type="button"
              class="btn btn-outline-secondary btn-sm"
              (click)="onRestoreRequirementClick(j)"
            >
              Restore
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
