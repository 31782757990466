<div *ngIf="!isLoading && jobRoleForm.get('id').value">
  <h4 class="jr-subtitle">
    {{ jobRoleForm.get('id').value }} : {{ jobRoleForm.get('name').value }}
  </h4>
</div>
<div class="card shadow overflow-visible">
  <div class="card-body">
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="listLength"
    ></ug-list-status>
    <div *ngIf="!isLoading">
      <form>
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="activeTab"
          [destroyOnHide]="false"
          class="nav-justified"
        >
          <li [ngbNavItem]="navItemList['details']" id="details">
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Key Details</h6>
              <h6><small>Provide key details about the role</small></h6>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="jobReqEventHasNoSessions"
                class="alert alert-warning mb-3 py-2"
              >
                <i class="fas fa-exclamation-triangle pe-2"></i>One or more
                Events assigned to this role has no Sessions defined
              </div>
              <ug-role-details
                [parentFormGroup]="jobRoleForm"
                [detailsAudit]="detailsAudit"
              ></ug-role-details>
            </ng-template>
          </li>
          <li
            *ngIf="navItemList['competencies']"
            [ngbNavItem]="navItemList['competencies']"
            id="competencies"
          >
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Competencies</h6>
              <h6>
                <small>Add/Remove competencies assigned to the role</small>
              </h6>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="jobReqEventHasNoSessions"
                class="alert alert-warning mb-3 py-2"
              >
                <i class="fas fa-exclamation-triangle pe-2"></i>One or more
                Events assigned to this role has no Sessions defined
              </div>
              <ug-role-competencies
                [discardChangesSubj]="discardChangesSubj"
                [jobRoleId]="jobRoleId"
                [parentFormGroup]="jobRoleForm"
                [allowEdit]="allowEditReq"
                [jobRoleCompetencies]="jobRoleCompetencies"
                [jobRole]="jobRole"
                [requirementAudit]="requirementAudit"
              >
              </ug-role-competencies>
            </ng-template>
          </li>
          <li
            *ngIf="navItemList['training']"
            [ngbNavItem]="navItemList['training']"
            id="training"
          >
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Training</h6>
              <h6><small>Add/Remove training assigned to this role</small></h6>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="jobReqEventHasNoSessions"
                class="alert alert-warning mb-3 py-2"
              >
                <i class="fas fa-exclamation-triangle pe-2"></i>One or more
                Events assigned to this role has no Sessions defined
              </div>
              <ug-role-training
                [discardChangesSubj]="discardChangesSubj"
                [parentFormGroup]="jobRoleForm"
                [allowEdit]="allowEditReq"
                [jobRoleId]="jobRoleId"
                [jobRoleTrainings]="jobRoleTrainings"
                [jobRole]="jobRole"
                [requirementAudit]="requirementAudit"
              ></ug-role-training>
            </ng-template>
          </li>
          <li [ngbNavItem]="navItemList['assignment']" id="assignment">
            <a ngbNavLink class="p-0 m-0" (click)="setTab()">
              <h6>Assignment</h6>
              <h6>
                <small
                  >Manage people/organisation units assigned to the role</small
                >
              </h6>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="jobReqEventHasNoSessions"
                class="alert alert-warning mb-3 py-2"
              >
                <i class="fas fa-exclamation-triangle pe-2"></i>One or more
                Events assigned to this role has no Sessions defined
              </div>
              <ug-role-assignment
                [discardChangesSubj]="discardChangesSubj"
                [saveChangesSubj]="saveChangesSubj"
                [parentFormGroup]="jobRoleForm"
                [allowEdit]="allowEditPeople"
                [jobRoleId]="jobRoleId"
                [events]="jobRoleSavedSubject.asObservable()"
                (changeOrgUnitsRoles)="jobRoleOrgUnitsChanges($event)"
                (changePeopleRoles)="jobRolePeopleChanges($event)"
                [assignmentAudit]="assignmentAudit"
                [orgUnitAudit]="orgUnitAudit"
              ></ug-role-assignment>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </form>
    </div>
  </div>
  <div class="card-footer bg-white border-0" *ngIf="!isLoading">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        *ngIf="jobRoleForm.dirty && allowEditRole"
        class="btn btn-outline-secondary me-1"
        (click)="discardChanges()"
      >
        Discard Changes
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary ms-3"
        (click)="backToJobRoles()"
      >
        Return to Job Roles
      </button>
      <button
        *ngIf="createAnotherRole"
        type="button"
        class="btn btn-outline-secondary ms-3"
        (click)="createJobRole()"
      >
        Create Another
      </button>
      <button
        *ngIf="allowEditRole"
        [disabled]="!jobRoleForm.dirty || jobRoleForm.invalid"
        type="button"
        title="Save"
        class="btn btn-outline-success ms-3"
        (click)="onSaveClick()"
      >
        Save
      </button>
      <button
        type="button"
        *ngIf="activeTab !== 1"
        title="Previous"
        [disabled]="activeTab === 1"
        class="btn esqepBtn ms-3"
        (click)="selectPreviousTab()"
      >
        Previous
      </button>
      <button
        type="button"
        *ngIf="activeTab !== navItemLength"
        title="Next"
        class="btn esqepBtn ms-3"
        (click)="selectNextTab()"
      >
        Next
      </button>
    </div>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
