<div class="modal-header pb-1 px-3 view-session">
  <h4 class="" id="modal-basic-title px-1">{{ currentSession.title }}</h4>
  <button
    type="button"
    class="btn-close m-0 text-center align-self-start"
    aria-label="Close"
    (click)="modal.dismiss()"
  ></button>
</div>

<div class="modal-body">
  <div *ngIf="viewSessionObject">
    <div *ngFor="let ent of viewSessionObjectEntries()" class="row">
      <div class="col-3">
        <ng-container [ngSwitch]="ent[0]">
          <p *ngSwitchCase="'startDate'" class="fw-bold">Start date</p>
          <p *ngSwitchCase="'endDate'" class="fw-bold">End date</p>
          <p *ngSwitchCase="'attendeeCount'" class="fw-bold">Attendee count</p>
          <p *ngSwitchDefault class="fw-bold">{{ ent[0] | titlecase }}</p>
        </ng-container>
      </div>
      <div class="col-9">{{ ent[1] }}</div>
    </div>
  </div>
</div>
