<div class="modal-header bg-dark text-light">
  <h5 class="modal-title">Keys definition</h5>
  <button
    type="button"
    class="btn-close btn-close-white"
    (click)="modal.close()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <table class="table table-sm table-borderless table-striped">
    <tbody>
      <tr *ngFor="let state of keySymbolMap | keyvalue : originalOrder">
        <td class="text-center align-middle">
          <span
            class="ms-2 fa-xl"
            style="line-height: normal"
            [ngClass]="state.value"
            ><i></i
          ></span>
        </td>
        <td class="align-middle">{{ state.key }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.close()"
  >
    Close
  </button>
</div>
