<div class="nav-width">
  <div *ngIf="menuReady" class="side-nav-container">
    <ng-container>
      <ul class="side-nav-list">
        <li *ngFor="let topMenuItem of menuData; let first = first">
          <a
            class="side-nav-item"
            [routerLink]="topMenuItem.itemRoute ?? []"
            data-bs-target="#side-nav"
            (click)="toggleSubMenu(topMenuItem.itemName)"
          >
            <div class="menu-layout">
              <span
                class="menu-icons"
                [ngClass]="topMenuItem.itemIconClass"
              ></span>
              <span class="px-3">{{ topMenuItem.itemName }}</span>
            </div>
            <span
              *ngIf="!first"
              class="fas fa-sm"
              [ngClass]="
                subMenu === topMenuItem.itemName
                  ? 'fa-chevron-down'
                  : 'fa-chevron-right'
              "
            ></span>
          </a>
          <ul
            [ngbCollapse]="subMenu !== topMenuItem.itemName"
            class="side-nav-sublist"
          >
            <ng-container *ngIf="topMenuItem.subMenu">
              <li
                *ngFor="let dropDownMenuItem of topMenuItem.subMenu"
                class="side-nav-sublist-item position-relative"
                routerLinkActive="active"
              >
                <a
                  routerLinkActive="active"
                  [routerLink]="dropDownMenuItem.itemRoute"
                  >{{ dropDownMenuItem.itemName }}
                </a>
                <div>
                  <span
                    *ngIf="
                      dropDownMenuItem.claimCategory &&
                      claimNotificationMap.get(dropDownMenuItem.claimCategory) >
                        0
                    "
                    class="text-end badge bg-danger"
                    >{{
                      claimNotificationMap.get(dropDownMenuItem.claimCategory)
                    }}</span
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
