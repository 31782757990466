<div class="card overflow-hidden border-0 my-0 py-0">
  <div class="card-body my-0 py-0">
    <div class="d-flex justify-content-between">
      <h5>Role Due Date Settings</h5>
    </div>
    <form [formGroup]="settingsFormGroup">
      <div class="row align-items-end">
        <div class="col-2">
          <label class="form-check-label" for="due-after-value-control">
            Default due dates
          </label>
          <div class="input-group">
            <input
              type="number"
              min="1"
              class="form-control pe-0 ps-2 recur-due-alignment"
              id="due-after-value-control"
              value="30"
              title="Due after"
              formControlName="dueAfterValue"
            />
            <select
              class="form-select chevron-position pe-3 ps-1"
              title="Due after unit"
              formControlName="dueAfterUnit"
            >
              <option [value]="'day'">days</option>
              <option [value]="'week'">weeks</option>
              <option [value]="'month'">months</option>
              <option [value]="'year'">years</option>
            </select>
            <div class="invalid-feedback">
              Due after value must be blank or greater than 0
            </div>
          </div>
        </div>
        <div class="col-2">
          <label
            class="form-check-label"
            for="reversioning-due-after-value-control"
          >
            Reversioning due dates
          </label>
          <div class="input-group">
            <input
              type="number"
              min="1"
              class="form-control pe-0 ps-2 recur-due-alignment"
              id="reversioning-due-after-value-control"
              value="30"
              title="Reversioning value"
              formControlName="newVersionDueValue"
            />
            <select
              class="form-select chevron-position pe-3 ps-1"
              title="Reversioning unit"
              formControlName="newVersionDueUnit"
            >
              <option [value]="'day'">days</option>
              <option [value]="'week'">weeks</option>
              <option [value]="'month'">months</option>
              <option [value]="'year'">years</option>
            </select>
            <div class="invalid-feedback">
              Reversioning due value must be blank or greater than 0
            </div>
          </div>
        </div>
        <div class="col-2">
          <label class="form-check-label" for="new-hire-value-control">
            New hire due dates
          </label>
          <div class="input-group">
            <input
              type="number"
              min="1"
              class="form-control pe-0 ps-2 recur-due-alignment"
              id="new-hire-value-control"
              value="30"
              title="New Hire"
              formControlName="newHireDueValue"
            />
            <select
              class="form-select chevron-position pe-3 ps-1"
              title="New hire unit"
              formControlName="newHireDueUnit"
            >
              <option [value]="'day'">days</option>
              <option [value]="'week'">weeks</option>
              <option [value]="'month'">months</option>
              <option [value]="'year'">years</option>
            </select>
            <div class="invalid-feedback">
              New hire due value must be blank or greater than 0
            </div>
          </div>
        </div>
        <div class="col-2">
          <button
            *ngIf="!editingSettings && allowEdit"
            class="btn esqepBtn"
            (click)="editSettings()"
          >
            Edit
          </button>
        </div>
      </div>
      <div class="my-3" *ngIf="editingSettings && trainingLength > 0">
        <h5>Assignment Rules</h5>
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            (change)="selectIndividualRequirementsChange($event.target)"
            id="selected"
          />
          <label class="form-check-label" for="selected">
            <span class="fw-bold">Select Requirements</span> - when selected,
            values given above will impact requirements selected via the
            checkboxes below.
          </label>
        </div>
        <p>
          Choose to either replace or maintain existing requirements due dates:
        </p>
        <div>
          <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="radio"
              name="assignmentRule"
              id="replace"
              value="replace"
              checked
              [(ngModel)]="assignmentRule"
              [ngModelOptions]="{ standalone: true }"
            />
            <label class="form-check-label" for="replace">
              <span class="fw-bold">Replace (Default)</span> - when selected,
              values given above will replace existing requirements dates and
              trigger a new assignment when the job role is saved.
            </label>
          </div>
          <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="radio"
              value="newOnly"
              name="assignmentRule"
              id="newOnly"
              [(ngModel)]="assignmentRule"
              [ngModelOptions]="{ standalone: true }"
            />
            <label class="form-check-label" for="newOnly">
              <span class="fw-bold">New Only/Keep Due Dates</span> - when
              selected, values given above will impact new assignments only. Any
              existing requirements dates will be retained and no re-assignment
              issued.
            </label>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          *ngIf="editingSettings"
          class="btn btn-outline-danger me-2"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button
          *ngIf="editingSettings"
          class="btn btn-outline-success"
          (click)="confirmSettings()"
        >
          Confirm
        </button>
      </div>
    </form>
  </div>
</div>
