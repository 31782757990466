import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { TableHeader } from '../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { UitoolsService } from '../../../core/services/uitools.service';
import {
  EventsService,
  TrainingOutcome,
  TrainingOutcomeAction,
  TrainingOutcomePayload,
} from '../../../events/services/events.service';
import { AssessmentOptionsModalComponent } from '../assessment-options-modal/assessment-options-modal.component';

@Component({
  selector: 'ug-assessment-options',
  templateUrl: './assessment-options.component.html',
  styleUrls: ['./assessment-options.component.scss'],
})
export class AssessmentOptionsComponent implements OnInit, OnDestroy {
  private eventsService = inject(EventsService);
  private alertService = inject(AlertService);
  private ngbModal = inject(NgbModal);
  private uiService = inject(UitoolsService);

  exceptionData = {
    TRAINING_OUTCOME_CREATE: {
      level: AlertLevels.ERROR,
      code: 'EAO-001',
      message: 'Error creating an assessment option',
    } as AlertData,
    TRAINING_OUTCOMES: {
      level: AlertLevels.ERROR,
      code: 'EAO-002',
      message: 'Error retrieving assessment options',
    } as AlertData,
    TRAINING_OUTCOME_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'EAO-003',
      message: 'Error update assessment option',
    } as AlertData,
  };

  ngUnsubscribe: Subject<boolean> = new Subject();
  tableHeaders: Array<TableHeader> = [];
  tableRowButtons = [];
  noDataMessage: 'No Assessment Options Found';
  tableLoading = true;
  passedOptionsTableRows = [];
  failedOptionsTableRows = [];
  assessmentOptions = [];
  TrainingOutcomeAction = TrainingOutcomeAction;
  @Input() readOnlyView: boolean;

  ngOnInit(): void {
    this.tableHeaders = [
      { id: 'name', title: 'Name', class: 'w-25' },
      { id: 'description', title: 'Description', class: 'w-45' },
      {
        id: 'active',
        title: 'Active',
        iconFunction: this.activeIcon,
        class: 'w-15 text-center',
      },
    ];

    this.tableRowButtons = [
      {
        title: 'Edit',
        class: 'btn-outline-secondary',
        rowAction: this.onEditOutcomeClick,
        hideCondition: this.hideEditAndDeactivate,
      },
      {
        title: 'Deactivate',
        class: 'btn-outline-danger',
        rowAction: this.onReactivateOrDeactivateOptionClick,
        hideCondition: this.hideDeactivate,
      },
      {
        title: 'Reactivate',
        class: 'btn-outline-success',
        rowAction: this.onReactivateOrDeactivateOptionClick,
        hideCondition: this.hideReactivate,
      },
    ];

    this.eventsService
      .getTrainingOutcomes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (c: TrainingOutcome[]) => {
          this.assessmentOptions = c;
          this.updateTrainingOutcomeOptionTables(TrainingOutcomeAction.Init);
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.TRAINING_OUTCOMES,
            err,
          );
        },
      });
  }

  openAssessmentOptionModal(
    trainingOutcomeAction: TrainingOutcomeAction,
    trainingOutcomeOption?: TrainingOutcome,
  ) {
    const modalRef = this.ngbModal.open(AssessmentOptionsModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    if (trainingOutcomeOption) {
      modalRef.componentInstance.trainingOutcome = trainingOutcomeOption;
    }

    modalRef.componentInstance.saveTrainingOutcome.subscribe(
      (trainingOutcomeData: TrainingOutcomePayload) => {
        switch (trainingOutcomeAction) {
          case TrainingOutcomeAction.Create:
            this.eventsService
              .createTrainingOutcome(trainingOutcomeData)
              .subscribe({
                next: (s: TrainingOutcome) => {
                  this.updateTrainingOutcomeOptionTables(
                    TrainingOutcomeAction.Create,
                    s,
                  );
                  this.uiService.showToast(`"${s.name}" successfully added`, {
                    classname: 'bg-success text-light',
                    delay: 5000,
                  });
                },
                error: (err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.TRAINING_OUTCOME_CREATE,
                    err,
                  );
                },
              });
            break;
          case TrainingOutcomeAction.Update:
            this.eventsService
              .updateTrainingOutcomeById(
                trainingOutcomeOption.id,
                trainingOutcomeData,
              )
              .subscribe({
                next: (s: TrainingOutcome) => {
                  this.updateTrainingOutcomeOptionTables(
                    TrainingOutcomeAction.Update,
                    s,
                  );
                  this.uiService.showToast(`${s.name} successfully updated`, {
                    classname: 'bg-success text-light',
                    delay: 5000,
                  });
                },
                error: (err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.TRAINING_OUTCOME_UPDATE,
                    err,
                  );
                },
              });
            break;
        }
      },
    );
  }

  onReactivateOrDeactivateOptionClick = (option) => {
    const trainingOutcomeData: TrainingOutcomePayload = {
      name: option.name,
      description: option.description,
      isSuccess: option.isSuccess,
      isActive: !option.isActive,
    };
    this.eventsService
      .updateTrainingOutcomeById(option.id, trainingOutcomeData)
      .subscribe({
        next: (s: TrainingOutcome) => {
          this.updateTrainingOutcomeOptionTables(
            TrainingOutcomeAction.Update,
            s,
          );
          this.uiService.showToast(`"${s.name}" successfully updated`, {
            classname: 'bg-success text-light',
            delay: 5000,
          });
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.TRAINING_OUTCOME_UPDATE,
            err,
          );
        },
      });
  };

  onEditOutcomeClick = (option) => {
    this.openAssessmentOptionModal(TrainingOutcomeAction.Update, option);
  };

  activeIcon = (row) => {
    return row.isActive
      ? 'fas fa-xl fa-check-circle text-success'
      : 'fas fa-xl fa-times-circle text-danger';
  };

  hideEditAndDeactivate = (assessmentOption) => {
    return assessmentOption.isDefault || this.readOnlyView;
  };

  hideDeactivate = (assessmentOption) => {
    return (
      !assessmentOption.isActive ||
      assessmentOption.isDefault ||
      this.readOnlyView
    );
  };

  hideReactivate = (assessmentOption) => {
    return (
      assessmentOption.isActive ||
      assessmentOption.isDefault ||
      this.readOnlyView
    );
  };

  updateTrainingOutcomeOptionTables(
    trainingOutcomeAction?: TrainingOutcomeAction,
    trainingOutcome?: TrainingOutcome,
  ) {
    switch (trainingOutcomeAction) {
      case TrainingOutcomeAction.Init:
        this.filterAssessmentOptions();
        this.tableLoading = false;
        break;
      case TrainingOutcomeAction.Create:
        this.addTrainingOutcome(trainingOutcome);
        break;
      case TrainingOutcomeAction.Update:
        this.updateTrainingOutcome(trainingOutcome);
        break;
      default:
        console.warn(
          'Unsupported training outcome action:',
          trainingOutcomeAction,
        );
    }
  }

  private filterAssessmentOptions() {
    this.passedOptionsTableRows = this.filterOptionsBySuccess(true);
    this.failedOptionsTableRows = this.filterOptionsBySuccess(false);
  }

  private addTrainingOutcome(trainingOutcome: TrainingOutcome) {
    if (trainingOutcome.isSuccess) {
      this.passedOptionsTableRows = [
        ...this.passedOptionsTableRows,
        ...[trainingOutcome],
      ];
    } else {
      this.failedOptionsTableRows = [
        ...this.failedOptionsTableRows,
        ...[trainingOutcome],
      ];
    }
  }

  private updateTrainingOutcome(trainingOutcome: TrainingOutcome) {
    let index: number;

    if (trainingOutcome.isSuccess) {
      index = this.passedOptionsTableRows.findIndex(
        (option) => option.id === trainingOutcome.id,
      );
      if (index !== -1) {
        this.passedOptionsTableRows[index] = trainingOutcome;
        this.passedOptionsTableRows = [...this.passedOptionsTableRows];
      }
    } else {
      index = this.failedOptionsTableRows.findIndex(
        (option) => option.id === trainingOutcome.id,
      );
      if (index !== -1) {
        this.failedOptionsTableRows[index] = trainingOutcome;
        this.failedOptionsTableRows = [...this.failedOptionsTableRows];
      }
    }
  }

  private filterOptionsBySuccess(isSuccess: boolean): TrainingOutcome[] {
    return this.assessmentOptions.filter(
      (option) => option.isSuccess === isSuccess,
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
