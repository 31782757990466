<div class="row">
  <div class="col-md-12" *ngIf="showListStatus">
    <div class="alert alert-info">
      <span
        [ngClass]="[
          'fas fa-xl',
          isLoading ? 'fa-spinner fa-spin' : 'fa-info-circle',
        ]"
      ></span>
      <ng-container>
        <span class="ms-2">{{ listStatusText }}</span>
      </ng-container>
    </div>
  </div>
</div>
