import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { Assessor } from '../../assessor/models/assessor';
import { ClaimLevel } from '../../claim/models/claim-level';
import { DotNetClaim } from '../../claim/services/claim.service';
import { Skill } from '../../skill/models/skill';

@Component({
  selector: 'esqep-level-and-verifier', // tslint:disable-line:component-selector
  templateUrl: './level-and-verifier.component.html',
  styleUrls: ['./level-and-verifier.component.scss'],
})
export class LevelAndVerifierComponent implements OnChanges, OnDestroy, OnInit {
  private fb = inject(FormBuilder);

  @Input() parentFormGroup: FormGroup;
  @Input() editMode = false;
  @Input() claimantView = true;
  @Input() claim: DotNetClaim;
  @Input() competency: Skill = new Skill();
  @Input() level: ClaimLevel = new ClaimLevel();
  @Input() verifier: Assessor = new Assessor();
  @Input() verifierChangeText = '';

  @Output() levelChange: EventEmitter<ClaimLevel> = new EventEmitter();
  @Output() verifierChange: EventEmitter<Assessor> = new EventEmitter();

  levelAndVerifierSubs = new Subscription();

  get levelAndVerifierFG(): FormGroup {
    return (this.parentFormGroup.get('levelAndVerifier') ||
      new FormGroup({})) as FormGroup;
  }

  get verifierChanged(): boolean {
    return (
      this.editMode &&
      this.claim.assessorId &&
      this.levelAndVerifierFG.get('verifier.selectedVerifier').dirty
    );
  }

  get showDiagnostics(): boolean {
    return true;
  }

  get levelControlStatus(): any {
    const lStatus = { disabled: false, reason: '' };
    if (this.editMode && this.claimantView && !this.claim.isPrivate) {
      lStatus.disabled = true;
      lStatus.reason = `Claimant cannot modify level of Assessment Request with status: 
        ${this.claim.statusText}`;
    }
    return lStatus;
  }

  get verifierControlStatus(): any {
    const vStatus = { disabled: false, reason: '' };
    if (this.editMode && this.claimantView && !this.claim.isPrivate) {
      vStatus.disabled = true;
      vStatus.reason = `Claimant cannot modify assessor of Assessment Request with status: ${this.claim.statusText} `;
    }
    return vStatus;
  }

  get selfVerifiedLevel(): boolean {
    return (
      this.levelAndVerifierFG.get('level.selfAssessed') || new FormControl()
    ).value
      ? true
      : false;
  }

  levelAndVerifierValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (
        !control.get('level.selfAssessed').value &&
        !control.get('verifier.assessorId').value
      ) {
        return { verifierRequiredForLevel: true };
      }
      return null;
    };
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.levelAndVerifierSubs.unsubscribe();
  }

  onLevelChange(level: ClaimLevel) {
    this.levelChange.emit(level);
  }

  onVerifierChange(verifier: Assessor) {
    this.verifierChange.emit(verifier);
  }
}
