import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  mapTo,
  merge,
} from 'rxjs';
import { BaseInputComponent } from '../base-input/base-input.component';

const ngbTypeahead = Symbol();

@Component({
  selector: 'ug-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent
  extends BaseInputComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  autocomplete: string;

  @Input()
  container: string;

  @Input()
  editable = false;

  @Input()
  focusFirst = false;

  @Input()
  inlineGroup = true;

  @Input()
  inputFormatter: (item: any) => string;

  @Input()
  resultTemplate: TemplateRef<ResultTemplateContext>;

  @Input()
  showHint: boolean;

  @Input()
  placement: PlacementArray = 'bottom-left bottom-right top-left top-right';

  @Input()
  resultFormatter: (item: any) => string;

  @Output()
  selectItem = new EventEmitter<NgbTypeaheadSelectItemEvent>();

  // tslint:disable-next-line:no-output-native
  @Output()
  blur = new EventEmitter<FocusEvent>();

  @ViewChild('instance', { static: true })
  instance: NgbTypeahead;

  @ViewChild('labelElement') labelElement: ElementRef;

  private value = null;
  private focusSubject = new Subject();
  private clickSubject = new Subject();

  constructor() {
    super();
    const ngControl = inject(NgControl);

    ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    if (!this.labelElement.nativeElement.innerHTML) {
      this.labelElement.nativeElement.hidden = true;
    }
  }

  get ngbTypeahead(): (text: Observable<string>) => Observable<any[]> {
    return this[ngbTypeahead];
  }

  @Input()
  set ngbTypeahead(value: (text: Observable<string>) => Observable<any[]>) {
    if ('function' === typeof value) {
      this[ngbTypeahead] = (text: Observable<string>) => {
        return value(
          merge(
            text,
            merge(this.focusSubject, this.clickSubject).pipe(
              filter(() => !this.instance.isPopupOpen()),
              mapTo(''),
            ),
          ),
        );
      };
    } else {
      this[ngbTypeahead] = value;
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.addSubscription(
      this.formControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value === '') {
            value = null;
          }

          if (value == null) {
            if (this.value == null) {
              return;
            }

            this.onSelectItem({ item: null, preventDefault: () => null });
          }
        }),
    );
  }

  onBlur($event: FocusEvent) {
    if (!this.editable && this.value !== this.formControl.value) {
      this.formControl.setValue(this.value);
    }

    this.onTouched();
    this.blur.next($event);
  }

  override writeValue(obj): void {
    this.value = obj;
    this.instance.dismissPopup();
    this.formControl.setValue(obj);
  }

  onSelectItem($event: NgbTypeaheadSelectItemEvent) {
    this.value = $event.item;
    this.onChange($event.item);
    this.selectItem.next($event);
  }

  onFocus($event: FocusEvent) {
    // @ts-ignore
    this.focusSubject.next();
  }

  onClick($event: MouseEvent) {
    // @ts-ignore
    this.clickSubject.next();
  }

  override ngOnDestroy(): void {}
}
