import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, HostListener, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, catchError, combineLatest, of } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { MatrixSettingsService } from './matrix-settings.service';
import { ICustomSkillWarning, IWarningUnits } from './skill-warning';

@Component({
  selector: 'ug-matrix-settings',
  templateUrl: './matrix-settings.component.html',
  styleUrl: './matrix-settings.component.scss',
})
export class MatrixSettingsComponent {
  private destroyRef = inject(DestroyRef);
  private matrixSettingsService = inject(MatrixSettingsService);
  private formBuilder = inject(FormBuilder);
  private uiService = inject(UitoolsService);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);

  protected defaultAmberWarningForm: UntypedFormGroup = this.formBuilder.group({
    value: [null],
    unit: [null],
  });

  protected settingUnits: IWarningUnits;
  protected skillsToUpdate: ICustomSkillWarning[] = [];
  protected auditCollapsed: boolean = true;
  protected trainingList: ICustomSkillWarning[] = [];
  protected competencyList: ICustomSkillWarning[] = [];
  protected canSave: boolean = false;
  protected allSkills: ICustomSkillWarning[];
  hasAdminAccess: boolean;

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.defaultAmberWarningForm.dirty || this.canSave) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.authService.loggedInUserSubj.subscribe((usd: LoggedInUser) => {
      this.hasAdminAccess = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
      ].some((sr) => usd.roleIdentifier === sr);

      if (!this.hasAdminAccess) {
        this.defaultAmberWarningForm.disable();
      }
    });

    this.defaultAmberWarningForm.valueChanges.subscribe((values) => {
      if (values.value && !values.unit) {
        this.defaultAmberWarningForm.get('unit').setErrors({ required: true });
      }

      if (values.unit && !values.value) {
        this.defaultAmberWarningForm.get('value').setErrors({ required: true });
      }

      if ((values.unit && values.value) || (!values.unit && !values.value)) {
        this.defaultAmberWarningForm.get('unit').setErrors(null);
        this.defaultAmberWarningForm.get('value').setErrors(null);
      }

      this.canSave = this.checkIfCanSave();
    });
    this.matrixSettingsService
      .getSkillWarnings()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (settings) => {
          this.allSkills = settings.skills;
          //TO DO: Filter out by dimension type
          this.trainingList = settings.skills.filter(
            (s) => s.dimensionId === 4,
          );
          this.competencyList = settings.skills.filter(
            (s) => s.dimensionId !== 4,
          );
          this.settingUnits = settings.units;
          this.defaultAmberWarningForm.setValue(
            {
              value: settings.defaultExpiringAmber.value,
              unit: settings.defaultExpiringAmber.unit,
            },
            { emitEvent: false },
          );
          this.defaultAmberWarningForm.markAsPristine();
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'MS-001',
              message: 'Error retrieving matrix settings',
            } as AlertData,
            err.error?.Request ?? `${err.status}`,
          );
        },
      });
  }

  saveSettings() {
    const skillWarningDefault = {
      value: this.defaultAmberWarningForm.value.value,
      unit: this.defaultAmberWarningForm.value.unit,
    };

    const payload = this.skillsToUpdate.map((skill) => {
      return {
        id: skill.id,
        expiringAmberValue: skill.expiringAmberValue,
        expiringAmberUnit: skill.expiringAmberUnit,
      };
    });

    const skillWarningDefaultSetting: Observable<void> =
      this.defaultAmberWarningForm.dirty &&
      !this.defaultAmberWarningForm.invalid
        ? this.matrixSettingsService
            .saveDefaultSkillWarning(skillWarningDefault)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                this.alertService.createAlert2(
                  {
                    level: AlertLevels.ERROR,
                    code: 'MS-002',
                    message: 'Error saving default amber warning settings',
                  } as AlertData,
                  err.error?.Request ?? `${err.status}`,
                );
                return of(undefined);
              }),
            )
        : of(undefined);

    const customSkillWarningSetting: Observable<ICustomSkillWarning[]> = this
      .skillsToUpdate.length
      ? this.matrixSettingsService.saveCustomSkillWarning(payload).pipe(
          catchError((err: HttpErrorResponse) => {
            this.alertService.createAlert2(
              {
                level: AlertLevels.ERROR,
                code: 'MS-003',
                message: 'Error saving custom amber warning settings',
              } as AlertData,
              err.error?.Request ?? `${err.status}`,
            );
            return of(undefined);
          }),
        )
      : of(undefined);

    combineLatest([
      skillWarningDefaultSetting,
      customSkillWarningSetting,
    ]).subscribe({
      next: ([defaultResponse, customResponse]) => {
        this.uiService.showToast(
          'Successfully updated amber warning settings',
          {
            classname: 'bg-success text-light',
            delay: 3000,
          },
        );

        this.skillsToUpdate = [];
        if (customResponse) {
          this.trainingList = customResponse.filter((s) => s.dimensionId === 4);
          this.competencyList = customResponse.filter(
            (s) => s.dimensionId !== 4,
          );
        }
        this.canSave = false;
        this.defaultAmberWarningForm.markAsPristine();
      },
    });
  }

  updateSkillsEvent(skill: ICustomSkillWarning | ICustomSkillWarning[]) {
    if (Array.isArray(skill)) {
      skill.forEach((s) => {
        this.skillsToUpdate.push(s);
      });
    } else {
      const skillIndex = this.skillsToUpdate.findIndex(
        (sk) => sk.id === skill.id,
      );

      skillIndex !== -1
        ? (this.skillsToUpdate[skillIndex] = skill)
        : this.skillsToUpdate.push(skill);
    }

    this.canSave = this.checkIfCanSave();
  }

  deleteSkillFromUpdateList(id: number) {
    this.skillsToUpdate = this.skillsToUpdate.filter(
      (skill) => skill.id !== id,
    );

    this.canSave = this.checkIfCanSave();
  }

  checkIfCanSave(): boolean {
    // Filter out skills that are missing required expiring amber information

    if (this.defaultAmberWarningForm.invalid) {
      return false;
    }

    const nonValidSkills = this.skillsToUpdate.filter(
      (skill) => !skill.expiringAmberValue || !skill.expiringAmberUnit,
    );

    // If all skills have valid expiring amber information, return true
    if (nonValidSkills.length === 0) {
      return true;
    }

    // Check if any non-valid skill does not exist in allSkills
    const missingSkills = nonValidSkills.some(
      (nonValidSkill) =>
        !this.allSkills.some((allSkill) => allSkill.id === nonValidSkill.id),
    );

    // If any missing skills are found, return false; otherwise, true
    return !missingSkills;
  }
}
