import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { OperatorFunction, Subject, Subscription } from 'rxjs';

import { FormBuilder, FormGroup } from '@angular/forms';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { Person } from '../../person/models/person';
import {
  PersonLookup,
  PersonService,
} from '../../person/services/person.service';
import {
  JobRole,
  JobRoleDotNet,
  JobRoleOrPersonOptions,
  JobRoleService,
} from '../services/job-role.service';

export interface JobRoleAnalysis {
  jobRoleId: number;
  jobRoleName: string;
  people: JobRoleAnalysisPerson[];
  isAdditional?: boolean;
}

export interface JobRoleAnalysisPerson {
  jobRoleLevelScore: number;
  personId: number;
  personName: string;
  skills: JobRoleAnalysisSkill[];
  unmetMandatoryCount: number;
  isAdditional?: boolean;
}

export interface JobRoleAnalysisSkill {
  skillId: number;
  skillName: string;
  subjects: { id: number; name: string }[];
  functionalAreaId: number;
  functionalAreaName: string;
  requirementMandatory: boolean;
  requirementLevelName: string;
  requirementLevelScore: number;
  requirementWeighting: number;
  claimId: number;
  claimLevelName: string;
  claimLevelScore: number;
  claimExpiryDate: string;
  personSkillWeightedScore?: number;
  personSkillWeightedScorePercentage?: number;
  roleSkillWeightedScore?: number;
  roleSkillWeightedScorePercentage?: number;
  maxLevelWeighted?: number;
}

@Component({
  selector: 'ug-job-role-analysis',
  templateUrl: './job-role-analysis.component.html',
  styleUrls: [],
})
export class JobRoleAnalysisComponent implements OnDestroy, OnInit {
  typeaheadService = inject(TypeaheadService);
  private alertService = inject(AlertService);
  private jobRoleService = inject(JobRoleService);
  private personService = inject(PersonService);
  private readonly formBuilder = inject(FormBuilder);

  exceptionData = {
    PEOPLE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRA-001',
      message: 'Error retrieving people list',
    } as AlertData,
    JOB_ROLE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRA-002',
      message: 'Error retrieving job role list',
    } as AlertData,
    JOB_ROLE_PEOPLE: {
      level: AlertLevels.ERROR,
      code: 'JRA-003',
      message: 'Error retrieving people for job role',
    } as AlertData,
    PERSON_JOB_ROLES: {
      level: AlertLevels.ERROR,
      code: 'JRA-004',
      message: 'Error retrieving job role for person',
    } as AlertData,
  };

  protected jobRoleList: Array<JobRoleDotNet>;
  protected jobRolePeople: JobRoleAnalysisPerson[] = [];
  protected selectedJobRoleName: string;
  protected personJobRoles: JobRoleAnalysis[] = [];

  jobRolePeopleCountMessageMap = {
    '=-1': 'Select a Job Role / Person to view',
    '=0': 'No data for this selection criteria',
    '=1': '1 record found',
    other: '# records found',
  };

  jobRoleOrPersonOptions = JobRoleOrPersonOptions;
  jobRoleOrPersonSelection = this.jobRoleOrPersonOptions.JOB_ROLE;

  peopleList: PersonLookup[] = [];

  jobRoleListSubs = new Subscription();
  peopleListSubs = new Subscription();
  private ngUnsubscribe: Subject<boolean> = new Subject();

  selectedJobRoleId: number;
  selectedPersonId: number;

  selectedOtherJobRoleId: number = null;
  selectedOtherPersonId: number = null;

  jobRoleQueryInProgress = false;

  formGroup: FormGroup;

  jobRoleSearch: OperatorFunction<string, JobRole[]>;
  peopleSearch: OperatorFunction<string, Person[]>;

  static sortJobRole(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  static compareJobRole(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  static sortPeople(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  static comparePeople(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  private getWeightedScore(skill: any) {
    const maxLevelWeighted =
      skill.claimLevelScore > skill.requirementLevelScore
        ? skill.claimLevelScore * skill.requirementWeighting
        : skill.requirementLevelScore * skill.requirementWeighting;

    const personWeightedScore =
      skill.claimLevelScore * skill.requirementWeighting;
    const personWeightedScorePercentage =
      (personWeightedScore / maxLevelWeighted) * 100;
    const roleWeightedScore =
      skill.requirementLevelScore * skill.requirementWeighting;
    const roleWeightedScorePercentage =
      (roleWeightedScore / maxLevelWeighted) * 100;

    return {
      personWeightedScore,
      personWeightedScorePercentage,
      roleWeightedScore,
      roleWeightedScorePercentage,
      maxLevelWeighted,
    };
  }

  jobRoleFormatter = (result) => result['name'];
  personFormatter = (result) => result['name'];

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      jobRole: [],
      person: [],
    });

    this.jobRoleListSubs = this.jobRoleService.getJobRolesDotNet().subscribe({
      next: (jrl) => {
        this.jobRoleList = jrl.filter((jr) => jr.active);
        this.jobRoleSearch = this.typeaheadService.typeahead(
          this.jobRoleList,
          JobRoleAnalysisComponent.compareJobRole,
          JobRoleAnalysisComponent.sortJobRole,
        );
      },
      error: (err) =>
        this.alertService.createAlert2(this.exceptionData.JOB_ROLE_LIST, err),
    });

    this.peopleListSubs = this.personService
      .getPersonLookup({
        isViewUserData: true,
      })
      .subscribe({
        next: (pl) => {
          this.peopleList = pl;
          this.peopleSearch = this.typeaheadService.typeahead(
            this.peopleList,
            JobRoleAnalysisComponent.comparePeople,
            JobRoleAnalysisComponent.sortPeople,
          );
        },
        error: (err) =>
          this.alertService.createAlert2(this.exceptionData.PEOPLE_LIST, err),
      });
  }

  ngOnDestroy() {
    this.jobRoleListSubs.unsubscribe();
    this.peopleListSubs.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  get viewButtonDisabled(): boolean {
    return (
      (this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.JOB_ROLE &&
        !this.formGroup.controls.jobRole?.value) ||
      (this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.PERSON &&
        !this.formGroup.controls.person?.value) ||
      this.jobRoleQueryInProgress
    );
  }

  get addButtonDisabled(): boolean {
    return (
      (this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.JOB_ROLE &&
        !this.selectedOtherPersonId) ||
      (this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.PERSON &&
        !this.selectedOtherJobRoleId) ||
      this.jobRoleQueryInProgress
    );
  }

  get selectedPersonName(): string {
    return (
      (this.peopleList.find((p) => Number(p.key) === this.selectedPersonId) ||
        {})['name'] || ''
    );
  }

  get otherJobRoleList(): Array<any> {
    const currentJobRoleIdList = this.personJobRoles.map((jrp) => {
      return jrp.jobRoleId;
    });
    return this.jobRoleList.filter((jr) => {
      return currentJobRoleIdList.indexOf(jr.id) === -1;
    });
  }

  get otherPersonList(): Array<any> {
    const currentPersonIdList = (this.jobRolePeople || []).map((jrp) => {
      return jrp.personId;
    });
    return this.peopleList.filter((p) => {
      return currentPersonIdList.indexOf(Number(p.key)) === -1;
    });
  }

  onRadioChange() {
    this.jobRolePeople = [];
    this.personJobRoles = [];
    this.selectedJobRoleId = null;
    this.selectedPersonId = null;
    this.selectedOtherJobRoleId = null;
    this.selectedOtherPersonId = null;
  }

  onViewClick() {
    this.jobRoleQueryInProgress = true;
    this.jobRolePeople = [];
    this.personJobRoles = [];
    if (
      this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.JOB_ROLE
    ) {
      this.selectedJobRoleId = this.formGroup.controls.jobRole.value.id;
      this.jobRoleService
        .getJobRoleAnalysisByJobRoleId(this.selectedJobRoleId)
        .subscribe({
          next: (jrp) => {
            this.selectedJobRoleName = jrp.length ? jrp[0].jobRoleName : '';
            this.jobRolePeople = jrp.length ? jrp[0].people : [];
            this.jobRolePeople.forEach((p) => {
              p.skills.forEach((s) => {
                this.calculateWeightedScores(s);
              });
            });
            this.jobRoleQueryInProgress = false;
          },
          error: (err) =>
            this.alertService.createAlert2(
              this.exceptionData.JOB_ROLE_PEOPLE,
              err,
            ),
        });
    } else if (
      this.jobRoleOrPersonSelection === this.jobRoleOrPersonOptions.PERSON
    ) {
      this.selectedPersonId = Number(this.formGroup.controls.person.value.key);
      this.jobRoleService
        .getJobRoleAnalysisByPersonId(this.selectedPersonId)
        .subscribe({
          next: (jrp) => {
            this.personJobRoles = jrp;
            this.personJobRoles.forEach((pjr) => {
              pjr['people'].forEach((p) => {
                p.skills.forEach((s) => {
                  this.calculateWeightedScores(s);
                });
              });
            });
            this.jobRoleQueryInProgress = false;
          },
          error: (err) =>
            this.alertService.createAlert2(
              this.exceptionData.PERSON_JOB_ROLES,
              err,
            ),
        });
    }
  }

  onAddJobRoleClick() {
    this.jobRoleService
      .getJobRoleAnalysisByJobRoleIdAndPersonId(
        this.selectedOtherJobRoleId,
        this.selectedPersonId,
      )
      .subscribe({
        next: (jrp) => {
          const personJobRoles = jrp;
          personJobRoles.forEach((pjr) => {
            pjr.isAdditional = true;
            pjr['people'].forEach((p) => {
              p.skills.forEach((s) => {
                this.calculateWeightedScores(s);
              });
            });
          });

          this.personJobRoles = [...this.personJobRoles, ...personJobRoles];
        },
        error: (err) =>
          this.alertService.createAlert2(
            this.exceptionData.JOB_ROLE_PEOPLE,
            err,
          ),
      });

    this.selectedOtherJobRoleId = null;
  }

  onAddPersonClick() {
    this.jobRoleService
      .getJobRoleAnalysisByJobRoleIdAndPersonId(
        this.selectedJobRoleId,
        this.selectedOtherPersonId,
      )
      .subscribe({
        next: (jrp) => {
          const jobRolePeople = jrp[0].people;
          jobRolePeople.forEach((p) => {
            p.isAdditional = true;
            p.skills.forEach((s) => {
              this.calculateWeightedScores(s);
            });
          });

          this.jobRolePeople = [...this.jobRolePeople, ...jrp[0].people];
        },
        error: (err) =>
          this.alertService.createAlert2(
            this.exceptionData.PERSON_JOB_ROLES,
            err,
          ),
      });

    this.selectedOtherPersonId = null;
  }

  onCompareRemove(event: any) {
    const element =
      event.type === JobRoleOrPersonOptions.JOB_ROLE
        ? this.jobRolePeople.findIndex((f) => f.personId === event.id)
        : this.personJobRoles.findIndex((f) => f.jobRoleId === event.id);

    if (event.type === JobRoleOrPersonOptions.JOB_ROLE) {
      this.jobRolePeople.splice(element, 1);
      this.jobRolePeople = [...this.jobRolePeople];
    } else if (event.type === JobRoleOrPersonOptions.PERSON) {
      this.personJobRoles.splice(element, 1);
      this.personJobRoles = [...this.personJobRoles];
    }
  }

  calculateWeightedScores(s: JobRoleAnalysisSkill) {
    const skillWeightedInfo = this.getWeightedScore(s);
    s.personSkillWeightedScore = skillWeightedInfo.personWeightedScore;
    s.personSkillWeightedScorePercentage =
      skillWeightedInfo.personWeightedScorePercentage;
    s.roleSkillWeightedScore = skillWeightedInfo.roleWeightedScore;
    s.roleSkillWeightedScorePercentage =
      skillWeightedInfo.roleWeightedScorePercentage;
    s.maxLevelWeighted = skillWeightedInfo.maxLevelWeighted;
  }
}
