import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntityValues } from '../../dynamic-groups/services/dynamic-groups.service';
import { AuditData, AuditFilter } from '../../shared/audit-table/audit.service';
import { Skill } from '../../skill/models/skill';
import { JobRoleAnalysis } from '../job-role-analysis/job-role-analysis.component';
import { JobRolePeopleAudit } from '../models/job-role-people-audit';
import { JobRoleTrainingAudit } from '../models/job-role-training-audit';

export enum JobRoleOrPersonOptions {
  JOB_ROLE,
  PERSON,
}
export enum ScoreTrafficLight {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
}

export enum PersonOrAllRoleOptions {
  PERSON_ROLES,
  ALL_ROLES,
}

export interface JobRoleRequirement {
  allowDescendents?: boolean;
  claimLevelId: number;
  claimLevelName?: string;
  disciplineId: number;
  disciplineName?: string;
  dueAfterUnit?: string;
  dueAfterValue?: number;
  dueAfterDays?: number;
  externalType?: string;
  externalGUID?: string;
  externalProvider?: string;
  externalOwner?: string;
  id?: number;
  jobRoleId: number;
  mandatory: boolean;
  active?: boolean;
  pendingDeletion?: boolean;
  pendingActivation?: boolean;
  recurUnit?: string;
  recurValue?: number;
  recurOffsetDays?: number;
  keepDueDates?: boolean;
  recurMonths?: string;
  skillId: number;
  skillName?: string;
  skillVersion?: string;
  weighting: number;
}

export interface JobRolePerson {
  id: number;
  name: string;
  externalActive: boolean;
  jobRoleId: number;
  personId: number;
  isFromOrgUnit: boolean;
  pendingDeletion: boolean;
  pendingActivation: boolean;
}

export interface JobRoleOrgUnit {
  id: number;
  jobRoleId: number;
  orgUnitId: number;
  externalOuId: string;
  externalType: string;
  externalActive: boolean;
  ouName: string;
  pendingDeletion?: boolean;
  pendingActivation?: boolean;
}

export interface JobRoleDetail {
  id: number;
  detail: string;
  jobRoleDetailTypeId: number;
  jobRoleDetailTypeName: string;
  jobRoleId: number;
}

export interface JobRoleDetailType {
  id: number;
  name: string;
}

export interface JobRole {
  description?: string;
  id?: number;
  identifierCode?: string;
  jobFamilyId: number;
  jobGradeId: number;
  name: string;
  organisationUnitId?: number;
  requirements: Array<JobRoleRequirement>;
  requirementsCount?: number;
  people: Array<JobRolePerson>;
  peopleCount?: number;
  active?: boolean;
  pendingDeletion?: boolean;
  pendingInactivation?: boolean;
  orgUnits: Array<JobRoleOrgUnit>;
}

export interface JobRoleList {
  jobRoles: Array<JobRole>;
  count: number;
}

export interface JobRolePayload {
  description?: string;
  id?: number;
  identifierCode?: string;
  jobFamilyId: number;
  jobGradeId: number;
  name: string;
  organisationUnitId?: number;
  active?: boolean;
  pendingInactivation?: boolean;
  requirements: {
    create: Array<JobRoleRequirement>;
    delete: Array<JobRoleRequirement>;
    update: Array<JobRoleRequirement>;
  };
  people: {
    create: Array<{ personId: number }>;
    delete: Array<{ id: number; personId: number }>;
    update: Array<{ id: number; personId: number }>;
  };
  orgUnits: {
    create: Array<{ orgUnitId: number }>;
    delete: Array<{ id: number; orgUnitId: number }>;
    update: Array<{ id: number; orgUnitId: number }>;
  };
}

export interface JobRoleDotNet {
  active: boolean;
  description: string;
  filterId: number;
  id: number;
  locationId: number;
  name: string;
  peopleCount: number;
  pendingDeletion: boolean;
  pendingInactivation: boolean;
  dueAfterValue: number;
  dueAfterUnit: string;
  newHireDueValue: number;
  newHireDueUnit: string;
  newVersionDueValue: number;
  newVersionDueUnit: string;
}

export interface JobGrade {
  description?: string;
  id: number;
  name: string;
  jobGradeSetId: number;
}

export interface JobFamily {
  description?: string;
  id: number;
  jobGradeSetId: number;
  name: string;
  parentJobFamilyId?: number;
  parentJobFamilyDescription?: string;
  parentJobFamilyName: string;
}

export interface Level {
  name: string;
  score: number;
  weightedScore?: number;
  weightedScorePercentage?: number;
}

export interface JobRolePerson {
  id: number;
  personId: number;
  name: string; // roleName
  personName: string;
  personRoleScore: number;
  unmetMandatoryCount: number;
  unassigned?: boolean;
  summary: [
    {
      disciplineId: number;
      disciplineName: string;
      mandatory: boolean;
      skillId: number;
      skillName: string;
      weighting: number;
      personLevel: Level;
      roleLevel: Level;
      maxLevelWeighted?: number;
    },
  ];
}

export interface JobRoleSettingsForm {
  [key: string]: FormGroup<JobRoleSettingForm>;
}

export interface JobRoleSettingForm {
  isActive: FormControl<boolean | null>;
  value: FormControl<number | null>;
  unit: FormControl<string | null>;
  field: FormControl<string | null>;
  filterId: FormControl<number | null>;
}

export interface JobRoleSettings {
  [settingName: string]: JobRoleSetting;
}

export interface JobRoleSetting {
  field: string;
  filterId: number;
  id?: number;
  isActive: boolean;
  typeKey?: string;
  typeName?: string;
  unit: string;
  value: number;
}

export interface JobRoleSettingsPost {
  [settingName: string]: Pick<
    JobRoleSetting,
    'field' | 'filterId' | 'isActive' | 'unit' | 'value'
  >;
}

export interface JobRoleDueDateAudit {
  date: string;
  personId: number;
  personDisplayName: string;
  fromValue: string;
  toValue: string;
  action: string;
  typeName: string;
  propertyName: string;
}

export interface PersonJobRoleLookup {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

const apiServerUri = environment.apiUrl;
const dotNetServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class JobRoleService {
  private http = inject(HttpClient);

  settingUnitsMap = new Map<string, string>([
    ['day', 'days'],
    ['week', 'weeks'],
    ['month', 'months'],
    ['years', 'years'],
  ]);

  updateTrainingAuditHistory: Subject<boolean> = new Subject();
  updatePeopleAuditHistory: Subject<boolean> = new Subject();

  jobRoleProfileDropdownBinding = new Map<
    string,
    { label: string; value: string }
  >([
    ['personRole', { label: 'name', value: 'key' }],
    ['allRoles', { label: 'name', value: 'id' }],
  ]);

  getJobRoles(): Observable<Array<JobRole>> {
    return this.http.get(`${apiServerUri}/job-roles`).pipe(
      map((resp) => {
        return resp['jobRoles'] || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getJobRole(jobRoleId: number): Observable<JobRole> {
    return this.http.get(`${apiServerUri}/job-roles/${jobRoleId}`).pipe(
      map((resp) => {
        return resp['jobRole'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  createJobRole(jobRoleData: any): Observable<JobRole> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${apiServerUri}/job-roles`, JSON.stringify(jobRoleData), {
        headers: headers,
      })
      .pipe(
        map((resp) => {
          return resp['jobRole'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updateJobRole(jobRoleId: number, jobRoleData: any): Observable<JobRole> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .put(
        `${apiServerUri}/job-roles/${jobRoleId}`,
        JSON.stringify(jobRoleData),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return resp['jobRole'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  listJobRolePeopleAudit(
    jobRoleId: number,
  ): Observable<Array<JobRolePeopleAudit>> {
    return this.http
      .get(`${apiServerUri}/job-roles/${jobRoleId}/people/audit`)
      .pipe(
        map((resp) => {
          return resp['auditJobRolePeople'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  listJobRoleTrainingAudit(
    jobRoleId: number,
  ): Observable<Array<JobRoleTrainingAudit>> {
    return this.http
      .get(`${apiServerUri}/job-roles/${jobRoleId}/requirements/audit`)
      .pipe(
        map((resp) => {
          return resp['auditJobRoleRequirements'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  listJobRoleEventsWithoutSessions(
    jobRoleId: number,
  ): Observable<Array<Skill>> {
    return this.http
      .get(
        `${apiServerUri}/job-roles/${jobRoleId}/requirements/missing-event-sessions`,
      )
      .pipe(
        map((resp) => resp['missingEventSessions'] || []),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getJobRoleReqsCsv(): Observable<Blob> {
    return this.http
      .get(`${apiServerUri}/job-roles/reports/requirements`, {
        responseType: 'blob',
      })
      .pipe(
        map((resp) => {
          return new Blob([resp], { type: resp.type });
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getJobRolePeopleCsv(): Observable<Blob> {
    return this.http
      .get(`${apiServerUri}/job-roles/reports/people`, { responseType: 'blob' })
      .pipe(
        map((resp) => {
          return new Blob([resp], { type: resp.type });
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  updateJobRoleDotNet(
    jobRoleId: number,
    jobRoleData: any,
  ): Observable<JobRoleDotNet> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/jobRoles/${jobRoleId}`,
        JSON.stringify(jobRoleData),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  createJobRoleDotNet(jobRoleData: any): Observable<JobRoleDotNet> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(`${dotNetServerUri}/jobRoles`, JSON.stringify(jobRoleData), {
        headers: headers,
      })
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getJobRoleDotNet(jobRoleId: number): Observable<JobRoleDotNet> {
    return this.http.get(`${dotNetServerUri}/jobRoles/${jobRoleId}`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getJobRoleRequirements(jobRoleId: number): Observable<any> {
    return this.http
      .get(`${dotNetServerUri}/jobRoleRequirements/byJobRoleId/${jobRoleId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  deleteJobRoleRequirementsByJobRoleId(jobRoleId: number): Observable<any> {
    return this.http
      .delete(`${dotNetServerUri}/jobRoleRequirements/byJobRoleId/${jobRoleId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  deleteJobRoleRequirementById(requirementId: number): Observable<any> {
    return this.http
      .delete(`${dotNetServerUri}/jobRoleRequirements/${requirementId}`)
      .pipe(
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  getJobRoleOrgUnits(jobRoleId: number): Observable<any> {
    return this.http
      .get(`${dotNetServerUri}/jobRoleOrgUnits/byJobRoleId/${jobRoleId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getJobRolePeopleDotNet(jobRoleId: number): Observable<JobRolePerson> {
    return this.http.get<JobRolePerson>(
      `${dotNetServerUri}/jobRolePeople/byJobRoleId/${jobRoleId}`,
    );
  }

  getJobRolePeoplePagedDotNet(
    jobRoleId: number,
    data,
  ): Observable<EntityValues> {
    return this.http
      .post(
        `${dotNetServerUri}/jobRolePeople/byJobRoleId/${jobRoleId}/paged`,
        data,
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  getJobRolesDotNet(): Observable<Array<JobRoleDotNet>> {
    return this.http.get(`${dotNetServerUri}/jobRoles`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  deleteJobRole(jobRoleId: number): Observable<JobRoleDotNet> {
    return this.http.delete(`${dotNetServerUri}/jobRoles/${jobRoleId}`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  updateJobRolesOrgUnitsDotNet(
    id: number,
    orgUnitIds: Array<number>,
  ): Observable<any> {
    return this.http
      .post(`${dotNetServerUri}/jobRoleOrgUnits/byJobRoleId/${id}`, orgUnitIds)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  updateJobRolesPeopleDotNet(
    id: number,
    peopleIds: Array<number>,
  ): Observable<any> {
    return this.http
      .post(`${dotNetServerUri}/jobRolePeople/byJobRoleId/${id}`, peopleIds)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }
  addJobRoleRequirement(jobRoleRequirement: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/jobRoleRequirements`,
        JSON.stringify(jobRoleRequirement),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  updateJobRoleRequirementById(jobRoleRequirement: any, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/jobRoleRequirements/${id}`,
        JSON.stringify(jobRoleRequirement),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  getJobRoleDetails(jobRoleId: number): Observable<Array<JobRoleDetail>> {
    return this.http
      .get(`${dotNetServerUri}/jobRoleDetails/byJobRoleId/${jobRoleId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getJobRoleDetailTypes(): Observable<Array<JobRoleDetailType>> {
    return this.http.get(`${dotNetServerUri}/jobRoleDetailTypes`).pipe(
      map((resp) => {
        return (resp as any) || [];
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  updateJobRoleDetails(
    jobRoleId: number,
    jobRoleDetails: Array<any>,
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${dotNetServerUri}/jobRoleDetails/byJobRoleId/${jobRoleId}`,
        JSON.stringify(jobRoleDetails),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          const errorMessage =
            err.error?.request ??
            err.error?.Request ??
            `${err.status} ${err.statusText}`;
          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  getJobRoleSettingsDueDates(): Observable<JobRoleSettings> {
    return this.http.get<JobRoleSettings>(`${dotNetServerUri}/jobRoleDueDates`);
  }

  updateJobRoleSettingsDueDates(
    settings: JobRoleSettingsPost,
  ): Observable<JobRoleSettings> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<JobRoleSettings>(
      `${dotNetServerUri}/jobRoleDueDates`,
      JSON.stringify(settings),
      { headers: headers },
    );
  }

  getJobRoleSettingsAuditPaged = (data: AuditFilter): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/jobRoleDueDates/audit/paged`,
      data,
    );
  };

  getJobRolesByPersonId(personId: number): Observable<PersonJobRoleLookup[]> {
    return this.http.get<PersonJobRoleLookup[]>(
      `${dotNetServerUri}/jobRoles/lookups/byPersonId/${personId}`,
    );
  }

  getJobRoleAnalysisByJobRoleId(
    jobRoleId: number,
  ): Observable<JobRoleAnalysis[]> {
    return this.http.get<JobRoleAnalysis[]>(
      `${dotNetServerUri}/jobRoleComparisons/byJobRoleId/${jobRoleId}`,
    );
  }

  getJobRoleAnalysisByPersonId(
    personId: number,
  ): Observable<JobRoleAnalysis[]> {
    return this.http.get<JobRoleAnalysis[]>(
      `${dotNetServerUri}/jobRoleComparisons/byPersonId/${personId}`,
    );
  }

  getJobRoleAnalysisByJobRoleIdAndPersonId(
    jobRoleId: number,
    personId: number,
  ): Observable<JobRoleAnalysis[]> {
    return this.http.get<JobRoleAnalysis[]>(
      `${dotNetServerUri}/jobRoleComparisons/byJobRoleIdAndPersonId/${jobRoleId}/${personId}`,
    );
  }

  getCurriculumChildrenBySkillId(skillId: number): Observable<any> {
    return this.http.get(
      `${dotNetServerUri}/skills/curriculumChildrenBySkillId/${skillId}`,
    );
  }

  getAIGeneratedKeyDetails(
    data: {
      jobTitle: string;
      details: string;
      wordCount: number;
    },
    key: string,
  ): Observable<string> {
    return this.http.post<string>(
      `${dotNetServerUri}/jobRoles/ai/${key}`,
      data,
    );
  }

  getJobRoleAudit = (
    pagedData: AuditFilter,
    id: number,
  ): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/jobRoles/${id}/audit/paged`,
      pagedData,
    );
  };

  getJobRolePeopleAudit = (
    pagedData: AuditFilter,
    id: number,
  ): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/jobRolePeople/byJobRoleId/${id}/audit/paged`,
      pagedData,
    );
  };

  getJobRoleRequirementsAudit = (
    pagedData: AuditFilter,
    id: number,
  ): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/jobRoleRequirements/byJobRoleId/${id}/audit/paged`,
      pagedData,
    );
  };

  getJobRoleOrgUnitsAudit = (
    pagedData: AuditFilter,
    id: number,
  ): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/jobRoleOrgUnits/byJobRoleId/${id}/audit/paged`,
      pagedData,
    );
  };
}
