<div class="modal-header pb-1">
  <div class="w-100" *ngIf="currentSession">
    <h4 id="modal-basic-title">
      {{ currentSession.title }}
      <small class="text-muted d-block">
        <ug-date-time-session-display
          [startDate]="currentSession.startDate"
          [endDate]="currentSession.endDate"
        ></ug-date-time-session-display>
      </small>
    </h4>
    <div class="classId">
      <span class="fas fa-school me-1"></span>
      Classroom Session ID:
      <span>
        {{ currentSession.externalClassroomId }}
      </span>
    </div>
    <div class="instructors">
      <span class="fas fa-chalkboard-teacher me-1"></span>
      Trainers:
      <ng-container *ngIf="instructors.length > 0; else noAssessors">
        <span>{{ instructors }}</span>
      </ng-container>
      <ng-template #noAssessors>
        <span>n/a</span>
      </ng-template>
    </div>
    <div>
      <span class="fas fa-map-marked me-1"></span>
      Location: {{ currentSession.location }}
    </div>
  </div>
  <button
    type="button"
    class="btn-close m-0 text-center align-self-start"
    aria-label="Close"
    (click)="modal.dismiss()"
  ></button>
</div>

<div class="modal-body">
  <ng-container *ngIf="userHasAdminAccess">
    <ng-container *ngIf="!isSessionCompleted; else sessionCompleted">
      <div class="my-1">
        <h5>Roster</h5>
        @for (error of removedAttendeesErrors; track error.id) {
          <p class="text-danger">
            Error removing {{ error.personName }} from the roster :
            {{ error.message }}
          </p>
        }
        <div>
          <ug-table
            [isLoading]="attendingIsLoading"
            [tableHeaders]="attendingHeaders"
            [tableRows]="attending"
            [tableSelectedButtons]="attendingTableButtons"
            [showSelectBox]="!hasSessionEnded"
          >
          </ug-table>
          <div class="mt-3 d-flex">
            <button class="btn esqepBtn" (click)="openAddAttendeeModal()">
              Add attendees
            </button>
            <button
              type="submit"
              class="btn btn-outline-success ms-auto"
              (click)="saveChanges()"
              [disabled]="saveDisabled || spin"
            >
              <i class="fa fa-spinner fa-spin" *ngIf="spin"> </i> Save Changes
            </button>
          </div>
        </div>
        <hr class="solid" *ngIf="!hasSessionEnded" />
        <div class="my-3" *ngIf="!hasSessionEnded">
          <h5>Recommendations</h5>
          <div>
            <ug-table
              [isLoading]="recommendedIsLoading"
              [tableHeaders]="recommendedHeaders"
              [tableSelectedButtons]="recommendedSelectedButtons"
              [tableFilters]="filters"
              [tableRows]="recommended"
              [showSelectBox]="true"
            >
            </ug-table>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #sessionCompleted>
      <ug-bulk-assessment-completed
        [trainingSessionId]="currentSession.id"
      ></ug-bulk-assessment-completed>
    </ng-template>
  </ng-container>
</div>
