<div class="card my-3">
  <div class="card-body">
    <div class="d-flex justify-content-between mb-2">
      <h5 class="card-title">{{ auditTitle }} Audit history</h5>
      <div>
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary ms-1"
          (click)="filtersCollapsed = !filtersCollapsed"
          [attr.aria-expanded]="!filtersCollapsed"
          aria-controls="collapseFilters"
        >
          {{ filtersCollapsed ? 'Show Filters' : 'Hide Filters' }}
        </button>
      </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="filtersCollapsed">
      <ug-audit-filters
        (filtersChangedEvent)="onFiltersChanged($event)"
        [contextKey]="contextKey"
        [contextTitle]="contextTitle"
      ></ug-audit-filters>
    </div>

    <div class="my-2">
      <ug-table
        [tableHeaders]="tableHeaders"
        [tableRows]="tableRows"
        [tablePageSize]="tablePageSize"
        [tablePageIndex]="tablePageIndex"
        [showPagination]="true"
        [isLoading]="loadingData"
        [totalRecords]="auditRecordCount"
        [pagedApi]="true"
        (pageIndexChange)="updateData($event)"
        (pageSizeChange)="updateData(1, $event)"
        (sortChange)="updateData(1, undefined, $event)"
        (filtersCleared)="clearFilters($event)"
      >
      </ug-table>
    </div>
  </div>
</div>
