import { AuthenticationConfigNames } from '../app/core/authenticationConfigNames';

export const environment = {
  production: false,
  envName: 'mfrs-pilot',
  shareUrl: 'https://sqeptech-mfrs-esqep-api-pilot.azurewebsites.net/shared',
  apiUrl:
    'https://sqeptech-mfrs-esqep-api-pilot.azurewebsites.net/esqep/api/v1.0',
  serverUrl:
    'https://sqeptech-mfrs-esqep-api-dotnet-pilot.azurewebsites.net/api',
  clientUrl: 'https://sqeptech-mfrs-esqep-pilot.azurewebsites.net',
  auth: [
    {
      authConfigName: AuthenticationConfigNames.CSOD,
      enabled: false,
      buttonTitle: 'Login with Cornerstone',
      logoutUrl: '',
      spConfigName: '',
    },
    {
      authConfigName: AuthenticationConfigNames.OKTA,
      enabled: false,
      buttonTitle: 'Login with Okta',
      clientId: '0oa5oupk22Ln3TUa75d7',
      openIdIssuer: 'https://dev-71733550.okta.com/oauth2/default',
      sqeptechClientId: '',
      sqeptechOpenIdIssuer: '',
      allowedOrigins: [],
    },
    {
      authConfigName: AuthenticationConfigNames.ENTRA,
      enabled: true,
      buttonTitle: 'Login',
      clientId: '8f362cce-a25b-47dc-9a18-b3ff0bb5fe34', // multi tenant app id
      allowedAzureTenants: [
        'd320fce4-2119-4307-8597-5b2363fcc067',
        '825c9c3f-a0e8-4f43-afb2-d8e0cf1fd645',
      ],
    },
  ],
  loggingLevel: 3,
  skillMatrixHeadings: ['PM', 'TL', 'Staff'],
  // skillMatrixFaId: 23,
  skillMatrixFaId: 983,
  clientLogoAsset: 'mfrs-learn.png',
  navbarBgClass: 'bg-mfrs',
  userGroups: [
    {
      id: '103eead7-1830-457b-8ba3-1a1e9ef53072',
      name: 'eSQEP-users',
      isAdmin: false,
    },
    {
      id: 'c645c0db-2c14-4421-850a-8f4699f9f9cf',
      name: 'eSQEP-admins',
      isAdmin: true,
    },
  ],
  loginPageMessage: 'Welcome to your eSQEP Portal',
  roleManagementTabs: ['details', 'competencies', 'training', 'assignment'],
  homeCards: [
    'myActivitySummary',
    'myClaimActions',
    'myJobRoleProfile',
    'myAssignment',
    'myTrainingCompletion',
  ],
  clientAccount: {
    menu: 'pilot',
  },
};
