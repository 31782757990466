import { Location } from '@angular/common';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  OKTA_AUTH,
  OktaAuthConfigService,
  OktaAuthGuard,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, from, of } from 'rxjs';
import { AuthenticationConfigNames } from '../core/authenticationConfigNames';
import { AuthService } from '../core/services/auth.service';
import { ConfigService } from '../core/services/config.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class PreAuthGuard {
  authenticationConfigNames: typeof AuthenticationConfigNames =
    AuthenticationConfigNames;

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,

    @Optional()
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    @Optional()
    @Inject(MsalBroadcastService)
    private msalBroadcastService: MsalBroadcastService,
    @Optional() @Inject(MsalService) private msalAuthService: MsalService,
    @Optional() private location: Location,
    @Optional()
    @Inject(OktaAuthConfigService)
    private oktaConfig: OktaAuthConfigService,
    @Optional() @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    @Optional() private injector: Injector,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    if (
      this.configService.authSetup[this.authenticationConfigNames.CSOD] &&
      route.queryParams.token &&
      !route.queryParams.error
    ) {
      if (
        localStorage.getItem('esqep.authType') !==
        this.authenticationConfigNames.CSOD
      ) {
        localStorage.setItem(
          'esqep.authType',
          this.authenticationConfigNames.CSOD,
        );
      }
    }
    if (
      this.configService.authSetup[this.authenticationConfigNames.CSOD] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.CSOD
    ) {
      return new AuthGuard(this.authService, this.router).canActivate(
        route,
        state,
      );
    }

    if (
      this.configService.authSetup[this.authenticationConfigNames.OKTA] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.OKTA
    ) {
      // constructor(oktaAuth: OktaAuth, injector: Injector, configService: OktaAuthConfigService);

      return from(
        new OktaAuthGuard(
          this.oktaAuth,
          this.injector,
          this.oktaConfig,
        ).canActivate(route, state),
      );
    }

    if (
      this.configService.authSetup[this.authenticationConfigNames.ENTRA] &&
      localStorage.getItem('esqep.authType') ===
        this.authenticationConfigNames.ENTRA
    ) {
      return new MsalGuard(
        this.msalGuardConfig,
        this.msalBroadcastService,
        this.msalAuthService,
        this.location,
        this.router,
      ).canActivate(route, state);
    }

    if (!localStorage.getItem('esqep.authType')) {
      this.router.navigate(['/login']);
      return of(false);
    }

    return of(true);
  }
}
