import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { OperatorFunction, Subject, takeUntil } from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { TypeaheadFilter } from '../../controls/dynamic-form/control-types/filter-typeahead';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableHeaderButton,
  TableRowButton,
} from '../../controls/table/table.service';
import {
  JobRole,
  JobRoleService,
} from '../../job-role/services/job-role.service';
import { Skill } from '../../skill/models/skill';
import { SkillService } from '../../skill/services/skill.service';

@Component({
  selector: 'ug-competency-management',
  templateUrl: './competency-management.component.html',
  styleUrls: [],
})
export class CompetencyManagementComponent implements OnInit, OnDestroy {
  private skillService = inject(SkillService);
  private typeaheadService = inject(TypeaheadService);
  private jobRoleService = inject(JobRoleService);

  tableLoading = false;

  competencyBehaviourSearch: OperatorFunction<string, string[]>;

  competencyAreaSearch: OperatorFunction<string, string[]>;
  competencies: Array<Skill> = [];
  jobRoles: Array<JobRole> = [];
  subCategoryList: Array<string> = [];
  jobRoleList: Array<string> = [];

  private ngUnsubscribe: Subject<boolean> = new Subject();

  competencyCatSearch: OperatorFunction<string, string[]>;
  competencySubCatSearch: OperatorFunction<string, string[]>;

  jobRoleSearch: OperatorFunction<string, string[]>;
  headers: Array<TableHeader> = [
    { id: 'id', title: 'Id' },
    { id: 'funcAreaName', title: 'SubCategory', class: 'w-20' },
    { id: 'name', title: 'Competency', class: 'w-25' },
    { id: 'assessorCount', title: 'Assessors' },
    { id: 'jobRoleCount', title: 'Job Roles' },
  ];
  headerButtons: Array<TableHeaderButton>;
  rowButtons: Array<TableRowButton>;
  filters: Array<FilterBase<any>>;
  static sort(a, b, text: string) {
    return a.startsWith(text) - b.startsWith(text) || b - a;
  }

  static compare(items, input: string) {
    return items.toLowerCase().includes(input);
  }

  formatter = (result) => result;

  ngOnInit(): void {
    this.tableLoading = true;
    this.jobRoleService
      .getJobRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((jrl) => {
        this.jobRoles = jrl;
        const allJobRoles = this.jobRoles.map((c) => c.name);
        this.jobRoleList = [...new Set(allJobRoles)];
        this.jobRoleSearch = this.typeaheadService.typeahead(
          this.jobRoles,
          CompetencyManagementComponent.compare,
          CompetencyManagementComponent.sort,
        );
        this.skillService
          .getSkills(38, 0)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((x) => {
            this.competencies = x;
            this.competencies.forEach((c) => {
              c['assessorCount'] = Math.floor(Math.random() * 20);
              c['jobRoles'] = [];
              this.jobRoles.forEach((jr) => {
                if (jr.requirements.some((req) => req.skillId === c.id)) {
                  c['jobRoles'].push(jr);
                }
              });
              c['jobRoleCount'] = c['jobRoles'].length;
            });
            const allSubCategories = this.competencies.map(
              (c) => c.funcAreaName,
            );
            this.subCategoryList = [...new Set(allSubCategories)];
            this.competencySubCatSearch = this.typeaheadService.typeahead(
              this.subCategoryList,
              CompetencyManagementComponent.compare,
              CompetencyManagementComponent.sort,
            );

            this.tableLoading = false;
          });
      });
    this.headerButtons = [
      {
        title: 'Create Competency',
        headerAction: this.createCompetency,
        type: 'button',
      },
    ];
    this.filters = [
      new TextboxFilter({
        key: 'searchTerm',
        label: 'Filter by term',
        order: 1,
        placeholder: 'Filter by term',
      }),
      new TypeaheadFilter({
        key: 'behaviour',
        label: 'Filter by behaviour',
        typeAheadSearch: this.competencyBehaviourSearch,
        resultFormatter: this.formatter,
        inputFormatter: this.formatter,
        inLineGroup: false,
        order: 2,
      }),
      new TypeaheadFilter({
        key: 'area',
        label: 'Filter by area',
        typeAheadSearch: this.competencyAreaSearch,
        resultFormatter: this.formatter,
        inputFormatter: this.formatter,
        inLineGroup: false,
        order: 3,
      }),
      new TypeaheadFilter({
        key: 'category',
        label: 'Filter by category',
        typeAheadSearch: this.competencyCatSearch,
        resultFormatter: this.formatter,
        inputFormatter: this.formatter,
        inLineGroup: false,
        order: 4,
      }),
      new TypeaheadFilter({
        key: 'funcAreaName',
        label: 'Filter by subcategory',
        typeAheadSearch: this.competencySubCatSearch,
        resultFormatter: this.formatter,
        inputFormatter: this.formatter,
        inLineGroup: false,
        order: 5,
      }),
    ];

    this.rowButtons = [
      { title: 'Edit', rowAction: this.edit },
      { title: 'Delete', class: 'btn-outline-danger', rowAction: this.delete },
    ];
  }

  edit = (id) => {
    console.log('edit : ', id);
  };

  delete = (id) => {
    console.log('delete : ', id);
  };
  createCompetency = () => {
    console.log('create competency');
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
