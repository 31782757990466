import { Injectable } from '@angular/core';
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  map,
  merge,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadService {
  notFound = new Subject();

  typeahead(
    items,
    field: string | ((item: any, input: string) => boolean),
    sort?: string | ((a: any, b: any, input: string) => number),
    notFoundObject?: { searchProperty: string; displayText: string },
    slice = 10,
  ): OperatorFunction<string, any[]> {
    // @ts-ignore
    return (input: Observable<string>) => {
      const debouncedText$ = input.pipe(
        debounceTime(500),
        map((text) => text.toLocaleLowerCase()),
      );
      return merge(debouncedText$).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        map((text) => {
          // @ts-ignore
          let result = items.filter((item) => {
            if ('function' === typeof field) {
              return field(item, text);
            } else {
              return item.toLocaleLowerCase().includes(text);
            }
          });

          if ('function' === typeof sort) {
            items = items.sort((a, b) => sort(a, b, text));
          }

          if ('string' === typeof sort) {
            items = items.sort((a, b) => {
              return a.localeCompare(b) || b - a;
            });
          }
          if ('number' === typeof slice) {
            // @ts-ignore
            result = result.slice(0, slice);
          }

          if (result.length === 0 && notFoundObject) {
            const notfound = {
              [notFoundObject.searchProperty]: notFoundObject.displayText,
              value: text,
              notFound: true,
            };

            result.push(notfound);
          }

          return result;
        }),
      );
    };
  }
}
