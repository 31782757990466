import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';

import { PublishedClaimsComponent } from '../claim/published-claims/published-claims.component';

import { MetaClaimGuard } from '../claim/guards/meta-claim.guard';
import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { ClaimDetailGuard } from './guards/claim-detail.guard';

import { PreAuthGuard } from '../guards/pre-auth.guard';
import { ClaimListComponent } from './claim-list/claim-list.component';

const routes: Routes = [
  {
    path: 'assessment-request/:claimantId/assessed',
    component: PublishedClaimsComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  },
  {
    path: 'claim/:claimantId/published',
    redirectTo: 'assessment-request/:claimantId/assessed',
    pathMatch: 'full',
  },
  {
    path: 'mydata/assessment-requests/assessed',
    component: PublishedClaimsComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    data: {
      routeIdentifier: 'MY-DATA_PUBLISHED-CLAIMS',
      title: 'Assessed Competencies',
      helpUrl:
        'https://scribehow.com/shared/Competency_Assessment_Requests__YHGYUhNgRNuOjQHL4S0kJw',
    },
  },
  {
    path: 'mydata/claims/published',
    redirectTo: 'mydata/assessment-requests/assessed',
    pathMatch: 'full',
  },
  {
    path: 'submissions/PUBLISHED',
    redirectTo: 'submissions/MY-ASSESSED',
    pathMatch: 'full',
  },
  {
    path: 'submissions/VERIFY',
    redirectTo: 'submissions/ASSESS',
    pathMatch: 'full',
  },
  {
    path: 'submissions/VERIFIED',
    redirectTo: 'submissions/ASSESSED',
    pathMatch: 'full',
  },
  {
    path: 'submissions/:category',
    component: ClaimListComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: { title: 'Submissions' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ClaimDetailGuard, MetaClaimGuard],
})
export class ClaimRoutingModule {}
