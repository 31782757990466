import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { UitoolsService } from '../../../../core/services/uitools.service';
import {
  DynamicGroupsService,
  Filter,
} from '../../../../dynamic-groups/services/dynamic-groups.service';
import { BasicOrgUnit } from '../../../../org-unit/models/org-unit';
import { BasicJobRolePerson } from '../../../../person/models/person';
import {
  AuditData,
  AuditFilter,
} from '../../../../shared/audit-table/audit.service';
import { GroupFiltersModalComponent } from '../../../../shared/group-filters-modal/group-filters-modal.component';
import { JobRoleService } from '../../../services/job-role.service';
import { OrgUnitsModalComponent } from './org-units-modal/org-units-modal.component';
import { PeopleModalComponent } from './people-modal/people-modal.component';
import { PeopleUploadComponent } from './people-upload/people-upload.component';

@Component({
  selector: 'ug-role-assignment',
  templateUrl: './role-assignment.component.html',
  styleUrls: ['./role-assignment.component.scss'],
})
export class RoleAssignmentComponent implements OnInit {
  fb = inject(FormBuilder);
  private ngbModal = inject(NgbModal);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private uiService = inject(UitoolsService);
  private jobRoleService = inject(JobRoleService);

  @Input() parentFormGroup: FormGroup;
  @Input() allowEdit!: boolean;
  @Input() jobRoleId!: number;
  @Input() events: Observable<void>;
  @Output() changeOrgUnitsRoles: EventEmitter<Array<number>> = new EventEmitter<
    Array<number>
  >();
  @Output() changePeopleRoles: EventEmitter<Array<number>> = new EventEmitter<
    Array<number>
  >();

  public isCollapsed = true;
  public selectedFilter: Filter;
  selectedOrgUnits: Array<BasicOrgUnit> = [];
  jobRoleOrgUnits: Array<BasicOrgUnit> = [];
  currentJobRoleOrgUnits: Array<BasicOrgUnit> = [];
  jobRolePeople: Array<BasicJobRolePerson> = [];
  currentJobRolePeople: Array<BasicJobRolePerson> = [];
  selectedPeople: Array<BasicJobRolePerson> = [];
  filterPeople: Array<BasicJobRolePerson> = [];

  totalJobRolePeople: number;
  jobRoleFilter: Filter;
  tableFilterData: {
    filter: string;
    page: number;
    pageSize: number;
    order: string;
  } = {
    filter: '',
    page: 1,
    pageSize: 10,
    order: '',
  };
  @Input() discardChangesSubj: Subject<boolean>;
  @Input() saveChangesSubj: Subject<boolean>;
  @Input() assignmentAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;
  @Input() orgUnitAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;
  tableLoading: boolean;
  disableUploadButton: boolean = false;

  get jobRoleFormId(): number {
    return this.parentFormGroup.get('id').value;
  }

  get filterId(): number {
    return this.parentFormGroup.get('filterId').value;
  }

  ngOnInit(): void {
    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.currentJobRolePeople = this.jobRolePeople;
        this.changePeopleRoles.emit(
          this.currentJobRolePeople
            .filter((p) => !p.pendingDeletion)
            .map((m) => m.id),
        );
        this.currentJobRoleOrgUnits = this.jobRoleOrgUnits;
        this.changeOrgUnitsRoles.emit(
          this.currentJobRoleOrgUnits.map((m) => m.id),
        );
        this.resetTableFilter();
      }
    });
    this.saveChangesSubj.subscribe((ch) => {
      this.init();
    });

    this.init();
  }

  private init() {
    if (this.jobRoleFormId) {
      this.disableUploadButton = true;
      this.selectedOrgUnits = [];
      this.selectedPeople = [];
      this.jobRoleService
        .getJobRoleOrgUnits(this.jobRoleFormId)
        .subscribe((orgUnits) => {
          this.jobRoleOrgUnits = orgUnits.map((ou) => {
            return {
              id: ou.orgUnitId,
              name: ou.orgUnitName,
              description: ou.description,
              orgUnitTypeName: ou.orgUnitTypeName,
            };
          });
          this.currentJobRoleOrgUnits = [...this.jobRoleOrgUnits];
        });

      this.jobRoleService
        .getJobRolePeopleDotNet(this.jobRoleFormId)
        .subscribe((jrpeople) => {
          this.updateTableData(jrpeople);
          this.disableUploadButton = false;
        });

      if (this.filterId) {
        this.dynamicGroupsService
          .getFilterById(this.filterId)
          .subscribe((gf) => {
            this.jobRoleFilter = gf;
          });
      } else {
        this.jobRoleFilter = null;
      }

      this.selectedFilter = null;
    }
  }

  openFileUploadModal() {
    const modalRef = this.ngbModal.open(PeopleUploadComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.currentRolePeople = this.currentJobRolePeople;

    modalRef.componentInstance.peopleSelected.subscribe((pl) => {
      if (pl.length > 0) {
        const newPeople = pl.map((p) => {
          return {
            id: p.id,
            name: p.displayName,
            pendingDeletion: false,
          };
        });
        this.selectedPeople = [...this.selectedPeople, ...newPeople];
        this.currentJobRolePeople = [
          ...this.jobRolePeople,
          ...this.selectedPeople,
        ];
        this.uiService.showToast(`Successfully added ${pl.length} people`, {
          classname: 'bg-success text-light',
          delay: 3000,
        });
        this.changePeopleRoles.emit(
          this.currentJobRolePeople
            .filter((p) => !p.pendingDeletion)
            .map((m) => m.id),
        );
      }
    });
  }

  openAddOuModal() {
    const modalRef = this.ngbModal.open(OrgUnitsModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    const existingOrgUnits = this.jobRoleOrgUnits ? this.jobRoleOrgUnits : [];
    const selectedOrgUnits = this.selectedOrgUnits ? this.selectedOrgUnits : [];
    modalRef.componentInstance.jobRoleOrgUnitsInUse = [
      ...existingOrgUnits,
      ...selectedOrgUnits,
    ];
    modalRef.componentInstance.orgUnitsSelected.subscribe((ous) => {
      if (ous.length > 0) {
        const newOrgUnits = ous.map((ou) => {
          return {
            id: ou.id,
            name: ou.name,
            description: ou.description,
            orgUnitTypeName: ou.externalType,
          };
        });
        this.selectedOrgUnits = [...this.selectedOrgUnits, ...newOrgUnits];
        this.currentJobRoleOrgUnits = [
          ...this.jobRoleOrgUnits,
          ...this.selectedOrgUnits,
        ];
        this.uiService.showToast(`Successfully added ${ous.length} org units`, {
          classname: 'bg-success text-light',
          delay: 3000,
        });
        this.changeOrgUnitsRoles.emit(
          this.currentJobRoleOrgUnits.map((m) => m.id),
        );
      }
    });
  }

  openAddRoleMembersModal() {
    const modalRef = this.ngbModal.open(PeopleModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    const existingPeople = this.jobRolePeople ? this.jobRolePeople : [];
    const selectedPeople = this.selectedPeople ? this.selectedPeople : [];
    modalRef.componentInstance.jobRolePeopleInUse = [
      ...existingPeople,
      ...selectedPeople,
    ];
    modalRef.componentInstance.jobRoleId = this.jobRoleId;
    modalRef.componentInstance.peopleSelected.subscribe((pl) => {
      if (pl.length > 0) {
        const newPeople = pl.map((p) => {
          return {
            id: p.id,
            name: p.name,
            pendingDeletion: false,
          };
        });
        this.selectedPeople = [...this.selectedPeople, ...newPeople];
        this.currentJobRolePeople = [
          ...this.jobRolePeople,
          ...this.selectedPeople,
        ];
        this.uiService.showToast(`Successfully added ${pl.length} people`, {
          classname: 'bg-success text-light',
          delay: 3000,
        });
        this.changePeopleRoles.emit(
          this.currentJobRolePeople
            .filter((p) => !p.pendingDeletion)
            .map((m) => m.id),
        );
      }
    });

    // modalRef.componentInstance.parentFormGroup = this.parentFormGroup;
  }

  openAddGroupModal() {
    const modalRef = this.ngbModal.open(GroupFiltersModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.filterId =
      this.parentFormGroup.get('filterId').value;
    modalRef.componentInstance.modalTitle = 'Add filter to job role';
    modalRef.componentInstance.selectedFilterChange.subscribe(
      (selectedFilter) => {
        this.resetTableFilter();
        this.selectedFilter = selectedFilter;
        this.parentFormGroup.get('filterId').setValue(selectedFilter.id);
        this.parentFormGroup.get('filterId').markAsDirty();
        this.tableLoading = true;
        this.tableFilterData.page = 1;

        const tableFilterData = {
          filter: this.selectedFilter.filterJson,
          page: 1,
          pageSize: this.tableFilterData.pageSize,
          order: '',
        };

        this.dynamicGroupsService
          .getFilteredEntityValuesPaged('Person', tableFilterData)
          .subscribe((fp) => {
            this.updateTableData(fp, true);
            this.tableLoading = false;
            this.uiService.showToast(
              `Successfully added ${selectedFilter.name} with ${fp.totalRowCount} associated people`,
              { classname: 'bg-success text-light', delay: 3000 },
            );
          });
      },
    );
  }

  discardOu(id: number) {
    this.selectedOrgUnits = this.selectedOrgUnits.filter((f) => f.id !== id);
    this.currentJobRoleOrgUnits = this.currentJobRoleOrgUnits.filter(
      (f) => f.id !== id,
    );
    this.changeOrgUnitsRoles.emit(this.currentJobRoleOrgUnits.map((m) => m.id));
  }

  discardPerson(id: number) {
    this.selectedPeople = this.selectedPeople.filter((f) => f.id !== id);
    this.currentJobRolePeople = this.currentJobRolePeople.filter(
      (f) => f.id !== id,
    );
    this.changePeopleRoles.emit(
      this.currentJobRolePeople
        .filter((p) => !p.pendingDeletion)
        .map((m) => m.id),
    );
  }

  removeRestoreOu(event: { id: number; remove: boolean }) {
    if (event.remove) {
      this.currentJobRoleOrgUnits = this.currentJobRoleOrgUnits.filter(
        (f) => f.id !== event.id,
      );
    } else {
      this.currentJobRoleOrgUnits = [
        ...this.currentJobRoleOrgUnits,
        ...this.jobRoleOrgUnits.filter((f) => f.id === event.id),
      ];
    }

    this.changeOrgUnitsRoles.emit(this.currentJobRoleOrgUnits.map((m) => m.id));
  }

  removeRestorePerson(event: { id: number; remove: boolean }) {
    if (event.remove) {
      this.currentJobRolePeople = this.currentJobRolePeople.filter(
        (f) => f.id !== event.id,
      );
    } else {
      this.currentJobRolePeople = [
        ...this.currentJobRolePeople,
        ...this.jobRolePeople.filter((f) => f.id === event.id),
      ];
    }

    this.changePeopleRoles.emit(
      this.currentJobRolePeople
        .filter((p) => !p.pendingDeletion)
        .map((m) => m.id),
    );
  }

  discardChangesFilter($event) {
    this.resetTableFilter();
    this.updateData(1, 10, null, true);
  }

  updateData(
    pageNumber?: number,
    pageSize?: number,
    sort?: { column: string; sortDirection: string },
    discardChanges?: boolean,
  ) {
    if (
      this.parentFormGroup.get('filterId')?.dirty &&
      this.parentFormGroup.get('filterId')?.value
    ) {
      this.tableFilterData.order = sort
        ? `DisplayName ${sort.sortDirection}`
        : this.tableFilterData.order;

      const dataToPaginate = {
        filter: this.selectedFilter?.filterJson,
        page: pageNumber ? pageNumber : this.tableFilterData.page,
        pageSize: pageSize ? pageSize : this.tableFilterData.pageSize,
        order: this.tableFilterData.order,
      };

      if (!sort) {
        this.tableLoading = true;
      }

      this.dynamicGroupsService
        .getFilteredEntityValuesPaged('Person', dataToPaginate)
        .subscribe((fp) => {
          this.updateTableData(fp, true);
          this.tableLoading = false;
        });
    } else if (discardChanges) {
      this.jobRoleService
        .getJobRolePeopleDotNet(this.jobRoleId)
        .subscribe((jrpeople) => {
          this.updateTableData(jrpeople);
        });
    }
  }

  updateTableData(response: any, forFilter: boolean = false) {
    let people = [];
    if (forFilter) {
      people = response.data.map((jrp) => {
        return {
          id: jrp.id,
          name: jrp.displayName,
          pendingDeletion: jrp.pendingDeletion,
        };
      });
      this.tableFilterData.pageSize = response['pageSize'];
      this.tableFilterData.page = response['page'];
      this.totalJobRolePeople = response['totalRowCount'];
    } else {
      people = response.map((jrp) => {
        return {
          id: jrp.personId,
          name: jrp.name,
          pendingDeletion: jrp.pendingDeletion,
        };
      });

      this.totalJobRolePeople = people.length;
    }

    forFilter ? (this.filterPeople = people) : (this.jobRolePeople = people);
    this.currentJobRolePeople = [...people];
  }

  resetTableFilter() {
    this.tableFilterData.page = 1;
    this.tableFilterData.pageSize = 10;
  }
}
