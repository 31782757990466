import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MatrixGroupedSkills,
  MatrixPerson,
  MatrixSkill,
} from '../../matrix.service';

@Component({
  selector: 'ug-state-icon',
  templateUrl: './state-icon.component.html',
  styleUrl: './state-icon.component.scss',
})
export class StateIconComponent {
  @Input() personSkillMapValue: {
    stateSymbolClass: string;
    required: boolean;
    state: string;
  };
  @Input() skill: MatrixSkill;
  @Input() person: MatrixPerson;
  @Input() groupedSkills: MatrixGroupedSkills;

  @Output() modifySkillEvent: EventEmitter<{
    skill: MatrixSkill;
    person: MatrixPerson;
    gsk: MatrixGroupedSkills;
  }> = new EventEmitter<{
    skill: MatrixSkill;
    person: MatrixPerson;
    gsk: MatrixGroupedSkills;
  }>();

  ngOnInit() {}

  modifySkill() {
    this.modifySkillEvent.emit({
      skill: this.skill,
      person: this.person,
      gsk: this.groupedSkills,
    });
  }
}
