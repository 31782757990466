import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SkillsMatrixSubjectUser } from '../skill-matrix/skill-matrix';

@Component({
  selector: 'ug-matrix-detail-cell',
  templateUrl: './matrix-detail-cell.component.html',
  styleUrls: [],
})
export class MatrixDetailCellComponent {
  statusColourMap = new Map<string, string>([
    ['1', ' text-danger'],
    ['2', ' text-danger'],
    ['3', ' text-danger'],
    ['4', ' text-danger'],
    ['5', ' text-success'],
    ['6', ' text-warning'],
    ['7', ' text-danger'],
    ['8', ' text-info'],
    ['9', ' text-danger'],
  ]);
  statusSymbolMap = new Map<string, string>([
    ['1', 'fas fa-xl fa-times-circle'],
    ['2', 'fas fa-xl fa-times-circle'],
    ['3', 'fas fa-xl fa-check-circle'],
    ['4', 'fas fa-xl fa-times-circle'],
    ['5', 'fas fa-xl fa-check-circle'],
    ['6', 'fas fa-xl fa-check-circle'],
    ['7', 'fas fa-xl fa-ellipsis-h'],
    ['8', 'fas fa-xl fa-spinner fa-spin'],
    ['9', 'fas fa-xl fa-exclamation-circle'],
  ]);

  @Input() currSubjectUser: SkillsMatrixSubjectUser;
  @Input() activeSubjectUser: boolean;
  @Output() matrixCellClicked: EventEmitter<boolean> = new EventEmitter(false);

  get statusColourClass(): string {
    // if (this.currSubjectUser?.dueDate) {
    //   console.log(`${this.currSubjectUser?.dueDate} ${new Date()}`);
    // }
    if (this.activeSubjectUser) {
      if (
        this.currSubjectUser?.statusId === '1' &&
        new Date(this.currSubjectUser?.dueDate) > new Date()
      ) {
        return this.statusColourMap.get('3');
      }
      if (this.currSubjectUser?.statusId === '5') {
        const trainingDuration =
          new Date(this.currSubjectUser?.expiryDate).valueOf() -
          new Date(this.currSubjectUser?.dueDate).valueOf();
        const timeToExpiry =
          new Date(this.currSubjectUser?.expiryDate).valueOf() -
          new Date().valueOf();
        if (timeToExpiry < 0) {
          return this.statusColourMap.get('1');
        }
        if (timeToExpiry / trainingDuration < 0.1) {
          return this.statusColourMap.get('9');
        }
        if (timeToExpiry / trainingDuration < 0.25) {
          return this.statusColourMap.get('6');
        }
      }
    }

    return this.activeSubjectUser
      ? this.statusColourMap.get(this.currSubjectUser?.statusId) ||
          'text-secondary'
      : 'table-light';
  }

  get statusSymbolClass(): string {
    if (
      this.activeSubjectUser &&
      this.currSubjectUser?.statusId === '1' &&
      new Date(this.currSubjectUser?.dueDate) > new Date()
    ) {
      return this.statusSymbolMap.get('3');
    }

    if (this.activeSubjectUser) {
      if (
        this.currSubjectUser?.statusId === '1' &&
        new Date(this.currSubjectUser?.dueDate) > new Date()
      ) {
        return this.statusColourMap.get('3');
      }
      if (this.currSubjectUser?.statusId === '5') {
        const trainingDuration =
          new Date(this.currSubjectUser?.expiryDate).valueOf() -
          new Date(this.currSubjectUser?.dueDate).valueOf();
        const timeToExpiry =
          new Date(this.currSubjectUser?.expiryDate).valueOf() -
          new Date().valueOf();
        if (timeToExpiry < 0) {
          return this.statusSymbolMap.get('1');
        }
        if (timeToExpiry / trainingDuration < 0.1) {
          return this.statusSymbolMap.get('9');
        }
        if (timeToExpiry / trainingDuration < 0.25) {
          return this.statusSymbolMap.get('6');
        }
      }
    }

    return this.activeSubjectUser
      ? this.statusSymbolMap.get(this.currSubjectUser?.statusId) ||
          'fas fa-question-circle'
      : 'fas fa-minus text-secondary';
  }

  onMatrixCellClick(): void {
    if (this.activeSubjectUser) {
      this.matrixCellClicked.emit(true);
    }
  }
}
