import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';
import {
  ClaimService,
  ClaimSubmissionActivity,
  SharedInfo,
  SharedInfoResponse,
} from '../services/claim.service';

import { PublishedClaim } from '../models/published-claim';

@Component({
  selector: 'ug-published-claims',
  templateUrl: './published-claims.component.html',
  styleUrls: ['./published-claims.component.scss'],
})
export class PublishedClaimsComponent implements OnDestroy, OnInit {
  authService = inject(AuthService);
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);
  private configService = inject(ConfigService);

  publishedClaims: Array<ClaimSubmissionActivity>;
  retrievedTimeStamp: string;
  shareButtonDisabled = true;
  sharedInfoUniqueId: string;
  sharedUrl: string;

  exceptionData = {
    PUB_CLAIMS: {
      level: AlertLevels.ERROR,
      code: 'PCL-001',
      message: 'Error retrieving published assessment requests',
      translationKey: 'errorRetrievingPubAssessmentRequests',
    } as AlertData,
    SHARE_PUB_CLAIMS: {
      level: AlertLevels.ERROR,
      code: 'PCL-002',
      message: 'Error creating share for published assessment requests',
      translationKey: 'errCreatingSharePubAssessments',
    } as AlertData,
  };

  appName = this.configService.title;

  userDetailSubs = new Subscription();
  publishedClaimsSubs = new Subscription();
  publishedShare = new Subscription();

  ngOnInit() {
    this.userDetailSubs = this.authService.loggedInUserSubj.subscribe(
      (p: LoggedInUser) => {
        if (p?.id) {
          this.claimService.getSkillAnalyticsTrainingStatuses().subscribe({
            next: (result) => {
              this.publishedClaims = result;
              this.retrievedTimeStamp = new Date().toISOString();
            },
            error: (err) =>
              this.alertService.createAlert2(
                this.exceptionData.PUB_CLAIMS,
                err,
              ),
          });
          this.shareButtonDisabled = false;
        }
      },
    );
  }

  ngOnDestroy() {
    this.userDetailSubs.unsubscribe();
    this.publishedClaimsSubs.unsubscribe();
    this.publishedShare.unsubscribe();
  }

  onShareButtonClick() {
    this.shareButtonDisabled = true;
    const shareData: SharedInfo = {
      publishedClaims: {
        createTimestamp: this.retrievedTimeStamp,
        personId: this.authService.me.id,
        sharedInfoData: this.publishedClaims.map(
          (m) =>
            ({
              claimLevel: m.claimLevelName,
              dimension: m.competencyDimensionName,
              expiresDate: m.expiryDate ? m.expiryDate : 'n/a',
              publishedDate: m.createDatetime
                ? new Date(m.createDatetime).toLocaleString('en-gb')
                : 'n/a',
              selfVerified: m.isSelfAssessed,
              skillName: m.skillName,
            }) as PublishedClaim,
        ),
      },
    };

    this.publishedShare = this.claimService
      .createPublishedClaimsShare(this.authService.me.id, shareData)
      .subscribe({
        next: (r: SharedInfoResponse) => {
          this.sharedInfoUniqueId = r['sharedInfoUniqueId'];
          this.sharedUrl = `${this.configService.shareServerUri}/${this.sharedInfoUniqueId}`;
        },
        error: (e) => {
          this.alertService.createAlert2(
            this.exceptionData.SHARE_PUB_CLAIMS,
            e,
          );
          this.shareButtonDisabled = false;
        },
      });
  }
}
