import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CompetenciesService,
  SkillDimensionId,
  SkillSubject,
} from '../../../competencies/services/competencies.service';

@Component({
  selector: 'ug-subject-modal',
  templateUrl: './subject-modal.component.html',
  styleUrls: ['./subject-modal.component.scss'],
})
export class SubjectModalComponent implements OnInit {
  modal = inject(NgbActiveModal);
  private fb = inject(FormBuilder);
  private competencyService = inject(CompetenciesService);

  subjectForm: FormGroup = new FormGroup({});
  tableLoading = true;

  @Input() createMode = false;
  @Input() subject: SkillSubject;
  @Input() subjectLevelId: number;
  @Input() skillDimensions = [];
  @Input() parentSubject: SkillSubject;
  @Input() selectedDimensionId;
  @Input() notFoundValue;
  @Input() subjectLevelName: string;
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteConfirmed: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  confirmingDelete: boolean;
  disableDelete: boolean;
  subjectSkillCount = 0;
  unableToDeleteText = '';

  get dimensions(): AbstractControl {
    return this.subjectForm.get('dimensionIds');
  }

  ngOnInit(): void {
    this.subjectForm = this.fb.group({
      name: [null, Validators.required],
      dimensionIds: [null, Validators.required],
      parentId: null,
      subjectLevelId: null,
      isActive: null,
      source: null,
      externalId: null,
      externalParentId: null,
    });

    this.skillDimensions.map((sd) => {
      if (this.subjectLevelId > 1) {
        sd.disabled = true;
      } else if (this.subject && this.subject.source) {
        sd.disabled = sd.id === SkillDimensionId.Training;
      }
    });

    if (this.subject) {
      this.subjectForm.get('name').setValue(this.subject.name);
      this.subjectForm.get('dimensionIds').setValue(this.subject.dimensionIds);
      this.subjectForm.get('parentId').setValue(this.subject.parentId);
      this.subjectForm
        .get('subjectLevelId')
        .setValue(this.subject.subjectLevelId);
      this.subjectForm.get('isActive').setValue(this.subject.isActive);
      this.subjectForm.get('source').setValue(this.subject.source);
      this.subjectForm.get('externalId').setValue(this.subject.externalId);
      this.subjectForm
        .get('externalParentId')
        .setValue(this.subject.externalParentId);

      if (this.subject.source) {
        this.subjectForm.get('name').disable();
        this.disableDelete = true;
      }

      this.subject.dimensionSkillCounts.forEach((dsc) => {
        this.subjectSkillCount += dsc.skillCount;
      });

      this.disableDelete = this.subjectSkillCount > 0;

      if (this.disableDelete) {
        if (this.subjectSkillCount > 0 && !this.subject.source) {
          this.unableToDeleteText =
            'Unable to delete subjects with assigned competencies / training';
        } else if (this.subjectSkillCount === 0 && this.subject.source) {
          this.unableToDeleteText =
            'Unable to delete subjects created externally';
        } else if (this.subjectSkillCount === 0 && this.subject.source) {
          this.unableToDeleteText =
            'Unable to delete subjects created externally or that have assigned competencies / training';
        }
      }
    } else {
      if (this.notFoundValue) {
        this.subjectForm.get('name').setValue(this.notFoundValue);
        this.subjectForm.get('name').markAsDirty();
      }
      if (this.parentSubject) {
        this.subjectForm
          .get('dimensionIds')
          .setValue(this.parentSubject.dimensionIds);
        this.subjectForm.get('parentId').setValue(this.parentSubject.id);
        this.subjectForm
          .get('externalParentId')
          .setValue(this.parentSubject.externalId);
      }

      this.subjectForm.get('subjectLevelId').setValue(this.subjectLevelId);
      this.subjectForm.get('isActive').setValue(true);
      this.disableDelete = true;
    }
  }

  saveSubject() {
    this.saveClicked.emit(this.subjectForm);
  }

  getDimensionName(id) {
    return this.skillDimensions.find((sd) => sd.id === id).name;
  }

  setItemColour(id) {
    return this.competencyService.dimensionBgMap.get(id);
  }

  formControlValid(controlName: string): boolean {
    const c: AbstractControl = this.subjectForm.get(controlName);
    if (c) {
      return !c.invalid;
    }
    // TODO - CHECK THE LOGIC -- ADDED THIS TO GET THE TEST TO PASS
    return false;
  }

  deleteSubject() {
    this.confirmingDelete = true;
  }

  confirmDelete() {
    this.deleteConfirmed.emit(true);
  }

  cancelDelete() {
    this.confirmingDelete = false;
  }

  getDimensionById(id: number) {
    return this.skillDimensions.find((dimension) => dimension.id === id);
  }
}
