<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">
      {{ skillType }} Assignment ({{ dimensionName }})
    </h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="mb-2">
      <ug-table
        [tableHeaders]="tableHeaders"
        [tableSelectedButtons]="tableSelectedButtons"
        [tableFilters]="filters"
        [tableRows]="skillList"
        [showSelectBox]="true"
        [isLoading]="tableLoading"
        [disableAddSelected]="false"
        [existsKey]="'belongsToSubject'"
        [includeExistingAsSelected]="true"
        [disablePreSelected]="false"
      ></ug-table>
    </div>
  </div>
</div>
