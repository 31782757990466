import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize, Observable, of, Subscription } from 'rxjs';
import { AlertData, AlertLevels } from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
import { Claim } from '../models/claim';
import { ClaimService } from '../services/claim.service';

const ALL_TEXT = '<All>';

@Component({
  selector: 'ug-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
})
export class ClaimListComponent implements OnDestroy, OnInit {
  private authService = inject(AuthService);
  private claimService = inject(ClaimService);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  isLoading = true;
  @Input() cardTitle = 'Claims List';
  @Input() personId: number;
  @Input() assessorPersonId: number;

  claimList: Array<Claim>;

  compDimensionLookup = {
    APPLICATION: 'Skill',
    KNOWLEDGE: 'Knowledge',
  };

  filtersEnabled = false;
  filterListStatus = [];
  filterListLevel = [];
  filterListAssessor = [];
  filterListClaimant = [];
  filterListCompDimension: Array<string> = [];
  filterListIntervals: Array<{
    intervalName: string;
    offsetValue: moment.Moment;
  }> = [];

  filterControls: FormGroup;

  verifierView = false;
  notificationList: { claimId: number }[] = [];
  activitySummary = [];
  selectedCategory: string;
  get claimantView(): boolean {
    return !this.verifierView;
  }

  exceptionData = {
    CLAIM: {
      level: AlertLevels.ERROR,
      code: 'CLS-001',
      message: 'Error retrieving claim',
      translationKey: 'errRetrievingAssessmentReq',
    } as AlertData,
  };

  claimsSubs = new Subscription();

  constructor() {
    this.createFilterControls();
    this.buildFilterListIntervals();
  }

  createFilterControls() {
    this.filterControls = this.fb.group({
      actionedDateRange: [null],
      createDateRange: [null],
      compDimension: [null],
      status: [null],
      level: [null],
      counterparty: [null],
    });
  }

  buildFilterListIntervals() {
    this.filterListIntervals.push({
      intervalName: 'last week',
      offsetValue: moment(0, 'HH').subtract(1, 'weeks'),
    });
    this.filterListIntervals.push({
      intervalName: 'last 2 weeks',
      offsetValue: moment(0, 'HH').subtract(2, 'weeks'),
    });
    this.filterListIntervals.push({
      intervalName: 'last month',
      offsetValue: moment(0, 'HH').subtract(1, 'months'),
    });
    this.filterListIntervals.push({
      intervalName: 'last 6 months',
      offsetValue: moment(0, 'HH').subtract(6, 'months'),
    });
    this.filterListIntervals.push({
      intervalName: 'last year',
      offsetValue: moment(0, 'HH').subtract(1, 'years'),
    });
  }

  ngOnInit() {
    this.claimService.sharedClaimActivitySummary.subscribe((cas) => {
      if (cas.length) {
        this.activitySummary = cas;
        this.setActivityListData(this.activatedRoute.snapshot.params.category);
      }
    });

    this.activatedRoute.params.subscribe((params) => {
      if (this.selectedCategory && params.category !== this.selectedCategory) {
        this.setActivityListData(params.category);
      }
    });
  }

  onClaimDetailClick(claim: Claim) {
    let notificationObs: Observable<any>;
    if (this.hasNotification(claim)) {
      notificationObs = this.claimService.clearClaimNotifications(
        claim.id,
        this.personId,
        this.verifierView ? 'C' : 'A',
      );
    } else {
      notificationObs = of(null);
    }
    this.claimsSubs.add(
      notificationObs
        .pipe(
          finalize(() => {
            if (this.verifierView) {
              this.router.navigate([
                '/assessment-request',
                claim.id,
                'assessor',
              ]);
            } else {
              this.router.navigate([
                '/assessment-request',
                claim.id,
                'claimant',
              ]);
            }
          }),
        )
        .subscribe({
          next: () => {
            this.removeFromNotificationList(claim.id);
          },
          error: (err) => {},
        }),
    );
  }

  private removeFromNotificationList(claimId: number) {
    const notificationIndex = this.notificationList.findIndex((n) => {
      return n['claimId'] === claimId;
    });
    if (notificationIndex !== -1) {
      this.claimService.activityListData['notificationClaimIdList'].splice(
        notificationIndex,
        1,
      );
    }
  }

  addClaim(personId: number) {
    this.router.navigate(['/assessment-request']);
  }

  buildFilterLists(claimList: Array<Claim>) {
    // this.filterListStatus = [...new Set(claimList.map(item => item.statusText))];
    this.filterListStatus = this.makeSet(
      claimList.map((item) => item.statusText),
    );
    this.filterListStatus.sort();

    // this.filterListLevel = [...new Set(claimList.map(item => item.claimLevelName))];
    this.filterListLevel = this.makeSet(
      claimList.map((item) => item.claimLevelName),
    );
    this.filterListLevel.sort();

    // this.filterListAssessor = [...new Set(claimList.map(item => item.assessorName))];
    this.filterListAssessor = this.makeSet(
      claimList.map((item) => item.assessorName),
    );
    this.filterListAssessor.sort();

    // this.filterListClaimant = [...new Set(claimList.map(item => item.claimantName))];
    this.filterListClaimant = this.makeSet(
      claimList.map((item) => item.claimantName),
    );
    this.filterListClaimant.sort();

    this.filterListCompDimension = this.makeSet(
      claimList.map((item) => item.competencyDimensionName),
    );
    // this.filterListCompDimension = this.makeSet(claimList.map(
    //   item => this.compDimensionLookup[item.competencyDimension] || item.competencyDimension)
    // );
    this.filterListCompDimension.sort();
  }

  showClaim(claim: Claim) {
    return (
      !this.filtersEnabled ||
      ((!this.filterControls.get('level').value ||
        this.filterControls.get('level').value === claim.claimLevelName) &&
        (!this.filterControls.get('status').value ||
          this.filterControls.get('status').value === claim.statusText) &&
        (!this.filterControls.get('counterparty').value ||
          (this.claimantView &&
            this.filterControls.get('counterparty').value ===
              claim.assessorName) ||
          (this.verifierView &&
            this.filterControls.get('counterparty').value ===
              claim.claimantName)) &&
        (!this.filterControls.get('createDateRange').value ||
          !claim.createDatetime ||
          this.filterControls.get('createDateRange').value.format() <
            claim.createDatetime) &&
        (!this.filterControls.get('actionedDateRange').value ||
          !claim.lastActionDatetime ||
          this.filterControls.get('actionedDateRange').value.format() <
            claim.lastActionDatetime) &&
        (!this.filterControls.get('compDimension').value ||
          this.filterControls.get('compDimension').value ===
            claim.competencyDimensionName))
    );
  }

  hasNotification(claim: Claim) {
    return this.notificationList
      .map((nc) => {
        return nc.claimId;
      })
      .includes(claim.id);
  }

  onNotificationButtonClick(rowIndex: number) {
    this.clearNotification(this.claimList[rowIndex].id);
  }

  clearNotification(claimId: number) {
    this.claimsSubs.add(
      this.claimService
        .clearClaimNotifications(
          claimId,
          this.personId,
          this.verifierView ? 'C' : 'A',
        )
        .subscribe(() => {
          this.removeFromNotificationList(claimId);
        }),
    );
  }

  makeSet(itemList: Array<any>) {
    const newSet = [];
    for (const item of itemList) {
      if (newSet.indexOf(item) === -1) {
        newSet.push(item);
      }
    }
    return newSet.sort();
  }

  toggleFilters() {
    this.filtersEnabled = !this.filtersEnabled;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnDestroy() {
    this.claimsSubs.unsubscribe();
  }

  setupClaimList() {
    this.notificationList =
      this.claimService.activityListData['notificationClaimIdList'];
    this.cardTitle = this.claimService.activityListData['categoryName'];
    this.claimList = this.claimService.activityListData['claimList']
      .sort((a, b) => {
        const aHasNotification = this.hasNotification(a);
        const bHasNotification = this.hasNotification(b);
        let returnValue = 0;
        if (aHasNotification && !bHasNotification) {
          returnValue = -1;
        } else if (!aHasNotification && bHasNotification) {
          returnValue = 1;
        } else {
          returnValue = a.id - b.id;
        }
        return returnValue;
      })
      .map((c) => {
        return {
          ...c,
          hasNotification: this.hasNotification(c), // Correct assignment
        };
      });
    this.verifierView = ['VERIFY', 'VERIFIED'].includes(
      this.claimService.activityListData['categoryCode'],
    );

    this.personId = this.authService.me.id;
    this.buildFilterLists(this.claimList);
    this.claimService.activityListData = null;
    this.isLoading = false;
    this.claimService.activityListData = null;
  }

  setActivityListData(categoryCode: string) {
    this.selectedCategory = categoryCode;
    let claimCategory;
    if (this.selectedCategory === 'MY-ASSESSED') {
      claimCategory = 'PUBLISHED';
    } else if (this.selectedCategory === 'ASSESSED') {
      claimCategory = 'VERIFIED';
    } else if (this.selectedCategory === 'ASSESS') {
      claimCategory = 'VERIFY';
    } else {
      claimCategory = this.selectedCategory;
    }

    const foundCategory = this.activitySummary.find(
      (ca) => ca.categoryCode === claimCategory,
    );

    if (!foundCategory && !this.claimService.activityListData) {
      this.router.navigate(['/home']);
    }

    if (foundCategory && !this.claimService.activityListData) {
      this.claimService.activityListData = foundCategory;
    }

    this.setupClaimList();
  }
}
