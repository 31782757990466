import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'ug-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class Pagination implements OnChanges, OnInit {
  @Input() pageSize: number;
  @Input() totalRecords: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  currentPage: 0;
  numberOfPages: number = 0;

  ngOnInit(): void {
    this.currentPage = 0;
  }

  ngOnChanges(): void {
    this.numberOfPages = Math.ceil(this.totalRecords / this.pageSize);
  }

  pageClick(currentPage) {
    this.currentPage = currentPage;
    this.pageChange.emit(currentPage * this.pageSize);
  }
}
