export class ClaimLevel {
  id: number;
  name: string;
  description: string;
  levelCode: string;
  selfAssessed: boolean;
  claimLevelSetId: number;
  sortOrder: number;
  approvalLevel: number;
}
