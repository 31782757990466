<div class="mx-0">
  <p class="fs-6 badge rounded-pill bg-sqeptech mb-0">
    Role Match Score :
    {{
      jobRolePersonScore
        ? (jobRolePersonScore | percent: '1.1-1')
        : (0 | percent: '1.1-1')
    }}
  </p>
</div>
