import { Component, Input, OnChanges, inject } from '@angular/core';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { PersonService } from '../services/person.service';

import { Person } from '../models/person';

@Component({
  selector: 'ug-person-summary',
  templateUrl: './person-summary.component.html',
  styleUrls: [],
})
export class PersonSummaryComponent implements OnChanges {
  private alertService = inject(AlertService);
  private personService = inject(PersonService);

  private _id: number;
  @Input()
  set personId(id: number) {
    this._id = id;
  }
  get personId() {
    return this._id;
  }
  person = new Person();

  exceptionData = {
    GET_PERSON_SUMM: {
      level: AlertLevels.ERROR,
      code: 'PRS-001',
      message: 'Error retrieving person summary',
    } as AlertData,
  };

  ngOnChanges() {
    if (!this.personId) {
      return;
    }
    this.personService.getPersonSummary(this.personId).subscribe(
      (person: Person) => (this.person = person[0]),
      (err) => {
        this.alertService.createAlert2(this.exceptionData.GET_PERSON_SUMM, err);
      },
      () => console.log('Got Person by ID data'),
    );
  }
}
