import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';

import { PreAuthGuard } from '../guards/pre-auth.guard';
import { JobFamilyFilterComponent } from './job-family-filter/job-family-filter.component';
import { SkillListComponent } from './skill-list/skill-list.component';

const routes: Routes = [
  {
    path: 'oldskills',
    component: SkillListComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  },
  {
    path: 'skills2',
    component: JobFamilyFilterComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SkillRoutingModule {}
