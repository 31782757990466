import { Component, inject, Input } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ClaimService } from '../../../claim/services/claim.service';
import { AuthService } from '../../../core/services/auth.service';
import { ClientAccountService } from '../../../core/services/client-account.service';
import { MenuItem, Subset } from '../menu-item';

@Component({
  selector: 'ug-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  @Input() inUserGroup = false;
  @Input() navType = 'sideNav';
  @Input() isCollapsed: boolean;

  private claimService: ClaimService = inject(ClaimService);
  private authService: AuthService = inject(AuthService);
  private clientAccountService: ClientAccountService =
    inject(ClientAccountService);

  protected menuData: Array<MenuItem | Subset<MenuItem>> = [];
  protected userSecurityLevel: number;
  protected personHasManagementPosition = false;
  protected claimNotificationMap = new Map<string, number>();
  protected roleLookup: any;
  protected menuLoading = true;
  protected subMenu: string;

  ngOnInit() {
    this.menuLoading = true;

    combineLatest([
      this.authService.securityRoleLookupSubj,
      this.authService.loggedInUserSubj,
    ]).subscribe({
      next: ([srl, usd]) => {
        this.roleLookup = srl;
        this.userSecurityLevel = usd.securityLevel;
        this.personHasManagementPosition = usd.isManager;
        this.menuData = [...this.clientAccountService.getMenu()]
          .filter((mi) => this.showMenuItem(mi))
          .map((element) => {
            return {
              ...element,
              subMenu: element.subMenu?.filter((subElement) =>
                this.showMenuItem(subElement),
              ),
            };
          });
        this.menuLoading = false;

        this.claimService.sharedClaimActivitySummary.subscribe({
          next: (cas) => {
            if (cas && cas.length > 0) {
              this.menuData.forEach((mi) => {
                if (mi.subMenu)
                  mi?.subMenu.forEach((sm) => {
                    if (sm.claimCategory) {
                      this.claimNotificationMap.set(
                        sm.claimCategory,
                        cas.find((c) => c.categoryCode === sm.claimCategory)
                          ?.notificationClaimIdList.length,
                      );
                    }
                  });
              });
            }
          },
        });
      },
    });
  }

  showMenuItem(menuItem: any): boolean {
    return (
      !menuItem.hideItem &&
      (this.roleLookup[menuItem.minSecurityRole].securityLevel <=
        this.userSecurityLevel ||
        (menuItem.allowManagementPositionAccess &&
          this.personHasManagementPosition))
    );
  }
}
