<div class="row">
  <div class="col-12 col-md-6 mt-3">
    <ug-card-tile
      cardTitle="Person / Role match score"
      [cardContent]="jobRolePerson.personRoleScore | percent: '1.1-1'"
      icon="fas fa-percent"
      [highlight]="getScoreTrafficLight(jobRolePerson.personRoleScore)"
    ></ug-card-tile>
  </div>
  <div class="col-12 col-md-6 mt-3">
    <ug-card-tile
      cardTitle="Mandatory requirements"
      [cardContent]="
        jobRolePerson.unmetMandatoryCount | i18nPlural: mandatoryCountMessageMap
      "
      icon="fas fa-tasks"
      [highlight]="jobRolePerson.unmetMandatoryCount > 0 ? 'danger' : 'success'"
    ></ug-card-tile>
  </div>
</div>
