import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';

import { SecurityRoleGuard } from '../core/guards/security-role.guard';
import { PreAuthGuard } from '../guards/pre-auth.guard';
import { UnsavedChangesGuard } from '../guards/unsaved-changes.guard';
import { BulkAssessmentComponent } from './bulk-assessment/bulk-assessment.component';
import { EventsCalendarSettingsComponent } from './events-calendar-settings/events-calendar-settings.component';
import { TrainingCalendarComponent } from './training-calendar/training-calendar.component';

const routes: Routes = [
  {
    path: 'events-calendar',
    component: TrainingCalendarComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      title: 'Training Calendar',
      routeIdentifier: 'TRAINING-CALENDAR',
      itemTranslationKey: 'trainingCalendar',
    },
  },
  {
    path: 'events-calendar/:subjectId',
    component: TrainingCalendarComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      title: 'Training Calendar',
      routeIdentifier: 'TRAINING-CALENDAR',
      itemTranslationKey: 'trainingCalendar',
    },
  },
  {
    path: 'events-calendar/:subjectId/:sessionId',
    component: TrainingCalendarComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: {
      title: 'Training Calendar',
      routeIdentifier: 'TRAINING-CALENDAR',
      itemTranslationKey: 'trainingCalendar',
    },
  },
  {
    path: 'training-assessment-settings',
    component: EventsCalendarSettingsComponent,
    canActivate: mapToCanActivate([PreAuthGuard, SecurityRoleGuard]),
    canDeactivate: [UnsavedChangesGuard],
    data: {
      title: 'Training Assessment Settings',
      routeIdentifier: 'TRAINING-CALENDAR-SETTINGS',
      itemTranlationKey: 'trainingCalendarSettings',
    },
  },
  {
    path: 'bulk-assessment/:id',
    component: BulkAssessmentComponent,
    canActivate: mapToCanActivate([PreAuthGuard]),
    data: { title: 'Bulk Assessment' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
