import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { TextboxFilter } from '../../../../../controls/dynamic-form/control-types/filter-textbox';
import { TableHeader } from '../../../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { UitoolsService } from '../../../../../core/services/uitools.service';
import { ToFirstCharUpperCasePipe } from '../../../../../pipes/to-first-char-upper-case.pipe';
import { Skill } from '../../../../../skill/models/skill';
import {
  SkillDimension,
  SkillService,
} from '../../../../../skill/services/skill.service';

@Component({
  selector: 'ug-competencies-modal',
  templateUrl: './competencies-modal.component.html',
  styleUrls: ['./competencies-modal.component.scss'],
})
export class CompetenciesModalComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  private skillService = inject(SkillService);
  private alertService = inject(AlertService);
  private uiService = inject(UitoolsService);

  exceptionData = {
    SKILLS_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRC-001',
      message: 'Error retrieving skills',
    } as AlertData,
    SKILL_DIMS: {
      level: AlertLevels.ERROR,
      code: 'JRC-002',
      message: 'Error retrieving skill dimensions',
    } as AlertData,
  };

  @Input() parentFormGroup: FormGroup;
  @Input() jobRoleId: number;

  dimensionsForm: FormGroup;
  skillDimensions: Array<SkillDimension>;
  ngUnsubscribe: Subject<boolean> = new Subject();
  tableLoading = true;
  skillList: Array<Skill>;
  initialSkillList = [];
  skillIds: Set<number>;
  addSelectedLoading = false;
  roleReqsArray = [];
  skillCount = 0;

  @Output() readonly filtersCleared = new EventEmitter<boolean>();
  @Output() readonly pageIndexChange = new EventEmitter<number>();
  @Output() readonly pageSizeChange = new EventEmitter<number>();
  @Output() readonly sortChange = new EventEmitter<{
    column: string;
    sortDirection: string;
  }>();

  paginatedTableFilterData: {
    filter: string;
    order: string;
    page: number;
    pageSize: number;
  } = {
    filter: '',
    order: 'ExistsInJobRole desc, FunctionalAreaName, Name',
    page: 1,
    pageSize: 10,
  };

  searchFilterData: {
    filter: string;
    page: number;
    pageSize: number;
    order?: string;
  };
  tablePageSize: any;
  tablePageIndex: any;

  public rows = [];

  tableHeaders: Array<TableHeader> = [];
  tableRows = [];
  tableFilters = [];
  tableSelectedButtons = [];

  termSubject = new Subject();

  get dimensionId() {
    return this.dimensionsForm.get('dimensionId').value;
  }

  ngOnInit(): void {
    this.tableHeaders = [
      {
        id: 'functionalAreaName',
        title: 'Competency Subcategory',
        class: 'w-15',
      },
      { id: 'name', title: 'Competency', class: 'w-25' },
      { id: 'description', title: 'Description', class: 'w-40' },
      {
        id: 'existsInJobRole',
        title: 'Exists In Role',
        iconFunction: this.activeIcon,
        class: 'text-center',
      },
    ];

    this.tableFilters = [
      new TextboxFilter({
        key: 'searchTerm',
        label: 'Filter by term',
        callback: this.skillSearch,
        order: 1,
        placeholder: 'Filter by term',
      }),
    ];

    this.tableSelectedButtons = [
      {
        title: 'Cancel',
        class: 'btn-outline-secondary',
        cancelButton: true,
      },
      {
        title: 'Add Selected',
        class: 'btn-outline-success',
        function: this.addSelected,
      },
    ];

    this.skillService
      .getSkillDimensions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (cd) => {
          this.skillDimensions = cd.filter((sd) => sd.name !== 'Training');
          this.dimensionsForm
            .get('dimensionId')
            .setValue(this.skillDimensions[0].id);
        },
        (err) =>
          this.alertService.createAlert2(this.exceptionData.SKILL_DIMS, err),
      );

    this.dimensionsForm = this.fb.group({
      dimensionId: [''],
    });

    this.dimensionsForm.valueChanges.subscribe((df) => {
      this.tableLoading = true;

      // this.skillService.getSkillsByDimensionIdForJobRoleId(df.dimensionId, this.jobRoleId, this.tableFilterData).subscribe(x => {
      //   this.skillCount = x.length;
      //   this.tableRows = x;
      //   this.tableLoading = false;
      // })
      this.skillService
        .getSkillsByDimensionIdForJobRoleIdPaged(
          df.dimensionId,
          this.jobRoleId,
          this.paginatedTableFilterData,
        )
        .subscribe({
          next: (x) => {
            this.skillCount = x.totalRowCount;
            this.tableRows = x.data;
            this.tablePageSize = x.pageSize;
            this.tablePageIndex = x.pageIndex;
            this.tableLoading = false;
          },
          error: (err) => {
            console.log(err);
          },
        });
    });
  }

  get competencies(): FormArray {
    return this.parentFormGroup.get('competencies') as FormArray;
  }

  activeIcon = (row) => {
    return row.existsInJobRole === true
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  addSelected = (selected: any[]) => {
    this.addSelectedLoading = true;
    let competenciesAdded = 0;
    selected.forEach((req) => {
      const compIndex = this.competencies.value.findIndex(
        (s) => s.skillId === req.id,
      );
      if (compIndex === -1) {
        this.competencies.push(this.newComp(req));
        this.competencies.at(this.competencies.length - 1).markAsDirty();
        competenciesAdded++;
      } else {
        this.uiService.showToast(
          'Competency ' + req.name + ' has already been added to role',
          { classname: 'bg-danger text-light', delay: 5000 },
        );
      }
    });
    if (competenciesAdded > 0) {
      this.uiService.showToast(
        'Successfully added ' + competenciesAdded + ' competencies',
        { classname: 'bg-success text-light', delay: 3000 },
      );
      this.uiService.closeModal();
    }

    this.addSelectedLoading = false;
  };

  newComp(skill): FormGroup {
    return this.fb.group({
      claimLevelId: ['', Validators.required],
      claimLevelSetId: [skill.claimLevelSetId],
      functionalAreaName: [skill.functionalAreaName],
      id: [null],
      jobRoleId: [this.jobRoleId],
      mandatory: [false, Validators.required],
      pendingDeletion: [false],
      skillId: [
        skill.id,
        [Validators.required, this.roleSkillUniquenessValidator()],
      ],
      skillName: [skill.name],
      weighting: [
        1,
        [Validators.required, Validators.min(0), Validators.max(1)],
      ],
    });
  }

  roleSkillUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.roleReqsArray
        .map((jr) => jr.skillId)
        .indexOf(control.value) !== -1
        ? { roleSkillUniqueness: { value: control.value } }
        : null;
    };
  }

  closeModal() {
    console.log('called');
    this.uiService.closeModal();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  skillSearch = (searchTerm) =>
    searchTerm
      .pipe(
        distinctUntilChanged(),
        debounceTime(5000),
        switchMap((term) => {
          this.termSubject.next(term);

          this.searchFilterData = Object.assign(
            {},
            this.paginatedTableFilterData,
          );
          this.searchFilterData.page = 1;
          this.searchFilterData.filter = term
            ? `[[\"Name\",\"contains\",\"${term}\"],"or",[\"FunctionalAreaName\",\"contains\",\"${term}\"]]`
            : '';

          return this.skillService
            .getSkillsByDimensionIdForJobRoleIdPaged(
              this.dimensionId,
              this.jobRoleId,
              this.searchFilterData,
            )
            .pipe(
              takeUntil(this.termSubject),
              map((response) => {
                return {
                  ...response,
                  searchTerm: term,
                };
              }),
              catchError(() => {
                return of([]);
              }),
            );
        }),
      )
      .subscribe((x, val) => {
        this.skillCount = x.totalRowCount;
        this.tableRows = x.data;
        this.tablePageSize = x.pageSize;
        this.tablePageIndex = x.pageIndex;
      });

  updateData(
    pageNumber?: number,
    pageSize?: number,
    sort?: { column: string; sortDirection: string },
  ) {
    const dataToPaginate = this.searchFilterData
      ? this.searchFilterData
      : this.paginatedTableFilterData;
    const toFirstCharUpperCase = new ToFirstCharUpperCasePipe();

    if (pageNumber) {
      dataToPaginate.page = pageNumber;
    }
    if (pageSize) {
      dataToPaginate.pageSize = pageSize;
    }
    if (sort) {
      const col = toFirstCharUpperCase.transform(sort.column);
      dataToPaginate.order = `${col} ${sort.sortDirection}`;
    }

    this.skillService
      .getSkillsByDimensionIdForJobRoleIdPaged(
        this.dimensionId,
        this.jobRoleId,
        dataToPaginate,
      )
      .subscribe({
        next: (x) => {
          this.skillCount = x.totalRowCount;
          this.tableRows = x.data;
        },
        error: (err) =>
          this.alertService.createAlert2(this.exceptionData.SKILLS_LIST, err),
      });
  }

  clearFilters($event: boolean) {
    if ($event) {
      this.searchFilterData = null;
      this.paginatedTableFilterData.page = 1;
      this.updateData();
    }
  }
}
