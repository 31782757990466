import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AssessorService } from '../../assessor/services/assessor.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService } from '../../core/services/auth.service';
// import { ClaimService } from '../claim/claim.service';
import { ConfigService } from '../../core/services/config.service';

import { Assessor } from '../../assessor/models/assessor';

@Component({
  selector: 'esqep-verifier-select-form', // tslint:disable-line:component-selector
  templateUrl: './verifier-select-form.component.html',
  styleUrls: ['./verifier-select-form.component.scss'],
})
export class VerifierSelectFormComponent implements OnChanges, OnDestroy {
  private authService = inject(AuthService);
  private alertService = inject(AlertService);
  private assessorService = inject(AssessorService);
  private configService = inject(ConfigService);
  private fb = inject(FormBuilder);

  @Input() parentFormGroup: FormGroup;
  @Input() funcAreaId: number;
  @Input() verifier: Assessor;
  @Input() selfVerified = true;
  @Input() controlStatus = { disabled: false, reason: '' };

  @Output() verifierChange: EventEmitter<Assessor> =
    new EventEmitter<Assessor>();
  verifierList: Array<Assessor> = [];

  savedFuncAreaId: number;

  verifierSelectSubs = new Subscription();

  exceptionData = {
    VERIFIER_FA_OU: {
      level: AlertLevels.ERROR,
      code: 'VSC-001',
      message: 'Unable to retrieve verifer data',
      translationKey: 'errorValidatingVerifier',
    } as AlertData,
  };

  clog = (msg, lvl) => {
    this.configService.logging.newMsg(msg, lvl);
  };

  get orgUnitVerifiersOnly(): FormControl {
    return this.parentFormGroup.get(
      'verifier.orgUnitVerifiersOnly'
    ) as FormControl;
  }

  get formGroupDisabled(): boolean {
    return (
      this.parentFormGroup.get('verifier').disabled ||
      this.controlStatus.disabled
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.funcAreaId || !this.authService.me.organisationUnitId) {
      this.verifierList = [];
      return;
    }
    if (this.savedFuncAreaId === this.funcAreaId) {
      return;
    }
    if (this.selfVerified) {
      return;
    }

    this.savedFuncAreaId = this.funcAreaId;
    this.verifierSelectSubs.add(
      this.assessorService
        .getAssessorByFuncAreaOrgUnit(
          this.funcAreaId,
          this.authService.me.organisationUnitId
        )
        .subscribe(
          (al: Array<Assessor>) => {
            this.verifierList = al
              .filter((a) => a.personId !== this.authService.me.id)
              .sort((a, b) => {
                return a.personFamilyGivenName
                  .toLocaleLowerCase()
                  .localeCompare(b.personFamilyGivenName.toLocaleLowerCase());
              });
            this.clog(
              `Got Verifiers for FA:${this.funcAreaId}, OU:${this.authService.me.organisationUnitId}`,
              'VERBOSE'
            );
            const currentVerifierFromList = this.verifierList.find((v) => {
              return v.assessorId === this.verifier.assessorId;
            });
            if (currentVerifierFromList) {
              this.orgUnitVerifiersOnly.setValue(
                currentVerifierFromList.inOrgBranch
              );
            }
          },
          (err: HttpErrorResponse) => {
            this.alertService.createAlert2(
              this.exceptionData.VERIFIER_FA_OU,
              err
            );
          }
        )
    );
  }

  ngOnDestroy() {
    this.verifierSelectSubs.unsubscribe();
  }

  onVerifierClick(idx: number, verifier: Assessor) {
    if (this.formGroupDisabled) {
      return;
    }
    this.clog(
      `Clicked on verifier id: ${this.verifierList[idx].assessorId} ${this.verifierList[idx].personFamilyGivenName}`,
      'VERBOSE'
    );
    if (this.verifier.assessorId === verifier.assessorId) {
      this.verifierChange.emit(new Assessor());
    } else {
      this.verifierChange.emit(verifier);
    }
  }

  getVerifierRowClass(verifierRow: Assessor): string {
    let displayClass = '';
    if (!verifierRow.inOrgBranch && this.orgUnitVerifiersOnly.value) {
      displayClass = 'd-none';
    } else {
      if (verifierRow.assessorId === this.verifier.assessorId) {
        displayClass = 'table-success';
      } else {
        displayClass = this.formGroupDisabled
          ? 'table-secondary'
          : 'table-light';
      }
    }
    return displayClass;
  }
}
