<form [formGroup]="defaultAmberWarningForm">
  <div class="card mb-5" [formGroup]="defaultAmberWarningForm">
    <div class="card-body">
      <h2 class="h5 fw-bold">Default Calculation</h2>
      <p>
        Set your desired expiry calculation to determine when the Amber warning
        should be displayed for both Training and Competencies.
      </p>
      <p class="fst-italics">
        For example, if you set the default warning to be one month, the amber
        warning will be displayed for that competency or training in the matrix
        when the expiry date is one month away.
      </p>
      <div class="row align-items-center">
        <div class="col-3">
          <div class="input-group rounded-end">
            <input
              [ngClass]="{
                'is-invalid': defaultAmberWarningForm.get('value').invalid,
              }"
              class="form-control"
              type="number"
              min="1"
              formControlName="value"
              aria-label="Default amber warning value"
            />
            <select
              [ngClass]="{
                'is-invalid': defaultAmberWarningForm.get('unit').invalid,
              }"
              class="form-select rounded-end"
              formControlName="unit"
              aria-label="Date From"
            >
              <option
                *ngFor="let opt of units | keyvalue; let i = index"
                [value]="opt.key"
              >
                {{ opt.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <button
            *ngIf="!defaultAmberWarningForm.disabled"
            class="btn esqepBtn"
            (click)="clearSettings()"
            [disabled]="
              !defaultAmberWarningForm.get('value').value ||
              !defaultAmberWarningForm.get('unit').value
            "
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
