<div class="row">
  <div class="d-flex align-items-center">
    <div class="col-4 text-end">{{ progressTitle }} Progress</div>
    <div class="col-4">
      <div class="progress mx-3">
        <div
          class="progress-bar bg-success"
          role="progressbar"
          [ngStyle]="progressStyle(progressSuccess, progressTotal)"
          [attr.aria-label]="progressTitle + ' Success'"
          [attr.aria-valuenow]="progressSuccess"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          class="progress-bar bg-danger"
          role="progressbar"
          [ngStyle]="progressStyle(progressFailed, progressTotal)"
          [attr.aria-label]="progressTitle + ' Failed'"
          [attr.aria-valuenow]="progressFailed"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <div class="col-4">
      Total: {{ progressTotal }} Success: {{ progressSuccess }} Failed:
      {{ progressFailed }}
    </div>
  </div>
</div>
