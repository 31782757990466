<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add Organisation Units to Job Role</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="closeModal()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <div [formGroup]="orgUnitFiltersForm" class="mb-2">
      <div class="d-flex">
        <div class="me-1">
          <label for="orgUnitSelect" class="mb-2">Filter By</label>
          <select
            class="form-select"
            id="orgUnitSelect"
            formControlName="filterType"
          >
            <option
              *ngFor="let orgUnitType of orgUnitTypes"
              [value]="orgUnitType.key"
            >
              {{ orgUnitType.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [tableFilters]="tableFilters"
      [tableSelectedButtons]="tableSelectedButtons"
      [showSelectBox]="true"
      [showPagination]="true"
      [isLoading]="ouTableLoading"
      [totalRecords]="orgUnitCount"
    >
    </ug-table>
  </div>
</div>
