<div class="card shadow">
  <div class="card-body">
    <!-- <h5 class="card-title">Division import</h5> -->
    <div class="row row-cols-2">
      <div class="col">
        <h6>Available Divisions</h6>
        <ul class="list-group">
          <li
            *ngFor="let csodDiv of csodDisplayDivsList; let i = index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>{{ csodDiv.externalId }}</span>
            <span>{{ csodDiv.name }}</span>
            <button
              class="btn esqepBtn"
              [ngClass]="{ disabled: updateInProgress }"
              (click)="onAddClick(i)"
            >
              Add
            </button>
          </li>
        </ul>
      </div>
      <div class="col">
        <h6>Imported Divisions</h6>
        <ul class="list-group">
          <li
            *ngFor="let confSetting of configSettingsList; let j = index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>{{ confSetting.configValue }}</span>
            <span>{{ confSetting.description }}</span>
            <button
              class="btn btn-outline-danger"
              [ngClass]="{ disabled: updateInProgress }"
              (click)="onRemoveClick(j)"
            >
              Remove
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
