import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, tap } from 'rxjs/operators';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { OrgUnitService } from '../../org-unit/services/org-unit.service';
import { KeyModalComponent } from '../matrix/key-modal/key-modal.component';
import {
  Matrix,
  MatrixPayload,
  MatrixService,
  MatrixSummaryFilters,
  MatrixTab,
} from '../matrix/matrix.service';

@Component({
  selector: 'ug-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss'],
})
export class MatrixComponent implements OnInit, OnChanges {
  exceptionData = {
    MATRIX_DATA: {
      level: AlertLevels.ERROR,
      code: 'TMX-001',
      message: 'Error retrieving matrix data',
    } as AlertData,
    MATRIX_OU_TYPE: {
      level: AlertLevels.ERROR,
      code: 'TMX-002',
      message: 'Error retrieving matrix org unit type',
    } as AlertData,
  };

  @Input() isForSummary!: boolean;
  @Input() matrixSummaryFilters!: MatrixSummaryFilters;
  @Input() matrixSummaryTitle!: string;
  @Input() canAccessOuData: boolean;
  @Input() matrixType!: string;

  private ngbModal = inject(NgbModal);
  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);
  private matrixService = inject(MatrixService);
  private alertService = inject(AlertService);
  private orgUnitService = inject(OrgUnitService);
  private destroyRef = inject(DestroyRef);
  private selectedOrgUnitId!: number;
  private selectedSubjectId!: number;
  private additionalPeopleIds: number[] = [];

  protected activeTab = 1;
  protected loadingData = true;
  protected fullFunc: boolean = false;
  protected matrixTabData: Array<any> = [];
  protected matrixPeople: Array<any> = [];
  protected showUnassignedUsers: boolean = false;
  protected orgUnitType: string;
  protected isManager: boolean = false;

  protected stateSymbolMap = new Map<string, string>([
    ['Expired', 'fas fa-xl fa-times-circle text-danger'],
    ['Expiring', 'fas fa-xl fa-check-circle text-esqep-warning'],
    ['Complete', 'fas fa-xl fa-check-circle text-success'],
    ['Overdue', 'fas fa-xl fa-times-circle text-danger'],
    ['Not due yet', 'fas fa-xl fa-clock text-danger'],
    ['Incomplete', 'fas fa-xl fa-times-circle text-danger'],
    ['Not required', 'fas fa-xl fa-minus text-secondary'],
    ['Updating', 'fas fa-xl fa-solid fa-spinner fa-spin text-info'],
    ['Awaiting Sync', 'fas fa-xl fa-ellipsis-h text-danger'],
    ['Not activated', 'fas fa-xl fa-question-circle text-secondary'],
  ]);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['matrixSummaryFilters'] &&
      changes['matrixSummaryFilters'].currentValue !==
        changes['matrixSummaryFilters'].previousValue &&
      changes['matrixSummaryFilters'].previousValue
    ) {
      this.loadingData = true;
      this.activeTab = 1;
      this.loadMatrixData();
    }
  }

  orgUnitTypeForViewing = this.orgUnitService
    .getOrgUnitTypesForViewing()
    .pipe(takeUntilDestroyed(this.destroyRef));

  ngOnInit(): void {
    this.loadingData = true;

    this.activatedRoute.data
      .pipe(
        tap((data) => (this.matrixType = data.matrixType)),
        switchMap(() => {
          return this.authService.loggedInUserSubj;
        }),
      )
      .subscribe((liu: LoggedInUser) => {
        if (liu) {
          this.fullFunc = [
            SecurityRoleKey.Admin,
            SecurityRoleKey.Superuser,
            SecurityRoleKey.OuResponsible,
          ].some((sr) => liu.roleIdentifier === sr);
          this.isManager = liu.isManager;
          this.loadMatrixData();
        }
      });
  }

  tabContentLength(tab: MatrixTab) {
    return tab.groupedSkills.length + tab.ungroupedSkills.length;
  }

  openKeyModal() {
    const modelRef = this.ngbModal.open(KeyModalComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    modelRef.componentInstance.stateSymbolMap = this.stateSymbolMap;
  }

  updateMatrix(matrixFilters: {
    orgUnitId: number;
    additionalPeopleIds: number[];
  }) {
    this.selectedOrgUnitId = matrixFilters.orgUnitId;
    this.additionalPeopleIds = matrixFilters.additionalPeopleIds;
    this.loadingData = true;
    this.loadMatrixData();
  }

  loadMatrixData() {
    this.activeTab = 1;
    this.showUnassignedUsers = false;
    const matrixReq: MatrixPayload = {
      orgUnitId: this.matrixSummaryFilters
        ? this.matrixSummaryFilters.orgUnitId
        : this.selectedOrgUnitId,
      subjectId: this.matrixSummaryFilters
        ? this.matrixSummaryFilters.subjectId
        : this.selectedSubjectId,
      extraPersonIds: this.additionalPeopleIds,
      excludeLoggedInUser: this.isForSummary,
    };

    this.matrixService
      .getMatrixByType(matrixReq, this.matrixType)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (md: Matrix) => {
          const tabData = Object.values(md.tabs);

          this.matrixTabData = tabData;
          const people = Object.values(md.people);

          const adPeople = people.filter((p) => p.isAdditional);
          const nonAdPeople = people.filter((p) => !p.isAdditional);
          const sortedPeople: {}[] = [];

          md.personIdOrder.forEach((id) => {
            const person = nonAdPeople.find((p) => p.id === id);
            if (person) {
              sortedPeople.push(person);
            }
          });

          this.matrixPeople = [...sortedPeople, ...adPeople];
          this.loadingData = false;
        },
        error: (err: HttpErrorResponse) => {
          this.loadingData = false;
          this.alertService.createAlert2(this.exceptionData.MATRIX_DATA, err);
        },
      });
  }

  //When passing a subject id, data is grouped up into one tab, so we only need the first tab in the array
  get matrixForSummary(): MatrixTab {
    return this.matrixTabData[0];
  }

  updateUnassignedUsersVisibility(show: boolean) {
    this.showUnassignedUsers = show;
  }
}
