import { Component, Input, OnChanges, inject } from '@angular/core';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { OrgUnitService } from '../services/org-unit.service';

import { OrgUnit } from '../models/org-unit';

@Component({
  selector: 'ug-org-unit-detail',
  templateUrl: './org-unit-detail.component.html',
  styleUrls: [],
})
export class OrgUnitDetailComponent implements OnChanges {
  private alertService = inject(AlertService);
  private orgUnitService = inject(OrgUnitService);

  private _id: number;
  @Input()
  set orgUnitId(id: number) {
    this._id = id;
  }
  get orgUnitId() {
    return this._id;
  }
  orgUnit = new OrgUnit();

  exceptionData = {
    ORG_UNIT: {
      level: AlertLevels.ERROR,
      code: 'OUD-001',
      message: 'Error retrieving org unit detail',
    } as AlertData,
  };

  ngOnChanges() {
    console.log('In org-unit onInit, orgUnitId=' + this.orgUnitId);
    if (!this.orgUnitId) {
      return;
    }
    this.orgUnitService.getOrgUnit(this.orgUnitId).subscribe(
      (orgUnit: OrgUnit) => (this.orgUnit = orgUnit),
      (err) => {
        this.alertService.createAlert2(this.exceptionData.ORG_UNIT, err);
      },
      () => console.log('Got OrgUnit'),
    );
  }
}
