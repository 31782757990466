<div [formGroup]="formGroup">
  <h3 class="h5 mb-0">{{ controlTitle }}</h3>
  <p class="translated-paragraph">{{ controlText }}</p>
  <div class="row d-flex align-items-top my-3">
    <div *ngIf="fieldOptions?.size" class="col-4">
      <div>
        <select
          [ngClass]="{ 'is-invalid': formGroup.get('field').invalid }"
          class="form-select rounded-end"
          formControlName="field"
          aria-label="Date From"
        >
          <option
            *ngFor="let opt of fieldOptions | keyvalue; let i = index"
            [value]="opt.key"
          >
            {{ opt.value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="input-group rounded-end">
        <input
          [ngClass]="{ 'is-invalid': formGroup.get('value').invalid }"
          class="form-control"
          type="number"
          min="1"
          formControlName="value"
          aria-label="Due date value"
        />
        <select
          [ngClass]="{ 'is-invalid': formGroup.get('unit').invalid }"
          class="form-select rounded-end"
          formControlName="unit"
          aria-label="Due date unit"
        >
          <option
            *ngFor="let opt of unitOptions | keyvalue; let j = index"
            [ngValue]="opt.key"
          >
            {{ opt.value }}
          </option>
        </select>
        <div *ngIf="formGroup.get('value').invalid" class="invalid-feedback">
          Due date value must be greater than 0
        </div>
      </div>
    </div>
  </div>
  <p *ngIf="hireDateConfigurationSummary && fieldOptions">
    Based on the above, users are considered as 'new hires' within
    <span class="fw-bold">{{
      formGroup.get('value').value +
        ' ' +
        unitOptions.get(formGroup.get('unit').value)
    }}</span>
    of their
    <span class="fw-bold">{{
      fieldOptions.get(formGroup.get('field').value)
    }}</span>
  </p>
  <p *ngIf="!fieldOptions?.size" class="text-danger">
    Warning: Amending this setting will only impact future assignments once
    saved
  </p>
</div>
