<div class="col-12 mb-3">
  <div [formGroup]="parentFormGroup">
    <div class="d-flex justify-content-between">
      <div class="mb-3">
        <p class="form-label">Expires in</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="1m"
            id="1m"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="expiresIn"
            [value]="1"
            (ngModelChange)="updateExpiryDate($event)"
            [disabled]="expiryDate.disabled"
          />
          <label class="form-check-label" for="1m"> 1 month </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="6m"
            id="6m"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="expiresIn"
            [value]="6"
            (ngModelChange)="updateExpiryDate($event)"
            [disabled]="expiryDate.disabled"
          />
          <label class="form-check-label" for="6m"> 6 months </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="12m"
            id="12m"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="expiresIn"
            [value]="12"
            (ngModelChange)="updateExpiryDate($event)"
            [disabled]="expiryDate.disabled"
          />
          <label class="form-check-label" for="12m"> 1 year </label>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <label for="expiryDate" class="form-label">Expiry Date</label>
      <div class="input-group">
        <input
          class="form-control"
          type="date"
          formControlName="expiryDate"
          id="expiryDate"
          title="Expiry Date"
          [ngClass]="{ 'is-invalid': expiryDate?.invalid }"
        />
        <div class="invalid-feedback" *ngIf="isExpiryDateRequired()">
          Expiry Date is required
        </div>
        <div class="invalid-feedback" *ngIf="isExpiryDateInPast()">
          Expiry date cannot be in the past
        </div>
      </div>
    </div>
  </div>
</div>
