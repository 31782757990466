<!-- 
<p>perf-summary works!</p>
<p>{{perfsummary | json}}</p>
 -->
<!-- <ug-perf-grid *ngIf="!isLoading" [perfGridContent]="perfsummary"></ug-perf-grid> -->

<!-- <p *ngFor="let r of regionData">{{r.name}}</p>
<p *ngFor="let r of siteData">{{r.name}}</p>
<p *ngFor="let r of subSiteData">{{r.name}}</p> -->

<!-- 
<div class="card">
  <div class="card-header bg-light">Performance Management Demo</div>
  <div class="card-body">
    <form [formGroup]="roleSelectForm" class="form">
      <div class="row">
          <div class="col-4">
              <label class="my-1 me-2" for="role">Demo Settings: Role</label>
              <select class="form-select" formControlName="role">
                <option [value]="0">Sales Director</option>
                <option [value]="1">Sales Leader</option>
                <option [value]="2">Deputy Sales Leader</option>
                <option [value]="3">Sales Manager</option>
                <option [value]="4">Sales Agent</option>
              </select>
          </div>
        </div>
    </form>
</div>
 -->

<div class="card" [ngClass]="{ 'h-100': false }" id="regions">
  <div class="card-body">
    <h5 class="card-title">Regions</h5>
    <!-- <ug-list-status [isLoading]="loadingData" [listLength]="regionData.length" [emptyListMessage]="'No data found'" ></ug-list-status> -->
    <div class="card-group justify-content-center">
      <div *ngFor="let r of regionData; let i = index" class="">
        <div class="card mx-1" (click)="onRegionClick(r?.name, i)">
          <div class="card-body">
            <a [routerLink]="[]" class="btn shadow-none">
              <h6 class="card-title">{{ r?.name }}</h6>
              <p class="card-text">Region Score: {{ r?.totalScore }}</p>
              <p class="card-text" [ngClass]="categoryColour(r.totalScore)">
                <span class="fas fa-globe fa-4x"></span>
              </p>
              <!-- <ug-chart-donut [chartId]="safeCardTitle + 'StatusSummaryPie' + i" [chartData]="pieChartData.data" [ringRadius]="donutRadius"></ug-chart-donut> -->
              <!-- {{pieChartData | json}} -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isSiteLoading"
  class="card"
  [ngClass]="{ 'h-100': false }"
  id="sites"
>
  <div class="card-body">
    <h5 class="card-title">Sites</h5>
    <!-- <ug-list-status [isLoading]="loadingData" [listLength]="regionData.length" [emptyListMessage]="'No data found'" ></ug-list-status> -->
    <div class="card-group justify-content-center">
      <div *ngFor="let r of siteData; let i = index" class="">
        <div class="card mx-1" (click)="onSiteClick(r?.userFirstName, i)">
          <div class="card-body">
            <a [routerLink]="[]" class="btn shadow-none">
              <h6 class="card-title">{{ r?.name }}</h6>
              <p class="card-text">Site Score: {{ r?.totalScore }}</p>
              <p class="card-text" [ngClass]="categoryColour(r.totalScore)">
                <span class="fas fa-city fa-4x"></span>
              </p>
              <!-- <p class="card-text">{{r.totalScore}}</p> -->
              <!-- <ug-chart-donut [chartId]="safeCardTitle + 'StatusSummaryPie' + i" [chartData]="pieChartData.data" [ringRadius]="donutRadius"></ug-chart-donut> -->
              <!-- {{pieChartData | json}} -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isSubSiteLoading"
  class="card"
  [ngClass]="{ 'h-100': false }"
  id="subSites"
>
  <div class="card-body">
    <h5 class="card-title">Sub-sites</h5>
    <div class="card-group justify-content-center">
      <div *ngFor="let r of subSiteData; let i = index" class="">
        <div class="card mx-1" (click)="onSubSiteClick(r?.userFirstName, i)">
          <div class="card-body">
            <a [routerLink]="[]" class="btn shadow-none">
              <h6 class="card-title">{{ r?.name }}</h6>
              <p class="card-text">Sub-Site Score: {{ r?.totalScore }}</p>
              <p class="card-text" [ngClass]="categoryColour(r.totalScore)">
                <span class="fas fa-building fa-4x"></span>
              </p>
              <!-- <p class="card-text">{{r | json}}</p> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isTeamsLoading"
  class="card"
  [ngClass]="{ 'h-100': false }"
  id="teams"
>
  <div class="card-body">
    <h5 class="card-title">Teams</h5>
    <div class="card-group justify-content-center">
      <div *ngFor="let r of teamData; let i = index" class="">
        <div class="card mx-1" (click)="onTeamClick(r?.name, i)">
          <div class="card-body">
            <a [routerLink]="[]" class="btn shadow-none">
              <h6 class="card-title">{{ r?.name }}</h6>
              <p class="card-text">Team Score: {{ r?.totalScore }}</p>
              <p class="card-text" [ngClass]="categoryColour(r.totalScore)">
                <span class="fas fa-users fa-4x"></span>
              </p>
              <!-- <p class="card-text">{{r | json}}</p> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isAgentsLoading"
  class="card"
  [ngClass]="{ 'h-100': false }"
  id="agents"
>
  <div class="card-body">
    <h5 class="card-title">Agents</h5>
    <div class="card-group justify-content-center">
      <div *ngFor="let r of agentData; let i = index" class="">
        <div class="card mx-1" (click)="onAgentClick(r, i)">
          <div class="card-body">
            <a [routerLink]="[]" class="btn shadow-none">
              <h6 class="card-title">
                {{ r?.userFirstName }} {{ r?.userLastName }}
              </h6>
              <p class="card-text">Agent Score: {{ r?.totalScore }}</p>
              <p class="card-text" [ngClass]="categoryColour(r.totalScore)">
                <span class="fas fa-user fa-4x"></span>
              </p>
              <!-- <p class="card-text">{{r | json}}</p> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isPerfGridLoading"
  class="card"
  [ngClass]="{ 'h-100': false }"
  id="perfGrid"
>
  <div class="card-body">
    <h5 class="card-title">
      {{ singleAgentData.userFirstName }} {{ singleAgentData.userLastName }}
    </h5>
    <!-- <ug-perf-grid [perfGridContent]="singleAgentData"></ug-perf-grid> -->
    <ug-perf-grid [perfGridArray]="perfData"></ug-perf-grid>
  </div>
</div>
