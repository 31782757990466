import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsCount } from '../../../dashboard/services/dashboard.service';
import { ChartConfig, ChartData } from '../../../echarts/chart-config';

@Component({
  selector: 'ug-analytics-chart',
  templateUrl: './analytics-chart.component.html',
  styleUrl: './analytics-chart.component.scss',
})
export class AnalyticsChartComponent implements OnInit {
  @Output() updateStatus: EventEmitter<number> = new EventEmitter<number>();

  private analyticsCountData: AnalyticsCount[];
  private chartData: ChartData[];
  private skillName: string;
  protected chartConfig: ChartConfig;

  @Input() set peopleVsClaimChartData(data: AnalyticsCount[]) {
    this.analyticsCountData = data;
    this.updateChart();
  }

  @Input() set selectedSkillName(name: string) {
    this.skillName = name;
  }

  analyticsChartColourMap = new Map<number, string>([
    [10, '#17b9a7'],
    [20, '#ef432e'],
    [30, '#f7931d'],
    [40, '#90439a'],
    [50, '#90439a'],
    [90, '#B2BEB5'],
  ]);

  ngOnInit() {}

  private updateChart() {
    this.chartData = this.analyticsCountData
      .filter((item) => item.count)
      .map((item) => {
        return {
          name: item.analyticStatus,
          value: item.count,
          colour: this.analyticsChartColourMap.get(item.analyticStatusId),
          id: item.analyticStatusId,
        };
      });

    this.chartConfig = {
      title: this.skillName,
      subTitle: 'People Count vs Status',
      type: 'pie',
      data: this.chartData,
      chartClickCallback: this.pointClicked,
      disableHideItemOnLegendClick: true,
      legendClickCallback: this.legendClicked,
      sort: true,
      disableAnimation: true,
      tooltipFormatter: this.pieToolTipFormatter,
      legendPosition: 'bottom',
    };
  }

  private legendClicked = ($event) => {
    const data = this.chartConfig.data;
    const item = data.find((item) => item.name === $event.name);
    item.colour =
      item.colour.substring(7) === '4D'
        ? item.colour.substring(0, 7)
        : item.colour + '4D';

    this.chartConfig = {
      ...this.chartConfig,
      data: data,
    };

    this.updateStatus.emit(item.id);
  };

  private pointClicked = ($event) => {
    const data = this.chartConfig.data;
    const item = data.find((item) => item.id === $event.data.id);
    item.colour =
      item.colour.substring(7) === '4D'
        ? item.colour.substring(0, 7)
        : item.colour + '4D';

    this.chartConfig = {
      ...this.chartConfig,
      data: data,
    };
    this.updateStatus.emit(item.id);
  };

  toggleGraphType() {
    if (this.chartConfig.type === 'pie') {
      this.chartConfig = {
        ...this.chartConfig,
        type: 'bar',
        tooltipFormatter: this.barToolTipFormatter,
        xAxisKey: 'name',
        yAxis: { name: 'Count' },
      };
    } else {
      this.chartConfig = {
        ...this.chartConfig,
        type: 'pie',
        tooltipFormatter: this.pieToolTipFormatter,
      };
    }
  }

  private pieToolTipFormatter = ($event) => {
    return `<b>${$event.name}</b> ${$event.value} (${Math.round(
      $event.percent,
    )}%)`;
  };

  private barToolTipFormatter = ($event) => {
    return `<b>${$event.name}</b> ${$event.value}`;
  };
}
