import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
} from '@azure/msal-browser';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { AuthenticationConfigNames } from '../authenticationConfigNames';
import { ExceptionTypes } from '../exception/exception.component';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'ug-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginPageMessage =
    environment?.loginPageMessage || 'Welcome to your eSQEP Portal';
  authSetup: any;
  authenticationConfigNames: typeof AuthenticationConfigNames =
    AuthenticationConfigNames;

  constructor(
    public authService: AuthService,
    private router: Router,
    @Optional() private msalService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,
    @Optional()
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    @Optional() @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public configService: ConfigService,
  ) {
    // redirect to home if already logged in
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    this.authSetup = this.configService.authSetup;
  }

  async loginEntra() {
    localStorage.setItem(
      'esqep.authType',
      this.authenticationConfigNames.ENTRA,
    );

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        await this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            const allowedTenants =
              this.authSetup[this.authenticationConfigNames.ENTRA]
                .allowedAzureTenants;
            if (allowedTenants.includes(response.account.idTokenClaims.tid)) {
              this.msalService.instance.setActiveAccount(response.account);
              this.authService.authenticationStatusSubj.next(true);
              this.router.navigate(['/home']);
            } else {
              this.router.navigate([
                'exception',
                ExceptionTypes[ExceptionTypes.NOT_CUSTOMER],
              ]);
            }
          });
      } else {
        await this.msalService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            const allowedTenants =
              this.authSetup[this.authenticationConfigNames.ENTRA]
                .allowedAzureTenants;
            if (allowedTenants.includes(response.account.idTokenClaims.tid)) {
              this.msalService.instance.setActiveAccount(response.account);
              this.authService.authenticationStatusSubj.next(true);
              this.router.navigate(['/home']);
            } else {
              this.router.navigate([
                'exception',
                ExceptionTypes[ExceptionTypes.NOT_CUSTOMER],
              ]);
            }
          });
      }
    }
  }

  loginCsodSaml() {
    localStorage.setItem('esqep.authType', this.authenticationConfigNames.CSOD);
    this.authService.getSsoRedirect().subscribe((s) => {
      if (s.errorMessage) {
        this.router.navigate([
          'exception',
          ExceptionTypes[ExceptionTypes.NOT_CUSTOMER],
        ]);
      } else {
        window.location.href = s.url;
      }
    });
  }

  async loginOkta() {
    if (this.configService.authSetup[this.authenticationConfigNames.OKTA]) {
      localStorage.setItem(
        'esqep.authType',
        this.authenticationConfigNames.OKTA,
      );
      await this.oktaAuth
        .signInWithRedirect()
        .then((_) => this.router.navigate(['/home']));
    }
  }
}
