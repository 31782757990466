<div class="card mb-3">
  <div class="card-body">
    <h5 class="card-title">Discussion</h5>
    <!-- <div *ngFor="let statement of claimEvidence.slice(eviListBeginOffset)"> -->
    <div *ngFor="let statement of claimEvidenceList">
      <div
        [ngClass]="{
          'col-10': true,
          'offset-2': !speechBubbleLeft(claimantView, statement?.initiatorType)
        }"
      >
        <div
          class="card mb-2 esqep-speech-bubble"
          [ngClass]="speechBubbleClass(claimantView, statement?.initiatorType)"
        >
          <div class="card-header">
            <span class="far fa-comment"></span>
            <strong
              >{{
                statement?.initiatorType === 'A' ? 'Assessor' : 'Claimant'
              }}:</strong
            >
            {{ statement?.updatePersonFamilyGivenName }}
            <span class="float-end">{{
              statement?.updateDatetime | date: 'medium'
            }}</span>
          </div>
          <div class="card-body">{{ statement?.description }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
