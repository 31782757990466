<div class="card">
  <div class="card-body">
    <h4 class="card-title">Organisation Units</h4>
    <h6 class="card-subtitle">
      Org Units assigned to this role ({{
        currentOrgUnitsInUse.length | i18nPlural: orgUnitCountMessageMap
      }})
    </h6>

    <div [hidden]="currentOrgUnitsInUse.length === 0">
      <table class="table table-responsive">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Name</th>
            <th scope="col">Action</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let ou of currentOrgUnitsInUse;
              let i = index;
              trackBy: trackByFn
            "
          >
            <tr [ngClass]="ouIndex === i ? 'bg-light' : null">
              <td>
                {{ ou.orgUnitTypeName }}
              </td>
              <td>
                {{ ou.name }}
              </td>
              <td>
                <div *ngIf="ou.pendingDeletion">
                  <span class="alert alert-danger p-1"> Pending Removal</span>
                </div>
                <ng-container *ngIf="allowEdit && !ou.pendingDeletion">
                  <button
                    *ngIf="!ou.actionType"
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    (click)="onRemoveOuClick(ou.id)"
                  >
                    Remove
                  </button>
                  <button
                    *ngIf="
                      ou.actionType &&
                      ou.actionType === OrgUnitActionType.DISCARD
                    "
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    (click)="onDiscardOuClick(ou.id)"
                  >
                    Discard
                  </button>
                  <button
                    *ngIf="
                      ou.actionType &&
                      ou.actionType === OrgUnitActionType.RESTORE
                    "
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    (click)="onRestoreOuClick(ou.id)"
                  >
                    Restore
                  </button>
                </ng-container>
              </td>
              <td>
                <div class="w-100 text-center mt-1">
                  <i
                    class="me-2 pointer"
                    (click)="toggleOu(i, ou)"
                    [ngClass]="
                      ouIndex === i
                        ? 'fa fa-chevron-down'
                        : 'fa fa-chevron-right'
                    "
                  ></i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="p-0 m-0">
                <div [ngbCollapse]="ouIndex != i" class="my-1 card m-auto">
                  <div class="card-body">
                    <ug-list-status
                      [isLoading]="ouPeopleIsLoading"
                      [listLength]="currentOrgUnitsInUse.length"
                    ></ug-list-status>
                    <div *ngIf="!ouPeopleIsLoading">
                      <h6 class="card-title">
                        People in {{ ou?.name }} ({{ ouPeopleList.length }})
                      </h6>
                      <div class="fixed-table-height">
                        <table class="table" *ngIf="ouPeopleList.length">
                          <thead>
                            <tr class="info">
                              <th scope="col">Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let person of ouPeopleList; let j = index"
                            >
                              <td>{{ person.displayName }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
