import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  BulkAssessmentResponse,
  BulkAssessmentResponseSkill,
  EventsService,
} from '../services/events.service';

@Component({
  selector: 'ug-bulk-assessment-completed',
  templateUrl: './bulk-assessment-completed.component.html',
  styleUrls: ['./bulk-assessment-completed.component.scss'],
})
export class BulkAssessmentCompletedComponent implements OnInit {
  @Input() trainingSessionId!: number;
  private destroyRef = inject(DestroyRef);
  private eventsService = inject(EventsService);
  private alertService = inject(AlertService);

  protected bulkAssessment!: BulkAssessmentResponse;
  protected bulkAssessmentSkills: Array<BulkAssessmentResponseSkill> = [];
  protected noAssessmentAvailable = false;
  protected isLoading!: boolean;

  ngOnInit(): void {
    this.isLoading = true;
    this.eventsService
      .getBulkAssessment(this.trainingSessionId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ba: BulkAssessmentResponse) => {
          this.bulkAssessment = ba;
          this.bulkAssessmentSkills =
            ba.people.find((p) => p.skills && p.skills.length > 0)?.skills ||
            [];
          this.isLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'BAC-001',
              message: 'Error getting bulk assessment history',
            } as AlertData,
            err,
          );
        },
      });
  }
}
