import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import moment from 'moment';
import { Subscription, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { SpinnerService } from './controls/services/spinner.service';
import { AlertData, AlertLevels } from './core/services/alert.service';
import { AuthService } from './core/services/auth.service';
import { ConfigService } from './core/services/config.service';

import { Title } from '@angular/platform-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { ClaimService } from './claim/services/claim.service';
import { AuthenticationConfigNames } from './core/authenticationConfigNames';
import { NavbarComponent } from './core/navbars/navbar/navbar.component';
import { InactivityTimeoutService } from './core/services/inactivity-timeout.service';
import { UitoolsService } from './core/services/uitools.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy, OnInit {
  @ViewChild('inactivityWarningModal') inactivityWarningModal: ElementRef;

  authenticationConfigNames: typeof AuthenticationConfigNames =
    AuthenticationConfigNames;

  displayContent: boolean = false;
  title: string;
  appVersion: string;
  logging: any;
  isSignedIn: boolean = false;
  loading: boolean = false;
  inactiveTime = 0;
  timeoutModalVisible: boolean = false;

  exceptionData = {
    PERSON_UPN: {
      level: AlertLevels.ERROR,
      code: 'APC-001',
      message: 'Unable to retrieve user data',
    } as AlertData,
    USER_GROUP: {
      level: AlertLevels.ERROR,
      code: 'APC-003',
      message: 'Unable to retrieve user group info',
    } as AlertData,
    ADMIN_STATUS: {
      level: AlertLevels.ERROR,
      code: 'APC-004',
      message: 'Unable to retrieve user role info',
    } as AlertData,
    PERSON_UUID: {
      level: AlertLevels.ERROR,
      code: 'APC-005',
      message: 'Unable to retrieve user data',
    } as AlertData,
    SECURITY_ROLE: {
      level: AlertLevels.ERROR,
      code: 'APC-006',
      message: 'Unable to retrieve user security role',
    } as AlertData,
    PERSON_PHOTO: {
      level: AlertLevels.WARNING,
      code: 'APC-007',
      message: 'Unable to retrieve user photo',
    } as AlertData,
  };

  loginPageMessage =
    environment?.loginPageMessage || 'Welcome to your eSQEP Portal';
  authStatusSubs = new Subscription();
  personByUUIDSubs = new Subscription();
  userGroupSubs = new Subscription();
  appSubscriptions = new Subscription();
  inactivitySubs = new Subscription();

  copyrightDate = Date.now();
  isIframe: boolean = false;
  helpUrl: string;
  navigationSubscription: Subscription;
  navCollapsed: boolean;
  offCanvasOpen = false;
  navType: string = 'sideNav';
  offCanvasRef: NgbOffcanvasRef;

  private offcanvasService = inject(NgbOffcanvas);
  private authService = inject(AuthService);
  private configService = inject(ConfigService);
  private router = inject(Router);
  private spinnerService = inject(SpinnerService);
  private uiService = inject(UitoolsService);
  private activatedRoute = inject(ActivatedRoute);
  private inactivityTimeoutService = inject(InactivityTimeoutService);
  private titleService = inject(Title);
  private claimService = inject(ClaimService);
  private broadcastService = inject(MsalBroadcastService, { optional: true });
  private oktaStateService = inject(OktaAuthStateService, { optional: true });
  private oktaAuth = inject(OKTA_AUTH, { optional: true });

  constructor() {
    this.setupNavigationListener();

    this.inactivitySubs =
      this.inactivityTimeoutService.inactiveSecondsCount.subscribe(
        (inactiveSeconds) => {
          this.inactiveTime = inactiveSeconds;
          if (
            !this.timeoutModalVisible &&
            this.inactiveTime >
              this.configService.inactivity_timeout_warning_seconds
          ) {
            this.timeoutModalVisible = true;
            this.uiService.openModalStatic(this.inactivityWarningModal);
            this.inactivityTimeoutService.unsubscribeIdleSub();
          }
          if (
            this.inactiveTime >
            this.configService.inactivity_timeout_logout_seconds
          ) {
            this.authService.timeoutLogout();
            this.inactivityTimeoutService.inactiveSecondsCount.unsubscribe();
          }
        },
      );
  }

  get spinnerInProgressUrls(): string {
    return this.spinnerService.inProgressUrls();
  }

  get inUserGroup(): boolean {
    return this.authService.inUserGroup;
  }

  get hasAdminRole(): boolean {
    return this.authService.hasAdminRole;
  }

  get inactivityMessage1(): string {
    return `No activity has been detected for ${this.getInactiveTimeDisplay(
      this.inactiveTime,
    )}`;
  }

  get inactivityMessage2(): string {
    let inactivityMessage = null;
    if (
      this.inactiveTime >
      this.configService.inactivity_timeout_logout_seconds -
        this.configService.inactivity_timeout_countdown_seconds
    ) {
      const timeUntilLogout =
        this.configService.inactivity_timeout_logout_seconds -
        this.inactiveTime;
      const timeUntilLogoutDisplay =
        timeUntilLogout > 60
          ? this.getInactiveTimeDisplay(timeUntilLogout)
          : Math.max(0, timeUntilLogout) + ' seconds';
      inactivityMessage = `You will be automatically logged out in ${timeUntilLogoutDisplay}`;
    }
    return inactivityMessage;
  }

  get clientLogoAsset(): string {
    return this.configService.clientLogoAsset;
  }

  get navbarBgClass(): string {
    return this.configService.navbarBgClass;
  }

  clog = (msg: string, lvl: string | number) => {
    this.configService.logging.newMsg(msg, lvl);
  };

  toggleMenu(state?: boolean) {
    if (state === undefined) {
      this.navCollapsed = !this.navCollapsed;
    } else {
      this.navCollapsed = state;
    }
  }

  async ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((url: NavigationEnd) => {
        if (url.url === '/home') {
          this.toggleMenu(true);
        } else {
          this.toggleMenu(false);
        }
      });

    this.isIframe = window !== window.parent && !window.opener;

    if (window !== window.parent && !window.opener) {
      this.clog('In iframe', 'INFO'); // For token refresh purposes, prevent multiple iframes from being created
    } else {
      this.spinnerService.reset();
      this.logging = this.configService.logging;
      this.appVersion = this.configService.appVersion;

      if (
        this.configService.authSetup[this.authenticationConfigNames.ENTRA]
          .enabled
      ) {
        await this.authService.msalService.instance.initialize();
        await this.authService.msalService.instance.handleRedirectPromise();

        this.broadcastService.msalSubject$.pipe().subscribe((result) => {
          if (result.eventType === EventType.LOGIN_FAILURE) {
            this.router.navigate(['/login']);
          }

          this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((url: NavigationEnd) => {
              if (url.url === '/') {
                // we need this because msal:loginFailure triggers Router Event: NavigationCancel with url set to be '/'
                // (msal post logout url is the domain without the '/home')
                this.router.navigate(['/home']);
              }

              window.scrollTo(0, 0);
            });
        });
        this.broadcastService.inProgress$
          .pipe(
            filter(
              (status: InteractionStatus) => status === InteractionStatus.None,
            ),
          )
          .subscribe((s) => {
            if (
              localStorage.getItem('esqep.authType') ===
              this.authenticationConfigNames.ENTRA
            ) {
              this.authService.isAuthenticated =
                this.authService.msalService.instance.getAllAccounts().length >
                0;
            }
          });
      }

      if (
        this.configService.authSetup[this.authenticationConfigNames.OKTA]
          .enabled &&
        localStorage.getItem('esqep.authType') ===
          this.authenticationConfigNames.OKTA
      ) {
        this.oktaStateService.authState$
          .pipe(
            filter((s: AuthState) => !!s),
            map((s: AuthState) => s.isAuthenticated ?? false),
          )
          .subscribe((s) => {
            if (s) {
              this.authService.isAuthenticated = true;
            }
          });
      }
      this.authStatusSubs = this.authService.authenticationStatusSubj.subscribe(
        (a) => {
          if (
            this.configService.authSetup[
              this.authenticationConfigNames.ENTRA
            ] &&
            this.configService.authSetup[this.authenticationConfigNames.ENTRA]
              .enabled
          ) {
            this.isSignedIn =
              a ||
              this.authService.msalService.instance.getAllAccounts().length > 0;
          } else {
            this.isSignedIn = a;
          }
          this.clog('Auth Status:' + this.isSignedIn, 'VERBOSE');
          this.inactivityTimeoutService.startWatch();
          this.inactivityTimeoutService.startIdleWatch.next(a);
          if (this.authService.isAuthenticated) {
            this.authService.getLoggedInUser().subscribe((u) => {
              this.authService.userInfo = u;
              this.authService.storeUserPersonDetail(u);
              this.appSubscriptions.add(
                this.authService.populateSecurityRoles(),
              );
            });
          }
        },
      );
      this.displayContent = true;
    }

    this.authService.loggedInUserSubj.subscribe((usd) => {
      if (usd) {
        this.claimService.getClaimActivitySummary(usd.id).subscribe({
          next: (cas) => {
            if (cas) {
              this.claimService.sharedClaimActivitySummary.next(cas);
            }
          },
        });
      }
    });
  }

  ngOnDestroy() {
    this.authStatusSubs.unsubscribe();
    this.personByUUIDSubs.unsubscribe();
    this.userGroupSubs.unsubscribe();
    this.appSubscriptions.unsubscribe();
    this.inactivitySubs.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  onRouterOutletActivate(event: any) {
    this.configService.componentName = event.constructor.name;
  }

  logout() {
    this.authService.logout();
  }
  onInactivityDismiss() {
    this.authService.authTimeoutSubj.next(true);
    this.timeoutModalVisible = false;
    this.inactivityTimeoutService.watchForUserIdle();
  }

  getInactiveTimeDisplay(inactiveSeconds: number): string {
    return moment.duration(inactiveSeconds, 'seconds').humanize();
  }

  getTimeoutModalHeaderStyle(inactiveSeconds: number): string {
    return inactiveSeconds >
      this.configService.inactivity_timeout_logout_seconds -
        this.configService.inactivity_timeout_countdown_seconds
      ? 'bg-danger text-light'
      : 'bg-warning text-dark';
  }

  getPageRouteData(): {
    title?: string;
    itemTranslationKey?: string;
    helpUrl?: string;
  } {
    let child = this.activatedRoute.firstChild;
    if (!child) {
      return this.activatedRoute.snapshot.data;
    }
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data.title) {
      return child.snapshot.data;
    }
    return null;
  }

  private setupNavigationListener() {
    this.navigationSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
        tap((event) => (this.loading = event instanceof NavigationStart)),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getPageRouteData()),
        switchMap((routeData) => {
          // removed whislt resetting the translation stuff
          // if (routeData?.itemTranslationKey) {
          //   return this.translocoService
          //     .selectTranslate(routeData.itemTranslationKey)
          //     .pipe(map((title) => ({ ...routeData, title })));
          // } else {
          return of({ ...routeData });
          // }
        }),
        startWith(null),
      )
      .subscribe((data) => {
        this.title = data?.title ?? null;
        this.titleService.setTitle(this.title ?? 'eSQEP');
        this.helpUrl = data?.helpUrl ?? null;
      });
  }

  toggleOffCanvasMenu() {
    this.navCollapsed = true;
    this.offCanvasOpen = !this.offCanvasOpen;
    if (this.offCanvasOpen) {
      this.offCanvasRef = this.offcanvasService.open(NavbarComponent);
      this.offCanvasRef.componentInstance.navType = 'offCanvas';
    } else {
      this.offCanvasRef.close();
    }
    this.offCanvasRef.dismissed.subscribe((d) => {
      this.offCanvasOpen = false;
    });
  }
}
