import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import {
  NgbCollapseModule,
  NgbToastModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { CompetenciesRoutingModule } from './competencies-routing.module';
import { AssignmentModalComponent } from './competency-hierarchy/assignment-modal/assignment-modal.component';
import { CompetencyHierarchyComponent } from './competency-hierarchy/competency-hierarchy.component';
import { SubjectModalComponent } from './competency-hierarchy/subject-modal/subject-modal.component';
import { CompetencyManagementComponent } from './competency-management/competency-management.component';
import { CompetencyComponent } from './v2/competency/competency.component';
import { DimensionButtonsComponent } from './v2/competency/components/dimension-buttons/dimension-buttons.component';
import { SkillAssignmentModalComponent } from './v2/competency/components/skill-assignment-modal/skill-assignment-modal.component';
import { SkillsubjectComponent } from './v2/competency/components/skillsubject/skillsubject.component';
import { SubjectAssignedSkillsComponent } from './v2/competency/components/subject-assigned-skills/subject-assigned-skills.component';
@NgModule({
  declarations: [
    CompetencyManagementComponent,
    CompetencyHierarchyComponent,
    SubjectModalComponent,
    AssignmentModalComponent,
    CompetencyComponent,
    SkillsubjectComponent,
    DimensionButtonsComponent,
    DimensionButtonsComponent,
    SkillAssignmentModalComponent,
    SubjectAssignedSkillsComponent,
  ],
  imports: [
    CompetenciesRoutingModule,
    ControlsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbToastModule,
    NgbCollapseModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    NgbTooltip,
  ],
  exports: [KeyValuePipe],
})
export class CompetenciesModule {}
