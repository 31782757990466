import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CompetenciesService,
  SkillSubject,
} from '../../../competencies/services/competencies.service';
import { TextboxFilter } from '../../../controls/dynamic-form/control-types/filter-textbox';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../controls/table/table.service';
import { SkillFilterPayload } from '../../../skill/services/skill.service';

@Component({
  selector: 'ug-assignment-modal',
  templateUrl: './assignment-modal.component.html',
  styleUrls: ['./assignment-modal.component.scss'],
})
export class AssignmentModalComponent implements OnInit {
  private competencyService = inject(CompetenciesService);
  modal = inject(NgbActiveModal);

  @Input() selectedDimension;
  @Input() subject: SkillSubject;
  tableLoading = true;
  tableHeaders: Array<TableHeader>;
  tableSelectedButtons: Array<TableSelectedButton>;
  filters = [
    new TextboxFilter({
      key: 'searchTerm',
      order: 1,
      placeholder: 'Search ...',
    }),
  ];
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();

  skillList = [];
  dimensionName = '';
  skillType = '';

  ngOnInit(): void {
    const filterData: SkillFilterPayload = {
      dimensionId: this.selectedDimension?.id,
      subjectId: null,
    };
    this.dimensionName = this.selectedDimension?.name;
    this.skillType =
      this.selectedDimension?.id === 4 ? 'Training' : 'Competency';
    this.tableHeaders = [
      { id: 'name', title: this.skillType, class: 'w-25' },
      { id: 'description', title: 'Description', class: 'w-50' },
      { id: 'assignedSubjects', title: 'Assigned To' },
    ];
    this.tableSelectedButtons = [
      {
        title: 'Update',
        class: 'btn-outline-success',
        function: this.updateSelected,
      },
    ];

    this.competencyService
      .getSkillsVsSubjectId(this.subject?.id, filterData)
      .subscribe((sk) => {
        this.skillList = sk;
        this.skillList.forEach((skill) => {
          const subjNames = skill.subjects.map((sj) => sj.name);
          skill['assignedSubjects'] = subjNames.join(', ');
        });

        this.tableLoading = false;
      });
  }

  updateSelected = (selected: any[]) => {
    this.saveClicked.emit(selected);
  };
}
