<form>
  <div [ngClass]="asRow ? 'row' : ''">
    <ng-container *ngFor="let filter of filters">
      <div *ngIf="filter.newRow" class="w-100 mb-3"></div>
      <ug-filter
        [filter]="filter"
        [form]="form"
        [rowIndex]="rowIndex"
        [ngClass]="
          filter.filterClass.indexOf('col') === -1 ? 'col' : filter.filterClass
        "
      >
      </ug-filter>
    </ng-container>
    <div
      *ngIf="filters?.length > 0 && showClearFilters"
      [ngClass]="asRow ? 'col align-self-end' : ''"
      class="mt-1"
    >
      <button class="btn btm-sm esqepBtn" (click)="clearFilters()">
        Clear Filters
      </button>
    </div>
  </div>
</form>
