<div class="card mt-3 p3">
  <div class="card-body">
    <h5 class="card-title">Document preview</h5>
    <!-- <div class="card-text">showDoc = {{showDoc}}; showImg = {{showImg}}; fileUrl = {{fileUrl}}</div> -->
    <div
      *ngIf="showDoc && !showImg"
      class="embed-responsive embed-responsive-4by3"
    >
      <iframe class="embed-responsive-item w-100" [src]="fileUrl">
        <a [href]="fileUrl" target="_blank">Click here to view document</a>
      </iframe>
    </div>
    <div *ngIf="showDoc && showImg">
      <img [src]="fileUrl" alt="Document url" class="img-fluid mx-auto" />
    </div>
    <div *ngIf="!showDoc && isMS">
      <div class="alert alert-info">
        <h5 class="alert-heading">
          <span class="fas fa-xl fa-info-circle"></span> Unable to display PDF
          document inline
        </h5>
        <p>The selected document will be opened in your default PDF viewer.</p>
        <p class="small">
          If you are using the Microsoft Edge browser your default PDF viewer
          may also be Edge. In this case choose Open to view the file in a new
          tab or Save to save to disk and then open with an alternative PDF
          viewer.
        </p>
        <hr />
        <p class="small italic">
          To view PDF documents inline use an alternative browser such as
          Firefox or Chrome
        </p>
      </div>
    </div>
  </div>
</div>
