import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService, SecurityRoleKey } from '../../core/services/auth.service';
import { ClaimService } from '../services/claim.service';

@Component({
  selector: 'esqep-claim-activity-summary', // tslint:disable-line: component-selector
  templateUrl: './claim-activity-summary.component.html',
  styleUrls: ['./claim-activity-summary.component.scss'],
})
export class ClaimActivitySummaryComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private claimService = inject(ClaimService);
  private router = inject(Router);

  claimActivitySummary: Array<any> = [];
  claimantActivitySummary: Array<any> = [];
  verifierActivitySummary: Array<any> = [];
  isLoading = true;

  categoryItemCountMessageMap = {
    '=0': '0 items',
    '=1': '1 item',
    other: '# items',
  };
  isMinSrAssessor: boolean;

  ngOnInit() {
    this.claimService.sharedClaimActivitySummary.subscribe((cas) => {
      if (cas.length) {
        cas.sort((a, b) => {
          return a['categorySortOrder'] - b['categorySortOrder'];
        });
        this.claimantActivitySummary = [];
        this.verifierActivitySummary = [];
        this.claimActivitySummary = cas;
        this.claimActivitySummary.forEach((e, i) => {
          if (e['categoryForClaimant']) {
            this.claimantActivitySummary.push({ ...e, index: i });
          }
        });
        this.claimActivitySummary.forEach((e, i) => {
          if (!e['categoryForClaimant']) {
            this.verifierActivitySummary.push({ ...e, index: i });
          }
        });
        this.isLoading = false;
      }
    });

    combineLatest([
      this.authService.securityRoleLookupSubj,
      this.authService.loggedInUserSubj,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([srl, usd]) => {
          this.isMinSrAssessor =
            usd.securityLevel >= srl[SecurityRoleKey.Assessor].securityLevel;
        },
      });
  }

  onNewSubmissionClick() {
    this.router.navigate(['/assessment-request/new']);
    return false;
  }

  onActivitySummaryItemClick(itemIndex: number) {
    this.claimService.activityListData = this.claimActivitySummary[itemIndex];
    let route = '';
    if (this.claimActivitySummary[itemIndex]['categoryCode'] === 'PUBLISHED') {
      route = 'MY-ASSESSED';
    } else if (
      this.claimActivitySummary[itemIndex]['categoryCode'] === 'VERIFIED'
    ) {
      route = 'ASSESSED';
    } else if (
      this.claimActivitySummary[itemIndex]['categoryCode'] === 'VERIFY'
    ) {
      route = 'ASSESS';
    } else {
      route = this.claimActivitySummary[itemIndex]['categoryCode'];
    }
    this.router.navigate(['/submissions', route]);
    return false;
  }

  onRefreshClick() {
    this.claimActivitySummary = [];
    this.getClaimActivitySummary();
    return false;
  }

  getClaimActivitySummary() {
    this.isLoading = true;
    this.claimService
      .getClaimActivitySummary(this.authService.me.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cas: Array<any>) => {
        cas.sort((a, b) => {
          return a['categorySortOrder'] - b['categorySortOrder'];
        });
        this.claimantActivitySummary = [];
        this.verifierActivitySummary = [];
        this.claimActivitySummary = cas;
        this.claimActivitySummary.forEach((e, i) => {
          if (e['categoryForClaimant']) {
            this.claimantActivitySummary.push({ ...e, index: i });
          }
        });
        this.claimActivitySummary.forEach((e, i) => {
          if (!e['categoryForClaimant']) {
            this.verifierActivitySummary.push({ ...e, index: i });
          }
        });
        this.isLoading = false;
      });
  }
}
