<div class="card shadow">
  <div class="card-body">
    <!-- <h5 class="card-title">My Shared Links</h5> -->
    <div class="table-responsive">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Snapshot unique ID</th>
            <th scope="col">Snapshot timestamp</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let si of sharedInfoList">
            <td>Assessed Competencies</td>
            <th scope="row" nowrap>
              <a
                [href]="
                  sharedServerUri + '/' + si['publishedClaim']['uniqueId']
                "
                >{{ si['publishedClaim'].uniqueId }}</a
              >
            </th>
            <td nowrap>
              {{ si['publishedClaim'].createTimestamp | date: 'medium' }}
            </td>
            <td>
              <button
                class="btn btn-outline-danger btn-sm"
                [disabled]="
                  buttonStatus[si['publishedClaim'].sharedInfoId]?.disabled ||
                  false
                "
                (click)="onDeleteClick(si['publishedClaim'].sharedInfoId)"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Delete this shared link"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
