import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import {
  NgbCollapse,
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { JobRoleModule } from '../job-role/job-role.module';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { CurriculaSummaryComponent } from './curricula-summary/curricula-summary.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { PersonDetailNavbarComponent } from './person-detail-navbar/person-detail-navbar.component';
import { PersonDetailComponent } from './person-detail/person-detail.component';
import { PersonRoutingModule } from './person-routing.module';
import { PersonSecurityAccessComponent } from './person-security-access/person-security-access.component';
import { PersonSecurityRoleComponent } from './person-security-role/person-security-role.component';
import { PersonSummaryComponent } from './person-summary/person-summary.component';
import { PersonService } from './services/person.service';
import { SharedInfoListComponent } from './shared-info-list/shared-info-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    NgOptimizedImage,
    DashboardModule,
    ReactiveFormsModule,
    PersonRoutingModule,
    JobRoleModule,
    NgbCollapse,
    NgbTooltipModule,
    NgSelectModule,
    TranslocoModule,
    NgbPaginationModule,
  ],
  declarations: [
    PersonDetailComponent,
    PersonSummaryComponent,
    NotificationListComponent,
    SharedInfoListComponent,
    AssignmentDetailComponent,
    PersonSecurityRoleComponent,
    CurriculaSummaryComponent,
    PersonDetailNavbarComponent,
    PersonSecurityAccessComponent,
  ],
  providers: [PersonService],
  exports: [
    PersonDetailComponent,
    PersonSummaryComponent,
    NotificationListComponent,
    SharedInfoListComponent,
    AssignmentDetailComponent,
    PersonSecurityRoleComponent,
    CurriculaSummaryComponent,
    PersonDetailNavbarComponent,
  ],
})
export class PersonModule {}
