import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, OperatorFunction, Subject, takeUntil } from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import {
  JobRole,
  JobRoleService,
} from '../../job-role/services/job-role.service';
import { Person } from '../../person/models/person';
import { PersonService } from '../../person/services/person.service';
import { FuncArea } from '../../skill/models/func-area';
import { Skill } from '../../skill/models/skill';
import { SkillService } from '../../skill/services/skill.service';

@Component({
  selector: 'ug-roles-and-resources',
  templateUrl: './roles-and-resources.component.html',
  styleUrls: ['./roles-and-resources.component.scss'],
})
export class RolesAndResourcesComponent implements OnInit {
  private personService = inject(PersonService);
  private typeaheadService = inject(TypeaheadService);
  private jobRoleService = inject(JobRoleService);
  private skillService = inject(SkillService);

  @Input() parentFormGroup: FormGroup;
  @Input() activeParentTab: number;
  @ViewChild('nav2') nav;
  activeSubTabBS: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  @Input()
  set activeSubTab(value) {
    this.activeSubTabBS.next(value);
  }

  get activeSubTab() {
    return this.activeSubTabBS.getValue();
  }

  jobRoleList: Array<any>;
  jobRoleSearch: OperatorFunction<string, JobRole[]>;
  compDims: Array<FuncArea>;
  applicationSkillList: Array<Skill>;
  knowledgeSkillList: Array<Skill>;
  peopleList: Array<Person>;
  peopleSearch: OperatorFunction<string, Person[]>;
  private ngUnsubscribe: Subject<boolean> = new Subject();
  jrLoading: boolean;
  plLoading: boolean;

  static sortJobRole(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  static compareJobRole(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  static comparePeople(items, input: string) {
    return items.displayName.toLowerCase().includes(input);
  }

  static sortPeople(a, b, text: string) {
    return (
      a.displayName.startsWith(text) - b.displayName.startsWith(text) ||
      b.displayName - a.displayName
    );
  }

  ngOnInit(): void {
    this.jrLoading = true;
    this.plLoading = true;

    this.jobRoleService
      .getJobRolesDotNet()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((jrl) => {
        this.jobRoleList = jrl;
        this.jobRoleSearch = this.typeaheadService.typeahead(
          this.jobRoleList,
          RolesAndResourcesComponent.compareJobRole,
          RolesAndResourcesComponent.sortJobRole,
        );
        this.jrLoading = false;
        this.personService
          .getPersonList()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((pl) => {
            pl.map((p) => {
              p['location'] = 'London';
              p['activeProjects'] = Math.floor(Math.random() * 6);
              p['jobRole'] =
                this.jobRoleList[
                  Math.floor(Math.random() * this.jobRoleList.length)
                ].name;
            });
            this.peopleList = pl;
            this.peopleSearch = this.typeaheadService.typeahead(
              this.peopleList,
              RolesAndResourcesComponent.comparePeople,
              RolesAndResourcesComponent.sortPeople,
            );
            this.plLoading = true;
          });
      });

    this.skillService
      .getCompDimensions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dims) => {
        this.compDims = dims;
      });

    this.activeSubTabBS.subscribe((x) => {
      if (this.activeParentTab === 2) {
        this.nav.select(x);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
