import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuditData, AuditFilter } from '../../shared/audit-table/audit.service';
import {
  ICustomSkillWarning,
  ICustomSkillWarningPayload,
  IDefaultSkillWarningPayload,
  IPagedSkillSearch,
  ISkillSearchFilter,
  ISkillWarningSettings,
} from './skill-warning';

const dotNetServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class MatrixSettingsService {
  private http = inject(HttpClient);


  getSkillWarnings(): Observable<ISkillWarningSettings> {
    return this.http.get<ISkillWarningSettings>(
      `${dotNetServerUri}/skillWarnings`,
    );
  }

  saveDefaultSkillWarning(
    settings: IDefaultSkillWarningPayload,
  ): Observable<void> {
    return this.http.post<void>(
      `${dotNetServerUri}/skillWarnings/defaults/expiringAmber`,
      settings,
    );
  }

  getSkills(searchFilter: ISkillSearchFilter): Observable<IPagedSkillSearch> {
    return this.http.post<IPagedSkillSearch>(
      `${dotNetServerUri}/skillWarnings/skills/search/paged`,
      searchFilter,
    );
  }

  saveCustomSkillWarning(
    skills: ICustomSkillWarningPayload[],
  ): Observable<ICustomSkillWarning[]> {
    return this.http.post<ICustomSkillWarning[]>(
      `${dotNetServerUri}/skillWarnings/skills`,
      skills,
    );
  }

  getMatrixSettingsAudit = (pagedData: AuditFilter): Observable<AuditData> => {
    return this.http.post<AuditData>(
      `${dotNetServerUri}/skillWarnings/audit/paged`,
      pagedData,
    );
  };
}
