import { AfterViewChecked, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import {
  PerfGridContent,
  PerfGridRating,
  PerfGridRatingCriterion,
} from '../perf-grid-content/perf-grid-content';
import { PerfGridCol } from '../perf-grid/perf-grid-meta';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'ug-perf-summary',
  templateUrl: './perf-summary.component.html',
  styleUrls: ['./perf-summary.component.scss'],
})
export class PerfSummaryComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  private dashboardService = inject(DashboardService);

  perfsummary: PerfGridContent;
  isRegionLoading = true;
  isSiteLoading = true;
  isSubSiteLoading = true;
  isTeamsLoading = true;
  isAgentsLoading = true;
  isPerfGridLoading = true;

  activeDbChartItemIndex = -1;

  regionData: Array<PerfGridContent> = [];
  siteData: Array<PerfGridContent> = [];
  subSiteData: PerfGridContent[] = [];
  teamData: Array<PerfGridContent> = [];
  agentData: Array<PerfGridContent> = [];
  singleAgentData: PerfGridContent = new PerfGridContent();
  perfData: Array<PerfGridContent> = [];

  selectedAreaIndex = -1;
  selectedBoroughIndex = -1;
  selectedStationIndex = -1;
  selectedWatchIndex = -1;

  scrollToArea = false;
  scrollToBorough = false;
  scrollToStation = false;
  scrollToWatch = false;
  scrollToSM = false;
  scrollToGrid = false;

  selectedWatchName: string;
  selectedBoroughName: string;
  selectedStationName: string;
  selectedAreaName: string;

  showMatrix = false;

  siteList: Array<any> = [
    'Portsmouth',
    'Southampton',
    'Brighton',
    'Bournemouth',
  ];

  subscriptions: Subscription = new Subscription();

  roleSelectForm = new FormGroup({
    role: new FormControl(0),
  });

  get isLoading(): boolean {
    return this.isRegionLoading || this.isSiteLoading || this.isSubSiteLoading;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.perfsummary = new PerfGridContent();
    this.subscriptions.add(
      this.dashboardService
        .getPerfSite('Southern Region', 'Sales', 'Director')
        .subscribe((pm) => {
          this.perfsummary = this.mapResponse(pm);
          this.regionData.push(this.mapResponse(pm));
          this.isRegionLoading = false;
        })
    );

    // this.dashboardService.getPerfSite(`Portsmouth site`, 'Portsmouth', 'site')
    // .subscribe(
    //   sd => {
    //     this.siteData.push(this.mapResponse(sd));
    //   }
    // )

    // this.dashboardService.getPerfSite(`Southampton site`, 'Southampton', 'site')
    // .subscribe(
    //   sd => {
    //     this.siteData.push(this.mapResponse(sd));
    //   }
    // )

    // this.dashboardService.getPerfSite(`Brighton site`, 'Brighton', 'site')
    // .subscribe(
    //   sd => {
    //     this.siteData.push(this.mapResponse(sd));
    //   }
    // )

    // this.dashboardService.getPerfSite(`Bournemouth site`, 'Bournemouth', 'site')
    // .subscribe(
    //   sd => {
    //     this.siteData.push(this.mapResponse(sd));
    //   }
    // )

    // const siteObservables = [
    //   this.dashboardService.getPerfSite(`Portsmouth site`, 'Portsmouth', 'site'),
    //   this.dashboardService.getPerfSite(`Southampton site`, 'Southampton', 'site'),
    //   this.dashboardService.getPerfSite(`Brighton site`, 'Brighton', 'site'),
    //   this.dashboardService.getPerfSite(`Bournemouth site`, 'Bournemouth', 'site')
    // ]

    // forkJoin(siteObservables).subscribe( fj => {
    //     fj.forEach(o => this.siteData.push(this.mapResponse(o)));
    //     this.isSiteLoading = false;
    //   }
    // );

    // const subSiteObservables = [
    //   this.dashboardService.getPerfSite(`Portsmouth North`, 'Portsmouth', 'North'),
    //   this.dashboardService.getPerfSite(`Southampton North`, 'Southampton', 'North'),
    //   this.dashboardService.getPerfSite(`Brighton North`, 'Brighton', 'North'),
    //   this.dashboardService.getPerfSite(`Bournemouth North`, 'Bournemouth', 'North'),
    //   this.dashboardService.getPerfSite(`Portsmouth South`, 'Portsmouth', 'South'),
    //   this.dashboardService.getPerfSite(`Southampton South`, 'Southampton', 'South'),
    //   this.dashboardService.getPerfSite(`Brighton South`, 'Brighton', 'South'),
    //   this.dashboardService.getPerfSite(`Bournemouth South`, 'Bournemouth', 'South')
    // ]

    // forkJoin(subSiteObservables).subscribe( fj => {
    //   fj.forEach(o => this.subSiteData.push(this.mapResponse(o)));
    //   this.isSubSiteLoading = false;
    // });

    // this.siteList.forEach(s => {
    //   this.dashboardService.getPerfSite(`${s} site`, s, 'site')
    //   .subscribe(
    //     sd => {
    //       this.siteData.push(this.mapResponse(sd));
    //     }
    //   )
    //   this.dashboardService.getPerfSite(`${s} North`, s, 'North')
    //   .subscribe(
    //     ssd => {
    //       this.subSiteData.push(this.mapResponse(ssd));
    //     }
    //   )
    //   this.dashboardService.getPerfSite(`${s} South`, s, 'South')
    //   .subscribe(
    //     ssd => {
    //       this.subSiteData.push(this.mapResponse(ssd));
    //     }
    //   )
    // })
    // this.isSiteLoading = false;
    // this.isSubSiteLoading = false;
  }

  mapResponse(pm: PerfGridContent): PerfGridContent {
    const perfSummary = new PerfGridContent();
    perfSummary.name = pm.name;
    perfSummary.userFirstName = pm.userFirstName;
    perfSummary.userLastName = pm.userLastName;

    pm.metadata.Categories.forEach((c) => {
      perfSummary.metadata.Categories.push(
        new PerfGridCol({ name: c.name, score: c.score })
      );
    });

    pm.ratings.forEach((r) => {
      const ratcrit: Array<PerfGridRatingCriterion> = [];
      r.ratingCriteria.forEach((rc) => {
        ratcrit.push(
          new PerfGridRatingCriterion({
            fromValue: rc.fromValue,
            toValue: rc.toValue,
            description: rc.description,
          })
        );
      });
      perfSummary.ratings.push(
        new PerfGridRating({
          name: r.name,
          ratingValue: r.ratingValue,
          hoistRating: r.hoistRating || false,
          allowInput: r.allowInput || false,
          displayType: r.displayType,
          ratingCriteria: ratcrit,
        })
      );
    });
    return perfSummary;
  }

  onRegionClick(regionName: string, index: number) {
    // this.selectedCategoryIndex = chartIndex;
    // this.donutClick.emit({chartName: chartName, chartIndex: chartIndex})
    this.isSiteLoading = true;
    this.isSubSiteLoading = true;
    this.isTeamsLoading = true;
    this.isAgentsLoading = true;
    this.isPerfGridLoading = true;
    this.siteData.length = 0;
    const siteObservables = [
      this.dashboardService.getPerfSite(
        `Portsmouth Site`,
        'Portsmouth',
        'Site'
      ),
      this.dashboardService.getPerfSite(
        `Southampton Site`,
        'Southampton',
        'Site'
      ),
      this.dashboardService.getPerfSite(`Brighton Site`, 'Brighton', 'Site'),
      this.dashboardService.getPerfSite(
        `Bournemouth Site`,
        'Bournemouth',
        'Site'
      ),
    ];

    this.subscriptions.add(
      forkJoin(siteObservables).subscribe(
        (fj) => {
          fj.forEach((o) => this.siteData.push(this.mapResponse(o)));
          this.onAgentClick(this.regionData[index], index);
          this.isSiteLoading = false;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  onSiteClick(siteName: string, index: number) {
    this.isSubSiteLoading = true;
    this.isTeamsLoading = true;
    this.isAgentsLoading = true;
    this.isPerfGridLoading = true;
    const subSiteObservables = [
      this.dashboardService.getPerfSite(`${siteName} North`, siteName, 'North'),
      this.dashboardService.getPerfSite(`${siteName} South`, siteName, 'South'),
    ];
    this.subSiteData.length = 0;

    this.subscriptions.add(
      forkJoin(subSiteObservables).subscribe(
        (fj) => {
          fj.forEach((o) => this.subSiteData.push(this.mapResponse(o)));
          this.onAgentClick(this.siteData[index], index);
          this.isSubSiteLoading = false;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  onSubSiteClick(subSiteName: string, index: number) {
    this.isTeamsLoading = true;
    this.isAgentsLoading = true;
    this.isPerfGridLoading = true;
    const constTeamObservables = [
      this.dashboardService.getPerfTeam('Team A', 'Team', 'A'),
      this.dashboardService.getPerfTeam('Team B', 'Team', 'B'),
      this.dashboardService.getPerfTeam('Team C', 'Team', 'C'),
    ];
    this.teamData.length = 0;
    this.subscriptions.add(
      forkJoin(constTeamObservables).subscribe(
        (fj) => {
          fj.forEach((o) => this.teamData.push(this.mapResponse(o)));
          this.onAgentClick(this.subSiteData[index], index);
          this.isTeamsLoading = false;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  onTeamClick(teamName: string, index: number) {
    this.isAgentsLoading = true;
    this.isPerfGridLoading = true;
    const constAgentsObservables = [
      this.dashboardService.getPerfAgent('Agent', 'A'),
      this.dashboardService.getPerfAgent('Agent', 'B'),
      this.dashboardService.getPerfAgent('Agent', 'C'),
    ];
    this.agentData.length = 0;
    this.subscriptions.add(
      forkJoin(constAgentsObservables).subscribe(
        (fj) => {
          fj.forEach((o) => this.agentData.push(this.mapResponse(o)));
          this.onAgentClick(this.teamData[index], index);
          this.isAgentsLoading = false;
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  onAgentClick(perfGridData: PerfGridContent, index: number) {
    this.isPerfGridLoading = true;
    this.singleAgentData = perfGridData;
    this.perfData = new Array<PerfGridContent>();
    this.perfData.push(perfGridData);
    this.isPerfGridLoading = false;
    this.scrollToGrid = true;
  }

  ngAfterViewChecked() {
    if (this.scrollToGrid) {
      (document.getElementById('perfGrid') as HTMLInputElement).scrollIntoView({
        behavior: 'smooth',
      });
      this.scrollToGrid = false;
    }
  }

  categoryColour(score: number) {
    if (score >= 220) {
      return 'text-success';
    }
    if (score >= 180) {
      return 'text-warning';
    }
    if (score >= 100) {
      return 'esqep-req-improve';
    }
    return 'text-danger';
  }
}
