import { Component } from '@angular/core';

import { Skill } from '../../skill/models/skill';

@Component({
  selector: 'ug-test',
  templateUrl: './test.component.html',
  styleUrls: [],
})
export class TestComponent {
  selectedCompAttr = new Skill();
  filter = '';

  changed(newValue) {
    console.log(newValue);
  }
}
