import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'ug-progress-row-card',
  templateUrl: './progress-row-card.component.html',
  styleUrls: [],
})
export class ProgressRowCardComponent implements OnInit {
  private dashboardService = inject(DashboardService);

  @Input() cardTitle = '';
  @Input() cardSubTitle = '';
  @Input() progressDataArray: Array<any> = [];
  @Input() selectedCategoryIndex = -1;
  @Input() loadingData = true;
  @Output() donutClick = new EventEmitter<{
    chartName: string;
    chartIndex: number;
  }>();

  ngOnInit() {}

  getChartScore(chartScore: number): string {
    return this.dashboardService.getDisplayPercentage(chartScore);
  }
}
