<div class="card shadow curricula-summary">
  <div class="card-body">
    <h5 class="card-title">My Training Completion Summary</h5>
    <ug-list-status
      [isLoading]="loadingData"
      [listLength]="curriculaData.length"
      [emptyListMessage]="'No training assigned'"
    ></ug-list-status>
    <div class="summary-tableview">
      <div
        *ngFor="let progressBarData of curriculaData; let i = index"
        class="row"
      >
        <div class="col">
          <span>
            {{ progressBarData.name }} ({{ progressBarData.chartScore }})
          </span>
        </div>
        <div class="col align-self-center">
          <div
            class="progress bg-danger"
            role="progressbar"
            aria-label="{{ progressBarData.name }} ({{
              progressBarData.chartScore
            }})"
            aria-valuenow="{{ progressBarData.chartScore }}"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="progress-bar bg-success"
              [style.width]="progressBarData.chartScore"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
