<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">
      {{ trainingOutcome ? 'Update' : 'Create' }} Assessment Option
    </h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="trainingOutcomeForm" class="mb-2">
      <div class="form-check mb-2">
        <label for="active" class="form-check-label text-bottom">Active</label>
        <input
          id="active"
          class="form-check-input"
          type="checkbox"
          formControlName="isActive"
          title="Is assessment option active?"
        />
      </div>
      <div class="col-6 mb-2">
        <label class="form-label">Assessment outcome</label>
        <ng-select
          formControlName="isSuccess"
          [multiple]="false"
          [closeOnSelect]="true"
          [items]="dropdownOptions"
          bindLabel="name"
          bindValue="isSuccess"
          placeholder="Select an outcome"
        >
        </ng-select>
      </div>
      <div class="col-8 mb-2">
        <label class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          [placeholder]="'Provide a name for the assessment option'"
        />
      </div>
      <div class="col-12">
        <label for="description" class="form-label">Description</label>
        <textarea
          class="form-control"
          type="textarea"
          rows="2"
          id="description"
          formControlName="description"
          placeholder="Enter description"
          maxlength="500"
        ></textarea>
        <div class="max-characters">
          <div id="char_count">{{ outcomeDescriptionLength }}/500</div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary btn-md"
      title="Cancel"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="addOrUpdateTrainingOutcome()"
      [disabled]="trainingOutcomeForm.invalid || !trainingOutcomeForm.dirty"
    >
      {{ trainingOutcome ? 'Update' : 'Create' }}
    </button>
  </div>
</div>
