import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subject, Subscription, takeUntil } from 'rxjs';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableHeaderButton,
  TableRowButton,
} from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { ProgressModalComponent } from '../../shared/progress-modal/progress-modal.component';
import {
  JobRole,
  JobRoleDotNet,
  JobRoleList,
  JobRoleService,
} from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-list',
  templateUrl: './job-role-list.component.html',
  styleUrls: ['./job-role-list.component.css'],
})
export class JobRoleListComponent implements OnDestroy, OnInit {
  private jobRoleService = inject(JobRoleService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);
  private alertService = inject(AlertService);
  private uiService = inject(UitoolsService);
  private configService = inject(ConfigService);
  private ngbModal = inject(NgbModal);

  exceptionData = {
    JOB_ROLE_DELETE: {
      level: AlertLevels.ERROR,
      code: 'JRL-001',
      message: 'Error deleting job role',
    } as AlertData,
    JOB_ROLE_DEACTIVATE: {
      level: AlertLevels.ERROR,
      code: 'JRL-002',
      message: 'Error deactivating job role',
    } as AlertData,
    JOB_ROLE_REACTIVATE: {
      level: AlertLevels.ERROR,
      code: 'JRL-003',
      message: 'Error reactivating job role',
    } as AlertData,
    JOB_ROLE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRL-004',
      message: 'Error getting job roles',
    } as AlertData,
  };

  jobRolesList: JobRoleList = {} as any;
  jobRolesSubscription = new Subscription();
  jobRoles: Array<JobRoleDotNet> = [];
  tableLoading = false;

  editOrViewButtonText = '';
  totalRecords = 0;
  pageSize = 10;

  showDeleteButton = false;
  showCreateButton = false;
  showEditOrViewButton = false;
  showSettingsButton = false;

  ngUnsubscribe: Subject<boolean> = new Subject();
  csvReqsFileUrl: any;
  csvPeopleFileUrl: any;
  reqsReportCreated = false;
  peopleReportCreated = false;
  reqsDownloading = false;
  peopleDownloading = false;
  tableHeaders: Array<TableHeader> = [];
  tableHeaderButtons: Array<TableHeaderButton> = [];
  tableRowButtons: Array<TableRowButton> = [];
  tableFilters: Array<FilterBase<any>> = [];
  tableRows: Array<any> = [];
  navItemList = {};
  progressModal: any;

  ngOnInit(): void {
    this.tableLoading = true;

    this.tableHeaders = [
      { id: 'id', title: 'Id', class: 'w-5' },
      { id: 'name', title: 'Name', class: 'w-20' },
      { id: 'description', title: 'Description', class: 'w-40' },
      { id: 'peopleCount', title: 'People', class: 'w-5' },
      {
        id: 'active',
        title: 'Active',
        iconFunction: this.activeIcon,
        class: 'w-10 text-center',
      },
    ];

    this.authService.loggedInUserSubj.subscribe((usd: LoggedInUser) => {
      this.showEditOrViewButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRolePeople,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => usd.roleIdentifier === sr);

      this.editOrViewButtonText = this.showEditOrViewButton
        ? 'Edit Role'
        : 'View Role';

      if (this.showEditOrViewButton) {
        this.tableRowButtons = [
          {
            title: this.editOrViewButtonText,
            rowAction: this.editOrViewJobRole,
            hideCondition: this.hideEditOrView,
          },
          {
            title: 'Delete',
            class: 'btn-outline-danger',
            rowAction: this.onDeleteJobRoleClick,
            hideCondition: this.hideDelete,
          },
          {
            title: 'Deactivate',
            class: 'btn-outline-danger',
            rowAction: this.onDeactivateJobRoleClick,
            hideCondition: this.hideDeactivate,
          },
          {
            title: 'Reactivate',
            class: 'btn-outline-success',
            rowAction: this.onReactivateJobRoleClick,
            hideCondition: this.hideReactivate,
          },
        ];

        this.tableHeaderButtons = [
          {
            title: 'Create Role',
            headerAction: this.createJobRole,
            type: 'button',
            hideCondition: this.hideCreate,
          },
          {
            title: 'Reports',
            type: 'dropdown',
            dropdownItems: [
              {
                title: 'Role Requirements Report',
                dropdownAction: this.getReqsCsv,
              },
              { title: 'People Report', dropdownAction: this.getPeopleCsv },
            ],
          },
          {
            title: 'Settings',
            headerAction: this.jobRoleSettings,
            type: 'button',
            hideCondition: this.hideSettingsButton,
          },
        ];
      }

      this.showDeleteButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => usd.roleIdentifier === sr);
      this.showCreateButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => usd.roleIdentifier === sr);
      this.showSettingsButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
      ].some((sr) => usd.roleIdentifier === sr);
    });

    this.tableFilters = [
      new TextboxFilter({
        key: 'searchTerm',
        label: 'Filter by term',
        order: 1,
        placeholder: 'Filter by term',
      }),
    ];

    this.jobRoleService
      .getJobRolesDotNet()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (jl) => {
          this.jobRoles = jl;
          this.tableRows = this.jobRoles;
          this.tableLoading = false;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.JOB_ROLE_LIST, err);
        },
      });

    this.navItemList = this.configService.roleManagementTabs
      .map((rmt, index) => ({ [rmt]: index + 1 }))
      .reduce((prev, navList) => ({ ...prev, ...navList }), {});
  }

  ngOnDestroy(): void {
    this.jobRolesSubscription.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  createJobRole = () => {
    this.router.navigate([`job-role-management/0`]);
  };

  editOrViewJobRole = (jobRole: JobRole) => {
    const roleId = jobRole.id;
    this.router.navigate([`job-role-management/${roleId}`]);
  };

  onDeleteJobRoleClick = (jobRole: JobRoleDotNet) => {
    if (jobRole.peopleCount < 1) {
      this.jobRoleService
        .deleteJobRoleRequirementsByJobRoleId(jobRole.id)
        .subscribe({
          next: (jr) => {
            this.jobRoleService.deleteJobRole(jobRole.id).subscribe(
              (jrd) => {
                this.jobRoles = this.jobRoles.filter(
                  (jr) => jr.id !== jobRole.id,
                );
                this.tableRows = this.jobRoles;
                this.uiService.showToast(
                  'Job Role ' + jobRole.name + ' deleted',
                  { classname: 'bg-success text-light', delay: 3000 },
                );
              },
              (err) => {
                this.alertService.createAlert2(
                  this.exceptionData['JOB_ROLE_DELETE'],
                  err,
                );
              },
            );
          },
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData['JOB_ROLE_DELETE_REQUIREMENTS'],
              err,
            );
          },
        });
    } else {
      this.uiService.showToast(
        'Unable to delete ' +
          jobRole.name +
          ' as ' +
          jobRole.peopleCount +
          ' are assigned to the role',
        { classname: 'bg-success text-light', delay: 3000 },
      );
    }
  };

  onDeactivateJobRoleClick = (jobRole: JobRoleDotNet) => {
    const requestData = {
      name: jobRole.name,
      description: jobRole.description,
      filterId: jobRole.filterId,
      locationId: jobRole.locationId,
      active: false,
      pendingDeletion: jobRole.pendingDeletion,
      pendingInactivation: true,
    };

    this.jobRoleService
      .updateJobRoleDotNet(jobRole.id, requestData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (jrd) => {
          jobRole.active = false;
          const index = this.jobRoles.findIndex((jr) => jr.id === jobRole.id);
          this.jobRoles[index].active = false;
          this.tableRows = this.jobRoles;
          this.uiService.showToast(
            'Job Role ' + jobRole.name + ' deactivated',
            { classname: 'bg-success text-light', delay: 3000 },
          );
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.JOB_ROLE_DEACTIVATE,
            err,
          );
        },
      });
  };

  onReactivateJobRoleClick = (jobRole: JobRoleDotNet) => {
    this.progressModal = this.ngbModal.open(ProgressModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    this.progressModal.componentInstance.modalTitle = 'Reactivating Job Role';

    const requestData = {
      name: jobRole.name,
      description: jobRole.description,
      filterId: jobRole.filterId,
      locationId: jobRole.locationId,
      active: true,
      pendingDeletion: jobRole.pendingDeletion,
      pendingInactivation: false,
    };

    this.jobRoleService
      .updateJobRoleDotNet(jobRole.id, requestData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (jrd) => {
          this.progressModal.componentInstance.updatesDone++,
            (jobRole.active = true);
          const index = this.jobRoles.findIndex((jr) => jr.id === jobRole.id);
          this.jobRoles[index].active = true;
          this.tableRows = this.jobRoles;
          this.uiService.showToast(
            'Job Role ' + jobRole.name + ' reactivated',
            { classname: 'bg-success text-light', delay: 3000 },
          );
        },
        error: (err) => {
          this.progressModal.componentInstance.updatesFailed++;
          this.progressModal.componentInstance.failedSections.push({
            sectionName: 'Activate Job Role',
            description:
              err.error?.request ??
              err.error?.Request ??
              `${err.status} ${err.statusText}`,
          });
          this.alertService.createAlert2(
            this.exceptionData.JOB_ROLE_REACTIVATE,
            err,
          );
        },
      });
  };

  getReqsCsv = () => {
    if (this.reqsReportCreated) {
      const link = document.createElement('a');
      link.setAttribute('target', '_self');
      link.setAttribute('href', this.csvReqsFileUrl);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      this.reqsDownloading = true;
      this.jobRoleService
        .getJobRoleReqsCsv()
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.reqsDownloading = false)),
        )
        .subscribe({
          next: (csvFile) => {
            const csvBlob = new Blob([csvFile], { type: csvFile.type });
            this.csvReqsFileUrl = window.URL.createObjectURL(csvBlob);
            // this.csvReqsFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(csvBlob));
            this.reqsReportCreated = true;
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', this.csvReqsFileUrl);
            // link.setAttribute('download', `test.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          error: (err) => {},
          complete: () => {},
        });
    }
  };

  getPeopleCsv = () => {
    if (this.peopleReportCreated) {
      const link = document.createElement('a');
      link.setAttribute('target', '_self');
      link.setAttribute('href', this.csvPeopleFileUrl);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    this.peopleDownloading = true;
    this.jobRoleService
      .getJobRolePeopleCsv()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.peopleDownloading = false)),
      )
      .subscribe({
        next: (csvFile) => {
          const csvBlob = new Blob([csvFile], { type: csvFile.type });
          this.csvPeopleFileUrl = window.URL.createObjectURL(csvBlob);
          // this.csvPeopleFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(csvBlob));
          this.peopleReportCreated = true;
          const link = document.createElement('a');
          link.setAttribute('target', '_self');
          link.setAttribute('href', this.csvPeopleFileUrl);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        error: (err) => {},
        complete: () => {},
      });
  };

  activeIcon = (row) => {
    return row.active
      ? 'fas fa-xl fa-check-circle text-success'
      : 'fas fa-xl fa-times-circle text-danger';
  };

  hideDelete = (jr) => {
    return !(this.showDeleteButton && jr.peopleCount === 0);
  };

  hideDeactivate = (jr) => {
    return !(this.showDeleteButton && jr.peopleCount > 0 && jr.active);
  };

  hideReactivate = (jr) => {
    return !(this.showDeleteButton && jr.peopleCount > 0 && !jr.active);
  };

  hideCreate = () => {
    return !this.showCreateButton;
  };

  hideEditOrView = () => {
    return !this.showEditOrViewButton;
  };

  hideSettingsButton = () => {
    return !this.showSettingsButton || !this.navItemList['training'];
  };

  jobRoleSettings = () => {
    this.router.navigate([`job-role-settings`]);
  };
}
