import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Subject, Subscription, takeUntil } from 'rxjs';

import { ClaimService } from '../../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { FuncArea } from '../../../skill/models/func-area';
import { SkillService } from '../../../skill/services/skill.service';
import { JobRole, JobRoleRequirement } from '../../services/job-role.service';

@Component({
  selector: 'ug-default-job-role-requirements-table-component',
  templateUrl: './default-job-role-requirements-table.component.html',
})
export class DefaultJobRoleRequirementsTableComponent
  implements OnInit, OnDestroy
{
  private fb = inject(FormBuilder);
  private skillService = inject(SkillService);
  private claimService = inject(ClaimService);
  private alertService = inject(AlertService);

  @Input() formGroup: FormGroup;
  @Input() ngUnsubscribe: Subject<boolean>;
  @Input() jobRole: JobRole;

  addingReqInProgress = false;
  skillsCache: any = {};
  levelSetsCache: any = {};
  preDeleteReqControlStatus = {};
  disciplineList: Array<FuncArea>;
  disciplineFeedSubs = new Subscription();

  reqCountMessageMap = {
    '=0': 'None specified',
    '=1': '1 requirement',
    other: '# requirements',
  };

  exceptionData = {
    DISCIPLINE_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-004',
      message: 'Error retrieving disciplines',
      translationKey: 'errRetrievingDisciplines',
    } as AlertData,
    SKILLS_LIST: {
      level: AlertLevels.ERROR,
      code: 'JRD-009',
      message: 'Error retrieving skills',
    } as AlertData,
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'JRD-010',
      message: 'Error retrieving claim levels',
      translationKey: 'errRetrievingAssessmentReq',
    } as AlertData,
  };

  ngOnInit() {
    this.disciplineFeedSubs = this.skillService.disciplineFeed.subscribe({
      next: (dl) => (this.disciplineList = dl),
      error: (err) =>
        this.alertService.createAlert2(this.exceptionData.DISCIPLINE_LIST, err),
    });

    this.jobRole.requirements.forEach((r) => {
      this.addLevelSetToCache(r.disciplineId);
    });
  }

  onAddRequirementClick() {
    this.formGroup.addControl(
      'fgNewReq',
      this.fb.group({
        action: 'C',
        claimLevelId: [null, Validators.required],
        claimLevelName: [''],
        disciplineId: [null, Validators.required],
        disciplineName: [''],
        mandatory: [false, Validators.required],
        skillId: [
          null,
          [Validators.required, this.roleSkillUniquenessValidator()],
        ],
        skillName: [''],
        weighting: [
          1,
          [Validators.required, Validators.min(0), Validators.max(1)],
        ],
      }),
    );
    this.addingReqInProgress = true;
  }

  roleSkillUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.roleReqsArray
        .map((jr) => jr.skillId)
        .indexOf(control.value) !== -1
        ? { roleSkillUniqueness: { value: control.value } }
        : null;
    };
  }

  formControlValid(controlName: string): boolean {
    const c: AbstractControl = this.formGroup.get(controlName);
    return c.disabled || !(c.touched && c.invalid);
  }

  trackByReqs(index: number, requirement: JobRoleRequirement): number {
    return requirement.id;
  }

  onNewReqDoneClick() {
    this.formGroup.get('fgNewReq').patchValue({
      disciplineName: this.getDisciplineNameFromId(
        this.formGroup.get('fgNewReq').get('disciplineId').value,
      ),
      skillName: this.getSkillNameFromId(
        this.formGroup.get('fgNewReq').get('disciplineId').value,
        this.formGroup.get('fgNewReq').get('skillId').value,
      ),
    });

    const reqArray = this.formGroup.get('requirements') as FormArray;
    reqArray.push(this.fb.group(this.formGroup.get('fgNewReq').value));
    reqArray.controls[reqArray.length - 1].markAsDirty();
    this.formGroup.get('fgNewReq').markAsPristine();
    this.formGroup.removeControl('fgNewReq');
    this.addingReqInProgress = false;
  }

  onNewReqDeleteClick() {
    this.formGroup.get('fgNewReq').markAsPristine();
    this.formGroup.removeControl('fgNewReq');
    this.addingReqInProgress = false;
  }

  getDisciplineNameFromId(disciplineId: number): string {
    return (
      (this.disciplineList || [])
        .filter((d) => {
          return d.id === disciplineId;
        })
        .map((d1) => d1.name)[0] || ''
    );
  }

  getSkillNameFromId(disciplineId: number, skillId: number): string {
    return (
      (this.skillsCache[disciplineId] || [])
        .filter((s) => {
          return s.id === skillId;
        })
        .map((s1) => s1.name)[0] || ''
    );
  }

  get roleReqsArray(): Array<any> {
    return this.formGroup.get('requirements').value;
  }

  get addedDisciplineId(): number {
    return this.formGroup.get('fgNewReq').get('disciplineId').value;
  }

  onDisciplineChange() {
    const disciplineId = this.addedDisciplineId;
    if (!(disciplineId in this.skillsCache)) {
      this.skillsCache[disciplineId] = [];
      const parentFuncAreaId = this.disciplineList.find((d) => {
        return d.id === disciplineId;
      }).parentFuncAreaId;
      this.skillService
        .getSkillsFeed(parentFuncAreaId, disciplineId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (sl) => (this.skillsCache[disciplineId] = sl),
          error: (err) =>
            this.alertService.createAlert2(this.exceptionData.SKILLS_LIST, err),
        });
    }
  }

  onSkillChange() {
    this.addLevelSetToCache(this.addedDisciplineId);
  }

  addLevelSetToCache(disciplineId: number) {
    if (!(disciplineId in this.levelSetsCache)) {
      this.levelSetsCache[disciplineId] = [];
      this.claimService
        .getClaimLevelsFeed(disciplineId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (cl) => (this.levelSetsCache[disciplineId] = cl),
          error: (err) =>
            this.alertService.createAlert2(
              this.exceptionData.CLAIM_LEVELS,
              err,
            ),
        });
    }
  }

  onRemoveRequirementClick(reqIndex: number) {
    const reqControls = (this.formGroup.get('requirements') as FormArray)
      .controls[reqIndex];

    this.preDeleteReqControlStatus[reqIndex] = {
      action: reqControls.get('action').value,
      dirty: reqControls.dirty,
    } as { action: string; dirty: boolean };

    reqControls.get('action').setValue('D');
    reqControls.get('claimLevelId').disable();
    reqControls.get('weighting').disable();
    reqControls.get('mandatory').disable();
    reqControls.markAsDirty();
  }

  onRestoreRequirementClick(reqIndex: number) {
    const reqControls = (this.formGroup.get('requirements') as FormArray)
      .controls[reqIndex];
    reqControls
      .get('action')
      .setValue(this.preDeleteReqControlStatus[reqIndex].action);
    reqControls.get('claimLevelId').enable();
    reqControls.get('weighting').enable();
    reqControls.get('mandatory').enable();
    if (this.preDeleteReqControlStatus[reqIndex].dirty) {
      reqControls.markAsDirty();
    } else {
      reqControls.markAsPristine();
    }
  }

  ngOnDestroy() {
    this.disciplineFeedSubs.unsubscribe();
  }
}
