export class PerfGridCol {
  name: string;
  score: number;

  constructor(init?: Partial<PerfGridCol>) {
    Object.assign(this, init);
  }
}

export class PerfGridMeta {
  Categories: Array<PerfGridCol> = [];

  constructor(init?: Partial<PerfGridMeta>) {
    Object.assign(this, init);
  }

  categoryScore(catIdx: number): number {
    return catIdx === -1 ? 0 : this.Categories[catIdx].score;
  }
}
