import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'ug-text-filter-control',
  templateUrl: './text-filter-control.component.html',
  styleUrls: [],
})
export class TextFilterControlComponent
  implements OnDestroy, OnInit, OnChanges
{
  @Input() filterText!: string;
  @Input() ugDisabled = false;
  @Input() placeholderText = 'Begin typing to filter results';
  @Input() showSearchIcon = false;
  @Input() showMinCharMessage = false;
  @Output() filterTextChange = new EventEmitter<string>();

  filterGroup = new FormGroup({
    filterControl: new FormControl(''),
  });
  textFilterControlSubs = new Subscription();

  ngOnInit() {
    this.filterGroup.get('filterControl').setValue(this.filterText);
    if (this.ugDisabled) {
      this.filterGroup.disable();
    }
    this.textFilterControlSubs.add(
      this.filterGroup
        .get('filterControl')
        .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
        .subscribe((ft) =>
          this.filterTextChange.emit(
            this.filterGroup.get('filterControl').value,
          ),
        ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterGroup.get('filterControl').setValue(this.filterText);
    if (this.ugDisabled) {
      this.filterGroup.disable();
    } else {
      this.filterGroup.enable();
    }
  }

  ngOnDestroy() {
    this.textFilterControlSubs.unsubscribe();
  }

  onResetClick() {
    this.filterGroup.get('filterControl').setValue('');
  }
}
