<div class="row vh-100 align-items-center">
  <div class="col-12 col-md-8 offset-md-2 mt-n5">
    <div class="card">
      <div class="card-header bg-danger text-light">
        <span class="h3"
          ><span class="fas fa-exclamation-triangle"></span> I'm afraid I can't
          do that</span
        >
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ exceptionConfig[exceptionType].title }}</h5>
        <p class="card-text">
          {{ exceptionConfig[exceptionType].description }}
        </p>
      </div>
      <div class="card-footer">
        <div class="btn-group float-end">
          <button
            *ngIf="
              exceptionConfig[exceptionType].actions.includes(
                exceptionActions.HOME
              )
            "
            class="btn btn-outline-primary"
            (click)="home()"
            role="button"
          >
            <span class="fas fa-home"></span> Home
          </button>
          <button
            *ngIf="
              exceptionConfig[exceptionType].actions.includes(
                exceptionActions.LOGOUT
              )
            "
            class="btn btn-outline-danger"
            (click)="logout()"
            role="button"
          >
            Logout
          </button>
          <button
            *ngIf="
              exceptionConfig[exceptionType].actions.includes(
                exceptionActions.ACKNOWLEDGED
              )
            "
            class="btn btn-outline-danger"
            (click)="logout()"
            role="button"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    <!--
    <p>exceptionCongig: {{exceptionConfig | json}}</p>
    <p>exceptionType: {{exceptionType}}</p>
    <p>exceptionActions: {{exceptionActions | json}}</p>
-->
  </div>
</div>
