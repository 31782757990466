import {
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  forkJoin,
  map,
  OperatorFunction,
  Subject,
  takeUntil,
} from 'rxjs';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  JobRole,
  JobRoleService,
} from '../../job-role/services/job-role.service';

@Component({
  selector: 'ug-project-roles',
  templateUrl: './project-roles.component.html',
  styleUrls: ['./project-roles.component.scss'],
})
export class ProjectRolesComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private uiService = inject(UitoolsService);
  private jobRoleService = inject(JobRoleService);

  @ViewChild('jobRolesModal') jobRolesModal: ElementRef;
  @Input() parentFormGroup: FormGroup;
  @Input() jrLoading: boolean;
  @Input() jobRoleSearch: OperatorFunction<string, JobRole[]>;

  @Input()
  set jobRoleList(value) {
    this.jobRoleListBS.next(value);
  }

  get jobRoleList() {
    return this.jobRoleListBS.getValue();
  }

  private jobRoleListBS = new BehaviorSubject<Array<JobRole>>([]);
  jobRoleFormatter = (result) => result['name'];
  filteredJobRoleList: Array<JobRole>;
  private ngUnsubscribe: Subject<boolean> = new Subject();
  activeJobRoleTitle: string;
  activeJobRoleRequirements: Array<any>;
  requirementsShowing: boolean = false;
  quantityDropDown: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectAllFilteredJobs: boolean = false;
  rolesAdded: number;
  roleIndex: number = 1;

  ngOnInit(): void {
    this.jobRoleListBS.pipe(takeUntil(this.ngUnsubscribe)).subscribe((jrl) => {
      this.filteredJobRoleList = jrl;
    });
    this.parentFormGroup
      .get('projectRoles.roleModalFilters.jobRole')
      .valueChanges.subscribe((jr) => {
        this.filteredJobRoleList = this.jobRoleList;
        if (jr !== null) {
          this.filteredJobRoleList = this.filteredJobRoleList.filter((fjr) => {
            return fjr.id === jr.id;
          });
        }
      });
  }

  get jobRoles(): FormArray {
    return this.parentFormGroup.get(
      'projectRoles.assignedJobRoles.jobRoles',
    ) as FormArray;
  }

  openAddRolesModal() {
    if (!this.jrLoading) {
      this.filteredJobRoleList.map((jr) => {
        jr['isSelected'] = false;
      });
    }

    this.selectAllFilteredJobs = false;
    this.uiService.openModalExtraLarge(this.jobRolesModal);
  }

  clearFilters() {
    this.selectAllFilteredJobs = false;
    this.parentFormGroup.get('projectRoles.roleModalFilters').reset();
  }

  addSelectedJobs() {
    this.rolesAdded = 0;
    this.filteredJobRoleList.forEach((jr) => {
      if (jr['isSelected']) {
        this.rolesAdded++;
        const obs = [
          this.jobRoleService.getJobRolePeopleDotNet(jr.id),
          this.jobRoleService.getJobRoleRequirements(jr.id),
        ];
        forkJoin(obs)
          .pipe(map(([people, requ]) => ({ people, requ })))
          .subscribe((resp) => {
            this.jobRoles.push(this.newRole(jr, resp.people, resp.requ));
            this.uiService.showToast(
              'Successfully added ' + this.rolesAdded + ' role(s)',
              { classname: 'bg-success text-light', delay: 5000 },
            );
            this.filteredJobRoleList.map((jr) => {
              jr['isSelected'] = false;
            });
          });
      }
    });
  }

  newRole(jobRole, people, requ): FormGroup {
    const roleIndex =
      this.jobRoles.value.length === 0 ? 1 : this.jobRoles.value.length + 1;
    return this.fb.group({
      name: jobRole.name,
      id: jobRole.id,
      people: [people],
      req: [requ],
      quantity: 1,
      minRoleMatch: 50,
      roleIndex: roleIndex,
    });
  }

  openRequirements(i) {
    this.requirementsShowing = !this.requirementsShowing;
    this.setActiveJobRole(i);
  }

  setActiveJobRole(i) {
    const jrId = this.jobRoles.at(i).value.id;
    const jobRole = this.jobRoles.value.filter((x) => {
      return x.id === jrId;
    });
    this.activeJobRoleTitle = jobRole[0].name;
    this.activeJobRoleRequirements = jobRole[0].req;
  }

  removeRole(index) {
    this.jobRoles.removeAt(index);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  get listLength() {
    return this.jrLoading ? 0 : 1;
  }

  checkUncheckAllFilteredJobs() {
    this.filteredJobRoleList.forEach((job) => {
      job['isSelected'] = this.selectAllFilteredJobs;
    });
  }

  isAllFilteredJobsSelected() {
    this.selectAllFilteredJobs = this.filteredJobRoleList.every((job: any) => {
      return job['isSelected'] === true;
    });
  }
}
