<div class="card">
  <div class="card-body">
    <h5 class="card-title">Attached Documents</h5>
    <div class="table-responsive">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th scope="col">Date / Time</th>
            <th scope="col">Filename</th>
            <!-- <th class="col-5">Comment</th> -->
            <th scope="col">Document Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="claimDocumentList?.length === 0">
            <td colspan="4">
              No documents have been uploaded for this assessment request
            </td>
          </tr>
          <tr *ngFor="let claimDocument of claimDocumentList; let i = index">
            <td nowrap>{{ claimDocument.createDatetime | date: 'medium' }}</td>
            <td>{{ claimDocument.filename }}</td>
            <!-- <td>{{claimDocument.comment}}</td> -->
            <td>
              <button
                *ngIf="i !== selectedDocIdx"
                class="btn btn-outline-primary btn-sm"
                (click)="onViewClick(claimId, claimDocument.id, i)"
              >
                View
              </button>
              <button
                *ngIf="i === selectedDocIdx"
                class="btn btn-outline-warning btn-sm"
                (click)="onHideClick(claimDocument.id)"
              >
                Hide
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ug-claim-document
  *ngIf="selectedDocIdx !== -1"
  [claimId]="docClaimId"
  [docId]="docDocId"
  #d
></ug-claim-document>
