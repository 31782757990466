import { Component, Input, OnDestroy, OnInit } from '@angular/core';
const FIRST_ACCELATION_TIME = 2000;
const LAST_ACCELATION_TIME = 1000;
@Component({
  selector: 'ug-progress-bar-fake',
  templateUrl: './progress-bar-fake.component.html',
  styleUrls: ['./progress-bar-fake.component.scss'],
})
export class ProgressBarFakeComponent implements OnInit, OnDestroy {
  isStartLoading = false;
  private actualWaitingTime = 0;
  private _isFailed = false;
  private currentProgress = 0;
  private intervals = [];
  private showAnimationTime = 2000;
  @Input() progressTitle = '';
  @Input() type = 'info';
  @Input()
  set maximumWaitingTime(time: number) {
    this.actualWaitingTime =
      time -
      this.showAnimationTime -
      FIRST_ACCELATION_TIME -
      LAST_ACCELATION_TIME;
  }
  @Input()
  set isDone(status: boolean) {
    if (status) {
      this.resetIntervals();
      this.accelateToEnd();
    }
  }
  @Input()
  set isFailed(status: boolean) {
    this._isFailed = status;
    if (status) {
      this.resetIntervals();
    }
  }
  get isFailed() {
    return this._isFailed;
  }

  get currentPercentage(): number {
    return Math.min(1, this.currentProgress) * 100;
  }

  get currentPercentageLabel(): string {
    return this.isStartLoading ? Math.floor(this.currentPercentage) + '%' : '';
  }

  ngOnInit() {
    setTimeout(() => {
      this.isStartLoading = true;
      this.startLoading();
    }, this.showAnimationTime);
  }

  startLoading() {
    this.intervals.push(
      this.createLoadingInterval(
        FIRST_ACCELATION_TIME,
        0.2,
        this.loading.bind(this),
      ),
    );
  }

  loading() {
    this.intervals.push(
      this.createLoadingInterval(
        this.actualWaitingTime,
        0.7,
        this.endLoading.bind(this),
      ),
    );
  }

  endLoading() {
    this.intervals.push(this.createLoadingInterval(LAST_ACCELATION_TIME, 0.1));
  }

  accelateToEnd() {
    this.intervals.push(
      this.createLoadingInterval(
        FIRST_ACCELATION_TIME,
        1 - this.currentProgress,
      ),
    );
  }

  ngOnDestroy() {
    this.resetIntervals();
  }

  private createLoadingInterval(
    maxTime: number,
    maxPercent: number,
    next?: () => void,
  ) {
    let currentTime = 0;
    const step = 0.01 * maxTime;

    const interval = setInterval(() => {
      this.currentProgress += 0.01 * maxPercent;

      if (currentTime + step > maxTime) {
        clearInterval(interval);
        if (next) {
          next();
        }
        return;
      }
      currentTime += step;
    }, step);

    return interval;
  }

  private resetIntervals() {
    for (const interval of this.intervals) {
      clearInterval(interval);
    }
    this.intervals = [];
  }
}
