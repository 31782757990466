<div class="row">
  <div class="col-md-4">
    <ug-text-filter-control [(filterText)]="filter"></ug-text-filter-control>
    <br />
    {{ selectedCompAttr | json }}
  </div>
  <div class="col-md-8">
    <ug-knowledge-select
      [funcAreaId]="40"
      [filter]="filter"
      [(selectedCompAttr)]="selectedCompAttr"
    ></ug-knowledge-select>
  </div>
</div>
