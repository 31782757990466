import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { ClaimService } from '../services/claim.service';

import { ClaimDocument } from '../models/claim-document';

@Component({
  selector: 'ug-claim-file-list',
  templateUrl: './claim-file-list.component.html',
  styleUrls: [],
})
export class ClaimFileListComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);

  @Input() claimId: number;
  claimDocumentList: Array<ClaimDocument>;
  // dataSrc: SafeResourceUrl;
  showDoc = false;
  selectedDocIdx: number = -1;
  docClaimId: number;
  docDocId: number;
  isMS = false;

  exceptionData = {
    CLAIM_DOCS: {
      level: AlertLevels.ERROR,
      code: 'CFL-001',
      message: 'Error retrieving claim documents',
      translationKey: 'errRetrievingAssessmentRequestDoc',
    } as AlertData,
  };

  claimDocSubs = new Subscription();

  ngOnChanges() {
    if (!this.claimId) {
      return;
    }
    this.claimDocSubs = this.claimService.getClaimDocs(this.claimId).subscribe(
      (cd) => (this.claimDocumentList = cd),
      (err) => {
        this.alertService.createAlert2(this.exceptionData.CLAIM_DOCS, err);
      }
    );
  }

  onViewClick(claimId: number, docId: number, docIdx: number) {
    this.docClaimId = claimId;
    this.docDocId = docId;
    this.selectedDocIdx = docIdx;
  }

  onHideClick(docId: number) {
    this.showDoc = false;
    this.selectedDocIdx = -1;
  }

  ngOnDestroy() {
    this.claimDocSubs.unsubscribe();
  }
}
