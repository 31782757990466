import { Component, Input, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { DateFilter } from '../../../controls/dynamic-form/control-types/filter-date';
import { DropdownFilter } from '../../../controls/dynamic-form/control-types/filter-dropdown';
import { DropdownSelectFilter } from '../../../controls/dynamic-form/control-types/filter-select';
import { FilterBase } from '../../../controls/dynamic-form/filter-base';
import { FilterControlService } from '../../../controls/dynamic-form/filter-control.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import {
  AttributeField,
  DynamicGroupsService,
  Filter,
  InvalidFilter,
  JoinOperator,
} from '../../../dynamic-groups/services/dynamic-groups.service';

@Component({
  selector: 'ug-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit {
  private dynamicGroupsService = inject(DynamicGroupsService);
  private fb = inject(FormBuilder);
  private filterControlService = inject(FilterControlService);
  private alertService = inject(AlertService);

  exceptionData = {
    ATTRIBUTE_FIELDS: {
      level: AlertLevels.ERROR,
      code: 'JGF-001',
      message: 'Error retrieving attribute fields and operators',
    } as AlertData,
    LOOKUP_VALUES: {
      level: AlertLevels.ERROR,
      code: 'JGF-002',
      message: 'Error retrieving lookup values',
    } as AlertData,
    SEARCH_VALUES: {
      level: AlertLevels.ERROR,
      code: 'JGF-003',
      message: 'Error retrieving lookup values based on search term',
    } as AlertData,
  };

  @Input() groupFiltersForm: FormGroup;
  @Input() peopleCount: number = 0;
  @Input() gettingFilteredPeople: boolean = false;
  @Input() discardChangesSubj: Subject<boolean> = new Subject();
  @Input() groupFilterId: number;
  @Input() allowEditGroup: boolean;

  JoinOperator = JoinOperator;
  InvalidFilter = InvalidFilter;
  isLoading: boolean;
  attributeFields: Array<{ key: string; value: AttributeField }> = [];
  booleanValueList = [
    { key: 'True', value: { key: 'True', name: 'True' } },
    { key: 'False', value: { key: 'False', name: 'False' } },
  ];
  groupFilterObs: Observable<any>[];
  ngUnsubscribe: Subject<boolean> = new Subject();
  maxNumOfChars: number = 800;
  filterReset: Subject<boolean> = new Subject();
  externalActiveOptions = [
    { key: 'Active', value: { key: 'Active', value: 'True' } },
    { key: 'Inactive', value: { key: 'Inactive', value: 'False' } },
    { key: 'All', value: { key: 'All', value: null } },
  ];

  ngOnInit(): void {
    this.isLoading = true;

    if (this.groupFilterId === 0) {
      this.groupFiltersForm
        .get('externalActive')
        .setValue(this.externalActiveOptions[0].value);
      this.dynamicGroupsService.getAttributeFields('Person').subscribe({
        next: (afs: AttributeField[]) => {
          this.attributeFields = afs
            .filter((af1) => af1.lookupParentKey !== 'Group')
            .map((af2) => ({ key: af2.name, value: af2 }));
          this.addGroupConditions();
          if (!this.allowEditGroup) {
            this.groupFiltersForm.disable();
          }
          this.isLoading = false;
        },
        error: (err) =>
          this.alertService.createAlert2(
            this.exceptionData.ATTRIBUTE_FIELDS,
            err,
          ),
      });
    } else {
      this.setupGroupForm();
    }

    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        if (this.groupFilterId !== 0) {
          this.resetGroupFormFields();
          this.setupGroupForm();
        } else {
          this.resetGroupFormFields();
          this.addGroupConditions();
        }
      }
    });
  }

  resetGroupFormFields() {
    this.groupFiltersForm.get('groupName').setValue('');
    this.groupFiltersForm.get('groupDescription').setValue('');
    this.groups.clear();
  }

  setupGroupForm() {
    this.isLoading = true;
    this.groupFilterObs = [
      this.dynamicGroupsService.getAttributeFields('Person'),
      this.dynamicGroupsService.getFilterById(this.groupFilterId),
    ];
    forkJoin(this.groupFilterObs)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(([personAttributeFields, filter]) => ({
          personAttributeFields,
          filter,
        })),
      )
      .subscribe((obs) => {
        const attributes = obs.personAttributeFields.filter(
          (af) => af.lookupParentKey !== 'Group',
        );
        const filter = obs.filter as Filter;
        this.attributeFields = attributes.map((af) => ({
          key: af.name,
          value: af,
        }));

        this.groupFiltersForm.get('groupName').setValue(filter.name);
        this.groupFiltersForm
          .get('groupDescription')
          .setValue(filter.description);

        const totalParsedGroup = JSON.parse(filter.filterJson);
        const totalParsedUiGroup = JSON.parse(filter.uiFilterInfo);

        //gets external active filter as either True, False, or "Any"
        const personActiveFilter = totalParsedGroup[2];
        let personActiveOption;

        // checks if external active condition exists at position [2] of filter and sets form value
        // if it doesn't exist, set default of active = true.

        if (personActiveFilter && personActiveFilter[0] === 'ExternalActive') {
          const personActiveValue = personActiveFilter[2];
          personActiveOption = this.externalActiveOptions.find(
            (opt) => opt.value.value === personActiveValue,
          );
          if (personActiveOption) {
            this.groupFiltersForm
              .get('externalActive')
              .setValue(personActiveOption.value);
          } else {
            this.groupFiltersForm
              .get('externalActive')
              .setValue(this.externalActiveOptions[0].value);
            // console.log(
            //   'External active filter not available, set to default of active',
            // );
          }
        } else {
          this.groupFiltersForm
            .get('externalActive')
            .setValue(this.externalActiveOptions[0].value);
          // console.log(
          //   'External active filter not available, set to default of active',
          // );
        }

        //removes 'hidden' group for "External Active = True"
        let parsedFilter = totalParsedGroup[0][0];
        let parsedUiFilter = totalParsedUiGroup[0][0];

        //checking if its just one filter or one group, otherwise looping through to create multiple groups
        if (
          this.checkTypes(parsedFilter) ||
          parsedFilter.every((f) => this.checkTypes(f))
        ) {
          const group = this.setupGroupConditions(parsedFilter, parsedUiFilter);
          this.groups.push(group);
        } else {
          let groupIndex = 0;
          parsedFilter.forEach((f, i) => {
            if (Array.isArray(f)) {
              const group = this.setupGroupConditions(f, parsedUiFilter[i]);
              this.groups.push(group);
            } else {
              const group = this.groups.at(groupIndex) as FormGroup;
              group.addControl('groupJoin', this.fb.control(f));
              groupIndex++;
            }
          });
        }
        if (!this.allowEditGroup) {
          this.groupFiltersForm.disable();
        }
        this.isLoading = false;
      });
  }

  setupGroupConditions(fs: Array<any>, uif: Array<any>) {
    const group = this.fb.group({
      conditions: this.fb.array([]),
    });

    const formArray = group.get('conditions') as FormArray;
    let formArrayIndex = 0;

    if (this.checkTypes(fs)) {
      const condition = this.setupCondition(fs, uif);
      formArray.push(condition);
    } else {
      fs.forEach((f, i) => {
        if (Array.isArray(f)) {
          const condition = this.setupCondition(f, uif[i]);
          formArray.push(condition);
        } else {
          formArray.at(formArrayIndex).get('join').setValue(f);
          formArrayIndex++;
        }
      });
    }
    return group;
  }

  addGroupConditions() {
    const group = this.fb.group({
      conditions: this.fb.array([this.createCondition()]),
    });
    this.groups.push(group);
  }

  setupCondition(jsonFilter: Array<any>, uiFilter: Array<any>) {
    const filterArr = jsonFilter;
    const filterEntityPath = filterArr[0];
    const filterAttributeParentKey = uiFilter[3];
    const filterOperator = filterArr[1];
    const filterValue = filterArr[2];

    const form = this.createConditionForm();
    form.addValidators(this.customValidator());
    if (Array.isArray(filterValue)) {
      form.get('filters').value[2].multiple = true;
    }

    //setting attribute field value
    const attributeIndex = this.attributeFields.findIndex(
      (x) =>
        x.value.entityPath === filterEntityPath &&
        x.value.lookupParentKey === filterAttributeParentKey,
    );
    if (attributeIndex !== -1) {
      const attribute = this.attributeFields[attributeIndex].value;
      form.get('attribute').setValue(attribute);

      //setting operator dropdown list and value
      const operatorList = attribute.operators.map((op) => ({
        key: op.name,
        value: op,
      }));
      form.get('filters').value[1].options = operatorList;
      const operatorIndex = operatorList.findIndex(
        (x) => x.value.operator === filterOperator,
      );
      const operator = operatorList[operatorIndex].value;
      form
        .get('conditionalOperator')
        .setValue(operatorList[operatorIndex].value);

      this.setValueControl(form, attribute);

      if (operator.noValueRequired) {
        form.get('value').disable();
      } else {
        if (attribute.dataType === 'Boolean') {
          form.get('filters').value[2].options = this.booleanValueList;
          const valueIndex = this.booleanValueList.findIndex(
            (bv) => bv.key === filterValue,
          );
          const boolValue = this.booleanValueList[valueIndex].value;
          form.get('value').setValue(boolValue);
        } else if (attribute.dataType === 'DateTime') {
          form.get('value').setValue(filterValue);
        } else if (attribute.useSearchableLookup) {
          form.get('filters').value[2].options = [];

          let filterString = '';
          if (operator.operator === 'in' || operator.operator === '!in') {
            filterString = JSON.stringify(['Id', 'in', filterValue]);
          } else {
            filterString = JSON.stringify(['Id', '=', filterValue]);
          }

          const filterData = { filter: filterString, page: 0, pageSize: 0 };

          this.dynamicGroupsService
            .getFilteredEntityValues('Person', filterData)
            .subscribe((filteredValues) => {
              const valueList = filteredValues.map((fv) => ({
                hierarchyKey: null,
                key: fv.id.toString(),
                name: `${fv.displayName} (${fv.externalUserid})`,
                parentKey: null,
              }));

              if (operator.operator === 'in' || operator.operator === '!in') {
                const filterValueArray = [];
                valueList.forEach((vi) => {
                  filterValueArray.push(vi);
                });
                form.get('value').setValue(filterValueArray);
              } else {
                const filterValue = valueList[0];
                form.get('value').setValue(filterValue);
              }
            });

          this.peopleSearchTypeahead(form, attribute.name);
        } else {
          form.get('filters').value[2].filterLoading = true;
          this.dynamicGroupsService
            .getLookupValues(attribute.lookupEntity, attribute.lookupParentKey)
            .subscribe((lvs) => {
              const valueList = lvs.map((vf) => ({ key: vf.name, value: vf }));
              form.get('filters').value[2].options = valueList;

              if (Array.isArray(filterValue)) {
                const valueArr = [];
                filterValue.forEach((fv) => {
                  const valueIndex = lvs.findIndex((lv) => lv.key === fv);
                  if (valueIndex !== -1) {
                    valueArr.push(valueList[valueIndex].value);
                  } else {
                    form.get('filters').value[2].options.push({
                      key: InvalidFilter.Value,
                      value: InvalidFilter.Value,
                      hide: true,
                    });
                    valueArr.push(InvalidFilter.Value);
                  }
                });
                form.get('value').setValue(valueArr);
              } else {
                const valueIndex = lvs.findIndex(
                  (lv) => lv.key === filterValue,
                );
                if (valueIndex !== -1) {
                  const value = valueList[valueIndex].value;
                  form.get('value').setValue(value);
                } else {
                  form.get('filters').value[2].options.push({
                    key: InvalidFilter.Value,
                    value: InvalidFilter.Value,
                    hide: true,
                  });
                  form.get('value').setValue(InvalidFilter.Value);
                }
              }

              form.get('filters').value[2].filterLoading = false;
            });
        }
      }
    } else {
      form.get('filters').value[0].options.push({
        key: InvalidFilter.Attribute,
        value: InvalidFilter.Attribute,
        hide: true,
      });
      form.get('attribute').setValue(InvalidFilter.Attribute);
    }
    this.watchForFormChanges(form);
    return form;
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value.attribute === InvalidFilter.Attribute) {
        return { invalidAttrOrValError: `${InvalidFilter.Attribute}` };
      } else if (control.value.value === InvalidFilter.Value) {
        return { invalidAttrOrValError: `${InvalidFilter.Value}` };
      } else if (
        Array.isArray(control.value.value) &&
        control.value.value.some((item) => item === 'Invalid Value')
      ) {
        return { invalidAttrOrValError: `${InvalidFilter.Value}(s)` };
      }
      return null;
    };
  }

  createCondition(): FormGroup {
    const form = this.createConditionForm();
    this.watchForFormChanges(form);
    this.groupFiltersForm.markAsDirty();
    return form;
  }

  watchForFormChanges(form: FormGroup) {
    form.get('attribute').valueChanges.subscribe((sa) => {
      this.resetDropdowns(form);
      const operatorList = sa.operators.map((op) => ({
        key: op.name,
        value: op,
      }));
      form.get('filters').value[1].options = operatorList;
      form.get('conditionalOperator').setValue(operatorList[0].value);

      this.setValueControl(form, sa);
    });

    form.get('conditionalOperator').valueChanges.subscribe((operator) => {
      if (operator.noValueRequired) {
        form.get('value').setValue(null);
        form.get('value').disable();
      }
      if (operator.noValueRequired === false && form.get('value').disabled) {
        form.get('value').enable();
        form.get('value').setValue(null);
      }
      if (operator.operator === 'in' || operator.operator === '!in') {
        form.get('value').setValue(null);
        form.get('filters').value[2].multiple = true;
      } else {
        let val = form.get('value').value;
        if (Array.isArray(val)) {
          form.get('value').setValue(null);
        }
        form.get('filters').value[2].multiple = false;
      }
    });
  }

  createConditionForm(): FormGroup {
    const filters = [
      new DropdownFilter({
        key: 'attribute',
        label: 'Attribute Field',
        filterClass: 'col-sm-12 col-md-4',
        required: true,
        options: this.attributeFields,
        showFirstLabel: true,
      }),
      new DropdownFilter({
        key: 'conditionalOperator',
        label: 'Conditional Operator',
        filterClass: 'col-sm-12 col-md',
        required: true,
        options: [],
        showFirstLabel: true,
      }),
      new DropdownSelectFilter({
        key: 'value',
        filterClass: 'col-sm-12 col-md-6',
        multiple: false,
        required: true,
        label: 'Value',
        filterLoading: false,
        options: [],
        showFirstLabel: true,
        bindLabel: 'name',
      }),
    ];
    const form = this.filterControlService.toFormGroup(
      filters as FilterBase<string>[],
    );
    form.addControl('join', this.fb.control(''));
    form.addControl('filters', this.fb.control(filters));
    return form;
  }

  get groups(): FormArray {
    return this.groupFiltersForm.get('groups') as FormArray;
  }

  resetDropdowns(form: FormGroup) {
    form.get('value').setValue(null);
    form.get('filters').value[1].options = [];
    form.get('filters').value[2].options = [];
  }

  addCondition(groupIndex: number) {
    const conditions = this.getGroupConditions(groupIndex);
    conditions.push(this.createCondition());
  }

  getGroupConditions(groupIndex: number): FormArray {
    const group = this.groups.controls[groupIndex] as FormGroup;
    return group.get('conditions') as FormArray;
  }

  removeCondition(groupIndex: number, conditionIndex: number) {
    const conditions = this.getGroupConditions(groupIndex);
    if (conditions.length === 1) {
      this.removeGroup(groupIndex);
    } else {
      if (conditionIndex + 1 === conditions.length) {
        conditions
          .at(conditionIndex - 1)
          .get('join')
          .setValue('');
      }
      conditions.removeAt(conditionIndex);
      this.groupFiltersForm.markAsDirty();
    }
  }

  onNewGroupClick(groupIndex: number) {
    const group = this.groups.at(groupIndex) as FormGroup;
    group.addControl('groupJoin', this.fb.control(JoinOperator.And));
    this.addGroupConditions();
  }

  removeGroup(index: number) {
    const previousGroup = this.groups.at(index - 1) as FormGroup;
    if (index + 1 === this.groups.value.length) {
      previousGroup.removeControl('groupJoin');
    }
    this.groups.removeAt(index);
    this.groupFiltersForm.markAsDirty();
  }

  setJoinCondition(
    groupIndex: number,
    conditionIndex: number,
    operator: string,
  ) {
    const conditionFormArray = this.getConditions(groupIndex);
    if (conditionIndex + 1 === conditionFormArray.value.length) {
      this.addCondition(groupIndex);
    }
    const condition = this.getCondition(groupIndex, conditionIndex);
    condition.get('join').setValue(operator);
    condition.markAsDirty();
  }

  getConditions(groupIndex: number): FormArray {
    return this.groups.at(groupIndex).get('conditions') as FormArray;
  }

  getCondition(groupIndex: number, conditionIndex: number): AbstractControl {
    const conditionFormArray = this.getConditions(groupIndex);
    return conditionFormArray.at(conditionIndex);
  }

  setJoinGroup(groupIndex: number, operator: string) {
    const group = this.groups.at(groupIndex);
    group.get('groupJoin').setValue(operator);
    group.markAsDirty();
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  checkTypes(filterItem): boolean {
    if (Array.isArray(filterItem)) {
      return typeof filterItem[0] === 'string';
    } else if (filterItem === 'and' || filterItem === 'or') {
      return true;
    } else {
      return false;
    }
  }

  get groupDescriptionLength() {
    return this.groupFiltersForm.get('groupDescription').value.length;
  }

  setValueControl(form: FormGroup, selectedAttribute) {
    const operator = form.get('conditionalOperator').value;

    let multiple = false;
    if (operator.operator === 'in' || operator.operator === '!in') {
      multiple = true;
    }

    if (selectedAttribute.dataType === 'DateTime') {
      form.get('filters').value[2] = new DateFilter({
        key: 'value',
        label: 'Value',
        filterClass: 'col-sm-12 col-md-6',
        required: true,
        showFirstLabel: true,
      });
    } else if (selectedAttribute.useSearchableLookup) {
      form.get('filters').value[2] = new DropdownSelectFilter({
        key: 'value',
        filterClass: 'col-sm-12 col-md-6',
        multiple: multiple,
        required: true,
        label: 'Value',
        filterLoading: false,
        options: [],
        showFirstLabel: true,
        typeaheadSubject: new Subject<string>(),
        items: of([]),
        bindLabel: 'name',
      });
      this.peopleSearchTypeahead(form, selectedAttribute.name);
    } else {
      form.get('filters').value[2] = new DropdownSelectFilter({
        key: 'value',
        filterClass: 'col-sm-12 col-md-6',
        multiple: multiple,
        required: true,
        label: 'Value',
        filterLoading: false,
        options: [],
        showFirstLabel: true,
        bindLabel: 'name',
      });

      if (selectedAttribute.dataType === 'Boolean') {
        form.get('filters').value[2].options = this.booleanValueList;
      } else {
        form.get('filters').value[2].filterLoading = true;
        this.dynamicGroupsService
          .getLookupValues(
            selectedAttribute.lookupEntity,
            selectedAttribute.lookupParentKey,
          )
          .subscribe({
            next: (vfs) => {
              const valueList = vfs.map((vf) => ({ key: vf.name, value: vf }));
              form.get('filters').value[2].options = valueList;
              form.get('filters').value[2].filterLoading = false;
            },
            error: (err) =>
              this.alertService.createAlert2(
                this.exceptionData.LOOKUP_VALUES,
                err,
              ),
          });
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  peopleSearchTypeahead(form: FormGroup, attributeName: string) {
    form.get('filters').value[2].items = form
      .get('filters')
      .value[2].typeaheadSubject.pipe(
        filter((res: string) => {
          return res !== null && res.length >= 2;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap((term: string) => {
          form.get('filters').value[2].filterLoading = true;
          return this.getPeopleSearch(term, attributeName, form);
        }),
      );
  }

  getPeopleSearch(
    term: string = null,
    attributeName: string,
    form,
  ): Observable<any> {
    let data = {
      parentKey: attributeName,
      search: term,
      maxCount: 50,
    };
    return this.dynamicGroupsService.filterSearchLookup('Person', data).pipe(
      finalize(() => {
        form.get('filters').value[2].filterLoading = false;
      }),
    );
  }
}
