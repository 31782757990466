export class Skill {
  id: number;
  funcAreaId: number;
  funcAreaName: string;
  funcAreaDesc: string;
  level: number;
  funcAreaTypeName: string;
  name: string;
  description: string;
  externalType: string;
  externalGUID: string;
  externalProvider: string;
  externalOwner: string;
  skillVersion: string;
}
