<ng-container [formGroup]="detailsFormGroup" *transloco="let t">
  <div
    *ngFor="let detail of detailsFormGroup.controls | keyvalue: originalOrder"
    class="my-3"
  >
    <ug-text-editor
      [controlName]="detail.key"
      [controlLabel]="t('additionalDetails.' + detail.key)"
      [controlPlaceholder]="
        t('additionalDetails.' + detail.key + 'Placeholder')
      "
      [parentFormGroup]="detailsFormGroup"
    >
    </ug-text-editor>
  </div>
</ng-container>
