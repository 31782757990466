<div>
  <span *ngFor="let item of [].constructor(numberOfPages); let i = index">
    <button
      [ngClass]="{ 'btn-border': i === currentPage }"
      class="btn"
      (click)="pageClick(i)"
    >
      {{ i + 1 }}
    </button>
  </span>
</div>
