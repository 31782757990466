<div class="card bg-light">
  <div class="card-body">
    <dl>
      <dt>Name</dt>
      <dd>{{ orgUnit.name }}</dd>
      <dt>Description</dt>
      <dd>{{ orgUnit.description || 'n/a' }}</dd>
      <dt>Parent Organisation Unit</dt>
      <dd>{{ orgUnit.parentOrgUnitName || 'None (top level)' }}</dd>
    </dl>
  </div>
</div>
