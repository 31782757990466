<div class="card shadow mb-4">
  <div class="card-body">
    <ug-list-status
      [isLoading]="loadingData"
      [listLength]="filteredOrgUnitMap.size > 0"
      [emptyListMessage]="'No matrix summary data available'"
    ></ug-list-status>
    <ng-container *ngIf="!loadingData && subjects.length">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeTab"
        class="nav-pills d-flex justify-content-evenly flex-wrap mb-2"
      >
        <div class="w-100">
          <p class="fw-bold">Category</p>
        </div>
        <li
          *ngFor="let subj of subjects; let tabIndex = index"
          [ngbNavItem]="tabIndex + 1"
          class="mb-2 max-tab-width"
        >
          <a ngbNavLink class="text-center" (click)="onSubjectClick(tabIndex)">
            {{ subj.name }} ({{ subj.complete | percent }})
          </a>
          <ng-template ngbNavContent>
            <ul
              class="nav nav-pills d-flex justify-content-center flex-wrap mb-1 border-top border-2 pt-2"
              id="pills-tab"
            >
              <div class="w-100">
                <p class="fw-bold">Subcategory</p>
              </div>
              <li
                *ngFor="let childSubj of subj.children; let childIndex = index"
                class="nav-item me-1"
              >
                <button
                  class="nav-link"
                  [ngClass]="{ active: childSubj.selected }"
                  (click)="onChildSubjectClick(childSubj, childIndex, tabIndex)"
                >
                  {{ childSubj.name }} ({{ childSubj.complete | percent }})
                </button>
              </li>
            </ul>
          </ng-template>
        </li>
        <div [ngbNavOutlet]="nav" class="content w-100"></div>
      </ul>
    </ng-container>
  </div>
</div>

<ng-container
  *ngFor="
    let row of filteredOrgUnitMap | keyvalue;
    let i = index;
    trackBy: trackByFn
  "
>
  <div class="card shadow ouRowCard my-4">
    <div class="card-header headerHeight">
      <div class="row">
        <div class="col-md-3 col-12 border-end">
          <div *ngIf="subjectBreadCrumb.length" class="card-title mb-0">
            <ng-container
              *ngFor="let bc of subjectBreadCrumb; let isLast = last"
            >
              <span>{{ bc }}</span>
              <ng-container *ngIf="!isLast"> | </ng-container>
            </ng-container>
          </div>
          <div class="card-subtitle text-muted">
            {{ row.key > 1 ? orgUnitBreadCrumb.get(i) : '' }}
          </div>
        </div>
        <ng-container *ngIf="!collapsedRows[row.key]; else collapsedRowHeader">
          <div class="col-md-4 col-12 align-self-center">
            <div class="input-group">
              <span class="input-group-text">
                <span class="fas fa-search text-center pt-1"></span>
              </span>
              <input
                [(ngModel)]="filterText[row.key]"
                (ngModelChange)="onFilterTextChange($event, row.key)"
                type="text"
                class="form-control"
                placeholder="Begin typing an org units name to filter results"
                id="ouFilterBox"
              />
              <button
                type="button"
                class="btn btn-outline-dark esqepBtn"
                (click)="onResetClick(row.key)"
              >
                Reset
              </button>
            </div>
          </div>
          <div class="col-md-3 col-12 align-self-center">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="sortByScore"
                [(ngModel)]="sortByScoreDesc[row.key]"
                (ngModelChange)="onSortByScoreDesc(row.key)"
              />
              <label class="form-check-label" for="sortByScore"
                >Sort by completion percentage</label
              >
            </div>
          </div>
        </ng-container>
        <ng-template #collapsedRowHeader>
          <div class="col-12 col-md-6 text-center">
            <p class="fw-bold">{{ selectedOuHeaders[row.key] }}</p>
          </div>
        </ng-template>

        <div class="col-md col-12 text-end">
          <button
            class="btn btn-link"
            [attr.aria-expanded]="collapsedRows[row.key]"
            (click)="toggleCollapse(row.key)"
          >
            <span
              class="fas"
              [ngClass]="
                !collapsedRows[row.key] ? 'fa-chevron-down' : 'fa-chevron-right'
              "
            ></span>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body" [ngbCollapse]="collapsedRows[row.key]">
      <div class="row align-items-end"></div>
      <div class="row justify-content-center">
        <div
          *ngFor="let ou of row.value; let j = index"
          class="col-md-6 col-lg-4 col-sm-12 mb-2 pa card-width"
        >
          <div
            class="card donutCard"
            [ngClass]="{ 'border-warning': ou.selected }"
          >
            <div class="card-body p-0 m-0" (click)="onOuClick(row.key, ou, i)">
              <ug-matrix-summary-donut-chart
                [publishedCount]="ou.publishedCount"
                [totalCount]="ou.totalCount"
                [chartTitle]="ou.name"
              ></ug-matrix-summary-donut-chart>
            </div>
            <div class="card-footer bg-transparent border-0 card-view-matrix">
              <button
                class="btn btn-link view-matrix-btn"
                (click)="viewMatrix(ou, row.key)"
              >
                View Matrix
              </button>
            </div>
          </div>
        </div>
      </div>

      <ug-list-status
        *ngIf="row.value.length === 0"
        [emptyListMessage]="'No organisation units found'"
        [isLoading]="false"
        [listLength]="row.value.length"
      ></ug-list-status>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showMatrix">
  <ug-matrix
    [matrixSummaryTitle]="matrixSummaryTitle"
    [matrixType]="matrixType"
    [matrixSummaryFilters]="matrixSummaryFilters"
    [isForSummary]="true"
    [canAccessOuData]="canAccessOuData"
  ></ug-matrix
></ng-container>
