import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  map,
  throwError,
} from 'rxjs';

import { environment } from '../../../environments/environment';

import { Assessor } from '../../assessor/models/assessor';
import { Skill } from '../../skill/models/skill';
import { Claim } from '../models/claim';
import { ClaimAction } from '../models/claim-action';
import { ClaimDocument } from '../models/claim-document';
import { ClaimEvidence } from '../models/claim-evidence';
import { ClaimHistory } from '../models/claim-history';
import { ClaimLevel } from '../models/claim-level';
import { PublishedClaim } from '../models/published-claim';

export interface SharedInfo {
  [sharedDataType: string]: {
    sharedInfoId?: number;
    personId?: number;
    createTimestamp?: string;
    uniqueId?: string;
    sharedInfoData: Array<PublishedClaim>;
  };
}

export interface SharedInfoResponse {
  [sharedDataType: string]: string;
}

export interface ClaimLevelDictionary {
  [parentKey: string]: [
    {
      key: string;
      name: string;
      parentKey: string;
      hierarchyKey: string;
    },
  ];
}

export interface ClaimSubmissionActivity {
  id: number;
  assessorId?: number;
  assessorName: string;
  claimLevelId?: number;
  claimLevelName: string;
  claimantId: number;
  competencyDimensionId: number;
  competencyDimensionName: string;
  createDatetime?: Date;
  disciplineId: number;
  disciplineName: string;
  isOpen?: boolean;
  isPrivate?: boolean;
  lastActionDatetime?: Date;
  lastActionName: string;
  skillId: number;
  skillName: string;
  status: string;
  statusId: number;
  statusText: string;
  expiryDate?: Date;
  isSelfAssessed?: boolean;
}

export interface ClaimSubmissionActivitySummary {
  categoryCode: string;
  categoryDescription: string;
  categoryForClaimant: boolean;
  categoryName: string;
  categorySortOrder: number;
  claimList: Array<ClaimSubmissionActivity>;
  notificationClaimIdList: Array<number>;
}

export interface ModifyClaimData {
  claimLevelId: number;
  assessorId?: number;
  isSelfAssessed?: boolean;
  comment?: string;
  claimStatusId?: number;
  claimActionId?: number;
  initiatorType: string;
  evidenceId?: number;
  evidenceDescription: string;
  assessorChangeReason?: string;
  expiryDate?: string;
}

export interface ClaimData {
  claim?: DotNetClaim;
  action?: ClaimAction;
  assessor?: Assessor;
  evidence?: any;
  level?: ClaimLevel;
  skill?: Skill;
  initiator?: Initiator;
}

interface Initiator {
  initiatorType: string;
  personId: number;
}

export interface DotNetClaim {
  assessorId: number;
  assessorName: string;
  assignedDate: string;
  claimLevelId: number;
  claimLevelName: string;
  claimStatusId: number;
  competencyDimensionId: number;
  competencyDimensionName: string;
  completionDate: string;
  createDatetime: string;
  disciplineId: number;
  disciplineName: string;
  dueDate: string;
  expiryDate: string;
  id: number;
  isOpen: boolean;
  isPrivate: boolean;
  isPublished: boolean;
  isSelfAssessed: boolean;
  lastModified: string;
  personId: number;
  personName: string;
  regNum: number;
  registrationDate: string;
  skillId: number;
  skillName: string;
  statusText: string;
}

const apiServerUri = environment.apiUrl;
const serverUrl = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class ClaimService implements OnDestroy {
  private http = inject(HttpClient);

  createClaimData = {};
  eviFiles = [];
  newClaim = new Claim();
  claimLevelFeeds = {}; // : Array<BehaviorSubject<Array<ClaimLevel>>> = [];
  claimLevelsSubs = new Subscription();

  bulkClaimSummary: BehaviorSubject<any> = new BehaviorSubject<any>({});
  bulkClaimLevelCache: BehaviorSubject<any> = new BehaviorSubject<any>({});
  claimActivitySummary: BehaviorSubject<any> = new BehaviorSubject<any>({});
  sharedClaimActivitySummary: BehaviorSubject<any> = new BehaviorSubject<any>(
    {},
  );

  private _activityListData: any;

  get activityListData(): any {
    return this._activityListData;
  }

  set activityListData(value: any) {
    this._activityListData = value;
  }

  getClaimLevelsFeed(disciplineId: number): BehaviorSubject<Array<ClaimLevel>> {
    if (!this.claimLevelFeeds[disciplineId]) {
      this.claimLevelFeeds[disciplineId] = new BehaviorSubject<
        Array<ClaimLevel>
      >([]);
      this.claimLevelsSubs.add(
        this.getFuncAreaClaimLevels(disciplineId).subscribe((cl) =>
          this.claimLevelFeeds[disciplineId].next(cl),
        ),
      );
    }
    return this.claimLevelFeeds[disciplineId];
  }

  getPublishedClaims(claimantId: number): Observable<SharedInfo> {
    return this.http
      .get<SharedInfo>(
        `${apiServerUri}/claims/claimant/${claimantId}/published`,
      )
      .pipe(
        map((resp) => {
          return resp || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getClaim(claimId: number): Observable<Claim> {
    return this.http.get(`${apiServerUri}/claim/${claimId}`).pipe(
      map((resp) => {
        return resp['claim'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getClaimActions(
    claimId: number,
    initiatorType: string,
  ): Observable<Array<ClaimAction>> {
    return this.http
      .get(
        `${apiServerUri}/claim/${claimId}/actions/initiator/${initiatorType}`,
      )
      .pipe(
        map((resp) => {
          return resp['claimActions'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getClaimEvidence(claimId: number): Observable<Array<ClaimEvidence>> {
    return this.http.get(`${apiServerUri}/claims/${claimId}/evidence`).pipe(
      map((resp) => {
        return resp['claimEvidence'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getClaimDocs(claimId: number): Observable<Array<ClaimDocument>> {
    return this.http.get(`${apiServerUri}/claim/${claimId}/doc`).pipe(
      map((resp) => {
        return resp['data'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getClaimDoc(claimId: number, docId: number): Observable<Blob> {
    return this.http
      .get(`${apiServerUri}/claim/${claimId}/doc/${docId}`, {
        responseType: 'blob',
      })
      .pipe(
        map((resp) => {
          return new Blob([resp], { type: resp.type });
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getClaimDocUrl(claimId: number, docId: number): string {
    return `${apiServerUri}/claim/${claimId}/doc2/${docId}.pdf`;
  }

  getClaimHistory(claimId: number): Observable<Array<ClaimHistory>> {
    return this.http.get(`${apiServerUri}/claims/${claimId}/history`).pipe(
      map((resp) => {
        return resp['claimHistory'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getFuncAreaClaimLevels(funcAreaId: number): Observable<Array<ClaimLevel>> {
    return this.http
      .get(`${apiServerUri}/claims/func-area-levels/${funcAreaId}`)
      .pipe(
        map((resp) => {
          return resp['claimsLevels'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getClaimLevel(claimLevelId: number): Observable<ClaimLevel> {
    return this.http.get(`${apiServerUri}/claims/levels/${claimLevelId}`).pipe(
      map((resp) => {
        return resp['claimLevel'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getClaimActivitySummary(personId: number): Observable<any> {
    return this.http
      .get(`${apiServerUri}/submissions/person/${personId}/activity-summary`)
      .pipe(
        map((resp) => {
          return resp['submissionsActivitySummary'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  clearClaimNotifications(
    claimId: number,
    personId: number,
    origActorType: string,
  ) {
    return this.http
      .put(
        `${apiServerUri}/submissions/${claimId}/notifications-by-person/${personId}/by-originator/${origActorType}/clear`,
        {},
      )
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  createClaim2(createClaimData: any): Observable<DotNetClaim> {
    const claimFormData = new FormData();

    // if (createClaimData['detail']['levelAndVerifier']['verifier']['selectedVerifier']) {
    //   claimFormData.append('assessorId', createClaimData['detail']['levelAndVerifier']['verifier']['id']);
    // }
    if (
      !createClaimData['detail']['levelAndVerifier']['level']['selfAssessed']
    ) {
      claimFormData.append(
        'assessorId',
        createClaimData['detail']['levelAndVerifier']['verifier'][
          'selectedVerifier'
        ]['assessorId'],
      );
    }
    claimFormData.append(
      'claimantId',
      createClaimData['detail']['claimant']['id'],
    );
    claimFormData.append(
      'claimLevelId',
      createClaimData['detail']['levelAndVerifier']['level']['id'],
    );
    claimFormData.append(
      'personId',
      createClaimData['detail']['claimant']['id'],
    );
    claimFormData.append(
      'skillId',
      createClaimData['detail']['competency']['competencyDetail']['id'],
    );
    claimFormData.append(
      'evidenceDescription',
      createClaimData['detail']['evidence']['statementText'],
    );
    if (createClaimData['detail']['evidence']['evidenceFiles'].length > 0) {
      claimFormData.append(
        'evidenceFile',
        createClaimData['detail']['evidence']['evidenceFiles'][0],
        createClaimData['detail']['evidence']['evidenceFiles'][0].name,
      );
    }

    return <Observable<DotNetClaim>>(
      this.http.post(`${serverUrl}/claims`, claimFormData).pipe(
        map((resp) => {
          return resp || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      )
    );
  }

  isClaimAssessor(claimId: number, assessorId: number): Observable<boolean> {
    return this.http.get(`${apiServerUri}/claim/${claimId}/person-role`).pipe(
      map((r) => {
        return r['personRoles']['verifier'] || false;
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  isClaimClaimant(claimId: number, claimantId: number): Observable<boolean> {
    return this.http.get(`${apiServerUri}/claim/${claimId}/person-role`).pipe(
      map((r) => {
        return r['personRoles']['claimant'] || false;
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  createPublishedClaimsShare(
    personId: number,
    publishedClaimsData: SharedInfo,
  ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${apiServerUri}/claims/claimant/${personId}/published/share`,
        JSON.stringify(publishedClaimsData),
        { headers: headers },
      )
      .pipe(
        map((resp: SharedInfoResponse) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  completeSMClaims(
    skillId: number,
    initiatorId: number,
    users: number[],
    scheduleTraining: boolean = false,
    dueDate: Date = null,
    expiryDate: Date = null,
  ) {
    const payload = {
      completions: {
        skillId: skillId,
        initiatorId: initiatorId,
        users: users,
        scheduleTraining: scheduleTraining,
        dueDate: dueDate,
        expiryDate: expiryDate,
      },
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(
        `${apiServerUri}/claims/sm-complete-items`,
        JSON.stringify(payload),
        { headers: headers },
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  ngOnDestroy() {
    this.claimLevelsSubs.unsubscribe();
  }

  getAllClaimLevels(): Observable<ClaimLevelDictionary> {
    return this.http.get(`${serverUrl}/claimLevels/dictionary`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getClaimsByStatus(
    statusId,
    type: 'claimant' | 'assessor',
  ): Observable<ClaimSubmissionActivitySummary> {
    return this.http
      .get(`${serverUrl}/claims/byStatusFor${type}/${statusId}`)
      .pipe(
        map((resp) => {
          return (resp as any) || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  modifyClaim(claimData: ClaimData): Observable<DotNetClaim> {
    const modifiedClaimData: ModifyClaimData = {
      claimLevelId: claimData.level.id,
      assessorId: claimData.assessor?.assessorId,
      isSelfAssessed: claimData.level?.selfAssessed,
      claimStatusId:
        claimData.action?.resultingClaimStatusId ??
        claimData.claim.claimStatusId,
      claimActionId: claimData.action?.id,
      initiatorType: claimData.initiator?.initiatorType,
      evidenceId: claimData.evidence?.statement?.id,
      evidenceDescription:
        claimData.evidence?.assessorStatement ??
        claimData.evidence?.statement?.description,
      assessorChangeReason: claimData.evidence?.assessorChangeReason,
      expiryDate: claimData.claim.expiryDate,
    };

    return this.http.post<DotNetClaim>(
      `${serverUrl}/claims/${claimData.claim.id}`,
      modifiedClaimData,
    );
  }

  getClaimById(claimId: number): Observable<DotNetClaim> {
    return this.http.get<DotNetClaim>(`${serverUrl}/claims/${claimId}`);
  }

  getSkillAnalyticsTrainingStatuses(): Observable<any> {
    return this.http.get<any>(`${serverUrl}/claims/publishedForClaimant`);
  }
}
