import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'app/core/services/config.service';
import { Subject, debounceTime, finalize } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../core/services/alert.service';
import { AuthService } from '../../../core/services/auth.service';
import { ProgressModalComponent } from '../../../shared/progress-modal/progress-modal.component';
import { MatrixPersonDetailComponent } from '../matrix-person-detail/matrix-person-detail.component';
import {
  ClaimsForTrainingMatrixRequest,
  MatrixGroupedSkills,
  MatrixPerson,
  MatrixPersonClaim,
  MatrixService,
  MatrixSkill,
  MatrixTab,
} from '../matrix.service';
import { TrainingBulkCompletionActionComponent } from '../training-bulk-completion-action/training-bulk-completion-action.component';
import { UserTaskStatusComponent } from '../user-task-status/user-task-status.component';

@Component({
  selector: 'ug-matrix-table',
  templateUrl: './matrix-table.component.html',
  styleUrls: ['./matrix-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixTableComponent implements OnInit, OnDestroy, DoCheck {
  private locale = inject(LOCALE_ID);
  private ngbModal = inject(NgbModal);
  private cd = inject(ChangeDetectorRef);
  matrixService = inject(MatrixService);
  private authService = inject(AuthService);
  private alertService = inject(AlertService);
  private router = inject(Router);
  protected hideActionButton =
    inject(ConfigService).hideActionButtonForManagers;

  exceptionData = {
    EXPRESS_CLASS: {
      level: AlertLevels.ERROR,
      code: 'MX-001',
      message: 'Error creating Express Class',
    } as AlertData,
  };

  @Input() set matrixTab(tab: MatrixTab) {
    this.unfilteredGroupedSkills = tab.groupedSkills;
    this.unfilteredUngroupedSkills = tab.ungroupedSkills;

    this.filteredGroupedSkills = [...tab.groupedSkills];
    this.filteredUngroupedSkills = [...tab.ungroupedSkills];
  }
  @Input() matrixPeople: Array<MatrixPerson>;
  @Input() fullFunc = false;
  @Input() matrixUpdated: Subject<boolean> = new Subject();
  @Input() matrixType;
  @Input() excludeLoggedInUser: boolean;
  @Input() set showUnassignedUsers(show: boolean) {
    this.showUnassigned = show;

    this.processPeopleVsSkills();
    this.processGroupedSkillsSummary();
    this.filteredMatrixPeople = this.filterMatrixPeople(this.personFilterText);
  }
  @Input() isManager = false;
  @Input() orgUnitType: string;
  @Input() stateSymbolMap: Map<string, string>;

  showUnassigned = false;
  filteredMatrixPeople: MatrixPerson[];
  unassignedUserIds: number[] = [];
  matrixTableTitle: string;
  awaitingCompleteConfirmation = false;

  uncollapsedGroup: Array<string> = [];
  personSkillMap = new Map<
    string,
    {
      stateSymbolClass: string;
      required: boolean;
      state: string;
    }
  >();
  personGroupCompletion = new Map<
    string,
    {
      requiredCount: number;
      percentComplete: number;
      progressClass: string;
      progressStyle: {
        width: number;
      };
      isAlert: boolean;
    }
  >();
  groupSummary = new Map<
    number,
    {
      average: number;
      progressClass: string;
      progressStyle: {
        width: number;
      };
    }
  >();
  personCompletion = new Map<
    number,
    {
      totalPercentComplete: number;
      totalRequiredCount: number;
      progressClass: string;
    }
  >();
  groupMeanPercentage = new Map<number, number[]>();
  unfilteredGroupedSkills: MatrixGroupedSkills[];
  unfilteredUngroupedSkills: MatrixSkill[];
  filteredGroupedSkills: MatrixGroupedSkills[];
  filteredUngroupedSkills: MatrixSkill[];
  collapsedRowMap = new Map<number, boolean>();
  currFilterText = '';
  personFilterText = '';
  skillFilterSubject = new Subject<string>();

  ngOnInit(): void {
    this.matrixTableTitle = this.matrixService.matrixTableTitle.get(
      this.matrixType,
    );

    this.skillFilterSubject.pipe(debounceTime(300)).subscribe({
      next: (filterText: string) => {
        this.filteredGroupedSkills = this.unfilteredGroupedSkills
          .map((groupSkill) => {
            if (groupSkill.name.toLowerCase().includes(filterText)) {
              return groupSkill;
            }

            const filteredSections = groupSkill.sections
              .map((section) => {
                if (section?.name?.toLowerCase().includes(filterText)) {
                  this.collapsedRowMap.set(groupSkill.id, false);
                  return section;
                }

                const filteredSkills = section.skills.filter((skill) =>
                  skill.name.toLowerCase().includes(filterText),
                );

                if (filteredSkills.length > 0) {
                  return {
                    ...section,
                    skills: filteredSkills,
                  };
                }
                return null;
              })
              .filter(Boolean);

            const filteredGroupSkills = groupSkill.skills.filter((skill) =>
              skill.name.toLowerCase().includes(filterText),
            );

            if (filteredSections.length > 0 || filteredGroupSkills.length > 0) {
              this.collapsedRowMap.set(groupSkill.id, false);
              return {
                ...groupSkill,
                sections: filteredSections,
                skills: filteredGroupSkills,
              };
            }
            return null;
          })
          .filter(Boolean);

        this.filteredUngroupedSkills = this.unfilteredUngroupedSkills.filter(
          (usk) =>
            usk.name
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase()),
        );
        this.unassignedUserIds = [];
        this.processPeopleVsSkills();
        this.processGroupedSkillsSummary();
        this.filteredMatrixPeople = this.filterMatrixPeople(
          this.personFilterText,
        );
      },
    });
  }

  filterMatrixPeople(personName?: string): MatrixPerson[] {
    return !this.showUnassigned
      ? this.matrixPeople.filter(
          (p) =>
            (p.isAdditional ||
              !this.unassignedUserIds.includes(p.id) ||
              p.id === this.authService.me.id) &&
            (personName
              ? p.displayName.toLowerCase().includes(personName.toLowerCase())
              : true),
        )
      : this.matrixPeople.filter((p) =>
          personName
            ? p.displayName.toLowerCase().includes(personName.toLowerCase())
            : true,
        );
  }

  processPeopleVsSkills(personIds: number[] = null) {
    const peopleToUpdate = personIds
      ? this.matrixPeople.filter((p) => personIds.includes(p.id))
      : this.matrixPeople;

    peopleToUpdate.forEach((p) => {
      let groupedPublishedCount = 0;
      let groupedRequiredCount = 0;
      let ungroupedPublishedCount = 0;
      let ungroupedRequiredCount = 0;

      this.filteredUngroupedSkills.forEach((sk) => {
        const claim = sk.claims.find((c) => c.personId === p.id);
        const personSkill = this.personSkill(sk.id, p.id, claim);
        ungroupedRequiredCount += personSkill.required;
        ungroupedPublishedCount += personSkill.publishedAndNotExpired;
      });

      this.filteredGroupedSkills.forEach((gsk) => {
        let requiredSkillCount = 0;
        let publishedSkillCount = 0;
        let isAlertCount = 0;

        if (gsk.sections.length > 0) {
          gsk.sections.forEach((section) => {
            section.skills.forEach((sk) => {
              const claim = sk.claims.find((c) => c.personId === p.id);
              const personSkill = this.personSkill(sk.id, p.id, claim, gsk.id);
              requiredSkillCount += personSkill.required;
              publishedSkillCount += personSkill.publishedAndNotExpired;
              isAlertCount += personSkill.isAlert ? 1 : 0;
            });
          });
        } else {
          gsk.skills.forEach((sk) => {
            const claim = sk.claims.find((c) => c.personId === p.id);
            const personSkill = this.personSkill(sk.id, p.id, claim, gsk.id);
            requiredSkillCount += personSkill.required;
            publishedSkillCount += personSkill.publishedAndNotExpired;
            isAlertCount += personSkill.isAlert ? 1 : 0;
          });
        }

        let percentComplete = publishedSkillCount / requiredSkillCount || 0;

        this.updatePersonGroupCompletion(
          p,
          gsk,
          requiredSkillCount,
          percentComplete,
          isAlertCount > 0,
        );

        if (requiredSkillCount) {
          if (!this.groupMeanPercentage.has(gsk.id)) {
            this.groupMeanPercentage.set(gsk.id, []);
          }
          this.groupMeanPercentage.get(gsk.id).push(percentComplete);

          groupedPublishedCount += percentComplete;
          groupedRequiredCount++;
        }
      });

      const personTotalPercentComplete =
        groupedRequiredCount > 0 || ungroupedRequiredCount > 0
          ? (groupedPublishedCount + ungroupedPublishedCount) /
            (groupedRequiredCount + ungroupedRequiredCount)
          : null;

      this.personCompletion.set(p.id, {
        totalPercentComplete: personTotalPercentComplete,
        totalRequiredCount: groupedRequiredCount + ungroupedRequiredCount,
        progressClass:
          personTotalPercentComplete !== null
            ? 'bg-' + this.progressClass(personTotalPercentComplete)
            : '',
      });

      if (personTotalPercentComplete === null) {
        this.unassignedUserIds.push(p.id);
      }
    });
  }

  personSkill(
    skillId: number,
    personId: number,
    personClaim: MatrixPersonClaim,
    curricSkillId?: number,
  ): { required: number; publishedAndNotExpired: number; isAlert: boolean } {
    this.updatePersonSkillMap(
      skillId,
      personId,
      personClaim,
      curricSkillId ? curricSkillId : null,
    );

    const required = personClaim?.isRequired ? 1 : 0;
    const publishedAndNotExpired =
      personClaim?.id &&
      personClaim?.isPublished &&
      personClaim.state !== 'Expired' &&
      personClaim.state !== 'Overdue'
        ? 1
        : 0;
    const isAlert = personClaim?.isAlert;

    return { required, publishedAndNotExpired, isAlert };
  }

  processGroupedSkillsSummary() {
    this.filteredGroupedSkills.forEach((gsk) => {
      if (!this.collapsedRowMap.has(gsk.id)) {
        this.collapsedRowMap.set(gsk.id, true);
      }

      const percentageArray = this.groupMeanPercentage.get(gsk.id);

      let averagePercentComplete = 0;
      if (percentageArray && percentageArray.length > 0) {
        averagePercentComplete =
          percentageArray.reduce((acc, curr) => acc + curr, 0) /
          percentageArray.length;
      }

      // Set the average percentage score for each gsk
      this.groupSummary.set(gsk.id, {
        average: averagePercentComplete,
        progressClass: 'bg-' + this.progressClass(averagePercentComplete),
        progressStyle: this.progressStyle(averagePercentComplete),
      });
    });
  }

  updatePersonGroupCompletion(
    p: MatrixPerson,
    gsk: MatrixGroupedSkills,
    requiredSkillCount: number,
    percentComplete: number,
    isAlert: boolean,
  ) {
    this.personGroupCompletion.set(`${p.id}_${gsk.id}`, {
      requiredCount: requiredSkillCount,
      percentComplete: percentComplete,
      progressClass: requiredSkillCount
        ? 'bg-' + this.progressClass(percentComplete)
        : 'bg-secondary',
      progressStyle: this.progressStyle(percentComplete),
      isAlert: isAlert,
    });
  }

  updatePersonSkillMap(
    skillId: number,
    personId: number,
    claim: MatrixPersonClaim,
    curricSkillId?: number,
  ) {
    const mapKey = curricSkillId
      ? `${personId}_${skillId}_${curricSkillId}`
      : `${personId}_${skillId}`;
    this.personSkillMap.set(mapKey, {
      stateSymbolClass: this.stateSymbolClass(claim),
      required: claim.isRequired ? true : false,
      state: claim.state,
    });
  }

  ngOnDestroy(): void {}

  onFilterTextChange(filterText: string): void {
    this.currFilterText = filterText;
    this.skillFilterSubject.next(filterText);
  }

  showUserDetail(person: MatrixPerson) {
    const modalRef = this.ngbModal.open(MatrixPersonDetailComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.currentUser = person;
    modalRef.componentInstance.orgUnitType = this.orgUnitType;
  }

  toggleRow(id: number) {
    this.collapsedRowMap.set(id, !this.collapsedRowMap.get(id));
  }

  showBulkSubmit(skill: MatrixSkill, groupedSkills?: MatrixGroupedSkills) {
    const peopleArray = [];
    this.matrixPeople.forEach((p) => {
      const claim = skill.claims.find((c) => c.personId === p.id);
      const userState = claim.state;
      const checkBoxDisabled =
        ['Awaiting sync', 'Updating'].includes(userState) ||
        !p.externalId ||
        !claim.isRequired;
      if (claim.isRequired) {
        peopleArray.push({
          personId: p.id,
          personName: p.displayName,
          personExternalId: p.externalId,
          trainingState: userState || 'Not activated',
          prevTrainingState: userState || 'Not activated',
          includePerson: !checkBoxDisabled,
          disabled: checkBoxDisabled,
          stateSymbolClass: this.stateSymbolClass(claim),
        });
      }
    });

    const modalRef = this.ngbModal.open(TrainingBulkCompletionActionComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.trainingTitle = skill.name;
    modalRef.componentInstance.curricula = groupedSkills;
    modalRef.componentInstance.subject = skill;
    modalRef.componentInstance.peopleArray = peopleArray;
    modalRef.componentInstance.users = this.matrixPeople;
    modalRef.componentInstance.learningObjectId = skill.objectId;

    modalRef.componentInstance.confirmBulkSubmitClicked.subscribe((fa) => {
      modalRef.close();
      const progressModal = this.ngbModal.open(ProgressModalComponent, {
        centered: true,
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      progressModal.componentInstance.modalTitle = 'Completing Training';

      const peopleFormArray: FormArray = fa as FormArray;
      const personIds: number[] = [];

      peopleFormArray.controls.forEach((p) => {
        const person = p.value;
        if (person.includePerson) {
          personIds.push(person.personId);
          p.get('trainingState')?.setValue('Updating');

          const mapKey = groupedSkills
            ? `${person.personId}_${skill.id}_${groupedSkills.id}`
            : `${person.personId}_${skill.id}`;

          this.personSkillMap.set(mapKey, {
            stateSymbolClass: this.stateSymbolMap.get('Updating'),
            required: true,
            state: 'Updating',
          });
        }
      });

      const claimData: ClaimsForTrainingMatrixRequest = {
        skillId: skill.id,
        skillObjectId: skill.objectId,
        personIds: personIds,
      };

      this.matrixService
        .completeClaimsForTrainingMatrix(claimData)
        .pipe(
          finalize(() => {
            this.processPeopleVsSkills(personIds);
            this.processGroupedSkillsSummary();
          }),
        )
        .subscribe({
          next: (c: any) => {
            personIds.forEach((personId) => {
              const updatedClaim = c.find(
                (claim) => claim.personId === personId,
              );
              const claim = skill.claims.find((c) => c.personId === personId);
              claim.state = 'Complete';
              claim.isPublished = updatedClaim.isPublished;
              claim.status = updatedClaim.statusText;
              claim.id = updatedClaim.id;
              claim.expiryDate = updatedClaim.expiryDate;
              claim.completionDate = updatedClaim.completionDate;
            });

            progressModal.componentInstance.updatesDone++;
          },
          error: (err) => {
            progressModal.componentInstance.updatesFailed++;
            this.alertService.createAlert2(
              this.exceptionData.EXPRESS_CLASS,
              err,
            );

            peopleFormArray.controls.forEach((p) => {
              const person = p.value;
              if (person.includePerson) {
                const claim = skill.claims.find(
                  (c) => c.personId === person.personId,
                );
                claim.state = person.prevTrainingState;
              }
            });
          },
        });

      this.awaitingCompleteConfirmation = false;
    });
  }

  onLinkClick(skill: MatrixSkill, gskId?: number) {
    const claim = skill.claims.find(
      (c) => c.personId === this.authService.me.id,
    );
    if (claim) {
      claim.state = 'Awaiting Sync';
      this.updatePersonSkillMap(
        skill.id,
        this.matrixPeople[0].id,
        claim,
        gskId ? gskId : null,
      );
    }
  }

  modifySkill($event: {
    skill: MatrixSkill;
    person: MatrixPerson;
    gsk: MatrixGroupedSkills;
  }) {
    const person = $event.person;
    const skill = $event.skill;
    const groupedSkills = $event.gsk;

    const modalRef = this.ngbModal.open(UserTaskStatusComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.person = person;
    modalRef.componentInstance.groupedSkills = groupedSkills;
    modalRef.componentInstance.skill = skill;
    modalRef.componentInstance.fullFunc = this.fullFunc;
    modalRef.componentInstance.matrixType = this.matrixType;
    modalRef.componentInstance.isManager = this.isManager;
    modalRef.componentInstance.hideActionButton = this.hideActionButton;

    const claim = skill.claims.find((c) => c.personId === person.id);
    const prevState: string = claim.state;

    modalRef.componentInstance.deepLinkClicked.subscribe((event) => {
      claim.state = event;
    });

    modalRef.componentInstance.confirmCompleteClicked.subscribe((newState) => {
      claim.state = 'Updating';
      const progressModal = this.ngbModal.open(ProgressModalComponent, {
        centered: true,
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      progressModal.componentInstance.modalTitle = 'Completing Training';

      const claimData: ClaimsForTrainingMatrixRequest = {
        skillId: skill.id,
        skillObjectId: skill.objectId,
        personIds: [person.id],
      };

      // TODO: ADD LOGIC TO HANDLE onConfirmCompleteClick METHOD

      this.matrixService
        .completeClaimsForTrainingMatrix(claimData)
        .pipe(
          finalize(() => {
            this.processPeopleVsSkills([person.id]);
            this.processGroupedSkillsSummary();
          }),
        )
        .subscribe({
          next: (c: any) => {
            claim.state = newState;
            claim.expiryDate = c[0].expiryDate;
            claim.isPublished = c[0].isPublished;
            claim.status = c[0].statusText;
            claim.id = c[0].id;
            claim.completionDate = c[0].completionDate;
            progressModal.componentInstance.updatesDone++;
          },
          error: (err) => {
            progressModal.componentInstance.updatesFailed++;
            this.alertService.createAlert2(
              this.exceptionData.EXPRESS_CLASS,
              err,
            );
            claim.state = prevState;
          },
        });

      this.awaitingCompleteConfirmation = false;
    });
  }

  private progressClass(percentComplete: number): string {
    if (percentComplete <= 0.25) {
      return 'danger';
    } else if (percentComplete <= 0.75) {
      return 'warning-matrix';
    }
    return 'success';
  }

  private progressStyle(percentComplete: number): any {
    return { width: `${percentComplete * 100}%` };
  }

  stateSymbolClass(claim: MatrixPersonClaim): string {
    return this.stateSymbolMap.get(claim.state);
  }

  ngDoCheck(): void {
    this.cd.markForCheck();
  }

  showEventCalendar(skill: MatrixSkill) {
    this.router.navigate([`events-calendar/${skill.id}`]);
  }

  trackById(index, item) {
    return item.id; // or any unique property of the item
  }

  actionButtonClick(sk: MatrixSkill) {
    if (sk.type === MatrixService.EVENT) {
      this.showEventCalendar(sk);
    } else {
      this.showBulkSubmit(sk);
    }
  }

  onPersonFilterTextChange($event) {
    this.personFilterText = $event;
    this.filteredMatrixPeople = this.filterMatrixPeople(this.personFilterText);
  }

  @Output() showUnassignedUsersEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  toggleUnassignedUsers($event: any) {
    // this.filteredMatrixPeople = this.filterMatrixPeople();
    this.showUnassignedUsersEvent.emit($event.target.checked);
  }
}
