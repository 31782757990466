import { Component, Input, OnInit, inject } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';

import { DatePipe } from '@angular/common';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { JobRolePeopleAudit } from '../models/job-role-people-audit';
import { JobRoleService } from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-people-audit',
  templateUrl: './job-role-people-audit.component.html',
  styleUrls: ['./job-role-people-audit.component.css'],
  providers: [DatePipe],
})
export class JobRolePeopleAuditComponent implements OnInit {
  datepipe = inject(DatePipe);
  private jobRoleService = inject(JobRoleService);

  @Input() jobRoleId: number;

  tableHeaders = [];
  tableRows = [];
  tableFilters = [];
  totalRecords: number;

  auditItems: JobRolePeopleAudit[] = [];
  ngUnsubscribe: Subject<boolean> = new Subject();
  loadingData = true;

  ngOnInit(): void {
    this.tableHeaders = [
      { id: 'lastModifiedDt', title: 'Date', stringFunction: this.dateDisplay },
      { id: 'personDisplayName', title: 'Person Name' },
      { id: 'personUsername', title: 'Person Username' },
      { id: 'action', title: 'Action', stringFunction: this.actionDisplay },
      { id: 'auditPersonDisplayName', title: 'By' },
      { id: 'auditPersonUserName', title: 'User Name' },
    ];

    this.tableFilters = [
      new TextboxFilter({
        key: 'searchTerm',
        label: 'Search audit history',
        order: 1,
        placeholder: 'Search audit history',
      }),
    ];

    this.getPeopleAudit();
    this.jobRoleService.updatePeopleAuditHistory.subscribe((upa) => {
      if (upa) {
        this.getPeopleAudit();
      }
    });
  }

  displayAction(actionCode: string): string {
    if (actionCode === 'I') {
      return 'Added';
    } else if (actionCode === 'U') {
      return 'Updated';
    } else if (actionCode === 'D') {
      return 'Removed';
    } else {
      return 'Unknown action';
    }
  }

  actionDisplay = (row) => {
    if (row.action === 'I') {
      return 'Added';
    } else if (row.action === 'U') {
      return 'Updated';
    } else if (row.action === 'D') {
      return 'Removed';
    } else {
      return 'Unknown action';
    }
  };

  trackByPerson(index: number, requirement: JobRolePeopleAudit): number {
    return index;
  }

  dateDisplay = (row) => {
    return this.datepipe.transform(row.lastModifiedDt, 'YYYY-MM-dd HH:mm:ss');
  };

  getPeopleAudit() {
    this.loadingData = true;
    this.jobRoleService
      .listJobRolePeopleAudit(this.jobRoleId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingData = false)),
      )
      .subscribe({
        next: (jta) => {
          this.auditItems = jta;
          this.tableRows = this.auditItems;

          this.loadingData = false;
        },
        error: (err) => console.log(err),
      });
  }
}
