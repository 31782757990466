import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SpinnerService } from '../../controls/services/spinner.service';
import { AuthService } from '../services/auth.service';

export enum ExceptionTypes {
  NOT_AUTH,
  NOT_USER,
  NOT_CUSTOMER,
}

export enum ExceptionActions {
  LOGOUT,
  HOME,
  ACKNOWLEDGED,
}

@Component({
  selector: 'ug-exception',
  templateUrl: './exception.component.html',
  styleUrls: [],
})
export class ExceptionComponent implements OnInit {
  private router = inject(Router);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private spinnerService = inject(SpinnerService);

  exceptionActions = ExceptionActions;

  @Input()
  exceptionType: number;

  exceptionConfig = {
    [ExceptionTypes.NOT_AUTH]: {
      title: 'Not authorised',
      description:
        'You do not have permission to view the page that you requested',
      actions: [this.exceptionActions.LOGOUT, this.exceptionActions.HOME],
    },
    [ExceptionTypes.NOT_USER]: {
      title: 'Not a valid user',
      description: `
        Although you have been successfully authenticated, you have not been enabled as a user of this system.
        Contact your system administrator to request user access.
      `,
      actions: [this.exceptionActions.LOGOUT],
    },
    [ExceptionTypes.NOT_CUSTOMER]: {
      title: 'Not a valid user',
      description: `
        Although you have been successfully authenticated, you do not have access to this application.
      `,
      actions: [this.exceptionActions.ACKNOWLEDGED],
    },
  };

  ngOnInit() {
    this.spinnerService.reset(); // Cancel outstanding spinner. TODO: Should handle in spinner itself?
    if (!this.exceptionType) {
      this.exceptionType =
        ExceptionTypes[this.route.snapshot.paramMap.get('type').toUpperCase()];
    }
  }

  home() {
    this.router.navigate(['home']);
  }

  logout() {
    this.authService.logout();
  }
}
