import { Component, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OperatorFunction,
  Subject,
  catchError,
  defaultIfEmpty,
  forkJoin,
  map,
  of,
  takeUntil,
} from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  CompetenciesService,
  SkillDimension,
  SkillDimensionId,
  SkillSubject,
  SubjectLevels,
  SubjectPayload,
} from '../services/competencies.service';
import { AssignmentModalComponent } from './assignment-modal/assignment-modal.component';
import { SubjectModalComponent } from './subject-modal/subject-modal.component';

@Component({
  selector: 'ug-competency-hierarchy',
  templateUrl: './competency-hierarchy.component.html',
  styleUrls: ['./competency-hierarchy.component.scss'],
})
export class CompetencyHierarchyComponent implements OnInit {
  private competencyService = inject(CompetenciesService);
  private typeaheadService = inject(TypeaheadService);
  private uiService = inject(UitoolsService);
  private alertService = inject(AlertService);
  private ngbModal = inject(NgbModal);
  private formBuilder = inject(FormBuilder);

  exceptionData = {
    SKILL_DIMENSIONS: {
      level: AlertLevels.ERROR,
      code: 'SH-001',
      message: 'Error retrieving skill dimensions',
    } as AlertData,
    SUBJECT_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'SH-002',
      message: 'Error retrieving subject levels',
    } as AlertData,
    SUBJECT_DICTIONARY: {
      level: AlertLevels.ERROR,
      code: 'SH-003',
      message: 'Error retrieving subject dictionary',
    } as AlertData,
    SUBJECT_ADD_DIMENSIONS: {
      level: AlertLevels.ERROR,
      code: 'SH-004',
      message: 'Error adding subject dimensions',
    } as AlertData,
    SUBJECT_ADD: {
      level: AlertLevels.ERROR,
      code: 'SH-005',
      message: 'Error adding subject',
    } as AlertData,
    SUBJECT_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'SH-006',
      message: 'Error updating subject',
    } as AlertData,
    SUBJECT_UPDATE_DIMENSIONS_CHILDREN: {
      level: AlertLevels.ERROR,
      code: 'SH-007',
      message: 'Error updating dimensions for subject and children',
    } as AlertData,
    SUBJECT_DELETE: {
      level: AlertLevels.ERROR,
      code: 'SH-008',
      message: 'Error deleting subject',
    } as AlertData,
    SUBJECT_LEVEL_NAME: {
      level: AlertLevels.ERROR,
      code: 'SH-009',
      message: 'Error editing subject level name',
    } as AlertData,
    SUBJECT_UPDATE_SKILL: {
      level: AlertLevels.ERROR,
      code: 'SH-010',
      message: 'Error updating subject skills',
    } as AlertData,
  };

  ngUnsubscribe: Subject<boolean> = new Subject();
  subjectLevels: Array<SubjectLevels>;
  hierarchyMap = new Map<string, any>();
  subjectMap = new Map<string, any>();
  subjectBreadcrumbs = new Map<string, any>();
  skillDimensions: Array<SkillDimension>;
  selectedDimensionId: number = 1;
  subjectLevelForm: FormGroup;
  subjectSearchForm: FormGroup;
  subjectSearch: OperatorFunction<string, any[]>;
  subjectFormatter = (result) => result['title'];
  isLoading = true;
  notFoundObject: { searchProperty: string; displayText: string } = {
    searchProperty: 'title',
    displayText: 'Subject not found, click here to add',
  };

  static sortSubject(a, b, text: string) {
    return (
      a.title.startsWith(text) - b.title.startsWith(text) || b.name - a.name
    );
  }

  static compareSubject(items, input: string) {
    return items.title.toLowerCase().includes(input);
  }

  get subjectLevelNames(): FormArray {
    return this.subjectLevelForm.get('subjectLevelNames') as FormArray;
  }

  get levelsFormArray(): FormArray {
    return this.subjectSearchForm.get('levels') as FormArray;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.isLoading = true;

    this.subjectLevelForm = this.formBuilder.group({
      subjectLevelNames: this.formBuilder.array([]),
    });

    this.subjectSearchForm = this.formBuilder.group({
      levels: this.formBuilder.array([]),
    });

    const subjectObservables = [
      this.competencyService.getSkillDimensions().pipe(
        catchError((err) => {
          this.alertService.createAlert2(
            this.exceptionData.SKILL_DIMENSIONS,
            err,
          );
          return of(undefined);
        }),
      ),
      this.competencyService.getSubjectLevels().pipe(
        catchError((err) => {
          this.alertService.createAlert2(
            this.exceptionData.SUBJECT_LEVELS,
            err,
          );
          return of(undefined);
        }),
      ),
      this.competencyService.getSubjectsDictionary().pipe(
        catchError((err) => {
          this.alertService.createAlert2(
            this.exceptionData.SUBJECT_DICTIONARY,
            err,
          );
          return of(undefined);
        }),
      ),
    ];

    forkJoin(subjectObservables)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        defaultIfEmpty([]),
        map(([skillDims, subjectLevels, subjectDictionary]) => ({
          skillDims,
          subjectLevels,
          subjectDictionary,
        })),
      )
      .subscribe((obs) => {
        this.skillDimensions = obs.skillDims;

        this.subjectLevels = obs.subjectLevels;
        this.subjectLevels.forEach((sl, i) => {
          this.subjectLevelNames.push(
            this.formBuilder.group({ id: sl.id, name: sl.name }),
          );
          this.levelsFormArray.push(
            this.formBuilder.group({ id: sl.id, search: '' }),
          );

          this.levelsFormArray
            .at(i)
            .get('search')
            .valueChanges.subscribe((searchRes) => {
              if (searchRes && searchRes.notFound) {
                const subjLevel = this.subjectLevelNames.value.find(
                  (sl) => sl.id === this.levelsFormArray.at(i).get('id'),
                );
                this.levelsFormArray.at(i).get('search').setValue(null);
                this.levelsFormArray.at(i).get('search').markAsUntouched();
                this.openSubjectModal(
                  subjLevel.name,
                  (i + 1).toString(),
                  null,
                  searchRes.value,
                );
              }
            });
        });

        for (const key in obs.subjectDictionary) {
          if (obs.subjectDictionary.hasOwnProperty(key)) {
            const values = obs.subjectDictionary[key];
            this.subjectMap.set(key, values);
          }
        }

        const categories = obs.subjectDictionary[0];

        const subjectLevelSearch = this.typeaheadService.typeahead(
          categories,
          CompetencyHierarchyComponent.compareSubject,
          CompetencyHierarchyComponent.sortSubject,
          this.notFoundObject,
        );

        const hierarchyLevel = {
          name: this.subjectLevels[0].name,
          id: this.subjectLevels[0].id,
          selectedSubjectId: null,
          selectedSubjectName: null,
          search: subjectLevelSearch,
          editMode: false,
          isEditable: false,
          collapsed: false,
          subjects: categories,
        };

        this.hierarchyMap.set('1', hierarchyLevel);
        this.isLoading = false;

        console.log(this.hierarchyMap);
      });
  }

  isDimensionAssigned(ids: number[]): boolean {
    return (
      this.selectedDimensionId !== null &&
      ids.includes(this.selectedDimensionId)
    );
  }

  onBreadCrumbClick(bc) {
    const selectedSubjectLevel = this.hierarchyMap.get(
      bc.subjectLevelId.toString(),
    );
    selectedSubjectLevel.collapsed = false;

    this.hierarchyMap.forEach((val, key) => {
      if (Number(key) > Number(bc.subjectLevelId)) {
        this.hierarchyMap.delete(key);
        this.subjectBreadcrumbs.delete(key);
      } else if (Number(key) < Number(bc.subjectLevelId)) {
        val.collapsed = true;
      }
    });
  }

  onSubjectClick(subj: SkillSubject) {
    const selectedSubjectLevel = this.hierarchyMap.get(
      subj.subjectLevelId.toString(),
    );

    this.hierarchyMap.forEach((val, key) => {
      if (Number(key) > Number(subj.subjectLevelId)) {
        this.hierarchyMap.delete(key);
        this.subjectBreadcrumbs.delete(key);
      }
    });

    selectedSubjectLevel.selectedSubjectId === subj.id
      ? this.subjectBreadcrumbs.delete(subj.subjectLevelId.toString())
      : null;

    if (selectedSubjectLevel.selectedSubjectId === subj.id) {
      selectedSubjectLevel.selectedSubjectId = null;
      selectedSubjectLevel.selectedSubjectName = null;
      selectedSubjectLevel.collapsed = false;
    } else {
      selectedSubjectLevel.selectedSubjectId = subj.id;
      selectedSubjectLevel.selectedSubjectName = subj.name;
      selectedSubjectLevel.collapsed = true;
      this.subjectBreadcrumbs.set(subj.subjectLevelId.toString(), subj);
      const subjId = subj.id.toString();
      const nextSubjLevelId = subj.subjectLevelId + 1;
      const children = this.subjectMap.get(subjId) ?? [];

      const nextSubjectLevel = this.subjectLevels.find(
        (sl) => sl.id === nextSubjLevelId,
      );
      const isEditable = nextSubjLevelId > 2 ? true : false;

      const subjectLevelSearch = this.typeaheadService.typeahead(
        children,
        CompetencyHierarchyComponent.compareSubject,
        CompetencyHierarchyComponent.sortSubject,
        this.notFoundObject,
      );

      const hierarchyLevel = {
        name: nextSubjectLevel.name,
        id: nextSubjectLevel.id,
        search: subjectLevelSearch,
        selectedSubjectId: null,
        selectedSubjectName: null,
        editMode: false,
        isEditable: isEditable,
        collapsed: false,
        subjects: children ?? [],
      };

      this.hierarchyMap.set(nextSubjLevelId.toString(), hierarchyLevel);
      console.log(this.hierarchyMap);
    }
  }

  openSubjectModal(
    subjectLevelName: string,
    subjectLevelId: string | number,
    subject: SkillSubject = null,
    notFoundValue: string,
  ) {
    const modalRef = this.ngbModal.open(SubjectModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.selectedDimensionId = this.selectedDimensionId;
    modalRef.componentInstance.notFoundValue = notFoundValue;
    modalRef.componentInstance.subjectLevelName = subjectLevelName;

    if (subject) {
      modalRef.componentInstance.subject = subject;
    }

    let parent: SkillSubject = new SkillSubject();
    const subjLevelId = Number(subjectLevelId);

    modalRef.componentInstance.subjectLevelId = subjLevelId;

    if (subjLevelId > 1) {
      const prevSubjectLevel = subjLevelId - 1;
      const parentId = this.hierarchyMap.get(
        prevSubjectLevel.toString(),
      ).selectedSubjectId;
      parent = this.hierarchyMap
        .get(prevSubjectLevel.toString())
        .subjects.find((x) => x.id === parentId);
      modalRef.componentInstance.parentSubject = parent;
    }

    const skillDims = JSON.parse(JSON.stringify(this.skillDimensions));
    modalRef.componentInstance.skillDimensions = skillDims;

    modalRef.componentInstance.deleteConfirmed.subscribe((dc) => {
      if (dc) {
        this.competencyService.deleteSubjectById(subject.id).subscribe(
          (ds) => {
            const subjMapId =
              subject.subjectLevelId === 1 ? '0' : parent.id.toString();
            const subjects = this.subjectMap.get(subjMapId);
            const index = subjects.findIndex((s) => s.id === subject.id);
            subjects.splice(index, 1);
            this.uiService.showToast(
              'Successfully deleted subject ' + subject.name,
              { classname: 'bg-success text-light', delay: 3000 },
            );
            modalRef.close();
          },
          (err) =>
            this.alertService.createAlert2(
              this.exceptionData.SUBJECT_DELETE,
              err,
            ),
        );
      }
    });

    modalRef.componentInstance.saveClicked.subscribe((sf) => {
      const subjectForm = sf as FormGroup;
      const dimensionIds = subjectForm.get('dimensionIds').value;

      const subjectData: SubjectPayload = {
        parentId: subjectForm.get('parentId').value,
        subjectLevelId: subjectForm.get('subjectLevelId').value,
        name: subjectForm.get('title').value,
        isActive: subjectForm.get('isActive').value,
        source: subjectForm.get('source').value,
        externalId: subjectForm.get('externalId').value,
        externalParentId: subjectForm.get('externalParentId').value,
      };

      if (!subject) {
        this.competencyService.addSubject(subjectData).subscribe(
          (s) => {
            this.competencyService
              .updateSubjectDimensionsById(s.id, dimensionIds)
              .subscribe(
                (sd) => {
                  let newSubject: SkillSubject = {
                    id: s.id,
                    dimensionSkillCounts: [],
                    parentId: s.parentId,
                    subjectLevelId: s.subjectLevelId,
                    name: s.name,
                    isActive: s.isActive,
                    source: s.source,
                    externalId: s.externalId,
                    externalParentId: s.externalParentId,
                    dimensionIds: dimensionIds,
                    canDelete: s.canDelete,
                    culture: s.culture,
                    cultureId: s.cultureId,
                    skillCount: s.skillCount,
                    functionalAreaCount: s.functionalAreaCount,
                    subjectIds: s.subjectIds,
                  };

                  if (newSubject.parentId) {
                    if (this.subjectMap.get(newSubject.parentId.toString())) {
                      const subjects = this.subjectMap.get(
                        newSubject.parentId.toString(),
                      );
                      subjects.unshift(newSubject);
                    } else {
                      const hierarchyMapSubj = this.hierarchyMap.get(
                        newSubject.subjectLevelId.toString(),
                      );
                      hierarchyMapSubj.subjects.unshift(newSubject);
                      this.subjectMap.set(newSubject.parentId.toString(), [
                        newSubject,
                      ]);
                    }
                  } else {
                    const subjects = this.subjectMap.get('0');
                    subjects.unshift(newSubject);
                  }

                  this.uiService.showToast(
                    'Successfully created subject ' + newSubject.name,
                    { classname: 'bg-success text-light', delay: 3000 },
                  );
                  modalRef.close();
                },
                (error) =>
                  this.alertService.createAlert2(
                    this.exceptionData.SUBJECT_ADD_DIMENSIONS,
                    error,
                  ),
              );
          },
          (error) =>
            this.alertService.createAlert2(
              this.exceptionData.SUBJECT_ADD,
              error,
            ),
        );
      } else {
        if (
          subjectForm.get('title').touched ||
          subjectForm.get('dimensionIds').touched
        ) {
          const updateSubjectObservables = [
            this.competencyService
              .updateSubjectById(subject.id, subjectData)
              .pipe(
                catchError((err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.SUBJECT_UPDATE,
                    err,
                  );
                  return of(undefined);
                }),
              ),
            this.competencyService
              .updateSubjectAndChildDimensionsById(subject.id, dimensionIds)
              .pipe(
                catchError((err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.SUBJECT_UPDATE_DIMENSIONS_CHILDREN,
                    err,
                  );
                  return of(undefined);
                }),
              ),
          ];

          forkJoin(updateSubjectObservables)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              defaultIfEmpty([]),
              map(([subject, subjectDimensions]) => ({
                subject,
                subjectDimensions,
              })),
            )
            .subscribe((resp) => {
              const subject = resp.subject;

              let existigSubject: SkillSubject = {
                id: subject.id,
                dimensionSkillCounts: subject.dimensionSkillCounts,
                parentId: subject.parentId,
                subjectLevelId: subject.subjectLevelId,
                name: subject.name,
                isActive: subject.isActive,
                source: subject.source,
                externalId: subject.externalId,
                externalParentId: subject.externalParentId,
                dimensionIds: dimensionIds,
                canDelete: subject.canDelete,
                culture: subject.culture,
                cultureId: subject.cultureId,
                skillCount: subject.skillCount,
                functionalAreaCount: subject.functionalAreaCount,
                subjectIds: subject.subjectIds,
              };

              if (existigSubject.parentId) {
                const subjects = this.subjectMap.get(
                  existigSubject.parentId.toString(),
                );
                const subjIndex = subjects.findIndex(
                  (s) => s.id === existigSubject.id,
                );
                subjects[subjIndex] = existigSubject;
              } else {
                const subjects = this.subjectMap.get('0');
                const subjIndex = subjects.findIndex(
                  (s) => s.id === existigSubject.id,
                );
                subjects[subjIndex] = existigSubject;
              }

              this.updateDimensionsForSubject(existigSubject.id, dimensionIds);
              this.uiService.showToast(
                'Successfully updated subject ' + existigSubject.name,
                { classname: 'bg-success text-light', delay: 3000 },
              );
              modalRef.close();
            });
        }
      }
    });
  }

  updateDimensionsForSubject(id: number, dimensionIds: Array<number>) {
    if (this.subjectMap.has(id.toString())) {
      const subjects = this.subjectMap.get(id.toString());
      for (const subj of subjects) {
        subj.dimensionIds = dimensionIds;
        this.updateDimensionsForSubject(subj.id, dimensionIds);
      }
    }
  }

  openSkillAssignmentModal(subject: SkillSubject) {
    const modalRef = this.ngbModal.open(AssignmentModalComponent, {
      centered: true,
      size: 'xl',
    });
    const selectedDimension = this.skillDimensions.find(
      (d) => d.id === this.selectedDimensionId,
    );
    modalRef.componentInstance.selectedDimension = selectedDimension;
    modalRef.componentInstance.subject = subject;

    modalRef.componentInstance.saveClicked.subscribe(
      (selected) => {
        const skillIds = selected.map((sk) => sk.id);

        this.competencyService
          .updateSubjectSkillsById(subject.id, skillIds)
          .subscribe((x) => {
            this.uiService.showToast(
              'Successfully updated assigned competencies/training for subject ' +
                subject.name,
              { classname: 'bg-success text-light', delay: 3000 },
            );
            modalRef.close();
          });
      },
      (err) =>
        this.alertService.createAlert2(
          this.exceptionData.SUBJECT_UPDATE_SKILL,
          err,
        ),
    );
  }

  getDimensionsColour(dimensionId: number): string {
    let classList = this.competencyService.dimensionButtonMap.get(dimensionId);
    classList += dimensionId === this.selectedDimensionId ? ' checked' : '';

    return classList;
  }

  getCardHeaderColour(isCollapsed): string {
    let classList = this.competencyService.dimensionBgMap.get(
      this.selectedDimensionId,
    );
    classList += isCollapsed ? ' opacity-75' : '';

    return classList;
  }

  getAddButtonColour() {
    return this.competencyService.addButtonMap.get(this.selectedDimensionId);
  }

  getActionButtonColour() {
    return this.competencyService.actionButtonMap.get(this.selectedDimensionId);
  }

  getSelectedSubjectButtonColour(selectedSubjectId, subjectId) {
    return selectedSubjectId === subjectId
      ? this.competencyService.selectedSubjectButtonMap.get(
          this.selectedDimensionId,
        )
      : '';
  }

  getTextColour() {
    if (this.selectedDimensionId === SkillDimensionId.Training) {
      return 'text-sqeptech-dark';
    } else {
      return 'text-white';
    }
  }

  editSubjectLevel(subjectKey: number) {
    const hierarchyLevel = this.hierarchyMap.get(subjectKey.toString());
    hierarchyLevel.editMode = true;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  collapseLevel(levelId: string) {
    const level = this.hierarchyMap.get(levelId);
    level.collapsed = !level.collapsed;
  }

  confirmLevelChange(subjectLevelKey: string) {
    const level = this.hierarchyMap.get(subjectLevelKey);

    const subjectLevelName = this.subjectLevelNames
      .at(Number(subjectLevelKey) - 1)
      .get('name').value;
    const subjectObject = { name: subjectLevelName };

    this.competencyService
      .updateSubjectLevelById(level.id, subjectObject)
      .subscribe({
        next: (sjl) => {
          this.uiService.showToast(
            'Successfully renamed subject level ' +
              level.id +
              ' to ' +
              subjectLevelName,
            { classname: 'bg-success text-light', delay: 3000 },
          );
          level.name = sjl.name;
          level.editMode = false;
          const subjLevel = this.subjectLevels.find((sl) => sl.id === level.id);
          subjLevel.name = sjl.name;
        },
        error: (err) => {
          console.log(err);
          if (err.error.name) {
            this.uiService.showToast(
              'Subject level with name ' + subjectLevelName + ' already exists',
              { classname: 'bg-danger text-light', delay: 5000 },
            );
          } else {
            this.alertService.createAlert2(
              this.exceptionData.SUBJECT_LEVEL_NAME,
              err,
            );
          }
        },
      });
  }

  getBadgeColour() {
    return this.competencyService.badgeColourMap.get(this.selectedDimensionId);
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  resetHierarchy() {
    this.hierarchyMap.forEach((val, key) => {
      if (Number(key) > 1) {
        this.hierarchyMap.delete(key);
        this.subjectBreadcrumbs.delete(key);
      }

      if (Number(key) === 1) {
        val.selectedSubjectId = null;
        val.selectedSubjectName = null;
        val.collapsed = false;
      }
    });

    this.subjectBreadcrumbs.clear();
  }

  clearSearchFilter(j) {
    this.levelsFormArray.at(j).get('search').setValue(null);
  }
}
