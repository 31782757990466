import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ClaimEvidence } from '../../claim/models/claim-evidence';

@Component({
  selector: 'esqep-statement-list-form', // tslint:disable-line:component-selector
  templateUrl: './statement-list-form.component.html',
  styleUrls: ['./statement-list-form.component.scss'],
})
export class StatementListFormComponent {
  @Input() parentFormGroup: FormGroup;
  @Input() claimantView = true;

  @Input() claimEvidenceList: Array<ClaimEvidence> = [];

  speechBubbleClass(claimantView: boolean, initiatorType: string): string {
    return this.speechBubbleLeft(claimantView, initiatorType)
      ? 'esqep-speech-bubble-left'
      : 'esqep-speech-bubble-right';
  }

  speechBubbleLeft(claimantView: boolean, initiatorType: string): boolean {
    return (
      (claimantView && initiatorType !== 'C') ||
      (!claimantView && initiatorType === 'C')
    );
  }
}
