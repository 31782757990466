<div class="card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <h4 class="card-title">Group Filter Detail</h4>
      <div *ngIf="allowEdit">
        <button
          *ngIf="
            !selectedFilter &&
            jobRoleFilter &&
            !parentFormGroup.get('filterId').dirty
          "
          type="button"
          class="btn btn-outline-danger btn-sm"
          (click)="onRemoveFilterClick()"
        >
          Remove
        </button>
        <button
          *ngIf="
            !selectedFilter &&
            jobRoleFilter &&
            this.parentFormGroup.get('filterId').dirty
          "
          type="button"
          class="btn btn-outline-secondary btn-sm"
          (click)="onRestoreFilterClick()"
        >
          Restore
        </button>
        <button
          *ngIf="
            selectedFilter &&
            !jobRoleFilter &&
            this.parentFormGroup.get('filterId').dirty
          "
          type="button"
          class="btn btn-outline-danger btn-sm"
          (click)="onDiscardFilterClick()"
        >
          Discard
        </button>
        <button
          *ngIf="
            selectedFilter &&
            jobRoleFilter &&
            this.parentFormGroup.get('filterId').dirty
          "
          type="button"
          class="btn btn-outline-danger btn-sm"
          (click)="onDiscardChangesFilterClick()"
        >
          Discard Changes
        </button>
      </div>
    </div>
    <h6 class="card-subtitle" *ngIf="!displayFilter">
      No Group Filter has been assigned to this role
    </h6>
    <div *ngIf="displayFilter">
      <div>Name: {{ displayFilter.name }}</div>
      <div>Description: {{ displayFilter.description }}</div>
    </div>
  </div>
</div>
