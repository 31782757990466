import { Component, Input } from '@angular/core';
import { PerfGridContent } from '../perf-grid-content/perf-grid-content';

@Component({
  selector: 'ug-perf-grid-card',
  templateUrl: './perf-grid-card.component.html',
  styleUrls: [],
})
export class PerfGridCardComponent {
  @Input() perfGridArray: Array<PerfGridContent> = [];

  categoryBG(ratingIdx: number) {
    let bgClass = '';
    switch (ratingIdx) {
      case 0:
        bgClass = 'esqep-bg-outstanding';
        break;
      case 1:
        bgClass = 'esqep-bg-good';
        break;
      case 2:
        bgClass = 'esqep-bg-req-improve';
        break;
      case 3:
        bgClass = 'esqep-bg-special-measures';
        break;
    }
    return bgClass;
  }

  overallBG(ratingIdx: number): string {
    return this.categoryBG(ratingIdx);
  }
}
