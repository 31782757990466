import { Component, OnChanges, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subscription, finalize, forkJoin } from 'rxjs';
import {
  PerfGridContent,
  PerfGridRating,
  PerfGridRatingCriterion,
  PerfMgmtResponse,
} from '../perf-grid-content/perf-grid-content';
import { PerfGridCol } from '../perf-grid/perf-grid-meta';
import { DashboardService } from '../services/dashboard.service';
// import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'ug-perf-mgmt',
  templateUrl: './perf-mgmt.component.html',
  styleUrls: ['./perf-mgmt.component.scss'],
})
export class PerfMgmtComponent implements OnDestroy, OnChanges, OnInit {
  private dashboardService = inject(DashboardService);

  perfMgmtData = new PerfMgmtResponse();
  // perfGridData = new PerfGridContent();
  perfGridData: Array<PerfGridContent> = [];

  perfGridUsers: Array<any> = [];
  perfGridUserIds: Array<number> = [];
  perfGridTabData: Array<PerfGridContent> = [];

  perfMgmtSubs = new Subscription();
  mgmtForm = new FormGroup({
    meOrTeam: new FormControl('me'),
    teamMember: new FormControl({ value: 0, disabled: true }),
  });

  userMap = new Map<number, string>();
  userRoles = new Map<number, any[]>();

  isLoading = true;

  ngOnChanges() {}

  radioClick() {
    // if (!this.mgmtForm) {
    //   return
    // }
    // if (this.mgmtForm.get('meOrTeam').value === 'me') {
    //   this.perfGridData = new PerfGridContent(this.perfMgmtData.me);
    // } else {
    //   this.perfGridData = new PerfGridContent(this.perfMgmtData.team[0]);
    // }
  }

  ngOnDestroy() {
    this.perfMgmtSubs.unsubscribe();
  }

  ngOnInit() {
    // this.perfGridMeta.Categories = [];
    // this.perfGridMeta.Categories.push( { name: 'Outstanding', score: 40});
    // this.perfGridMeta.Categories.push( { name: 'Good', score: 20} );
    // this.perfGridMeta.Categories.push( { name: 'Requires Improvement', score: 0} );
    // this.perfGridMeta.Categories.push( { name: 'Special Measures', score: -20} );

    this.perfMgmtSubs.add(
      // this.dashboardService.getPerfAgent('John', 'Harmon').subscribe(
      this.dashboardService.getPerfManager('Portsmouth North', 77).subscribe(
        (me) => {
          this.perfMgmtData.me = this.mapResponse(me);
          this.perfGridData = new Array<PerfGridContent>();
          this.perfGridData.push(this.perfMgmtData.me);
          // this.perfGridData = this.perfMgmtData.me;
        },
        (err) => {
          console.log(err);
        }
      )
    );

    const teamObservables = [
      this.dashboardService.getPerfAgent(84),
      this.dashboardService.getPerfAgent(544),
      this.dashboardService.getPerfProjCoord('Project Coordinator', 544),
      this.dashboardService.getPerfAgent(92),
      this.dashboardService.getPerfRecruitTeam('Recruitment Manager', 92),
    ];

    this.perfMgmtSubs.add(
      forkJoin(teamObservables)
        .pipe(
          finalize(() => {
            this.perfMgmtData.team.forEach((tm, i) => {
              this.userMap.set(
                tm.personId,
                `${tm.userFirstName} ${tm.userLastName}`
              );
              const jj = this.userRoles.get(tm.personId) || [];
              jj.push({
                name: tm.name,
                personId: tm.personId,
                perfDataIndex: i,
              });
              this.userRoles.set(tm.personId, jj);
            });
            this.perfGridUsers = [
              ...new Set(
                this.perfMgmtData.team.map((tm) => {
                  return {
                    name: `${tm.userFirstName} ${tm.userLastName}`,
                    personId: tm.personId,
                  };
                })
              ),
            ].sort();
            this.perfGridUserIds = [
              ...new Set(this.perfGridUsers.map((pgu) => pgu.personId)),
            ];
            this.mgmtForm.get('teamMember').setValue(this.perfGridUserIds[0]);
            this.isLoading = false;
          })
        )
        .subscribe(
          (fj) => {
            fj.forEach((o) => this.perfMgmtData.team.push(this.mapResponse(o)));
          },
          (err) => {
            console.log(err);
          }
        )
    );

    // this.perfMgmtData = this.dashboardService.getPerfMgmtData();
    // this.perfGridData = this.perfMgmtData.me;

    // this.perfGridUsers = [...new Set(this.perfMgmtData.team.map(tm => `${tm.userFirstName} ${tm.userLastName}`))].sort();

    this.perfMgmtSubs.add(
      this.mgmtForm.get('meOrTeam').valueChanges.subscribe((changes) => {
        if (this.mgmtForm.get('meOrTeam').value === 'me') {
          this.perfGridData = new Array<PerfGridContent>();
          this.perfGridData.push(new PerfGridContent(this.perfMgmtData.me));
          // this.perfGridData = new PerfGridContent(this.perfMgmtData.me);
          this.mgmtForm.get('teamMember').disable();
        } else {
          this.perfGridData = this.perfMgmtData.team.filter(
            (tm) => tm.personId === this.selectedPersonId
          );
          // this.perfGridData.push(new PerfGridContent(this.perfMgmtData.team[this.mgmtForm.get('teamMember').value]));
          // this.perfGridData = new PerfGridContent(this.perfMgmtData.team[this.mgmtForm.get('teamMember').value]);
          this.mgmtForm.get('teamMember').enable();
        }
      })
    );
    this.perfMgmtSubs.add(
      this.mgmtForm.get('teamMember').valueChanges.subscribe((changes) => {
        if (this.mgmtForm.get('teamMember').enabled) {
          this.perfGridData = this.perfMgmtData.team.filter(
            (tm) => tm.personId === this.selectedPersonId
          );
          // this.perfGridData = new Array<PerfGridContent>();
          // this.perfGridData.push(new PerfGridContent(this.perfMgmtData.team[this.mgmtForm.get('teamMember').value]));
          // this.perfGridData = new PerfGridContent(this.perfMgmtData.team[this.mgmtForm.get('teamMember').value]);
          this.perfGridTabData = this.perfMgmtData.team.filter(
            (tm) => this.mgmtForm.get('teamMember').value === tm.personId
          );
        }
      })
    );
  }

  mapResponse(pm: PerfGridContent): PerfGridContent {
    const perfSummary = new PerfGridContent();
    perfSummary.name = pm.name;
    perfSummary.userFirstName = pm.userFirstName;
    perfSummary.userLastName = pm.userLastName;
    perfSummary.personId = pm.personId;

    pm.metadata.Categories.forEach((c) => {
      perfSummary.metadata.Categories.push(
        new PerfGridCol({ name: c.name, score: c.score })
      );
    });

    pm.ratings.forEach((r) => {
      const ratcrit: Array<PerfGridRatingCriterion> = [];
      r.ratingCriteria.forEach((rc) => {
        ratcrit.push(
          new PerfGridRatingCriterion({
            fromValue: rc.fromValue,
            toValue: rc.toValue,
            description: rc.description,
          })
        );
      });
      perfSummary.ratings.push(
        new PerfGridRating({
          name: r.name,
          ratingValue: r.ratingValue,
          hoistRating: r.hoistRating || false,
          allowInput: r.allowInput || false,
          displayType: r.displayType,
          ratingCriteria: ratcrit,
        })
      );
    });
    return perfSummary;
  }

  get usersList(): IterableIterator<any> {
    return this.userRoles.values();
  }

  get selectedPersonId(): number {
    return +this.mgmtForm.get('teamMember').value;
  }
}
