import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ug-training-bulk-completion-action',
  templateUrl: './training-bulk-completion-action.component.html',
  styleUrls: ['./training-bulk-completion-action.component.scss'],
})
export class TrainingBulkCompletionActionComponent implements OnInit {
  modal = inject(NgbActiveModal);
  private fb = inject(FormBuilder);

  @Input() trainingTitle: string;
  @Input() curricula: string;
  @Input() peopleArray: any;
  @Output() recalculateSummStatus: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() bulkStatusUpdates: EventEmitter<Map<string, string>> =
    new EventEmitter<Map<string, string>>();

  bulkSubmitForm: FormGroup;
  awaitingCompleteConfirmation: boolean;
  selectAll = true;

  get disableBulkSave() {
    return this.peopleFormArray.controls
      .map((c: FormGroup) => c.get('includePerson'))
      .every((e: FormControl) => {
        return !e.value;
      });
  }

  get peopleFormArray(): FormArray {
    return this.bulkSubmitForm.controls.people as FormArray;
  }

  @Output() confirmBulkSubmitClicked: EventEmitter<FormArray> =
    new EventEmitter<FormArray>();

  ngOnInit(): void {
    this.bulkSubmitForm = this.fb.group({
      people: this.fb.array([]),
    });
    this.peopleArray.forEach((p) => {
      const personControl = this.fb.group({
        personId: [p.personId],
        personName: [p.personName],
        personExternalId: [p.personExternalId],
        trainingState: [p.trainingState],
        prevTrainingState: [p.prevTrainingState],
        includePerson: p.includePerson,
        disabled: [p.disabled],
      });
      if (p.disabled) {
        personControl.get('includePerson').disable();
      }
      this.peopleFormArray.push(personControl);
    });
  }

  saveBulkSubmit() {
    this.confirmBulkSubmitClicked.emit(this.peopleFormArray);
    this.awaitingCompleteConfirmation = false;
  }

  selectAllChange(newValue) {
    this.peopleFormArray.controls.forEach((p) => {
      if (!p.get('disabled')?.value) {
        p.get('includePerson')?.setValue(newValue);
      }
    });
  }
}
