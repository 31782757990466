<div class="card">
  <div class="card-body">
    <h4 class="card-title">People</h4>
    <div
      [hidden]="
        currentPeopleInUse?.length === 0 && selectedPeople?.length === 0
      "
      class="fixed-table-height"
    >
      <ul
        ngbNav
        #savedAndPending="ngbNav"
        [(activeId)]="active"
        [destroyOnHide]="false"
        class="nav-tabs nav-justified"
      >
        <li [ngbNavItem]="1">
          <button ngbNavLink>
            Assigned ({{ peopleCount | i18nPlural: peopleCountMessageMap }})
          </button>
          <ng-template ngbNavContent>
            <ug-table
              [tableHeaders]="headers"
              [tableRows]="currentPeopleInUse"
              [tablePageIndex]="tablePageIndex"
              [tablePageSize]="tablePageSize"
              [showSelectBox]="false"
              [tableRowButtons]="rowButtons"
              [isLoading]="tableLoading"
              [totalRecords]="peopleCount"
              (pageIndexChange)="onPageChange($event)"
              (pageSizeChange)="onPageSizeChange($event)"
              (sortChange)="onSortChange($event)"
              (filtersCleared)="clearFilters($event)"
            ></ug-table>
          </ng-template>
        </li>
        <li
          [ngbNavItem]="2"
          [disabled]="!selectedPeople || selectedPeople?.length < 1"
        >
          <button ngbNavLink>
            Pending assignment ({{
              selectedPeople?.length || 0 | i18nPlural: peopleCountMessageMap
            }})
          </button>
          <ng-template ngbNavContent>
            <ug-table
              [tableHeaders]="headers"
              [tableRows]="selectedPeople"
              [tablePageIndex]="tablePageIndex"
              [tablePageSize]="tablePageSize"
              [showSelectBox]="false"
              [tableRowButtons]="rowButtons"
              [isLoading]="tableLoading"
              [totalRecords]="selectedPeople.length"
            ></ug-table>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="savedAndPending" class="mt-2"></div>
    </div>
  </div>
</div>
