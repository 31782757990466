import { Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { BaseComponent } from '../base-component/base-component.component';

@Component({
  selector: 'ug-base-input',
  template: ``,
})
export class BaseInputComponent
  extends BaseComponent
  implements ControlValueAccessor, OnInit
{
  private readonly ngControl = inject(NgControl);

  @Input()
  id = 'input-' + Math.random().toString(36).substring(2);

  @Input()
  type: 'text' | 'email' | 'password' | 'tel' = 'text';

  @Input()
  placeholder: string;

  @Input()
  hint: string;

  @Input()
  required = true;

  @HostBinding('class.form-group')
  formGroupClass = true;

  errorCode: string;
  formControl = new FormControl();

  constructor() {
    super();
    const ngControl = this.ngControl;


    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe((status) => {
        if (status === 'VALID') {
          this.errorCode = null;
        } else {
          this.errorCode = Object.keys(this.ngControl.errors)[0];
        }
      });
  }

  writeValue(obj: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(value: any) {}

  onTouched() {}
}
