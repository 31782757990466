<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add People to Job Role</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="closeModal()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <form class="my-2">
      <input
        type="file"
        class="form-control form-control-sm"
        (change)="fileUploadChange($event)"
        placeholder="Choose file"
        click="this.value=null"
        #userFile
      />
    </form>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [tableSelectedButtons]="tableSelectedButtons"
      [showSelectBox]="true"
      [isLoading]="tableLoading"
      [selectLoading]="addSelectedLoading"
      [tableNoDataMessage]="'No users found based on the ids provided'"
    ></ug-table>
  </div>
</div>
