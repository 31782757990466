import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { JobRoleService } from '../../../job-role/services/job-role.service';
import {
  AuditData,
  AuditFilter,
} from '../../../shared/audit-table/audit.service';

@Component({
  selector: 'ug-job-role-settings-audit',
  templateUrl: './job-role-settings-audit.component.html',
  styleUrl: './job-role-settings-audit.component.scss',
  providers: [DatePipe],
})
export class JobRoleSettingsAuditComponent {
  private jobRoleService = inject(JobRoleService);

  jobRoleSettingsAudit: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData> = this.jobRoleService.getJobRoleSettingsAuditPaged;
}
