<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add Competencies to Job Role</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="closeModal()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="dimensionsForm" class="mb-1">
      <div
        *ngFor="let dim of skillDimensions; let i = index; trackBy: trackByFn"
        class="form-check form-check-inline"
      >
        <input
          type="radio"
          class="form-check-input"
          id="{{ 'dimensionOption' + i }}"
          [value]="dim.id"
          formControlName="dimensionId"
        />
        <label class="form-check-label" for="{{ 'dimensionOption' + i }}">{{
          dim.name | titlecase
        }}</label>
      </div>
    </form>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [tableFilters]="tableFilters"
      [tablePageSize]="tablePageSize"
      [tablePageIndex]="tablePageIndex"
      [tableSelectedButtons]="tableSelectedButtons"
      [showSelectBox]="true"
      [showPagination]="true"
      [isLoading]="tableLoading"
      [totalRecords]="skillCount"
      [showSelectAll]="false"
      [existsKey]="'existsInJobRole'"
      [pagedApi]="true"
      (pageIndexChange)="updateData($event)"
      (pageSizeChange)="updateData(1, $event)"
      (sortChange)="updateData(1, null, $event)"
      (filtersCleared)="clearFilters($event)"
    >
    </ug-table>
  </div>
</div>
