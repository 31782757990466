import { NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { environment } from '../environments/environment';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'es', label: 'Spanish' },
          { id: 'fr', label: 'French' },
          { id: 'ge', label: 'Germany' },
        ],
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        fallbackLang: 'en',
        defaultLang: 'en',
        missingHandler: {
          useFallbackTranslation: false,
        },
        prodMode: environment.production,
      },
    }),
  ],
})
export class TranslocoRootModule {}
