import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterBase } from './filter-base';
import { FilterControlService } from './filter-control.service';

@Component({
  selector: 'ug-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [FilterControlService],
})
export class DynamicFormComponent {
  @Input() filters: Array<FilterBase<any>>;
  @Input() form!: FormGroup;
  @Input() showClearFilters = true;
  @Input() rowIndex: number;
  @Input() asRow: boolean = true;
  @Output() filtersCleared: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges() {
    if (this.filters) {
      this.filters = this.filters.sort((a, b) =>
        a['order'] > b['order'] ? 1 : a['order'] === b['order'] ? 0 : -1,
      );
      this.filters.forEach((filter) => {
        if (filter.defaultValue) {
          this.form.patchValue({
            [filter.key]: filter.defaultValue,
          });
        }
      });
    }
  }

  clearFilters() {
    this.form.reset();
    this.filtersCleared.emit(true);
  }
}
