import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimExpiryDate',
})
export class ClaimExpiryDatePipe implements PipeTransform {
  transform(skill: any, person: any): any {
    if (skill && person && skill.claims) {
      const claim = skill.claims.find((claim) => claim.personId === person.id);
      if (claim) {
        return claim.expiryDate;
      }
    }
    return null;
  }
}
