import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import {
  PersonSearchParameters,
  PersonSecrityRole,
  PersonService,
  SecurityRoleUpdate,
} from '../services/person.service';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SecurityRole } from '../../core/models/security-role';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  OrgUnitService,
  OrganisationUnit,
} from '../../org-unit/services/org-unit.service';

enum updateStatus {
  DIRTY,
  DISABLED_ROLE,
  DISABLED_SELF,
  FAILED_CHANGED,
  FAILED_OTHER,
  PRISTINE,
  SUCCESS,
}

@Component({
  selector: 'ug-person-security-role',
  templateUrl: './person-security-role.component.html',
  styleUrl: './person-security-role.component.scss',
})
export class PersonSecurityRoleComponent implements OnInit {
  exceptionData = {
    PEOPLE: {
      level: AlertLevels.ERROR,
      code: 'PSR-001',
      message: 'Unable to retrieve people data',
    } as AlertData,
    PERSON_SECURITY_ROLE: {
      level: AlertLevels.ERROR,
      code: 'PSR-002',
      message: 'Failed to update person security role',
    } as AlertData,
    PERSON_CHANGED: {
      level: AlertLevels.WARNING,
      code: 'PSR-003',
      message: 'Record out of date vs. database. Please try again',
    } as AlertData,
    OU_LIST: {
      level: AlertLevels.ERROR,
      code: 'PSR-004',
      message: 'Error retrieving organisation units',
    } as AlertData,
    OU_TYPE: {
      level: AlertLevels.ERROR,
      code: 'PSR-005',
      message: 'Error retrieving organisation unit type',
    } as AlertData,
  };

  statusData: {
    [updateStatus: number]: {
      classString: string;
      tooltip: string;
      showIndicator: boolean;
    };
  } = {
    [updateStatus.DIRTY]: {
      classString: 'fas fa-xl fa-asterisk fa-xl text-warning',
      tooltip: 'Unsaved changes',
      showIndicator: true,
    },
    [updateStatus.DISABLED_ROLE]: {
      classString: 'fas fa-xl fa-info-circle fa-xl text-info',
      tooltip: 'Your security role prevents amendment of this user',
      showIndicator: true,
    },
    [updateStatus.DISABLED_SELF]: {
      classString: 'fas fa-xl fa-info-circle fa-xl text-info',
      tooltip: 'You cannot amend your own security role',
      showIndicator: true,
    },
    [updateStatus.FAILED_CHANGED]: {
      classString: 'fas fa-xl fa-exclamation-circle fa-xl text-warning',
      tooltip: 'Record has changed in database. Try again',
      showIndicator: true,
    },
    [updateStatus.FAILED_OTHER]: {
      classString: 'fas fa-xl fa-exclamation-circle fa-xl text-danger',
      tooltip: 'Update failed',
      showIndicator: true,
    },
    [updateStatus.PRISTINE]: {
      classString: '',
      tooltip: '',
      showIndicator: false,
    },
    [updateStatus.SUCCESS]: {
      classString: 'fas fa-xl fa-check-circle fa-xl text-success',
      tooltip: 'Record successfully updated',
      showIndicator: true,
    },
  };

  private uiService = inject(UitoolsService);
  private orgUnitService = inject(OrgUnitService);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private personService = inject(PersonService);
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private filterTextSubj: Subject<string> = new Subject<string>();
  private updatedPeopleIds: Array<number> = [];
  private totals$ = new BehaviorSubject<number>(0);

  protected pageSizeOptions: Array<number> = [5, 10, 25, 50, 100];
  protected updateStatus = updateStatus;
  protected filtersEnabled = false;
  protected filtersForm: FormGroup;
  protected userSecurityRoles: Array<SecurityRole> = [];
  protected personSecurityLevel: number;
  protected psrForm: FormGroup;
  protected filteredPeople: Array<PersonSecrityRole> = [];
  protected showUnsavedExitWarning = false;
  protected warningModalResponse: Subject<boolean> = new Subject();
  protected isLoading = true;
  protected filterText = '';
  protected ouList: OrganisationUnit[];
  protected ouType: string;
  protected page: number = 1;
  protected pageSize: number = 10;

  get personControlsArray(): Array<any> {
    return this.psrForm.get('personSecurityRoles').value;
  }

  get securityRoleDropdownDisabled(): boolean {
    return this.filtersForm.get('securityRoleId').disabled;
  }

  get total$() {
    return this.totals$.asObservable();
  }

  ngOnInit() {
    this.createForm();
    this.filtersEnabled = true;

    this.authService.loggedInUserSubj
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((liu: LoggedInUser) => {
        this.personSecurityLevel = liu.securityLevel;
      });

    this.authService.securityRolesSubj
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sr: Array<SecurityRole>) => {
        this.userSecurityRoles = sr.sort((a, b) => {
          return a.securityLevel > b.securityLevel ? 1 : -1;
        });
      });

    this.orgUnitService
      .getOrgUnitsForViewing()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ous) => {
          this.ouList = ous;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.PEOPLE, err);
        },
      });

    this.orgUnitService
      .getOrgUnitTypesForViewing()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ouTypes) => {
          this.ouType = ouTypes.name;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.OU_LIST, err);
        },
      });

    this.updateFilteredPeopleList();

    this.filterTextSubj
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap((searchTerm) => {
          const searchParams: PersonSearchParameters = {
            search: searchTerm,
            securityRoleId: this.filtersForm.get('securityRoleId').value,
            pageSize: this.pageSize,
            page: 1,
          };

          return this.personService.searchForSecurityRolesPaged(searchParams);
        }),
      )
      .subscribe({
        next: (pl) => {
          this.totals$.next(pl.totalRowCount);
          this.page = pl.page;
          this.pageSize = pl.pageSize;
          this.filteredPeople = pl.data;
          this.buildDisplayList();
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.PEOPLE, err);
        },
      });
  }

  getPersonFormGroup(index: number): FormGroup {
    return this.psrForm.get(`personSecurityRoles.${index}`) as FormGroup;
  }

  createForm() {
    this.psrForm = this.fb.group({ personSecurityRoles: this.fb.array([]) });
    this.filtersForm = this.fb.group({
      securityRoleId: this.fb.control(null),
    });
    this.filtersForm
      .get('securityRoleId')
      .valueChanges.pipe(
        switchMap(() => {
          const payload = {
            search: this.filterText,
            securityRoleId: this.filtersForm.get('securityRoleId').value,
            pageSize: this.pageSize,
            page: 1,
          };

          return this.personService.searchForSecurityRolesPaged(payload);
        }),
      )
      .subscribe((pl) => {
        this.totals$.next(pl.totalRowCount);
        this.page = pl.page;
        this.pageSize = pl.pageSize;
        this.filteredPeople = pl.data;
        this.buildDisplayList();
      });
  }

  onFilterTextChange(filterText: string) {
    this.filterText = filterText;
    this.filterTextSubj.next(filterText);
  }

  hidePersonSecurityRole(index: number): boolean {
    return (
      this.filtersEnabled &&
      !this.getPersonFormGroup(index).dirty &&
      (this.getPersonFormGroup(index).get('hidePerson').value ||
        (this.filtersForm.get('securityRoleId').value &&
          this.getPersonFormGroup(index).getRawValue()[
            'personSecurityRoleId'
          ] !== this.filtersForm.get('securityRoleId').value))
    );
  }

  onUpdatePersonSecurityRole(index: number) {
    const personRecord: FormGroup = this.getPersonFormGroup(index);
    const personId = personRecord.get('personId')?.value;
    const securityRoleId = personRecord.get('personSecurityRoleId')?.value;
    const securityRoleUpdate: SecurityRoleUpdate = {
      securityRoleId: securityRoleId,
      orgUnitIds: personRecord.get('restrictedOus').value,
    };

    const canRestrictByOu = this.canRestrictByOu(securityRoleId);
    if (
      !canRestrictByOu &&
      personRecord.get('restrictedOus').value.length > 0
    ) {
      securityRoleUpdate.orgUnitIds = [];
    }

    this.personService
      .updatePersonSecurityRole(personId, securityRoleUpdate)
      .subscribe({
        next: () => {
          this.uiService.showToast('Successfully updated user security role', {
            classname: 'bg-success text-light',
            delay: 3000,
          });

          this.getPersonFormGroup(index).patchValue({
            updateStatus: updateStatus.SUCCESS,
          });
          this.updatedPeopleIds.push(personId);
          this.getPersonFormGroup(index).setErrors({ updateFailed: false });
          this.getPersonFormGroup(index).markAsPristine();
        },
        error: (err) => {
          this.getPersonFormGroup(index).setErrors({ updateFailed: true });
          const previousSecurityRoleId = personRecord.get(
            'previousSecurityRoleId',
          )?.value;
          this.getPersonFormGroup(index).patchValue({
            personSecurityRoleId: previousSecurityRoleId,
            previousSecurityRoleId: previousSecurityRoleId,
            updateStatus: updateStatus.FAILED_CHANGED,
            canRestrictByOu: this.canRestrictByOu(previousSecurityRoleId),
            restrictedOus: this.getPersonFormGroup(index).get(
              'previousRestrictedOus',
            ).value,
          });
          this.alertService.createAlert2(
            this.exceptionData.PERSON_CHANGED,
            err,
          );
        },
      });
  }

  onDiscardPersonSecurityRole(index: number) {
    this.getPersonFormGroup(index).setErrors({ updateFailed: false });
    this.getPersonFormGroup(index).patchValue({
      personSecurityRoleId: this.getPersonFormGroup(index).get(
        'previousSecurityRoleId',
      ).value,
      updateStatus: updateStatus.PRISTINE,
      canRestrictByOu: this.canRestrictByOu(
        this.getPersonFormGroup(index).get('previousSecurityRoleId').value,
      ),
      restrictedOus: this.getPersonFormGroup(index).get('previousRestrictedOus')
        .value,
    });
    this.getPersonFormGroup(index).markAsPristine();
  }

  resetUnsavedExitWarning() {
    this.showUnsavedExitWarning = false;
  }

  onSecurityRoleChange(index: number): void {
    this.getPersonFormGroup(index).patchValue({
      updateStatus: updateStatus.DIRTY,
      canRestrictByOu: this.canRestrictByOu(
        this.getPersonFormGroup(index).value.personSecurityRoleId,
      ),
    });
  }

  showStatusIcon(index: number): boolean {
    const rowFormGroup = this.getPersonFormGroup(index);
    let displayStatus = false;

    switch (rowFormGroup.get('updateStatus').value) {
      case updateStatus.DIRTY:
      case updateStatus.DISABLED_ROLE:
      case updateStatus.DISABLED_SELF:
      case updateStatus.FAILED_CHANGED:
      case updateStatus.FAILED_OTHER:
        displayStatus = true;
        break;
      case updateStatus.SUCCESS:
        displayStatus = !rowFormGroup.dirty;
        break;
      default:
        displayStatus = false;
    }
    return displayStatus;
  }

  statusIconClass(index: number): string {
    const rowStatus = this.getPersonFormGroup(index).get('updateStatus').value;
    return this.statusData[rowStatus].classString;
  }

  statusIconTooltip(index: number): string {
    const rowStatus = this.getPersonFormGroup(index).get('updateStatus').value;
    return this.statusData[rowStatus].tooltip;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  private buildDisplayList() {
    this.psrForm.setControl(
      'personSecurityRoles',
      this.fb.array(
        this.filteredPeople.map((p: any) => {
          const roleTooLow: boolean =
            p.securityLevel > this.personSecurityLevel;
          const selfRecord: boolean = p.id === this.authService.me.id;
          const roleControlDisabled = roleTooLow || selfRecord;
          let controlStatus = updateStatus.PRISTINE;
          if (roleTooLow) {
            controlStatus = updateStatus.DISABLED_ROLE;
          } else if (selfRecord) {
            controlStatus = updateStatus.DISABLED_SELF;
          } else if (this.updatedPeopleIds.some((up) => up === p.id)) {
            controlStatus = updateStatus.SUCCESS;
          }
          return this.fb.group({
            personId: p.id,
            personName: p.displayName,
            personUPN: p.adUpn,
            personSecurityRoleId: {
              value: p.securityRoleId,
              disabled: roleControlDisabled,
            },
            previousSecurityRoleId: p.securityRoleId,
            hidePerson: false,
            updateStatus: controlStatus,
            filterSearch: `${p.adObjectGuid} ${p.displayName} ${p.adUpn}`,
            rowVersion: p.rowVersion,
            restrictedOus: {
              value: p.orgUnits.map((ou) => ou.id),
              disabled: roleControlDisabled,
            },
            previousRestrictedOus: {
              value: p.orgUnits.map((ou) => ou.id),
              disabled: roleControlDisabled,
            },
            canRestrictByOu: this.canRestrictByOu(p.securityRoleId),
          });
        }),
      ),
    );

    if (this.securityRoleDropdownDisabled) {
      this.filtersForm.get('securityRoleId').enable();
    }
  }

  canRestrictByOu(id: number): boolean {
    const usr = this.userSecurityRoles.find((r) => r.id === id);
    if (
      usr.roleIdentifier === SecurityRoleKey.OuResponsible ||
      usr.roleIdentifier === SecurityRoleKey.TrainingCalendarAdmin ||
      usr.roleIdentifier === SecurityRoleKey.Admin ||
      usr.roleIdentifier === SecurityRoleKey.Superuser
    ) {
      return true;
    }

    return false;
  }

  onPageChange($event) {
    this.page = $event;
    this.updateFilteredPeopleList();
  }

  onPageSizeChange($event) {
    this.pageSize = $event;
    this.page = 1;
    this.updateFilteredPeopleList();
  }

  updateFilteredPeopleList() {
    const searchParams: PersonSearchParameters = {
      search: this.filterText,
      securityRoleId: this.filtersForm.get('securityRoleId').value,
      pageSize: this.pageSize,
      page: this.page,
    };

    this.personService.searchForSecurityRolesPaged(searchParams).subscribe({
      next: (pl) => {
        this.totals$.next(pl.totalRowCount);
        this.filteredPeople = pl.data;
        this.buildDisplayList();
        this.isLoading = false;
      },
      error: (err) => {
        this.alertService.createAlert2(this.exceptionData.PEOPLE, err);
      },
    });
  }
}
