import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  JobRoleOrPersonOptions,
  ScoreTrafficLight,
} from '../services/job-role.service';

@Component({
  selector: 'ug-person-job-role-compare',
  templateUrl: './person-job-role-compare.component.html',
  styleUrl: './person-job-role-compare.component.scss',
})
export class PersonJobRoleCompareComponent {
  @Input() personJobRoles: any[];
  isCollapsed = true;

  @Output()
  remove: EventEmitter<{ type: JobRoleOrPersonOptions; id: number }> =
    new EventEmitter();

  getScoreTrafficLight(roleScore: number): string {
    let scoreLight = ScoreTrafficLight.success;
    if (roleScore < 0.4) {
      scoreLight = ScoreTrafficLight.danger;
    } else if (roleScore < 0.75) {
      scoreLight = ScoreTrafficLight.warning;
    }
    return scoreLight;
  }

  removeComparison(objectToRemove) {
    const idToRemove = objectToRemove.jobRoleId;
    this.remove.emit({ type: JobRoleOrPersonOptions.PERSON, id: idToRemove });
  }
}
