import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { defaultIfEmpty, forkJoin, switchMap, tap } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  OrgUnitLookup,
  OrgUnitService,
} from '../../org-unit/services/org-unit.service';
import {
  MatrixService,
  MatrixSummaryFilters,
  OrgUnitStatusCount,
  SummarySubject,
} from '../matrix/matrix.service';

@Component({
  selector: 'ug-matrix-summary',
  templateUrl: './matrix-summary.component.html',
  styleUrls: ['./matrix-summary.component.scss'],
})
export class MatrixSummaryComponent implements OnInit {
  exceptionData = {
    MATRIX_SUMMARY: {
      level: AlertLevels.ERROR,
      code: 'MS-001',
      message: 'Error retrieving matrix summary',
    } as AlertData,
  };
  @Input() matrixType: string;

  private matrixService = inject(MatrixService);
  private alertService = inject(AlertService);
  private activatedRoute = inject(ActivatedRoute);
  private orgUnitService = inject(OrgUnitService);
  private destroyRef = inject(DestroyRef);
  private skillsStatusCounts: Map<string, OrgUnitStatusCount>;
  private orgUnitMap = new Map<number, OrgUnitStatusCount[]>();
  private availableOrgUnits: OrgUnitLookup[];

  protected activeTab: number = 1;
  protected loadingData = true;
  protected subjects: SummarySubject[] = [];
  protected orgUnitBreadCrumb: Map<number, string> = new Map<number, string>();
  protected subjectBreadCrumb: string[] = [];
  protected showMatrix = false;
  protected matrixSummaryFilters: MatrixSummaryFilters;
  protected matrixSummaryTitle: string;
  protected canAccessOuData: boolean;
  protected selectedOuHeaders = {};
  protected filteredOrgUnitMap = new Map<number, OrgUnitStatusCount[]>();
  protected sortByScoreDesc = {};
  protected filterText = {};
  protected collapsedRows = {};

  ngOnInit(): void {
    this.loadingData = true;

    this.activatedRoute.data
      .pipe(
        tap((data) => (this.matrixType = data.matrixType)),
        switchMap(() => {
          return forkJoin([
            this.matrixService.getMatrixSummaryByType(this.matrixType),
            this.orgUnitService.getOrgUnitLookups({
              isViewUserData: true,
            }),
          ]).pipe(defaultIfEmpty([]));
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([ms, ouList]) => {
          this.availableOrgUnits = ouList;
          this.skillsStatusCounts = new Map(
            Object.entries(ms.skillStatusCounts),
          );

          this.subjects = ms.subjects.map((s) => ({
            ...s,
            complete:
              this.skillsStatusCounts.get(s.key).publishedCount /
              this.skillsStatusCounts.get(s.key).totalCount,
            selected: false,
            children: s.children.map((cs) => ({
              ...cs,
              complete:
                this.skillsStatusCounts.get(cs.key).publishedCount /
                this.skillsStatusCounts.get(cs.key).totalCount,
              selected: false,
            })),
          }));

          //By default select the first category subject
          if (this.subjects.length > 0) {
            this.onSubjectClick(0);
            this.collapsedRows[1] = false;
          }
          this.loadingData = false;
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.MATRIX_SUMMARY,
            err,
          );
          this.loadingData = false;
        },
      });
  }

  onSubjectClick(tabIndex: number) {
    //By default select the first child subject
    this.subjectBreadCrumb[0] = this.subjects[tabIndex].name;
    this.onChildSubjectClick(this.subjects[tabIndex].children[0], 0, tabIndex);
  }

  onChildSubjectClick(
    subject: SummarySubject,
    childIndex: number,
    tabIndex: number,
  ) {
    const children = this.subjects[tabIndex].children;
    this.showMatrix = false;

    const currSelectedChild = children.find((c) => c.selected);
    currSelectedChild ? (currSelectedChild.selected = false) : false;
    children[childIndex].selected = true;

    // Update breadcrumb with the selected child's name
    this.subjectBreadCrumb[1] = children[childIndex].name;
    this.orgUnitMap = this.updateOrgUnitMap(subject);

    this.filteredOrgUnitMap = this.deepCopyOrgUnitMap(this.orgUnitMap);
    this.filterText = {};
    this.filteredOrgUnitMap.forEach((values, key) => {
      this.onSortByScoreDesc(key);
    });
  }

  deepCopyOrgUnitMap(originalMap) {
    const newMap = new Map();

    originalMap.forEach((value, key) => {
      // Copy each OrgUnitStatusCount object, including the childKeys array
      const copiedArray = value.map((orgUnit) => ({
        ...orgUnit,
        childKeys: [...orgUnit.childKeys],
      }));
      newMap.set(key, copiedArray);
    });

    return newMap;
  }

  updateOrgUnitMap(subject: SummarySubject): Map<number, OrgUnitStatusCount[]> {
    // Populate org unit skill status counts
    const subjectKey = subject.key;
    const orgUnitKeys = this.skillsStatusCounts.get(subjectKey).childKeys;
    const orgUnitSkillStatusCounts = orgUnitKeys.map((key) => ({
      ...this.skillsStatusCounts.get(key),
    }));

    const ouMap = new Map<number, OrgUnitStatusCount[]>();
    this.selectedOuHeaders = {};
    ouMap.set(1, orgUnitSkillStatusCounts);

    // Process org units and create breadcrumbs
    if (this.orgUnitMap.size > 0) {
      Array.from(this.orgUnitMap.keys()).forEach((key) => {
        const currentSelected = this.findSelectedOrgUnit(key);

        if (currentSelected) {
          const ou = this.findOrgUnitById(
            ouMap,
            key,
            currentSelected.orgUnitId,
          );
          if (ou) {
            ou.selected = true;
            if (this.hasChildKeys(ou)) {
              this.processChildOrgUnits(ouMap, key, ou);
            }

            this.createBreadcrumbAtLevel(key, ou);
            this.selectedOuHeaders[key] = this.getSelectedOuHeader(ou);
            // View matrix if the last key is selected and it has no child keys
            if (key === this.orgUnitMap.size && ou && !this.hasChildKeys(ou)) {
              this.viewMatrix(ou, key, false);
            }
          } else {
            this.collapsedRows[key] = false;
          }
        }
      });
    }

    return ouMap;
  }

  findSelectedOrgUnit(key: number) {
    return this.orgUnitMap.get(key)?.find((ou) => ou.selected);
  }

  findOrgUnitById(
    ouMap: Map<number, OrgUnitStatusCount[]>,
    key: number,
    orgUnitId: number,
  ): OrgUnitStatusCount {
    return ouMap.get(key)?.find((ou) => ou.orgUnitId === orgUnitId);
  }

  processChildOrgUnits(
    ouMap: Map<number, OrgUnitStatusCount[]>,
    key: number,
    ou: OrgUnitStatusCount,
  ) {
    const childValues = ou.childKeys.map((c) => ({
      ...this.skillsStatusCounts.get(c),
    }));
    ouMap.set(key + 1, childValues);
    this.filteredOrgUnitMap.set(key + 1, childValues);
    this.collapsedRows[key + 1] = false;
  }

  hasChildKeys(orgUnit: OrgUnitStatusCount): boolean {
    return orgUnit.childKeys.length > 0;
  }

  onOuClick(rowKey: number, selectedOu: OrgUnitStatusCount) {
    this.showMatrix = false;

    const selectedLevel = Number(rowKey);
    this.createBreadcrumbAtLevel(selectedLevel, selectedOu);

    this.removeOuMapKeys(selectedLevel);
    this.updateSelectedOu(selectedOu, rowKey);
    if (this.hasChildKeys(selectedOu)) {
      this.processChildOrgUnits(this.orgUnitMap, rowKey, selectedOu);
      this.toggleCollapse(rowKey);
    } else {
      this.viewMatrix(selectedOu, rowKey);
    }
  }

  updateSelectedOu(selectedOu: OrgUnitStatusCount, rowKey: number) {
    const prevSelected = this.findSelectedOrgUnit(rowKey);
    prevSelected ? (prevSelected.selected = false) : false;
    selectedOu.selected = true;
    this.selectedOuHeaders[rowKey] = this.getSelectedOuHeader(selectedOu);
  }

  removeOuMapKeys(selectedLevel: number) {
    const keysToDelete = Array.from(this.orgUnitMap.keys())
      .map(Number)
      .filter((key) => key > selectedLevel);

    // Delete the filtered keys from orgUnitMap and orgUnitBreadCrumb
    keysToDelete.forEach((key) => {
      this.orgUnitMap.delete(key);
      this.filteredOrgUnitMap.delete(key);
      this.orgUnitBreadCrumb.delete(key);
    });
  }

  get orgUnitBreadCrumbSize(): number {
    return this.orgUnitBreadCrumb.size;
  }

  createBreadcrumbAtLevel(
    selectedLevel: number,
    selectedOu: OrgUnitStatusCount,
  ): void {
    const delimiter = ' | ';
    const previousLevel = selectedLevel - 1;
    const previousSelected =
      selectedLevel > 1 ? this.orgUnitBreadCrumb.get(previousLevel) : '';
    const breadcrumb = !previousSelected
      ? selectedOu.name
      : [previousSelected, selectedOu.name].join(delimiter);

    this.orgUnitBreadCrumb.set(selectedLevel, breadcrumb);
  }

  viewMatrix(
    selectedOu: OrgUnitStatusCount,
    rowKey: number,
    toggleCollapse = true,
  ): void {
    this.selectedOuHeaders[rowKey] = this.getSelectedOuHeader(selectedOu);
    this.matrixSummaryTitle =
      this.subjectBreadCrumb.join(' | ') +
      ' - ' +
      this.selectedOuHeaders[rowKey];

    this.matrixSummaryFilters = {
      subjectId: selectedOu.subjectId ?? 0,
      orgUnitId: selectedOu.orgUnitId,
    };

    this.canAccessOuData = this.availableOrgUnits.some(
      (aou) => Number(aou.key) === selectedOu.orgUnitId,
    );

    this.showMatrix = true;
    toggleCollapse ? this.toggleCollapse(rowKey) : false;
  }

  trackByFn(index: any, item: any): number {
    return index;
  }

  toggleCollapse(rowKey: number) {
    this.collapsedRows[rowKey] = this.collapsedRows[rowKey]
      ? !this.collapsedRows[rowKey]
      : true;
  }

  getSelectedOuHeader(ou: OrgUnitStatusCount): string {
    const complete = `${
      Math.round((ou?.publishedCount / ou?.totalCount) * 100 * 10) / 10
    }%`;
    return ou ? `${ou.name} | ${complete}` : '';
  }

  onFilterTextChange(filterText: string, ouLevel: number) {
    this.filterText[ouLevel] = filterText;
    const sort = this.sortByScoreDesc[ouLevel];
    const values = this.orgUnitMap.get(ouLevel) || [];

    const filterByName = (v) =>
      !filterText || v.name.toLowerCase().includes(filterText.toLowerCase());

    const sortByScoreDesc = (a, b) =>
      b.publishedCount / (b.totalCount || 1) -
      a.publishedCount / (a.totalCount || 1);

    const filteredValues = values
      .filter(filterByName)
      .sort(sort ? sortByScoreDesc : undefined);

    this.filteredOrgUnitMap.set(ouLevel, filteredValues);
  }

  onSortByScoreDesc(ouLevel: number) {
    this.onFilterTextChange(this.filterText[ouLevel], ouLevel);
  }

  onResetClick(ouLevel: number) {
    this.onFilterTextChange('', ouLevel);
  }
}
