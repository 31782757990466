import {
  Component,
  HostListener,
  inject,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AssessorService } from '../../assessor/services/assessor.service';
import {
  ClaimData,
  ClaimService,
  DotNetClaim,
} from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';
import { SkillService } from '../../skill/services/skill.service';

import { Assessor } from '../../assessor/models/assessor';
import { ClaimAction } from '../../claim/models/claim-action';
import { ClaimEvidence } from '../../claim/models/claim-evidence';
import { ClaimLevel } from '../../claim/models/claim-level';
import { FuncArea } from '../../skill/models/func-area';
import { Skill } from '../../skill/models/skill';

import { formatDate } from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subscription } from 'rxjs';

interface ControlStatus {
  disabled: boolean;
  reason: string;
}

@Component({
  selector: 'esqep-submission-detail', // tslint:disable-line:component-selector
  templateUrl: './submission-detail.component.html',
  styleUrls: ['./submission-detail.component.scss'],
})
export class SubmissionDetailComponent implements OnChanges, OnDestroy, OnInit {
  authService = inject(AuthService);
  claimService = inject(ClaimService);
  private alertService = inject(AlertService);
  private assessorService = inject(AssessorService);
  private configService = inject(ConfigService);
  private fb = inject(FormBuilder);
  private skillService = inject(SkillService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  exceptionData = {
    SUCCESS: {
      level: AlertLevels.SUCCESS,
      code: 'CED-000',
      message: 'Successful operation',
    } as AlertData,
    CLAIM_EVIDENCE: {
      level: AlertLevels.ERROR,
      code: 'CED-002',
      message: 'Error retrieving claim evidence',
    } as AlertData,
    TOP_COMP_DIM: {
      level: AlertLevels.ERROR,
      code: 'CED-003',
      message: 'Error retrieving top competency dimensions',
    } as AlertData,
    FUNC_AREA: {
      level: AlertLevels.ERROR,
      code: 'CED-004',
      message: 'Error retrieving functional area',
      translationKey: 'errRetrievingFuncArea',
    } as AlertData,
    SKILL: {
      level: AlertLevels.ERROR,
      code: 'CED-005',
      message: 'Error retrieving skill',
    } as AlertData,
    VERIFIER: {
      level: AlertLevels.ERROR,
      code: 'CED-006',
      message: 'Error retrieving verifier',
      translationKey: 'errRetrievingVerifier',
    } as AlertData,
    CLAIM: {
      level: AlertLevels.ERROR,
      code: 'CED-007',
      message: 'Error retrieving claim data',
    } as AlertData,
    MODIFY_CLAIM: {
      level: AlertLevels.ERROR,
      code: 'CED-008',
      message: 'Error modifying claim',
    } as AlertData,
    CREATE_CLAIM: {
      level: AlertLevels.ERROR,
      code: 'CED-009',
      message: 'Error creating claim',
    } as AlertData,
    INVALID_FILE_TYPE: {
      level: AlertLevels.WARNING,
      code: 'CED-010',
      message: 'File type not allowed',
    } as AlertData,
    CLAIM_LEVEL: {
      level: AlertLevels.ERROR,
      code: 'CED-011',
      message: 'Error retrieving claim level data',
      translationKey: 'errRetrievingClaimData',
    } as AlertData,
  };

  viewData = {
    activeTabIndex: 0,
    changesSaved: false,
    claimantView: true,
    editMode: false,
    savePending: false,
    showClaimHistory: false,
    buttons: {
      save: {
        disabled: () => {
          return (
            !this.submissionForm.get('detail').valid ||
            this.viewData.savePending
          );
        }, // || !this.claim.canEdit(); },
        visible: () => {
          return !this.viewData.editMode;
        },
      },
      modify: {
        disabled: () => {
          return (
            this.submissionForm.get('detail').pristine ||
            this.viewData.savePending ||
            !this.submissionForm.get('detail').valid
          );
        },
        visible: () => {
          return this.viewData.editMode;
        },
      },
      discard: {
        disabled: () => {
          return (
            this.submissionForm.get('detail').pristine ||
            this.viewData.savePending
          );
        },
        visible: () => {
          return this.viewData.editMode;
        },
      },
      create: {
        disabled: () => {
          return this.submissionForm.get('detail').dirty;
        },
        visible: () => {
          return this.viewData.editMode;
        },
      },
      cancel: {
        disabled: false,
        visible: () => {
          return !this.viewData.editMode;
        },
      },
    },
  };

  currentTab: number;
  initiatorType: string;
  currentAssessorId: number;
  currentEvidenceStatement: string;
  existingEvidenceStatementId: number = null;

  compDimension = 'KNOWLEDGE';
  selectedCompDimension: FuncArea;

  claim: DotNetClaim;
  evidenceList: Array<ClaimEvidence> = [];
  editClaimId: number;

  existingClaimData: ClaimData;

  selectedJobFamily: FuncArea = new FuncArea();

  claimSubs = new Subscription();
  claimCreateSubs = new Subscription();
  claimEvidenceSubs = new Subscription();
  claimLevelSubs = new Subscription();
  claimModifySubs = new Subscription();
  compDimsSubs = new Subscription();
  routeParamsSubs = new Subscription();
  skillSubs = new Subscription();
  skillFuncAreaSubs = new Subscription();
  tabIndexSubs = new Subscription();
  topCompDimsSubs = new Subscription();
  verifierSubs = new Subscription();
  submissionDetailSubs = new Subscription();

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.submissionForm.dirty) {
      return false;
    } else {
      return true;
    }
  }

  logging: any;
  // clog = (msg, lvl) => { this.configService.logging.newMsg( `${this.constructor.name}: ${msg}`, lvl); };

  submissionForm: FormGroup = new FormGroup({});

  get selectedAssessor(): Assessor {
    if (this.selectedClaimLevel && this.selectedClaimLevel['selfAssessed']) {
      return new Assessor();
    } else {
      return (
        this.submissionForm.get(
          'detail.levelAndVerifier.verifier.selectedVerifier',
        ) || new FormControl()
      ).value as Assessor;
    }
  }

  get claimExpiryDate(): string {
    return this.submissionForm.get('action.detail.verification.expiryDate')
      .value;
  }

  set selectedAssessor(value: Assessor) {
    if (this.isEmptyObject(value)) {
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.selectedVerifier')
        .reset();
    } else {
      if (this.claim?.assessorId === value.assessorId) {
        this.submissionForm
          .get('detail.levelAndVerifier.verifier.selectedVerifier')
          .markAsPristine();
      } else {
        this.submissionForm
          .get('detail.levelAndVerifier.verifier.selectedVerifier')
          .markAsDirty();
      }
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.selectedVerifier')
        .setValue({
          assessorId: value.assessorId,
          personId: value.personId,
          funcAreaId: value.funcAreaId,
          funcAreaName: value.funcAreaName,
          funcAreaDescription: value.funcAreaDescription,
          inOrgBranch: value.inOrgBranch || true, // TODO: Not accurate default
          personFamilyGivenName: value.personFamilyGivenName,
          orgUnitName: value.orgUnitName || value['name'] || '', // TODO: Change in API
        });
    }
  }

  get verifierChangeText(): string {
    return this.submissionForm.get(
      'detail.levelAndVerifier.verifier.verifierChange.statementText',
    ).value as string;
  }

  get selectedClaimAction(): ClaimAction {
    if (this.viewData.claimantView) {
      return this.submissionForm.get('action.detail.claimantAction.claimAction')
        .value;
    } else {
      return this.submissionForm.get('action.detail.verification.claimAction')
        .value;
    }
  }

  set selectedClaimAction(value: ClaimAction) {
    const caFormGroup = this.viewData.claimantView
      ? this.submissionForm.get('action.detail.claimantAction.claimAction')
      : this.submissionForm.get('action.detail.verification.claimAction');

    if (this.isEmptyObject(value)) {
      caFormGroup.reset();
    } else {
      caFormGroup.setValue({
        claimStatusActionSetId: value.claimStatusActionSetId,
        id: value.id,
        name: value.name,
        description: value.description,
        resultingClaimStatus: value.resultingClaimStatus,
        resultingClaimStatusId: value.resultingClaimStatusId,
        requireEvidence: value.requireEvidence,
      });
    }
  }

  get selectedClaimLevel(): ClaimLevel {
    return this.submissionForm.get('detail.levelAndVerifier.level')
      .value as ClaimLevel;
  }

  set selectedClaimLevel(value: ClaimLevel) {
    if (value.id) {
      this.submissionForm.get('detail.levelAndVerifier.level').setValue({
        id: value.id,
        name: value.name,
        description: value.description,
        levelCode: value.levelCode,
        selfAssessed: value.selfAssessed,
        claimLevelSetId: value.claimLevelSetId,
        sortOrder: value.sortOrder,
        approvalLevel: value.approvalLevel,
      });
    } else {
      this.submissionForm.get('detail.levelAndVerifier.level').reset();
    }
    if (value.selfAssessed) {
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.selectedVerifier')
        .reset();
      this.submissionForm.get('detail.levelAndVerifier.verifier').disable();
    } else {
      this.submissionForm.get('detail.levelAndVerifier.verifier').enable();
    }
  }

  get selectedSkill(): Skill {
    return this.submissionForm.get('detail.competency.competencyDetail').value;
  }

  set selectedSkill(value: Skill) {
    if (value.id) {
      this.submissionForm.get('detail.competency.competencyDetail').setValue({
        id: value.id,
        funcAreaId: value.funcAreaId,
        funcAreaName: value.funcAreaName,
        funcAreaDesc: value.funcAreaDesc,
        funcAreaTypeName: value.funcAreaTypeName,
        name: value.name,
        description: value.description,
      });
      if (this.claim?.skillId === value.id) {
        this.submissionForm
          .get('detail.competency.competencyDetail')
          .markAsPristine();
      } else {
        this.submissionForm
          .get('detail.competency.competencyDetail')
          .markAsDirty();
      }
    } else {
      this.submissionForm.get('detail.competency.competencyDetail').reset();
      this.submissionForm.get('detail.levelAndVerifier.level').reset();
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.selectedVerifier')
        .reset();
    }
  }

  get evidenceText(): string {
    return this.submissionForm.get('detail.evidence.statementText').value;
  }

  set evidenceText(value: string) {
    this.submissionForm.get('detail.evidence.statementText').setValue(value);
  }

  get claimantAction(): ClaimAction {
    return this.submissionForm.get('action.detail.claimantAction.claimAction')
      .value as ClaimAction;
  }

  set claimantAction(value: ClaimAction) {
    if (value.id) {
      this.submissionForm
        .get('action.detail.claimantAction.claimAction')
        .setValue(value);
    } else {
      this.submissionForm
        .get('action.detail.claimantAction.claimAction')
        .reset();
    }
  }

  get verifierAction(): ClaimAction {
    return this.submissionForm.get('action.detail.verification.claimAction')
      .value as ClaimAction;
  }

  set verifierAction(value: ClaimAction) {
    if (value.id) {
      this.submissionForm
        .get('action.detail.verification.claimAction')
        .setValue(value);
    } else {
      this.submissionForm.get('action.detail.verification.claimAction').reset();
    }
  }

  get verifierStatementValid(): boolean {
    return this.submissionForm.get(
      'action.detail.verification.statement.statementText',
    ).valid;
  }

  get detailFormGroup(): FormGroup {
    return this.submissionForm.get('detail') as FormGroup;
  }

  constructor() {
    this.logging = this.configService.logging;
    if (this.route.snapshot.url[1].path === 'new') {
      this.logging.newMsg('New claim', 'VERBOSE');
      this.viewData.editMode = false;
    } else {
      this.logging.newMsg('Edit claim', 'VERBOSE');
      this.viewData.editMode = true;
    }
    this.createForm();
    this.submissionDetailSubs.add(
      this.authService.loggedInUserSubj.subscribe((liu: LoggedInUser) => {
        if (liu?.id) {
          this.submissionForm.get('detail.claimant.id').setValue(liu.id);
        }
      }),
    );
  }

  ngOnInit() {
    this.claimService.eviFiles = [];
    this.existingClaimData = {};

    if (this.viewData.editMode === false) {
      this.viewData.claimantView = true;
      this.submissionForm
        .get('detail.claimant.id')
        .setValue(this.authService.me?.id);
    } else {
      this.editClaimId = parseInt(this.route.snapshot.url[1].path, 10);
      this.initiatorType = this.route.snapshot.url[2].path;
      if (this.initiatorType === 'assessor') {
        this.viewData.claimantView = false;
      } else {
        this.viewData.claimantView = true;
      }
      this.logging.newMsg('Initiator Type:' + this.initiatorType, 'VERBOSE');
      this.claimSubs = this.claimService
        .getClaimById(this.editClaimId)
        .subscribe(
          (c: DotNetClaim) => {
            this.existingClaimData.claim = c;
            this.claim = { ...c };
            this.selectedAssessor.assessorId = this.claim.assessorId;
            this.selectedClaimLevel.id = this.claim.claimLevelId;
            this.submissionForm.get('detail.claimant.id').setValue(c.personId);

            if (c.expiryDate) {
              const expiryDate = formatDate(
                c.expiryDate,
                'yyyy-MM-dd',
                'en-UK',
              );
              this.submissionForm
                .get('action.detail.verification.expiryDate')
                .setValue(expiryDate);
            }

            this.getClaimEvidence(c);
            this.topCompDimsSubs = this.skillService
              .getTopCompDimension(c.skillId)
              .subscribe(
                (f: FuncArea) => {
                  this.compDimension = f.name;
                  this.selectedCompDimension = f;
                },
                (err) => {
                  this.alertService.createAlert2(
                    this.exceptionData.TOP_COMP_DIM,
                    err,
                  );
                },
              );
            this.skillSubs = this.skillService.getSkill(c.skillId).subscribe(
              (s: Skill) => {
                this.existingClaimData.skill = s[0];
                this.selectedSkill = s[0];
                this.selectedJobFamily.id = s[0].funcAreaId;
                this.skillFuncAreaSubs = this.skillService
                  .getFuncArea(this.selectedSkill.funcAreaId)
                  .subscribe(
                    (f: Array<FuncArea>) => {
                      this.selectedJobFamily = f[0];
                    },
                    (err) => {
                      this.alertService.createAlert2(
                        this.exceptionData.FUNC_AREA,
                        err,
                      );
                    },
                  );
              },
              (err) => {
                this.alertService.createAlert2(this.exceptionData.SKILL, err);
              },
            );
            if (c.assessorId) {
              this.verifierSubs = this.assessorService
                .getAssessor(c.assessorId)
                .subscribe(
                  (a: Assessor) => {
                    this.existingClaimData.assessor = a[0];
                    this.selectedAssessor = a[0];
                    this.currentAssessorId = a[0].assessorId;
                  },
                  (err) => {
                    this.alertService.createAlert2(
                      this.exceptionData.VERIFIER,
                      err,
                    );
                  },
                );
            }
            if (c.claimLevelId) {
              this.claimLevelSubs = this.claimService
                .getClaimLevel(c.claimLevelId)
                .subscribe(
                  (cl: ClaimLevel) => {
                    this.existingClaimData.level = cl;
                    this.selectedClaimLevel = cl;
                  },
                  (err) => {
                    this.alertService.createAlert2(
                      this.exceptionData.CLAIM_LEVEL,
                      err,
                    );
                  },
                );
            }
          },
          (err: any) => {
            this.alertService.createAlert2(this.exceptionData.CLAIM, err);
          },
        );
    }

    if (this.viewData.claimantView) {
      this.submissionForm.get('action.detail.verification').disable();
      this.submissionForm.get('action.detail.claimantAction').enable();
    } else {
      this.submissionForm.get('action.detail.verification').enable();
      this.submissionForm.get('action.detail.claimantAction').disable();
    }
  }

  private getClaimEvidence(c: DotNetClaim) {
    if (this.evidenceStatementRequired) {
      this.submissionForm.get('detail.evidence').enable();
    } else {
      this.submissionForm.get('detail.evidence').disable();
    }
    this.claimEvidenceSubs = this.claimService.getClaimEvidence(c.id).subscribe(
      (ce: Array<ClaimEvidence>) => {
        this.existingClaimData.evidence = JSON.parse(JSON.stringify(ce));
        this.setupClaimEvidence(ce);
      },
      (err) => {
        this.alertService.createAlert2(this.exceptionData.CLAIM_EVIDENCE, err);
      },
    );
  }

  ngOnChanges(changes) {
    console.log(this.submissionForm.get('detail.evidence'));
  }

  ngOnDestroy() {
    this.claimSubs.unsubscribe();
    this.claimCreateSubs.unsubscribe();
    this.claimEvidenceSubs.unsubscribe();
    this.claimModifySubs.unsubscribe();
    this.compDimsSubs.unsubscribe();
    this.routeParamsSubs.unsubscribe();
    this.skillSubs.unsubscribe();
    this.skillFuncAreaSubs.unsubscribe();
    this.tabIndexSubs.unsubscribe();
    this.topCompDimsSubs.unsubscribe();
    this.verifierSubs.unsubscribe();

    this.submissionDetailSubs.unsubscribe();
  }

  createForm() {
    this.submissionForm = this.fb.group({
      detail: this.fb.group({
        claimant: this.fb.group({
          id: [null, Validators.required],
        }),
        competency: this.fb.group({
          competencyDimensionRadio: this.fb.array([]),
          competencyDetail: this.fb.group(
            {
              // id: [null, Validators.required],
              id: null,
              funcAreaId: null,
              funcAreaName: null,
              funcAreaDesc: null,
              // level: null,
              funcAreaTypeName: null,
              name: null,
              description: null,
            },
            { validator: this.competencyDetailValidator() },
          ),
        }),
        levelAndVerifier: this.fb.group(
          {
            level: this.fb.group(
              {
                // id: [null, Validators.required],
                id: null,
                name: null,
                description: null,
                levelCode: null,
                selfAssessed: null,
                claimLevelSetId: null,
                sortOrder: null,
                approvalLevel: null,
              },
              { validator: this.levelValidator() },
            ),
            verifier: this.fb.group(
              {
                orgUnitVerifiersOnly: this.fb.control(false),
                selectedVerifier: this.fb.group(
                  {
                    // assessorId: [null, Validators.required],
                    assessorId: null,
                    personId: null,
                    funcAreaId: null,
                    funcAreaName: null,
                    funcAreaDescription: null,
                    inOrgBranch: null,
                    personFamilyGivenName: null,
                    orgUnitName: null,
                  },
                  { validator: this.selectedVerifierValidator() },
                ),
                verifierChange: this.fb.group(
                  {
                    statementText: [{ value: null, disabled: true }],
                    // statementText: [{value: null, disabled: true}, this.verifierChangeValidator ],
                  },
                  { validator: this.verifierChangeValidator() },
                ),
              },
              { validator: this.verifierGroupValidator() },
            ),
          },
          { validator: this.levelAndVerifierValidator() },
        ),
        evidence: this.fb.group(
          {
            // statementText: [null, Validators.required],
            statementText: [null],
            previousEvidence: this.fb.array([]),
            evidenceFiles: this.fb.array([]),
          },
          { validator: this.evidenceValidator() },
        ),
      }),
      history: this.fb.array([]),
      summary: this.fb.group({}),
      action: this.fb.group({
        detail: this.fb.group({
          claimantAction: this.fb.group({
            claimAction: this.fb.group({
              claimStatusActionSetId: null,
              id: [null, Validators.required],
              name: null,
              description: null,
              resultingClaimStatus: null,
              resultingClaimStatusId: null,
              requireEvidence: null,
            }),
          }),
          verification: this.fb.group({
            expiryDate: new FormControl({ value: null, disabled: true }, [
              this.dateInPastValidator(),
            ]),
            statement: this.fb.group({
              statementText: new FormControl(
                { value: null, disabled: true },
                Validators.required,
              ),
            }),
            claimAction: this.fb.group(
              {
                claimStatusActionSetId: null,
                id: [null, Validators.required],
                name: null,
                description: null,
                resultingClaimStatus: null,
                resultingClaimStatusId: null,
                requireEvidence: null,
              },
              { validator: this.verificationActionValidator() },
            ),
          }),
        }),
        buttons: this.fb.group({
          okButton: this.fb.control('Ok'),
          cancelButton: this.fb.control('Cancel'),
        }),
      }),
    });

    this.submissionForm.get('action.detail').disable();
    setTimeout(() => {
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.verifierChange')
        .disable();
    }, 0);

    // this.submissionDetailSubs.add(
    //   this.submissionForm.get('detail.evidence.statementText').valueChanges.subscribe(
    //     st => {
    //       // this.newClaimEvidence.description = st;
    //       // this.newClaimEvidence.initiatorType = 'C';
    //       console.log("('detail.evidence.statementText').valueChanges")
    //     }
    //   )
    // )

    /* TODO: This gives erroneous errors
    this.submissionDetailSubs.add(
      this.submissionForm.get('detail.competency.competencyDetail').statusChanges.subscribe(
        (compDetailStatus) => {
          if (compDetailStatus === 'INVALID') {
            if (!this.submissionForm.get('detail.levelAndVerifier.level').hasError('competencyAttrBeforeLevel')) {
              setTimeout( () => {
                this.submissionForm.get('detail.levelAndVerifier.level').setErrors({'competencyAttrBeforeLevel': true});
              }, 0);
            }
            if (!this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').hasError('competencyAttrBeforeVerifier')) {
              setTimeout( () => {
                this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').setErrors(
                  {'competencyAttrBeforeVerifier': true}
                );
              }, 0);
            }
          } else if (compDetailStatus === 'VALID') {
            if (this.submissionForm.get('detail.levelAndVerifier.level').hasError('competencyAttrBeforeLevel')) {
              setTimeout( () => {
                this.submissionForm.get('detail.levelAndVerifier.level').setErrors({'competencyAttrBeforeLevel': false});
              }, 0)
            }
            if (this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').hasError('competencyAttrBeforeVerifier')) {
              setTimeout( () => {
                this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').setErrors(
                  {'competencyAttrBeforeVerifier': false}
                );
              }, 0)
            }
          }

          // if (
          //   compDetailStatus === 'INVALID' &&
          //   !this.submissionForm.get('detail.levelAndVerifier.level').hasError('competencyAttrBeforeLevel')
          // ) {
          //   setTimeout( () => {
          //     this.submissionForm.get('detail.levelAndVerifier.level').setErrors({'competencyAttrBeforeLevel': true});
          //     this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').setErrors(
          //       {'competencyAttrBeforeVerifier': true}
          //     );
          //   }, 0);
          // } else if (
          //   compDetailStatus === 'VALID' &&
          //   this.submissionForm.get('detail.levelAndVerifier').hasError('competencyAttrBeforeLevel')
          // ) {
          //   setTimeout( () => {
          //     this.submissionForm.get('detail.levelAndVerifier.level').setErrors({'competencyAttrBeforeLevel': false});
          //     this.submissionForm.get('detail.levelAndVerifier.verifier.selectedVerifier').setErrors(
          //       {'competencyAttrBeforeVerifier': false}
          //     );
          //   }, 0)
          // }
        }
      )
    )
*/

    this.submissionDetailSubs.add(
      this.submissionForm
        .get('action.detail.verification.claimAction')
        .valueChanges.subscribe((ca) => {
          if (
            ca.id &&
            this.submissionForm.get(
              'action.detail.verification.statement.statementText',
            ).disabled
          ) {
            setTimeout(() => {
              this.submissionForm
                .get('action.detail.verification.statement.statementText')
                .enable();
            }, 0);
          }
          if (
            ca.id == null &&
            this.submissionForm.get(
              'action.detail.verification.statement.statementText',
            ).enabled
          ) {
            setTimeout(() => {
              this.submissionForm
                .get('action.detail.verification.statement.statementText')
                .disable();
            }, 0);
          }
        }),
    );
    this.submissionDetailSubs.add(
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.orgUnitVerifiersOnly')
        .valueChanges.subscribe(() => {
          // Prevent this control causing form to be dirty when changed
          this.submissionForm
            .get('detail.levelAndVerifier.verifier.orgUnitVerifiersOnly')
            .markAsPristine();
        }),
    );
    this.submissionDetailSubs.add(
      this.submissionForm
        .get('detail.levelAndVerifier.verifier.selectedVerifier')
        .valueChanges.subscribe((changes) => {
          if (changes.assessorId === this.claim?.assessorId) {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier.selectedVerifier')
                .markAsPristine();
              this.submissionForm
                .get('detail.levelAndVerifier.verifier.verifierChange')
                .disable();
            }, 0);
          }
          if (
            this.viewData.editMode &&
            this.claim.assessorId &&
            this.submissionForm.get(
              'detail.levelAndVerifier.verifier.selectedVerifier',
            ).dirty
          ) {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier.verifierChange')
                .enable();
            }, 0);
          } else if (
            this.viewData.editMode &&
            !this.claim.assessorId &&
            this.claim.id &&
            this.submissionForm.get(
              'detail.levelAndVerifier.verifier.selectedVerifier',
            ).dirty
          ) {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier.verifierChange')
                .enable();
              this.submissionForm
                .get(
                  'detail.levelAndVerifier.verifier.verifierChange.statementText',
                )
                .setValue('Assessor Changed');
            }, 0);
          } else {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier.verifierChange')
                .disable();
            }, 0);
          }
        }),
    );
    this.submissionDetailSubs.add(
      this.submissionForm
        .get('detail.levelAndVerifier.level')
        .valueChanges.subscribe((cl) => {
          if (cl.selfAssessed) {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier')
                .disable();
            }, 0);
          } else {
            setTimeout(() => {
              this.submissionForm
                .get('detail.levelAndVerifier.verifier')
                .enable();
            }, 0);
          }
        }),
    );
    // this.submissionDetailSubs.add(
    //   this.submissionForm.get('detail').statusChanges.subscribe(
    //     changedStatus => {
    //       if ( changedStatus === 'VALID' && this.submissionForm.get('detail').pristine) {
    //         // this.submissionForm.get('action').enable();
    //       } else {
    //         // this.submissionForm.get('action').disable();
    //       }
    //     }
    //   )
    // )
    // this.submissionDetailSubs.add(
    //   this.submissionForm.get('detail').valueChanges.subscribe(
    //   (changes) => {
    //       // console.log("('detail').valueChanges")

    //       const targetFormGroup: FormGroup = this.submissionForm.get(
    //         'action.detail' + (this.viewData.claimantView ? '.claimantAction' : '.verification')
    //       ) as FormGroup;
    //       // console.log('action.detail' + (this.viewData.claimantView ? '.claimantAction' : '.verification'));

    //       if (this.submissionForm.get('detail').pristine && this.submissionForm.get('detail').valid && targetFormGroup.disabled) {
    //         // console.log('Enabled ' + (this.viewData.claimantView ? '.claimantAction' : '.verification'))
    //         setTimeout( () => {
    //           targetFormGroup.get('claimAction').enable();
    //           this.submissionForm.get('action').updateValueAndValidity();  // Re-evaluate so buttons get correct state?
    //         }, 0);
    //       }

    //       if ((this.submissionForm.get('detail').dirty || this.submissionForm.get('detail').invalid) && targetFormGroup.enabled) {
    //         // console.log('Disabled ' + (this.viewData.claimantView ? '.claimantAction' : '.verification'))
    //         setTimeout( () => {
    //           targetFormGroup.disable()
    //           this.submissionForm.get('action.buttons').disable();
    //         }, 0)
    //       }
    //     }
    //   )
    // );
    // this.submissionDetailSubs.add(
    //   this.submissionForm.get('action.detail').valueChanges.subscribe(
    //     () => {
    //       // console.log("('action.detail').valueChanges")
    //       // console.log('action.detail: ' + this.submissionForm.get('action.detail').status)
    //       if (this.submissionForm.get('action.detail').valid && this.submissionForm.get('action.buttons.okButton').disabled) {
    //         // console.log('action.detail valid; enable okButton')
    //         setTimeout( () => {
    //           this.submissionForm.get('action.buttons.okButton').enable();
    //         }, 0);
    //       }
    //       if (this.submissionForm.get('action.detail').invalid && this.submissionForm.get('action.buttons.okButton').enabled) {
    //         // console.log('action.detail invalid; disable okButton')
    //         setTimeout( () => {
    //           this.submissionForm.get('action.buttons.okButton').disable();
    //         }, 0);
    //       }
    //       if (this.submissionForm.get('action.detail').dirty && this.submissionForm.get('action.buttons.cancelButton').disabled) {
    //         // console.log('action.detail dirty; enable cancelButton')
    //         setTimeout( () => {
    //           this.submissionForm.get('action.buttons.cancelButton').enable();
    //         }, 0);
    //       }
    //       if (this.submissionForm.get('action.detail').pristine && this.submissionForm.get('action.buttons.cancelButton').enabled) {
    //         // console.log('action.detail pristine; disable cancelButton')
    //         setTimeout( () => {
    //           this.submissionForm.get('action.buttons.cancelButton').disable();
    //         }, 0);
    //       }
    //     }
    //   )
    // );
  }

  competencyDetailValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (!control.get('id').value) {
        return { competencyAttrRequired: true };
      }
      return null;
    };
  }

  levelValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (!control.get('id').value) {
        return { levelRequired: true };
      }
      return null;
    };
  }

  selectedVerifierValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (!control.get('assessorId').value) {
        return { verifierRequired: true };
      }
      return null;
    };
  }

  levelAndVerifierValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (
        !control.get('level.selfAssessed').value &&
        !control.get('verifier.selectedVerifier.assessorId').value
      ) {
        return { verifierRequiredForLevel: true };
      }
      return null;
    };
  }

  verifierGroupValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (
        this.viewData.editMode &&
        control.get('selectedVerifier').dirty &&
        this.claim.assessorId &&
        this.claim.assessorId !==
          control.get('selectedVerifier.assessorId').value &&
        !control.get('verifierChange.statementText').value
      ) {
        return { verifierChangeReasonRequired: true };
      }
      return null;
    };
  }

  verifierChangeValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (!control.get('statementText').value) {
        return { verifierChangeReasonRequired: true };
      }
      return null;
    };
  }

  evidenceValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (
        !(
          (this.claim?.isPublished && !this.selectedClaimAction.id) ||
          !this.viewData.claimantView ||
          (
            this.submissionForm.get('detail.evidence.statementText')?.value ||
            ''
          ).length > 0
        )
      ) {
        return { evidenceStatementRequired: true };
      }
      return null;
    };
  }

  verificationValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (
        control.get('claimAction.id').value &&
        control.get('statementText').value.length < 1
      ) {
        return { verifierStatementRequired: true };
      }
      return null;
    };
  }

  verificationActionValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (!control.get('id').value) {
        return { verificationActionRequired: true };
      }
      return null;
    };
  }

  modifyClaim() {
    this.viewData.savePending = true;
    this.claimService
      .modifyClaim(this.saveClaimData)
      .pipe(finalize(() => (this.viewData.savePending = false)))
      .subscribe(
        (c: DotNetClaim) => {
          this.claim = { ...c };
          this.submissionForm.markAsPristine();
          this.submissionForm.get('detail').updateValueAndValidity();
          this.alertService.createAlert(
            AlertLevels.SUCCESS,
            `Claim ID: ${this.claim.id} has been modified`,
            'CED-000',
          );
        },
        (err) => {
          this.alertService.createAlert2(this.exceptionData.MODIFY_CLAIM, err);
        },
      );
  }

  discardChanges() {
    this.selectedAssessor = this.existingClaimData.assessor || new Assessor();
    this.setupClaimEvidence(this.existingClaimData.evidence);
    this.selectedClaimLevel = this.existingClaimData.level;
    this.selectedSkill = this.existingClaimData.skill;

    this.submissionForm
      .get('detail.levelAndVerifier.verifier.verifierChange')
      .disable();
    this.submissionForm
      .get('detail.levelAndVerifier.verifier.verifierChange.statementText')
      .reset();
    this.submissionForm.get('detail').markAsPristine();
  }

  createClaim() {
    this.viewData.savePending = true;
    if (this.viewData.editMode) {
      this.claimModifySubs = this.claimService
        .modifyClaim(this.claimData)
        .pipe(
          finalize(() => {
            this.viewData.savePending = false;
          }),
        )
        .subscribe(
          (c: DotNetClaim) => {
            this.logging.newMsg('Claim Data:' + JSON.stringify(c), 'VERBOSE');
            this.claim = { ...c };
            this.currentEvidenceStatement = this.evidenceText;
            this.currentAssessorId = c.assessorId;
            this.logging.newMsg('Modified claim', 'VERBOSE');
            this.viewData.changesSaved = true;
            this.viewData.editMode = true;
            this.submissionForm.markAsPristine();
            this.alertService.createAlert(
              AlertLevels.SUCCESS,
              `Claim ID: ${this.claim.id} has been modified`,
              'CED-000',
            );
          },
          (err) => {
            this.alertService.createAlert2(
              this.exceptionData.MODIFY_CLAIM,
              err,
            );
          },
        );
    } else {
      this.claimCreateSubs = this.claimService
        .createClaim2(this.submissionForm.value)
        .pipe(
          finalize(() => {
            this.viewData.savePending = false;
          }),
        )
        .subscribe(
          (c: DotNetClaim) => {
            this.claim = { ...c };
            this.currentEvidenceStatement = this.evidenceText;
            this.currentAssessorId = this.selectedAssessor.assessorId;
            this.logging.newMsg('Created claim', 'VERBOSE');
            this.viewData.changesSaved = true;
            this.viewData.editMode = true;
            this.submissionForm
              .get(
                'detail.levelAndVerifier.verifier.verifierChange.statementText',
              )
              .reset();
            this.submissionForm
              .get('detail.evidence.statementText')
              .reset(this.evidenceText);
            this.submissionForm
              .get('action.detail.verification.statement.statementText')
              .reset('');
            this.submissionForm.markAsPristine();
            this.alertService.createAlert(
              AlertLevels.SUCCESS,
              `Claim has been created with ID: ${this.claim.id}, status: ${
                this.claim.statusText ?? 'Draft'
              }`,
              'CED-000',
            );
            this.router.navigate([
              '/assessment-request',
              this.claim.id,
              'claimant',
            ]);
          },
          (err) => {
            this.alertService.createAlert2(
              this.exceptionData.CREATE_CLAIM,
              err,
            );
          },
        );
    }
  }

  onSubmitActionClick() {
    this.claimModifySubs = this.claimService
      .modifyClaim(this.claimData)
      .pipe(
        finalize(() => {
          this.viewData.savePending = false;
        }),
      )
      .subscribe(
        (c: DotNetClaim) => {
          this.logging.newMsg('Claim Data:' + JSON.stringify(c), 'VERBOSE');
          this.claim = { ...c };
          this.logging.newMsg('Claim has been modified', 'VERBOSE');
          this.viewData.changesSaved = true;
          this.viewData.editMode = true;
          this.getClaimEvidence(this.claim);
          this.selectedClaimAction = new ClaimAction();
          this.alertService.createAlert(
            AlertLevels.SUCCESS,
            `Claim ID: ${this.claim.id} has been modified`,
            'CED-000',
          );
        },
        (err) => {
          this.alertService.createAlert2(this.exceptionData.MODIFY_CLAIM, err);
        },
      );
  }

  onActionCancel() {
    this.submissionForm
      .get(
        'action.detail' +
          (this.viewData.claimantView ? '.claimantAction' : '.verification'),
      )
      .reset();
  }

  tabClick(tabIndex: number) {
    this.currentTab = tabIndex;
    this.logging.newMsg('Clicked tab with index=' + tabIndex, 'VERBOSE');
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.currentTab = changeEvent.nextId;
  }

  detailTabStatusClass(fg: AbstractControl, editMode: boolean): string {
    const classList: Array<string> = [];
    classList.push('fas fa-xl');
    if (fg.invalid) {
      classList.push('fa-exclamation-circle');
      classList.push('text-danger');
    } else {
      if (editMode && fg.dirty) {
        classList.push('fa-asterisk');
        classList.push('text-warning');
      } else {
        classList.push('fa-check-circle text-success');
      }
    }
    return classList.join(' ');
  }

  actionTabStatusClass(): string {
    const classList: Array<string> = [];
    classList.push('fas fa-xl');
    if (this.submissionForm.get('detail').invalid) {
      classList.push('fa-ban');
      classList.push('text-danger');
    } else if (this.submissionForm.get('detail').dirty) {
      classList.push('fa-ban');
      classList.push('text-warning');
    } else {
      if (this.submissionForm.get('action').dirty) {
        if (this.submissionForm.get('action').invalid) {
          classList.push('fa-exclamation-circle');
          classList.push('text-danger');
        } else {
          classList.push('fa-check-circle text-success');
        }
      } else {
        classList.push('fa-lightbulb');
      }
    }
    return classList.join(' ');
  }

  public get claimData(): ClaimData {
    const claimData = {};

    if (!this.isEmptyObject(this.claim)) {
      if (!this.isEmptyObject(this.claimExpiryDate)) {
        this.claim.expiryDate = this.claimExpiryDate;
      }
      claimData['claim'] = this.claim;
    }
    if (!this.isEmptyObject(this.selectedClaimAction)) {
      claimData['action'] = this.selectedClaimAction;
    }
    if (!this.isEmptyObject(this.selectedAssessor)) {
      if (
        !this.isEmptyObject(this.selectedClaimLevel) &&
        this.selectedClaimLevel.selfAssessed !== true
      ) {
        claimData['assessor'] = this.selectedAssessor;
      }
    }
    if (!this.isEmptyObject(this.claimStatements)) {
      claimData['evidence'] = this.claimStatements;
    }
    if (!this.isEmptyObject(this.selectedClaimLevel)) {
      claimData['level'] = this.selectedClaimLevel;
    }
    if (!this.isEmptyObject(this.selectedSkill)) {
      claimData['skill'] = this.selectedSkill;
    }
    claimData['initiator'] = {
      initiatorType: this.viewData.claimantView ? 'C' : 'A',
      personId: this.authService.me?.id,
    };
    return claimData;
  }

  formatExpiryDate(expiryDate: { day: number; month: number; year: number }) {
    return `${expiryDate.day}`;
  }

  public get saveClaimData(): ClaimData {
    /**
     * Generate claim data for saving (not actioning). Any selected action is excluded from returned data.
     */
    const claimData = {};
    if (!this.isEmptyObject(this.claim)) {
      claimData['claim'] = this.claim;
    }
    if (!this.isEmptyObject(this.selectedAssessor)) {
      if (
        !this.isEmptyObject(this.selectedClaimLevel) &&
        this.selectedClaimLevel.selfAssessed !== true
      ) {
        claimData['assessor'] = this.selectedAssessor;
      }
    }
    if (!this.isEmptyObject(this.claimStatements)) {
      claimData['evidence'] = this.claimStatements;
    }
    if (!this.isEmptyObject(this.selectedClaimLevel)) {
      claimData['level'] = this.selectedClaimLevel;
    }
    if (!this.isEmptyObject(this.selectedSkill)) {
      claimData['skill'] = this.selectedSkill;
    }
    claimData['initiator'] = {
      initiatorType: this.viewData.claimantView ? 'C' : 'A',
      personId: this.authService.me.id,
    };
    return claimData;
  }

  get claimStatements(): any {
    const claimStatements = {};
    if (this.viewData.claimantView) {
      if (this.submissionForm.get('detail.evidence.statementText').dirty) {
        claimStatements['statement'] = {
          description: this.evidenceText,
          initiatorType: 'C',
        };
        if (this.existingEvidenceStatementId) {
          claimStatements['statement']['id'] = this.existingEvidenceStatementId;
        }
      }
    } else {
      claimStatements['assessorStatement'] = this.submissionForm.get(
        'action.detail.verification.statement.statementText',
      ).value;
    }
    if (this.assessorChanged) {
      claimStatements['assessorChangeReason'] = this.submissionForm.get(
        'detail.levelAndVerifier.verifier.verifierChange.statementText',
      ).value;
    }
    return claimStatements;
  }

  get assessorChanged(): boolean {
    const assessorChanged =
      this.viewData.editMode &&
      this.selectedAssessor &&
      this.selectedAssessor.assessorId &&
      ((this.existingClaimData.assessor &&
        this.existingClaimData.assessor.assessorId &&
        this.existingClaimData.assessor.assessorId !==
          this.selectedAssessor.assessorId) ||
        (this.claim.id &&
          !this.claim.assessorId &&
          this.submissionForm.get(
            'detail.levelAndVerifier.verifier.selectedVerifier',
          ).dirty));

    return assessorChanged;
  }

  get evidenceStatementRequired(): boolean {
    return (
      this.viewData.claimantView &&
      (!this.claim.isPublished ||
        (this.claim.isPublished &&
          this.selectedClaimAction.id !== undefined &&
          this.selectedClaimAction.id !== null))
    );
  }

  isEmptyObject(obj: any) {
    if (!obj) {
      return true;
    }
    for (const prop of Object.keys(obj)) {
      if (obj[prop]) {
        return false;
      }
    }
    return true;
  }

  private setupClaimEvidence(ce: Array<ClaimEvidence>) {
    if (
      ce.length > 0 &&
      ce[0].initiatorType === 'C' &&
      !this.claim.isPublished
    ) {
      this.evidenceList = ce.slice(1);
      this.evidenceText = ce[0].description;
      this.existingEvidenceStatementId = ce[0].id;
      this.submissionForm
        .get('detail.evidence.statementText')
        .reset(this.evidenceText);
    } else {
      this.evidenceList = ce;
      this.submissionForm.get('detail.evidence.statementText').reset('');
    }
  }

  toggleClaimHistory(): void {
    this.viewData.showClaimHistory = !this.viewData.showClaimHistory;
  }

  dateInPastValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const selectedDate = new Date(control.value);
      const currentDate = new Date();

      if (selectedDate < currentDate) {
        return { dateInPast: true };
      }
      return null;
    };
  }
}
