<div class="card">
  <div class="card-header bg-danger text-light">
    <h2>Page Not Found</h2>
  </div>
  <div class="card-body">
    <p>We couldn't find that page!</p>
  </div>
  <div class="card-footer">
    <div class="btn-group float-end">
      <button class="btn btn-outline-primary" (click)="home()" role="button">
        <span class="fas fa-home"></span> Home
      </button>
    </div>
  </div>
</div>
