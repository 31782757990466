import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
// import { catchError } from 'rxjs/operators/catchError';

import { FuncArea } from '../models/func-area';
import { Skill } from '../models/skill';

import { ClaimService } from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { SkillService } from '../services/skill.service';

// import 'rxjs/add/operator/debounceTime';
// import 'rxjs/add/operator/distinctUntilChanged';
// import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'ug-job-family-filter',
  templateUrl: './job-family-filter.component.html',
  styleUrls: [],
})
export class JobFamilyFilterComponent implements OnDestroy, OnInit {
  private fb = inject(FormBuilder);
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);
  private skillService = inject(SkillService);

  testFormArray: FormArray;
  testFormGroup: FormGroup;

  parentJobFamily: Array<FuncArea>;
  skillsValue: Observable<Array<Skill>>;
  childJobFamily = {};
  jobFamilyList = {};
  jobFamilyTypeList = {};

  parentJobFamilySubs = new Subscription();
  childJobFamilySubs = new Subscription();
  private ngUnsubscribe: Subject<boolean> = new Subject();

  get currentFAID() {
    return this.parentJobFamily[this.parentJobFamily.length - 1]
      ? this.parentJobFamily[this.parentJobFamily.length - 1]['id']
      : 0;
  }

  // @Input()
  get skillList() {
    return this.skillsValue;
  }

  @Output()
  skillsChange = new EventEmitter();

  set skillList(val) {
    this.skillsValue = val;
    this.skillsChange.emit(this.skillsValue);
  }

  private jfStream = new Subject<Object>();

  selectedJobFamilyIdx: number = -1;
  selectedSkill: Skill;
  currentJobFamilyId: number;

  exceptionData = {
    FUNC_AREA: {
      level: AlertLevels.ERROR,
      code: 'JFF-001',
      message: 'Error retrieving functional area',
      translationKey: 'errRetrievingFuncArea',
    } as AlertData,
    CHILD_FUNC_AREAS: {
      level: AlertLevels.ERROR,
      code: 'JFF-002',
      message: 'Error retrieving child functional areas',
      translationKey: 'errRetrievingChildFuncArea',
    } as AlertData,
  };

  constructor() {
    this.testFormArray = new FormArray([]);
    this.testFormGroup = this.fb.group({
      testControls: this.testFormArray,
    });
  }

  ngOnInit() {
    this.parentJobFamily = [];
    this.parentJobFamilySubs = this.skillService.getFuncArea(0).subscribe(
      (jobFamilyArray: Array<FuncArea>) => {
        this.parentJobFamily.push(jobFamilyArray[0]);
        this.childJobFamily[0] = [];
        this.jobFamilyTypeList[0] = '';
        this.testFormArray.push(new FormControl());
        this.testFormArray.controls[0].setValue(0);
        this.filterJobFamily({
          parentId: this.parentJobFamily[0].id,
          discId: 0,
        });
      },
      (err) => {
        this.alertService.createAlert2(this.exceptionData.FUNC_AREA, err);
      },
      () => {
        this.childJobFamilySubs = this.skillService
          .getChildFuncAreas(this.parentJobFamily[0].id)
          .subscribe({
            next: (cjf: Array<FuncArea>) => {
              this.childJobFamily[0] = cjf;
              this.jobFamilyTypeList[0] = cjf[0].funcAreaTypeName;
            },
            error: (err) => {
              this.alertService.createAlert2(
                this.exceptionData.CHILD_FUNC_AREAS,
                err
              );
            },
          });
      }
    );
    this.skillList = this.jfStream.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((jf: Object) =>
        this.skillService.getSkills(jf['parentId'], jf['discId'])
      ),
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
    // this.skillList = this.jfStream
    //   .debounceTime(300)
    //   .distinctUntilChanged()
    //   .switchMap(( jf: Object) => this.skillService.getSkills(jf['parentId'], jf['discId']));
  }

  ngOnDestroy() {
    this.parentJobFamilySubs.unsubscribe();
    this.childJobFamilySubs.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnSelectChange(filterIdx: number) {
    console.log('OnSelectChange filterIdx=' + filterIdx);
    while (this.testFormArray.length > filterIdx + 1) {
      this.testFormArray.removeAt(filterIdx + 1);
      this.selectedJobFamilyIdx = -1;
    }
    this.parentJobFamily.length = filterIdx + 1;
    const selectedJobFamilyId = this.testFormArray.controls[filterIdx].value;
    this.currentJobFamilyId = selectedJobFamilyId;
    console.log(selectedJobFamilyId);

    // this.parentJobFamilySubs.unsubscribe(); // TODO: Unsubs
    // this.parentJobFamilySubs = this.skillService.getFuncArea(selectedJobFamilyId)
    this.skillService
      .getFuncArea(selectedJobFamilyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (pjf: Array<FuncArea>) => {
          // if (pjf[0].funcAreaTypeName !== 'Discipline') {
          if (
            !['Discipline', 'Curriculum Section'].includes(
              pjf[0].funcAreaTypeName
            )
          ) {
            this.parentJobFamily.push(pjf[0]);
            if (!this.jobFamilyTypeList[filterIdx + 1]) {
              this.jobFamilyTypeList[filterIdx + 1] = '';
            }
            this.jobFamilyList[pjf[0].id] = pjf[0];
          }
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.FUNC_AREA, err);
        },
      });

    // this.childJobFamilySubs.unsubscribe(); // TODO: Unsubs
    // this.childJobFamilySubs = this.skillService.getChildFuncAreas(selectedJobFamilyId)
    this.skillService
      .getChildFuncAreas(selectedJobFamilyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (cjf: Array<FuncArea>) => {
          if (cjf.length > 0) {
            this.childJobFamily[filterIdx + 1] = cjf;
            this.filterJobFamily({
              parentId: cjf[0].parentFuncAreaId,
              discId: 0,
            });
            this.testFormArray.push(new FormControl());
            this.testFormArray.controls[filterIdx + 1].setValue(0);
            this.childJobFamily[filterIdx + 1] = cjf;
            this.jobFamilyTypeList[filterIdx + 1] = cjf[0].funcAreaTypeName;
            cjf.forEach((element) => {
              this.jobFamilyList[element.id] = element;
            });
          } else {
            this.filterJobFamily({
              parentId: this.parentJobFamily[filterIdx].id,
              discId: selectedJobFamilyId,
            });
          }
        },
        error: (err) => {
          this.alertService.createAlert2(
            this.exceptionData.CHILD_FUNC_AREAS,
            err
          );
        },
      });
  }

  get testFilters(): FormArray {
    return this.testFormGroup.get('testFormArray') as FormArray;
  }

  filterJobFamily(jf: Object) {
    console.log(`Calling jfStream with data ${jf['parentId']} ${jf['discId']}`);
    this.jfStream.next(jf);
  }

  onJobFamilyClick(idx: number, skill: Skill) {
    if (this.selectedJobFamilyIdx === idx) {
      this.selectedJobFamilyIdx = -1;
      this.selectedSkill = null;
      this.claimService.newClaim.skillId = null;
      delete this.claimService.createClaimData['skillId'];
      // this.currentJobFamilyId = null;
    } else {
      this.selectedJobFamilyIdx = idx;
      this.selectedSkill = skill;
      // this.claimService.createClaimData['skillId'] = skill.id;
      this.claimService.newClaim.skillId = skill.id;
      // this.claimService.claimFormData.set('skillId', skill.id.toString());
      this.claimService.createClaimData['skillId'] = skill.id.toString();
      this.currentJobFamilyId = skill.funcAreaId;
      console.log(this.selectedSkill);
    }
  }

  onRemoveFilter(filterIdx: number) {
    console.log('onRemoveFilter filterIdx=' + filterIdx);
    if (filterIdx > 0) {
      while (this.testFormArray.length > filterIdx) {
        this.testFormArray.removeAt(filterIdx);
      }
      this.testFormArray.controls[filterIdx - 1].setValue(0);
    } else {
      this.testFormArray.controls[0].setValue(0);
    }
  }
}
