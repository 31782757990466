<div class="card overflow-visible mb-5">
  <div class="card-body">
    <h2 class="h5 fw-bold">
      Custom {{ skillType | titlecase }} Amber Warnings
    </h2>
    <p>
      Assign {{ skillType }} items with a default warning setting to determine
      when the amber warning should be displayed in the matrix.
    </p>
    <button
      *ngIf="hasAdminAccess"
      type="button"
      class="btn esqepBtn"
      (click)="openSkillModal()"
    >
      Add {{ skillType | titlecase }}
    </button>

    <div>
      <ug-table
        [tableHeaders]="tableHeaders"
        [showPagination]="tableRows.length > 10"
        [tableRows]="tableRows"
        [showSelectBox]="false"
        [isLoading]="isLoading"
        [tableRowButtons]="tableRowButtons"
      ></ug-table>
      <!-- [tableSelectedButtons]="tableSelectedButtons" -->
    </div>
  </div>
</div>
