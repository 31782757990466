<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body p-0">
    <div class="row m-0 flex-nowrap">
      <div class="col-auto d-flex justify-content-between border-end px-2">
        <div id="subjectFilters" class="collapse collapse-horizontal show">
          <div style="width: 325px">
            <form *ngIf="dimensionId">
              <ng-container *ngIf="dimensionIdsToInclude.length > 1">
                <div [formGroup]="dimensionsForm" class="my-2">
                  <div>Select a Dimension:</div>
                  <div
                    *ngFor="
                      let dim of skillDimensions;
                      let i = index;
                      trackBy: trackByFn
                    "
                    class="form-check form-check-inline"
                  >
                    <input
                      type="radio"
                      class="form-check-input"
                      id="{{ 'dimensionOption' + i }}"
                      [value]="dim.id"
                      formControlName="dimensionId"
                    />
                    <label
                      class="form-check-label"
                      for="{{ 'dimensionOption' + i }}"
                      >{{ dim.name | titlecase }}</label
                    >
                  </div>
                </div>
              </ng-container>

              <div [formGroup]="subjectsFilterForm">
                <div formArrayName="subjectLevels">
                  <div
                    *ngFor="let item of subjectsArray.controls; let i = index"
                    [formGroupName]="i"
                    class="my-2 pe-2"
                  >
                    <div>
                      <label [attr.for]="item.value.name">{{
                        item.value.name
                      }}</label>
                      <ng-select
                        formControlName="subjectId"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        [searchable]="true"
                        [items]="subjectMap.get(item.value.subjectMapKey)"
                        bindLabel="name"
                        bindValue="id"
                        [placeholder]="'Select ' + item.value.name"
                        [labelForId]="item.value.name"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="align-self-center">
          <button
            class="border-0 bg-transparent"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#subjectFilters"
            aria-expanded="false"
            aria-controls="subjectFilters"
            (click)="collapseFilters = !collapseFilters"
          >
            <i
              [ngClass]="
                collapseFilters
                  ? 'fas fa-chevron-right '
                  : 'fas fa-chevron-left'
              "
            ></i>
            <i
              [ngClass]="
                collapseFilters
                  ? 'fas fa-chevron-right '
                  : 'fas fa-chevron-left'
              "
            ></i>
          </button>
        </div>
      </div>
      <div class="col-10 flex-fill overflow-auto mt-2" style="height: 85vh">
        <ug-table
          [tableHeaders]="tableHeaders"
          [tableRows]="tableRows"
          [tableFilters]="tableFilters"
          [tableSelectedButtons]="tableSelectedButtons"
          [showSelectBox]="true"
          [showPagination]="true"
          [isLoading]="tableLoading"
          [tablePageSize]="
            pagedApi ? tablePageSize : paginatedTableFilterData.pageSize
          "
          [tablePageIndex]="
            pagedApi ? tablePageIndex : paginatedTableFilterData.page
          "
          [totalRecords]="skillCount"
          [existsKey]="existsKey"
          (pageIndexChange)="pagedApi ? updateData($event) : null"
          (pageSizeChange)="pagedApi ? updateData(1, $event) : null"
          (sortChange)="pagedApi ? updateData(1, null, $event) : null"
          [pagedApi]="pagedApi"
        >
        </ug-table>
      </div>
    </div>
  </div>
</div>
