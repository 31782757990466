import { Component, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { UitoolsService } from '../../core/services/uitools.service';
// import 'extensions/string.extensions';
import {
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import { ToFirstCharUpperCasePipe } from '../../pipes/to-first-char-upper-case.pipe';
import {
  DynamicGroupsService,
  FilterPayload,
  JoinOperator,
} from '../services/dynamic-groups.service';
@Component({
  selector: 'ug-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss'],
})
export class GroupManagementComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private alertService = inject(AlertService);
  private typeaheadService = inject(TypeaheadService);
  private uiService = inject(UitoolsService);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);

  exceptionData = {
    PEOPLE_LIST: {
      level: AlertLevels.ERROR,
      code: 'GM-001',
      message: 'Error retrieving filtered people list',
    } as AlertData,
    GROUP_FILTER_CREATE: {
      level: AlertLevels.ERROR,
      code: 'GM-002',
      message: 'Error creating group filter',
    } as AlertData,
    GROUP_FILTER_UPDATE: {
      level: AlertLevels.ERROR,
      code: 'GM-003',
      message: 'Error updating group filter',
    } as AlertData,
  };

  @ViewChild('nav') nav;

  activeTab = 1;
  groupFiltersForm: FormGroup;
  isLoading: boolean;
  peopleCount = 0;
  groupFilterId: number;
  filterString: string;
  uiFilterString: string;
  discardChangesSubj: Subject<boolean> = new Subject();
  filtersChanged: Subject<boolean> = new Subject<boolean>();
  filterArray = [];
  uiFilterArray = [];
  peopleList = [];
  tableLoading: boolean;
  filterSummary = [];
  ngUnsubscribe: Subject<boolean> = new Subject();
  savingSpinner = false;

  allowEditGroup = false;
  tableFilters: Array<FilterBase<any>>;
  searchFilterData: {
    filter: string;
    page: number;
    pageSize: number;
    order?: string;
  };

  tableFilterData: {
    filter: string;
    page: number;
    pageSize: number;
    order: string;
  } = {
    filter: '',
    page: 1,
    pageSize: 10,
    order: 'DisplayName asc',
  };
  tableRows: Array<any>;
  tablePageSize: any;
  tablePageIndex: any;
  static compare(items, input: string) {
    return items.displayName.toLowerCase().includes(input);
  }

  static sort(a, b, text: string) {
    return (
      a.displayName.startsWith(text) - b.displayName.startsWith(text) ||
      b.displayName - a.displayName
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.groupFiltersForm.dirty) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.isLoading = true;
    this.tableFilters = [
      new TextboxFilter({
        key: 'displayName',
        label: 'Filter by name',
        callback: this.peopleSearch,
        inLineGroup: false,
        order: 1,
      }),
    ];
    this.authService.loggedInUserSubj
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((liu: LoggedInUser) => {
        this.allowEditGroup = [
          SecurityRoleKey.Admin,
          SecurityRoleKey.Superuser,
          SecurityRoleKey.JobRolePeople,
          SecurityRoleKey.JobRoleReqsPeople,
        ].some((sr) => liu.roleIdentifier === sr);
        this.isLoading = false;
      });

    this.groupFiltersForm = this.fb.group({
      externalActive: [],
      groupName: ['', Validators.required],
      groupDescription: ['', Validators.maxLength(800)],
      groups: this.fb.array([]),
    });

    this.groups.valueChanges
      .pipe(
        tap(() => {
          this.filtersChanged.next(true);
          this.resetTable();
        }),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe((x) => {
        if (!this.groups.invalid) {
          this.getPeople();
        }
      });

    this.activatedRoute.params.subscribe((params) => {
      this.groupFilterId = Number(params.id);
    });
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  setTab() {
    this.nav.select(this.activeTab);
  }

  selectNextTab() {
    this.nav.select(this.activeTab++);
  }

  selectPreviousTab() {
    this.nav.select(this.activeTab--);
  }

  onSaveClick() {
    this.savingSpinner = true;
    if (!this.groupFiltersForm.invalid) {
      this.createFilterArray();
      const filterData: FilterPayload = {
        entity: 'Person',
        filterJson: this.filterString,
        uiFilterInfo: this.uiFilterString,
        name: this.groupFiltersForm.get('groupName').value,
        description: this.groupFiltersForm.get('groupDescription').value,
        errorMessage: null,
      };

      if (this.groupFilterId !== 0) {
        this.dynamicGroupsService
          .updateFilterById(this.groupFilterId, filterData)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.savingSpinner = false)),
          )
          .subscribe({
            next: (gf) => {
              this.uiService.showToast(
                `Successfully updated group with Id: ${gf.id}`,
                { classname: 'bg-success text-light', delay: 3000 },
              );
              this.groupFiltersForm.markAsPristine();
            },
            error: (err) =>
              this.alertService.createAlert2(
                this.exceptionData.GROUP_FILTER_UPDATE,
                err,
              ),
          });
      } else if (this.groupFilterId === 0) {
        this.dynamicGroupsService
          .addFilter(filterData)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.savingSpinner = false)),
          )
          .subscribe({
            next: (gf) => {
              this.groupFilterId = gf.id;
              this.uiService.showToast(
                `Successfully added group with Id: ${gf.id}`,
                { classname: 'bg-success text-light', delay: 3000 },
              );
              this.groupFiltersForm.markAsPristine();
            },
            error: (err) => {
              if (err.message === 'Duplicated Name') {
                this.uiService.showToast(
                  `Group with name '${
                    this.groupFiltersForm.get('groupName').value
                  }' already exists`,
                  { classname: 'bg-danger text-light', delay: 5000 },
                );
              } else {
                this.alertService.createAlert2(
                  this.exceptionData.GROUP_FILTER_CREATE,
                  err,
                );
              }
            },
          });
      }
    }
  }

  discardChanges() {
    this.discardChangesSubj.next(true);
    this.groupFiltersForm.markAsPristine();
  }

  get groups(): FormArray {
    return this.groupFiltersForm.get('groups') as FormArray;
  }

  getPeople() {
    if (!this.groups.invalid) {
      this.tableLoading = true;
      this.createFilterArray();
      this.filterSummary = this.createFilterSummary(this.uiFilterArray);

      this.tableFilterData.filter = this.filterString;

      this.dynamicGroupsService
        .getFilteredEntityValuesPaged('Person', this.tableFilterData)
        .pipe(takeUntil(this.filtersChanged))
        .subscribe({
          next: (people) => {
            this.peopleList = people['data'];
            this.peopleCount = people['totalRowCount'];
            this.tableRows = people['data'];
            this.tablePageSize = people['pageSize'];
            this.tablePageIndex = people['page'];
            this.tableLoading = false;
          },
          error: (err) =>
            this.alertService.createAlert2(this.exceptionData.PEOPLE_LIST, err),
        });
    } else {
      this.tableLoading = false;
    }
  }
  peopleSearch = (searchTerm) =>
    searchTerm
      .pipe(
        distinctUntilChanged(),
        filter((t) => !!t),
        filter((t1: string) => t1.length > 2),
        switchMap((term) => {
          this.searchFilterData = Object.assign({}, this.tableFilterData);
          this.searchFilterData.page = 1;
          this.searchFilterData.filter = `[[${this.tableFilterData.filter}],\"and\",[\"DisplayName\",\"contains\",\"${term}\"]]`;

          return this.dynamicGroupsService
            .getFilteredEntityValuesPaged('Person', this.searchFilterData)
            .pipe(
              map((response) => {
                return {
                  ...response,
                  searchTerm: term,
                };
              }),
              catchError(() => {
                return of([]);
              }),
            );
        }),
      )
      .subscribe((response, val) => {
        this.peopleList = response['data'];
        this.peopleCount = response['totalRowCount'];
        this.tableRows = response['data'];

        this.tableFilters[0].defaultValue = response['searchTerm'];
        this.tableLoading = false;
      });
  updateData(
    pageNumber?: number,
    pageSize?: number,
    sort?: { column: string; sortDirection: string },
  ) {
    // this.tableLoading = true;
    const dataToPaginate = this.searchFilterData
      ? this.searchFilterData
      : this.tableFilterData;
    const toFirstCharUpperCase = new ToFirstCharUpperCasePipe();
    if (pageNumber) {
      dataToPaginate.page = pageNumber;
    }
    if (pageSize) {
      dataToPaginate.pageSize = pageSize;
    }
    if (sort) {
      const col = toFirstCharUpperCase.transform(sort.column);
      dataToPaginate.order = `${col} ${sort.sortDirection}`;
    }

    this.dynamicGroupsService
      .getFilteredEntityValuesPaged('Person', dataToPaginate)
      .pipe(takeUntil(this.filtersChanged))
      .subscribe({
        next: (people) => {
          this.peopleList = people['data'];
          this.peopleCount = people['totalRowCount'];

          this.tableRows = people['data'];
          this.tablePageSize = people['pageSize'];
          this.tablePageIndex = people['page'];

          this.tableLoading = false;
        },
        error: (err) =>
          this.alertService.createAlert2(this.exceptionData.PEOPLE_LIST, err),
      });
  }

  createFilterArray() {
    this.filterArray = [];
    this.uiFilterArray = [];
    this.groups.value.forEach((group, i) => {
      let groupArray = [];
      let uiGroupArray = [];
      group.conditions.forEach((condition, j) => {
        let valueKey;
        let uiValue;

        if (condition.attribute.dataType === 'DateTime') {
          valueKey = condition.value;
          uiValue = condition.value;
        } else if ('value' in condition) {
          valueKey = Array.isArray(condition.value)
            ? condition.value.map((v) => v.key)
            : condition.value.key;
          uiValue = Array.isArray(condition.value)
            ? condition.value.map((v) => v.name)
            : condition.value.name;
        } else {
          const groupConditions = this.groups
            .at(i)
            .get('conditions') as FormArray;
          if (groupConditions.at(j).get('value').disabled) {
            valueKey = null;
            uiValue = null;
          }
        }
        const conditionArray = [
          condition.attribute.entityPath,
          condition.conditionalOperator.operator,
          valueKey,
        ];
        const uiConditionArray = [
          condition.attribute.name,
          condition.conditionalOperator.name,
          uiValue,
          condition.attribute.lookupParentKey,
        ];

        if (group.conditions.length > 1) {
          groupArray.push(conditionArray);
          uiGroupArray.push(uiConditionArray);
        } else {
          groupArray = conditionArray;
          uiGroupArray = uiConditionArray;
        }

        if (condition.join) {
          groupArray.push(condition.join);
          uiGroupArray.push(condition.join);
        }
      });

      if (this.groups.value.length > 1) {
        this.filterArray.push(groupArray);
        this.uiFilterArray.push(uiGroupArray);
      } else {
        this.filterArray = groupArray;
        this.uiFilterArray = uiGroupArray;
      }

      if (group.groupJoin) {
        this.filterArray.push(group.groupJoin);
        this.uiFilterArray.push(group.groupJoin);
      }
    });

    const groupString = JSON.stringify(this.filterArray);
    const uiGroupString = JSON.stringify(this.uiFilterArray);

    const filterActiveString = this.externalActiveValue.value
      ? `[\"ExternalActive\",\"=\",\"${this.externalActiveValue.value}\"]`
      : `[\"ExternalActive\",\"!null\",null]`;
    const filterActiveUiString = this.externalActiveValue.value
      ? `[\"Person Active Status\",\"Equals\",\"${this.externalActiveValue.key}\"]`
      : `[\"Person Active\",\"Is not blank\",null]`;

    // appends active filter as group
    this.filterString = `[[${groupString}],\"and\",${filterActiveString}]`;
    this.uiFilterString = `[[${uiGroupString}],\"and\",${filterActiveUiString}]`;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  createFilterSummary(filterArray) {
    const filterSummary = [];
    const groupsSummary =
      this.dynamicGroupsService.createGroupsSummary(filterArray);
    groupsSummary.forEach((f) => {
      Array.isArray(f)
        ? filterSummary.push(this.createJoinedFilterSummary(f))
        : filterSummary.push(f);
    });
    return filterSummary;
  }

  createJoinedFilterSummary(filter) {
    const resultArray = [];
    let currentConditionGroup = [];
    let conditionString = '';

    for (let i = 0; i < filter.length; i++) {
      if (filter[i + 1] === JoinOperator.Or) {
        currentConditionGroup.push(filter[i]);
        conditionString = currentConditionGroup.join(' ');
        resultArray.push(conditionString);
        currentConditionGroup = [];
        conditionString = '';
      } else if (filter[i] === JoinOperator.Or) {
        resultArray.push(filter[i].toUpperCase());
      } else if (filter[i + 1] === JoinOperator.And) {
        currentConditionGroup.push(filter[i]);
      } else if (filter[i] === JoinOperator.And) {
        currentConditionGroup.push(filter[i].toUpperCase());
      } else {
        currentConditionGroup.push(filter[i]);
        conditionString = currentConditionGroup.join(' ');
        resultArray.push(conditionString);
      }
    }

    return resultArray;
  }

  clearFilters($event: boolean) {
    if ($event) {
      // this is because we're setting it manually above
      this.tableFilters[0].defaultValue = '';
      this.searchFilterData = null;
      this.updateData();
    }
  }

  resetTable() {
    this.tableFilters[0].defaultValue = '';
    this.searchFilterData = null;
    this.tableFilterData.page = 1;
    this.tableFilterData.pageSize = 10;
  }

  get externalActiveValue() {
    return this.groupFiltersForm.get('externalActive').value;
  }
}
