<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a
          *ngFor="let tab of perfGridArray; let i = index"
          class="nav-link active"
          data-toggle="tab"
          [routerLink]="'#tab-' + i"
          >{{ tab.name }}
          <span [ngClass]="overallBG(tab.moderatedRatingScoreIdx)">{{
            tab.cModeratedRating
          }}</span></a
        >
      </li>
      <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li> -->
    </ul>
  </div>
  <div class="card-body">
    <!-- <h5 class="card-title">Sales Performance Summary - {{perfGridContent.userFirstName}} {{perfGridContent.userLastName}}</h5> -->
    <h5 class="card-title">Performance Summary</h5>
  </div>
</div>
