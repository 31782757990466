import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OperatorFunction, Subject, takeUntil } from 'rxjs';
import { TypeaheadService } from '../../controls/dropdown-select/typeahead.service';
import { Person } from '../../person/models/person';
import { PersonService } from '../../person/services/person.service';
import { ProjectsService } from '../services/projects.service';

@Component({
  selector: 'ug-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  private personService = inject(PersonService);
  private typeaheadService = inject(TypeaheadService);
  private projectsService = inject(ProjectsService);

  @Input() parentFormGroup: FormGroup;

  visibilityStatus = 'public';
  selectedPeople = [];
  owners = [];
  peopleList = [];
  private ngUnsubscribe: Subject<boolean> = new Subject();

  personFormatter = (result) => result['displayName'];
  peopleSearch: OperatorFunction<string, Person[]>;

  initialPhases = [
    {
      title: 'Discover',
      colour: '#8F4299',
    },
    {
      title: 'Design',
      colour: '#24B7A6',
    },
    {
      title: 'Develop',
      colour: '#EF402B',
    },
    {
      title: 'Deploy',
      colour: '#F9981C',
    },
  ];

  ngOnInit(): void {
    this.parentFormGroup
      .get('projectSettings.visibility.status')
      .valueChanges.subscribe((x) => (this.visibilityStatus = x));
    this.personService
      .getPersonList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pl) => {
        this.peopleList = pl;
        this.peopleSearch = this.typeaheadService.typeahead(
          this.peopleList,
          ProjectSettingsComponent.comparePeople,
          ProjectSettingsComponent.sortPeople,
        );
      });

    this.initialPhases.forEach((phase) => {
      this.phases.push(
        this.fb.group({
          title: [phase.title, Validators.required],
          colour: phase.colour,
        }),
      );
    });
  }

  removeOwner(person) {
    const index = this.owners.findIndex(
      (x) => x.displayName === person.displayName,
    );
    this.owners.splice(index, 1);
  }

  get phases(): FormArray {
    return this.parentFormGroup.get(
      'projectSettings.phasesForm.phases',
    ) as FormArray;
  }

  newPhase(): FormGroup {
    return this.fb.group({
      title: ['', Validators.required],
      colour: '#D3D3D3',
    });
  }

  getPhaseColour(i) {
    return this.parentFormGroup.get('projectSettings.phasesForm.phases').value[
      i
    ].colour;
  }

  removePhase(i: number) {
    this.phases.removeAt(i);
  }

  addPhase() {
    this.phases.push(this.newPhase());
    this.projectsService.phases.next(this.phases.controls);
  }

  static comparePeople(items, input: string) {
    return items.displayName.toLowerCase().includes(input);
  }

  static sortPeople(a, b, text: string) {
    return (
      a.displayName.startsWith(text) - b.displayName.startsWith(text) ||
      b.displayName - a.displayName
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
