import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { PersonService } from '../../person/services/person.service';
import { JobRoleAnalysis } from '../job-role-analysis/job-role-analysis.component';
import {
  JobRoleOrPersonOptions,
  JobRoleService,
} from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-profile',
  templateUrl: './job-role-profile.component.html',
  styleUrls: ['./job-role-profile.component.scss'],
})
export class JobRoleProfileComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private personService = inject(PersonService);
  private jobRoleService = inject(JobRoleService);

  private subscriptions = new Subscription();

  @Input() personId: number;
  @Input() cardTitle = 'My Job Role Profile';

  exceptionData = {
    PERSON_JOB_ROLES: {
      level: AlertLevels.ERROR,
      code: 'JRP-001',
      message: 'Error retrieving person job roles',
    } as AlertData,
    ROLE_VS_PERSON: {
      level: AlertLevels.ERROR,
      code: 'JRP-002',
      message: 'Error retrieving job role vs person data',
    } as AlertData,
  };

  jobRoleOrPersonOptions = JobRoleOrPersonOptions;

  jobRoleForm: FormGroup;
  selectedJobRoleIndex = new FormControl(null);

  isLoading = true;
  isPlotLoading = true;
  hasPlottingData = false;

  roleProfiles: Array<any> = [];
  jobRoles: Array<any>;
  roleProfile: JobRoleAnalysis[] = [];
  selectedRole: any;

  ngOnChanges(changes: SimpleChanges): void {
    // If personId not currently set then exit
    if (!this.personId) {
      return;
    }

    // Get job roles for the selected person

    this.jobRoleService
      .getJobRolesByPersonId(this.personId)
      .subscribe((result) => {
        this.jobRoles = result;
        if (this.jobRoles.length > 0) {
          this.selectedRole = result[0].key;
          this.loadProfile();
        } else {
          this.isPlotLoading = false;
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  get hasJobRole() {
    // return this.roleProfiles.length > 0;
    return this.jobRoles.length > 0;
  }

  loadProfile(): void {
    this.isPlotLoading = true;
    this.jobRoleService
      .getJobRoleAnalysisByJobRoleIdAndPersonId(
        Number(this.selectedRole),
        this.personId,
      )
      .subscribe({
        next: (jrp) => {
          const personJobRoles = jrp;
          personJobRoles.forEach((pjr) => {
            pjr.isAdditional = true;
            pjr['people'].forEach((p) => {
              p.skills.forEach((s) => {
                const skillWeightedInfo = this.getWeightedScore(s);
                s.personSkillWeightedScore =
                  skillWeightedInfo.personWeightedScore;
                s.personSkillWeightedScorePercentage =
                  skillWeightedInfo.personWeightedScorePercentage;
                s.roleSkillWeightedScore = skillWeightedInfo.roleWeightedScore;
                s.roleSkillWeightedScorePercentage =
                  skillWeightedInfo.roleWeightedScorePercentage;
                s.maxLevelWeighted = skillWeightedInfo.maxLevelWeighted;
              });
            });
          });

          this.roleProfile = personJobRoles;
          this.isPlotLoading = false;
          this.hasPlottingData =
            this.roleProfile.length > 0 &&
            this.roleProfile[0].people[0].jobRoleLevelScore !== 0 &&
            this.roleProfile[0].people[0].jobRoleLevelScore !== null;
        },
        error: (err) => {
          this.alertService.createAlert2(
            {
              level: AlertLevels.ERROR,
              code: 'JRP-003',
              message: 'Unable to retrieve job role vs person data',
            },
            err,
          );
          this.isPlotLoading = false;
        },
      });
  }

  private getWeightedScore(skill: any) {
    const maxLevelWeighted =
      skill.claimLevelScore > skill.requirementLevelScore
        ? skill.claimLevelScore * skill.requirementWeighting
        : skill.requirementLevelScore * skill.requirementWeighting;

    // person level
    const personWeightedScore =
      skill.claimLevelScore * skill.requirementWeighting;
    const personWeightedScorePercentage =
      (personWeightedScore / maxLevelWeighted) * 100;
    const roleWeightedScore =
      skill.requirementLevelScore * skill.requirementWeighting;
    const roleWeightedScorePercentage =
      (roleWeightedScore / maxLevelWeighted) * 100;

    return {
      personWeightedScore,
      personWeightedScorePercentage,
      roleWeightedScore,
      roleWeightedScorePercentage,
      maxLevelWeighted,
    };
  }
}
