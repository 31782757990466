<div [ngClass]="{ 'inline-group': inlineGroup }">
  <label #labelElement [for]="id" [class]="{ 'form-label': !inlineGroup }">
    <ng-content></ng-content>
  </label>

  <!--suppress HtmlFormInputWithoutLabel -->
  <input
    #instance="ngbTypeahead"
    (blur)="onBlur($event)"
    (click)="onClick($event)"
    (focus)="onFocus($event)"
    (selectItem)="onSelectItem($event)"
    [autocomplete]="autocomplete"
    [class.is-hinted]="hint"
    [class.is-invalid]="errorCode"
    [container]="container"
    [focusFirst]="focusFirst"
    [formControl]="formControl"
    [id]="id"
    [inputFormatter]="inputFormatter"
    [ngbTypeahead]="ngbTypeahead"
    [placeholder]="placeholder || labelElement.innerText"
    [placement]="placement"
    [required]="required"
    [resultFormatter]="resultFormatter"
    [resultTemplate]="resultTemplate"
    [showHint]="showHint"
    [type]="type"
    class="form-control"
  />
</div>

<ng-container *ngIf="!errorCode && hint">
  {{ hint }}
</ng-container>

<ng-container *ngIf="errorCode">
  <span class="text-danger">{{ errorCode | titlecase }}</span>
</ng-container>
