import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClaimEvidence } from '../../claim/models/claim-evidence';

@Component({
  selector: 'esqep-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss'],
})
export class EvidenceComponent {
  @Input() parentFormGroup: FormGroup;
  @Input() editMode = false;
  @Input() evidenceStatementText = '';
  @Input() evidenceList: Array<ClaimEvidence> = [];
  @Input() claimId: number;
  @Input() claimantView = true;

  evidenceFetched = false;

  get evidenceFG(): FormGroup {
    return (this.parentFormGroup.get('evidence') ||
      new FormGroup({})) as FormGroup;
  }
}
