import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  TrainingOutcome,
  TrainingOutcomePayload,
} from '../../../events/services/events.service';

@Component({
  selector: 'ug-assessment-options-modal',
  templateUrl: './assessment-options-modal.component.html',
  styleUrls: ['./assessment-options-modal.component.scss'],
})
export class AssessmentOptionsModalComponent implements OnInit {
  modal = inject(NgbActiveModal);
  private formBuilder = inject(FormBuilder);

  @Input() trainingOutcome: TrainingOutcome;
  @Output() saveTrainingOutcome: EventEmitter<TrainingOutcomePayload> =
    new EventEmitter<TrainingOutcomePayload>();

  trainingOutcomeForm: FormGroup;
  dropdownOptions: Array<any> = [
    {
      name: 'Passed assessment',
      isSuccess: true,
    },
    {
      name: 'Failed assessment',
      isSuccess: false,
    },
  ];

  ngOnInit(): void {
    this.trainingOutcomeForm = this.formBuilder.group({
      name: [
        this.trainingOutcome ? this.trainingOutcome.name : null,
        Validators.required,
      ],
      description: [
        this.trainingOutcome ? this.trainingOutcome.description : null,
        Validators.maxLength(500),
      ],
      isSuccess: [
        this.trainingOutcome ? this.trainingOutcome.isSuccess : null,
        Validators.required,
      ],
      isActive: [this.trainingOutcome ? this.trainingOutcome.isActive : true],
    });

    if (this.trainingOutcome?.id) {
      this.trainingOutcomeForm.get('isSuccess').disable();
    }
  }

  addOrUpdateTrainingOutcome() {
    this.saveTrainingOutcome.next(this.trainingOutcomeForm.getRawValue());
    this.modal.close();
  }

  get outcomeDescriptionLength() {
    return this.trainingOutcomeForm.get('description').value?.length || 0;
  }

  cancel(): void {
    this.modal.close();
  }
}
