import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  TableHeader,
  TableRowButton,
} from '../../../../../controls/table/table.service';
import { BasicJobRolePerson } from '../../../../../person/models/person';

export enum PersonActionType {
  RESTORE = 'Restore',
  REMOVE = 'Remove',
  DISCARD = 'Discard',
}

@Component({
  selector: 'ug-role-people',
  templateUrl: './role-people.component.html',
  styleUrls: ['./role-people.component.scss'],
})
export class RolePeopleComponent implements OnInit, OnChanges {
  @Input() filterIdControl: FormControl;
  @Input() discardChangesSubj: Subject<boolean>;
  @Input() jobRoleId: number;
  @Input() allowEdit: boolean;
  @Input() jobRolePeople: Array<BasicJobRolePerson>;
  @Input() selectedPeople: Array<any>;
  @Input() filterPeople: Array<BasicJobRolePerson>;
  @Input() jobRoleFilter;
  @Input() peopleCount: number;
  @Input() tablePageSize: any;
  @Input() tablePageIndex: any;
  @Input() tableLoading: boolean;
  @Output() discardPerson: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeRestorePerson: EventEmitter<{ id: number; remove: boolean }> =
    new EventEmitter<{ id: number; remove: boolean }>();
  @Output() readonly filtersCleared = new EventEmitter<boolean>();
  @Output() readonly pageIndexChange = new EventEmitter<number>();
  @Output() readonly pageSizeChange = new EventEmitter<number>();
  @Output() readonly sortChange = new EventEmitter<{
    column: string;
    sortDirection: string;
  }>();
  active = 1;

  private peopleFromApi: Array<BasicJobRolePerson> = [];
  private selectedPpl: Array<BasicJobRolePerson> = [];
  peopleFromFilter: Array<any> = [];
  currentPeopleInUse: Array<any> = [];

  peopleCountMessageMap = {
    '=0': 'Nobody',
    '=1': '1 person',
    other: '# people',
  };

  headers: Array<TableHeader> = [{ id: 'name', title: 'Name' }];

  rowButtons: Array<TableRowButton>;
  hideRemove = (person?: any) => {
    return !(
      this.allowEdit &&
      !person.pendingDeletion &&
      !this.jobRoleFilter &&
      !this.filterId &&
      !person.actionType
    );
  };
  hideDiscard = (person?: any) => {
    return !(
      this.allowEdit &&
      !person.pendingDeletion &&
      !this.jobRoleFilter &&
      !this.filterId &&
      person.actionType &&
      person.actionType === PersonActionType.DISCARD
    );
  };
  hideRestore = (person?: any) => {
    return !(
      this.allowEdit &&
      !person.pendingDeletion &&
      !this.jobRoleFilter &&
      !this.filterId &&
      person.actionType &&
      person.actionType === PersonActionType.RESTORE
    );
  };

  hidePendingDeletion = (person?: any) => {
    return !person.pendingDeletion;
  };

  ngOnInit(): void {
    this.rowButtons = [
      {
        title: 'Remove',
        class: 'btn btn-outline-danger btn-sm',
        rowAction: this.onRemovePersonClick,
        hideCondition: this.hideRemove,
      },
      {
        title: 'Discard',
        class: 'btn btn-outline-danger btn-sm',
        rowAction: this.onDiscardPersonClick,
        hideCondition: this.hideDiscard,
      },
      {
        title: 'Restore',
        class: 'btn btn-outline-secondary btn-sm',
        rowAction: this.onRestorePersonClick,
        hideCondition: this.hideRestore,
      },
      {
        title: 'Pending Removal',
        class: 'btn btn-danger btn-sm disabled',
        hideCondition: this.hidePendingDeletion,
      },
    ];
    this.discardChangesSubj.subscribe((dc) => {
      if (dc) {
        this.currentPeopleInUse = this.peopleFromApi.map((e) => {
          e['actionType'] = null;
          return e;
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterPeople']) {
      this.peopleFromFilter =
        changes['filterPeople'] && changes['filterPeople'].currentValue
          ? changes['filterPeople'].currentValue.map((e) => {
              e.actionType = null;
              return e;
            })
          : [];
      this.currentPeopleInUse = [...this.peopleFromFilter];
    } else {
      if (changes['jobRolePeople']) {
        this.peopleFromApi =
          changes['jobRolePeople'] && changes['jobRolePeople'].currentValue
            ? changes['jobRolePeople'].currentValue.map((e) => {
                e.actionType = null;
                return e;
              })
            : [];
      }

      if (changes['selectedPeople']) {
        this.selectedPpl =
          changes['selectedPeople'] && changes['selectedPeople'].currentValue
            ? changes['selectedPeople'].currentValue.map((e) => {
                e.actionType = PersonActionType.DISCARD;
                return e;
              })
            : [];
      }
      this.currentPeopleInUse = [...this.peopleFromApi];
    }
  }

  onPageChange(pageNumber: number) {
    this.pageIndexChange.emit(pageNumber);
  }

  onPageSizeChange(pageSize: number) {
    this.pageSizeChange.emit(pageSize);
  }

  onSortChange($event: { column: string; sortDirection: string }) {
    this.sortChange.emit($event);
  }

  clearFilters($event: boolean) {
    this.filtersCleared.emit(true);
  }
  onRemovePersonClick = (person) => {
    const personToRemove = this.currentPeopleInUse.find(
      (f) => f.id === person.id,
    );
    personToRemove.actionType = PersonActionType.RESTORE;
    this.currentPeopleInUse = [...this.currentPeopleInUse];
    this.removeRestorePerson.emit({ id: person.id, remove: true });
  };

  onRestorePersonClick = (person) => {
    const personToRestore = this.currentPeopleInUse.find(
      (f) => f.id === person.id,
    );
    personToRestore.actionType = null;
    this.currentPeopleInUse = [...this.currentPeopleInUse];
    this.removeRestorePerson.emit({ id: personToRestore.id, remove: false });
  };

  onDiscardPersonClick = (person) => {
    this.discardPerson.emit(person.id);
  };

  get filterId(): number {
    return this.filterIdControl.value;
  }
}
