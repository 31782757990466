<ug-list-status
  [isLoading]="isLoading"
  [listLength]="listLength"
></ug-list-status>
<div *ngIf="!isLoading" [formGroup]="groupFiltersForm">
  <div>
    <div *ngIf="!allowEditGroup">
      <p class="fst-italic fw-bold">
        You do not have permission to edit Group Filters
      </p>
    </div>
    <div class="mb-3 w-25">
      <label for="groupName" class="form-label">Group Name</label>
      <input
        type="text"
        class="form-control"
        id="groupName"
        placeholder="Group Name"
        formControlName="groupName"
      />
    </div>
    <div class="mb-3 w-100">
      <label for="description" class="form-label">Description</label>
      <textarea
        class="form-control"
        type="textarea"
        rows="2"
        id="description"
        maxlength="{{ maxNumOfChars }}"
        formControlName="groupDescription"
        placeholder="Enter a description for the group"
      ></textarea>
      <div class="max-characters">
        <div id="char_count">
          {{ groupDescriptionLength }}/{{ maxNumOfChars }}
        </div>
      </div>
    </div>
    <div class="my-2">
      <div class="d-flex justify-content-between">
        <div>
          People in Group:
          <span *ngIf="!gettingFilteredPeople && !groups.invalid">
            {{ peopleCount }}
          </span>
          <span
            *ngIf="gettingFilteredPeople && !groups.invalid"
            class="fas fa-spinner fa-spin px-2"
          >
          </span>
          <span *ngIf="groups.invalid" class="text-danger">
            Invalid filter selection
          </span>
        </div>
      </div>
      <div formArrayName="groups">
        <div
          *ngFor="let group of groups.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="card overflow-visible" formArrayName="conditions">
            <div
              *ngFor="
                let condition of group.controls.conditions.controls;
                let j = index
              "
              [formGroupName]="j"
              class="card-body"
            >
              <div class="row align-items-end">
                <div class="col-11">
                  <ug-dynamic-form
                    [form]="condition"
                    [filters]="condition.value.filters"
                    [showClearFilters]="false"
                    [rowIndex]="j"
                  ></ug-dynamic-form>
                </div>
                <div class="col-1 text-center">
                  <button
                    *ngIf="
                      (groups.value.length > 1 ||
                        group.controls.conditions.value.length > 1) &&
                      allowEditGroup
                    "
                    type="button"
                    (click)="removeCondition(i, j)"
                    class="btn btn-sm bg-transparent mx-2"
                  >
                    <i class="fas fa-times text-danger"></i>
                  </button>
                  <i
                    *ngIf="condition.invalid"
                    class="fas fa-exclamation-triangle text-warning"
                    placement="top"
                    [ngbTooltip]="
                      condition.errors?.invalidAttrOrValError ??
                      'Invalid condition'
                    "
                    tooltipClass="custom-tooltip"
                  ></i>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <button
                    class="mx-2 btn esqepBtn"
                    [ngClass]="
                      condition.controls.join.value === JoinOperator.And
                        ? 'active'
                        : ''
                    "
                    (click)="setJoinCondition(i, j, JoinOperator.And)"
                    [disabled]="condition.invalid"
                  >
                    {{ JoinOperator.And | uppercase }}
                  </button>
                  <button
                    class="mx-2 btn esqepBtn"
                    [ngClass]="
                      condition.controls.join.value === JoinOperator.Or
                        ? 'active'
                        : ''
                    "
                    (click)="setJoinCondition(i, j, JoinOperator.Or)"
                    [disabled]="condition.invalid"
                  >
                    {{ JoinOperator.Or | uppercase }}
                  </button>
                  <button
                    *ngIf="
                      j + 1 === group.controls.conditions.value.length &&
                      i + 1 === groups.value.length
                    "
                    type="button"
                    class="mx-2 btn esqepBtn"
                    (click)="onNewGroupClick(i)"
                    [disabled]="condition.invalid"
                  >
                    NEW GROUP
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="group.controls.groupJoin" class="my-3">
            <div class="text-center d-flex">
              <hr class="flex-grow-1 mx-2" />
              <button
                class="mx-2 btn esqepBtn"
                [ngClass]="
                  group.controls.groupJoin.value === JoinOperator.And
                    ? 'active'
                    : ''
                "
                (click)="setJoinGroup(i, JoinOperator.And)"
              >
                {{ JoinOperator.And | uppercase }}
              </button>
              <button
                class="mx-2 btn esqepBtn"
                [ngClass]="
                  group.controls.groupJoin.value === JoinOperator.Or
                    ? 'active'
                    : ''
                "
                (click)="setJoinGroup(i, JoinOperator.Or)"
              >
                {{ JoinOperator.Or | uppercase }}
              </button>
              <hr class="flex-grow-1 mx-2" />
              <button
                type="button"
                (click)="removeGroup(i + 1)"
                class="btn btn-sm bg-transparent mx-2 float-end"
              >
                <i class="fas fa-times text-danger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
