export class Alert {
  type: number;
  typeName: string;
  className: any;
  message: string;
  code: string;
  timestamp: number;
  secondsUntilDismissed?: number;
  id: number;
  translationKey?: string;
}
