import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CompetenciesService,
  SkillBySubjectId,
  SkillDimension,
  SkillSubject,
} from '../../../../../competencies/services/competencies.service';
import { TableHeader } from '../../../../../controls/table/table.service';
import { UitoolsService } from '../../../../../core/services/uitools.service';
import { SkillAssignmentModalComponent } from '../skill-assignment-modal/skill-assignment-modal.component';

@Component({
  selector: 'ug-subject-assigned-skills',
  templateUrl: './subject-assigned-skills.component.html',
  styleUrl: './subject-assigned-skills.component.scss',
})
export class SubjectAssignedSkillsComponent {
  private competencyService = inject(CompetenciesService);
  private ngbModal = inject(NgbModal);
  private destroyRef = inject(DestroyRef);
  private uiService = inject(UitoolsService);
  private id: number;

  @Input() skillDimensions: SkillDimension[];
  @Input() skillType: string;
  @Input() set subjectId(value: number) {
    this.id = value;
    this.updateSkills();
  }
  @Input() set dimensionId(value: number) {
    this.selectedDimensionId = value;
    this.updateSkills();
  }
  @Input() subject: SkillSubject;
  @Output() skillsUpdatedEvent = new EventEmitter<number>();

  skills: any[];
  tableHeaders: TableHeader[] = [
    {
      id: 'skillName',
      title: 'Name',
      class: 'w-100',
    },
  ];
  tableLoading: boolean;
  tableNoDataMessage: string;
  selectedDimensionId: number;
  selectedDimensionSkills: SkillBySubjectId[];
  nonSelectedDimensionSkills: SkillBySubjectId[];

  updateSkills() {
    this.tableLoading = true;

    this.skillType = this.selectedDimensionId === 4 ? 'Training' : 'Competency';
    this.competencyService
      .getSkillsBySubjectId(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (skills) => {
          this.selectedDimensionSkills = skills.filter(
            (skill) => skill.dimensionId === this.selectedDimensionId,
          );
          this.nonSelectedDimensionSkills = skills.filter(
            (skill) => skill.dimensionId !== this.selectedDimensionId,
          );
          this.tableNoDataMessage = `No ${
            this.selectedDimensionId === 4 ? 'training' : 'competencies'
          } assigned to this subject.`;
          this.tableLoading = false;
        },
      });
  }

  openSkillAssignmentModal() {
    const modalRef = this.ngbModal.open(SkillAssignmentModalComponent, {
      centered: true,
      size: 'xl',
    });
    const selectedDimension = this.skillDimensions.find(
      (d) => d.id === this.selectedDimensionId,
    );
    modalRef.componentInstance.selectedDimension = selectedDimension;
    modalRef.componentInstance.subjectId = this.subject.id;
    modalRef.componentInstance.subjectTitle = this.subject.name;

    modalRef.componentInstance.updateSelectedEvent.subscribe(
      (skillIds: number[]) => {
        const skills = [
          ...skillIds,
          ...this.nonSelectedDimensionSkills.map((s) => s.skillId),
        ];
        this.competencyService
          .updateSubjectSkillsById(this.subject.id, skills)
          .subscribe({
            next: (res) => {
              this.uiService.showToast(
                'Successfully updated assigned skill for subject ' +
                  this.subject.name,
                { classname: 'bg-success text-light', delay: 3000 },
              );
              modalRef.close();
              this.updateSkills();
              this.skillsUpdatedEvent.emit(skills.length);
            },
            error: (err) => {
              this.uiService.showToast(
                'Failed to update assigned skill for subject ' +
                  this.subject.name,
                { classname: 'bg-danger text-light', delay: 3000 },
              );
            },
          });
      },
    );
  }
}
