<div class="card">
  <div class="card-body">
    <h5 class="card-title">Audit history</h5>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [showPagination]="true"
      [isLoading]="loadingData"
      [totalRecords]="totalRecords"
      [tableFilters]="tableFilters"
    >
    </ug-table>
  </div>
</div>
