import { Component, Input, OnChanges, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DisplayType,
  InputControlType,
  PerfGridContent,
  PerfGridRating,
  PerfGridRatingCriterion,
} from '../perf-grid-content/perf-grid-content';

@Component({
  selector: 'ug-perf-grid',
  templateUrl: './perf-grid.component.html',
  styleUrls: ['./perf-grid.component.scss'],
})
export class PerfGridComponent implements OnDestroy, OnChanges, OnInit {
  private fb = inject(FormBuilder);
  private router = inject(Router);

  // @Input() gridMeta: PerfGridMeta;
  @Input() perfGridContent: PerfGridContent;
  @Input() perfGridArray: Array<PerfGridContent> = [];

  overallRating: PerfGridRating;

  perfForm = this.fb.group({});

  perfGridSubs = new Subscription();
  numberControlInput = InputControlType.Numeric;
  radioControlInput = InputControlType.Radio;
  displayType = DisplayType;

  ngOnDestroy() {
    this.perfGridSubs.unsubscribe();
  }

  ngOnInit() {}
  ngOnChanges() {
    // }
    // ngOnInit() {
    // if ( this.perfGridContent.metadata.Categories.length === 0 || this.perfGridContent.ratings.length === 0 ) {
    if (this.perfGridArray.length < 1) {
      return;
    }
    this.perfGridContent = this.perfGridArray[0];
    this.overallRating = new PerfGridRating({
      name: 'SCORE',
      // ratingValue: this.perfGridContent.totalScore,
      ratingValue: this.overallScore,
      ratingValue2: () => {
        return this.perfGridContent.totalScore;
      },
      displayType: DisplayType.Category,
      ratingCriteria: [
        new PerfGridRatingCriterion({
          // fromValue: 220,
          fromValue: this.perfGridContent.ratings.length * 40 * 0.61,
          toValue: null,
          description0: (fromValue, toValue) => {
            return 'OUTSTANDING';
          },
          description: 'OUTSTANDING',
        }),
        new PerfGridRatingCriterion({
          // fromValue: 180,
          // toValue: 220,
          fromValue: this.perfGridContent.ratings.length * 40 * 0.5,
          toValue: this.perfGridContent.ratings.length * 40 * 0.61,
          description0: (fromValue, toValue) => {
            return 'GOOD';
          },
          description: 'GOOD',
        }),
        new PerfGridRatingCriterion({
          // fromValue: 99,
          // toValue: 180,
          fromValue: this.perfGridContent.ratings.length * 40 * 0.275,
          toValue: this.perfGridContent.ratings.length * 40 * 0.5,
          description0: (fromValue, toValue) => {
            return 'REQUIRES IMPROVEMENT';
          },
          description: 'REQUIRES IMPROVEMENT',
        }),
        new PerfGridRatingCriterion({
          fromValue: null,
          toValue: this.perfGridContent.ratings.length * 40 * 0.275,
          description0: (fromValue, toValue) => {
            return 'SPECIAL MEASURES';
          },
          description: 'SPECIAL MEASURES',
        }),
      ],
    });

    // this.perfGridSubs.unsubscribe();
    this.perfForm = this.fb.group({});
    this.perfGridContent.ratings.forEach((r, i) => {
      if (r.allowInput) {
        this.perfForm.addControl(`${i}`, new FormControl(r.ratingValue));
      }
    });
    this.perfGridSubs.add(
      this.perfForm.valueChanges.subscribe((changes) => {
        console.log(changes);
        Object.keys(changes).forEach((k) => {
          console.log(`Key:${k}, Value:${changes[k]}`);
          // if (this.perfGridContent.ratings[k].displayType === this.displayType.Percentage) {
          //   this.perfGridContent.ratings[k].ratingValue = changes[k] / 100;
          // } else {
          //   this.perfGridContent.ratings[k].ratingValue = changes[k];
          // }
          this.perfGridContent.ratings[k].ratingValue = changes[k];
          console.log(this.perfGridContent.ratings[k].ratingValue);
          console.log(this.perfGridContent.totalScore);
        });
      })
    );
  }

  categoryBG(ratingIdx: number) {
    let bgClass = '';
    switch (ratingIdx) {
      case 0:
        bgClass = 'esqep-bg-outstanding';
        break;
      case 1:
        bgClass = 'esqep-bg-good';
        break;
      case 2:
        bgClass = 'esqep-bg-req-improve';
        break;
      case 3:
        bgClass = 'esqep-bg-special-measures text-white';
        break;
    }
    return bgClass;
  }

  ratingBG(ratingIdx: number, rating: PerfGridRating): string {
    let ratingClass = '';
    if (
      rating &&
      this.ratingInRange(
        rating.ratingValue,
        rating.ratingCriteria[ratingIdx].fromValue,
        rating.ratingCriteria[ratingIdx].toValue
      )
    ) {
      ratingClass = this.categoryBG(ratingIdx);
    }
    return ratingClass;
  }

  overallBG(ratingIdx: number): string {
    return this.categoryBG(ratingIdx);
  }

  ratingInRange(ratingValue: number, fromValue: number, toValue: number) {
    if (fromValue === null) {
      return ratingValue < toValue;
    }

    if (toValue === null) {
      return ratingValue >= fromValue;
    }

    // return ((ratingValue - fromValue) * (ratingValue - toValue) <= 0);
    return ratingValue < toValue && ratingValue >= fromValue;
  }

  salesProcessValue(indexValue: number) {
    return Math.abs(3 - indexValue);
  }

  hideRadio(radioIdx: number, buttonIdx: number) {
    return this.perfForm.get(`${radioIdx}`).value === 3 - buttonIdx;
  }

  percentValue(controlIdx: number): number {
    return this.perfForm.get(`${controlIdx}`).value * 100;
  }

  get moderatedRatingScoreIdxOld(): number {
    if (this.perfGridContent.hoistedRatingIdx === -1) {
      return this.overallRating.scoreIdx;
    }

    if (this.perfGridContent.hoistedRatingIdx < this.overallRating.scoreIdx) {
      return this.overallRating.scoreIdx;
    }

    return this.perfGridContent.hoistedRatingIdx;
  }

  get moderatedRatingScoreIdx(): number {
    // if (this.perfGridContent.hoistedRatingIdx === -1 || ) {
    try {
      if (
        this.perfGridContent.hoistedRatingIdx <= this.overallRating.scoreIdx
      ) {
        if (
          this.overallRating.scoreIdx === 0 &&
          this.perfGridContent.highestRatingIdx > 1
        ) {
          return this.overallRating.scoreIdx + 1;
        } else {
          return this.overallRating.scoreIdx;
        }
      }
    } catch {
      return -1;
    }

    // if (this.perfGridContent.hoistedRatingIdx < this.overallRating.scoreIdx) {
    //   return this.overallRating.scoreIdx;
    // }

    return this.perfGridContent.hoistedRatingIdx;
  }

  get moderatedRatingOld(): PerfGridRating {
    return this.overallRating;
  }

  get moderatedRating(): string {
    try {
      return this.overallRating.ratingCriteria[
        this.moderatedRatingScoreIdx
      ].description0();
    } catch {
      return '';
    }
  }

  get overallScore(): number {
    return this.perfGridContent.totalScore;
  }

  onAssessClick() {
    this.router.navigate(['/mydata/assessment-requests/bulk']);
  }

  tabClick(tabIndex: number) {
    this.perfGridContent = this.perfGridArray[tabIndex];
  }
}
