import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PersonSearchParameters } from '../../../person/services/person.service';
export const RoleManagementConfig = {
  descriptionMaxNumbOfChars: 800,
};
export interface JobRoleRequirements {
  children?: JobRoleRequirements[];
  newVersionDueValue: number;
  newVersionDueUnit: string;
  newHireDueValue: number;
  newHireDueUnit: string;
  parentId: number;
  allowDescendents: boolean;
  claimLevelId: number;
  claimLevelName: string;
  claimLevelScore: number;
  claimLevelSetId: number;
  dueAfterUnit?: string;
  dueAfterValue?: number;
  externalGuid?: string;
  externalOwner?: string;
  externalProvider?: string;
  externalType?: string;
  functionalAreaId?: number;
  functionalAreaName?: string;
  id: number;
  isActive?: boolean;
  jobRoleId: number;
  mandatory: boolean;
  overridePriorDueDates: boolean;
  pendingActivation?: boolean;
  pendingDeletion?: boolean;
  recurUnit?: string;
  recurValue?: number;
  skillDimensionId: number;
  skillId: number;
  skillName?: string;
  skillVersion?: string;
  versionLoId?: number;
  weighting: number;
}

export enum JobRoleDetailTypeName {
  about = 'About',
  keyResponsibilities = 'Key Responsibilities',
  skillsAndExperience = 'Experience',
  regulatoryRequirements = 'Regulatory Requirements',
  keyPerformanceIndicators = 'Key Performance Indicators',
}

const dotNetApiServerUri = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class RoleManagementService {
  private http = inject(HttpClient);


  getPeopleForJobRole(
    jobRoleId: number,
    payload: PersonSearchParameters,
  ): Observable<any> {
    const url = `${dotNetApiServerUri}/people/vsJobRoleId/${jobRoleId}`;
    return this.http.post(url, JSON.stringify(payload)).pipe(
      map((resp) => resp),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }
}
