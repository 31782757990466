<div class="w-100 d-flex mb-2">
  <button
    class="btn dimension-btn-all me-2"
    [class.checked]="selectedDimensionId === 0"
    (click)="dimensionSelected(0)"
  >
    All
  </button>
  <div *ngFor="let dimension of skillDimension; trackBy: track">
    <button
      type="button"
      class="btn appButton me-2"
      [attr.title]="dimension.name"
      [class.dimension-btn-application]="dimension.id === 1"
      [class.dimension-btn-behaviour]="dimension.id === 2"
      [class.dimension-btn-knowledge]="dimension.id === 3"
      [class.dimension-btn-training]="dimension.id === 4"
      [class.checked]="dimension.id === selectedDimensionId"
      (click)="dimensionSelected(dimension.id)"
    >
      {{ dimension.name }}
    </button>
  </div>
</div>
