import { KeyValue } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ug-key-modal',
  templateUrl: './key-modal.component.html',
  styleUrls: ['./key-modal.component.scss'],
})
export class KeyModalComponent implements OnInit {
  protected modal = inject(NgbActiveModal);
  protected stateSymbolMap: Map<string, string>;
  protected keySymbolMap: Map<string, string>;

  ngOnInit(): void {
    this.keySymbolMap = new Map<string, string>([
      [
        '(Curricula Only) Attention needed on one or more child training objects',
        'fa-xl fas fa-exclamation-triangle text-secondary',
      ],

      [
        'Training is Incomplete, but not due yet',
        this.stateSymbolMap.get('Not due yet') as string,
      ],
      [
        'Training is Incomplete (with no due date), Overdue or Expired',
        this.stateSymbolMap.get('Expired') as string,
      ],
      [
        'Training is Complete and within expiry range',
        this.stateSymbolMap.get('Complete') as string,
      ],
      [
        'Training is Complete but nearing expiry',
        this.stateSymbolMap.get('Expiring') as string,
      ],
      ['Not Activated', this.stateSymbolMap.get('Not activated') as string],
      ['Not Required', this.stateSymbolMap.get('Not required') as string],
      ['Updating', this.stateSymbolMap.get('Updating') as string],
      ['Awaiting Sync', this.stateSymbolMap.get('Awaiting Sync') as string],
    ]);
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => {
    return 0;
  };
}
