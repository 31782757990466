<div [formGroup]="parentFormGroup">
  <div>
    <div *ngIf="!allowEdit" class="fst-italic fw-bold">
      <p class="fst-italic fw-bold">
        You do not have permission to edit Role Competencies
      </p>
    </div>
    <div *ngIf="allowEdit">
      <button (click)="openAddRoleCompetenciesModal()" class="btn esqepBtn">
        Add Competencies
      </button>
      <div class="fst-italic mt-2">
        Click the "Add Competencies" button to add competencies to the role. In
        the table below, set the required level, weighting and mandatory flag.
      </div>
    </div>
    <div class="fixed-table-height mt-3">
      <div
        class="alert alert-info"
        [hidden]="competencies.controls?.length > 0"
      >
        <i class="fas fa-xl fa-info-circle me-2"></i>No competencies have been
        added to this role
      </div>
      <table
        class="w-100 table table-striped"
        [hidden]="competencies.controls?.length === 0"
      >
        <thead>
          <tr>
            <th scope="col" class="w-25">Competency Subcategory</th>
            <th scope="col">Competency</th>
            <th scope="col">Level Required</th>
            <th scope="col">Weighting</th>
            <th scope="col" class="text-center">Mandatory</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody formArrayName="competencies">
          <ng-container
            *ngFor="let comp of competencies.controls; let i = index"
            [formGroupName]="i"
          >
            <tr [ngClass]="{ 'border-start-danger-thick': comp?.errors }">
              <td>
                <div>
                  {{ comp.value.functionalAreaName }}
                </div>
              </td>
              <td>
                {{ comp.value.skillName }}
              </td>
              <td *ngIf="levelSets && levelSets[comp.value.claimLevelSetId]">
                <select
                  class="form-select form-select-sm"
                  formControlName="claimLevelId"
                  title="Level"
                  required
                  name="claimLevelId"
                >
                  <option selected value="">Select level...</option>
                  <option
                    *ngFor="
                      let level of levelSets[comp.value.claimLevelSetId];
                      let k = index
                    "
                    [ngValue]="parseInt(level.key)"
                  >
                    {{ level.name }}
                  </option>
                </select>
                <div
                  *ngIf="
                    comp.get('claimLevelId')?.hasError('required') &&
                    comp.get('claimLevelId')?.touched
                  "
                  class="is-invalid error"
                >
                  Level is required
                </div>
              </td>
              <td>
                <input
                  class="form-control form-control-sm text-end"
                  type="number"
                  step="0.01"
                  min="0"
                  max="1"
                  name="weighting"
                  formControlName="weighting"
                  title="Weighting"
                />
                <div
                  *ngIf="comp.value.weighting > 1 || comp.value.weighting < 0"
                  class="is-invalid error"
                >
                  Weighting must be between 0 and 1
                </div>
              </td>
              <td class="text-center">
                <input
                  class="form-check-input px-1"
                  type="checkbox"
                  formControlName="mandatory"
                  name="mandatory"
                  title="Is mandatory?"
                />
              </td>
              <td>
                <button
                  *ngIf="
                    !comp.value.pendingDeletion && comp.value.id && !comp.dirty
                  "
                  type="button"
                  class="btn btn-outline-danger btn-sm"
                  (click)="onRemoveRequirementClick(i)"
                  [disabled]="!allowEdit"
                >
                  Remove
                </button>
                <button
                  *ngIf="
                    !comp.value.pendingDeletion && comp.dirty && !comp.value.id
                  "
                  type="button"
                  class="btn btn-outline-danger btn-sm"
                  (click)="onDiscardAddedRequirementClick(i)"
                  [disabled]="!allowEdit"
                >
                  Discard
                </button>
                <button
                  *ngIf="
                    !comp.value.pendingDeletion && comp.dirty && comp.value.id
                  "
                  type="button"
                  class="btn btn-outline-danger btn-sm"
                  (click)="onDiscardChangeRequirementClick(i, comp.value.id)"
                  [disabled]="!allowEdit"
                >
                  Discard Change
                </button>
                <button
                  *ngIf="comp.value.pendingDeletion && comp.dirty"
                  type="button"
                  class="btn btn-outline-secondary btn-sm"
                  (click)="onRestoreRequirementClick(i, comp.value.id)"
                  [disabled]="!allowEdit"
                >
                  Restore
                </button>
              </td>
            </tr>
            <tr *ngIf="comp?.errors">
              <td colspan="11" class="text-danger">*{{ comp?.errors }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="parentFormGroup.get('id').value">
      <div class="my-2">
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary ms-1"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseAudit"
        >
          {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
        </button>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ug-audit-table
          [auditEndpoint]="requirementAudit"
          [id]="parentFormGroup.get('id').value"
          contextKey="requirementName"
          contextTitle="Requirement"
          auditTitle="Requirement"
        ></ug-audit-table>
      </div>
    </ng-container>
  </div>
</div>
