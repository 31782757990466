<div class="card shadow overflow-visible">
  <div class="card-body">
    <form class="form bg-light border p-3 mb-3">
      <div class="row align-items-center">
        <div class="col-12 col-md-2">
          <strong class="form-text text-md-end">Perspective:</strong>
        </div>
        <div class="col-12 col-md-4 pt-1">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="jobRoleOrPersonOptions"
              id="jobRoleOption"
              [value]="jobRoleOrPersonOptions.JOB_ROLE"
              [(ngModel)]="jobRoleOrPersonSelection"
              (change)="onRadioChange()"
            />
            <label class="form-check-label" for="jobRoleOption">Job Role</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="jobRoleOrPersonOptions"
              id="personOption"
              [value]="jobRoleOrPersonOptions.PERSON"
              [(ngModel)]="jobRoleOrPersonSelection"
              (change)="onRadioChange()"
            />
            <label class="form-check-label" for="personOption">Person</label>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <form [formGroup]="formGroup" class="d-flex flex-column">
            <div
              *ngIf="
                jobRoleOrPersonSelection === jobRoleOrPersonOptions.JOB_ROLE
              "
            >
              <ug-dropdown-select
                [ngbTypeahead]="jobRoleSearch"
                [resultFormatter]="jobRoleFormatter"
                [inputFormatter]="jobRoleFormatter"
                formControlName="jobRole"
              >
                Job Role
              </ug-dropdown-select>
            </div>
            <div
              *ngIf="jobRoleOrPersonSelection === jobRoleOrPersonOptions.PERSON"
            >
              <ug-dropdown-select
                [ngbTypeahead]="peopleSearch"
                [resultFormatter]="personFormatter"
                [inputFormatter]="personFormatter"
                formControlName="person"
              >
                Person
              </ug-dropdown-select>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-2">
          <button
            type="button"
            class="btn btn-outline-dark esqepBtn btn-sm"
            [disabled]="viewButtonDisabled"
            (click)="onViewClick()"
          >
            View
          </button>
        </div>
      </div>
    </form>
    <ng-container
      *ngIf="jobRolePeople?.length > 0 || personJobRoles.length > 0"
    >
      <h3 class="card-subtitle text-muted">
        <ng-container
          *ngIf="jobRoleOrPersonSelection === jobRoleOrPersonOptions.PERSON"
        >
          Job Role Skill Profiles compared to: {{ selectedPersonName }}
        </ng-container>
        <ng-container
          *ngIf="jobRoleOrPersonSelection === jobRoleOrPersonOptions.JOB_ROLE"
        >
          People Skill Profiles compared to: {{ selectedJobRoleName }}
        </ng-container>
      </h3>
    </ng-container>
    <div *ngIf="jobRoleOrPersonSelection === jobRoleOrPersonOptions.PERSON">
      <ug-person-job-role-compare
        *ngIf="personJobRoles.length"
        [personJobRoles]="personJobRoles"
        (remove)="onCompareRemove($event)"
      ></ug-person-job-role-compare>
    </div>
    <div *ngIf="jobRoleOrPersonSelection === jobRoleOrPersonOptions.JOB_ROLE">
      <ug-role-across-people
        *ngIf="jobRolePeople.length"
        [jobRolePeople]="jobRolePeople"
      ></ug-role-across-people>

      <ug-job-role-compare
        *ngIf="jobRolePeople.length"
        [jobRolePeople]="jobRolePeople"
        (remove)="onCompareRemove($event)"
        [jobRoleOrPerson]="jobRoleOrPersonSelection"
      ></ug-job-role-compare>
    </div>

    <ug-list-status
      [emptyListMessage]="
        selectedJobRoleId || selectedPersonId
          ? 'No data found for the selected criteria'
          : 'Select a job role/person to compare'
      "
      [isLoading]="jobRoleQueryInProgress"
      [listLength]="jobRolePeople.length + personJobRoles.length"
    >
    </ug-list-status>

    <form
      *ngIf="jobRolePeople?.length > 0 || personJobRoles?.length > 0"
      class="form bg-light border p-3 pb-md-0 pt-md-3 mt-3"
    >
      <div
        *ngIf="
          jobRoleOrPersonSelection === jobRoleOrPersonOptions.PERSON &&
          selectedPersonId !== undefined &&
          personJobRoles
        "
        class="row mb-0 pb-2"
      >
        <div class="input-group col-12 col-md-6">
          <label class="col-form-label-sm text-md-end" for="otherJobRoleSelect"
            >Also compare to role</label
          >
          <select
            class="form-select form-select-sm mb-3 mb-md-0 ms-1"
            id="otherJobRoleSelect"
            [(ngModel)]="selectedOtherJobRoleId"
            name="otherJobRoleSelect"
            title="Job role"
          >
            <option disabled selected value="null">Select Job Role...</option>
            <option
              *ngFor="let jobRole of otherJobRoleList"
              [ngValue]="jobRole.id"
            >
              {{ jobRole.name }}
            </option>
          </select>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            [disabled]="addButtonDisabled"
            (click)="onAddJobRoleClick()"
          >
            Add
          </button>
        </div>
      </div>
      <div
        *ngIf="
          jobRoleOrPersonSelection === jobRoleOrPersonOptions.JOB_ROLE &&
          selectedJobRoleId !== undefined &&
          jobRolePeople
        "
        class="row mb-0 pb-2"
      >
        <div class="input-group col-12 col-md-4">
          <label class="col-form-label-sm text-md-end" for="otherPersonSelect"
            >Also compare to person</label
          >
          <select
            class="form-select form-select-sm mb-3 mb-md-0 ms-1"
            id="otherPersonSelect"
            [(ngModel)]="selectedOtherPersonId"
            name="otherPersonSelect"
            title="Person"
          >
            <option disabled selected value="null">Select Person...</option>
            <option
              *ngFor="let person of otherPersonList"
              [ngValue]="person.key"
            >
              {{ person.name }}
            </option>
          </select>
          <button
            type="button"
            class="btn btn-outline-primary esqepBtn btn-sm"
            [disabled]="addButtonDisabled"
            (click)="onAddPersonClick()"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
