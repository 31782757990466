import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ControlsModule } from '../controls/controls.module';
import { CreateProjectComponent } from './create-project/create-project.component';
import { KeyDetailsComponent } from './key-details/key-details.component';
import { ProjectCompetenciesComponent } from './project-competencies/project-competencies.component';
import { ProjectMembersComponent } from './project-members/project-members.component';
import { ProjectReviewComponent } from './project-review/project-review.component';
import { ProjectRolesComponent } from './project-roles/project-roles.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ResourceSummaryComponent } from './resource-summary/resource-summary.component';
import { RolesAndResourcesComponent } from './roles-and-resources/roles-and-resources.component';
import { ProjectsService } from './services/projects.service';
import {
  NgbdSortableHeader,
  ViewProjectsComponent,
} from './view-projects/view-projects.component';

@NgModule({
  declarations: [
    ViewProjectsComponent,
    NgbdSortableHeader,
    CreateProjectComponent,
    KeyDetailsComponent,
    RolesAndResourcesComponent,
    ProjectSettingsComponent,
    ProjectReviewComponent,
    ProjectRolesComponent,
    ProjectCompetenciesComponent,
    ProjectMembersComponent,
    ResourceSummaryComponent,
  ],
  imports: [
    CommonModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbModule,
    ProjectsRoutingModule,
    NgbNavModule,
    NgbPopoverModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbToastModule,
  ],
  exports: [ViewProjectsComponent, CreateProjectComponent],
  providers: [ProjectsService],
})
export class ProjectsModule {}
