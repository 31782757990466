<div
  *ngIf="parentFormGroup"
  class="card esqep-indicate-validity"
  [formGroup]="parentFormGroup"
>
  <div class="card-body">
    <div class="card-title">
      <span class="h5">Authorised Assessor</span>
      <div class="float-xl-end">
        <div class="form-group" formGroupName="verifier">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="orgBranchCheckBox"
              formControlName="orgUnitVerifiersOnly"
            />
            <label class="form-check-label" for="orgBranchCheckBox"
              >Only show from my organisation branch</label
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selfVerified; else verifierSelect" class="">
      <div class="alert alert-info">
        <span class="fas fa-xl fa-info-circle"></span>
        Assessment requests at this level will be automatically verified; No
        assessor is required to be specified
      </div>
    </div>
    <ng-template #verifierSelect>
      <div *ngIf="controlStatus.disabled" class="mb-2">
        <div class="alert alert-info">
          <span class="fas fa-xl fa-info-circle"></span>
          {{ controlStatus.reason }}
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-sm table-striped"
          [class.table-hover]="!formGroupDisabled"
        >
          <thead [ngClass]="{ 'table-dark': formGroupDisabled }">
            <tr>
              <th scope="col" class="w-25">Assessor name</th>
              <th scope="col" class="w-50">Org. Unit</th>
              <th scope="col" class="w-25">Assessor For Job Family</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let verifier of verifierList; let i = index"
              [ngClass]="getVerifierRowClass(verifier)"
              (click)="onVerifierClick(i, verifier)"
            >
              <th scope="row">{{ verifier.personFamilyGivenName }}</th>
              <td>{{ verifier['name'] }}</td>
              <!--TODO: Change this to more meaningful name (in API)-->
              <td>{{ verifier.funcAreaName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </div>
</div>
