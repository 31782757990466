<div class="modal" tabindex="-1" role="dialog" id="myModal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Rating Criteria</h5>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row esqep-bg-outstanding">
            <div class="col-2">
              <p>OUTSTANDING</p>
            </div>
            <div class="col-3">
              <p>220 points+</p>
            </div>
            <div class="col">
              <p>
                Minimum GOOD rated in Written, STP, QA & Passover benchmarks
              </p>
              <p>Rated GOOD or above in every measurement area</p>
            </div>
          </div>
          <div class="row esqep-bg-good">
            <div class="col-2">
              <p>GOOD</p>
            </div>
            <div class="col-3">
              <p>180 to 219 points</p>
            </div>
            <div class="col">
              <p>Minimum GOOD rated in Written, STP, & QA benchmarks</p>
            </div>
          </div>
          <div class="row esqep-bg-req-improve">
            <div class="col-2">
              <p>REQUIRES IMPROVEMENT</p>
            </div>
            <div class="col-3">
              <p>99 to 179 points</p>
            </div>
            <div class="col">
              <p>
                Automatic REQUIRES IMPROVEMENT if Written, STP or QA benchmark
                missed irrespective of points
              </p>
            </div>
          </div>
          <div class="row esqep-bg-special-measures">
            <div class="col-2">
              <p>SPECIAL MEASURES</p>
            </div>
            <div class="col-3">
              <p>98 points of fewer</p>
            </div>
            <div class="col">
              <p>
                Automatic SPECIAL MEASURES if Written, STP or QA benchmark is in
                SPECIAL MEASURES category
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <div *ngIf="perfGridArray.length > 1" class="card-text h5">Roles:</div>
    <ul class="nav nav-tabs card-header-tabs">
      <li *ngFor="let pgc of perfGridArray; let i = index" class="nav-item">
        <!-- <a class="nav-link active" [routerLink]="">{{perfGridContent?.name}} <span [ngClass]="overallBG(moderatedRatingScoreIdx)">{{moderatedRating}}</span></a> -->
        <a
          class="nav-link"
          [ngClass]="i === 0 ? 'active' : ''"
          [routerLink]="[]"
          (click)="tabClick(i)"
          role="tab"
          data-toggle="tab"
        >
          <span>{{ pgc?.name }} </span>
          <span
            class="badge"
            [ngClass]="overallBG(pgc.moderatedRatingScoreIdx)"
            >{{ pgc.cModeratedRating }}</span
          >
        </a>
      </li>
      <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li> -->
    </ul>
  </div>
  <div class="card-body">
    <!-- <h5 class="card-title">Sales Performance Summary - {{perfGridContent.userFirstName}} {{perfGridContent.userLastName}}</h5> -->
    <h5 class="card-title">Performance Summary</h5>
    <div class="container-fluid text-center">
      <div class="row fw-bold">
        <div class="col-3">
          <h4>Ratings</h4>
        </div>
        <div
          *ngFor="let c of perfGridContent?.metadata?.Categories; let i = index"
          class="col-2"
          [ngClass]="categoryBG(i)"
        >
          <h4>{{ c.name }}</h4>
          <h5>
            <small>{{ c.score }} points</small>
          </h5>
        </div>
        <div class="col-1">
          <h4>Points</h4>
        </div>
      </div>

      <form [formGroup]="perfForm">
        <div *ngFor="let r of perfGridContent?.ratings; let rIdx = index">
          <div class="row border-top">
            <div class="offset-3"></div>
            <div *ngFor="let rc of r?.ratingCriteria" class="col-2">
              <!-- <p class="fst-italic">{{rc.description0(rc.fromValue, rc.toValue)}}</p> -->
              <p class="fst-italic">{{ rc.description }}</p>
            </div>
            <div class="col-1 offset-1"></div>
          </div>
          <div class="row">
            <div class="col-2 text-end">
              <h5>{{ r.name }}</h5>
            </div>
            <!-- 
                        <div *ngIf="!r.allowInput" class="col-3">
                            <h5>{{r.name}}</h5>
                        </div>
                        <div *ngIf="r.allowInput" class="col-2 text-end">
                            <h5>{{r.name}}</h5>
                        </div>
 -->
            <div class="col-1 form">
              <!-- <input type="number" class="form-control" [formControlName]="rIdx" step="0.01" [value]="percentValue(rIdx)"> -->
              <input
                *ngIf="r.allowInput && r.InputType === numberControlInput"
                type="number"
                class="form-control form-control-sm"
                [formControlName]="rIdx"
                step="0.0001"
                title="Performance score"
              />
              <button
                *ngIf="r.allowInput && r.InputType === radioControlInput"
                type="button"
                class="btn btn-outline-primary btn-sm float-end"
                (click)="onAssessClick()"
              >
                Rate Performance
              </button>
            </div>
            <!-- 
                        <div *ngIf="r.allowInput && r.InputType===numberControlInput" class="col-1 form">
                            <input type="number" class="form-control form-control-sm" [formControlName]="rIdx" step="0.0001">
                        </div>
                        <div *ngIf="r.allowInput && r.InputType===radioControlInput" class="col-1 form">
                            <button type="button" class="btn btn-outline-primary btn-sm float-end" (click)="onAssessClick()">Rate Performance</button>
                        </div>
 -->
            <div
              *ngFor="let rc of r?.ratingCriteria; let j = index"
              class="col-2 form-check"
              [ngClass]="ratingBG(j, r)"
            >
              <!-- <h3 [hidden]="!ratingInRange(r.ratingValue, rc.fromValue, rc.toValue)">{{r.ratingValue | percent:'1.2-2'}}</h3> -->
              <h5 [hidden]="!rc.ratingInRange(r.ratingValue)">
                {{ r.displayValue }}
              </h5>
              <input
                *ngIf="r.allowInput && r.InputType === radioControlInput"
                type="radio"
                class="form-control-radio"
                [formControlName]="rIdx"
                [value]="3 - j"
                [hidden]="hideRadio(rIdx, j)"
                title="Rating"
              />
            </div>
            <div class="col-1">
              <h5>
                {{ perfGridContent?.metadata?.categoryScore(r.scoreIdx) }}
              </h5>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 text-end">
          <h4>{{ perfGridContent?.cOverallRating?.name }}</h4>
        </div>
        <div class="col-2 border">
          <!-- <h4>{{perfGridContent?.cOverallRating?.ratingValue2()}}</h4> -->
        </div>
        <div class="col-2 text-end">
          <span class="h4">RATING</span>
          <a
            routerLink="#"
            class="text-primary float-end"
            data-toggle="modal"
            data-target="#myModal"
            ><span class="fas fa-question-circle"></span
          ></a>
        </div>
        <div
          class="col-2 border"
          [ngClass]="overallBG(perfGridContent?.moderatedRatingScoreIdx)"
        >
          <!-- <h5>{{overallRating?.displayValue}}</h5> -->
          <span class="h4">{{ perfGridContent?.cModeratedRating }}</span>
          <!-- {{perfGridContent?.cOverallRating.ratingValue}} {{perfGridContent?.moderatedRatingScoreIdx}} {{perfGridContent?.cOverallRating.scoreIdx}} -->
        </div>
      </div>
      <!-- <p>{{perfGridContent?.cOverallRating | json}}</p> -->
    </div>
  </div>
</div>
<!-- 
<div class="card">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-3 text-end">
                    <h4>{{overallRating?.name}}</h4>
                </div>
                <div class="col-2 border">
                    <h4>{{overallRating?.ratingValue2()}}</h4>
                </div>
                <div class="col-2 text-end">
                    <span class="h4">RATING</span>
                    <a routerLink="#" class="text-primary float-end" data-toggle="modal" data-target="#myModal"><span class="fas fa-question-circle"></span></a>
                </div>
                <div class="col-2 border" [ngClass]="overallBG(moderatedRatingScoreIdx)">
                    <span class="h4">{{moderatedRating}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
 -->
