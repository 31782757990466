<div
  class="card esqep-indicate-validity overflow-visible"
  [formGroup]="parentFormGroup"
>
  <div class="card-body">
    <h5 class="card-title">Available Actions</h5>
    <!-- <div [formGroup]="parentFormGroup"> -->
    <div
      [hidden]="claimActions?.length > 0 || parentFormGroup.disabled"
      class="alert alert-info mb-3"
    >
      <span class="fas fa-xl fa-info-circle"></span>
      No available actions for this Assessment Request
    </div>
    <div [hidden]="targetFormGroup.pristine" class="alert alert-warning mb-3">
      <span class="fas fa-xl fa-info-circle"></span> Save or discard changes to
      enable actions
    </div>
    <div class="form-group" formGroupName="claimAction">
      <select
        class="form-select"
        [attr.id]="'claimActionSelect' + componentIndex"
        (change)="onActionChange()"
        formControlName="id"
        title="Action"
      >
        <option disabled selected value="null">Select an action...</option>
        <option
          *ngFor="let action of claimActions; let i = index"
          [ngValue]="action.id"
        >
          {{ action.name }}
        </option>
      </select>
      <p
        [attr.id]="'claimActionSelect' + (componentIndex || '') + 'HelpBlock'"
        class="form-text small text-end"
      >
        {{
          parentFormGroup.get('claimAction.description').value ||
            'Please select an assessment request action'
        }}
      </p>
    </div>
    <div *ngIf="initiatorType && initiatorType === 'A'">
      <ug-claim-expiry-date-form
        [parentFormGroup]="parentFormGroup"
      ></ug-claim-expiry-date-form>
    </div>
    <!-- </div> -->
    <div>
      <button
        type="submit"
        class="btn esqepBtn me-2"
        [disabled]="
          parentFormGroup.invalid ||
          targetFormGroup.dirty ||
          targetFormGroup.invalid
        "
        (click)="onOkClick()"
      >
        Ok
      </button>
      <button
        type="button"
        class="btn esqepBtn"
        [disabled]="
          parentFormGroup.pristine ||
          targetFormGroup.dirty ||
          targetFormGroup.invalid
        "
        (click)="onCancelClick()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
