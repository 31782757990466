import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SkillDimension } from '../../../../../skill/services/skill.service';

@Component({
  selector: 'ug-dimension-buttons',
  templateUrl: './dimension-buttons.component.html',
  styleUrl: './dimension-buttons.component.scss',
})
export class DimensionButtonsComponent implements OnInit {
  @Input() skillDimension: SkillDimension[];
  selectedDimensionId: number;
  @Output() dimensionSelectedEvent: EventEmitter<number> =
    new EventEmitter<number>();

  track(item) {
    return item.id;
  }

  ngOnInit(): void {
    this.dimensionSelected(0);
  }

  dimensionSelected(dimId: number) {
    this.selectedDimensionId = dimId;
    this.dimensionSelectedEvent.emit(dimId);
  }
}
