import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AssessorModule } from '../assessor/assessor.module';
import { ClaimModule } from '../claim/claim.module';
import { ControlsModule } from '../controls/controls.module';
import { SkillModule } from '../skill/skill.module';
import { SubmissionRoutingModule } from './submission-routing.module';

import { NgbDatepickerModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionSelectFormComponent } from './action-select-form/action-select-form.component';
import { AttachmentSelectFormComponent } from './attachment-select-form/attachment-select-form.component';
import { ClaimExpiryDateFormComponent } from './claim-expiry-date-form/claim-expiry-date-form.component';
import { CompetencyAttrCategoryListComponent } from './competency-attr-category-list/competency-attr-category-list.component';
import { CompetencyAttrFilterListComponent } from './competency-attr-filter-list/competency-attr-filter-list.component';
import { CompetencyAttrFormComponent } from './competency-attr-form/competency-attr-form.component';
import { EvidenceComponent } from './evidence/evidence.component';
import { FormErrorListComponent } from './form-error-list/form-error-list.component';
import { HistoryTableComponent } from './history-table/history-table.component';
import { LevelAndVerifierComponent } from './level-and-verifier/level-and-verifier.component';
import { LevelSelectFormComponent } from './level-select-form/level-select-form.component';
import { StatementFormComponent } from './statement-form/statement-form.component';
import { StatementListFormComponent } from './statement-list-form/statement-list-form.component';
import { SubmissionDetailComponent } from './submission-detail/submission-detail.component';
import { SummaryActionFormComponent } from './summary-action-form/summary-action-form.component';
import { VerifierActionFormComponent } from './verifier-action-form/verifier-action-form.component';
import { VerifierSelectFormComponent } from './verifier-select-form/verifier-select-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AssessorModule,
    ControlsModule,
    SkillModule,
    ClaimModule,
    SubmissionRoutingModule,
    NgbNavModule,
    NgbDatepickerModule,
  ],
  declarations: [
    LevelAndVerifierComponent,
    SubmissionDetailComponent,
    LevelSelectFormComponent,
    VerifierSelectFormComponent,
    StatementFormComponent,
    EvidenceComponent,
    StatementListFormComponent,
    AttachmentSelectFormComponent,
    CompetencyAttrFormComponent,
    SummaryActionFormComponent,
    ActionSelectFormComponent,
    VerifierActionFormComponent,
    CompetencyAttrCategoryListComponent,
    CompetencyAttrFilterListComponent,
    FormErrorListComponent,
    HistoryTableComponent,
    ClaimExpiryDateFormComponent,
  ],
  exports: [
    LevelAndVerifierComponent,
    SubmissionDetailComponent,
    LevelSelectFormComponent,
    VerifierSelectFormComponent,
    StatementFormComponent,
    EvidenceComponent,
    StatementListFormComponent,
    AttachmentSelectFormComponent,
    CompetencyAttrFormComponent,
    SummaryActionFormComponent,
    ActionSelectFormComponent,
    VerifierActionFormComponent,
    CompetencyAttrCategoryListComponent,
    CompetencyAttrFilterListComponent,
    FormErrorListComponent,
    HistoryTableComponent,
  ],
})
export class SubmissionModule {}
