import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { TableHeader } from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { ToFirstCharUpperCasePipe } from '../../pipes/to-first-char-upper-case.pipe';
import {
  AuditData,
  AuditFilter,
  AuditFilterValue,
  AuditService,
} from './audit.service';

@Component({
  selector: 'ug-audit-table',
  templateUrl: './audit-table.component.html',
  styleUrl: './audit-table.component.scss',
})
export class AuditTableComponent implements OnInit {
  @Input() settingPropertyName: string = '';
  @Input() id: number;
  @Input() auditEndpoint!: (
    pagedData: AuditFilter,
    id: number,
  ) => Observable<AuditData>;
  @Input() contextKey: string = '';
  @Input() contextTitle: string = '';
  @Input() auditTitle: string = '';

  private auditService = inject(AuditService);
  private datepipe = inject(DatePipe);
  private alertService = inject(AlertService);
  private destroyRef = inject(DestroyRef);
  private baseAuditFilter: AuditFilter = {
    filter: '',
    order: 'Date desc',
    page: 1,
    pageSize: 10,
  };
  private auditFilter!: AuditFilter | null;
  private toFirstCharUpperCase = new ToFirstCharUpperCasePipe();
  protected tableHeaders: TableHeader[] = [];
  protected tableRows: any = [];
  protected loadingData = false;
  protected auditRecordCount!: number;
  protected tablePageSize!: number;
  protected tablePageIndex!: number;
  protected filtersCollapsed = true;

  ngOnInit(): void {
    this.tableHeaders = [
      {
        id: 'date',
        title: 'Date',
        stringFunction: this.dateDisplay,
        noWrap: true,
      },
      { id: 'action', title: 'Action', noWrap: true },
      { id: 'personDisplayName', title: 'Modified by', noWrap: true },
      {
        id: this.contextKey,
        title: this.contextTitle,
        hideCondition: this.hideContextColumn,
      },
      { id: 'propertyName', title: 'Modified property', noWrap: true },
      { id: 'fromValue', title: 'From value', class: 'w-15' },
      { id: 'toValue', title: 'To value', class: 'w-15' },
    ];

    this.loadingData = true;
    this.updateData();

    this.auditService.updateAuditHistory
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((usa) => {
        if (usa) {
          this.clearFilters(true);
        }
      });
  }

  hideContextColumn = () => {
    return !this.contextKey;
  };

  dateDisplay = (row: { date: string | number | Date }) => {
    return this.datepipe.transform(row.date, 'YYYY-MM-dd HH:mm');
  };

  updateData(
    pageNumber?: number,
    pageSize?: number,
    sort?: { column: string; sortDirection: string },
  ) {
    const dataToPaginate: AuditFilter = this.auditFilter
      ? this.auditFilter
      : this.baseAuditFilter;

    if (pageNumber) {
      dataToPaginate.page = pageNumber;
    }
    if (pageSize) {
      dataToPaginate.pageSize = pageSize;
    }
    if (sort) {
      const col = this.toFirstCharUpperCase.transform(sort.column);
      dataToPaginate.order = `${col} ${sort.sortDirection}`;
    }

    this.callAuditEndpoint(dataToPaginate);
  }

  callAuditEndpoint(dataToPaginate: AuditFilter) {
    this.auditEndpoint(dataToPaginate, this.id).subscribe({
      next: (x: AuditData) => {
        this.auditRecordCount = x.totalRowCount;
        this.tableRows = [...x.data];
        this.tablePageSize = x.pageSize;
        this.tablePageIndex = x.page;
        this.loadingData = false;
      },
      error: (err: HttpErrorResponse | undefined) => {
        this.alertService.createAlert2(
          {
            level: AlertLevels.ERROR,
            code: 'ADH-001',
            message: 'Error getting audit history',
          } as AlertData,
          err,
        );
      },
    });
  }

  clearFilters($event: boolean) {
    if ($event) {
      this.auditFilter = null;
      this.baseAuditFilter.page = 1;
      this.updateData();
    }
  }

  onFiltersChanged($event) {
    this.auditFilter = Object.assign({}, this.baseAuditFilter);
    this.auditFilter.page = 1;
    this.auditFilter.filter = this.constructFilterString($event);
    this.callAuditEndpoint(this.auditFilter);
  }

  constructFilterString(filter: AuditFilterValue): string {
    const formatDate = (date, isStartOfDay) => {
      return `${date}T${isStartOfDay ? '00:00:00.000Z' : '23:59:59.999Z'}`;
    };

    const filterConditions = Object.entries(filter)
      .map(([key, value]: [string, string]) => {
        if (value) {
          switch (key) {
            case 'dateFrom':
              return `["Date",">=","${formatDate(value, true)}"]`;
            case 'dateTo':
              return `["Date","<=","${formatDate(value, false)}"]`;
            case 'action':
              return `["Action","=","${value}"]`;
            case 'modifiedBy':
              return `["PersonDisplayName","contains","${value}"]`;
            case 'modifiedProperty':
              return `["PropertyName","contains","${value}"]`;
            case 'fromValue':
              return value.toLocaleLowerCase().includes('null')
                ? `["FromValue","null",null]`
                : `["FromValue","contains","${value}"]`;
            case 'toValue':
              return value.toLocaleLowerCase().includes('null')
                ? `["ToValue","null",null]`
                : `["ToValue","contains","${value}"]`;
            case this.contextKey:
              return `["${this.toFirstCharUpperCase.transform(this.contextKey)}","contains","${value}"]`;
            default:
              return '';
          }
        } else {
          return '';
        }
      })
      .filter((condition) => condition); // Filter out any empty strings

    // Join the conditions with "and" and wrap in brackets
    return filterConditions.length > 0
      ? `[${filterConditions.join(',"and",')}]`
      : '';
  }
}
