<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">
      {{
        this.configService.blurNames
          ? 'Person'
          : currentUser?.displayName + "'s"
      }}
      Details
    </h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div *ngIf="currentUser?.displayName" class="row">
        <div class="col-4 fw-bold text-end">Name</div>
        <div class="col-8" ugAnonymize>{{ currentUser.displayName }}</div>
      </div>
      <div *ngIf="currentUser?.externalUserId" class="row">
        <div class="col-4 fw-bold text-end">User Id</div>
        <div class="col-8">{{ currentUser.externalUserId }}</div>
      </div>
      <div *ngIf="currentUser?.externalUserName" class="row">
        <div class="col-4 fw-bold text-end">Username</div>
        <div class="col-8" ugAnonymize>{{ currentUser.externalUserName }}</div>
      </div>
      <div *ngIf="currentUser?.positionName" class="row">
        <div class="col-4 fw-bold text-end">Position</div>
        <div class="col-8">{{ currentUser.positionName }}</div>
      </div>
      <div *ngIf="currentUser?.orgUnitName" class="row">
        <div class="col-4 fw-bold text-end">
          {{ orgUnitType || 'Org Unit' }}
        </div>
        <div class="col-8">{{ currentUser.orgUnitName }}</div>
      </div>
      <div *ngIf="currentUser?.supervisorDisplayName" class="row">
        <div class="col-4 fw-bold text-end">Reports to</div>
        <div class="col-8" ugAnonymize>
          {{ currentUser.supervisorDisplayName }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.close()"
    >
      Close
    </button>
  </div>
</div>
