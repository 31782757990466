import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../core/services/auth.service';

import { ExceptionTypes } from '../../core/exception/exception.component';

@Injectable({ providedIn: 'root' })
export class AdminGuard {
  private authService = inject(AuthService);
  private router = inject(Router);


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.hasAdminRole) {
      this.router.navigate([
        'exception',
        ExceptionTypes[ExceptionTypes.NOT_AUTH],
      ]);
      return false;
    }
    return true;
  }
}
