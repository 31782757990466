import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  SkillDimension,
  SkillSubject,
  SubjectLevels,
} from '../../../../../competencies/services/competencies.service';

interface SkillDetails {
  name: string;
  levelName: string;
  levelNameChanged: boolean;
  application: boolean;
  behaviour: boolean;
  knowledge: boolean;
  training: boolean;
  skillSubject: SkillSubject;
}

@Component({
  selector: 'ug-skillsubject',
  templateUrl: './skillsubject.component.html',
  styleUrl: './skillsubject.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillsubjectComponent {
  subjectLevelName$ = new BehaviorSubject<string>('');
  @Input() parentSubject: SkillSubject;
  canDelete: boolean;

  skillCount: number;
  subjectLevelName: string;
  currentSubjectLevelName: string;

  @Input() set subjectLevels(value: SubjectLevels[]) {
    this.subjectLevelArray = value;
  }

  @Input() set skillSubject(value: SkillSubject | null) {
    this.application = false;
    this.behaviour = false;
    this.knowledge = false;
    this.training = false;
    if (value !== null) {
      value.dimensionIds.forEach((num) => {
        switch (num) {
          case 1:
            this.application = true;
            break;
          case 2:
            this.behaviour = true;
            break;
          case 3:
            this.knowledge = true;
            break;
          case 4:
            this.training = true;
            break;
        }

        this.disabled = value.source !== null;
        this.levelDisabled = value.subjectLevelId === 1;
      });
      this.skillCount = value.skillCount;
      this.currentSubjectLevelName = this.subjectLevelArray?.find(
        (x) => x.id === value.subjectLevelId,
      ).name;
      this.subjectLevelName$.next(this.currentSubjectLevelName);
      this.imported = !!value?.source !== null;
      this.skillDetails = {
        name: value !== null ? value.name : '',
        levelName:
          this.subjectLevelName$.getValue() !== null
            ? this.subjectLevelName$.getValue()
            : '',
        levelNameChanged: false,
        application: this.application,
        behaviour: this.behaviour,
        knowledge: this.knowledge,
        training: this.training,
        skillSubject: value,
      };
    } else {
      this.levelDisabled = this.parentSubject ? true : false;
      this.application = this.parentSubject
        ? this.parentSubject.dimensionIds.includes(1)
        : false;
      this.behaviour = this.parentSubject
        ? this.parentSubject.dimensionIds.includes(2)
        : false;
      this.knowledge = this.parentSubject
        ? this.parentSubject.dimensionIds.includes(3)
        : false;
      this.training = this.parentSubject
        ? this.parentSubject.dimensionIds.includes(4)
        : false;

      this.imported = false;
      this.disabled = false;
      this.dimensionSkillCounts = 0;

      this.skillDetails = {
        name: '',
        levelName: '',
        levelNameChanged: false,
        application: this.application,
        behaviour: this.behaviour,
        knowledge: this.knowledge,
        training: this.training,
        skillSubject: {
          id: null,
          dimensionSkillCounts: [],
          parentId: this.parentSubject ? this.parentSubject.id : null,
          subjectLevelId: this.parentSubject
            ? this.parentSubject.subjectLevelId + 1
            : 1,
          name: '',
          isActive: true,
          source: null,
          externalId: null,
          externalParentId: this.parentSubject
            ? this.parentSubject.externalId
            : null,
          dimensionIds: this.parentSubject
            ? this.parentSubject.dimensionIds?.filter((d) => d !== 4)
            : [],
          canDelete: true,
          cultureId: 0,
          culture: null,
          skillCount: 0,
          functionalAreaCount: 0,
          subjectIds: [],
        },
      };
    }
    this.canDelete = this.skillDetails.skillSubject.canDelete;
  }

  @Input() skillDimensions: SkillDimension[];
  @Input() newNode: boolean;
  @Output() editedDetails: EventEmitter<{
    name: string;
    levelName: string;
    levelNameChanged: boolean;
    application: boolean;
    behaviour: boolean;
    knowledge: boolean;
    training: boolean;
    skillSubject: SkillSubject;
  }> = new EventEmitter();
  @Output() addChildSubject: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() deleteConfirmed: EventEmitter<{
    deleted: boolean;
    skillSubject: SkillSubject;
  }> = new EventEmitter();

  @Input() set selectedSubjectDescendants(value: SkillSubject[]) {
    this.importedCount = value
      ? value.filter((s) => s.source !== null).length +
        (this.skillDetails.skillSubject.source ? 1 : 0)
      : 0;
    this.internalCount = value
      ? value.filter((s) => s.source === null).length +
        (!this.skillDetails.skillSubject.source ? 1 : 0)
      : 0;
  }

  protected showName: boolean;
  protected application: boolean;
  protected behaviour: boolean;
  protected knowledge: boolean;
  protected training: boolean;
  protected dimensionSkillCounts: number;
  protected imported: boolean = false;
  protected disabled: boolean;
  protected levelDisabled: boolean;
  protected subjectLevelArray: SubjectLevels[];
  protected skillDetails: SkillDetails;
  protected deleteDisabled: boolean;
  protected importedCount: number;
  protected internalCount: number;
  protected confirmingDelete: boolean;
  protected disableDelete: boolean;

  submitForm(): void {
    this.editedDetails.emit(this.skillDetails);
  }

  addChildNode(): void {
    this.addChildSubject.emit(true);
  }

  edit(): void {
    this.showName = !this.showName;
    this.subjectLevelName$.next(this.skillDetails.levelName);
    this.skillDetails.levelNameChanged =
      this.subjectLevelName$.getValue() !== this.currentSubjectLevelName;
  }

  delete(): void {
    this.confirmingDelete = true;
  }
  confirmDelete(): void {
    this.confirmingDelete = false;
    this.deleteConfirmed.emit({
      deleted: true,
      skillSubject: this.skillDetails.skillSubject,
    });
  }

  canceldelete(): void {
    this.confirmingDelete = false;
  }
}
