import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private get openRequestCount() {
    return this.urlList.length;
  }

  // private set openRequestCount(value: number) {} // Dummy setter during bug investigation
  private urlList: Array<string> = [];

  add(url: string) {
    // this.openRequestCount += 1;
    this.urlList.push(url);
  }

  remove(url) {
    // this.openRequestCount -= 1;
    // if (this.openRequestCount < 0) { this.openRequestCount = 0; }
    const itemIndex = this.urlList.findIndex((item) => {
      return item === url;
    });
    if (itemIndex !== -1) {
      this.urlList.splice(itemIndex, 1);
    }
  }

  reset() {
    // this.openRequestCount = 0;
    this.urlList.length = 0;
  }

  inProgressUrls() {
    return JSON.stringify(this.urlList);
  }

  get count() {
    return this.openRequestCount;
  }

  get showSpinner() {
    return this.openRequestCount > 0 ? true : false;
  }
}
