import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { TypeaheadService } from '../../../../../controls/dropdown-select/typeahead.service';
import { TypeaheadFilter } from '../../../../../controls/dynamic-form/control-types/filter-typeahead';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../../../controls/table/table.service';
import { PeopleByOuPayload } from '../../../../../core/models/peopleByOuPayload';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { UitoolsService } from '../../../../../core/services/uitools.service';
import { OrgUnit } from '../../../../../org-unit/models/org-unit';
import {
  OrgUnitLookup,
  OrgUnitService,
} from '../../../../../org-unit/services/org-unit.service';
import { Person } from '../../../../../person/models/person';
import { PersonService } from '../../../../../person/services/person.service';
import { RoleManagementService } from '../../role-management.service';

@Component({
  selector: 'ug-add-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.scss'],
})
export class PeopleModalComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  modal = inject(NgbActiveModal);
  private orgUnitService = inject(OrgUnitService);
  private typeaheadService = inject(TypeaheadService);
  private uiService = inject(UitoolsService);
  private personService = inject(PersonService);
  private roleManagementService = inject(RoleManagementService);
  private alertService = inject(AlertService);

  exceptionData = {
    PERSON_LIST: {
      level: AlertLevels.ERROR,
      code: 'APM-001',
      message: 'Error retrieving person list',
    } as AlertData,
    PERSON_LIST_IN_OU: {
      level: AlertLevels.ERROR,
      code: 'APM-002',
      message: 'Error retrieving person list in org unit',
    } as AlertData,
  };

  tableHeaders: Array<TableHeader> = [];
  tableRows = [];
  tableFilters = [];
  tableSelectedButtons: Array<TableSelectedButton> = [];
  peopleCount = 0;

  @Input() jobRolePeopleInUse!: Array<any>;
  @Input() jobRoleId!: number;
  @Output() peopleSelected: EventEmitter<Array<any>> = new EventEmitter<
    Array<any>
  >();

  peopleFiltersForm: FormGroup;
  ngUnsubscribe: Subject<boolean> = new Subject();
  peopleList: Array<any>;
  peopleFilterDisabled = true;
  tableLoading = false;

  peopleSearch: OperatorFunction<string, Person[]>;
  orgUnitSearch: OperatorFunction<string, OrgUnit[]>;

  orgUnitTypes: Array<OrgUnitLookup> = [];

  orgUnits: any[] = [];

  static compare(items, input: string) {
    return items.toLowerCase().includes(input);
  }

  static sort(a, b, text: string) {
    return a.startsWith(text) - b.startsWith(text) || b - a;
  }

  static compareOrgUnit(items, input: string) {
    return items.name.toLowerCase().includes(input);
  }

  static sortOrgUnit(a, b, text: string) {
    return a.name.startsWith(text) - b.name.startsWith(text) || b.name - a.name;
  }

  formatter = (result) => result;

  orgUnitFormatter = (result) => result['name'];

  ngOnInit(): void {
    this.peopleFiltersForm = this.fb.group({
      filterType: [''],
      orgUnitFilter: [''],
      peopleSearch: [''],
      peopleFilter: [''],
    });
    this.orgUnitService
      .getLookups('OrgUnitType')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: Array<OrgUnitLookup>) => {
        this.orgUnitTypes = r;
      });

    this.tableHeaders = [
      { id: 'name', title: 'Name', class: 'w-50' },
      {
        id: 'existsInJobRole',
        title: 'Exists in role',
        iconFunction: this.existsInRole,
        class: 'text-center',
      },
      {
        id: 'existsInOrgUnit',
        title: 'Part of an organisation',
        iconFunction: this.existsInOrgUnit,
        class: 'text-center',
      },
    ];

    this.tableSelectedButtons = [
      {
        title: 'Cancel',
        class: 'btn-outline-secondary',
        cancelButton: true,
        function: this.closeModal,
      },
      {
        title: 'Add Selected',
        class: 'btn-outline-success',
        function: this.addSelected,
      },
    ];

    this.peopleFiltersForm.get('filterType')?.valueChanges.subscribe((ft) => {
      this.peopleFilterDisabled = true;
      this.peopleFiltersForm.get('orgUnitFilter')?.patchValue('');
      if (ft === 'People') {
        this.peopleFilterDisabled = false;
        this.orgUnitSearch = null;
        this.tableRows = [];

        this.peopleFiltersForm
          .get('peopleFilter')
          ?.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((t) => !!t),
            filter((t1) => t1.length > 2),
            switchMap((searchTerm: string) => {
              this.tableLoading = true;
              // if searching for a person, set orgUnitId and operator to null
              const payload: PeopleByOuPayload = {
                search: searchTerm,
                maxCount: 50,
              };
              return this.roleManagementService.getPeopleForJobRole(
                this.jobRoleId,
                payload,
              );
            }),
          )
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((pl) => {
            const personList = pl.map((p) => {
              p['name'] = p.externalUserId
                ? `${p.displayName} (${p.externalUserId})`
                : p.displayName;
              p.existsInJobRole = this.jobRolePeopleInUse.find(
                (f) => f.id === p.id,
              )
                ? true
                : p.existsInJobRole;
              return p;
            });
            this.tableRows = personList;
            this.peopleCount = personList.length;
            this.tableLoading = false;
          });
      } else {
        this.peopleFiltersForm.get('peopleFilter')?.patchValue('');
        this.tableRows = [];
        this.tableFilters = [];
        this.orgUnitService
          .getLookups('OrgUnit', ft)
          .subscribe((ou: Array<object>) => {
            this.orgUnitSearch = this.typeaheadService.typeahead(
              ou,
              PeopleModalComponent.compareOrgUnit,
              PeopleModalComponent.sortOrgUnit,
            );
          });
      }
    });

    this.peopleFiltersForm
      .get('orgUnitFilter')
      ?.valueChanges.subscribe((ouf) => {
        if (ouf) {
          this.tableLoading = true;
          const payload: PeopleByOuPayload = {
            orgUnitId: ouf.key,
            operator: '_=',
            maxCount: 0,
          };
          this.roleManagementService
            .getPeopleForJobRole(this.jobRoleId, payload)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (p) => {
                const allPeople = p.map((c) => c.name);
                const peopleNames = [...new Set(allPeople)];
                this.peopleSearch = this.typeaheadService.typeahead(
                  peopleNames,
                  PeopleModalComponent.compare,
                  PeopleModalComponent.sort,
                );

                this.tableFilters = [
                  new TypeaheadFilter({
                    key: 'name',
                    label: 'Filter by name',
                    typeAheadSearch: this.peopleSearch,
                    resultFormatter: this.formatter,
                    inputFormatter: this.formatter,
                    inLineGroup: false,
                    order: 1,
                  }),
                ];
                const personList = p.map((p) => {
                  p.existsInJobRole = this.jobRolePeopleInUse.find(
                    (f) => f.id === p.id,
                  )
                    ? true
                    : p.existsInJobRole;
                  return p;
                });
                this.tableRows = personList;
                this.peopleCount = personList.length;
                this.tableLoading = false;
              },
              error: (err) => {
                this.tableLoading = false;
                this.alertService.createAlert2(
                  this.exceptionData.PERSON_LIST_IN_OU,
                  err,
                );
              },
            });
        }
      });
  }

  addSelected = (selected) => {
    this.peopleSelected.next(selected);
    this.closeModal();
  };

  existsInRole = (row) => {
    return this.existsIcon(row.existsInJobRole);
  };

  existsInOrgUnit = (row) => {
    return this.existsIcon(row.existsInOrgUnit);
  };
  private existsIcon = (exists: boolean) => {
    return exists ? 'text-center fas fa-xl fa-check-circle text-success' : '';
  };

  closeModal() {
    this.modal.close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
