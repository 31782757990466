<div class="card bg-light">
  <div class="card-body">
    <dl>
      <dt>Name</dt>
      <dd>{{ person.familyGivenName || 'Not Specified' }}</dd>
      <dt>Role</dt>
      <dd>{{ person.roleName || 'Not Specified' }}</dd>
    </dl>
  </div>
</div>
