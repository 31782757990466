import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateProjectComponent } from './create-project/create-project.component';
import { ViewProjectsComponent } from './view-projects/view-projects.component';

const routes: Routes = [
  {
    path: 'view-projects',
    component: ViewProjectsComponent,
    data: { title: 'Projects', routeIdentifier: 'PROJECTS_VIEW' },
  },
  {
    path: 'create-project',
    component: CreateProjectComponent,
    data: { title: 'Create A Project', routeIdentifier: 'PROJECTS_CREATE' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
