<div class="card shadow">
  <div class="card-body px-5">
    <ug-list-status
      [isLoading]="isLoading"
      [listLength]="currentSettings?.size"
    ></ug-list-status>
    <ng-container *ngIf="!isLoading">
      <p>
        Toggle the settings you want to apply to job roles below and configure
        accordingly. Settings will not be applied if they are not active.
      </p>
      <form [formGroup]="jobRoleSettingsForm">
        <div class="mb-4">
          <ug-settings-card
            [selectedGroup]="selectedGroup"
            (onAssignButtonClickEvent)="openGroupFiltersModal()"
            (onDeleteButtonClickEvent)="deleteGroupFilter()"
            [fieldOptions]="fieldOptions"
            [unitOptions]="unitOptions"
            [settingKey]="'newHireFilter'"
            [settingSwitchText]="
              'Configure the identification of new hires within a job role'
            "
            [settingTitle]="'New Hires Identification'"
            [controlText]="
              'Select the key date that the calculation should be made from.'
            "
            [controlTitle]="'Hire Date Configuration'"
            [formGroup]="jobRoleSettingsForm.get('newHireFilter')"
          ></ug-settings-card>
        </div>
        <div class="mb-4">
          <ug-settings-card
            [settingSwitchText]="
              'Configure a default due date for new hire training assignments (Requires New Hire Identification)'
            "
            [settingTitle]="'New Hires Due Date'"
            [controlText]="
              'If used, the below value will automatically set all training due dates for new hires only.\nSkip this option if you prefer new hires to share the same due date as other users as instructed on each training item within a job role.'
            "
            [unitOptions]="unitOptions"
            [settingKey]="'newHire'"
            [formGroup]="jobRoleSettingsForm.get('newHire')"
          ></ug-settings-card>
        </div>
        <div class="mb-4">
          <ug-settings-card
            [settingSwitchText]="
              'Configure a default due date for all training assignments within a job role'
            "
            [settingTitle]="'Default Due Date'"
            [controlText]="
              'If used, the below value will automatically set the due date for all training within a job role. This can be overwritten in the assigning of training to a role.\nSkip this option if you prefer to manually add due dates for each training item.'
            "
            [unitOptions]="unitOptions"
            [settingKey]="'default'"
            [formGroup]="jobRoleSettingsForm.get('jobRole')"
          ></ug-settings-card>
        </div>
        <div class="mb-4">
          <ug-settings-card
            [settingSwitchText]="
              'Configure a reversioning due date for all training assignments within a job role'
            "
            [settingTitle]="'Reversioning Due Date'"
            [controlText]="
              'If used, the below value will automatically set a new due date for the training item when a new version of it is detected. This will only impacted users who have completed the training previously.\nSkip this option if you prefer to manually add due dates for each training items version, or want to use the default due date setting instead.'
            "
            [unitOptions]="unitOptions"
            [settingKey]="'reversioning'"
            [formGroup]="jobRoleSettingsForm.get('newVersion')"
          ></ug-settings-card>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary ms-1"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseAudit"
          >
            {{ isCollapsed ? 'Show Audit History' : 'Hide Audit History' }}
          </button>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <ug-job-role-settings-audit></ug-job-role-settings-audit>
        </div>
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            (click)="backToJobRoles()"
          >
            Return to Job Roles
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            [disabled]="
              !jobRoleSettingsForm.touched || jobRoleSettingsForm.invalid
            "
            (click)="updateJobRoleSettings()"
          >
            Save
          </button>
        </div>
      </form>
    </ng-container>
  </div>
</div>
<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
