import { Component, Input } from '@angular/core';

@Component({
  selector: 'ug-card-tile',
  templateUrl: './card-tile.component.html',
  styleUrls: ['./card-tile.component.scss'],
})
export class CardTileComponent {
  @Input() icon?: string;
  @Input() cardTitle?: string;
  @Input() cardContent?: string;
  @Input() highlight?: string;
}
