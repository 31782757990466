import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import {
  TableHeader,
  TableHeaderButton,
  TableRowButton,
} from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  AuthService,
  LoggedInUser,
  SecurityRoleKey,
} from '../../core/services/auth.service';
import { UitoolsService } from '../../core/services/uitools.service';
import { GroupFilterUploadComponent } from '../group-filter-upload/group-filter-upload.component';
import {
  DynamicGroupsService,
  Filter,
} from '../services/dynamic-groups.service';

@Component({
  selector: 'ug-group-filters-list',
  templateUrl: './group-filters-list.component.html',
  styleUrls: ['./group-filters-list.component.scss'],
})
export class GroupFiltersListComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private dynamicGroupsService = inject(DynamicGroupsService);
  private alertService = inject(AlertService);
  private uiService = inject(UitoolsService);
  private authService = inject(AuthService);
  private ngbModal = inject(NgbModal);

  exceptionData = {
    GET_ENTITY_FILTERS: {
      level: AlertLevels.ERROR,
      code: 'JGL-001',
      message: 'Error retrieving group filters',
    } as AlertData,
    DELETE_GROUP_FILTERS: {
      level: AlertLevels.ERROR,
      code: 'JGL-002',
      message: 'Error deleting group filter',
    } as AlertData,
  };

  tableHeaders: Array<TableHeader>;
  tableRows: Array<TableHeader>;
  headerButtons: Array<TableHeaderButton>;
  rowButtons: Array<TableRowButton>;
  filters: Array<FilterBase<any>> = [
    new TextboxFilter({
      key: 'searchTerm',
      label: 'Filter by term',
      order: 1,
      placeholder: 'Filter by term',
    }),
  ];
  tableLoading: boolean;
  filterList: any;
  ngUnsubscribe: Subject<boolean> = new Subject();
  editOrViewButtonText = '';
  showDeleteButton = false;
  showCreateButton = false;
  showEditOrViewButton = false;

  ngOnInit(): void {
    this.tableHeaders = [
      { id: 'id', title: 'Id', class: 'w-10' },
      { id: 'name', title: 'Name', class: 'w-25' },
      { id: 'description', title: 'Description', class: 'w-40' },
      { id: 'jobRoleCount', title: 'Job Roles', class: 'w-10' },
      {
        id: 'errors',
        title: '',
        iconFunction: this.hasError,
        iconTooltip: this.hasErrorTooltip,
      },
    ];
    this.headerButtons = [
      {
        title: 'Create Group',
        headerAction: this.createGroup,
        type: 'button',
        hideCondition: this.hideCreate,
      },
      {
        title: 'Upload',
        headerAction: this.groupUpload,
        type: 'button',
        hideCondition: this.hideCreate,
      },
    ];

    this.authService.loggedInUserSubj.subscribe((liu: LoggedInUser) => {
      this.showEditOrViewButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRolePeople,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => liu.roleIdentifier === sr);
      this.showDeleteButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRoleReqs,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => liu.roleIdentifier === sr);
      this.showCreateButton = [
        SecurityRoleKey.Admin,
        SecurityRoleKey.Superuser,
        SecurityRoleKey.JobRolePeople,
        SecurityRoleKey.JobRoleReqsPeople,
      ].some((sr) => liu.roleIdentifier === sr);
    });

    this.setGroupListTable();
  }

  createGroup = () => {
    this.router.navigate([`group-management/0`]);
  };

  editGroup = (group: Filter) => {
    const id = group.id;
    this.router.navigate([`group-management/${id}`]);
  };

  deleteGroup = (group: Filter) => {
    const id = group.id;
    this.dynamicGroupsService.deleteFilterById(id).subscribe(
      (df) => {
        this.filterList = this.filterList.filter((f) => f.id !== df.id);
        this.tableRows = this.filterList;
        this.uiService.showToast(
          `Successfully deleted group filter with Id: ${df.id}`,
          {
            classname: 'bg-success text-light',
            delay: 3000,
          },
        );
      },
      (err) =>
        this.alertService.createAlert2(
          this.exceptionData.DELETE_GROUP_FILTERS,
          err,
        ),
    );
  };

  hideCreate = () => {
    return !this.showCreateButton;
  };

  hideEditOrView = () => {
    return !this.showEditOrViewButton;
  };

  hideDelete = (group) => {
    return !(this.showDeleteButton && group.jobRoleIds.length === 0);
  };
  hasError = (group) => {
    return group.errorMessage ? 'fas fa-exclamation-triangle text-warning' : '';
  };

  hasErrorTooltip = (group) => {
    return group.errorMessage;
  };

  setGroupListTable() {
    this.tableLoading = true;
    this.dynamicGroupsService
      .getFiltersByEntity('Person')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (af) => {
          this.filterList = af;
          this.filterList.map((f) => {
            f.jobRoleCount = f.jobRoleIds.length;
          });
          this.editOrViewButtonText = this.showCreateButton
            ? 'Edit Group'
            : 'View Group';

          this.tableRows = this.filterList;

          if (this.showEditOrViewButton) {
            this.rowButtons = [
              {
                title: this.editOrViewButtonText,
                rowAction: this.editGroup,
                hideCondition: this.hideEditOrView,
              },
              {
                title: 'Delete',
                class: 'btn-outline-danger',
                rowAction: this.deleteGroup,
                hideCondition: this.hideDelete,
              },
            ];
          }

          this.tableLoading = false;
        },
        (err) =>
          this.alertService.createAlert2(
            this.exceptionData.GET_ENTITY_FILTERS,
            err,
          ),
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  groupUpload = () => {
    this.ngbModal.open(GroupFilterUploadComponent, { centered: true });
  };
}
