import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { ClaimService } from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { UitoolsService } from '../../core/services/uitools.service';
import {
  CsodExpressClassPayload,
  CsodExpressClassResponse,
  DashboardService,
  ExpressClassUserObs,
} from '../../dashboard/services/dashboard.service';
import { ProgressModalComponent } from '../../shared/progress-modal/progress-modal.component';
import {
  BulkAssessmentPayload,
  BulkAssessmentResponse,
  ClaimStatusLookup,
  EventSession,
  EventsService,
  SkillForEvent,
  TrainingOutcome,
} from '../services/events.service';

@Component({
  selector: 'ug-bulk-assessment',
  templateUrl: './bulk-assessment.component.html',
  styleUrls: ['./bulk-assessment.component.scss'],
})
export class BulkAssessmentComponent implements OnInit {
  @Input('id') assessmentId: number;
  private eventsService = inject(EventsService);
  private claimService = inject(ClaimService);
  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private router = inject(Router);
  private formBuilder = inject(FormBuilder);
  private uiService = inject(UitoolsService);
  private ngbModal = inject(NgbModal);
  private dashboardService = inject(DashboardService);
  private passAllOption = null;
  private finalScoreValueAll!: number;

  protected bulkAssessmentForm!: FormGroup;
  protected viewCompletedAssessment = false;
  protected selectAllChecked = false;
  protected finalAssessmentOptions!: TrainingOutcome[];
  protected competenciesForEvent!: SkillForEvent[];
  protected claimLevelsMap = new Map<number, any>();
  protected currentSession!: EventSession;
  protected selectedAttendees;
  protected instructors!: string;
  protected pageSize: number = 10;
  protected page: number = 1;
  protected pageSizeOptions: Array<number> = [5, 10, 25, 50, 100];
  protected initialPageSize = 10;
  protected expiresIn = 0;

  exceptionData = {
    SESSION_COMPETENCIES: {
      level: AlertLevels.ERROR,
      code: 'BA-001',
      message: 'Error retrieving session competencies',
    } as AlertData,
    CLAIM_LEVELS: {
      level: AlertLevels.ERROR,
      code: 'BA-002',
      message: 'Error retrieving claim levels',
    } as AlertData,
    CLAIM_STATUS: {
      level: AlertLevels.ERROR,
      code: 'BA-003',
      message: 'Error retrieving assessment options',
    } as AlertData,
    BULK_ASSESSMENT: {
      level: AlertLevels.ERROR,
      code: 'BA-004',
      message: 'Error saving bulk assessment',
    } as AlertData,
    EXPRESS_CLASS: {
      level: AlertLevels.ERROR,
      code: 'BA-005',
      message: 'Error creating Express Class',
    } as AlertData,
  };

  get people(): FormArray {
    return this.bulkAssessmentForm.get('people') as FormArray;
  }

  ngOnInit(): void {
    this.bulkAssessmentForm = this.formBuilder.group({
      trainingSessionId: [],
      expiryDate: [null, Validators.required],
      comments: [null],
      people: this.formBuilder.array([]),
    });

    this.bulkAssessmentForm
      .get('expiryDate')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.expiresIn = null;
      });

    if (this.eventsService.session) {
      this.currentSession = this.eventsService.session;
      this.selectedAttendees = this.eventsService.selectedAttendees;
      this.instructors = this.eventsService.session.instructors
        .map((i) => i.displayName)
        .join(', ');

      forkJoin([
        this.eventsService.getSkillsForEvent(this.currentSession.skillId).pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError((err) => {
            this.alertService.createAlert2(
              this.exceptionData.SESSION_COMPETENCIES,
              err,
            );
            return of(undefined);
          }),
        ),
        this.claimService.getAllClaimLevels().pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError((err) => {
            this.alertService.createAlert2(
              this.exceptionData.CLAIM_LEVELS,
              err,
            );
            return of(undefined);
          }),
        ),
        this.eventsService.getTrainingOutcomes().pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError((err) => {
            this.alertService.createAlert2(
              this.exceptionData.CLAIM_STATUS,
              err,
            );
            return of(undefined);
          }),
        ),
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          ([competenciesForEvent, claimLevels, finalAssessmentOptions]) => {
            this.competenciesForEvent = competenciesForEvent;
            this.finalAssessmentOptions = finalAssessmentOptions?.filter(
              (ao) => ao.isActive,
            );

            for (const key in claimLevels) {
              if (claimLevels.hasOwnProperty(key)) {
                const values = claimLevels[key].map((c) => ({
                  ...c,
                  key: Number(c.key),
                }));
                this.claimLevelsMap.set(Number(key), values);
              }
            }

            this.bulkAssessmentForm
              .get('trainingSessionId')
              .setValue(this.currentSession.id);

            this.selectedAttendees.forEach((person, i) => {
              const aiFormGroup = this.formBuilder.group({
                personId: [person.id],
                attended: [person.attended],
                trainingOutcomeId: [null],
                score: [null],
                comments: [null],
                skills: this.formBuilder.array([]),
                displayName: [person.displayName],
                selected: [false],
              });

              if (!person.attended) {
                aiFormGroup.get('selected').disable();
                aiFormGroup.get('comments').disable();
                aiFormGroup.get('score').disable();
                aiFormGroup.get('trainingOutcomeId').disable();
              }

              this.people.push(aiFormGroup);

              const compsArray = this.people.at(i).get('skills') as FormArray;
              this.competenciesForEvent.forEach((s) => {
                const cGroup = this.formBuilder.group({
                  skillId: s.skillId,
                  functionalAreaId: s.functionalAreaId,
                  claimLevelSetId: s.claimLevelSetId,
                  claimLevelId: [
                    person.attended ? s.claimLevelId : null,
                    Validators.required,
                  ],
                });

                if (!person.attended) {
                  cGroup.get('claimLevelId').disable();
                }

                compsArray.push(cGroup);
              });
            });
          },
        );
    } else {
      this.eventsService
        .getTrainingSessionById(this.assessmentId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((ts) => {
          this.currentSession = ts;
          this.instructors = ts.instructors
            .map((i) => i.displayName)
            .join(', ');
          if (this.currentSession.isSessionCompleted) {
            this.viewCompletedAssessment = true;
          }
        });
    }

    this.pageSize = this.initialPageSize;
  }

  gradeAll(event, index: number) {
    console.log(typeof event);
    const levelId = parseInt(event.target.value, 10)
      ? parseInt(event.target.value, 10)
      : null;
    this.people.controls.forEach((fc) => {
      if (fc.value.selected) {
        const ac = fc.get('skills') as FormArray;
        ac.at(index).get('claimLevelId').patchValue(levelId);
        ac.updateValueAndValidity();
      }
    });
  }

  passFailAll() {
    this.people.controls.forEach((fc) => {
      if (fc.value.selected) {
        fc.get('trainingOutcomeId').setValue(this.passAllOption);
      }
    });
  }

  // onPageChange($event) {
  //   console.log({ $event });
  //   this.page = $event;
  //   this.updateTableList();
  // }

  // onPageSizeChange($event) {
  //   this.pageSize = $event;
  //   this.page = 1;
  //   this.updateTableList();
  // }

  // updateTableList() {}

  postBulkAssessment(): void {
    const modalRef = this.ngbModal.open(ProgressModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.updatesTotal = 1;
    modalRef.componentInstance.modalTitle = 'Creating Bulk Assessment';

    const postData: BulkAssessmentPayload =
      this.bulkAssessmentForm.getRawValue();

    postData.people.forEach((p) => {
      // if person did not attend, set claim level id to be the lowest available for that skill
      if (!p.attended) {
        p.skills.forEach((sk) => {
          const claimLevelSet = this.claimLevelsMap.get(sk.claimLevelSetId);
          claimLevelSet.sort((a: ClaimStatusLookup, b: ClaimStatusLookup) => {
            return a.key < b.key ? -1 : 1;
          });
          sk.claimLevelId = claimLevelSet[0].key;
        });
      }
    });

    this.eventsService
      .postBulkAssessment(postData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ba: BulkAssessmentResponse) => {
          modalRef.componentInstance.updatesDone++;
          this.uiService.showToast(
            `Successfully completed the bulk assessment for session ${this.currentSession.title}`,
            { classname: 'bg-success text-light', delay: 3000 },
          );
          this.viewCompletedAssessment = true;
        },
        error: (error: HttpErrorResponse) => {
          modalRef.componentInstance.updatesFailed++;
          this.alertService.createAlert2(
            this.exceptionData.BULK_ASSESSMENT,
            error,
          );
        },
      });
  }

  finalScoreAll() {
    this.people.controls.forEach((fc) => {
      if (fc.value.selected) {
        fc.get('score').setValue(this.finalScoreValueAll);
      }
    });
  }

  selectAll() {
    this.people.controls.forEach((fc) => {
      if (fc.get('selected').enabled) {
        fc.get('selected').setValue(this.selectAllChecked);
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  backToEventsCalendar(): void {
    this.router.navigate([
      '/events-calendar',
      this.currentSession.skillId,
      this.currentSession.id,
    ]);
  }

  createExpressClass(
    postData: BulkAssessmentPayload,
  ): Observable<CsodExpressClassResponse> {
    const userStatusList = [];

    postData.people.forEach((person) => {
      userStatusList.push({
        externalId: this.selectedAttendees.find((p) => p.id === person.personId)
          .externalId,
        status: this.finalAssessmentOptions.find(
          (ao) => ao.id === person.trainingOutcomeId,
        ).isSuccess
          ? 'Passed'
          : 'Failed',
        score: person.score,
        comment: `${person.comments} - ${postData.comments}`,
      } as ExpressClassUserObs);
    });

    const eppl: CsodExpressClassPayload = {
      loId: this.currentSession.externalId,
      userStatus: userStatusList,
    } as CsodExpressClassPayload;

    return this.dashboardService.createCsodExpressClass(eppl);
  }

  peopleSelected() {
    return this.people.value.some((p) => p.selected);
  }

  updateExpiryDate(event) {
    const startDate = new Date();
    const startMonth = startDate.getMonth();
    const newDate = new Date(startDate.setMonth(startMonth + Number(event)));
    const patchValueDate = formatDate(newDate, 'yyyy-MM-dd', 'en-UK');
    this.bulkAssessmentForm.controls.expiryDate.patchValue(patchValueDate);
    this.expiresIn = event;
  }
}
