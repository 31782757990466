import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { provideTranslocoScope } from '@jsverse/transloco';
import { Subject, forkJoin, map, takeUntil } from 'rxjs';
import { OrgUnit } from '../../org-unit/models/org-unit';
import { OrgUnitService } from '../../org-unit/services/org-unit.service';
import { JobRoleDetailTypeName } from '../job-role-list/role-management/role-management.service';
import {
  JobRoleDetail,
  JobRoleDetailType,
  JobRoleDotNet,
  JobRoleService,
} from '../services/job-role.service';

@Component({
  selector: 'ug-job-role-details-summary',
  templateUrl: './job-role-details-summary.component.html',
  styleUrls: ['./job-role-details-summary.component.scss'],
  providers: [provideTranslocoScope('job-role-details')],
})
export class JobRoleDetailsSummaryComponent implements OnInit {
  private jobRoleService = inject(JobRoleService);
  private orgUnitService = inject(OrgUnitService);

  @Input() jobRoleId: number;
  jobRole: JobRoleDotNet;
  jobRoleDetails: Array<JobRoleDetail>;
  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  ouLocations: Array<OrgUnit>;
  jobRoleSummary: any = {};
  isLoading: boolean;
  jobRoleDetailTypes: Array<JobRoleDetailType>;

  ngOnInit(): void {
    const observables = [
      this.orgUnitService.getExtOrgUnitsByType('Location'),
      this.jobRoleService.getJobRoleDetailTypes(),
    ];

    forkJoin(observables)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(([locationOus, detailTypes]) => ({
          locationOus,
          detailTypes,
        })),
      )
      .subscribe((ob) => {
        this.ouLocations = ob.locationOus as OrgUnit[];
        this.jobRoleDetailTypes = ob.detailTypes as JobRoleDetailType[];
        if (this.jobRoleId) {
          this.loadJobRoleProfile();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['jobRoleId'] &&
      changes['jobRoleId'].currentValue &&
      changes['jobRoleId'].previousValue &&
      changes['jobRoleId'].currentValue !== changes['jobRoleId'].previousValue
    ) {
      this.loadJobRoleProfile();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private getRoleDetail(typeName: string) {
    const typeId = this.jobRoleDetailTypes?.find(
      (dt) => dt.name === typeName,
    )?.id;
    return (
      this.jobRoleDetails?.find((jrd) => jrd.jobRoleDetailTypeId === typeId) ||
      null
    );
  }

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  loadJobRoleProfile() {
    this.isLoading = true;
    const getJrObservables = [
      this.jobRoleService.getJobRoleDotNet(this.jobRoleId),
      this.jobRoleService.getJobRoleDetails(this.jobRoleId),
    ];

    forkJoin(getJrObservables)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(([jobRole, jobRoleDetails]) => ({
          jobRole,
          jobRoleDetails,
        })),
      )
      .subscribe((jro) => {
        this.jobRole = jro.jobRole as JobRoleDotNet;
        this.jobRoleDetails = jro.jobRoleDetails as JobRoleDetail[];
        this.jobRoleSummary = {
          name: this.jobRole.name,
          location: this.jobRole.locationId
            ? this.ouLocations?.find((f) => f.id === this.jobRole.locationId)
                .name
            : null,
          about: this.getRoleDetail(JobRoleDetailTypeName.about)?.detail,
          description: this.jobRole.description,
          skillsAndExperience: this.getRoleDetail(
            JobRoleDetailTypeName.skillsAndExperience,
          )?.detail,
          keyResponsibilities: this.getRoleDetail(
            JobRoleDetailTypeName.keyResponsibilities,
          )?.detail,
          keyPerformanceIndicators: this.getRoleDetail(
            JobRoleDetailTypeName.keyPerformanceIndicators,
          )?.detail,
          regulatoryRequirements: this.getRoleDetail(
            JobRoleDetailTypeName.regulatoryRequirements,
          )?.detail,
        };
        this.isLoading = false;
      });
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => {
    return 0;
  };

  isNotEmptyHtml(value: string) {
    return value !== '<p></p>';
  }
}
