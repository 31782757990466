import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ClaimLevel } from '../../claim/models/claim-level';
import { FuncArea } from '../models/func-area';
import { Skill } from '../models/skill';
import { SkillTrainingSession } from '../skill-training/skill-training-session';

const apiServerUri = environment.apiUrl;
const dotNetServerUri = environment.serverUrl;

export class SkillDimension {
  id: number;
  name: string;
}

export interface SkillLookup {
  hierarchyKey: string;
  key: string;
  name: string;
  parentKey: string;
}

export interface SkillFilterPayload {
  dimensionId: number;
  subjectId: number;
}

export interface SkillSearchBody {
  dimensionId: number;
  name: string;
  maxCount: number;
  types: string[];
  subjectId?: number;
}

export interface SkillSearchDictionary {
  [key: string]: SkillSearch[];
}

export interface SkillSearch {
  id: number;
  name: string;
  description?: string;
  externalGuid: string;
  type: string;
  version: string;
  provider: string;
  owner: string;
  functionalAreaId?: number;
  claimLevelSetId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  private http = inject(HttpClient);

  disciplineFeed = new BehaviorSubject<Array<FuncArea>>([]);
  skillsFeeds = {};

  getSkillsFeed(
    parentFuncAreaId: number,
    disciplineId: number,
  ): BehaviorSubject<Array<Skill>> {
    if (!this.skillsFeeds[disciplineId]) {
      this.skillsFeeds[disciplineId] = new BehaviorSubject<Array<Skill>>([]);
      this.getSkills(parentFuncAreaId, disciplineId).subscribe(
        (s) => this.skillsFeeds[disciplineId].next(s),
        (err) => {
          return throwError(() => new Error(err));
        },
      );
    }
    return this.skillsFeeds[disciplineId];
  }

  getSkill(skillId: number): Observable<Skill> {
    return this.http.get(`${apiServerUri}/skill/${skillId}`).pipe(
      map((resp) => {
        return resp['skills'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getSkills(
    parentFuncAreaId: number,
    disciplineFuncAreaId: number,
  ): Observable<Array<Skill>> {
    return this.http
      .get(`${apiServerUri}/skills/${parentFuncAreaId}/${disciplineFuncAreaId}`)
      .pipe(
        map((resp) => {
          return resp['skills'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getFuncArea(funcAreaId: number): Observable<Array<FuncArea>> {
    return this.http.get(`${apiServerUri}/func-area/${funcAreaId}`).pipe(
      map((resp) => {
        return resp['funcArea'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getCompDimensions(): Observable<Array<FuncArea>> {
    return this.http.get(`${apiServerUri}/func-area/top`).pipe(
      map((resp) => {
        return resp['funcAreas'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getTopCompDimension(skillId: number): Observable<FuncArea> {
    return this.http.get(`${apiServerUri}/func-area/top/skill/${skillId}`).pipe(
      map((resp) => {
        return resp['funcArea'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getChildFuncAreas(parentFuncAreaId: number): Observable<Array<FuncArea>> {
    return this.http
      .get(`${apiServerUri}/child-func-areas/${parentFuncAreaId}`)
      .pipe(
        map((resp) => {
          return resp['funcAreas'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getLevels(skillId: number): Observable<Array<ClaimLevel>> {
    return this.http.get(`${apiServerUri}/skill/${skillId}/levels`).pipe(
      map((resp) => {
        return resp['claimsLevels'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getCompetencyAttrs(funcAreaId: number): Observable<Array<Skill>> {
    return this.http
      .get(`${apiServerUri}/func-area/${funcAreaId}/competency-attrs`)
      .pipe(
        map((resp) => {
          return resp['competencyAttrs'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  listTrainingSessions(
    skillId: number,
  ): Observable<Array<SkillTrainingSession>> {
    return this.http
      .get(`${apiServerUri}/dashboard/skill/${skillId}/training_sessions`)
      .pipe(
        map((resp) => {
          return resp['skillTrainingSession'] || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  listCatalogSearchItems(
    searchText: string,
    distinctNamesOnly: boolean = false,
  ): Observable<Array<Skill>> {
    let params: HttpParams = new HttpParams();
    params = params.append('search_text', searchText);
    return this.http
      .get(`${apiServerUri}/functions/csod/catalog-search`, { params: params })
      .pipe(
        map((resp) => {
          const trainingItems = (resp['trainingItems'] || []) as Skill[];
          if (!distinctNamesOnly) {
            return trainingItems;
          }

          let filtererdItems: Skill[] = [];
          trainingItems.forEach((ti) => {
            if (!filtererdItems.find((fi) => fi.name === ti.name)) {
              filtererdItems.push(ti);
            }
          });
          return filtererdItems;
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsByDimensionIdForJobRoleIdPaged(
    dimensionId: number,
    jobRoleId: number,
    filterData: any,
  ) {
    return this.http
      .post(
        `${dotNetServerUri}/skills/byDimensionIdForJobRoleId/${dimensionId}/${jobRoleId}/paged`,
        filterData,
      )
      .pipe(
        map((resp) => {
          return (resp as any) || [];
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillDimensions(): Observable<Array<SkillDimension>> {
    return this.http.get(`${dotNetServerUri}/skillDimensions`).pipe(
      map((resp) => {
        return (resp as any) || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getSkillsByExternalType(externalType: string): Observable<SkillLookup[]> {
    return this.http
      .get<
        SkillLookup[]
      >(`${dotNetServerUri}/skills/lookups/byExternalType/${externalType} `)
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsWithExistingSessions(): Observable<SkillLookup[]> {
    return this.http
      .get<
        SkillLookup[]
      >(`${dotNetServerUri}/skills/lookups/forTrainingSessions`)
      .pipe(
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }

  getSkillsByJobRoleSearch(
    filterData: SkillSearchBody,
  ): Observable<SkillSearchDictionary> {
    return this.http.post<SkillSearchDictionary>(
      `${dotNetServerUri}/skills/byJobRoleSearch`,
      filterData,
    );
  }

  getSkillsByAnalyticsSearch(
    filterData: SkillSearchBody,
  ): Observable<SkillSearchDictionary> {
    return this.http.post<SkillSearchDictionary>(
      `${dotNetServerUri}/skills/byAnalyticsSearch`,
      filterData,
    );
  }
}
