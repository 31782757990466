import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ug-role-due-date-settings',
  templateUrl: './role-due-date-settings.component.html',
  styleUrl: './role-due-date-settings.component.scss',
})
export class RoleDueDateSettingsComponent {
  @Input() viewSettings = false;
  @Input() trainingLength = 0;
  @Input() settingsFormGroup: FormGroup;
  @Output() showSelectBoxEvent = new EventEmitter<boolean>();
  @Output() confirmSettingsEvent = new EventEmitter<any>();
  @Input() allowEdit = false;

  protected editingSettings = false;
  protected selectIndividualRequirements = false;
  protected assignmentRule = 'replace';

  ngOnInit() {
    this.settingsFormGroup.disable();
  }

  confirmSettings() {
    this.confirmSettingsEvent.emit(this.assignmentRule);
    this.selectIndividualRequirements = false;
    this.assignmentRule = 'replace';
    this.settingsFormGroup.disable({ onlySelf: true });
    this.editingSettings = false;
  }

  editSettings() {
    this.editingSettings = true;
    this.settingsFormGroup.enable();
  }

  cancel() {
    this.editingSettings = false;
    this.settingsFormGroup.disable();
    this.showSelectBoxEvent.emit(false);
  }

  selectIndividualRequirementsChange($event) {
    this.showSelectBoxEvent.emit($event.checked);
  }
}
