import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Assessor } from '../models/assessor';

const apiServerUri = environment.apiUrl;
const serverUrl = environment.serverUrl;

@Injectable({
  providedIn: 'root',
})
export class AssessorService {
  private http = inject(HttpClient);

  getAssessor(assessorId: number): Observable<Assessor> {
    return this.http.get(`${apiServerUri}/assessor/${assessorId}`).pipe(
      map((resp) => {
        return resp['assessor'] || {};
      }),
      catchError((err) => {
        return throwError(() => new Error(err));
      }),
    );
  }

  getAssessorByFuncAreaOrgUnit(
    funcArea: number,
    orgUnit: number,
  ): Observable<Array<Assessor>> {
    return this.http
      .get(
        `${apiServerUri}/func-area/${funcArea}/org-unit/${orgUnit}/assessors`,
      )
      .pipe(
        map((resp) => {
          return resp['funcAreaAssessor'] || {};
        }),
        catchError((err) => {
          return throwError(() => new Error(err));
        }),
      );
  }
}
