<div class="card">
  <div class="card-body">
    <h5 class="card-title">Audit history</h5>
    <ug-table
      [tableHeaders]="headers"
      [tableRows]="auditItems"
      [tableFilters]="filters"
      [tableCollapsedRowTitle]="'Filter Summary'"
      [tableCollapsedRowData]="tableCollapsedRowData"
      [tableCollapsedRowSubHeadings]="tableCollapsedRowSubHeadings"
      [isLoading]="loadingData"
    ></ug-table>
  </div>
</div>
