import { Component, DestroyRef, Input, OnChanges, inject } from '@angular/core';

import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { ClaimService } from '../services/claim.service';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Claim } from '../models/claim';
import { ClaimHistory } from '../models/claim-history';

@Component({
  selector: 'ug-claim-history',
  templateUrl: './claim-history.component.html',
  styleUrls: [],
})
export class ClaimHistoryComponent implements OnChanges {
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);
  private destroyRef = inject(DestroyRef);

  @Input() claimHistoryList: ClaimHistory[];
  @Input() claim: Claim;
  @Input() claimRowVersion: number;

  exceptionData = {
    CLAIM_HISTORY: {
      level: AlertLevels.ERROR,
      code: 'CHI-001',
      message: 'Error retrieving claim history',
      translationKey: 'errRetrievingAssessmentReqHistory',
    } as AlertData,
  };

  ngOnChanges() {
    if (this.claim && this.claim['id']) {
      this.claimService
        .getClaimHistory(this.claim.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (ch) => (this.claimHistoryList = ch),
          error: (err) => {
            this.alertService.createAlert2(
              this.exceptionData.CLAIM_HISTORY,
              err,
            );
          },
        });
    }
  }
}
