import { Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { AlertLevels, AlertService } from '../../core/services/alert.service';

@Component({
  selector: 'esqep-attachment-select-form', // tslint:disable-line:component-selector
  templateUrl: './attachment-select-form.component.html',
  styleUrls: ['./attachment-select-form.component.scss'],
})
export class AttachmentSelectFormComponent implements OnInit {
  private alertService = inject(AlertService);
  private fb = inject(FormBuilder);

  @Input() parentFormGroup: FormGroup;

  ngOnInit() {}

  onEviFileChange(event) {
    const allowedFileTypes = ['image/jpeg', 'application/pdf', 'image/png'];
    // this.logging.newMsg('Evidence file has changed', 'INFO');
    if (
      event.target &&
      allowedFileTypes.indexOf(event.target.files[0].type) === -1
    ) {
      this.alertService.createAlert(
        AlertLevels.WARNING,
        `File of type ${event.target.files[0].type} is not allowed. Please select a different file`,
        'CED-010',
      );
    } else {
      const evidenceArray = this.parentFormGroup.get(
        'evidenceFiles',
      ) as FormArray;
      evidenceArray.push(this.fb.control(event.target.files[0]));
    }
  }

  get evidenceFileList(): any {
    return this.parentFormGroup.get('evidenceFiles').value;
  }
}
