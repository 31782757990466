import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, concatWith, from } from 'rxjs';
import { ClaimDetailGuard } from '../../claim/guards/claim-detail.guard';
import { PreAuthGuard } from '../../guards/pre-auth.guard';

@Injectable({
  providedIn: 'root',
})
export class MetaClaimGuard {
  private authGuard = inject(PreAuthGuard);
  private claimDetailGuard = inject(ClaimDetailGuard);

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const authCanActivate = from(this.authGuard.canActivate(next, state));
    const claimDetailCanActivate = this.claimDetailGuard.canActivate(
      next,
      state,
    );

    return authCanActivate.pipe(concatWith(claimDetailCanActivate));
  }
}
