import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TableHeader } from '../../controls/table/table.service';

@Component({
  selector: 'ug-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
})
export class ProgressModalComponent implements OnInit {
  modal = inject(NgbActiveModal);

  @Input() updatesDone = 0;
  @Input() modalTitle: string;
  @Input() updatesFailed = 0;
  @Input() updatesTotal = 1;
  @Input() failedSections = [];

  headers: Array<TableHeader>;

  get updatesInProgress() {
    return this.updatesTotal > this.updatesFailed + this.updatesDone;
  }

  get failedSectionRows(): Array<any> {
    return this.failedSections.map((m) => {
      return {
        status: 'text-center fas fa-xl fa-times-circle text-danger',
        sectionName: m.sectionName,
        description: m.description,
      };
    });
  }

  ngOnInit(): void {
    this.headers = [
      {
        id: 'status',
        title: 'Status',
        iconFunction: this.failedSectionIcon,
        class: 'text-center',
      },
      { id: 'sectionName', title: 'Section' },
      { id: 'description', title: 'Description' },
    ];
  }

  failedSectionIcon = (row) => {
    return 'text-center fas fa-xl fa-times-circle text-danger';
  };
}
