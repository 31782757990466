<div class="card shadow overflow-visible">
  <div class="card-body">
    <ng-container>
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeTab"
        [destroyOnHide]="false"
        class="nav-justified"
      >
        <li [ngbNavItem]="1">
          <a ngbNavLink class="text-center mb-2">
            <p class="h6">Competency Assignment</p>
            <p class="h6">
              <small>Assign competencies to a training event</small>
            </p>
          </a>
          <ng-template ngbNavContent>
            <div class="p-3">
              <div class="border-bottom">
                <div class="training-dropdown-container">
                  <label id="trainingSubject">Select the training event</label>
                  <ng-select
                    [items]="trainingSubjects"
                    bindLabel="name"
                    bindValue="key"
                    [(ngModel)]="selectedEvent"
                    (change)="onChange($event)"
                    [clearable]="false"
                  >
                  </ng-select>
                </div>
                <div class="mb-3">
                  <button
                    [hidden]="readOnlyView"
                    [disabled]="!selectedEvent"
                    (click)="openAddCompetenciesModal()"
                    class="btn esqepBtn"
                  >
                    Add Competencies
                  </button>
                </div>
              </div>
              <div class="mt-3">
                <ug-table
                  [tableNoDataMessage]="noDataMessage"
                  [tableHeaders]="tableHeaders"
                  [tableRows]="tableRows"
                  [tableFilters]="tableFilters"
                  [tableRowButtons]="tableRowButtons"
                  [showPagination]="true"
                  [isLoading]="tableLoading"
                  [totalRecords]="skillCount"
                  [existsKey]="'existsInEvent'"
                  [tableRowDropdowns]="claimLevels"
                  [tableSelectedButtons]="tableSelectedButtons"
                  [disableAddSelected]="false"
                >
                </ug-table>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="text-center mb-2">
            <p class="h6">Assessment Outcomes</p>
            <p class="h6">
              <small>Manage assessment outcomes for all events</small>
            </p>
          </a>
          <ng-template ngbNavContent>
            <ug-assessment-options
              [readOnlyView]="readOnlyView"
            ></ug-assessment-options>
          </ng-template>
        </li>
        <div [ngbNavOutlet]="nav" class="content w-100"></div>
      </ul>
    </ng-container>
  </div>
</div>

<ug-toasts aria-live="polite" aria-atomic="true"></ug-toasts>
