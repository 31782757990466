import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClaimHistory } from '../../claim/models/claim-history';
import { ClaimService, DotNetClaim } from '../../claim/services/claim.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';

@Component({
  selector: 'esqep-history-table', // tslint:disable-line: component-selector
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
})
export class HistoryTableComponent implements OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private claimService = inject(ClaimService);

  @Input() claimHistoryList: Array<ClaimHistory>;
  @Input() claim: DotNetClaim;

  exceptionData = {
    CLAIM_HISTORY: {
      level: AlertLevels.ERROR,
      code: 'CHI-001',
      message: 'Error retrieving claim history',
      translationKey: 'errRetrievingAssessmentReqHistory',
    } as AlertData,
  };

  claimHistorySubs = new Subscription();

  ngOnChanges() {
    if (this.claim && this.claim.id) {
      this.claimHistorySubs = this.claimService
        .getClaimHistory(this.claim.id)
        .subscribe(
          (ch) => (this.claimHistoryList = ch),
          (err) => {
            this.alertService.createAlert2(
              this.exceptionData.CLAIM_HISTORY,
              err,
            );
          },
        );
    }
  }

  ngOnDestroy() {
    this.claimHistorySubs.unsubscribe();
  }
}
