<div class="card shadow" *ngIf="menuItems.length && userSecurityRoles.length">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div>
        <h5 class="card-title">User Security Access Guidance</h5>
        <h6 class="card-subtitle mb-2 text-muted">
          Review what each security role is allowed to access by default across
          the platform
        </h6>
      </div>
      <div>
        <button
          class="btn toggle-row p-0 me-3"
          (click)="tableCollapsed = !tableCollapsed"
        >
          <span
            class="fas"
            [ngClass]="!tableCollapsed ? 'fa-chevron-down' : 'fa-chevron-right'"
          ></span>
        </button>
      </div>
    </div>
    <table class="table table-responsive" [ngbCollapse]="!tableCollapsed">
      <thead>
        <tr>
          <th scope="col" class="menu-item-column"></th>
          <th
            *ngFor="let sr of userSecurityRoles"
            scope="col"
            class="text-center security-role-header"
          >
            <div class="d-flex justify-content-center align-items-end">
              <div class="me-1">
                {{ sr.name }}
              </div>
              <div>
                <span
                  class="fas fa-solid fa-circle-info"
                  [ngbTooltip]="sr.description"
                  tooltipClass="my-custom-class"
                >
                </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let item of menuItems; index as i">
        <tr *ngIf="!item.hideItem" class="menu-item">
          <th class="border-end bg-light">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                {{ item.itemName }}
              </div>
              <button
                *ngIf="item.subMenu"
                class="btn toggle-row p-0 me-3"
                (click)="toggleRow(i)"
              >
                <span
                  class="fas"
                  [ngClass]="
                    !collapsedRowMap.get(i)
                      ? 'fa-chevron-down'
                      : 'fa-chevron-right'
                  "
                ></span>
              </button>
            </div>
          </th>
          <td
            *ngFor="let sr of userSecurityRoles"
            class="text-center bg-light is-accessible-icon"
          >
            <span
              class="text-center align-middle"
              [ngClass]="
                menuItemMap.get(item.itemName + ' ' + sr.roleIdentifier)
                  .isAccessible
                  ? 'fas fa-check'
                  : 'fas fa-times'
              "
            ></span>
          </td>
        </tr>
        <ng-container *ngIf="item.subMenu">
          <ng-container *ngFor="let subitem of item.subMenu">
            <tr
              *ngIf="!subitem.hideItem"
              [ngbCollapse]="!collapsedRowMap.get(i)"
              class="div"
            >
              <th class="border-end fw-normal">
                <span>{{ subitem.itemName }}</span>
              </th>
              <td
                *ngFor="let sr of userSecurityRoles"
                class="text-center is-accessible-icon"
              >
                <ng-container
                  *ngIf="
                    !menuItemMap.get(subitem.itemName + ' ' + sr.roleIdentifier)
                      .readOnly;
                    else readOnly
                  "
                >
                  <span
                    class="text-center align-middle"
                    [ngClass]="
                      menuItemMap.get(
                        subitem.itemName + ' ' + sr.roleIdentifier
                      ).isAccessible
                        ? 'fas fa-check'
                        : 'fas fa-times'
                    "
                  ></span>
                </ng-container>
                <ng-template #readOnly>
                  <div>Read Only</div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
