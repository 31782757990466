<div [formGroup]="parentFormGroup">
  <div class="row">
    <div class="col-12 col-lg-6">
      <esqep-statement-form
        *ngIf="!claimantView || evidenceFG.get('statementText').enabled"
        [parentFormGroup]="evidenceFG"
        [esqepTitle]="'Statement of evidence'"
        [statementText]="evidenceStatementText"
        [componentIndex]="'ES1'"
      ></esqep-statement-form>
      <esqep-statement-list-form
        *ngIf="editMode"
        [parentFormGroup]="evidenceFG"
        [claimEvidenceList]="evidenceList"
        [claimantView]="claimantView"
      ></esqep-statement-list-form>
    </div>
    <div class="col-12 col-lg-6">
      <div *ngIf="editMode">
        <ug-claim-file-list [claimId]="claimId"></ug-claim-file-list>
      </div>
      <div *ngIf="!editMode">
        <esqep-attachment-select-form
          [parentFormGroup]="evidenceFG"
        ></esqep-attachment-select-form>
      </div>
    </div>
  </div>
</div>
