export class OrgUnit {
  description: string;
  id: number;
  name: string;
  parentOrgUnitDescription: string;
  parentOrgUnitId: number;
  parentOrgUnitName: string;
  externalSource: string;
  externalId: string;
  externalType: string;
  externalActive: boolean;
  externalParentId: string;
  lastModified: Date;
}

export class BasicOrgUnit {
  description: string;
  id: number;
  name: string;
  orgUnitTypeName: string;
}
