import { PerfGridMeta } from '../perf-grid/perf-grid-meta';

export enum DisplayType {
  Percentage,
  Category,
  Value,
  Integer,
}

export enum InputControlType {
  Numeric,
  Radio,
}

export class PerfGridRatingCriterion {
  fromValue: number;
  toValue: number;
  description: string;
  description0: (x?: number, y?: number) => string;

  // title(displayType: DisplayType, fromValue?: number, toValue?: number): string {
  //     if (displayType === DisplayType.Percentage) {
  //         return ()
  //     }
  // }

  constructor(init?: Partial<PerfGridRatingCriterion>) {
    Object.assign(this, init);
  }

  ratingInRange(ratingValue: number) {
    if (this.fromValue === null) {
      return ratingValue < this.toValue;
    }
    if (this.toValue === null) {
      return ratingValue >= this.fromValue;
    }
    return ratingValue < this.toValue && ratingValue >= this.fromValue;
  }
}

export class PerfGridRating {
  name: string;
  ratingValue: number;
  ratingCriteria: Array<PerfGridRatingCriterion> = [];
  displayType: DisplayType = DisplayType.Percentage;
  allowInput = false;
  hoistRating = false;

  constructor(init?: Partial<PerfGridRating>) {
    Object.assign(this, init);
  }

  ratingValue2?(callback: () => number): number;

  get displayValue(): string {
    let desc = `${roundPercent(this.ratingValue * 100)}%`;
    if (this.displayType === DisplayType.Category) {
      // desc = this.ratingCriteria.find(rc => rc.ratingInRange(this.ratingValue)).description0(this.ratingValue);
      desc = this.ratingCriteria.find((rc) =>
        rc.ratingInRange(this.ratingValue)
      ).description;
    } else if (this.displayType === DisplayType.Value) {
      desc = `${this.ratingValue.toFixed(2)}`;
    } else if (this.displayType === DisplayType.Integer) {
      desc = `${this.ratingValue.toFixed(0)}`;
    }
    return desc;
  }

  get scoreIdx(): number {
    const idx = this.ratingCriteria.findIndex((rc) =>
      rc.ratingInRange(this.ratingValue)
    );
    return idx !== -1 ? idx : 0;
  }

  get InputType(): InputControlType {
    if (this.allowInput) {
      if (this.displayType === DisplayType.Percentage) {
        return InputControlType.Numeric;
      } else {
        return InputControlType.Radio;
      }
    } else {
      return null;
    }
  }
}

export class PerfGridContent {
  userId?: number;
  userFirstName?: string;
  userLastName?: string;
  personId?: number;
  jobRoleId?: number;

  name?: string;

  metadata: PerfGridMeta = new PerfGridMeta();
  ratings: Array<PerfGridRating> = new Array<PerfGridRating>();

  public constructor(init?: Partial<PerfGridContent>) {
    Object.assign(this, init);
  }

  get totalScore(): number {
    return this.ratings
      .map((r) => this.metadata.Categories[r.scoreIdx].score)
      .reduce((a, c) => a + c, 0);
  }

  get hoistedRatingIdx(): number {
    // let hoistedRatings: Array<PerfGridRating> = this.ratings.filter(r => r.hoistRating);
    const hoistedRatingIdxs = this.ratings
      .filter((r) => r.hoistRating)
      .map((hr) => hr.scoreIdx);
    return hoistedRatingIdxs.length > 0 ? Math.max(...hoistedRatingIdxs) : -1;
    // return Math.max(...this.ratings.filter(r => r.hoistRating).map(hr => hr.scoreIdx))
  }

  get highestRatingIdx(): number {
    return Math.max(...this.ratings.map((hr) => hr.scoreIdx));
  }

  get moderatedRatingScoreIdx(): number {
    // if (this.perfGridContent.hoistedRatingIdx === -1 || ) {
    try {
      if (
        this.hoistedRatingIdx === -1 ||
        this.hoistedRatingIdx <= this.cOverallRating.scoreIdx
      ) {
        if (this.cOverallRating.scoreIdx === 0 && this.highestRatingIdx > 1) {
          return this.cOverallRating.scoreIdx + 1;
        } else {
          return this.cOverallRating.scoreIdx;
        }
      }
    } catch {
      // return -1;
      return this.cOverallRating.scoreIdx;
    }

    // if (this.perfGridContent.hoistedRatingIdx < this.overallRating.scoreIdx) {
    //   return this.overallRating.scoreIdx;
    // }

    return this.hoistedRatingIdx;
    // return this.cOverallRating.scoreIdx;
  }

  get cOverallRating(): Partial<PerfGridRating> {
    // const jj: Partial<PerfGridRating> = {
    //   name: 'SCORE',
    //   ratingValue: this.totalScore,
    //   ratingValue2: () => {return this.totalScore},
    //   displayType: DisplayType.Category,
    //   ratingCriteria: [
    //     new PerfGridRatingCriterion(
    //       {
    //         // fromValue: 220,
    //         fromValue: this.ratings.length * 40 * 0.61,
    //         toValue: null,
    //         description0: (fromValue, toValue) => { return 'OUTSTANDING' },
    //         description: 'OUTSTANDING'
    //       }
    //     ),
    //     new PerfGridRatingCriterion(
    //       {
    //         // fromValue: 180,
    //         // toValue: 220,
    //         fromValue: this.ratings.length * 40 * 0.5,
    //         toValue: this.ratings.length * 40 * 0.61,
    //         description0: (fromValue, toValue) => { return 'GOOD' },
    //         description: 'GOOD'
    //       }
    //     ),
    //     new PerfGridRatingCriterion(
    //       {
    //         // fromValue: 99,
    //         // toValue: 180,
    //         fromValue: this.ratings.length * 40 * 0.275,
    //         toValue: this.ratings.length * 40 * 0.5,
    //         description0: (fromValue, toValue) => { return 'REQUIRES IMPROVEMENT' },
    //         description: 'REQUIRES IMPROVEMENT'
    //       }
    //     ),
    //     new PerfGridRatingCriterion(
    //       {
    //         fromValue: null,
    //         toValue: this.ratings.length * 40 * 0.275,
    //         description0: (fromValue, toValue) => { return 'SPECIAL MEASURES' },
    //         description: 'SPECIAL MEASURES'
    //       }
    //     ),
    //   ]
    // }

    const jj: PerfGridRating = new PerfGridRating();

    jj.name = 'SCORE';
    jj.ratingValue = this.totalScore;
    jj.ratingValue2 = () => {
      return this.totalScore;
    };
    jj.displayType = DisplayType.Category;

    jj.ratingCriteria.push(
      new PerfGridRatingCriterion({
        fromValue: this.ratings.length * 40 * 0.61,
        toValue: null,
        description0: (fromValue, toValue) => {
          return 'OUTSTANDING';
        },
        description: 'OUTSTANDING',
      })
    );
    jj.ratingCriteria.push(
      new PerfGridRatingCriterion({
        fromValue: this.ratings.length * 40 * 0.5,
        toValue: this.ratings.length * 40 * 0.61,
        description0: (fromValue, toValue) => {
          return 'GOOD';
        },
        description: 'GOOD',
      })
    );
    jj.ratingCriteria.push(
      new PerfGridRatingCriterion({
        fromValue: this.ratings.length * 40 * 0.275,
        toValue: this.ratings.length * 40 * 0.5,
        description0: (fromValue, toValue) => {
          return 'REQUIRES IMPROVEMENT';
        },
        description: 'REQUIRES IMPROVEMENT',
      })
    );
    jj.ratingCriteria.push(
      new PerfGridRatingCriterion({
        fromValue: null,
        toValue: this.ratings.length * 40 * 0.275,
        description0: (fromValue, toValue) => {
          return 'SPECIAL MEASURES';
        },
        description: 'SPECIAL MEASURES',
      })
    );

    return jj;
  }

  get cModeratedRating(): string {
    try {
      return this.cOverallRating.ratingCriteria[
        this.moderatedRatingScoreIdx
      ].description0();
    } catch {
      return '';
    }
  }
}

export class PerfMgmtResponse {
  me: PerfGridContent;
  team: Array<PerfGridContent> = [];
}

export function roundPercent(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
