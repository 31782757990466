<form [formGroup]="filterFormGroup" class="form bg-light border ps-1 pe-1 pb-1">
  <strong class="form-text">Filter by</strong>
  <div formArrayName="filterControls" class="row">
    <div
      *ngFor="
        let control of filterFormGroup.controls['filterControls']['controls'];
        let i = index
      "
      class="col-sm-12 col-md-3 mb-2"
    >
      <label for="filter{{ i }}" class="">{{
        (jobFamilyTypeList[i] | lowercase) ?? jobFamilyTypeList[i] | titlecase
      }}</label>
      <select
        class="form-select form-select-sm"
        [formControlName]="i"
        (change)="onSelectChange(i)"
        id="filter{{ i }}"
      >
        <option [value]="null" selected disabled>Show All</option>
        <option
          *ngFor="let cjf of childJobFamily[i]; let j = index"
          [value]="j"
        >
          {{ cjf.name }}
        </option>
      </select>
    </div>
    <div
      *ngIf="filterFormArray?.at(0)?.value !== null"
      class="col-sm-12 col-md-3 align-self-end mb-2"
    >
      <button
        [disabled]="!enableResetButton"
        class="btn btn-sm esqepBtn"
        (click)="onResetClick()"
      >
        Reset
      </button>
    </div>
  </div>
</form>
