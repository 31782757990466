<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">Add People to Job Role</h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div [formGroup]="peopleFiltersForm" class="mb-2">
      <div class="row">
        <div class="col-6">
          <label class="form-label" for="filterTypeSelect">Filter by</label>
          <select
            class="form-select"
            id="filterTypeSelect"
            formControlName="filterType"
          >
            <option value="People">People</option>
            <option
              *ngFor="let orgUnitType of orgUnitTypes; let i = index"
              [value]="orgUnitType.key"
            >
              {{ orgUnitType.name }}
            </option>
          </select>
        </div>
        <div class="col-6" *ngIf="!peopleFilterDisabled">
          <label for="people-filter" class="form-label">Filter by name</label>
          <input
            formControlName="peopleFilter"
            id="people-filter"
            type="textbox"
            class="form-control"
            placeholder="Enter person name to search by"
          />
        </div>

        <div
          *ngIf="peopleFiltersForm.value.filterType && peopleFilterDisabled"
          class="col-6 align-self-end"
        >
          <ug-dropdown-select
            [ngbTypeahead]="orgUnitSearch"
            [resultFormatter]="orgUnitFormatter"
            [inputFormatter]="orgUnitFormatter"
            [inlineGroup]="false"
            formControlName="orgUnitFilter"
            placeholder="Select criteria"
          >
          </ug-dropdown-select>
        </div>
      </div>
    </div>
    <ug-table
      [tableHeaders]="tableHeaders"
      [tableRows]="tableRows"
      [tableFilters]="tableFilters"
      [tableSelectedButtons]="tableSelectedButtons"
      [showSelectBox]="true"
      [showPagination]="true"
      [isLoading]="tableLoading"
      [totalRecords]="peopleCount"
    >
    </ug-table>
  </div>
</div>
