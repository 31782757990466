<div class="card d-print-block shadow">
  <div class="card-header h4 d-none d-print-block">{{ appName }}</div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th scope="col" class="w-5">Id</th>
            <th scope="col">Competency Type</th>
            <th scope="col" class="w-25">Competency</th>
            <th scope="col" class="w-15">Level</th>
            <th scope="col" class="w-10">Aassessor</th>
            <th scope="col" class="w-10">Publication Date</th>
            <th scope="col" class="w-10">Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let claim of publishedClaims; let i = index">
            <th scope="row">{{ claim.id }}</th>
            <td>{{ claim.competencyDimensionName | titlecase }}</td>
            <td>
              <span class="text-muted text-uppercase">{{
                claim.disciplineName
              }}</span>
              - {{ claim.skillName }}
            </td>
            <td>
              {{ claim.claimLevelName }}
              <span class="fst-italic">{{
                claim.isSelfAssessed === true ? '[Self-verified]' : ''
              }}</span>
            </td>
            <td>{{ claim.assessorName }}</td>
            <td nowrap>
              {{ claim.createDatetime | date: 'dd MMM yyyy' }}
            </td>
            <td nowrap>
              {{ (claim.expiryDate | date: 'dd MMM yyyy') || 'n/a' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-12 col-lg">
        <span class="fw-bold">Number of assessment requests: </span
        >{{ publishedClaims?.length }}
      </div>
      <div class="col-12 col-lg">
        <span class="float-lg-end">
          <span class="fw-bold">Snapshot generated at: </span
          >{{ retrievedTimeStamp | date: 'medium' }}</span
        >
      </div>
    </div>
  </div>
</div>
<div class="card mt-5 d-print-none shadow">
  <div class="card-body">
    <h5 class="card-title">Share this report</h5>
    <div class="row">
      <div class="col-12 col-md-10">
        <p class="card-text">
          Clicking the Share button will generate a unique link that may be used
          by anyone to view this snapshot of assessed competencies.
        </p>
      </div>
      <div class="col-12 col-md-2">
        <div class="float-md-end">
          <button
            type="button"
            class="btn btn-outline-dark esqepBtn"
            (click)="onShareButtonClick()"
            [disabled]="shareButtonDisabled"
          >
            Share
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="sharedInfoUniqueId" class="row">
      <div class="col-12">
        Link here: <a [href]="sharedUrl">{{ sharedUrl }}</a>
      </div>
    </div>
  </div>
</div>
