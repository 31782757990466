<div [formGroup]="parentFormGroup.get('projectMembers')">
  <button (click)="openAddProjectMembersModal()" class="btn esqepBtn me-3">
    Add Project Members
  </button>
  <button class="btn esqepBtn" disabled>Add People From Shortlist</button>
  <div class="fst-italic mt-2">
    Click the "Add Project Members" button to add people to the project. In the
    table below, set or change the role you want to assign this person to.
  </div>
  <div
    [formGroup]="parentFormGroup.get('projectMembers.assignedMembers')"
    class="w-100 fixed-table-height mt-3"
  >
    <div
      class="alert alert-info"
      [hidden]="projectMembers.controls?.length > 0"
    >
      <span class="fas fa-xl fa-info-circle"></span>
      Add members to the project - currently no people have been added
    </div>
    <table
      class="table table-responsive"
      [hidden]="projectMembers.controls?.length === 0"
    >
      <thead>
        <tr>
          <th scope="col" class="w-20">Person</th>
          <th scope="col" class="w-25">Role</th>
          <th scope="col" class="w-20">Role Match</th>
          <th scope="col" class="w-15">Location</th>
          <th scope="col" class="w-20">Actions</th>
        </tr>
      </thead>
      <tbody formArrayName="projectMembers">
        <tr
          *ngFor="let member of projectMembers.controls; let i = index"
          [formGroupName]="i"
        >
          <td>
            {{ member.value.name }}
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              formControlName="roleIndex"
              (change)="watchForChanges(i, member)"
            >
              <option [ngValue]="null">N/A</option>
              <option
                *ngFor="let jr of projectJobRoles"
                [ngValue]="jr.roleIndex"
              >
                {{ jr.name }}
              </option>
            </select>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-75">
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    aria-label="Score progress bar"
                    [style.width]="member.value.score + '%'"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="w-25 mx-2">
                <span>{{ member.value.score }}%</span>
              </div>
            </div>
          </td>
          <td>{{ member.value.location }}</td>
          <td>
            <div class="w-75 d-flex justify-content-between">
              <button
                (click)="removeMember(i)"
                class="action-btn"
                title="Remove from project"
              >
                <i class="fas fa-trash-alt text-danger"></i>
              </button>
              <button class="action-btn" title="Add to shortlist">
                <i class="fas fa-list-alt"></i>
              </button>
              <button class="action-btn" title="See person details">
                <i class="fas fa-id-card"></i>
              </button>
              <button class="action-btn" title="Contact">
                <i class="fas fa-envelope"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #membersModal let-modal>
  <div class="modal-content">
    <div class="modal-header bg-dark text-light">
      <h5 class="modal-title">Add Project Members</h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        (click)="modal.close()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="d-flex" [formGroup]="parentFormGroup.get('projectMembers')">
        <div class="form-check me-2">
          <input
            class="form-check-input"
            type="radio"
            name="radioOption"
            id="recRadio"
            value="rec"
            formControlName="radioOption"
          />
          <label class="form-check-label" for="recRadio">
            Recommendations</label
          >
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="radioOption"
            id="allRadio"
            value="all"
            formControlName="radioOption"
          />
          <label class="form-check-label" for="allRadio">All</label>
        </div>
      </div>
      <div *ngIf="parentFormGroup.value.projectMembers.radioOption === 'rec'">
        <div class="mt-3">
          <h5 class="fw-bold">Recommendations based on Job Roles</h5>
          <div
            class="alert alert-info"
            [hidden]="filteredJobRoleList.length > 0"
          >
            <span class="fas fa-xl fa-info-circle"></span>
            No job roles have been added to the project
          </div>
          <div [hidden]="filteredJobRoleList.length === 0" class="my-3">
            <div
              class="d-flex align-items-end mb-3"
              [formGroup]="
                parentFormGroup.get('projectMembers.recModalFilters')
              "
            >
              <div class="dropdownWidth">
                <ug-dropdown-select
                  [ngbTypeahead]="jobRoleSearchRec"
                  [resultFormatter]="jobRoleFormatterRec"
                  [inputFormatter]="jobRoleFormatter"
                  [inlineGroup]="false"
                  formControlName="jobRole"
                >
                  Filter by Job Role
                </ug-dropdown-select>
              </div>
              <div class="ms-3">
                <button class="btn esqepBtn" (click)="clearFiltersRoles()">
                  Clear Filters
                </button>
              </div>
            </div>
            <div class="recommendations">
              <div class="fixed-table-height my-2">
                <table class="table border">
                  <thead>
                    <tr>
                      <th scope="col" class="w-40">Job Role</th>
                      <th scope="col" class="text-center w-20">
                        Required Role Match
                      </th>
                      <th scope="col" class="text-center w-20">Roles Filled</th>
                      <th scope="col" class="w-20"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="
                        let jr of filteredJobRoleList;
                        let i = index;
                        trackBy: trackByFn
                      "
                    >
                      <tr
                        (click)="toggleJr(i)"
                        [ngClass]="jrIndex === i ? 'bg-light' : null"
                      >
                        <td>{{ jr.name }}</td>
                        <td>
                          <div class="text-center">{{ jr.minRoleMatch }}%</div>
                        </td>
                        <td>
                          <div
                            class="text-center"
                            [ngClass]="
                              getJobRoleMembersCount(jr) === jr.quantity
                                ? 'text-success'
                                : 'text-danger'
                            "
                          >
                            {{ getJobRoleMembersCount(jr) }} / {{ jr.quantity }}
                          </div>
                        </td>
                        <td>
                          <div class="w-100 text-end">
                            <i
                              class="me-2"
                              [ngClass]="
                                jrIndex === i
                                  ? 'fa fa-chevron-down'
                                  : 'fa fa-chevron-right'
                              "
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="12" class="p-0 m-0">
                          <div
                            [ngbCollapse]="jrIndex != i"
                            class="w-75 m-auto my-2"
                          >
                            <ug-list-status
                              [isLoading]="rcIsLoading"
                              [listLength]="listLength"
                            ></ug-list-status>
                            <div
                              *ngIf="!rcIsLoading"
                              class="alert alert-info"
                              [hidden]="jr.rec.length > 0"
                            >
                              <span class="fas fa-xl fa-info-circle"></span>
                              No recommendations can be found for this role and
                              role match level
                            </div>
                            <table
                              *ngIf="!rcIsLoading"
                              [hidden]="jr.rec.length === 0"
                              class="table"
                            >
                              <thead>
                                <tr class="info">
                                  <th scope="col" class="w-10">
                                    <input
                                      type="checkbox"
                                      [(ngModel)]="jr.isSelected"
                                      (change)="
                                        checkUncheckAllFilteredJobRecs(i)
                                      "
                                    />
                                    <label class="ms-1">Select all</label>
                                  </th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Role Match</th>
                                  <th scope="col">Location</th>
                                  <th scope="col" class="text-center">
                                    Active Projects
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="let person of jr.rec; let j = index"
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      [(ngModel)]="person.isSelected"
                                      (change)="
                                        isAllFilteredJobRecsSelected(i, j)
                                      "
                                    />
                                  </td>
                                  <td>{{ person.name }}</td>
                                  <td>
                                    <div
                                      class="d-flex align-items-center justify-content-between"
                                    >
                                      <div class="w-75">
                                        <div class="progress">
                                          <div
                                            class="progress-bar bg-success"
                                            role="progressbar"
                                            aria-label="Person role score"
                                            [style.width]="
                                              person.personRoleScore + '%'
                                            "
                                            [aria-valuenow]="
                                              person.personRoleScore
                                            "
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </div>
                                      <div class="w-25 mx-2">
                                        <span
                                          >{{ person.personRoleScore }}%</span
                                        >
                                      </div>
                                    </div>
                                  </td>
                                  <td>{{ person.location }}</td>
                                  <td class="text-center">
                                    {{ person.activeProjects }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr class="solid" />
        <div class="mt-3">
          <h5 class="fw-bold">Recommendations based on Competencies</h5>
          <div
            class="alert alert-info"
            [hidden]="filteredCompetencyList.length > 0"
          >
            <span class="fas fa-xl fa-info-circle"></span>
            No competencies have been added to the project
          </div>
          <div [hidden]="filteredCompetencyList.length === 0">
            <div
              class="d-flex align-items-end mb-3"
              [formGroup]="
                parentFormGroup.get('projectMembers.recModalFilters')
              "
            >
              <div class="dropdownWidth">
                <ug-dropdown-select
                  [ngbTypeahead]="competencySearch"
                  [resultFormatter]="competencyFormatter"
                  [inputFormatter]="competencyFormatter"
                  [inlineGroup]="false"
                  formControlName="competency"
                >
                  Filter by Competency
                </ug-dropdown-select>
              </div>
              <div class="ms-3">
                <button class="btn esqepBtn" (click)="clearFiltersComps()">
                  Clear Filters
                </button>
              </div>
            </div>
            <div class="fixed-table-height my-2 recommendations">
              <table class="table border">
                <thead>
                  <tr>
                    <th scope="col" class="w-40">Competency</th>
                    <th scope="col" class="w-40">Required Level</th>
                    <th scope="col" class="w-20"></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="
                      let c of filteredCompetencyList;
                      let i = index;
                      trackBy: trackByFn
                    "
                  >
                    <tr
                      (click)="toggleComp(i)"
                      [ngClass]="compIndex === i ? 'bg-light' : null"
                    >
                      <td>{{ c.discipline }} - {{ c.skillName }}</td>
                      <td>{{ c.requiredLevelName }}</td>
                      <td>
                        <div class="w-100 text-end">
                          <i
                            class="me-2"
                            [ngClass]="
                              compIndex === i
                                ? 'fa fa-chevron-down'
                                : 'fa fa-chevron-right'
                            "
                          ></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="12" class="p-0 m-0">
                        <div
                          [ngbCollapse]="compIndex != i"
                          class="w-75 m-auto my-2"
                        >
                          <ug-list-status
                            [isLoading]="cIsLoading"
                            [listLength]="clistLength"
                          ></ug-list-status>
                          <div
                            *ngIf="!cIsLoading"
                            class="alert alert-info"
                            [hidden]="c.rec.length > 0"
                          >
                            <span class="fas fa-xl fa-info-circle"></span>
                            No recommendations can be found for this competency
                            and competency level
                          </div>
                          <table class="table">
                            <thead>
                              <tr class="info">
                                <th scope="col" class="w-10">
                                  <input
                                    type="checkbox"
                                    [(ngModel)]="c.isSelected"
                                    (change)="
                                      checkUncheckAllFilteredCompRecs(i)
                                    "
                                  />
                                  <label class="ms-1">Select all</label>
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Competency Level</th>
                                <th scope="col">Location</th>
                                <th scope="col" class="text-center">
                                  Active Projects
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let person of c.rec; let j = index">
                                <td>
                                  <input
                                    type="checkbox"
                                    [(ngModel)]="person.isSelected"
                                    (change)="isAllFilteredCompRecsSelected(i)"
                                  />
                                </td>
                                <td>{{ person.displayName }}</td>
                                <td>
                                  {{ person.level.name }}
                                </td>
                                <td>{{ person.location }}</td>
                                <td class="text-center">
                                  {{ person.activeProjects }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="parentFormGroup.value.projectMembers.radioOption === 'all'">
        <div>
          <div
            class="d-flex w-100 align-items-end justify-content-between mb-3"
            [formGroup]="parentFormGroup.get('projectMembers.allModalFilters')"
          >
            <div class="me-3" class="filterWidth">
              <ug-dropdown-select
                [ngbTypeahead]="peopleSearch"
                [resultFormatter]="personFormatter"
                [inputFormatter]="personFormatter"
                [inlineGroup]="false"
                formControlName="person"
              >
                Select individual
              </ug-dropdown-select>
            </div>
            <div class="me-3" class="filterWidth">
              <ug-dropdown-select
                [ngbTypeahead]="locationSearch"
                [resultFormatter]="locationFormatter"
                [inputFormatter]="locationFormatter"
                [inlineGroup]="false"
                formControlName="location"
              >
                Location
              </ug-dropdown-select>
            </div>
            <div class="me-3" class="filterWidth">
              <ug-dropdown-select
                [ngbTypeahead]="jobRoleSearch"
                [resultFormatter]="jobRoleFormatter"
                [inputFormatter]="jobRoleFormatter"
                [inlineGroup]="false"
                formControlName="jobRole"
              >
                Job Role
              </ug-dropdown-select>
            </div>
            <div class="me-3">
              <label for="max-projects" class="form-label"
                >Maximum active projects</label
              >
              <select
                id="max-projects"
                class="form-select maxProjSelect"
                formControlName="maxProjects"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="clear-filter-btn">
              <button class="btn esqepBtn" (click)="clearFiltersAll()">
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <div class="fixed-table-height">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="w-10">
                  <input
                    type="checkbox"
                    [(ngModel)]="selectAllFilteredPeople"
                    (change)="checkUncheckAllFilteredPeople()"
                  />
                  <label class="ms-1">Select all</label>
                </th>
                <th scope="col">Name</th>
                <th scope="col">Role</th>
                <th scope="col">Location</th>
                <th scope="col">Active Projects</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of filteredPeopleList; let index = index">
                <td>
                  <input
                    type="checkbox"
                    [(ngModel)]="filteredPeopleList[index].isSelected"
                    (change)="isAllFilteredPeopleSelected()"
                  />
                </td>
                <td>{{ person.displayName }}</td>
                <td>{{ person.jobRole }}</td>
                <td>{{ person.location }}</td>
                <td>{{ person.activeProjects }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        (click)="addSelectedPeople()"
        class="btn btn-outline-success"
      >
        Add selected people
      </button>
      <button class="btn btn-outline-dark" (click)="modal.close('')">
        Close
      </button>
    </div>
  </div>
</ng-template>
