<div class="card shadow card-height">
  <div class="card-body">
    <ng-container>
      <div class="form bg-light border p-3 mb-3">
        <div class="row align-items-center">
          <div class="col-12 col-md-3 pt-1" *ngIf="!disableJobRoleSearch">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="myJobRoles"
                id="personRoleOption"
                [value]="personOrAllRoleOptions.PERSON_ROLES"
                [(ngModel)]="personOrAllRolesSelection"
                (ngModelChange)="onRadioChange($event)"
              />
              <label class="form-check-label" for="jobRoleOption"
                >My Assigned Roles</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="jobRoleOrPersonOptions"
                id="personOption"
                [value]="personOrAllRoleOptions.ALL_ROLES"
                [(ngModel)]="personOrAllRolesSelection"
                (ngModelChange)="onRadioChange($event)"
              />
              <label class="form-check-label" for="personOption"
                >All Roles</label
              >
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="d-flex align-items-center">
              <label class="col-1 me-1">Job Roles</label>
              <div class="col-6 me-4">
                <ng-select
                  [items]="dropdownJobRoles"
                  [bindLabel]="dropdownBinding.label"
                  [bindValue]="dropdownBinding.value"
                  [(ngModel)]="selectedRole"
                  [clearable]="false"
                  (ngModelChange)="loadProfile()"
                >
                </ng-select>
              </div>
              <div *ngIf="!disableJobRoleSearch" class="col">
                <ug-role-person-score
                  *ngIf="roleProfile.length"
                  [jobRolePersonScore]="
                    roleProfile[0].people[0].jobRoleLevelScore || 0
                  "
                ></ug-role-person-score>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ug-list-status
        [isLoading]="isLoading"
        [listLength]="selectedRole ? 1 : 0"
        [emptyListMessage]="' You have no assigned roles'"
      ></ug-list-status>
      <ng-container *ngIf="selectedRole && includeProfileMatch && !isLoading">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="activeTab"
          [destroyOnHide]="false"
          class="nav-justified"
        >
          <li [ngbNavItem]="1">
            <a ngbNavLink class="text-center mb-2">
              <h6>Profile Match</h6>
              <h6><small>View your job role match</small></h6>
            </a>
            <ng-template ngbNavContent>
              <ug-list-status
                [isLoading]="isPlotLoading"
                [listLength]="[roleProfile].length"
              ></ug-list-status>
              <ng-container *ngIf="!isPlotLoading">
                <div class="mt-2">
                  <ug-role-competency-summary
                    *ngIf="roleProfile.length; else noProfileMatch"
                    [jobRolePerson]="roleProfile[0].people[0]"
                    [showGraph]="true"
                  >
                  </ug-role-competency-summary>
                  <ng-template #noProfileMatch>
                    <div class="alert alert-info" role="alert">
                      This role has no requirements assigned to it.
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink class="text-center mb-2">
              <h6>Job Role Details</h6>
              <h6><small>View key details about the job role</small></h6>
            </a>
            <ng-template ngbNavContent>
              <ug-job-role-details-summary
                *ngIf="selectedRole"
                [jobRoleId]="selectedRole"
              ></ug-job-role-details-summary>
            </ng-template>
          </li>
          <div [ngbNavOutlet]="nav" class="content w-100"></div>
        </ul>
      </ng-container>

      <ng-container *ngIf="!includeProfileMatch">
        <ug-job-role-details-summary
          *ngIf="selectedRole"
          [jobRoleId]="selectedRole"
        ></ug-job-role-details-summary>
      </ng-container>
    </ng-container>
  </div>
</div>
