import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { TextboxFilter } from '../../controls/dynamic-form/control-types/filter-textbox';
import { FilterBase } from '../../controls/dynamic-form/filter-base';
import { TableHeader } from '../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import {
  DynamicGroupsService,
  GroupFilterAudit,
  JoinOperator,
} from '../services/dynamic-groups.service';

@Component({
  selector: 'ug-group-filter-audit',
  templateUrl: './group-filter-audit.component.html',
  styleUrls: ['./group-filter-audit.component.scss'],
  providers: [DatePipe],
})
export class GroupFilterAuditComponent implements OnInit {
  datepipe = inject(DatePipe);
  private dynamicGroupService = inject(DynamicGroupsService);
  private alertService = inject(AlertService);

  exceptionData = {
    FILTER_AUDIT: {
      level: AlertLevels.ERROR,
      code: 'GFA-001',
      message: 'Error retrieving group filter audit history',
    } as AlertData,
  };

  @Input() groupFilterId: number;
  auditItems: GroupFilterAudit[] = [];
  ngUnsubscribe: Subject<boolean> = new Subject();
  loadingData = true;
  filters: Array<FilterBase<any>>;
  tableCollapsedRowData = [];
  tableCollapsedRowSubHeadings = [];
  headers: Array<TableHeader>;

  ngOnInit() {
    this.loadingData = true;
    this.dynamicGroupService
      .listGroupFilterAudit(this.groupFilterId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingData = false)),
      )
      .subscribe({
        next: (gfa) => {
          this.auditItems = gfa;
          const collapsedRowItems = [];
          const collapsedRowSubHeadings = [];
          this.auditItems.forEach((ai) => {
            const parsedFilter = JSON.parse(ai.uiFilterInfo);
            const filter = parsedFilter[0][0];
            const personActiveFilter = parsedFilter[2];
            // Create filter summary for non 'hidden' person active filters at [0][0] in the array
            const uiFilterInfo = this.createFilterSummaryWithBrackets(filter);
            collapsedRowItems.push(uiFilterInfo.join(' '));
            // Create condition summary for the 'hidden' person active filters at [2] in the array
            const externalAbsentFilter =
              this.dynamicGroupService.createConditionSummary(
                personActiveFilter,
              );
            collapsedRowSubHeadings.push(externalAbsentFilter);
          });
          this.tableCollapsedRowData = collapsedRowItems;
          this.tableCollapsedRowSubHeadings = collapsedRowSubHeadings;
          this.headers = [
            {
              id: 'lastModifiedDate',
              title: 'Date',
              stringFunction: this.dateDisplay,
            },
            {
              id: 'auditStartOperation',
              title: 'Action',
              stringFunction: this.actionDisplay,
            },
            { id: 'auditStartPersonDisplayName', title: 'By' },
            { id: 'auditStartPersonUsername', title: 'User Name' },
          ];

          if (this.auditItems.length) {
            this.filters = [
              new TextboxFilter({
                key: 'searchTerm',
                label: 'Search audit history',
                order: 1,
                placeholder: 'Search audit history',
              }),
            ];
          }
          this.loadingData = false;
        },
        error: (err) => {
          this.alertService.createAlert2(this.exceptionData.FILTER_AUDIT, err);
          this.loadingData = false;
        },
      });
  }

  actionDisplay = (row) => {
    if (row.auditStartOperation === 'I') {
      return 'Created';
    } else if (row.auditStartOperation === 'U') {
      return 'Updated';
    } else if (row.auditStartOperation === 'D') {
      return 'Removed';
    } else {
      return 'Unknown action';
    }
  };

  dateDisplay = (row) => {
    return this.datepipe.transform(row.lastModifiedDate, 'YYYY-MM-dd hh:mm:ss');
  };

  applyBracketsToFilter(filter): string {
    let bracketedGroup = '(';
    for (let i = 0; i < filter.length; i++) {
      if (filter[i + 1] === JoinOperator.Or) {
        bracketedGroup += filter[i];
        bracketedGroup += ') ';
      } else if (filter[i] === JoinOperator.Or) {
        bracketedGroup += filter[i];
        bracketedGroup += ' (';
      } else if (filter[i] === JoinOperator.And) {
        bracketedGroup += ' ' + filter[i] + ' ';
      } else {
        bracketedGroup += filter[i];
      }
    }
    bracketedGroup += ')';
    return bracketedGroup;
  }

  createFilterSummaryWithBrackets(filterArray: Array<string>): Array<string> {
    const filterSummary = [];
    const groupsSummary =
      this.dynamicGroupService.createGroupsSummary(filterArray);
    groupsSummary.forEach((f) => {
      Array.isArray(f)
        ? filterSummary.push(this.applyBracketsToFilter(f))
        : filterSummary.push(f);
    });
    return filterSummary;
  }
}
