import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { provideTranslocoScope } from '@jsverse/transloco';
import { AuthenticationConfigNames } from '../../core/authenticationConfigNames';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../core/services/alert.service';
import { AuthService, LoggedInUser } from '../../core/services/auth.service';
import { LogLevel, Logger } from '../../core/services/config.service';
import {
  PersonService,
  PersonVerificationAreas,
} from '../../person/services/person.service';

@Component({
  selector: 'ug-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss'],
  providers: [provideTranslocoScope('person-details')],
})
export class PersonDetailComponent implements OnInit {
  @Input() personGUID;
  @Input() personUPN;

  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private authService = inject(AuthService);
  private personService = inject(PersonService);
  private sanitizer = inject(DomSanitizer);
  private authenticationConfigNames: typeof AuthenticationConfigNames =
    AuthenticationConfigNames;

  protected person: LoggedInUser;
  protected personPhotoUrl: SafeResourceUrl;
  protected personPhotoFailure = false;
  protected isLoading = true;
  protected personVerificationAreas = {} as PersonVerificationAreas;

  exceptionData = {
    PERSON_UUID: {
      level: AlertLevels.ERROR,
      code: 'PDT-001',
      message: 'Unable to retrieve user data',
    } as AlertData,
    PERSON_PHOTO: {
      level: AlertLevels.WARNING,
      code: 'PDT-002',
      message: 'Unable to retrieve user photo',
    } as AlertData,
    PERSON_VERIFICATION_AREAS: {
      level: AlertLevels.WARNING,
      code: 'PDT-003',
      message: 'Unable to retrieve user verification areas',
    } as AlertData,
  };

  get listLength() {
    return this.isLoading ? 0 : 1;
  }

  ngOnInit() {
    if (!this.personUPN) {
      this.personUPN = this.authService.me?.adUpn;
    }
    this.isLoading = true;
    this.person = this.authService.me;
    this.personService
      .getPersonVerificationAreas(this.authService.me.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (pva: PersonVerificationAreas) => {
          this.personVerificationAreas = pva;
          this.isLoading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.alertService.createAlert2(
            this.exceptionData['PERSON_VERIFICATION_AREAS'],
            err,
          );
        },
      });

    if (
      localStorage.getItem('esqep.authType') ===
      this.authenticationConfigNames.ENTRA
    ) {
      this.personService
        .getUserPhoto(this.authService.userGUID)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (photo) => {
            let photoUrl = '';
            const photoBlob = new Blob([photo], { type: photo.type });
            if (
              photoBlob.type === 'image/jpeg' ||
              photoBlob.type === 'image/png'
            ) {
              photoUrl = window.URL.createObjectURL(photoBlob);

              this.personPhotoUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(photoUrl);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.personPhotoFailure = true;
            this.alertService.createAlert2(
              this.exceptionData['PERSON_PHOTO'],
              err,
            );
          },
          complete: () => Logger('Got Person photo', LogLevel.VERBOSE),
        });
    } else {
      this.personPhotoUrl = '';
      this.personPhotoFailure = true;
    }
  }
}
