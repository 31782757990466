import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import {
  TableHeader,
  TableSelectedButton,
} from '../../../../../controls/table/table.service';
import {
  AlertData,
  AlertLevels,
  AlertService,
} from '../../../../../core/services/alert.service';
import { DynamicGroupsService } from '../../../../../dynamic-groups/services/dynamic-groups.service';
import {
  BasicJobRolePerson,
  Person,
} from '../../../../../person/models/person';

@Component({
  selector: 'ug-people-upload',
  templateUrl: './people-upload.component.html',
  styleUrls: ['./people-upload.component.scss'],
})
export class PeopleUploadComponent implements OnInit, OnDestroy {
  exceptionData = {
    PERSON_LIST_BY_UPLOAD: {
      level: AlertLevels.ERROR,
      code: 'JRP-008',
      message: 'Error retrieving person list by file upload',
    } as AlertData,
  };

  private dynamicGroupsService = inject(DynamicGroupsService);
  private modal = inject(NgbActiveModal);
  private alertService = inject(AlertService);

  @Input() parentFormGroup: FormGroup;
  @Input() currentRolePeople: Array<BasicJobRolePerson> = [];
  @Output() peopleSelected: EventEmitter<any> = new EventEmitter<any>();

  tableHeaders: Array<TableHeader>;
  tableRows: Array<any>;
  tableSelectedButtons: Array<TableSelectedButton>;
  tableLoading: boolean;
  ngUnsubscribe: Subject<boolean> = new Subject();
  fileUploadPeople: Array<Person>;
  addSelectedLoading = false;

  ngOnInit(): void {
    this.tableHeaders = [
      { id: 'displayName', title: 'Name', class: 'w-30' },
      {
        id: 'exists',
        title: 'Exists In Role',
        iconFunction: this.existsIcon,
        class: 'w-50 text-center',
      },
      //  { id: 'location', title: 'Location' }, { id: 'division', title: 'Division' }, { id: 'position', title: 'Position' }
    ];
    this.tableSelectedButtons = [
      {
        title: 'Cancel',
        class: 'btn-outline-secondary',
        cancelButton: true,
        function: this.closeModal,
      },
      {
        title: 'Add Selected',
        class: 'btn-outline-success',
        function: this.addSelected,
      },
    ];
  }

  existsIcon = (row) => {
    return row.exists
      ? 'text-center fas fa-xl fa-check-circle text-success'
      : '';
  };

  closeModal() {
    this.modal.close();
  }

  fileUploadChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.tableLoading = true;
    }
    const myReader = new FileReader();
    myReader.onloadend = () => {
      const idsFromFile = (myReader.result as string)
        .split(/\r\n|\n/)
        .filter((i) => ['userid', ''].findIndex((x) => x === i) === -1);

      const useridFilter = `["ExternalUserId","in",${JSON.stringify(idsFromFile)}]`;
      const activeFilter = `["ExternalActive","=","true"]`;
      const filterString = `[${useridFilter},\"and\",${activeFilter}]`;

      this.dynamicGroupsService
        .getFilteredEntityValues('Person', {
          filter: filterString,
          page: 0,
          pageSize: 0,
        })
        .subscribe({
          next: (data) => {
            this.fileUploadPeople = data.map((p) => {
              return {
                ...p,
                exists:
                  this.currentRolePeople.findIndex((s) => s.id === p.id) !== -1,
              };
            });
            this.tableRows = [...this.fileUploadPeople];
            this.tableLoading = false;
          },
          error: (error) => {
            this.alertService.createAlert2(
              this.exceptionData.PERSON_LIST_BY_UPLOAD,
              error,
            );
          },
        });
      event.target.value = '';
    };
    myReader.readAsText(file);
  }

  addSelected = (selected) => {
    this.peopleSelected.next(selected);
    this.closeModal();
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
