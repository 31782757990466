<div class="modal-content">
  <div class="modal-header bg-dark text-light">
    <h5 class="modal-title">
      {{
        matrixType === 'Training' ? 'Training Details' : 'Assessment Details'
      }}
    </h5>
    <button
      type="button"
      class="btn-close btn-close-white"
      (click)="modal.close()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <table class="table table-borderless table-striped">
      <tbody>
        <tr *ngIf="groupedSkills">
          <th scope="col">Title</th>
          <td>{{ groupedSkills?.name }}</td>
        </tr>
        <tr>
          <th scope="col">User</th>
          <td ugAnonymize>{{ person.displayName }}</td>
        </tr>
        <tr>
          <th scope="col">Training</th>
          <td>
            <ng-container
              *ngIf="
                matrixType === 'training' && showTrainingDeepLink(person);
                else noLink
              "
            >
              <a
                [href]="skill.url"
                target="_blank"
                rel="noreferrer noopener"
                (click)="onLinkClick()"
                title="Click here to launch training"
                >{{ skill.name }}</a
              >
            </ng-container>
            <ng-template #noLink>
              {{ skill.name }}
            </ng-template>
          </td>
        </tr>
        <tr *ngIf="matrixType === 'competency'">
          <th scope="col">{{ launchText }}</th>
          <td>
            <div *ngIf="showCompetencyLink(person)">
              <div *ngIf="claim.id">
                <a
                  [href]="routeToAssessmentRequest()"
                  target="_blank"
                  (click)="onLinkClick()"
                  >Click here</a
                >
              </div>
              <div *ngIf="!claim.id">No active claim for this competency</div>
            </div>
            <div *ngIf="!showCompetencyLink(person)">
              You are not an assessor for this competency
            </div>
          </td>
        </tr>
        <tr>
          <th scope="col">Current Status</th>
          <td>{{ claim.status }}</td>
        </tr>
        <tr>
          <th scope="col">Current State</th>
          <td>{{ claim.state }}</td>
        </tr>
        <tr *ngIf="showDueDate()">
          <th scope="col">Due Date</th>
          <td>{{ claim.dueDate | date: 'mediumDate' }}</td>
        </tr>
        <tr>
          <th scope="col">Expiry Date</th>
          <td>{{ claim.expiryDate | date: 'mediumDate' }}</td>
        </tr>
        <tr>
          <th scope="col">Completion Date</th>
          <td>{{ claim.completionDate | date: 'mediumDate' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.close()"
    >
      Close
    </button>
    @if (!hideActionButton || (hideActionButton && fullFunc)) {
      <div
        *ngIf="
          (fullFunc || isManager) &&
          skill.type !== eventType &&
          skill.objectId &&
          !['Awaiting sync', 'Updating'].includes(claim.state)
        "
      >
        <button
          type="button"
          *ngIf="!awaitingCompleteConfirmation && isCsodUser"
          class="btn btn-outline-success"
          (click)="awaitingCompleteConfirmation = true"
        >
          Mark complete
        </button>
        <div class="btn-group" *ngIf="awaitingCompleteConfirmation">
          <button
            type="button"
            class="btn btn-success"
            (click)="onConfirmCompleteClick()"
          >
            Click to confirm completion
          </button>
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="awaitingCompleteConfirmation = false"
          >
            Cancel
          </button>
        </div>
      </div>
    }
  </div>
</div>
