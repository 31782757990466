import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ug-key-details',
  templateUrl: './key-details.component.html',
  styleUrls: ['./key-details.component.scss'],
})
export class KeyDetailsComponent {
  @Input() parentFormGroup: FormGroup;
}
