<div class="row">
  <br />
  <div class="col-12">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">Please review the assessment request details</h5>
        <p>
          As a assessor you may modify the assessment request level if you feel
          the current level is incorrect. You may also assign a different
          assessment request to review the assessment request. Both of these
          steps may be performed on the
          <a [routerLink]="[]" (click)="onTabLinkClick(1)">
            Level and Assessor
          </a>
          tab. Any modifications to the assessment request should be supported
          with a <strong>Assessor statement</strong> and they must be
          <strong>Saved</strong> before actioning the assessment request.
        </p>
        <p>
          Once you have reviewed the assessment request details please choose an
          appropriate <strong>Action</strong> from the dropdown box and enter an
          <strong>Assessor statement</strong> to explain your decision.
        </p>
        <p>
          Finally, please click the <strong>OK</strong> button to submit your
          verification action
        </p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-4 mb-3">
    <esqep-action-select-form
      [parentFormGroup]="parentFormGroup.get('detail.verification')"
      [targetFormGroup]="detailFormGroup"
      [buttonsFormGroup]="parentFormGroup.get('buttons')"
      [componentIndex]="'VA1'"
      [initiatorType]="'A'"
      [claimId]="claim?.id"
      [claimStatusId]="claim?.claimStatusId"
      (actionChange)="onActionChange($event)"
      (submit)="onSubmitActionClick()"
      (cancel)="onCancelActionClick()"
    ></esqep-action-select-form>
  </div>
  <div class="col-12 col-md-8">
    <esqep-statement-form
      [parentFormGroup]="parentFormGroup.get('detail.verification.statement')"
      [esqepTitle]="'Assessor Statement'"
      [componentIndex]="'VS1'"
    ></esqep-statement-form>
  </div>
</div>
